export var defaultValues = {
    playerAvatar: 'playerDefaultAvatar',
    playerSocialAvatar: 'playerSocialAvatar',
};
export var defaultSettings = {
    music: true,
    sound: true,
    messages: true,
    vibration: true,
    language: window.navigator.language.substring(0, window.navigator.language.indexOf('-'))
};
export var defaultAvatarPosition = new Phaser.Math.Vector2(0.8, 0.5);
export var oneSignalId = '63c53724-6fb7-47db-b39b-2106df53f330';
