var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import OkInstantWrapper from '../../utils/wrappers/OkInstantWrapper';
import { StoreItemActionType, StoreItemState, } from '../VO/StoreVO';
import BaseStoreVOProxy from './BaseStoreVOProxy';
var OkStoreVOProxy = /** @class */ (function (_super) {
    __extends(OkStoreVOProxy, _super);
    function OkStoreVOProxy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OkStoreVOProxy.prototype.onRegister = function () {
        this.sendNotification(BaseStoreVOProxy.REGISTERED_NOTIFICATION);
    };
    OkStoreVOProxy.prototype.buyProduct = function (section, productId) {
        return __awaiter(this, void 0, void 0, function () {
            var targetProduct;
            return __generator(this, function (_a) {
                targetProduct = this.vo.mergedData[section].find(function (value) { return value.productId === productId; });
                if (!targetProduct) {
                    this.sendNotification(BaseStoreVOProxy.PRODUCT_DOES_NOT_EXIST_NOTIFICATION, section, productId);
                }
                else {
                    this.sendNotification(BaseStoreVOProxy.PRODUCT_BUY_PENDING_NOTIFICATION, section, productId);
                    OkInstantWrapper.showPayment(targetProduct);
                }
                return [2 /*return*/];
            });
        });
    };
    OkStoreVOProxy.prototype.removeAllProducts = function () {
        this.vo.apiData = {
            gold: [],
            silver: [],
            crystal: [],
            free: [],
            discounts: [],
        };
        this.vo.serviceData = {
            gold: [],
            silver: [],
            crystal: [],
            free: [],
        };
        this.vo.mergedData = {
            gold: [],
            silver: [],
            crystal: [],
            free: [],
        };
        this.sendNotification(BaseStoreVOProxy.STORE_DATA_REMOVED_NOTIFICATION);
    };
    OkStoreVOProxy.prototype.updateData = function (data) {
        this.removeAllProducts();
        this.vo.updated = false;
        this.vo.apiData.gold = data.gold || [];
        this.vo.apiData.silver = data.silver || [];
        this.vo.apiData.crystal = data.crystal || [];
        this.vo.apiData.free = data.free || [];
        this.vo.apiData.discounts = data.discounts || [];
        this.vo.initializedMoment = Date.now();
        this.registerProducts();
    };
    OkStoreVOProxy.prototype.registerProducts = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.margeProductObjects();
                this.vo.updated = true;
                this.sendNotification(BaseStoreVOProxy.STORE_DATA_UPDATED_NOTIFICATION);
                return [2 /*return*/];
            });
        });
    };
    OkStoreVOProxy.prototype.margeProductObjects = function () {
        var keys = Object.keys(this.vo.apiData);
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key = keys_1[_i];
            if (key === 'discounts') {
                continue;
            }
            this.margeProductObject(key);
        }
    };
    OkStoreVOProxy.prototype.margeProductObject = function (type) {
        if (type === 'free') {
            return;
        }
        var apiDataTargetArray = this.vo.apiData[type];
        var mergedDataTargetArray = this.vo.mergedData[type];
        mergedDataTargetArray.remove.apply(mergedDataTargetArray, mergedDataTargetArray);
        for (var i = 0; i < apiDataTargetArray.length; i++) {
            var apiItem = apiDataTargetArray[i];
            if (!apiItem) {
                continue;
            }
            var name_1 = "FOOL " + apiItem.value + " " + apiItem.type.toUpperCase();
            var description = "BUY " + apiItem.value + " " + apiItem.type.toUpperCase();
            var mergedProduct = {
                iconIndex: apiItem.id - 1,
                productId: apiItem.productId,
                state: apiItem.bestValue
                    ? StoreItemState.BEST_VALUE
                    : apiItem.bestSeller
                        ? StoreItemState.BEST_SELLER
                        : StoreItemState.NORMAL,
                value: apiItem.value,
                price: "" + apiItem.price,
                section: apiItem.section,
                name: name_1,
                description: description,
                packSize: apiItem.packSize,
                type: apiItem.type,
                action: StoreItemActionType.BUY,
                multiplier: apiItem.multiplier,
            };
            mergedDataTargetArray.push(mergedProduct);
        }
    };
    return OkStoreVOProxy;
}(BaseStoreVOProxy));
export default OkStoreVOProxy;
