var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Audios, Fonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { Translation } from '../../../translations';
import PopupScene from '../../scenes/PopupScene';
import AbstractSettingPopupSection from './AbstractSettingPopupSection';
var SettingsPopupLanguageSection = /** @class */ (function (_super) {
    __extends(SettingsPopupLanguageSection, _super);
    function SettingsPopupLanguageSection(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.config = config;
        _this.createBody();
        _this.updateStatus(_this.config.status);
        return _this;
    }
    SettingsPopupLanguageSection.prototype.updateStatus = function (value) {
        this.config.status = value;
    };
    SettingsPopupLanguageSection.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createNameText();
        this.createSwitcher();
        this.createLanguageTexts();
        this.setListeners();
    };
    SettingsPopupLanguageSection.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SettingsPopupSection,
        });
        this.add(this.background);
    };
    SettingsPopupLanguageSection.prototype.createNameText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextMedium.Name,
            fontSize: 40 * gameConfig.resolutionMultiplier,
            fill: '#c3a469',
        };
        this.nameText = this.scene.make.extText({
            x: this.background.x - this.background.width * 0.45,
            y: 0,
            text: "settings-popup-" + this.config.name,
            style: style,
        });
        this.nameText.setOrigin(0, 0.5);
        this.add(this.nameText);
    };
    SettingsPopupLanguageSection.prototype.createSwitcher = function () {
        this.valueBg = this.scene.make.ninePatch({
            x: this.background.width * 0.26,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SettingsPopupLanguageBg,
            width: this.background.width * 0.3,
            height: this.background.height * 0.7,
        });
        this.add(this.valueBg);
        this.arrowLeft = this.scene.make.sprite({
            x: this.valueBg.x - this.valueBg.width * 0.6,
            y: this.valueBg.y,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SettingsPopupArrow,
        });
        this.arrowLeft.setFlipX(true);
        this.add(this.arrowLeft);
        this.arrowRight = this.scene.make.sprite({
            x: this.valueBg.x + this.valueBg.width * 0.6,
            y: this.valueBg.y,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SettingsPopupArrow,
        });
        this.add(this.arrowRight);
    };
    SettingsPopupLanguageSection.prototype.createLanguageTexts = function () {
        var style = {
            fontFamily: Fonts.AvenirNextMedium.Name,
            fontSize: 36 * gameConfig.resolutionMultiplier,
            fill: '#c3a469',
        };
        this.text = this.scene.make.extText({
            x: this.valueBg.x,
            y: this.valueBg.y,
            text: Translation.SETTINGS_POPUP_LANGUAGE_VALUE,
            style: style,
        });
        this.text.setOrigin(0.5);
        this.add(this.text);
    };
    SettingsPopupLanguageSection.prototype.setListeners = function () {
        this.valueBg.setInteractive();
        this.valueBg.on(Phaser.Input.Events.POINTER_UP, this.onClick, this);
        this.arrowLeft.setInteractive();
        this.arrowLeft.on(Phaser.Input.Events.POINTER_UP, this.onClick, this);
        this.arrowRight.setInteractive();
        this.arrowRight.on(Phaser.Input.Events.POINTER_UP, this.onClick, this);
    };
    SettingsPopupLanguageSection.prototype.onClick = function () {
        this.scene.playSFX(Audios.ButtonTapSfx.Name);
        this.scene.events.emit(PopupScene.SETTING_CHANGE_EVENT, this.config.name);
    };
    return SettingsPopupLanguageSection;
}(AbstractSettingPopupSection));
export default SettingsPopupLanguageSection;
