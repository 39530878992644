var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import GameFacade from '../../../GameFacade';
import { getPlatform } from '../../../utils/CordovaUtils';
import { getZone } from '../../../utils/Utils';
import FirebaseXWrapper from '../../../utils/wrappers/FirebaseXWrapper';
import RetrieveUserDataFromNativeCommand from '../retrieve-user-data/RetrieveUserDataFromNativeCommand';
var LoginViaNativeCommand = /** @class */ (function (_super) {
    __extends(LoginViaNativeCommand, _super);
    function LoginViaNativeCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoginViaNativeCommand.prototype.execute = function () {
        var _this = this;
        FirebaseXWrapper.signInUser()
            .then(function (credentials) {
            FirebaseXWrapper.signInWithCredential(credentials).then(function () {
                _super.prototype.execute.call(_this);
            });
        })
            .catch(function (reason) {
            _this.sendNotification(GameFacade.LOGIN_SERVICE_SIGH_IN_FAIL_NOTIFICATION, getZone(), getPlatform(), reason);
        });
    };
    LoginViaNativeCommand.prototype.initializeMacroCommand = function () {
        this.addSubCommand(RetrieveUserDataFromNativeCommand);
    };
    return LoginViaNativeCommand;
}(SyncMacroCommand));
export default LoginViaNativeCommand;
