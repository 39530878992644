var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
import { upperCaseFirstLetter } from '../../../../utils/Utils';
var StoreItemCount = /** @class */ (function (_super) {
    __extends(StoreItemCount, _super);
    function StoreItemCount(scene, config, x, y) {
        var _this = _super.call(this, scene, x, y) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        return _this;
    }
    StoreItemCount.prototype.updateData = function (config) {
        this.config = config;
        this.countText.setText("" + this.config.count *
            (this.config.multiplier ? this.config.multiplier : 1));
        this.icon.setTexture(MultiAtlases.Store.Atlas.Name, MultiAtlases.Store.Atlas.Frames["Icon" + upperCaseFirstLetter(this.config.type) + "Passive"]);
        this.multiplier && this.multiplier.destroy();
        this.shine && this.shine.destroy();
        this.config.multiplier &&
            this.config.multiplier !== 1 &&
            this.createMultiplier();
        this.config.multiplier &&
            this.config.multiplier !== 1 &&
            this.createShine();
    };
    StoreItemCount.prototype.createBody = function () {
        this.createBackground();
        this.config.multiplier !== 1 && this.createMultiplier();
        this.config.multiplier !== 1 && this.createShine();
        this.createCount();
        this.createIcon();
        var gap = 10;
        var totalWidth = this.icon.displayWidth + this.countText.width + gap;
        this.icon.x = -totalWidth / 2 + this.icon.displayWidth / 2;
        this.countText.x = this.icon.x + this.icon.displayWidth / 2 + gap;
        this.setSize(this.background.width, this.background.height);
    };
    StoreItemCount.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames[upperCaseFirstLetter(this.config.section) + "Ribbon"],
        });
        this.add(this.background);
    };
    StoreItemCount.prototype.createMultiplier = function () {
        this.multiplier = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames["X" + this.config.multiplier],
        });
        this.multiplier.x =
            -this.background.width / 2 + this.multiplier.width / 2 + 15;
        this.multiplier.y = -5;
        this.add(this.multiplier);
    };
    StoreItemCount.prototype.createShine = function () {
        this.shine = this.scene.make.image({
            x: this.multiplier.x - this.multiplier.width * 0.45,
            y: this.multiplier.y - this.multiplier.height / 2,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames.ShineX2Rib,
        });
        this.add(this.shine);
    };
    StoreItemCount.prototype.createIcon = function () {
        this.icon = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames["Icon" + upperCaseFirstLetter(this.config.type) + "Passive"],
        });
        this.icon.setScale(0.7);
        this.icon.setAngle(10);
        this.add(this.icon);
    };
    StoreItemCount.prototype.createCount = function () {
        this.countText = this.scene.make.bitmapText({
            x: 0,
            y: 0,
            text: "" + this.config.count *
                (this.config.multiplier ? this.config.multiplier : 1),
            font: BitmapFonts.RobotoSlab.Name,
            size: 85 * gameConfig.resolutionMultiplier,
        });
        this.countText.setOrigin(0, 0.6);
        this.add(this.countText);
    };
    return StoreItemCount;
}(Phaser.GameObjects.Container));
export default StoreItemCount;
