var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import IsAttackGuard from '../../Guards/socket/IsAttackGuard';
import IsDefendGuard from '../../Guards/socket/IsDefendGuard';
import IsSwapGuard from '../../Guards/socket/IsSwapGuard';
import IsValidCardGuard from '../../Guards/socket/IsValidCardGuard';
import SocketMacroCommand from '../SocketMacroCommand';
import AttackCardPlayedCommand from './AttackCardPlayedCommand';
import CardPlayFailedCommand from './CardPlayFailedCommand';
import DefendCardPlayedCommand from './DefendCardPlayedCommand';
import SwapCardPlayedCommand from './SwapCardPlayedCommand';
var CardPlayedCommand = /** @class */ (function (_super) {
    __extends(CardPlayedCommand, _super);
    function CardPlayedCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CardPlayedCommand.prototype.prepare = function () {
        this.addGuards(IsValidCardGuard);
    };
    CardPlayedCommand.prototype.initializeMacroCommand = function () {
        this.addExclusiveSubCommand(AttackCardPlayedCommand, IsAttackGuard);
        this.addExclusiveSubCommand(SwapCardPlayedCommand, IsSwapGuard);
        this.addExclusiveSubCommand(DefendCardPlayedCommand, IsDefendGuard);
        this.addExclusiveSubCommand(CardPlayFailedCommand);
    };
    CardPlayedCommand.prototype.onAnyGuardDenied = function () {
        this.sendNotification(CardPlayedCommand.FAILED_NOTIFICATION);
    };
    CardPlayedCommand.NAME = 'CardPlayedCommand';
    CardPlayedCommand.FAILED_NOTIFICATION = CardPlayedCommand.NAME + "FailedNotification";
    return CardPlayedCommand;
}(SocketMacroCommand));
export default CardPlayedCommand;
