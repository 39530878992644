var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AllEvents, RoomChannelHandlingEvent, } from '../../constants/SocketEvents';
import { decrypt } from '../../utils/Crypto';
import { blackLog } from '../../utils/Utils';
import { wrapRoomToStatePlayers } from '../helpers/utils';
import SocketVOProxy, { debugSocket } from '../SocketVOProxy';
import { CardPlayType, } from '../VO/SocketVO';
import ProxyHandler from './ProxyHandler';
var RoomChannelHandler = /** @class */ (function (_super) {
    __extends(RoomChannelHandler, _super);
    function RoomChannelHandler(proxy) {
        return _super.call(this, proxy) || this;
    }
    RoomChannelHandler.prototype.SEND_EMOJI = function (data) {
        var convertedData = {
            userId: this.proxy.getPlayerIndexById(data.userId),
            key: data.key,
            type: data.type,
        };
        this.sendNotification(SocketVOProxy.EMOJI_PUBLISHED_NOTIFICATION, convertedData);
    };
    RoomChannelHandler.prototype.FORCE_GAME_END = function (data) {
        var _this = this;
        var convertedRoundEndData = {
            endCase: data.cases,
            userIndex: this.proxy.getPlayerIndexById(data.defender),
            userPositions: data.usersInfo.positions.map(function (id) {
                return _this.proxy.getPlayerIndexById(id);
            }),
            gameEnd: data.gameEnded,
            forceEnd: data.forceEnd,
        };
        this.sendNotification(SocketVOProxy.GAME_ENDED_NOTIFICATION, convertedRoundEndData);
    };
    RoomChannelHandler.prototype.GAME_START_DATA = function (data) {
        this.proxy.vo.players = wrapRoomToStatePlayers(data.usersInfo, [], this.proxy.vo.userId);
        var deckData = data.deckData;
        this.sendNotification(SocketVOProxy.ROOM_USERS_INFO_READY_NOTIFICATION);
        this.sendNotification(SocketVOProxy.CLAIM_GAME_PLAY_PRICE_NOTIFICATION, data.roomType);
        this.onDeckChange(deckData, 0);
        this.onNextUser(data.nextUserData, 0);
    };
    RoomChannelHandler.prototype.USER_LEFT = function (userId) {
        this.sendNotification(SocketVOProxy.PLAYER_LEFT_ROOM_NOTIFICATION, this.proxy.getPlayerIndexById(userId));
    };
    RoomChannelHandler.prototype.PLAYED_CARD = function (data) {
        // collecting data
        var actionIndex = data.actionIndex;
        var playedCardData = data.playedCardData;
        var turnData = data.turnData;
        var nextUserData = data.nextUserData;
        var roundEndData = data.roundEndData;
        var deckData = data.deckData;
        // card play
        var convertedPlayedCardData = {
            userIndex: this.proxy.getPlayerIndexById(playedCardData.userId),
            isBot: playedCardData.isBot,
            card: decrypt(playedCardData.card),
            type: playedCardData.type,
        };
        switch (convertedPlayedCardData.type) {
            case CardPlayType.DEFENCE: {
                var pair = turnData.filter(function (pair) { return pair.defendedBy === playedCardData.card; })[0];
                convertedPlayedCardData.target = decrypt(pair.card);
            }
            case CardPlayType.ATTACK:
            case CardPlayType.SWAP:
                this.sendNotification(SocketVOProxy.CARD_PLAYED_NOTIFICATION, convertedPlayedCardData);
                break;
            case CardPlayType.PASS:
                this.sendNotification(SocketVOProxy.TURN_PASSED_NOTIFICATION, convertedPlayedCardData.userIndex);
                break;
            case CardPlayType.TAKE:
                this.sendNotification(SocketVOProxy.PLAYER_TAKES_NOTIFICATION, convertedPlayedCardData.userIndex);
                break;
            default:
                break;
        }
        !!roundEndData && this.onRoundEnd(roundEndData, actionIndex);
        !!deckData && this.onDeckChange(deckData, actionIndex);
        this.onNextUser(nextUserData, actionIndex);
    };
    RoomChannelHandler.prototype.handleEvents = function (_a) {
        var event = _a.event, args = __rest(_a, ["event"]);
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                if (debugSocket) {
                    blackLog("RoomChannelHandlingEvent." + AllEvents[event] + " = " + event, args);
                }
                switch (event) {
                    case RoomChannelHandlingEvent.GAME_START_DATA:
                        this.GAME_START_DATA(args);
                        break;
                    case RoomChannelHandlingEvent.PLAYED_CARD:
                        this.PLAYED_CARD(args);
                        break;
                    case RoomChannelHandlingEvent.USER_LEFT:
                        this.USER_LEFT(args.userId);
                        break;
                    case RoomChannelHandlingEvent.FORCE_GAME_END:
                        this.FORCE_GAME_END(args);
                        break;
                    case RoomChannelHandlingEvent.SEND_EMOJI:
                        this.SEND_EMOJI(args);
                        break;
                    default:
                        console.warn("RoomChannelHandlingEvent." + AllEvents[event] + " = " + event + " is not handled", { args: args });
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    RoomChannelHandler.prototype.onDeckChange = function (deckChangeData, actionIndex) {
        var _this = this;
        var convertedDeckData = {
            trump: decrypt(deckChangeData.trump),
            cardsCountAfterDeal: deckChangeData.deckCount,
            opponentsCardsToDeal: deckChangeData.users
                .filter(function (user) {
                return user.userId !== _this.proxy.vo.userId;
            })
                .map(function (user) {
                return {
                    userIndex: _this.proxy.getPlayerIndexById(user.userId),
                    totalCardsCount: user.newCardsCount,
                };
            })
                .sort(function (first, second) {
                return first.userIndex - second.userIndex;
            }),
        };
        if (convertedDeckData.trump) {
            this.sendNotification(SocketVOProxy.PREPARE_DECK_NOTIFICATION, convertedDeckData.trump);
            // this.sendFromQueue();
        }
        if (convertedDeckData.opponentsCardsToDeal.length > 0) {
            this.sendNotification(SocketVOProxy.SET_OPPONENTS_CARDS_TO_DEAL_NOTIFICATION, convertedDeckData.opponentsCardsToDeal);
        }
    };
    RoomChannelHandler.prototype.onRoundEnd = function (roundEndData, actionIndex) {
        var _this = this;
        var convertedRoundEndData = {
            endCase: roundEndData.cases,
            userIndex: this.proxy.getPlayerIndexById(roundEndData.defender),
            userPositions: roundEndData.userPositions.map(function (id) {
                return _this.proxy.getPlayerIndexById(id);
            }),
            gameEnd: roundEndData.gameEnded,
            forceEnd: roundEndData.forceEnd,
        };
        console.warn(convertedRoundEndData);
        this.sendNotification(SocketVOProxy.ROUND_ENDED_NOTIFICATION, convertedRoundEndData);
    };
    RoomChannelHandler.prototype.onNextUser = function (nextUserData, actionIndex) {
        var _this = this;
        var nextUser = {
            userId: this.proxy.getPlayerIndexById(nextUserData.nextUser),
            attackers: nextUserData.attackers.map(function (value) {
                return _this.proxy.getPlayerIndexById(value);
            }),
            defender: this.proxy.getPlayerIndexById(nextUserData.defender),
            timeout: nextUserData.timeout,
        };
        this.sendNotification(SocketVOProxy.ACTIVATE_NEXT_USER_NOTIFICATION, nextUser);
    };
    RoomChannelHandler.prototype.queueOrSend = function (name, actionId) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        if (this.proxy.vo.isDisconnected) {
            return;
        }
        var otherActionNotification = this.queue.find(function (notification) { return notification.actionId !== actionId; });
        if (otherActionNotification) {
            this.addToQueueByActionId.apply(this, [name, actionId].concat(args));
        }
        else {
            this.proxy.vo.actionIndex = actionId;
            this.sendNotification.apply(this, [name].concat(args));
        }
    };
    RoomChannelHandler.prototype.addToQueue = function (name, actionId) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        var notification = {
            name: name,
            actionId: actionId,
            args: args,
        };
        this.queue.push(notification);
    };
    RoomChannelHandler.prototype.addToQueueByActionId = function (name, actionId) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        var notification = {
            name: name,
            actionId: actionId,
            args: args,
        };
        var index = this.queue.findIndex(function (notification) { return notification.actionId !== actionId; });
        this.queue.addAt(index, notification);
        this.queue.sort(function (a, b) { return a.actionId - b.actionId; });
    };
    RoomChannelHandler.prototype.clearQueue = function () {
        this.queue = [];
    };
    return RoomChannelHandler;
}(ProxyHandler));
export default RoomChannelHandler;
