var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MultiAtlases } from '../../assets';
import GameFacade from '../../GameFacade';
import PlayerVOProxy from '../../model/PlayerVOProxy';
import SocketVOProxy from '../../model/SocketVOProxy';
import { isInGame, fetchAvatarByConfig, isNativeAvatar } from '../../utils/Utils';
import { removeTexture } from '../utils/phaser/PhaserUtils';
import BaseSceneMediator from './BaseSceneMediator';
import BootScene from './BootScene';
import PreloadScene from './PreloadScene';
var PreloadSceneMediator = /** @class */ (function (_super) {
    __extends(PreloadSceneMediator, _super);
    function PreloadSceneMediator() {
        return _super.call(this, PreloadSceneMediator.NAME, null) || this;
    }
    PreloadSceneMediator.prototype.onRegister = function () {
        _super.prototype.onRegister.call(this);
        this.sceneManager.add(PreloadScene.NAME, PreloadScene);
        this.setViewComponent(this.sceneManager.getScene(PreloadScene.NAME));
        this.setListeners();
    };
    PreloadSceneMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(BootScene.BOOT_COMPLETE_NOTIFICATION, GameFacade.NO_LOGIN_INFORMATION_NOTIFICATION, GameFacade.LOGIN_SERVICE_SIGH_IN_FAIL_NOTIFICATION, GameFacade.LOGIN_SERVICE_SIGH_IN_SUCCESS_NOTIFICATION, SocketVOProxy.USER_CHANNEL_WELCOME_COMPLETED_NOTIFICATION);
    };
    PreloadSceneMediator.prototype.handleNotification = function (notificationName) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, playerVOProxy, url;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = notificationName;
                        switch (_a) {
                            case BootScene.BOOT_COMPLETE_NOTIFICATION: return [3 /*break*/, 1];
                            case SocketVOProxy.USER_CHANNEL_WELCOME_COMPLETED_NOTIFICATION: return [3 /*break*/, 3];
                            case GameFacade.NO_LOGIN_INFORMATION_NOTIFICATION: return [3 /*break*/, 4];
                            case GameFacade.LOGIN_SERVICE_SIGH_IN_FAIL_NOTIFICATION: return [3 /*break*/, 6];
                            case GameFacade.LOGIN_SERVICE_SIGH_IN_SUCCESS_NOTIFICATION: return [3 /*break*/, 8];
                        }
                        return [3 /*break*/, 9];
                    case 1:
                        this.sceneManager.start(PreloadScene.NAME);
                        return [4 /*yield*/, this.fadeScreenIn(400)];
                    case 2:
                        _b.sent();
                        this.viewComponent.startLoadingAssets();
                        return [3 /*break*/, 10];
                    case 3:
                        playerVOProxy = this.facade.retrieveProxy(PlayerVOProxy.NAME);
                        this.onLoadComplete();
                        url = playerVOProxy.vo.avatarUrl;
                        if (isNativeAvatar(playerVOProxy.vo.avatarUrl)) {
                            url = playerVOProxy.vo.socialAvatarUrl;
                        }
                        fetchAvatarByConfig(this.viewComponent, {
                            userId: playerVOProxy.vo.userId,
                            avatarURL: url,
                        }, "playerDefaultAvatar");
                        return [3 /*break*/, 10];
                    case 4: return [4 /*yield*/, this.viewComponent.hideLoadingBar()];
                    case 5:
                        _b.sent();
                        this.viewComponent.showSignInWith();
                        return [3 /*break*/, 10];
                    case 6: return [4 /*yield*/, this.viewComponent.hideLoadingBar()];
                    case 7:
                        _b.sent();
                        this.viewComponent.showSignInWith();
                        return [3 /*break*/, 10];
                    case 8:
                        this.viewComponent.showRetrievingUserData();
                        return [3 /*break*/, 10];
                    case 9:
                        if (this.defaultNotifications.contains(notificationName)) {
                            return [3 /*break*/, 10];
                        }
                        console.warn(notificationName + " is unhandled!");
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    PreloadSceneMediator.prototype.onSceneShutdown = function () {
        removeTexture(MultiAtlases.Loading.Atlas.Name);
    };
    PreloadSceneMediator.prototype.onSceneDestroy = function () {
        this.facade.removeMediator(PreloadSceneMediator.NAME, this.id);
    };
    PreloadSceneMediator.prototype.setListeners = function () {
        this.viewComponent.events.on(PreloadScene.FILE_LOAD_EVENT, this.onFileLoad, this);
        this.viewComponent.events.on(PreloadScene.MAIN_ASSETS_LOAD_COMPLETE_EVENT, this.onMainAssetsLoadComplete, this);
        this.viewComponent.events.on(PreloadScene.MODE_CLICK_EVENT, this.onModeClick, this);
    };
    PreloadSceneMediator.prototype.onLoadComplete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!isInGame()) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.viewComponent.onProgress(1)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.viewComponent.playLogoAnimation()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.fadeScreenOut(0x000000, isInGame() ? 200 : 600, 400)];
                    case 4:
                        _a.sent();
                        this.sceneManager.stop(PreloadScene.NAME);
                        this.facade.sendNotification(PreloadScene.LOAD_COMPLETE_NOTIFICATION);
                        return [2 /*return*/];
                }
            });
        });
    };
    PreloadSceneMediator.prototype.onFileLoad = function (progress) {
        this.viewComponent.onProgress(progress);
    };
    PreloadSceneMediator.prototype.onModeClick = function (key) {
        this.viewComponent.hideSignInWith();
        this.sendNotification(PreloadScene.LOGIN_SERVICE_CHOSE_NOTIFICATION, key);
    };
    PreloadSceneMediator.prototype.onMainAssetsLoadComplete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.generateTextures()];
                    case 1:
                        _a.sent();
                        this.viewComponent.setConnectText();
                        this.sendNotification(PreloadScene.WAITING_FOR_API_NOTIFICATION);
                        return [2 /*return*/];
                }
            });
        });
    };
    PreloadSceneMediator.prototype.generateTextures = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.viewComponent.generateTextures()];
                    case 1:
                        _a.sent();
                        this.sendNotification(PreloadScene.TEXTURE_GENERATION_COMPLETE_NOTIFICATION);
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(PreloadSceneMediator.prototype, "playerVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(PlayerVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    PreloadSceneMediator.NAME = 'PreloadSceneMediator';
    return PreloadSceneMediator;
}(BaseSceneMediator));
export default PreloadSceneMediator;
