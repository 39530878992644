var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Currency } from '../../model/VO/PlayerVO';
import PlayerCommand from './PlayerCommand';
var ApplyClaimedRewardCommand = /** @class */ (function (_super) {
    __extends(ApplyClaimedRewardCommand, _super);
    function ApplyClaimedRewardCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ApplyClaimedRewardCommand.prototype.execute = function (notificationName, updateConfig) {
        var keys = Object.keys(updateConfig);
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key = keys_1[_i];
            switch (key) {
                case Currency.GOLD:
                    this.proxy.forceUpdateCurrency(Currency.GOLD, updateConfig[key].after);
                    break;
                case Currency.SILVER:
                    this.proxy.forceUpdateCurrency(Currency.SILVER, updateConfig[key].after);
                    break;
                case Currency.CRYSTAL:
                    this.proxy.forceUpdateCurrency(Currency.CRYSTAL, updateConfig[key].after);
                    break;
                case 'xp':
                    this.proxy.forceUpdateExperience(updateConfig.xp.after);
                    break;
                default:
                    break;
            }
        }
    };
    return ApplyClaimedRewardCommand;
}(PlayerCommand));
export default ApplyClaimedRewardCommand;
