var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../../assets';
import { SpriteButton } from '../../../utils/simpleButton/SpriteButton';
import ControlView, { ControlAction } from '../ControlView';
var ControlLeaveButton = /** @class */ (function (_super) {
    __extends(ControlLeaveButton, _super);
    function ControlLeaveButton(scene) {
        var _this = this;
        var normalStateConfig = {
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.BackButtonNormal,
        };
        var hoverStateConfig = {
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.BackButtonHover,
        };
        var downStateConfig = {
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.BackButtonDown,
        };
        var configs = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        _this = _super.call(this, scene, configs) || this;
        _this.setEnabled(true);
        return _this;
    }
    ControlLeaveButton.prototype.onClick = function () {
        this.parentContainer.emit(ControlView.ACTION_DONE_EVENT, ControlAction.BACK);
    };
    ControlLeaveButton.prototype.makeDisabled = function () {
        _super.prototype.makeDisabled.call(this);
        this.setAlpha(0.5);
    };
    ControlLeaveButton.prototype.makeEnabled = function () {
        _super.prototype.makeEnabled.call(this);
        this.setAlpha(1);
    };
    return ControlLeaveButton;
}(SpriteButton));
export default ControlLeaveButton;
