var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Atlases, Audios, Fonts, MultiAtlases, Spines } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { Translation } from '../../translations';
import { getPlatform } from '../../utils/CordovaUtils';
import { addNinePatchDefaults } from '../components/preload/NinePatchConfigs';
import { generateGamePlayTextures } from '../textureGeneration/game/GemePlayTexturesIndex';
import { generateLobbySceneTextures } from '../textureGeneration/lobby/LobbySceneTexturesIndex';
import { generateTopBarTextures } from '../textureGeneration/topbar/TopBarTexturesIndex';
import { loadAtlases, loadAudios, loadMultiAtlases, loadSpines, } from '../utils/assetLoader';
import SpineMaker from '../utils/plugins/spine/SpineMaker';
import BaseScene from './BaseScene';
export var SignInMode;
(function (SignInMode) {
    SignInMode[SignInMode["NONE"] = -1] = "NONE";
    SignInMode[SignInMode["FACEBOOK"] = 0] = "FACEBOOK";
    SignInMode[SignInMode["NATIVE"] = 1] = "NATIVE";
    SignInMode[SignInMode["VK"] = 2] = "VK";
    SignInMode[SignInMode["OK"] = 3] = "OK";
})(SignInMode || (SignInMode = {}));
var PreloadScene = /** @class */ (function (_super) {
    __extends(PreloadScene, _super);
    function PreloadScene() {
        var _this = _super.call(this, PreloadScene.NAME) || this;
        _this.progressValue = 0;
        return _this;
    }
    PreloadScene.prototype.preload = function () {
        addNinePatchDefaults(this.game);
        this.setListeners();
        this.createBackground();
        this.createLogoSpine();
        this.createProgressBar();
        this.createLoadingText();
        this.createSignInModes();
    };
    PreloadScene.prototype.generateTextures = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.text.setText(Translation.PRELOAD_GENERATE_TEXTURES);
                        return [4 /*yield*/, generateLobbySceneTextures()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, generateTopBarTextures()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, generateGamePlayTextures()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PreloadScene.prototype.playLogoAnimation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.playSFX(Audios.LogoSpineSfx.Name);
                        _this.tweens.killTweensOf([_this.text, _this.logoSpine]);
                        _this.hideLoadingBar();
                        _this.logoSpine.play(Spines.LogoSpine.Spine.Animations.Play, false);
                        _this.logoSpine.once(Phaser.Animations.Events.ANIMATION_COMPLETE, resolve);
                    })];
            });
        });
    };
    PreloadScene.prototype.startLoadingAssets = function () {
        loadMultiAtlases(this, MultiAtlases);
        loadAtlases(this, Atlases);
        loadSpines(this, Spines);
        loadAudios(this, Audios);
        this.load.start();
        this.startProgressBlinking();
        this.load.on(Phaser.Loader.Events.PROGRESS, this.emitProgressChanged, this);
        this.load.on(Phaser.Loader.Events.COMPLETE, this.emitLoadComplete, this);
    };
    PreloadScene.prototype.onProgress = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.tweens.killTweensOf(_this);
                        _this.tweens.add({
                            targets: _this,
                            progressValue: value,
                            duration: 100,
                            onUpdate: function () {
                                _this.progressFill.width =
                                    _this.progressValue * _this.progressFillTexture.width;
                                _this.progressFill.setOrigin(0, 0.5);
                            },
                            onComplete: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (value === 1) {
                                        resolve();
                                    }
                                    return [2 /*return*/];
                                });
                            }); },
                        });
                    })];
            });
        });
    };
    PreloadScene.prototype.hideLoadingBar = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.tweens.killTweensOf([
                            _this.progressBackground,
                            _this.progressBorders,
                            _this.progressFill,
                            _this.text,
                        ]);
                        _this.tweens.add({
                            targets: [
                                _this.progressBackground,
                                _this.progressBorders,
                                _this.progressFill,
                                _this.text,
                            ],
                            alpha: 0,
                            duration: 200,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    PreloadScene.prototype.showSignInWith = function () {
        var _this = this;
        this.text.setText(Translation.PRELOAD_LOGIN_WITH);
        this.text.y = this.separator.y - this.text.height;
        this.text.setTint(0xe4c02a);
        this.text.setFontSize(40 * gameConfig.resolutionMultiplier);
        this.separator.scaleX = 0;
        this.separator.setVisible(true);
        this.tweens.killTweensOf([this.text, this.separator].concat(this.loginModes));
        this.tweens.add({
            targets: this.separator,
            scaleX: 1,
            duration: 100,
            onComplete: function () {
                _this.text.setVisible(true);
                _this.tweens.add({ targets: _this.text, alpha: 1, duration: 100 });
                for (var _i = 0, _a = _this.loginModes; _i < _a.length; _i++) {
                    var icon = _a[_i];
                    icon.y = _this.separator.y - icon.height;
                    icon.setVisible(true);
                    icon.input.enabled = true;
                }
                _this.tweens.add({
                    targets: _this.loginModes,
                    y: _this.separator.y + _this.loginModes[0].displayHeight * 0.82,
                    duration: 300,
                    onComplete: function () {
                        for (var _i = 0, _a = _this.loginModes; _i < _a.length; _i++) {
                            var mode = _a[_i];
                            mode.input.enabled = true;
                        }
                    },
                });
            },
        });
    };
    PreloadScene.prototype.hideSignInWith = function () {
        var _this = this;
        for (var _i = 0, _a = this.loginModes; _i < _a.length; _i++) {
            var mode = _a[_i];
            mode.input.enabled = false;
        }
        this.tweens.killTweensOf([this.text, this.separator].concat(this.loginModes));
        this.tweens.add({
            targets: this.text,
            alpha: 0,
            duration: 100,
        });
        this.tweens.add({
            targets: this.loginModes,
            y: this.separator.y - this.loginModes[0].displayHeight,
            duration: 200,
            onComplete: function () {
                _this.tweens.add({
                    targets: _this.separator,
                    scaleX: 0,
                    duration: 100,
                    ease: Phaser.Math.Easing.Back.In,
                });
            },
        });
    };
    PreloadScene.prototype.setConnectText = function () {
        this.text.setText(Translation.PRELOAD_CONNECT);
    };
    PreloadScene.prototype.showRetrievingUserData = function () {
        var _this = this;
        this.text.setText(Translation.PRELOAD_RETRIEVING);
        this.tweens.add({
            targets: this.text,
            alpha: 1,
            duration: 200,
            onComplete: function () {
                _this.tweens.add({
                    targets: _this.text,
                    alpha: 0.6,
                    duration: 1500,
                    yoyo: true,
                    repeat: -1,
                });
            },
        });
    };
    PreloadScene.prototype.emitLoadComplete = function () {
        this.tweens.add({
            targets: this.text,
            alpha: 0.7,
            duration: 1000,
            yoyo: true,
            repeat: -1,
        });
        this.onProgress(1);
        this.events.emit(PreloadScene.MAIN_ASSETS_LOAD_COMPLETE_EVENT);
    };
    PreloadScene.prototype.emitProgressChanged = function (progress) {
        this.events.emit(PreloadScene.FILE_LOAD_EVENT, progress);
    };
    PreloadScene.prototype.setListeners = function () {
        this.load.on(Phaser.Loader.Events.PROGRESS, this.onProgress, this);
    };
    PreloadScene.prototype.createBackground = function () {
        this.background = this.add.image(0, gameConfig.canvasHeight, MultiAtlases.Loading.Atlas.Name, MultiAtlases.Loading.Atlas.Frames.Background);
        this.background.setOrigin(0, 1);
        this.background.setScale(Math.max(gameConfig.canvasHeight / this.background.height, Math.max(gameConfig.canvasWidth / this.background.width)));
    };
    PreloadScene.prototype.createLoadingText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextMedium.Name,
            fontSize: 35 * gameConfig.resolutionMultiplier,
            fill: '#b98a0a',
        };
        this.text = this.add.extText(this.width / 2, this.progressBackground.y + this.progressBackground.displayHeight * 0.61, Translation.PRELOAD_LOADING, style);
        this.text.setOrigin(0.5);
        this.text.setScale(1);
    };
    PreloadScene.prototype.createLogoSpine = function () {
        this.logoSpine = SpineMaker.add(this, this.width / 2, this.height * 0.356 + gameConfig.designHeight * 0.079, Spines.LogoSpine.Spine.Name, Spines.LogoSpine.Spine.Animations.Static, false);
        this.logoSpine.setScale(gameConfig.resolutionMultiplier * 1.1);
    };
    PreloadScene.prototype.createSignInModes = function () {
        this.loginModes = [];
        this.separator = this.add.sprite(this.progressBackground.x, this.progressBackground.y - this.progressBackground.displayHeight * 0.1, MultiAtlases.Loading.Atlas.Name, MultiAtlases.Loading.Atlas.Frames.Line);
        this.separator.setVisible(false);
        var y = this.progressBackground.y - gameConfig.designHeight * 0.08;
        this.nativeIcon = this.add.sprite(this.progressBackground.x, y, MultiAtlases.Loading.Atlas.Name, getPlatform() !== 'ios'
            ? MultiAtlases.Loading.Atlas.Frames.PlayServices
            : MultiAtlases.Loading.Atlas.Frames.IosAppleIcon);
        this.facebookIcon = this.add.sprite(this.progressBackground.x, y, MultiAtlases.Loading.Atlas.Name, getPlatform() !== 'ios'
            ? MultiAtlases.Loading.Atlas.Frames.Facebook
            : MultiAtlases.Loading.Atlas.Frames.IosFacebookIcon);
        this.okIcon = this.add.sprite(this.progressBackground.x, y, MultiAtlases.Loading.Atlas.Name, getPlatform() !== 'ios'
            ? MultiAtlases.Loading.Atlas.Frames.Ok
            : MultiAtlases.Loading.Atlas.Frames.IosOkIcon);
        if (getPlatform() !== 'browser') {
            this.loginModes.push(this.nativeIcon);
        }
        else {
            this.nativeIcon.setVisible(false);
        }
        this.loginModes.push(this.facebookIcon);
        this.loginModes.push(this.okIcon);
        var width = this.facebookIcon.width;
        var line = new Phaser.Geom.Line(this.separator.x -
            this.separator.displayWidth / 2 -
            this.loginModes[0].displayWidth / 2, y, this.separator.x +
            this.separator.displayWidth / 2 +
            this.loginModes[0].displayWidth / 2, y);
        var points = line.getPoints(this.loginModes.length + 1);
        points.shift();
        for (var i = 0; i < this.loginModes.length; i++) {
            this.loginModes[i].x = points[i].x;
            this.loginModes[i].y = points[i].y;
        }
        var graphics = this.make.graphics({});
        graphics.fillRect(this.separator.x - this.separator.width / 2, this.separator.y + this.separator.height, this.separator.width, width * 1.5);
        for (var _i = 0, _a = this.loginModes; _i < _a.length; _i++) {
            var icon = _a[_i];
            icon.setInteractive();
            icon.setVisible(false);
            icon.input.enabled = false;
            icon.setMask(graphics.createGeometryMask());
        }
        this.facebookIcon.on(Phaser.Input.Events.POINTER_UP, this.onModeClick.bind(this, SignInMode.FACEBOOK));
        this.nativeIcon.on(Phaser.Input.Events.POINTER_UP, this.onModeClick.bind(this, SignInMode.NATIVE));
        this.okIcon.on(Phaser.Input.Events.POINTER_UP, this.onModeClick.bind(this, SignInMode.OK));
    };
    PreloadScene.prototype.createProgressBar = function () {
        this.progressBackground = this.add.sprite(gameConfig.canvasWidth / 2, this.logoSpine.y + gameConfig.canvasHeight * 0.32, MultiAtlases.Loading.Atlas.Name, MultiAtlases.Loading.Atlas.Frames.ProgressBackground);
        this.progressBorders = this.add.sprite(this.progressBackground.x, this.progressBackground.y, MultiAtlases.Loading.Atlas.Name, MultiAtlases.Loading.Atlas.Frames.ProgressFillFrame);
        this.progressBorders.depth = 1;
        this.progressFill = this.add.tileSprite(this.progressBorders.x - this.progressFillTexture.width / 2, this.progressBorders.y, 1, this.progressFillTexture.height, MultiAtlases.Loading.Atlas.Name, MultiAtlases.Loading.Atlas.Frames.ProgressFill);
        this.progressFill.setOrigin(0, 0.5);
    };
    PreloadScene.prototype.onModeClick = function (mode) {
        this.events.emit(PreloadScene.MODE_CLICK_EVENT, mode);
    };
    PreloadScene.prototype.startProgressBlinking = function () {
        this.tweens.add({
            targets: this.progressFill,
            alpha: 0.8,
            yoyo: true,
            repeat: -1,
        });
    };
    Object.defineProperty(PreloadScene.prototype, "progressFillTexture", {
        get: function () {
            return this.textures.getFrame(MultiAtlases.Loading.Atlas.Name, MultiAtlases.Loading.Atlas.Frames.ProgressFill);
        },
        enumerable: true,
        configurable: true
    });
    PreloadScene.NAME = 'PreloadScene';
    PreloadScene.LOAD_COMPLETE_NOTIFICATION = PreloadScene.NAME + "LoadCompleteNotification";
    PreloadScene.TEXTURE_GENERATION_COMPLETE_NOTIFICATION = PreloadScene.NAME + "TextureGenerationCompleteNotification";
    PreloadScene.WAITING_FOR_API_NOTIFICATION = PreloadScene.NAME + "WaitingForAPINotification";
    PreloadScene.LOGIN_SERVICE_CHOSE_NOTIFICATION = PreloadScene.NAME + "LoginServiceChoseNotification";
    PreloadScene.FILE_LOAD_EVENT = "fileLoad";
    PreloadScene.MAIN_ASSETS_LOAD_COMPLETE_EVENT = "mainAssetsLoadComplete";
    PreloadScene.MODE_CLICK_EVENT = 'modeClick';
    return PreloadScene;
}(BaseScene));
export default PreloadScene;
