export var Orientation;
(function (Orientation) {
    Orientation[Orientation["PORTRAIT"] = 0] = "PORTRAIT";
    Orientation[Orientation["LANDSCAPE"] = 1] = "LANDSCAPE";
})(Orientation || (Orientation = {}));
export var gameConfig = {
    designWidth: 1920,
    designHeight: 1080,
    canvasWidth: 1920,
    canvasHeight: 1080,
    designRatio: 1,
    deviceRatio: 1,
    orientation: Orientation.LANDSCAPE,
    resolutionMultiplier: 1,
};
