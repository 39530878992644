var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Proxy } from '@candywings/pure-mvc';
import { ValidationActionType, } from './VO/SocketVO';
import StateVO from './VO/StateVO';
var StateVOProxy = /** @class */ (function (_super) {
    __extends(StateVOProxy, _super);
    function StateVOProxy() {
        return _super.call(this, StateVOProxy.NAME, new StateVO()) || this;
    }
    StateVOProxy.prototype.clearValidCards = function () {
        this.vo.turnData.validatedCards = [];
        this.vo.turnData.action = ValidationActionType.DISABLED;
    };
    StateVOProxy.prototype.getValidCardData = function (card) {
        return this.vo.turnData.validatedCards.find(function (data) {
            return data.card.suit === card.suit && data.card.value === card.value;
        });
    };
    StateVOProxy.prototype.clearData = function () {
        this.vo = new StateVO();
        this.sendNotification(StateVOProxy.CLEARED_NOTIFICATION);
    };
    StateVOProxy.prototype.onRegister = function () {
        _super.prototype.onRegister.call(this);
        this.sendNotification(StateVOProxy.INITIALIZE_SUCCESS_NOTIFICATION);
    };
    StateVOProxy.prototype.initForceData = function (data) {
        this.vo = data;
        this.vo.reconnected = false;
        this.sendNotification(StateVOProxy.FORCE_DATA_INITIALIZED_NOTIFICATION);
    };
    StateVOProxy.prototype.initDeck = function (deckData) {
        this.vo.deckData = deckData;
    };
    StateVOProxy.prototype.initPlayers = function (players) {
        this.vo.players = players;
        this.vo.isGameStarted = true;
        this.sendNotification(StateVOProxy.PLAYERS_READY_NOTIFICATION);
    };
    StateVOProxy.prototype.applyDealing = function () {
        var _a;
        for (var i = 0; i < this.vo.deckData.cardsToDealToPlayers.length; i++) {
            (_a = this.vo.players[i].cards).push.apply(_a, this.vo.deckData.cardsToDealToPlayers[i]);
        }
        this.sendNotification(StateVOProxy.START_DEALING_NOTIFICATION);
    };
    StateVOProxy.prototype.applyCardPlay = function (data) {
        var player = this.vo.players[data.userIndex];
        if (data.userIndex === 3) {
            player.cards.splice(player.cards.indexOf(data.card), 1);
        }
        else {
            player.cards.pop();
        }
        data.isBot &&
            !player.isBot &&
            this.sendNotification(StateVOProxy.USER_BECOME_BOT_NOTIFICATION);
        !data.isBot &&
            player.isBot &&
            this.sendNotification(StateVOProxy.USER_RETURN_NOTIFICATION);
        player.isBot = data.isBot;
        if (data.target) {
            var targetPair = this.vo.turnData.cardPairs.filter(function (pair) { return pair.attack === data.target; })[0];
            targetPair.defence = data.card;
        }
        else {
            this.vo.turnData.cardPairs.push({
                attack: data.card,
                defence: null,
            });
        }
        this.vo.turnData.validatedCards = [];
        this.sendNotification(StateVOProxy.CARD_PLAYED_NOTIFICATION, data);
    };
    StateVOProxy.prototype.applyNextUser = function (nextUser) {
        for (var _i = 0, _a = this.vo.players; _i < _a.length; _i++) {
            var player = _a[_i];
            player.isAttacker = false;
            player.isDefender = false;
        }
        for (var _b = 0, _c = nextUser.attackers; _b < _c.length; _b++) {
            var id = _c[_b];
            var player = this.vo.players[id];
            player.isAttacker = true;
            player.isDefender = false;
        }
        this.vo.players[nextUser.defender].isAttacker = false;
        this.vo.players[nextUser.defender].isDefender = true;
        var isUserChanged = this.vo.turnData.currentPlayer.userId != null ?
            this.vo.turnData.currentPlayer.userId != nextUser.userId : false;
        this.vo.turnData.currentPlayer = nextUser;
        if (nextUser.userId !== 3) {
            this.clearValidCards();
        }
        this.sendNotification(StateVOProxy.NEXT_PLAYER_NOTIFICATION, isUserChanged);
    };
    StateVOProxy.prototype.applyCardValidation = function (validCards, action) {
        if (action === void 0) { action = ValidationActionType.DISABLED; }
        this.vo.turnData.validatedCards = validCards;
        this.vo.turnData.action = action;
        this.sendNotification(StateVOProxy.VALIDATE_CARDS_NOTIFICATION, validCards);
    };
    StateVOProxy.prototype.applyBita = function () {
        this.vo.turnData.validatedCards = [];
        this.vo.bitaCardsCount += this.turnCards.length;
        this.vo.turnData.cardPairs = [];
        this.sendNotification(StateVOProxy.BITA_NOTIFICATION);
    };
    StateVOProxy.prototype.applyTake = function (userIndex) {
        var _a;
        this.vo.turnData.validatedCards = [];
        var player = this.vo.players[userIndex];
        if (userIndex === 3) {
            (_a = player.cards).push.apply(_a, this.turnCards);
        }
        else {
            for (var i = 0; i < this.turnCards.length; i++) {
                player.cards.push(null);
            }
        }
        this.vo.turnData.cardPairs = [];
        this.sendNotification(StateVOProxy.PLAYER_TOOK_NOTIFICATION, userIndex);
    };
    StateVOProxy.prototype.applyPositions = function (positions) {
        var _this = this;
        var notAppliedPositions = positions.filter(function (position) { return !_this.vo.positions.contains(position); });
        this.vo.positions = positions;
        for (var _i = 0, notAppliedPositions_1 = notAppliedPositions; _i < notAppliedPositions_1.length; _i++) {
            var userIndex = notAppliedPositions_1[_i];
            var position = this.vo.positions.indexOf(userIndex);
            position !== this.vo.players.length - 1 &&
                this.sendNotification(StateVOProxy.SET_PLAYER_POSITION_NOTIFICATION, userIndex, position);
        }
        if (this.vo.positions.length === this.vo.players.length) {
            this.vo.isGameEnded = true;
            this.vo.isForceGameEnded = false;
            this.vo.gameEndMoment = Date.now();
            this.sendNotification(StateVOProxy.GAME_OVER_NOTIFICATION);
        }
    };
    StateVOProxy.prototype.applyPlayerGameEndData = function (data) {
        this.sendNotification(StateVOProxy.PLAYER_COMPLETE_GAME_NOTIFICATION, data.position, data.isWinner);
    };
    StateVOProxy.prototype.applyForceGameEnd = function (data) {
        this.vo.isGameEnded = true;
        this.vo.isForceGameEnded = true;
        this.vo.gameEndMoment = Date.now();
        this.applyPositions(data.userPositions);
        this.sendNotification(StateVOProxy.GAME_OVER_NOTIFICATION);
    };
    StateVOProxy.prototype.turnUserIntoBot = function (index) {
        this.vo.players[index].isBot = true;
    };
    StateVOProxy.prototype.isCompleted = function (index) {
        return this.vo.positions.contains(this.vo.players[index].userId);
    };
    StateVOProxy.prototype.getCurrentPlayerPosition = function () {
        return this.vo.positions.indexOf(3) + 1;
    };
    StateVOProxy.prototype.getBotsCount = function () {
        var botPlayers = this.vo.players.filter(function (player) { return player.isBot; });
        return botPlayers.length;
    };
    StateVOProxy.prototype.setReconnectState = function (isReconnected) {
        this.vo.reconnected = isReconnected;
    };
    StateVOProxy.prototype.isUserEndedTheGame = function (index) {
        return this.vo.positions.contains(index);
    };
    Object.defineProperty(StateVOProxy.prototype, "turnCards", {
        get: function () {
            var cards = [];
            for (var _i = 0, _a = this.vo.turnData.cardPairs; _i < _a.length; _i++) {
                var pair = _a[_i];
                cards.push(pair.attack);
                !!pair.defence && cards.push(pair.defence);
            }
            return cards;
        },
        enumerable: true,
        configurable: true
    });
    StateVOProxy.NAME = 'StateVOProxy';
    StateVOProxy.INITIALIZE_SUCCESS_NOTIFICATION = StateVOProxy.NAME + "InitializeSuccessNotification";
    StateVOProxy.CLEARED_NOTIFICATION = StateVOProxy.NAME + "ClearedNotification";
    StateVOProxy.CARD_PLAYED_NOTIFICATION = StateVOProxy.NAME + "CardPlayedNotification";
    StateVOProxy.NEXT_PLAYER_NOTIFICATION = StateVOProxy.NAME + "NextPlayerNotification";
    StateVOProxy.VALIDATE_CARDS_NOTIFICATION = StateVOProxy.NAME + "ValidateCardsNotification";
    StateVOProxy.PLAYERS_READY_NOTIFICATION = StateVOProxy.NAME + "PlayersReadyNotification";
    StateVOProxy.PLAYER_TOOK_NOTIFICATION = StateVOProxy.NAME + "PlayerTookNotification";
    StateVOProxy.BITA_NOTIFICATION = StateVOProxy.NAME + "BitaNotification";
    StateVOProxy.START_DEALING_NOTIFICATION = StateVOProxy.NAME + "StartDealingNotification";
    StateVOProxy.SET_PLAYER_POSITION_NOTIFICATION = StateVOProxy.NAME + "SetPlayerPositionNotification";
    StateVOProxy.PLAYER_COMPLETE_GAME_NOTIFICATION = StateVOProxy.NAME + "PlayerCompleteGameNotification";
    StateVOProxy.GAME_OVER_NOTIFICATION = StateVOProxy.NAME + "GameOverNotification";
    StateVOProxy.FORCE_DATA_INITIALIZED_NOTIFICATION = StateVOProxy.NAME + "ForceDataInitializedNotification";
    StateVOProxy.USER_BECOME_BOT_NOTIFICATION = StateVOProxy.NAME + "UserBecomeBotNotification";
    StateVOProxy.USER_RETURN_NOTIFICATION = StateVOProxy.NAME + "UserReturnNotification";
    return StateVOProxy;
}(Proxy));
export default StateVOProxy;
