import store from 'store';
export var storageNamespace = 'kings_of_durak';
var storage = store.namespace(storageNamespace);
export function setDataToStorage(key, value) {
    storage.set(key, value);
}
export function getDataFromStorage(key, defaultValue) {
    return storage.get(key, defaultValue);
}
export var StorageKey;
(function (StorageKey) {
    StorageKey["LAST_GAME_JOIN"] = "lastGameJoin";
    StorageKey["GAME_CENTER"] = "gameCenter";
    StorageKey["PLAY_SERVICE"] = "playService";
    StorageKey["OK_TOKEN"] = "ok_token";
    StorageKey["OK_SESSION"] = "ok_session";
    StorageKey["VK_TOKEN"] = "ok_token";
    StorageKey["VK_USER_ID"] = "ok_session";
    StorageKey["SETTINGS"] = "settings";
    StorageKey["AVATAR"] = "avatar";
    StorageKey["TEST"] = "test";
    StorageKey["IS_LOG_OUT"] = "is_log_out";
})(StorageKey || (StorageKey = {}));
