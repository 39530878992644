var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Images } from '../../assets';
import ApiVOProxy from '../../model/ApiVOProxy';
import PlayerVOProxy from '../../model/PlayerVOProxy';
import { Gender, Setting } from '../../model/VO/PlayerVO';
import { getFramesCountByString } from '../../utils/Utils';
import { KeyboardEvent } from '../../utils/wrappers/CordovaKeyboard';
import { FinalizeProfileSceneAction } from '../components/registration/RegistrationProfileInfoSection';
import BaseSceneMediator from './BaseSceneMediator';
import PreloadScene from './PreloadScene';
import RegistrationScene from './RegistrationScene';
var RegistrationSceneMediator = /** @class */ (function (_super) {
    __extends(RegistrationSceneMediator, _super);
    function RegistrationSceneMediator() {
        return _super.call(this, RegistrationSceneMediator.NAME, null) || this;
    }
    RegistrationSceneMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(PreloadScene.LOAD_COMPLETE_NOTIFICATION, PlayerVOProxy.UPDATE_GANDER_NOTIFICATION, RegistrationScene.AVATAR_CHANGED_NOTIFICATION, ApiVOProxy.USER_PROFILE_DATA_UPDATED_NOTIFICATION);
    };
    RegistrationSceneMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, avatarKeys, keys, key, avatarKeys_1, keys_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = notificationName;
                        switch (_a) {
                            case PreloadScene.LOAD_COMPLETE_NOTIFICATION: return [3 /*break*/, 1];
                            case PlayerVOProxy.UPDATE_GANDER_NOTIFICATION: return [3 /*break*/, 3];
                            case RegistrationScene.AVATAR_CHANGED_NOTIFICATION: return [3 /*break*/, 4];
                            case ApiVOProxy.USER_PROFILE_DATA_UPDATED_NOTIFICATION: return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 7];
                    case 1:
                        if (!this.playerVOProxy.vo.isNewUser) {
                            this.facade.removeMediator(RegistrationSceneMediator.NAME);
                            return [3 /*break*/, 8];
                        }
                        this.setKeyboardPluginListeners();
                        this.sceneManager.start(RegistrationScene.NAME, {
                            avatarsCounts: {
                                male: getFramesCountByString(Images, 'Male'),
                                female: getFramesCountByString(Images, 'Female'),
                            },
                            name: this.playerVOProxy.vo.name,
                        });
                        return [4 /*yield*/, this.fadeScreenIn(200)];
                    case 2:
                        _b.sent();
                        this.viewComponent.showLanguageSection();
                        this.sendNotification(RegistrationScene.SHOWN_NOTIFICATION);
                        return [3 /*break*/, 8];
                    case 3:
                        avatarKeys = this.viewComponent.getKeys();
                        keys = this.playerVOProxy.vo.gender === Gender.MALE
                            ? avatarKeys.male
                            : this.playerVOProxy.vo.gender === Gender.FEMALE
                                ? avatarKeys.female
                                : avatarKeys.uni;
                        this.sendNotification(RegistrationScene.AVATAR_CHANGED_NOTIFICATION, keys[0]);
                        this.viewComponent.showMessageAndNextButton();
                        this.viewComponent.updateGenderRadios(this.playerVOProxy.vo.gender);
                        this.viewComponent.updateProfileGender(this.playerVOProxy.vo.gender);
                        return [3 /*break*/, 8];
                    case 4:
                        {
                            key = args[0];
                            avatarKeys_1 = this.viewComponent.getKeys();
                            keys_1 = this.playerVOProxy.vo.gender === Gender.MALE
                                ? avatarKeys_1.male
                                : this.playerVOProxy.vo.gender === Gender.FEMALE
                                    ? avatarKeys_1.female
                                    : avatarKeys_1.uni;
                            this.viewComponent.updateProfileAvatar(this.playerVOProxy.vo.gender, keys_1.contains(key) ? keys_1.indexOf(key) : keys_1.length - 1);
                        }
                        return [3 /*break*/, 8];
                    case 5: return [4 /*yield*/, this.fadeScreenOut(0x000000, 300)];
                    case 6:
                        _b.sent();
                        this.sceneManager.stop(RegistrationScene.NAME);
                        this.sendNotification(RegistrationScene.HIDDEN_NOTIFICATION);
                        this.facade.removeMediator(RegistrationSceneMediator.NAME);
                        return [3 /*break*/, 8];
                    case 7:
                        if (this.defaultNotifications.contains(notificationName)) {
                            return [3 /*break*/, 8];
                        }
                        console.warn(notificationName + " is unhandled!");
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    RegistrationSceneMediator.prototype.setView = function () {
        var finalizeProfileScene = new RegistrationScene();
        this.sceneManager.add(RegistrationScene.NAME, finalizeProfileScene);
        this.setViewComponent(finalizeProfileScene);
        _super.prototype.setView.call(this);
    };
    RegistrationSceneMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.events.on(RegistrationScene.LANGUAGE_ACTION_EVENT, this.onLanguageAction, this);
        this.viewComponent.events.on(RegistrationScene.PROFILE_ACTION_EVENT, this.onProfileAction, this);
        this.viewComponent.events.on(RegistrationScene.AVATAR_CHANGED_EVENT, this.onAvatarChange, this);
        this.viewComponent.events.on(RegistrationScene.NAME_CHANGED_EVENT, this.onNameChange, this);
    };
    RegistrationSceneMediator.prototype.setKeyboardPluginListeners = function () {
        window.addEventListener(KeyboardEvent.WILL_SHOW, this.onKeyboardShown.bind(this));
        window.addEventListener(KeyboardEvent.WILL_HIDE, this.onKeyboardHidden.bind(this));
    };
    RegistrationSceneMediator.prototype.onKeyboardShown = function () {
        if (!this.viewComponent ||
            !this.viewComponent.sys.isActive() ||
            this.viewComponent.sys.isSleeping()) {
            return;
        }
        this.viewComponent.profileInfoSection.hideAvatar();
        this.viewComponent.profileInfoSection.prepareNameFieldForTyping();
    };
    RegistrationSceneMediator.prototype.onKeyboardHidden = function () {
        if (!this.viewComponent ||
            !this.viewComponent.sys.isActive() ||
            this.viewComponent.sys.isSleeping()) {
            return;
        }
        this.viewComponent.profileInfoSection.showAvatar();
        this.viewComponent.profileInfoSection.returnNameFieldToItsPosition();
    };
    RegistrationSceneMediator.prototype.onProfileAction = function (actionID) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, gender;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = actionID;
                        switch (_a) {
                            case FinalizeProfileSceneAction.EDIT: return [3 /*break*/, 1];
                            case FinalizeProfileSceneAction.AVATAR: return [3 /*break*/, 1];
                            case FinalizeProfileSceneAction.GENDER: return [3 /*break*/, 2];
                            case FinalizeProfileSceneAction.NEXT: return [3 /*break*/, 3];
                            case FinalizeProfileSceneAction.OK: return [3 /*break*/, 4];
                        }
                        return [3 /*break*/, 6];
                    case 1:
                        this.viewComponent.toggleAvatarList(this.playerVOProxy.vo.gender);
                        return [3 /*break*/, 7];
                    case 2:
                        {
                            gender = args[0];
                            this.sendNotification(RegistrationScene.GENDER_CHANGED_NOTIFICATION, gender);
                        }
                        return [3 /*break*/, 7];
                    case 3:
                        this.sendNotification(RegistrationScene.NEXT_CLICKED_NOTIFICATION, args[0]);
                        return [3 /*break*/, 7];
                    case 4:
                        this.sendNotification(RegistrationScene.OK_BUTTON_CLICKED_NOTIFICATION);
                        return [4 /*yield*/, this.viewComponent.hideAvatarList()];
                    case 5:
                        _b.sent();
                        this.viewComponent.showEditButton();
                        this.viewComponent.showMessageAndNextButton();
                        return [3 /*break*/, 7];
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    RegistrationSceneMediator.prototype.onLanguageAction = function (language) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.viewComponent.hideLanguageSection()];
                    case 1:
                        _a.sent();
                        this.sendNotification(RegistrationScene.GENDER_CHANGED_NOTIFICATION, this.playerVOProxy.vo.gender, true);
                        this.viewComponent.showProfileSection();
                        this.viewComponent.updateGenderRadios(this.playerVOProxy.vo.gender);
                        this.sendNotification(RegistrationScene.LANGUAGE_CLICKED_NOTIFICATION, Setting.LANGUAGE, language);
                        return [2 /*return*/];
                }
            });
        });
    };
    RegistrationSceneMediator.prototype.onAvatarChange = function (key) {
        this.sendNotification(RegistrationScene.AVATAR_CHANGED_NOTIFICATION, key);
    };
    RegistrationSceneMediator.prototype.onNameChange = function (name) {
        this.sendNotification(RegistrationScene.NAME_CHANGED_NOTIFICATION, name);
    };
    Object.defineProperty(RegistrationSceneMediator.prototype, "playerVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(PlayerVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    RegistrationSceneMediator.NAME = 'RegistrationSceneMediator';
    return RegistrationSceneMediator;
}(BaseSceneMediator));
export default RegistrationSceneMediator;
