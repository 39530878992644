var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mediator } from '@candywings/pure-mvc';
import GameFacade from '../../GameFacade';
import UiStateVOProxy from '../../model/UiStateVOProxy';
import { getScene, postRunnable } from '../utils/phaser/PhaserUtils';
import BaseScene from './BaseScene';
import PopupScene from './PopupScene';
import ServiceScene from './ServiceScene';
var BaseSceneMediator = /** @class */ (function (_super) {
    __extends(BaseSceneMediator, _super);
    function BaseSceneMediator(name, viewComponent) {
        var _this = _super.call(this, name, viewComponent) || this;
        _this.defaultNotifications = [
            PopupScene.WAKE_NOTIFICATION,
            PopupScene.SLEEP_NOTIFICATION,
        ];
        if (_this.viewComponent) {
            _this.registerEvents();
        }
        return _this;
    }
    BaseSceneMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.handleDefaultNotifications(notificationName);
    };
    BaseSceneMediator.prototype.setViewComponent = function (viewComponent) {
        _super.prototype.setViewComponent.call(this, viewComponent);
        this.setViewComponentListeners();
        this.registerEvents();
    };
    BaseSceneMediator.prototype.onRegister = function () {
        this.setView();
        this.subscribeToDefaultNotifications();
        _super.prototype.onRegister.call(this);
    };
    BaseSceneMediator.prototype.onRemove = function () {
        this.sceneManager.remove(this.viewComponent.constructor.name);
        _super.prototype.onRemove.call(this);
    };
    BaseSceneMediator.prototype.startScene = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        if (_this.sceneManager.isActive(_this.viewComponent.constructor.name)) {
                            return;
                        }
                        postRunnable(function () {
                            _this.sceneManager.start(_this.viewComponent.constructor.name);
                            resolve();
                        });
                    })];
            });
        });
    };
    BaseSceneMediator.prototype.stopScene = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.sceneManager.stop(this.viewComponent.constructor.name);
                return [2 /*return*/];
            });
        });
    };
    BaseSceneMediator.prototype.setView = function () {
        //
    };
    BaseSceneMediator.prototype.setViewComponentListeners = function () {
        //
    };
    BaseSceneMediator.prototype.registerEvents = function () {
        this.viewComponent.events.on(BaseScene.PLAY_SFX_EVENT, this.onPlaySfx, this);
        this.viewComponent.events.on(BaseScene.STOP_SFX_EVENT, this.onStopSfx, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.START, this.onSceneStart, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.READY, this.onSceneReady, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.PAUSE, this.onScenePause, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.RESUME, this.onSceneResume, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.SLEEP, this.onSceneSleep, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.WAKE, this.onSceneWake, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.TRANSITION_INIT, this.onSceneTransitionInit, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.TRANSITION_COMPLETE, this.onSceneTransitionComplete, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.TRANSITION_OUT, this.onSceneTransitionOut, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.SHUTDOWN, this.onSceneShutdown, this);
        this.viewComponent.events.on(Phaser.Scenes.Events.DESTROY, this.onSceneDestroy, this);
    };
    BaseSceneMediator.prototype.registerPreUpdateEvent = function () {
        this.viewComponent.events.on(Phaser.Scenes.Events.PRE_UPDATE, this.onScenePreUpdate, this);
    };
    BaseSceneMediator.prototype.registerUpdateEvent = function () {
        this.viewComponent.events.on(Phaser.Scenes.Events.UPDATE, this.onSceneUpdate, this);
    };
    BaseSceneMediator.prototype.registerPostUpdateEvent = function () {
        this.viewComponent.events.on(Phaser.Scenes.Events.POST_UPDATE, this.onScenePostUpdate, this);
    };
    BaseSceneMediator.prototype.onSceneStart = function () {
        this.sendNotification(this.viewComponent.constructor['START']);
    };
    BaseSceneMediator.prototype.onSceneReady = function () {
        this.sendNotification(this.viewComponent.constructor['READY']);
    };
    BaseSceneMediator.prototype.onScenePreUpdate = function () {
        this.sendNotification(this.viewComponent.constructor['PRE_UPDATE']);
    };
    BaseSceneMediator.prototype.onSceneUpdate = function () {
        this.sendNotification(this.viewComponent.constructor['UPDATE']);
    };
    BaseSceneMediator.prototype.onScenePostUpdate = function () {
        this.sendNotification(this.viewComponent.constructor['POST_UPDATE']);
    };
    BaseSceneMediator.prototype.onScenePause = function () {
        this.sendNotification(this.viewComponent.constructor['PAUSE']);
    };
    BaseSceneMediator.prototype.onSceneResume = function () {
        this.sendNotification(this.viewComponent.constructor['RESUME']);
    };
    BaseSceneMediator.prototype.onSceneSleep = function () {
        this.sendNotification(this.viewComponent.constructor['SLEEP']);
    };
    BaseSceneMediator.prototype.onSceneWake = function () {
        // this.viewComponent.showBackground();
        this.sendNotification(this.viewComponent.constructor['WAKE']);
    };
    BaseSceneMediator.prototype.onSceneTransitionInit = function () {
        this.sendNotification(this.viewComponent.constructor['TRANSITION_INIT']);
    };
    BaseSceneMediator.prototype.onSceneTransitionComplete = function () {
        this.sendNotification(this.viewComponent.constructor['TRANSITION_COMPLETE']);
    };
    BaseSceneMediator.prototype.onSceneTransitionOut = function () {
        this.sendNotification(this.viewComponent.constructor['TRANSITION_OUT']);
    };
    BaseSceneMediator.prototype.onSceneShutdown = function () {
        this.sendNotification(this.viewComponent.constructor['SHUTDOWN']);
    };
    BaseSceneMediator.prototype.onSceneDestroy = function () {
        this.sendNotification(this.viewComponent.constructor['DESTROY']);
    };
    BaseSceneMediator.prototype.fadeScreenOut = function (color, duration, delay, wait) {
        if (color === void 0) { color = 0x0000000; }
        if (duration === void 0) { duration = 500; }
        if (delay === void 0) { delay = 0; }
        if (wait === void 0) { wait = false; }
        return __awaiter(this, void 0, void 0, function () {
            var serviceScene, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        serviceScene = getScene(ServiceScene.NAME);
                        this.sceneManager.bringToTop(serviceScene);
                        _a = wait;
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, serviceScene.fadeInPromise];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2:
                        _a;
                        return [2 /*return*/, serviceScene.screenFadeOut(color, duration, delay)];
                }
            });
        });
    };
    BaseSceneMediator.prototype.fadeScreenIn = function (duration, delay, wait) {
        if (duration === void 0) { duration = 300; }
        if (delay === void 0) { delay = 0; }
        if (wait === void 0) { wait = false; }
        return __awaiter(this, void 0, void 0, function () {
            var serviceScene, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        serviceScene = getScene(ServiceScene.NAME);
                        this.sceneManager.bringToTop(serviceScene);
                        _a = wait;
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, serviceScene.fadeOutPromise];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2:
                        _a;
                        return [2 /*return*/, serviceScene.screenFadeIn(duration, delay)];
                }
            });
        });
    };
    BaseSceneMediator.prototype.subscribeToDefaultNotifications = function () {
        this.subscribeToNotifications.apply(this, this.defaultNotifications);
    };
    BaseSceneMediator.prototype.handleDefaultNotifications = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        switch (notificationName) {
            case PopupScene.WAKE_NOTIFICATION:
                if (this.sceneManager.isActive(this.viewComponent.constructor.name)) {
                    this.viewComponent.input.enabled = false;
                }
                break;
            case PopupScene.SLEEP_NOTIFICATION:
                if (this.sceneManager.isActive(this.viewComponent.constructor.name)) {
                    this.viewComponent.input.enabled = true;
                }
                break;
            default:
                break;
        }
    };
    BaseSceneMediator.prototype.onPlaySfx = function (sfxName, loop) {
        this.sendNotification(BaseScene.PLAY_SFX_NOTIFICATION, sfxName, loop);
    };
    BaseSceneMediator.prototype.onStopSfx = function () {
        this.sendNotification(BaseScene.STOP_SFX_NOTIFICATION);
    };
    Object.defineProperty(BaseSceneMediator.prototype, "game", {
        get: function () {
            return GameFacade.game;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSceneMediator.prototype, "sceneManager", {
        get: function () {
            return this.game.scene;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSceneMediator.prototype, "uiStateVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(UiStateVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    return BaseSceneMediator;
}(Mediator));
export default BaseSceneMediator;
