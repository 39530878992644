var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import SocketVOProxy from '../../model/SocketVOProxy';
import { AllowPlayMessage } from '../../model/VO/SocketVO';
import MaintenancePopup from './MaintenancePopup';
import StandardPopupMediator from './StandardPopupMediator';
var MaintenancePopupMediator = /** @class */ (function (_super) {
    __extends(MaintenancePopupMediator, _super);
    function MaintenancePopupMediator() {
        return _super.call(this, MaintenancePopupMediator.NAME) || this;
    }
    MaintenancePopupMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(SocketVOProxy.FIND_ROOM_DENIED_NOTIFICATION);
    };
    MaintenancePopupMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        switch (notificationName) {
            case SocketVOProxy.FIND_ROOM_DENIED_NOTIFICATION:
                var message = args[0];
                if (!message ||
                    message === AllowPlayMessage.SERVER_BUSY ||
                    message === AllowPlayMessage.ROOM_NOT_ACTIVE) {
                    this.showView();
                }
                break;
            default:
                break;
        }
    };
    MaintenancePopupMediator.prototype.createView = function () {
        _super.prototype.createView.call(this, new MaintenancePopup());
    };
    MaintenancePopupMediator.NAME = 'MaintenancePopupMediator';
    return MaintenancePopupMediator;
}(StandardPopupMediator));
export default MaintenancePopupMediator;
