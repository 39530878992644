var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var PopupNinePatchBitmapTitle = /** @class */ (function (_super) {
    __extends(PopupNinePatchBitmapTitle, _super);
    function PopupNinePatchBitmapTitle(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        _this.setListeners();
        return _this;
    }
    PopupNinePatchBitmapTitle.prototype.prepareToShow = function () {
        var frameTexture = this.scene.textures.getFrame(MultiAtlases.Popups.Atlas.Name, this.config.frame);
        this.background.resize(frameTexture.width, frameTexture.height);
        this.background.setOrigin(0.5);
        this.background.setAlpha(0);
        this.text.setAlpha(0);
    };
    PopupNinePatchBitmapTitle.prototype.show = function () {
        var _this = this;
        this.scene.tweens.add({
            targets: this.background,
            alpha: 1,
            duration: 200,
            onComplete: function () {
                var tweenObject = {
                    width: _this.background.width,
                };
                _this.scene.tweens.add({
                    targets: tweenObject,
                    width: _this.text.width + 2 * _this.config.borderDistance,
                    duration: 500,
                    onUpdate: function () {
                        _this.background.resize(tweenObject.width, _this.background.height);
                        _this.background.setOrigin(0.5);
                    },
                    ease: Phaser.Math.Easing.Back.Out,
                    onComplete: function () {
                        _this.scene.tweens.add({
                            targets: _this.text,
                            alpha: 1,
                            duration: 200,
                        });
                    },
                });
            },
        });
    };
    PopupNinePatchBitmapTitle.prototype.hide = function () {
        var _this = this;
        this.scene.tweens.add({
            targets: this.text,
            alpha: 0,
            duration: 200,
            onComplete: function () {
                var tweenObject = {
                    width: _this.background.width,
                };
                var frameTexture = _this.scene.textures.getFrame(MultiAtlases.Popups.Atlas.Name, _this.config.frame);
                _this.scene.tweens.add({
                    targets: tweenObject,
                    width: frameTexture.width,
                    duration: 500,
                    ease: Phaser.Math.Easing.Back.In,
                    onUpdate: function () {
                        _this.background.resize(tweenObject.width, _this.background.height);
                        _this.background.setOrigin(0.5);
                    },
                    onComplete: function () {
                        _this.scene.tweens.add({
                            targets: _this.background,
                            alpha: 0,
                            duration: 200,
                        });
                    },
                });
            },
        });
    };
    PopupNinePatchBitmapTitle.prototype.updateTitle = function (text, font, size, color, origin) {
        if (text === void 0) { text = this.config.text; }
        if (font === void 0) { font = this.config.font; }
        if (size === void 0) { size = this.config.size; }
        if (color === void 0) { color = this.config.color; }
        if (origin === void 0) { origin = this.config.origin || { x: 0.5, y: 0.5 }; }
        this.config.text = text;
        this.config.font = font;
        this.config.size = size;
        this.config.color = color;
        this.config.origin = origin;
        this.text.setText(this.config.text);
        this.text.setFont(this.config.font);
        this.text.setFontSize(this.config.size);
        this.config.color && this.text.setTint(this.config.color);
        this.config.origin &&
            this.text.setOrigin(this.config.origin.x, this.config.origin.y);
        this.correctBackgroundSize();
    };
    PopupNinePatchBitmapTitle.prototype.createBody = function () {
        this.createBackgroud();
        this.createText();
        this.correctBackgroundSize();
    };
    PopupNinePatchBitmapTitle.prototype.createBackgroud = function () {
        var frameTexture = this.scene.textures.getFrame(MultiAtlases.Popups.Atlas.Name, this.config.frame);
        this.background = this.scene.make.ninePatch({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: this.config.frame,
            width: frameTexture.width,
            height: frameTexture.height,
        });
        this.add(this.background);
    };
    PopupNinePatchBitmapTitle.prototype.createText = function () {
        this.text = this.scene.make.extBitmapText({
            x: 0,
            y: 0,
            text: this.config.text,
            font: this.config.font,
            size: this.config.size * gameConfig.resolutionMultiplier,
        });
        this.updateTitle(this.config.text);
        this.add(this.text);
    };
    PopupNinePatchBitmapTitle.prototype.correctBackgroundSize = function () {
        this.background.resize(this.text.width + 2 * this.config.borderDistance, this.background.height);
        this.background.setOrigin(0.5);
        this.setSize(this.background.width, this.background.height);
    };
    PopupNinePatchBitmapTitle.prototype.setListeners = function () {
        this.scene.events.on('languageChangedEvent', this.correctBackgroundSize, this);
    };
    return PopupNinePatchBitmapTitle;
}(Phaser.GameObjects
    .Container));
export default PopupNinePatchBitmapTitle;
