var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Audios } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var SimpleButtonWithBitmapText = /** @class */ (function (_super) {
    __extends(SimpleButtonWithBitmapText, _super);
    function SimpleButtonWithBitmapText(scene, configs) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.configs = configs;
        _this.createBody();
        configs.normalStateConfig.textConfig && _this.createText();
        _this.onOut();
        return _this;
    }
    SimpleButtonWithBitmapText.prototype.setEnabled = function (isEnabled) {
        isEnabled ? this.makeEnabled() : this.makeDisabled();
    };
    SimpleButtonWithBitmapText.prototype.setListeners = function () {
        this.setSize(this.normalState.width, this.normalState.height);
        this.setInteractive();
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
        this.on(Phaser.Input.Events.POINTER_OVER, this.onHover, this);
    };
    SimpleButtonWithBitmapText.prototype.playSfxOnDown = function () {
        //
    };
    SimpleButtonWithBitmapText.prototype.playSfxOnUp = function () {
        this.scene.playSFX(Audios.ButtonTapSfx.Name);
    };
    SimpleButtonWithBitmapText.prototype.onPointerDown = function () {
        this.hoverState && this.hoverState.setVisible(false);
        if (this.downState) {
            this.normalState.visible = false;
            this.downState.visible = true;
            !!this.configs.downStateConfig.textConfig &&
                this.updateTextWithConfig(this.configs.downStateConfig.textConfig);
        }
        this.playSfxOnDown();
        this.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
    };
    SimpleButtonWithBitmapText.prototype.onPointerUp = function () {
        this.normalState.visible = true;
        this.downState && this.downState.setVisible(false);
        this.playSfxOnUp();
        this.onClick();
    };
    SimpleButtonWithBitmapText.prototype.onOut = function () {
        this.emit(SimpleButtonWithBitmapText.OUT_EVENT);
        this.hoverState && this.hoverState.setVisible(false);
        this.downState && this.downState.setVisible(false);
        this.normalState.setVisible(true);
        this.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        !!this.configs.normalStateConfig.textConfig &&
            this.updateTextWithConfig(this.configs.normalStateConfig.textConfig);
    };
    SimpleButtonWithBitmapText.prototype.onHover = function () {
        this.emit(SimpleButtonWithBitmapText.OVER_EVENT);
        if (this.hoverState) {
            this.hoverState.visible = true;
            this.normalState.visible = false;
            !!this.configs.hoverStateConfig.textConfig &&
                this.updateTextWithConfig(this.configs.hoverStateConfig.textConfig);
        }
        this.once(Phaser.Input.Events.POINTER_OUT, this.onOut, this);
    };
    SimpleButtonWithBitmapText.prototype.onClick = function () {
        this.emit(SimpleButtonWithBitmapText.CLICK_EVENT);
    };
    SimpleButtonWithBitmapText.prototype.createBody = function () {
        this.createStates();
        this.setListeners();
    };
    SimpleButtonWithBitmapText.prototype.createStates = function () {
        // Down state if available
        if (this.configs.downStateConfig) {
            this.downState = this.scene.make.sprite(this.configs.downStateConfig);
            this.add(this.downState);
            this.downState.depth = 5;
        }
        // Hover state if available
        if (this.configs.hoverStateConfig) {
            this.hoverState = this.scene.make.sprite(this.configs.hoverStateConfig);
            this.add(this.hoverState);
            this.hoverState.depth = 10;
            this.hoverState.visible = false;
        }
        // Disabled state if available
        if (this.configs.disabledStateConfig) {
            this.disabledState = this.scene.make.sprite(this.configs.disabledStateConfig);
            this.add(this.disabledState);
            this.disabledState.depth = 0;
        }
        // Normal state if available
        this.normalState = this.scene.make.sprite(this.configs.normalStateConfig);
        this.add(this.normalState);
        this.normalState.depth = 15;
    };
    SimpleButtonWithBitmapText.prototype.createText = function () {
        this.text = this.scene.make.extBitmapText({
            x: 0,
            y: 0,
            text: this.configs.normalStateConfig.textConfig.text,
            font: this.configs.normalStateConfig.textConfig.font,
            size: this.configs.normalStateConfig.textConfig.size *
                gameConfig.resolutionMultiplier,
        });
        !!this.configs.normalStateConfig.textConfig.fill &&
            this.text.setTint(this.configs.normalStateConfig.textConfig.fill);
        this.text.align =
            this.configs.normalStateConfig.textConfig.align ||
                Phaser.GameObjects.BitmapText.ALIGN_CENTER;
        this.add(this.text);
        this.text.depth = 20;
        this.text.setOrigin(this.configs.normalStateConfig.textConfig.origin
            ? this.configs.normalStateConfig.textConfig.origin.x
            : 0.5, this.configs.normalStateConfig.textConfig.origin
            ? this.configs.normalStateConfig.textConfig.origin.y
            : 0.5);
    };
    SimpleButtonWithBitmapText.prototype.makeEnabled = function () {
        this.input.enabled = true;
        this.normalState.setVisible(true);
        this.disabledState && this.disabledState.setVisible(false);
        this.hoverState && this.hoverState.setVisible(false);
        this.downState && this.downState.setVisible(false);
        !!this.configs.normalStateConfig.textConfig &&
            this.updateTextWithConfig(this.configs.normalStateConfig.textConfig);
    };
    SimpleButtonWithBitmapText.prototype.makeDisabled = function () {
        this.input.enabled = false;
        if (this.disabledState) {
            this.disabledState.setVisible(true);
            this.normalState.setVisible(false);
            this.hoverState && this.hoverState.setVisible(false);
            this.downState && this.downState.setVisible(false);
            !!this.configs.disabledStateConfig.textConfig &&
                this.updateTextWithConfig(this.configs.disabledStateConfig.textConfig);
        }
    };
    SimpleButtonWithBitmapText.prototype.updateTextWithConfig = function (config) {
        this.text.setText(config.text);
        this.text.setFontSize(config.size);
        this.text.setFont(config.font);
        this.text.setOrigin(config.origin ? config.origin.x : 0.5, config.origin ? config.origin.y : 0.5);
        config.fill && this.text.setTint(config.fill);
    };
    SimpleButtonWithBitmapText.CLICK_EVENT = 'click';
    SimpleButtonWithBitmapText.OUT_EVENT = 'out';
    SimpleButtonWithBitmapText.OVER_EVENT = 'over';
    return SimpleButtonWithBitmapText;
}(Phaser.GameObjects
    .Container));
export default SimpleButtonWithBitmapText;
