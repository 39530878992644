var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import GameScene from '../view/scenes/GameScene';
import LobbyScene from '../view/scenes/LobbyScene';
import RegistrationScene from '../view/scenes/RegistrationScene';
import RoomScene from '../view/scenes/RoomScene';
import WheelScene from '../view/scenes/WheelScene';
import ChangeCurrentNavigationSceneCommand from './ui/ChangeCurrentNavigationSceneCommand';
import ChangeCurrentSceneCommand from './ui/ChangeCurrentSceneCommand';
import RegisterUiStateVOProxyCommand from './ui/RegisterUiStateVOProxyCommand';
var RegisterUiStateCommands = /** @class */ (function (_super) {
    __extends(RegisterUiStateCommands, _super);
    function RegisterUiStateCommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterUiStateCommands.prototype.execute = function () {
        this.facade.registerCommand(LobbyScene.SHOWN_NOTIFICATION, ChangeCurrentNavigationSceneCommand);
        this.facade.registerCommand(RoomScene.SHOW_COMPLETE_NOTIFICATION, ChangeCurrentNavigationSceneCommand);
        this.facade.registerCommand(RegistrationScene.SHOWN_NOTIFICATION, ChangeCurrentSceneCommand);
        this.facade.registerCommand(WheelScene.SHOWN_NOTIFICATION, ChangeCurrentSceneCommand);
        this.facade.registerCommand(LobbyScene.SHOWN_NOTIFICATION, ChangeCurrentSceneCommand);
        this.facade.registerCommand(RoomScene.SHOW_COMPLETE_NOTIFICATION, ChangeCurrentSceneCommand);
        this.facade.registerCommand(GameScene.CREATE_NOTIFICATION, ChangeCurrentSceneCommand);
        this.facade.registerCommand(GameScene.RECREATE_NOTIFICATION, ChangeCurrentSceneCommand);
        _super.prototype.execute.call(this);
    };
    RegisterUiStateCommands.prototype.initializeMacroCommand = function () {
        this.addSubCommand(RegisterUiStateVOProxyCommand);
    };
    return RegisterUiStateCommands;
}(SyncMacroCommand));
export default RegisterUiStateCommands;
