var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Images, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import GameScene from '../../scenes/GameScene';
import { getScene } from '../../utils/phaser/PhaserUtils';
import PathFollowerImage from '../../utils/plugins/pathFollower/PathFollowerImage';
import WaitingViewExitButton from './waiting/WaitingViewExitButton';
import WaitingTimer from './WaitingTimer';
var WaitingView = /** @class */ (function (_super) {
    __extends(WaitingView, _super);
    function WaitingView() {
        var _this = _super.call(this, getScene(GameScene.NAME), gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.58) || this;
        _this.createComponents();
        _this.scene.add.existing(_this);
        return _this;
    }
    WaitingView.prototype.preDestroy = function () {
        _super.prototype.preDestroy.call(this);
        this.background.destroy();
    };
    WaitingView.prototype.createComponents = function () {
        this.cards = [];
        this.createBlackBackground();
        this.creteExitButton();
        this.createPath();
        this.createTimer();
        this.createCards();
    };
    WaitingView.prototype.showExitButton = function () {
        this.exitButton.show();
    };
    WaitingView.prototype.show = function () {
        var _this = this;
        this.scene.tweens.add({
            targets: this.background,
            alpha: 0.4,
            duration: 300,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: _this.timer,
                    scaleX: 0.6,
                    scaleY: 0.6,
                    duration: 500,
                    ease: Phaser.Math.Easing.Back.Out,
                    onComplete: _this.startAnimation,
                    onCompleteScope: _this,
                });
            },
        });
    };
    WaitingView.prototype.hide = function () {
        var _this = this;
        this.stopAnimation();
        this.timer.stop();
        this.scene.tweens.add({
            targets: this.background,
            alpha: 0,
            duration: 200,
            onComplete: function () {
                _this.background.destroy();
                _this.exitButton.destroy();
            },
        });
    };
    WaitingView.prototype.startAnimation = function () {
        for (var i = 0; i < this.cards.length; i++) {
            var card = this.cards[this.cards.length - i - 1];
            var delay = i * 50;
            card.startFollow({
                duration: 2000,
                ease: Phaser.Math.Easing.Expo.InOut,
                rotateToPath: true,
                positionOnPath: true,
                delay: delay,
                repeat: -1,
                repeatDelay: this.cards.length * 50,
            });
        }
        this.timer.start();
    };
    WaitingView.prototype.stopAnimation = function () {
        this.scene.tweens.killTweensOf(this.list);
        for (var _i = 0, _a = this.cards; _i < _a.length; _i++) {
            var card = _a[_i];
            card.stopFollow();
            card.destroy();
        }
    };
    WaitingView.prototype.createBlackBackground = function () {
        this.background = this.scene.make.image({
            x: gameConfig.canvasWidth / 2 - this.x,
            y: gameConfig.canvasHeight / 2 - this.y,
            key: Images.WhitePixel.Name,
        });
        this.background.setScale(gameConfig.canvasWidth, gameConfig.canvasHeight);
        this.background.setAlpha(0);
        this.background.setTintFill(0x000000);
        this.background.setDepth(1);
        this.background.setInteractive();
        this.add(this.background);
    };
    WaitingView.prototype.creteExitButton = function () {
        this.exitButton = new WaitingViewExitButton(this.scene);
        this.add(this.exitButton);
    };
    WaitingView.prototype.createPath = function () {
        var curve = new Phaser.Curves.Ellipse(0, 0, gameConfig.designWidth * 0.104);
        curve.setStartAngle(-90);
        curve.setEndAngle(270);
        var firstPoint = curve.getStartPoint();
        this.path = new Phaser.Curves.Path(firstPoint.x, firstPoint.y);
        this.path.add(curve);
    };
    WaitingView.prototype.createCards = function () {
        var totalCardsCount = 18;
        for (var i = 0; i < totalCardsCount; i++) {
            var startPoint = this.path.getStartPoint();
            var card = new PathFollowerImage(this.scene, this.path, startPoint.x, startPoint.y, MultiAtlases.GamePlay.Atlas.Name, MultiAtlases.GamePlay.Atlas.Frames.Cover);
            card.setScale(0.5);
            card.setAlpha(0.6);
            this.cards.push(card);
            this.add(card);
        }
    };
    WaitingView.prototype.createTimer = function () {
        this.timer = new WaitingTimer(this.scene);
        this.add(this.timer);
        this.timer.setScale(0);
    };
    WaitingView.NAME = 'WaitingView';
    WaitingView.EXIT_CLICKED_EVENT = 'exitClicked';
    WaitingView.SHOWN_NOTIFICATION = WaitingView.NAME + "ShownNotification";
    WaitingView.HIDDEN_NOTIFICATION = WaitingView.NAME + "HiddenNotification";
    WaitingView.TIMER_COMPLETE_NOTIFICATION = WaitingView.NAME + "TimerCompleteNotification";
    WaitingView.EXIT_CLICKED_NOTIFICATION = WaitingView.NAME + "ExitClickedNotification";
    return WaitingView;
}(Phaser.GameObjects.Container));
export default WaitingView;
