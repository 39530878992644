var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Atlases, Audios, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { upperCaseFirstLetter } from '../../utils/Utils';
import StoreTabButton from '../components/store/StoreTabButton';
import StoreTabContent from '../components/store/StoreTabContent';
import BaseScene from './BaseScene';
var StoreScene = /** @class */ (function (_super) {
    __extends(StoreScene, _super);
    function StoreScene() {
        return _super.call(this, StoreScene.NAME) || this;
    }
    // public showBackground(): void {
    //   setDocumentBackgroundImage(
    //     `${MultiAtlases.Lobby.Atlas.Name}-${MultiAtlases.Lobby.Atlas.Frames.Background}`,
    //   );
    // }
    StoreScene.prototype.create = function () {
        this.input.setTopOnly(true);
        this.createBackgrounds();
        this.cameras.main.x = -gameConfig.canvasWidth;
    };
    StoreScene.prototype.getTabsCount = function () {
        return this.tabs ? this.tabs.length : 0;
    };
    StoreScene.prototype.updateContents = function (configs, sections) {
        var topBarFrame = this.textures.getFrame(Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.Background);
        var center = new Phaser.Math.Vector2((gameConfig.canvasWidth + this.backgroundLeft.displayWidth) / 2, (gameConfig.canvasHeight + topBarFrame.height) * 0.55);
        for (var i = 0; i < sections.length; i++) {
            var section = sections[i];
            var tab = this.getTabBySection(section);
            if (!configs[section] || configs[section].length === 0 || !tab) {
                continue;
            }
            var content = tab.content;
            if (!content) {
                content = new StoreTabContent(this, section);
                tab.setContent(content);
            }
            content.updateData(configs[section]);
            content.x = center.x;
            content.y = center.y;
        }
    };
    StoreScene.prototype.show = function (section) {
        if (section === void 0) { section = this.getCurrentTabSection() || 'gold'; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.playSFX(Audios.StoreSceneTransitionSfx.Name);
                        for (var _i = 0, _a = _this.tabs; _i < _a.length; _i++) {
                            var tab = _a[_i];
                            tab.makeInActive(true);
                        }
                        _this.tweens.killTweensOf(_this.cameras.main);
                        _this.tweens.add({
                            targets: _this.cameras.main,
                            x: 0,
                            duration: 800,
                            ease: Phaser.Math.Easing.Back.Out,
                            onComplete: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (this.tabs.length === 0) {
                                                return [2 /*return*/, resolve()];
                                            }
                                            return [4 /*yield*/, this.showTabs(section)];
                                        case 1:
                                            _a.sent();
                                            resolve();
                                            return [2 /*return*/];
                                    }
                                });
                            }); },
                        });
                    })];
            });
        });
    };
    StoreScene.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _i, _a, tab;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    for (_i = 0, _a = this.tabs; _i < _a.length; _i++) {
                                        tab = _a[_i];
                                        tab.makeInActive();
                                    }
                                    this.tweens.killTweensOf(this.cameras.main);
                                    this.tweens.killTweensOf(this.tabs);
                                    return [4 /*yield*/, this.hideTabs()];
                                case 1:
                                    _b.sent();
                                    this.playSFX(Audios.StoreSceneTransitionSfx.Name);
                                    this.tweens.add({
                                        targets: this.cameras.main,
                                        x: -this.width,
                                        duration: 800,
                                        ease: Phaser.Math.Easing.Back.In,
                                        onComplete: function () {
                                            resolve();
                                        },
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    StoreScene.prototype.showTabs = function (section) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        if (_this.tabs.length === 0) {
                            return resolve();
                        }
                        var _loop_1 = function (i) {
                            var tab = _this.tabs[i];
                            _this.tweens.add({
                                targets: tab,
                                y: tab.y - gameConfig.canvasHeight,
                                delay: i * 50,
                                duration: 500,
                                ease: Phaser.Math.Easing.Back.Out,
                                easeParams: [0.8],
                                onComplete: function () {
                                    if (tab === _this.tabs.getLast()) {
                                        var targetTab = _this.getTabBySection(section) || _this.tabs[0];
                                        targetTab.makeActive();
                                        resolve();
                                    }
                                },
                            });
                        };
                        for (var i = 0; i < _this.tabs.length; i++) {
                            _loop_1(i);
                        }
                    })];
            });
        });
    };
    StoreScene.prototype.hideTabs = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        if (_this.tabs.length === 0) {
                            return resolve();
                        }
                        var _loop_2 = function (i) {
                            var tab = _this.tabs[i];
                            _this.tweens.add({
                                targets: tab,
                                y: tab.y + gameConfig.canvasHeight,
                                delay: (_this.tabs.length - 1 - i) * 50,
                                duration: 500,
                                ease: Phaser.Math.Easing.Back.In,
                                easeParams: [0.8],
                                onComplete: function () {
                                    tab === _this.tabs.getLast() && resolve();
                                },
                            });
                        };
                        for (var i = 0; i < _this.tabs.length; i++) {
                            _loop_2(i);
                        }
                    })];
            });
        });
    };
    StoreScene.prototype.createOrUpdateTabs = function (sections) {
        if (!this.tabs) {
            this.tabs = [];
        }
        var tabsToRemove = this.tabs.filter(function (tab) { return !sections.contains(tab.config.section); });
        for (var _i = 0, tabsToRemove_1 = tabsToRemove; _i < tabsToRemove_1.length; _i++) {
            var tab = tabsToRemove_1[_i];
            this.tabs.remove(tab);
            tab.content && tab.content.destroy();
            tab.destroy();
        }
        var currentTabsCount = this.getTabsCount();
        var tabConfigs = [];
        for (var i = 0; i < sections.length; i++) {
            tabConfigs.push({
                section: sections[i],
                iconActiveFrame: MultiAtlases.Store.Atlas.Frames["Icon" + upperCaseFirstLetter(sections[i]) + "Active"],
                iconPassiveFrame: MultiAtlases.Store.Atlas.Frames["Icon" + upperCaseFirstLetter(sections[i]) + "Passive"],
                y: 0,
            });
        }
        var _loop_3 = function (config) {
            if (!!this_1.tabs.find(function (tab) {
                return !!tab.config &&
                    !!tab.content &&
                    config.section === tab.config.section;
            })) {
                return "continue";
            }
            var tab = new StoreTabButton(this_1, config);
            tab.setDepth(10);
            this_1.tabs.addAt(tabConfigs.indexOf(config), tab);
        };
        var this_1 = this;
        for (var _a = 0, tabConfigs_1 = tabConfigs; _a < tabConfigs_1.length; _a++) {
            var config = tabConfigs_1[_a];
            _loop_3(config);
        }
        this.tabs.sort(function (a, b) {
            return sections.indexOf(a.config.section) - sections.indexOf(b.config.section);
        });
        this.tabs.length !== currentTabsCount && this.positionTabs();
    };
    StoreScene.prototype.onTabSwitch = function (section) {
        return __awaiter(this, void 0, void 0, function () {
            var targetTab, promises, _i, _a, tab;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        targetTab = this.getTabBySection(section);
                        if (targetTab.activated) {
                            return [2 /*return*/];
                        }
                        promises = [];
                        for (_i = 0, _a = this.tabs; _i < _a.length; _i++) {
                            tab = _a[_i];
                            promises.push(tab.makeInActive());
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _b.sent();
                        this.children.bringToTop(targetTab.content);
                        this.children.bringToTop(targetTab);
                        targetTab.makeActive();
                        return [2 /*return*/];
                }
            });
        });
    };
    StoreScene.prototype.createBackgrounds = function () {
        this.backgroundLeft = this.add.image(0, gameConfig.canvasHeight, MultiAtlases.Store.Atlas.Name, MultiAtlases.Store.Atlas.Frames.BgLeft);
        this.backgroundLeft.depth = -91;
        this.backgroundLeft.setOrigin(0, 1);
        this.backgroundLeft.setInteractive();
        this.backgroundMain = this.add.image(this.backgroundLeft.width, gameConfig.canvasHeight, MultiAtlases.Store.Atlas.Name, MultiAtlases.Store.Atlas.Frames.BgMain);
        this.backgroundMain.depth = -90;
        var scale = Math.max((gameConfig.canvasWidth - this.backgroundLeft.width) /
            this.backgroundMain.width, gameConfig.canvasHeight / this.backgroundMain.height);
        this.backgroundMain.setOrigin(0, 1);
        this.backgroundMain.setInteractive();
        this.backgroundLeft.setScale(Math.max(scale, 1));
        this.backgroundMain.setScale(Math.max(scale, 1));
    };
    StoreScene.prototype.positionTabs = function () {
        var frame = this.textures.getFrame(MultiAtlases.Store.Atlas.Name, MultiAtlases.Store.Atlas.Frames.TabInactive);
        var x = this.backgroundLeft.width * 1.09;
        var gap = frame.height * 0.3;
        var totalHeight = this.tabs.length * frame.height + this.tabs.length * gap;
        var line = new Phaser.Geom.Line(x, gameConfig.canvasHeight / 2 - totalHeight / 2, x, gameConfig.canvasHeight / 2 + totalHeight / 2);
        var positions = line.getPoints(this.tabs.length + 1);
        positions.shift();
        for (var i = 0; i < this.tabs.length; i++) {
            this.tabs[i].x = positions[i].x;
            this.tabs[i].y = positions[i].y + gameConfig.canvasHeight;
            this.children.bringToTop(this.tabs[i]);
        }
    };
    StoreScene.prototype.getTabBySection = function (section) {
        if (section === void 0) { section = this.getCurrentTabSection(); }
        return this.tabs.find(function (tab) { return tab.config.section === section; });
    };
    StoreScene.prototype.getCurrentTabSection = function () {
        var activeTab = this.tabs.find(function (tab) { return tab.activated; });
        return !!activeTab ? activeTab.config.section : null;
    };
    StoreScene.NAME = 'StoreScene';
    StoreScene.STORE_ITEM_CLICK_NOTIFICATION = StoreScene.NAME + "StoreItemClickNotification";
    StoreScene.STORE_TAB_CLICK_NOTIFICATION = StoreScene.NAME + "StoreTabClickNotification";
    StoreScene.SHOWN_NOTIFICATION = StoreScene.NAME + "ShownNotification";
    StoreScene.HIDDEN_NOTIFICATION = StoreScene.NAME + "HiddenNotification";
    StoreScene.STORE_ITEM_CLICK_EVENT = 'onStoreItemClick';
    StoreScene.STORE_TAB_CLICK_EVENT = 'storeTabClick';
    return StoreScene;
}(BaseScene));
export default StoreScene;
