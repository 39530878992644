var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var BaseTurnButtonState = /** @class */ (function (_super) {
    __extends(BaseTurnButtonState, _super);
    function BaseTurnButtonState(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        return _this;
    }
    BaseTurnButtonState.prototype.setText = function (text) {
        this.text.setText(text);
    };
    BaseTurnButtonState.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        !!this.config.textConfig && this.createText();
    };
    BaseTurnButtonState.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: this.config.key,
            frame: this.config.frame,
        });
        this.add(this.background);
    };
    BaseTurnButtonState.prototype.createText = function () {
        this.text = this.scene.make.extBitmapText({
            x: 0,
            y: 0,
            text: this.config.textConfig.text,
            font: this.config.textConfig.font,
            size: this.config.textConfig.size,
        });
        this.config.textConfig.fill &&
            this.text.setTint(this.config.textConfig.fill);
        this.config.textConfig.origin
            ? this.text.setOrigin(this.config.textConfig.origin.x, this.config.textConfig.origin.y || this.config.textConfig.origin.x)
            : this.text.setOrigin(0.5);
        this.config.textConfig.alpha &&
            this.text.setAlpha(this.config.textConfig.alpha);
        this.config.textConfig.align &&
            (this.text.align = this.config.textConfig.align);
        this.add(this.text);
    };
    return BaseTurnButtonState;
}(Phaser.GameObjects.Container));
export default BaseTurnButtonState;
