var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ServiceScene from '../../../scenes/ServiceScene';
import { getScene } from '../../phaser/PhaserUtils';
var TextureGenerator = /** @class */ (function () {
    function TextureGenerator() {
    }
    TextureGenerator.generateTextures = function () {
        var textures = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            textures[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _i, textures_1, texture, error_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 5, , 6]);
                                    _i = 0, textures_1 = textures;
                                    _a.label = 1;
                                case 1:
                                    if (!(_i < textures_1.length)) return [3 /*break*/, 4];
                                    texture = textures_1[_i];
                                    return [4 /*yield*/, this.generateTexture(texture)];
                                case 2:
                                    _a.sent();
                                    texture.destroy();
                                    _a.label = 3;
                                case 3:
                                    _i++;
                                    return [3 /*break*/, 1];
                                case 4:
                                    resolve();
                                    return [3 /*break*/, 6];
                                case 5:
                                    error_1 = _a.sent();
                                    reject(error_1);
                                    return [3 /*break*/, 6];
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    TextureGenerator.generateTexture = function (texture) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var scene = getScene(ServiceScene.NAME);
                        var bounds = texture.getBounds();
                        var renderTexture = new Phaser.GameObjects.RenderTexture(scene, bounds.x, bounds.y, bounds.width, bounds.height);
                        renderTexture.draw(texture);
                        renderTexture.snapshot(_this.addTexture.bind(_this, texture.key, resolve, reject));
                    })];
            });
        });
    };
    TextureGenerator.addTexture = function (key, resolveFunction, rejectFunction, image) {
        var scene = getScene(ServiceScene.NAME);
        scene.textures.exists(key) && scene.textures.removeKey(key);
        scene.textures.addBase64(key, image.src);
        scene.textures.once(Phaser.Textures.Events.ADD, resolveFunction);
        scene.textures.once(Phaser.Textures.Events.ERROR, rejectFunction);
    };
    return TextureGenerator;
}());
export default TextureGenerator;
