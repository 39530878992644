var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Guard } from '@candywings/pure-mvc';
import { ValidationActionType } from '../../../model/VO/SocketVO';
var IsValidActionGuard = /** @class */ (function (_super) {
    __extends(IsValidActionGuard, _super);
    function IsValidActionGuard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IsValidActionGuard.prototype.approve = function (notificationName, action) {
        return action !== ValidationActionType.DISABLED;
    };
    return IsValidActionGuard;
}(Guard));
export default IsValidActionGuard;
