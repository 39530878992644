var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Proxy } from '@candywings/pure-mvc';
import API from '../controller/adapter/API';
import { getPlatform } from '../utils/CordovaUtils';
import { getAppVersion, isCordova } from '../utils/Utils';
import ApiVO from './VO/ApiVO';
var ApiVOProxy = /** @class */ (function (_super) {
    __extends(ApiVOProxy, _super);
    function ApiVOProxy() {
        return _super.call(this, ApiVOProxy.NAME, new ApiVO()) || this;
    }
    ApiVOProxy.prototype.updatePlayerData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var playerData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, API.getUserUpdates(this.vo.jwt, this.vo.userId)];
                    case 1:
                        playerData = _a.sent();
                        this.sendNotification(ApiVOProxy.UPDATE_PLAYER_DATA_NOTIFICATION, playerData);
                        return [2 /*return*/];
                }
            });
        });
    };
    ApiVOProxy.prototype.checkNotifications = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                API.checkNotifications(this.vo.jwt, this.vo.userId, getPlatform()).then(function (data) {
                    _this.sendNotification(ApiVOProxy.NOTIFICATIONS_CHECK_COMPLETE_NOTIFICATION, data);
                });
                return [2 /*return*/];
            });
        });
    };
    ApiVOProxy.prototype.getStoreItems = function () {
        return __awaiter(this, void 0, void 0, function () {
            var storeData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, API.getStoreItems(this.vo.jwt, this.vo.userId, getPlatform())];
                    case 1:
                        storeData = _a.sent();
                        this.sendNotification(ApiVOProxy.STORE_DATA_READY_NOTIFICATION, storeData);
                        return [2 /*return*/];
                }
            });
        });
    };
    ApiVOProxy.prototype.updateSettings = function (setting, value) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        API.updateSettings(_this.vo.jwt, _this.vo.userId, setting, value)
                            .then(function () {
                            _this.vo.settings[setting] = value;
                            _this.sendNotification(ApiVOProxy.SETTINGS_CHANGED_NOTIFICATION, _this.vo.settings);
                            resolve();
                        })
                            .catch(reject);
                    })];
            });
        });
    };
    ApiVOProxy.prototype.updateUserProfileInfo = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        API.updateUserInfo(_this.vo.jwt, _this.vo.userId, data)
                            .then(function () {
                            _this.sendNotification(ApiVOProxy.USER_PROFILE_DATA_UPDATED_NOTIFICATION, data);
                            resolve();
                        })
                            .catch(reject);
                    })];
            });
        });
    };
    ApiVOProxy.prototype.rotateWheel = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, API.wheelRolled(this.vo.jwt, this.vo.userId)];
                    case 1:
                        _a.sent();
                        this.sendNotification(ApiVOProxy.WHEEL_ROTATION_COMPLETED_NOTIFICATION);
                        return [2 /*return*/];
                }
            });
        });
    };
    ApiVOProxy.prototype.claimWheelReward = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.sendNotification(ApiVOProxy.CLAIM_WHEEL_REWARD_NOTIFICATION, this.vo.wheelData.data[this.vo.wheelData.segment]);
                return [2 /*return*/];
            });
        });
    };
    ApiVOProxy.prototype.getBonusReward = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.sendNotification(ApiVOProxy.CLAIM_BONUS_REWARD_NOTIFICATION, this.vo.bonuses.shift());
                return [2 /*return*/];
            });
        });
    };
    ApiVOProxy.prototype.sendRewardedVideoWatched = function () {
        var _this = this;
        API.sendRewardedVideoWatched(this.vo.jwt, this.vo.userId).then(function () {
            _this.updatePlayerData();
            _this.getStoreItems();
        });
    };
    ApiVOProxy.prototype.fetchLevelUpData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var levelUpData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, API.checkLevelUp(this.vo.jwt, this.vo.userId)];
                    case 1:
                        levelUpData = _a.sent();
                        this.vo.levelUpData = levelUpData.sort(function (a, b) { return a.level - b.level; });
                        this.checkLevelUpData();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApiVOProxy.prototype.checkLevelUpData = function () {
        if (this.vo.levelUpData.length > 0) {
            this.sendNotification(ApiVOProxy.USER_LEVEL_UP_NOTIFICATION);
        }
    };
    ApiVOProxy.prototype.consumeLevelUpRewards = function () {
        var levelUpData = this.vo.levelUpData.shift();
        return levelUpData.prize;
    };
    ApiVOProxy.prototype.getLevelUpRewards = function () {
        return this.vo.levelUpData[0].prize;
    };
    ApiVOProxy.prototype.buyProduct = function (boughtProduct, productInfo) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                API.buyProduct(this.vo.jwt, this.vo.userId, getPlatform(), boughtProduct, productInfo)
                    .then(function () {
                    _this.sendNotification(ApiVOProxy.PRODUCT_BUY_SUCCEED_NOTIFICATION, boughtProduct, productInfo);
                    _this.updatePlayerData();
                })
                    .catch(function (reason) {
                    _this.sendNotification(ApiVOProxy.PRODUCT_BUY_FAILED_NOTIFICATION, boughtProduct, productInfo, reason);
                });
                return [2 /*return*/];
            });
        });
    };
    ApiVOProxy.prototype.sendUnfinishedPurchase = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                API.unfinishedPurchase(this.vo.jwt, this.vo.userId, data.productId)
                    .then(function () {
                    _this.sendNotification(ApiVOProxy.PRODUCT_BUY_SUCCEED_NOTIFICATION, data);
                    _this.updatePlayerData();
                })
                    .catch(function (reason) {
                    _this.sendNotification(ApiVOProxy.PRODUCT_BUY_FAILED_NOTIFICATION, data, reason);
                });
                return [2 /*return*/];
            });
        });
    };
    ApiVOProxy.prototype.init = function (zone, data) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                data.deviceType = isCordova() ? window.device.platform : '';
                data.deviceModel = isCordova() ? window.device.model : '';
                data.deviceVersion = isCordova() ? window.device.version : '';
                data.gameVersion = getAppVersion() || '';
                return [2 /*return*/, (this.initPromise = API.authenticate(zone, data)
                        .then(function (userData) {
                        _this.vo = userData;
                        _this.sendNotification(ApiVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, _this.vo);
                    })
                        .catch(function (error) {
                        console.error(error);
                        _this.sendNotification(ApiVOProxy.INITIALIZE_FAILED_NOTIFICATION);
                    }))];
            });
        });
    };
    ApiVOProxy.NAME = 'ApiVOProxy';
    ApiVOProxy.INITIALIZE_SUCCESS_NOTIFICATION = ApiVOProxy.NAME + "InitializeSuccessNotification";
    ApiVOProxy.INITIALIZE_FAILED_NOTIFICATION = ApiVOProxy.NAME + "InitializeFailedNotification";
    ApiVOProxy.CLAIM_WHEEL_REWARD_NOTIFICATION = ApiVOProxy.NAME + "ClaimWheelRewardNotification";
    ApiVOProxy.CLAIM_BONUS_REWARD_NOTIFICATION = ApiVOProxy.NAME + "ClaimBonusRewardNotification";
    ApiVOProxy.WHEEL_ROTATION_COMPLETED_NOTIFICATION = ApiVOProxy.NAME + "WheelRotationCompletedNotification";
    ApiVOProxy.SETTINGS_CHANGED_NOTIFICATION = ApiVOProxy.NAME + "SettingsChangedNotification";
    ApiVOProxy.USER_PROFILE_DATA_UPDATED_NOTIFICATION = ApiVOProxy.NAME + "UserProfileDataUpdatedNotification";
    ApiVOProxy.STORE_DATA_READY_NOTIFICATION = ApiVOProxy.NAME + "StoreDataReadyNotification";
    ApiVOProxy.USER_LEVEL_UP_NOTIFICATION = ApiVOProxy.NAME + "UserLevelUpNotification";
    ApiVOProxy.UPDATE_PLAYER_DATA_NOTIFICATION = ApiVOProxy.NAME + "UpdatePlayerDataNotification";
    ApiVOProxy.PRODUCT_BUY_FAILED_NOTIFICATION = ApiVOProxy.NAME + "ProductBuyFailedNotification";
    ApiVOProxy.PRODUCT_BUY_SUCCEED_NOTIFICATION = ApiVOProxy.NAME + "ProductBuySucceedNotification";
    ApiVOProxy.NOTIFICATIONS_CHECK_COMPLETE_NOTIFICATION = ApiVOProxy.NAME + "NotificationsCheckCompleteNotification";
    ApiVOProxy.NOTIFICATION_OPENED_NOTIFICATION = ApiVOProxy.NAME + "NotificationOpenedNotification";
    return ApiVOProxy;
}(Proxy));
export default ApiVOProxy;
