var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import StateVOProxy from '../../model/StateVOProxy';
import GameEndPopup from './GameEndPopup';
import StandardPopupMediator from './StandardPopupMediator';
import WaitOrLeavePopup, { WaitOrLeavePopupAction } from './WaitOrLeavePopup';
var WaitOrLeavePopupMediator = /** @class */ (function (_super) {
    __extends(WaitOrLeavePopupMediator, _super);
    function WaitOrLeavePopupMediator() {
        return _super.call(this, WaitOrLeavePopupMediator.NAME) || this;
    }
    WaitOrLeavePopupMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(GameEndPopup.HIDDEN_NOTIFICATION, StateVOProxy.GAME_OVER_NOTIFICATION);
    };
    WaitOrLeavePopupMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        switch (notificationName) {
            case GameEndPopup.HIDDEN_NOTIFICATION:
                var isGameEnded = this.stateVOProxy.vo.isGameEnded;
                var isForceEnd = this.stateVOProxy.vo.isForceGameEnded;
                switch (true) {
                    case isForceEnd:
                    case !this.stateVOProxy.vo.isGameEnded &&
                        this.stateVOProxy.getBotsCount() === 3:
                        this.sendNotification(WaitOrLeavePopup.LEAVE_CLICKED_NOTIFICATION);
                        break;
                    case !isGameEnded:
                        this.showView();
                        return;
                    case isGameEnded:
                        this.sendNotification(WaitOrLeavePopup.WAIT_CLICKED_NOTIFICATION);
                        break;
                    default:
                        break;
                }
                break;
            case StateVOProxy.GAME_OVER_NOTIFICATION:
                if (!this.viewComponent) {
                    break;
                }
                this.hideView();
                this.sendNotification(WaitOrLeavePopup.WAIT_CLICKED_NOTIFICATION);
                break;
            default:
                break;
        }
    };
    WaitOrLeavePopupMediator.prototype.createView = function () {
        _super.prototype.createView.call(this, new WaitOrLeavePopup());
    };
    WaitOrLeavePopupMediator.prototype.onAction = function (action) {
        this.hideView();
        switch (action) {
            case WaitOrLeavePopupAction.WAIT:
                this.sendNotification(WaitOrLeavePopup.WAIT_CLICKED_NOTIFICATION);
                break;
            case WaitOrLeavePopupAction.LEAVE:
                this.sendNotification(WaitOrLeavePopup.LEAVE_CLICKED_NOTIFICATION);
                break;
            default:
                break;
        }
    };
    Object.defineProperty(WaitOrLeavePopupMediator.prototype, "stateVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(StateVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    WaitOrLeavePopupMediator.NAME = 'WaitOrLeavePopupMediator';
    return WaitOrLeavePopupMediator;
}(StandardPopupMediator));
export default WaitOrLeavePopupMediator;
