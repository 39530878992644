var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Audios, BitmapFonts, Cursors, Fonts, Images, Locales, MultiAtlases, Spines, } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { getZone } from '../../utils/Utils';
import { getDataFromStorage, StorageKey, } from '../../utils/wrappers/StorageWrapper';
import { loadAudios, loadBitmapFonts, loadFonts, loadImages, loadJSONs, loadMultiAtlases, loadSpines, } from '../utils/assetLoader';
import SpineMaker from '../utils/plugins/spine/SpineMaker';
import BaseScene from './BaseScene';
var BootScene = /** @class */ (function (_super) {
    __extends(BootScene, _super);
    function BootScene() {
        return _super.call(this, BootScene.NAME) || this;
    }
    BootScene.prototype.init = function () {
        _super.prototype.init.call(this);
        this.plugins.registerGameObject('spine', SpineMaker.add, SpineMaker.make);
    };
    BootScene.prototype.preload = function () {
        loadAudios(this, Audios.CompanyLogoSfx);
        loadJSONs(this, Locales);
        loadFonts(Fonts);
        loadBitmapFonts(this, BitmapFonts);
        loadImages(this, Images);
        loadMultiAtlases(this, MultiAtlases.Loading.Atlas);
        loadSpines(this, Spines.LogoSpine.Spine);
    };
    BootScene.prototype.create = function () {
        this.createBackground();
        this.createLogo();
        var settings = getDataFromStorage(StorageKey.SETTINGS, null);
        var defaultLanguage = getZone() === 'cordova' ? 'en' : 'ru';
        this.i18n.init(settings ? settings.language : defaultLanguage);
        var cursorConfig = "url(" + Cursors.Default.FileURL + "), pointer";
        this.input.setDefaultCursor(cursorConfig);
        this.events.emit(BootScene.BOOT_COMPLETE_EVENT);
    };
    BootScene.prototype.createBackground = function () {
        this.background = this.add.image(gameConfig.canvasWidth / 2, gameConfig.canvasHeight / 2, Images.WhitePixel.Name);
        this.background.setScale(Math.max(gameConfig.canvasWidth / this.background.width, gameConfig.canvasHeight / this.background.height));
    };
    BootScene.prototype.createLogo = function () {
        this.logo = this.add.image(gameConfig.canvasWidth / 2, gameConfig.canvasHeight / 2, Images.Logo.Name);
    };
    BootScene.NAME = 'BootScene';
    BootScene.LOAD_COMPLETE_NOTIFICATION = BootScene.NAME + "LoadCompleteNotification";
    BootScene.BOOT_COMPLETE_NOTIFICATION = BootScene.NAME + "BootCompleteNotification";
    BootScene.BOOT_COMPLETE_EVENT = BootScene.NAME + "BootCompleteEvent";
    BootScene.LOAD_COMPLETE_EVENT = BootScene.NAME + "LoadCompleteEvent";
    return BootScene;
}(BaseScene));
export default BootScene;
