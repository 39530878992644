var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import RegistrationScene from '../../scenes/RegistrationScene';
var RegistrationProfileGenderRadio = /** @class */ (function (_super) {
    __extends(RegistrationProfileGenderRadio, _super);
    function RegistrationProfileGenderRadio(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        _this.setSize(_this.border.width, _this.border.height);
        _this.setListeners();
        return _this;
    }
    RegistrationProfileGenderRadio.prototype.setSelected = function (enabled) {
        this.scene.tweens.killTweensOf(this.selection);
        var scale = enabled ? 1 : 0;
        this.scene.tweens.add({
            targets: this.selection,
            scaleX: scale,
            scaleY: scale,
            ease: enabled ? Phaser.Math.Easing.Back.Out : Phaser.Math.Easing.Back.In,
            duration: 150,
        });
    };
    RegistrationProfileGenderRadio.prototype.show = function () {
        this.scene.tweens.killTweensOf(this.selection);
        this.scene.tweens.add({
            targets: this.selection,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Back.Out,
            duration: 600,
        });
    };
    RegistrationProfileGenderRadio.prototype.hide = function () {
        this.scene.tweens.killTweensOf(this.selection);
        this.scene.tweens.add({
            targets: this.selection,
            scaleX: 0,
            scaleY: 0,
            ease: Phaser.Math.Easing.Back.Out,
            duration: 600,
        });
    };
    RegistrationProfileGenderRadio.prototype.createBody = function () {
        this.createBorder();
        this.createRadio();
        this.createSelection();
        this.createText();
    };
    RegistrationProfileGenderRadio.prototype.createBorder = function () {
        this.border = this.scene.make.ninePatch({
            x: 0,
            y: 0,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.GenderSelectionFrame,
            width: gameConfig.designWidth * 0.15,
            height: this.borderFrame.height * 1.3,
        });
        this.border.setOrigin(0.5);
        this.add(this.border);
    };
    RegistrationProfileGenderRadio.prototype.createRadio = function () {
        this.radio = this.scene.make.image({
            x: -this.border.width / 2.5,
            y: 0,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.GenderSelectionFrame,
        });
        this.radio.setOrigin(0.5);
        this.add(this.radio);
    };
    RegistrationProfileGenderRadio.prototype.createText = function () {
        this.text = this.scene.make.extBitmapText({
            x: 0,
            y: 0,
            text: this.config.text,
            font: BitmapFonts.RobotoSlab.Name,
            size: 40 * gameConfig.resolutionMultiplier,
        });
        this.text.setTint(0x08dbdb);
        this.text.setOrigin(0.5);
        this.add(this.text);
    };
    RegistrationProfileGenderRadio.prototype.createSelection = function () {
        this.selection = this.scene.make.image({
            x: this.radio.x,
            y: this.radio.y,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.GenderSelection,
        });
        this.selection.setScale(0);
        this.selection.setOrigin(0.5);
        this.add(this.selection);
    };
    RegistrationProfileGenderRadio.prototype.setListeners = function () {
        this.setInteractive();
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
    };
    RegistrationProfileGenderRadio.prototype.onPointerDown = function () {
        this.once(Phaser.Input.Events.POINTER_UP, this.onClick, this);
        this.once(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    RegistrationProfileGenderRadio.prototype.onPointerOut = function () {
        this.off(Phaser.Input.Events.POINTER_UP, this.onClick, this);
        this.off(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    RegistrationProfileGenderRadio.prototype.onClick = function () {
        this.scene.events.emit(RegistrationScene.PROFILE_ACTION_EVENT, this.config.actionId, this.config.gender);
    };
    Object.defineProperty(RegistrationProfileGenderRadio.prototype, "borderFrame", {
        get: function () {
            return this.scene.textures.getFrame(MultiAtlases.Login.Atlas.Name, MultiAtlases.Login.Atlas.Frames.GenderSelectionFrame);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationProfileGenderRadio.prototype, "isSelected", {
        get: function () {
            return this.selection.scaleX > 0;
        },
        enumerable: true,
        configurable: true
    });
    return RegistrationProfileGenderRadio;
}(Phaser.GameObjects
    .Container));
export default RegistrationProfileGenderRadio;
