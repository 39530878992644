var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BitmapFonts, MultiAtlases } from '../../assets';
import { Translation } from '../../translations';
import LevelUpPopupAvatar from '../components/popup/level-up/LevelUpPopupAvatar';
import LevelUpPopupButton from '../components/popup/level-up/LevelUpPopupButton';
import PopupTitle from '../components/popup/PopupTitle';
import SimpleButtonWithBitmapText from '../utils/simpleButton/SimpleButtonWithBitmapText';
import StandardPopup from './StandardPopup';
export var LevelUpPopupAction;
(function (LevelUpPopupAction) {
})(LevelUpPopupAction || (LevelUpPopupAction = {}));
var LevelUpPopup = /** @class */ (function (_super) {
    __extends(LevelUpPopup, _super);
    function LevelUpPopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LevelUpPopup.prototype.createBody = function () {
        this.createColoredBlocker(0.7);
        this.createBgImage(MultiAtlases.Flags.Atlas.Name, MultiAtlases.Flags.Atlas.Frames.FlagLeagueNone);
        this.createTitle();
        this.createButton();
    };
    LevelUpPopup.prototype.show = function (x, y, level, avatarKey) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.showBlocker()];
                                case 1:
                                    _a.sent();
                                    this.scene.tweens.add({
                                        targets: this,
                                        y: this.height * 0.5,
                                        duration: 200,
                                        onComplete: function () { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this.onShowComplete(level, avatarKey)];
                                                    case 1:
                                                        _a.sent();
                                                        resolve();
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); },
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    LevelUpPopup.prototype.onShowComplete = function (level, avatarKey) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.scene.tweens.add({
                                        targets: this.title,
                                        scaleX: 1,
                                        scaleY: 1,
                                        duration: 200,
                                        ease: Phaser.Math.Easing.Back.Out,
                                    });
                                    this.createAvatar(avatarKey, level);
                                    return [4 /*yield*/, this.avatar.show()];
                                case 1:
                                    _a.sent();
                                    this.scene.tweens.add({
                                        targets: this.button,
                                        scaleX: 1,
                                        scaleY: 1,
                                        duration: 200,
                                        ease: Phaser.Math.Easing.Back.Out,
                                    });
                                    _super.prototype.onShowComplete.call(this);
                                    resolve();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    LevelUpPopup.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.button.setEnabled(false);
                                    this.scene.tweens.add({
                                        targets: [this.button, this.title],
                                        scaleX: 0,
                                        scaleY: 0,
                                        ease: Phaser.Math.Easing.Back.In,
                                        duration: 100,
                                    });
                                    return [4 /*yield*/, this.avatar.hide()];
                                case 1:
                                    _a.sent();
                                    this.scene.tweens.add({
                                        targets: this,
                                        y: -this.height,
                                        duration: 200,
                                        onComplete: function () { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this.hideBlocker()];
                                                    case 1:
                                                        _a.sent();
                                                        this.emit(StandardPopup.HIDE_COMPLETE_EVENT);
                                                        resolve();
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); },
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    LevelUpPopup.prototype.createTitle = function () {
        var config = {
            frame: MultiAtlases.Popups.Atlas.Frames.LevelUpPopupTitle,
            text: Translation.LEVEL_UP_POPUP_TITLE,
            fontFamily: BitmapFonts.RobotoSlab.Name,
            fontSize: 60,
            fill: '#ffffff',
        };
        this.title = new PopupTitle(this.scene, config);
        this.title.y -= this.height * 0.36;
        this.add(this.title);
        this.title.setScale(0);
    };
    LevelUpPopup.prototype.createAvatar = function (avatarKey, level) {
        this.avatar = new LevelUpPopupAvatar(this.scene, {
            avatarKey: avatarKey,
            level: level,
        });
        this.avatar.y = -this.height * 0.1;
        this.add(this.avatar);
    };
    LevelUpPopup.prototype.createButton = function () {
        this.button = new LevelUpPopupButton(this.scene);
        this.add(this.button);
        this.button.y = this.height * 0.55;
        this.button.setScale(0);
        this.button.once(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction, this);
    };
    LevelUpPopup.NAME = "LevelUpPopup";
    LevelUpPopup.GET_REWARD_CLICKED_NOTIFICATION = LevelUpPopup.NAME + "GetRewardClickedNotification";
    return LevelUpPopup;
}(StandardPopup));
export default LevelUpPopup;
