var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fonts, MultiAtlases } from '../../../../../../assets';
import { gameConfig } from '../../../../../../constants/GameConfig';
import { Translation } from '../../../../../../translations';
import BaseEmojiScrollableElement from '../BaseEmojiScrollableElement';
var MessageScrollerElement = /** @class */ (function (_super) {
    __extends(MessageScrollerElement, _super);
    function MessageScrollerElement(scene, index) {
        var _this = _super.call(this, scene, index) || this;
        _this.createContent();
        return _this;
    }
    MessageScrollerElement.prototype.createContent = function () {
        this.createBackgroundImage();
        this.setSize(this.background.width, this.background.height);
        this.createMessageText();
    };
    MessageScrollerElement.prototype.createBackgroundImage = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Emojis.Atlas.Name,
            frame: MultiAtlases.Emojis.Atlas.Frames.MessageBackground,
        });
        this.add(this.background);
        this.background.setOrigin(0);
    };
    MessageScrollerElement.prototype.createMessageText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextBold.Name,
            fontSize: 36 * gameConfig.resolutionMultiplier,
            fill: '#69340D',
        };
        this.messageText = this.scene.make.extText({
            x: this.background.width / 2,
            y: this.background.height / 2,
            text: Translation["EMOJI_MESSAGE_" + this.index],
            style: style,
        });
        this.messageText.setOrigin(0.5);
        this.messageText.width > this.background.width - 10 &&
            this.messageText.setScale((this.background.width - 10) / this.messageText.width);
        this.add(this.messageText);
    };
    return MessageScrollerElement;
}(BaseEmojiScrollableElement));
export default MessageScrollerElement;
