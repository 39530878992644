var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Atlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import TextureGenerationContainer from '../../utils/plugins/textureGenerator/TextureGenerationContainer';
var TopBarBackgroundTexture = /** @class */ (function (_super) {
    __extends(TopBarBackgroundTexture, _super);
    function TopBarBackgroundTexture() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TopBarBackgroundTexture.prototype.createContent = function () {
        this.setKey(Atlases.Topbar.Atlas.Name + "-" + Atlases.Topbar.Atlas.Frames.Background);
        this.createBackgroundImage();
        this.setSize(this.background.width, this.background.height);
        this.x = this.background.width / 2;
        this.y = this.background.height / 2;
    };
    TopBarBackgroundTexture.prototype.createBackgroundImage = function () {
        var backgroundFrame = this.textures.getFrame(Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.Background);
        this.background = this.scene.add.ninePatch(0, 0, gameConfig.canvasWidth, backgroundFrame.height, Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.Background);
        this.add(this.background);
    };
    return TopBarBackgroundTexture;
}(TextureGenerationContainer));
export default TopBarBackgroundTexture;
