var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { getOpponentPositionX } from '../../utils/phaser/PhaserUtils';
import TextureGenerationContainer from '../../utils/plugins/textureGenerator/TextureGenerationContainer';
// TODO: generate background texture for 3 and 2 users too
var GameSceneBackgroundTexture = /** @class */ (function (_super) {
    __extends(GameSceneBackgroundTexture, _super);
    function GameSceneBackgroundTexture() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GameSceneBackgroundTexture.prototype.createContent = function () {
        this.setKey(MultiAtlases.GamePlay.Atlas.Name + "-" + MultiAtlases.GamePlay.Atlas.Frames.Background);
        this.createSceneBackground();
        this.setSize(gameConfig.canvasWidth, gameConfig.canvasHeight);
        this.x = 0;
        this.y = 0;
        this.createOpponentsBackground();
        this.createBorders();
        this.createControlBackground();
        this.createButtonBackground();
    };
    GameSceneBackgroundTexture.prototype.createSceneBackground = function () {
        this.background = this.scene.make.image({
            x: gameConfig.canvasWidth / 2,
            y: gameConfig.canvasHeight,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.Background,
        });
        this.background.setOrigin(0.5, 1);
        this.background.setScale(Math.max(gameConfig.canvasHeight / this.background.height, gameConfig.canvasWidth / this.background.width));
        this.add(this.background);
    };
    GameSceneBackgroundTexture.prototype.createBorders = function () {
        var leftFrame = this.scene.textures.getFrame(MultiAtlases.GamePlay.Atlas.Name, MultiAtlases.GamePlay.Atlas.Frames.ScreenFrameLeft);
        this.leftBorder = this.scene.make.ninePatch({
            x: 0,
            y: gameConfig.canvasHeight / 2,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.ScreenFrameLeft,
            height: gameConfig.canvasHeight,
            width: leftFrame.width,
        });
        this.leftBorder.setOrigin(0, 0.5);
        this.add(this.leftBorder);
        var rightFrame = this.scene.textures.getFrame(MultiAtlases.GamePlay.Atlas.Name, MultiAtlases.GamePlay.Atlas.Frames.ScreenFrameRight);
        this.rightBorder = this.scene.make.ninePatch({
            x: gameConfig.canvasWidth,
            y: gameConfig.canvasHeight / 2,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.ScreenFrameRight,
            height: gameConfig.canvasHeight,
            width: rightFrame.width,
        });
        this.rightBorder.setOrigin(1, 0.5);
        this.add(this.rightBorder);
    };
    GameSceneBackgroundTexture.prototype.createControlBackground = function () {
        this.controlBackground = this.scene.make.image({
            x: -6,
            y: gameConfig.canvasHeight * 0.52,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.ControlViewBackground,
        });
        this.controlBackground.setOrigin(0, 0.5);
        this.add(this.controlBackground);
    };
    GameSceneBackgroundTexture.prototype.createButtonBackground = function () {
        this.buttonBackground = this.scene.make.image({
            x: gameConfig.canvasWidth / 2,
            y: gameConfig.canvasHeight,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.ActionButtonBackground,
        });
        this.buttonBackground.setOrigin(0.5, 1);
        this.buttonBackground.setScale(1.2);
        this.add(this.buttonBackground);
    };
    GameSceneBackgroundTexture.prototype.createOpponentsBackground = function () {
        for (var i = 0; i < 3; i++) {
            var background = this.scene.make.image({
                x: getOpponentPositionX(i),
                y: 0,
                key: MultiAtlases.GamePlay.Atlas.Name,
                frame: MultiAtlases.GamePlay.Atlas.Frames.OpponentBackgroundDecoration,
            });
            background.setScale(1);
            background.setOrigin(0.5, 0);
            this.add(background);
        }
    };
    return GameSceneBackgroundTexture;
}(TextureGenerationContainer));
export default GameSceneBackgroundTexture;
