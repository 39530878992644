var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Audios } from '../../../assets';
var SpriteButton = /** @class */ (function (_super) {
    __extends(SpriteButton, _super);
    function SpriteButton(scene, configs, enabled) {
        var _this = _super.call(this, scene, 0, 0, configs.normalStateConfig.key, configs.normalStateConfig.frame) || this;
        _this.scene = scene;
        _this.normalState = configs.normalStateConfig;
        _this.hoverState = configs.hoverStateConfig;
        _this.downState = configs.downStateConfig;
        _this.disabledState = configs.disabledStateConfig;
        _this.setListeners();
        if (enabled) {
            _this.setEnabled(enabled);
        }
        return _this;
    }
    SpriteButton.prototype.setEnabled = function (isEnabled) {
        isEnabled ? this.makeEnabled() : this.makeDisabled();
    };
    SpriteButton.prototype.makeEnabled = function () {
        this.setNormalTexture();
        this.removeAllListeners();
        this.setListeners();
        this.input.enabled = true;
    };
    SpriteButton.prototype.makeDisabled = function () {
        this.input.enabled = false;
        if (this.disabledState) {
            this.setDisabledTexture();
        }
    };
    SpriteButton.prototype.playSfxOnDown = function () {
        //
    };
    SpriteButton.prototype.playSfxOnUp = function () {
        this.scene.playSFX(Audios.ButtonTapSfx.Name);
    };
    SpriteButton.prototype.setListeners = function () {
        this.setInteractive();
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
        this.on(Phaser.Input.Events.POINTER_OVER, this.onPointerOver, this);
        this.on(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    SpriteButton.prototype.onPointerDown = function () {
        this.downState && this.setDownTexture();
        this.once(Phaser.Input.Events.POINTER_UP, this.onClick, this);
    };
    SpriteButton.prototype.onPointerOver = function () {
        this.hoverState && this.setHoverTexture();
    };
    SpriteButton.prototype.onPointerOut = function () {
        this.off(Phaser.Input.Events.POINTER_UP, this.onClick, this);
        this.setNormalTexture();
    };
    SpriteButton.prototype.setNormalTexture = function () {
        this.setTexture(this.normalState.key, this.normalState.frame);
    };
    SpriteButton.prototype.setHoverTexture = function () {
        this.setTexture(this.hoverState.key, this.hoverState.frame);
    };
    SpriteButton.prototype.setDownTexture = function () {
        this.setTexture(this.downState.key, this.downState.frame);
    };
    SpriteButton.prototype.setDisabledTexture = function () {
        this.setTexture(this.disabledState.key, this.disabledState.frame);
    };
    SpriteButton.prototype.onClick = function () {
        this.hoverState ? this.setHoverTexture() : this.setNormalTexture();
        this.emit(SpriteButton.CLICK_EVENT);
    };
    SpriteButton.CLICK_EVENT = 'click';
    SpriteButton.OUT_EVENT = 'out';
    SpriteButton.OVER_EVENT = 'over';
    return SpriteButton;
}(Phaser.GameObjects.Sprite));
export { SpriteButton };
