var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Images } from '../../assets';
import { defaultValues } from '../../constants/Constants';
import { gameConfig } from '../../constants/GameConfig';
import { getNativeAvatarKey, isNativeAvatar } from '../../utils/Utils';
import CrashlyticsWrapper from '../../utils/wrappers/CrashlyticsWrapper';
import LoginFromOtherDeviceView from '../components/service/LoginFromOtherDeviceView';
import { loadImageFromUrl } from '../utils/phaser/PhaserUtils';
import BaseScene from './BaseScene';
var ServiceScene = /** @class */ (function (_super) {
    __extends(ServiceScene, _super);
    function ServiceScene() {
        var _this = _super.call(this, ServiceScene.NAME) || this;
        _this.musicVolume = 0.6;
        _this.sfxVolume = 1;
        return _this;
    }
    ServiceScene.prototype.preload = function () {
        this.SFXs = [];
        this.musics = [];
        this.musicManager = Phaser.Sound.SoundManagerCreator.create(this.game);
        this.sfxManager = Phaser.Sound.SoundManagerCreator.create(this.game);
        this.musicManager.volume = this.musicVolume;
        this.sfxManager.volume = this.sfxVolume;
    };
    ServiceScene.prototype.create = function () {
        this.createFadeImage();
    };
    ServiceScene.prototype.turnOffSounds = function () {
        this.musicManager.pauseAll();
        this.sfxManager.pauseAll();
    };
    ServiceScene.prototype.turnOnSounds = function () {
        this.musicManager.resumeAll();
        this.sfxManager.resumeAll();
    };
    ServiceScene.prototype.updatePlayerAvatarTexture = function (url) {
        var _this = this;
        if (isNativeAvatar(url)) {
            defaultValues.playerAvatar = getNativeAvatarKey(url);
            this.emitPlayerAvatarUpdated();
        }
        else {
            loadImageFromUrl(this, defaultValues.playerAvatar, url, true, this.textures.getBase64(Images.Avatar.Name)).then(function () {
                _this.emitPlayerAvatarUpdated();
            });
        }
    };
    ServiceScene.prototype.showLoginFromOtherDeviceView = function () {
        this.loginFromOtherDeviceView = new LoginFromOtherDeviceView(this);
        this.add.existing(this.loginFromOtherDeviceView);
    };
    ServiceScene.prototype.playMusic = function (key) {
        for (var _i = 0, _a = this.musics; _i < _a.length; _i++) {
            var music = _a[_i];
            try {
                music.key === key
                    ? !music.isPlaying &&
                        music.play({
                            volume: this.musicVolume,
                        })
                    : music.stop();
            }
            catch (error) {
                CrashlyticsWrapper.sendSignedUserException(error, 'ServiceScene.playMusic', null, null);
            }
        }
    };
    ServiceScene.prototype.updateMusicState = function (enabled) {
        this.musicManager.mute = !enabled;
    };
    ServiceScene.prototype.updateSoundState = function (enabled) {
        this.sfxManager.mute = !enabled;
    };
    ServiceScene.prototype.updateLanguage = function (lang) {
        this.i18n.changeLanguage(lang);
    };
    ServiceScene.prototype.screenFadeOut = function (color, duration, delay) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, (this.fadeOutPromise = new Promise(function (resolve) {
                        _this.scene.bringToTop(ServiceScene.NAME);
                        _this.fadeImage.setTint(color);
                        _this.fadeImage.alpha = 0;
                        var tweens = _this.tweens.getTweensOf(_this.fadeImage);
                        for (var _i = 0, tweens_1 = tweens; _i < tweens_1.length; _i++) {
                            var tween = tweens_1[_i];
                            tween.emit(Phaser.Tweens.Events.TWEEN_COMPLETE);
                        }
                        _this.tweens.killTweensOf(_this.fadeImage);
                        _this.tweens.add({
                            targets: _this.fadeImage,
                            alpha: 1,
                            duration: duration,
                            delay: delay,
                            onComplete: function () {
                                _this.scene.sendToBack(ServiceScene.NAME);
                                resolve();
                            },
                        });
                    }))];
            });
        });
    };
    ServiceScene.prototype.screenFadeIn = function (duration, delay) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, (this.fadeInPromise = new Promise(function (resolve) {
                        if (_this.fadeImage.alpha !== 1) {
                            resolve();
                            return;
                        }
                        _this.scene.bringToTop(ServiceScene.NAME);
                        var tweens = _this.tweens.getTweensOf(_this.fadeImage);
                        for (var _i = 0, tweens_2 = tweens; _i < tweens_2.length; _i++) {
                            var tween = tweens_2[_i];
                            tween.emit(Phaser.Tweens.Events.TWEEN_COMPLETE);
                        }
                        _this.tweens.killTweensOf(_this.fadeImage);
                        _this.tweens.add({
                            targets: _this.fadeImage,
                            alpha: 0,
                            duration: duration,
                            delay: delay,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    }))];
            });
        });
    };
    ServiceScene.prototype.createBackgroundMusics = function () {
        this.createLobbyBackgroundMusic();
        this.createGameBackgroundMusic();
    };
    ServiceScene.prototype.createFadeImage = function () {
        this.fadeImage = this.add.image(gameConfig.canvasWidth / 2, gameConfig.canvasHeight / 2, Images.WhitePixel.Name);
        this.fadeImage.setScale(gameConfig.canvasWidth / this.fadeImage.width, gameConfig.canvasHeight / this.fadeImage.height);
        this.fadeImage.setAlpha(0);
    };
    ServiceScene.prototype.createLobbyBackgroundMusic = function () {
        // const lobby: Phaser.Sound.BaseSound = this.musicManager.add(
        //   Audios.LobbyBackgroundMusic.Name,
        //   {
        //     loop: true,
        //     volume: this.musicVolume,
        //   },
        // );
        // lobby.stop();
        // this.musics.push(lobby);
    };
    ServiceScene.prototype.createGameBackgroundMusic = function () {
        // const game: Phaser.Sound.BaseSound = this.musicManager.add(
        //   Audios.GameBackgroundMusic.Name,
        //   {
        //     loop: true,
        //     volume: this.musicVolume,
        //   },
        // );
        // game.stop();
        // this.musics.push(game);
    };
    ServiceScene.prototype.playSFX = function (sfxKey) {
        // let sfx: Phaser.Sound.BaseSound = this.SFXs.find(
        //   (sfx: Phaser.Sound.BaseSound) => sfx.key === sfxKey,
        // );
        // if (!sfx) {
        //   sfx = this.sfxManager.add(sfxKey, {
        //     volume: this.sfxVolume,
        //   });
        //   this.SFXs.push(sfx);
        // }
        // sfx.play();
    };
    ServiceScene.prototype.stopSFX = function () {
        // for (const sfx of this.SFXs) {
        //   try {
        //     sfx.stop();
        //   } catch (error) {
        //     CrashlyticsWrapper.sendSignedUserException(
        //       error,
        //       'ServiceScene.stopSFX',
        //       null,
        //       null,
        //     );
        //   }
        // }
    };
    ServiceScene.prototype.emitPlayerAvatarUpdated = function () {
        this.events.emit(ServiceScene.PLAYER_AVATAR_UPDATED_EVENT);
    };
    ServiceScene.NAME = 'ServiceScene';
    ServiceScene.PLAY_SFX_EVENT = 'playSFX';
    ServiceScene.CONTINUE_EVENT = 'continue';
    ServiceScene.CLOSE_EVENT = 'close';
    ServiceScene.SETTINGS_CLICKED_EVENT = 'settingsClicked';
    ServiceScene.PLAYER_AVATAR_UPDATED_EVENT = 'playerAvatarUpdated';
    ServiceScene.CONTINUE_GAME_ON_THIS_DEVICE_NOTIFICATION = ServiceScene.NAME + "ContinueGameOnThisDeviceNotification";
    ServiceScene.CLOSE_APP_NOTIFICATION = ServiceScene.NAME + "CloseAppNotification";
    ServiceScene.GAME_RESUMED_NOTIFICATION = ServiceScene.NAME + "GameResumedNotification";
    ServiceScene.GAME_PAUSED_NOTIFICATION = ServiceScene.NAME + "GamePausedNotification";
    ServiceScene.PLAYER_AVATAR_UPDATED_NOTIFICATION = ServiceScene.NAME + "PlayerAvatarUpdated";
    return ServiceScene;
}(BaseScene));
export default ServiceScene;
