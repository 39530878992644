var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import GameScene from '../../view/scenes/GameScene';
import LobbyScene from '../../view/scenes/LobbyScene';
import RegistrationScene from '../../view/scenes/RegistrationScene';
import RoomScene from '../../view/scenes/RoomScene';
import WheelScene from '../../view/scenes/WheelScene';
import UiStateCommand from './UiStateCommand';
var ChangeCurrentSceneCommand = /** @class */ (function (_super) {
    __extends(ChangeCurrentSceneCommand, _super);
    function ChangeCurrentSceneCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChangeCurrentSceneCommand.prototype.execute = function (notificationName) {
        switch (notificationName) {
            case LobbyScene.SHOWN_NOTIFICATION:
                this.proxy.setCurrentScene(LobbyScene.NAME);
                break;
            case RoomScene.SHOW_COMPLETE_NOTIFICATION:
                this.proxy.setCurrentScene(RoomScene.NAME);
                break;
            case GameScene.CREATE_NOTIFICATION:
            case GameScene.RECREATE_NOTIFICATION:
                this.proxy.setCurrentScene(GameScene.NAME);
                break;
            case WheelScene.SHOWN_NOTIFICATION:
                this.proxy.setCurrentScene(WheelScene.NAME);
                break;
            case RegistrationScene.SHOWN_NOTIFICATION:
                this.proxy.setCurrentScene(RegistrationScene.NAME);
                break;
            default:
                break;
        }
    };
    return ChangeCurrentSceneCommand;
}(UiStateCommand));
export default ChangeCurrentSceneCommand;
