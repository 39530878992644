var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../../../assets';
export var EmojiType;
(function (EmojiType) {
    EmojiType[EmojiType["EMOJI"] = 0] = "EMOJI";
    EmojiType[EmojiType["TEXT"] = 1] = "TEXT";
})(EmojiType || (EmojiType = {}));
var MessageTypeButton = /** @class */ (function (_super) {
    __extends(MessageTypeButton, _super);
    function MessageTypeButton(scene, action) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.action = action;
        _this.createContent();
        return _this;
    }
    MessageTypeButton.prototype.setSelectState = function (enabled) {
        enabled ? this.makeSelected() : this.makeUnselected();
    };
    MessageTypeButton.prototype.makeUnselected = function () {
        this.setListeners();
        this.background.setTexture(MultiAtlases.Emojis.Atlas.Name, MultiAtlases.Emojis.Atlas.Frames.ButtonBackgroundPassive);
    };
    MessageTypeButton.prototype.makeSelected = function () {
        this.removeListeners();
        this.background.setTexture(MultiAtlases.Emojis.Atlas.Name, MultiAtlases.Emojis.Atlas.Frames.ButtonBackgroundActive);
    };
    MessageTypeButton.prototype.createContent = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createIcon();
    };
    MessageTypeButton.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Emojis.Atlas.Name,
            frame: MultiAtlases.Emojis.Atlas.Frames.ButtonBackgroundPassive,
        });
        this.add(this.background);
    };
    MessageTypeButton.prototype.createIcon = function () {
        this.icon = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Emojis.Atlas.Name,
            frame: this.action === EmojiType.EMOJI
                ? MultiAtlases.Emojis.Atlas.Frames.EmojiIcon
                : MultiAtlases.Emojis.Atlas.Frames.MessageIcon,
        });
        this.add(this.icon);
    };
    MessageTypeButton.prototype.setListeners = function () {
        this.setInteractive();
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
    };
    MessageTypeButton.prototype.removeListeners = function () {
        this.off(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
    };
    MessageTypeButton.prototype.onPointerDown = function () {
        this.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.once(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    MessageTypeButton.prototype.onPointerUp = function () {
        this.parentContainer.emit(MessageTypeButton.CLICK_EVENT, this.action);
    };
    MessageTypeButton.prototype.onPointerOut = function () {
        this.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.off(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    MessageTypeButton.CLICK_EVENT = 'typeClick';
    return MessageTypeButton;
}(Phaser.GameObjects.Container));
export { MessageTypeButton };
