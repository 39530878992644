var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import BaseStoreVOProxy from '../../model/store/BaseStoreVOProxy';
import InsufficientFundsPopup from '../popups/InsufficientFundsPopup';
import BaseSceneMediator from './BaseSceneMediator';
import StoreScene from './StoreScene';
import TopBarScene, { TopBarAction } from './TopBarScene';
var StoreSceneMediator = /** @class */ (function (_super) {
    __extends(StoreSceneMediator, _super);
    function StoreSceneMediator() {
        return _super.call(this, StoreSceneMediator.NAME, null) || this;
    }
    StoreSceneMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(BaseStoreVOProxy.NOT_SUPPORTED_NOTIFICATION, BaseStoreVOProxy.REGISTERED_NOTIFICATION, BaseStoreVOProxy.STORE_DATA_UPDATED_NOTIFICATION, BaseStoreVOProxy.INITIALIZE_FAILED_NOTIFICATION, TopBarScene.STORE_BUTTON_CLICKED_NOTIFICATION, TopBarScene.NAVIGATION_BUTTON_CLICKED_NOTIFICATION, TopBarScene.WHEEL_BUTTON_CLICKED_NOTIFICATION, InsufficientFundsPopup.EARN_NOTIFICATION, InsufficientFundsPopup.BUY_NOTIFICATION);
    };
    StoreSceneMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, currency, currencyKey, topBarAction;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.handleDefaultNotifications.apply(this, [notificationName].concat(args));
                        _a = notificationName;
                        switch (_a) {
                            case BaseStoreVOProxy.REGISTERED_NOTIFICATION: return [3 /*break*/, 1];
                            case BaseStoreVOProxy.STORE_DATA_UPDATED_NOTIFICATION: return [3 /*break*/, 2];
                            case TopBarScene.STORE_BUTTON_CLICKED_NOTIFICATION: return [3 /*break*/, 3];
                            case InsufficientFundsPopup.EARN_NOTIFICATION: return [3 /*break*/, 6];
                            case InsufficientFundsPopup.BUY_NOTIFICATION: return [3 /*break*/, 8];
                            case TopBarScene.WHEEL_BUTTON_CLICKED_NOTIFICATION: return [3 /*break*/, 10];
                            case TopBarScene.NAVIGATION_BUTTON_CLICKED_NOTIFICATION: return [3 /*break*/, 10];
                            case BaseStoreVOProxy.INITIALIZE_FAILED_NOTIFICATION: return [3 /*break*/, 13];
                            case BaseStoreVOProxy.NOT_SUPPORTED_NOTIFICATION: return [3 /*break*/, 14];
                        }
                        return [3 /*break*/, 15];
                    case 1:
                        this.startScene();
                        this.sceneManager.sleep(StoreScene.NAME);
                        return [3 /*break*/, 16];
                    case 2:
                        this.createOrUpdateItems();
                        return [3 /*break*/, 16];
                    case 3:
                        if (!this.sceneManager.isSleeping(StoreScene.NAME)) return [3 /*break*/, 5];
                        this.sceneManager.bringToTop(StoreScene.NAME);
                        this.sceneManager.wake(StoreScene.NAME);
                        return [4 /*yield*/, this.showBasedOnTopBarAction(args[0])];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        this.sendNotification(StoreScene.SHOWN_NOTIFICATION);
                        return [3 /*break*/, 16];
                    case 6:
                        this.sceneManager.bringToTop(StoreScene.NAME);
                        this.sceneManager.wake(StoreScene.NAME);
                        return [4 /*yield*/, this.viewComponent.show('free')];
                    case 7:
                        _b.sent();
                        this.sendNotification(StoreScene.SHOWN_NOTIFICATION);
                        return [3 /*break*/, 16];
                    case 8:
                        this.sceneManager.bringToTop(StoreScene.NAME);
                        this.sceneManager.wake(StoreScene.NAME);
                        currency = args[0];
                        currencyKey = currency.toUpperCase();
                        topBarAction = TopBarAction[currencyKey];
                        return [4 /*yield*/, this.showBasedOnTopBarAction(topBarAction)];
                    case 9:
                        _b.sent();
                        this.sendNotification(StoreScene.SHOWN_NOTIFICATION);
                        return [3 /*break*/, 16];
                    case 10:
                        if (!this.sceneManager.isActive(StoreScene.NAME)) return [3 /*break*/, 12];
                        return [4 /*yield*/, this.viewComponent.hide()];
                    case 11:
                        _b.sent();
                        this.sceneManager.sendToBack(StoreScene.NAME);
                        this.sceneManager.sleep(StoreScene.NAME);
                        this.sendNotification(StoreScene.HIDDEN_NOTIFICATION);
                        _b.label = 12;
                    case 12: return [3 /*break*/, 16];
                    case 13:
                        this.sceneManager.remove(StoreScene.NAME);
                        this.facade.removeMediator(StoreSceneMediator.NAME, this.id);
                        return [3 /*break*/, 16];
                    case 14:
                        this.sceneManager.remove(StoreScene.NAME);
                        this.facade.removeMediator(StoreSceneMediator.NAME);
                        return [3 /*break*/, 16];
                    case 15:
                        if (this.defaultNotifications.contains(notificationName)) {
                            return [3 /*break*/, 16];
                        }
                        console.warn(notificationName + " is unhandled!");
                        return [3 /*break*/, 16];
                    case 16: return [2 /*return*/];
                }
            });
        });
    };
    StoreSceneMediator.prototype.startScene = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.sceneManager.sendToBack(StoreScene.NAME);
                this.sceneManager.start(StoreScene.NAME);
                return [2 /*return*/];
            });
        });
    };
    StoreSceneMediator.prototype.createOrUpdateItems = function () {
        var contents = this.storeVOProxy.vo.mergedData;
        var sections = Object.keys(contents).filter(function (section) {
            return !!contents[section] && contents[section].length > 0;
        });
        var tabsCount = this.viewComponent.getTabsCount();
        this.viewComponent.createOrUpdateTabs(sections);
        this.viewComponent.updateContents(contents, sections);
        tabsCount !== sections.length &&
            !this.sceneManager.isSleeping(StoreScene.NAME) &&
            this.viewComponent.show();
    };
    StoreSceneMediator.prototype.showBasedOnTopBarAction = function (action) {
        return __awaiter(this, void 0, void 0, function () {
            var targetTab;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        switch (action) {
                            case TopBarAction.GOLD:
                                targetTab = 'gold';
                                break;
                            case TopBarAction.CRYSTAL:
                                targetTab = 'crystal';
                                break;
                            default:
                                break;
                        }
                        this.sceneManager.bringToTop(TopBarScene.NAME);
                        return [4 /*yield*/, this.viewComponent.show(targetTab)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StoreSceneMediator.prototype.setView = function () {
        var storeScene = new StoreScene();
        this.sceneManager.add(StoreScene.NAME, storeScene);
        this.setViewComponent(storeScene);
    };
    StoreSceneMediator.prototype.setViewComponentListeners = function () {
        _super.prototype.setViewComponentListeners.call(this);
        this.viewComponent.events.on(StoreScene.STORE_ITEM_CLICK_EVENT, this.onStoreItemClick, this);
        this.viewComponent.events.on(StoreScene.STORE_TAB_CLICK_EVENT, this.onTabSwitch, this);
    };
    StoreSceneMediator.prototype.onStoreItemClick = function (section, config) {
        this.sendNotification(StoreScene.STORE_ITEM_CLICK_NOTIFICATION, section, config);
    };
    StoreSceneMediator.prototype.onTabSwitch = function (section) {
        this.viewComponent.onTabSwitch(section);
        this.sendNotification(StoreScene.STORE_TAB_CLICK_NOTIFICATION, section);
    };
    Object.defineProperty(StoreSceneMediator.prototype, "storeVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(BaseStoreVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    StoreSceneMediator.NAME = 'StoreSceneMediator';
    return StoreSceneMediator;
}(BaseSceneMediator));
export default StoreSceneMediator;
