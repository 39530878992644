var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import PlayerVOProxy from '../../model/PlayerVOProxy';
import SocketVOProxy from '../../model/SocketVOProxy';
import StateVOProxy from '../../model/StateVOProxy';
import { calculateLevelAndProgress, isNativeAvatar, } from '../../utils/Utils';
import OpponentView from '../components/game/OpponentView';
import PlayerAvatarView from '../components/game/PlayerAvatarView';
import GameScene from '../scenes/GameScene';
import TopBarScene from '../scenes/TopBarScene';
import { getFrame, getScene } from '../utils/phaser/PhaserUtils';
import LogOutConfirmationPopup from './LogOutConfirmationPopup';
import StandardPopupMediator from './StandardPopupMediator';
import UserInfoPopup, { UserInfoPopupAction, } from './UserInfoPopup';
var UserInfoPopupMediator = /** @class */ (function (_super) {
    __extends(UserInfoPopupMediator, _super);
    function UserInfoPopupMediator() {
        return _super.call(this, UserInfoPopupMediator.NAME) || this;
    }
    UserInfoPopupMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(TopBarScene.AVATAR_CLICKED_NOTIFICATION, PlayerAvatarView.AVATAR_CLICKED_NOTIFICATION, StateVOProxy.NEXT_PLAYER_NOTIFICATION, OpponentView.AVATAR_CLICKED_NOTIFICATION, LogOutConfirmationPopup.CANCELED_NOTIFICATION);
    };
    UserInfoPopupMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        switch (notificationName) {
            case LogOutConfirmationPopup.CANCELED_NOTIFICATION:
            case TopBarScene.AVATAR_CLICKED_NOTIFICATION:
            case PlayerAvatarView.AVATAR_CLICKED_NOTIFICATION:
                var playerVOProxy = this.facade.retrieveProxy(PlayerVOProxy.NAME);
                var wins = playerVOProxy.vo.playerStatistics.wins;
                var loses = playerVOProxy.vo.playerStatistics.loses;
                var outs = playerVOProxy.vo.playerStatistics.outs;
                var draws = playerVOProxy.vo.playerStatistics.draws;
                var total = wins + loses + outs + draws;
                var gameScene = getScene(GameScene.NAME);
                var data = {
                    userId: playerVOProxy.vo.userId,
                    userIndex: 3,
                    name: playerVOProxy.vo.name,
                    wins: wins,
                    loses: loses,
                    draws: draws,
                    leaves: playerVOProxy.vo.playerStatistics.outs,
                    total: total,
                    level: playerVOProxy.vo.playerLevel.level,
                    gender: playerVOProxy.vo.gender,
                    isNative: isNativeAvatar(playerVOProxy.vo.avatarUrl),
                    logoutButtonEnabled: gameScene.sys.isSleeping(),
                    avatar: playerVOProxy.vo.avatarUrl
                };
                var levelData = calculateLevelAndProgress(playerVOProxy.vo.playerLevel.xp);
                var progress = levelData.progress;
                this.showView(data, progress);
                break;
            case StateVOProxy.NEXT_PLAYER_NOTIFICATION:
                {
                    var stateVOProxy = this.facade.retrieveProxy(StateVOProxy.NAME);
                    if (stateVOProxy.vo.turnData.currentPlayer.userId ===
                        stateVOProxy.vo.players.length - 1 &&
                        !!this.viewComponent) {
                        this.hideView();
                    }
                }
                break;
            case OpponentView.AVATAR_CLICKED_NOTIFICATION:
                {
                    var index = args[0];
                    var stateVOProxy = this.facade.retrieveProxy(StateVOProxy.NAME);
                    if (!stateVOProxy.vo.players || !stateVOProxy.vo.players[index]) {
                        break;
                    }
                    var playerData = stateVOProxy.vo.players[index];
                    var wins_1 = playerData.wins;
                    var loses_1 = playerData.loses || 0;
                    var draws_1 = playerData.draws || 0;
                    var total_1 = playerData.total;
                    var data_1 = {
                        userId: playerData.userId,
                        userIndex: index,
                        name: playerData.name,
                        wins: wins_1,
                        loses: loses_1,
                        draws: draws_1,
                        leaves: playerData.leaves,
                        total: total_1,
                        level: playerData.level,
                        gender: playerData.gender,
                        isNative: isNativeAvatar(playerData.avatar),
                        logoutButtonEnabled: false,
                        avatar: playerData.avatar
                    };
                    var progress_1 = 0;
                    this.showView(data_1, progress_1);
                }
                break;
            default:
                break;
        }
    };
    UserInfoPopupMediator.prototype.createView = function () {
        _super.prototype.createView.call(this, new UserInfoPopup());
    };
    UserInfoPopupMediator.prototype.showView = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var flagHandleFrame = getFrame(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.UserInfoPopupFlagHandle);
        _super.prototype.showView.apply(this, [gameConfig.canvasWidth / 2 + flagHandleFrame.width * 0.17,
            gameConfig.canvasHeight / 2 + flagHandleFrame.height * 1.2].concat(args));
    };
    UserInfoPopupMediator.prototype.onAction = function (action) {
        switch (action) {
            case UserInfoPopupAction.LOGOUT:
                this.sendNotification(UserInfoPopup.LOGOUT_CLICKED_NOTIFICATION);
                break;
            default:
                break;
        }
        _super.prototype.onAction.call(this, action);
    };
    Object.defineProperty(UserInfoPopupMediator.prototype, "socketVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(SocketVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    UserInfoPopupMediator.NAME = 'UserInfoPopupMediator';
    return UserInfoPopupMediator;
}(StandardPopupMediator));
export default UserInfoPopupMediator;
