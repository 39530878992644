var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { I18nPlugin } from '@candywings/phaser3-i18n-plugin';
import { MultiAtlases, Fonts } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var RegistrationProfileMessage = /** @class */ (function (_super) {
    __extends(RegistrationProfileMessage, _super);
    function RegistrationProfileMessage(scene, value) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.value = value;
        _this.createBody();
        _this.scene.i18n.on(I18nPlugin.LANGUAGE_CHANGED_EVENT, _this.updateBackgroundSize, _this);
        return _this;
    }
    RegistrationProfileMessage.prototype.updateBackgroundSize = function () {
        this.background.resize(this.text.text.length * this.text.height * 0.4 + 100, this.background.height);
        this.setSize(this.background.width, this.background.height);
        this.icon.x =
            this.background.x + this.background.width * 0.5 + this.icon.width * 0.6;
    };
    RegistrationProfileMessage.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createText();
        this.createIcon();
        this.updateBackgroundSize();
    };
    RegistrationProfileMessage.prototype.createBackground = function () {
        var frame = this.scene.textures.getFrame(MultiAtlases.Login.Atlas.Name, MultiAtlases.Login.Atlas.Frames.MessageBox);
        this.background = this.scene.make.ninePatch({
            x: 0,
            y: 0,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.MessageBox,
            width: frame.width * 10,
            height: frame.height,
        });
        this.background.setAlpha(0.8);
        this.add(this.background);
    };
    RegistrationProfileMessage.prototype.createText = function () {
        this.text = this.scene.make.extText({
            x: 0,
            y: 0,
            text: this.value,
            style: {
                fontFamily: Fonts.RobotoSlab.Name,
                fontSize: 36 * gameConfig.resolutionMultiplier,
                fill: "#e5c12d"
            }
        });
        // this.text.setTint(0xe5c12d);
        this.text.setOrigin(0.5, 0.7);
        this.add(this.text);
    };
    RegistrationProfileMessage.prototype.createIcon = function () {
        this.icon = this.scene.make.image({
            x: 0,
            y: this.background.y,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.WarningIcon,
        });
        this.icon.x =
            this.background.x +
                this.background.width * 0.5 +
                this.icon.displayWidth * 0.6;
        this.add(this.icon);
    };
    return RegistrationProfileMessage;
}(Phaser.GameObjects
    .Container));
export default RegistrationProfileMessage;
