var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
import { StoreItemActionType } from '../../../../model/VO/StoreVO';
var StoreItemPrice = /** @class */ (function (_super) {
    __extends(StoreItemPrice, _super);
    function StoreItemPrice(scene, price, action, x, y) {
        var _this = _super.call(this, scene, x, y) || this;
        _this.scene = scene;
        _this.price = price;
        _this.action = action;
        _this.createBody();
        return _this;
    }
    StoreItemPrice.prototype.updateText = function (value, action, force) {
        if (force === void 0) { force = false; }
        this.price = value;
        this.action = action;
        this.text.setText(this.action === StoreItemActionType.BUY || force
            ? this.price
            : "store-action-" + this.action);
    };
    StoreItemPrice.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createText();
    };
    StoreItemPrice.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames.PriceBg,
        });
        this.add(this.background);
    };
    StoreItemPrice.prototype.createText = function () {
        this.text = this.scene.make.extBitmapText({
            x: 0,
            y: 0,
            text: this.action === StoreItemActionType.BUY
                ? this.price
                : "store-action-" + this.action,
            font: BitmapFonts.RobotoSlab.Name,
            size: 66 * gameConfig.resolutionMultiplier,
        });
        this.text.setTint(0x58412b);
        this.text.setCenterAlign();
        this.text.setOrigin(0.5);
        this.add(this.text);
    };
    return StoreItemPrice;
}(Phaser.GameObjects.Container));
export default StoreItemPrice;
