var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../assets';
import ServiceScene from '../../scenes/ServiceScene';
import SimpleButton from '../../utils/simpleButton/SimpleButton';
var SettingsButton = /** @class */ (function (_super) {
    __extends(SettingsButton, _super);
    function SettingsButton(scene) {
        var _this = this;
        var normalStateConfig = {
            key: MultiAtlases.Lobby.Atlas.Name,
            frame: MultiAtlases.Lobby.Atlas.Frames.SettingsNormal,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Lobby.Atlas.Name,
            frame: MultiAtlases.Lobby.Atlas.Frames.SettingsHover,
        };
        var downStateConfig = {
            key: MultiAtlases.Lobby.Atlas.Name,
            frame: MultiAtlases.Lobby.Atlas.Frames.SettingsDown,
        };
        var configs = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        _this = _super.call(this, scene, configs) || this;
        _this.scene.add.existing(_this);
        _this.setEnabled(true);
        _this.createAdditionalBg();
        _this.setScrollFactor(0);
        return _this;
    }
    SettingsButton.prototype.onClick = function () {
        this.scene.events.emit(ServiceScene.SETTINGS_CLICKED_EVENT);
    };
    SettingsButton.prototype.createAdditionalBg = function () {
        this.additionalBg = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Lobby.Atlas.Name,
            frame: MultiAtlases.Lobby.Atlas.Frames.SettingsButtonBg,
        });
        this.additionalBg.x = -this.additionalBg.width * 0.01;
        this.add(this.additionalBg);
        this.sendToBack(this.additionalBg);
    };
    return SettingsButton;
}(SimpleButton));
export default SettingsButton;
