var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases, Audios } from '../../../assets';
export var GameTimerType;
(function (GameTimerType) {
    GameTimerType[GameTimerType["DEFENCE"] = 0] = "DEFENCE";
    GameTimerType[GameTimerType["ATTACK"] = 1] = "ATTACK";
})(GameTimerType || (GameTimerType = {}));
var GameTimer = /** @class */ (function (_super) {
    __extends(GameTimer, _super);
    function GameTimer(scene) {
        var _this = _super.call(this, scene) || this;
        _this.progress = 0;
        _this.duration = 10000;
        _this.playTurnSfx = false;
        _this.createBody();
        return _this;
    }
    GameTimer.prototype.show = function () {
        this.masker.clear();
        this.masker.lineStyle(75, 0xffffff);
        var transformMatrix = this.circle.getWorldTransformMatrix();
        this.masker.beginPath();
        var angle = 360 - 45;
        this.masker.arc(transformMatrix.tx, transformMatrix.ty, this.displayWidth * 0.325, Phaser.Math.DegToRad(-45), Phaser.Math.DegToRad(angle), false, 0.02);
        this.masker.strokePath();
    };
    GameTimer.prototype.hide = function () {
        this.masker.clear();
    };
    GameTimer.prototype.preDestroy = function () {
        this.timerTween && this.timerTween.stop();
        this.circle.clearMask();
        this.progressGlow.clearMask();
        this.masker.destroy();
        _super.prototype.preDestroy.call(this);
    };
    GameTimer.prototype.setPosition = function (x, y, z, w) {
        _super.prototype.setPosition.call(this, x, y, z, w);
        this.masker && this.updateMask();
        return this;
    };
    GameTimer.prototype.updateMask = function () {
        this.masker.clear();
        this.masker.lineStyle(75, 0xffffff);
        var transformMatrix = this.circle.getWorldTransformMatrix();
        this.masker.beginPath();
        var angle = this.progress * 360 - 45;
        this.masker.arc(transformMatrix.tx, transformMatrix.ty, this.displayWidth * 0.325, Phaser.Math.DegToRad(-45), Phaser.Math.DegToRad(angle), false, 0.02);
        this.masker.strokePath();
        this.progressGlow.setAngle(angle);
        if (this.playTurnSfx && this.progress > 0.5) {
            this.playTurnSfx = false;
            this.scene.playSFX(Audios.MyTurn.Name);
        }
    };
    GameTimer.prototype.setDuration = function (duration) {
        this.duration = duration;
    };
    GameTimer.prototype.pause = function () {
        this.timerTween && this.timerTween.pause();
    };
    GameTimer.prototype.resume = function () {
        this.timerTween && this.timerTween.resume();
    };
    GameTimer.prototype.start = function () {
        this.progress = 0;
        this.timerTween = this.scene.tweens.add({
            targets: this,
            progress: 1,
            duration: this.duration,
            onUpdate: this.updateMask,
            onUpdateScope: this,
            onComplete: this.onComplete,
            onCompleteScope: this,
        });
        this.scene.tweens.add({
            targets: [this.glow, this.progressGlow],
            alpha: 0.7,
            duration: 600,
            yoyo: true,
            repeat: -1,
        });
        this.emit(GameTimer.START_EVENT);
    };
    GameTimer.prototype.stop = function () {
        this.timerTween && this.timerTween.stop();
        this.scene.tweens.killTweensOf([this.glow, this.progressGlow]);
        this.emit(GameTimer.STOP_EVENT);
        this.progress = 0;
        this.updateMask();
    };
    GameTimer.prototype.prepare = function (type) {
        this.glow.setTexture(MultiAtlases.GamePlay.Atlas.Name, type === GameTimerType.ATTACK
            ? MultiAtlases.GamePlay.Atlas.Frames.TimerAttackGlow
            : MultiAtlases.GamePlay.Atlas.Frames.TimerDefenseGlow);
        this.progressGlow.setTexture(MultiAtlases.GamePlay.Atlas.Name, type === GameTimerType.ATTACK
            ? MultiAtlases.GamePlay.Atlas.Frames.TimerAttackGlow
            : MultiAtlases.GamePlay.Atlas.Frames.TimerDefenseGlow);
        this.circle.setTexture(MultiAtlases.GamePlay.Atlas.Name, type === GameTimerType.ATTACK
            ? MultiAtlases.GamePlay.Atlas.Frames.TimerAttackLine
            : MultiAtlases.GamePlay.Atlas.Frames.TimerDefenseLine);
    };
    GameTimer.prototype.createBody = function () {
        this.createCircle();
        this.setSize(this.circle.width * 1.1, this.circle.height);
        this.createGlow();
        this.createProgressGlow();
        this.createMask();
    };
    GameTimer.prototype.createCircle = function () {
        this.circle = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.TimerAttackLine,
        });
        this.add(this.circle);
    };
    GameTimer.prototype.createGlow = function () {
        this.glow = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.TimerAttackGlow,
        });
        this.glow.setAngle(-45);
        this.add(this.glow);
    };
    GameTimer.prototype.createProgressGlow = function () {
        this.progressGlow = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.TimerAttackGlow,
        });
        this.progressGlow.setScale(1, -1);
        this.progressGlow.setAngle(-45);
        this.add(this.progressGlow);
    };
    GameTimer.prototype.createMask = function () {
        this.masker = this.scene.make.graphics({});
        var mask = new Phaser.Display.Masks.GeometryMask(this.scene, this.masker);
        this.glow.setMask(mask);
        this.progressGlow.setMask(mask);
        this.circle.setMask(mask);
    };
    GameTimer.prototype.onComplete = function () {
        this.scene.tweens.killTweensOf([this.glow, this.progressGlow]);
        this.emit(GameTimer.COMPLETE_EVENT);
        this.progress = 0;
        this.updateMask();
    };
    GameTimer.START_EVENT = 'start';
    GameTimer.STOP_EVENT = 'stop';
    GameTimer.COMPLETE_EVENT = 'complete';
    return GameTimer;
}(Phaser.GameObjects.Container));
export default GameTimer;
