var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gameConfig } from '../../../constants/GameConfig';
import { ValidationActionType } from '../../../model/VO/SocketVO';
import { calculatePositions } from '../../../utils/Utils';
import BaseTurnButton from '../../utils/turnButton/BaseTurnButton';
import CardView from './CardView';
import DurakPlayerView from './DurakPlayerView';
import GameActionButton from './GameActionButton';
var PlayerView = /** @class */ (function (_super) {
    __extends(PlayerView, _super);
    function PlayerView(scene) {
        var _this = _super.call(this, scene) || this;
        _this.cards = [];
        _this.createActionButton();
        return _this;
    }
    PlayerView.prototype.checkAndRemoveCard = function (cardData) {
        this.cards.remove(this.cards.find(function (card) { return card.suit === cardData.suit && card.value === cardData.value; }));
        this.selectedCard = null;
    };
    PlayerView.prototype.clearCards = function () {
        var _this = this;
        if (this.cards && this.cards.length > 0) {
            this.scene.tweens.killTweensOf(this.cards);
            this.disableAction();
            this.scene.tweens.add({
                targets: this.cards,
                y: gameConfig.canvasHeight + this.cards[0].height,
                duration: 200,
                ease: Phaser.Math.Easing.Expo.InOut,
                onComplete: function () {
                    for (var _i = 0, _a = _this.cards; _i < _a.length; _i++) {
                        var card = _a[_i];
                        card.destroy();
                    }
                    _this.cards = [];
                },
            });
        }
    };
    PlayerView.prototype.preDestroy = function () {
        this.scene.tweens.killTweensOf(this.cards);
        for (var _i = 0, _a = this.cards; _i < _a.length; _i++) {
            var card = _a[_i];
            card.destroy();
        }
        this.actionButton.destroy();
        this.removeAllListeners();
        this.removeAllSceneListeners();
    };
    PlayerView.prototype.getBackSelectedCard = function () {
        !!this.selectedCard &&
            !this.cards.contains(this.selectedCard) &&
            this.cards.push(this.selectedCard);
    };
    PlayerView.prototype.validateCards = function (availableCards) {
        if (!this.cards.length) {
            return;
        }
        !!this.selectedCard && this.cards.push(this.selectedCard);
        this.selectedCard = null;
        var cardsToValidate = [];
        for (var _i = 0, availableCards_1 = availableCards; _i < availableCards_1.length; _i++) {
            var availableCard = availableCards_1[_i];
            var cardToValidate = this.getCard(availableCard.card);
            !!cardToValidate && cardsToValidate.push(cardToValidate);
        }
        this.scene.input.setTopOnly(true);
        for (var i = 0; i < availableCards.length; i++) {
            var availableCard = availableCards[i];
            var playableCard = cardsToValidate[i];
            this.setCardInputs(playableCard, availableCard.targets, availableCard.canSwap);
        }
        this.darkerCardsBasedOnInputState();
        cardsToValidate.length && this.setSceneListeners();
    };
    PlayerView.prototype.setAction = function (type) {
        this.actionButton.setAction(type);
    };
    PlayerView.prototype.disableAction = function () {
        this.disableCards();
        this.actionButton.setAction(ValidationActionType.DISABLED);
    };
    PlayerView.prototype.repositionCards = function (enlight) {
        if (enlight === void 0) { enlight = true; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                        var positions, promises, _loop_1, this_1, i;
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.cards = this.cards.filter(function (card) { return !!card; });
                                    enlight && this.lightenCards();
                                    if (this.cards.length === 0) {
                                        return [2 /*return*/, resolve()];
                                    }
                                    positions = calculatePositions(this.cards);
                                    this.scene.tweens.killTweensOf(this.cards);
                                    promises = [];
                                    _loop_1 = function (i) {
                                        var card = this_1.cards[i];
                                        card.setDepth(2 * i);
                                        var duration = card.y < positions[i].y ? 300 : 150;
                                        var promise = new Promise(function (resolve) {
                                            _this.scene.tweens.add({
                                                targets: card,
                                                ease: Phaser.Math.Easing.Expo.InOut,
                                                duration: duration,
                                                angle: 0,
                                                x: positions[i].x,
                                                y: positions[i].y,
                                                scaleX: 1,
                                                scaleY: 1,
                                                onComplete: function () {
                                                    !card.isOpen && _this.openDealedCard(card);
                                                    resolve();
                                                },
                                            });
                                        });
                                        promises.push(promise);
                                    };
                                    this_1 = this;
                                    for (i = 0; i < this.cards.length; i++) {
                                        _loop_1(i);
                                    }
                                    return [4 /*yield*/, Promise.all(promises)];
                                case 1:
                                    _a.sent();
                                    resolve();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    PlayerView.prototype.repositionCardsToHighlight = function () {
        var _this = this;
        // const cards: CardView[] = sortCardsArray(
        //   this.cards.slice(),
        //   this.trumpSuit,
        // );
        this.scene.tweens.killTweensOf(this.cards);
        var distanceDif = this.selectedCard.width * 0.1;
        var cardsFromLeft = this.cards.filter(function (card) { return card.x < _this.selectedCard.x; });
        var cardsFromRight = this.cards.filter(function (card) { return card.x > _this.selectedCard.x; });
        var positions = calculatePositions(this.cards);
        // left
        for (var i = 0; i < cardsFromLeft.length; i++) {
            var card = cardsFromLeft[i];
            var cardRealIndex = this.cards.indexOf(card);
            card.setDepth(cardRealIndex * 2);
            this.scene.tweens.add({
                targets: card,
                x: positions[cardRealIndex].x - distanceDif,
                y: positions[cardRealIndex].y,
                scaleX: 1,
                scaleY: 1,
                duration: 100,
                ease: Phaser.Math.Easing.Expo.InOut,
            });
        }
        // right
        for (var i = 0; i < cardsFromRight.length; i++) {
            var card = cardsFromRight[i];
            var cardRealIndex = this.cards.indexOf(card);
            this.scene.tweens.add({
                targets: card,
                x: positions[cardRealIndex].x + distanceDif,
                y: positions[cardRealIndex].y,
                scaleX: 1,
                scaleY: 1,
                duration: 100,
                ease: Phaser.Math.Easing.Expo.InOut,
            });
        }
        this.scene.tweens.killTweensOf(this.selectedCard);
        this.scene.tweens.add({
            targets: this.selectedCard,
            x: positions[this.cards.indexOf(this.selectedCard)].x,
            y: gameConfig.canvasHeight - 10,
            duration: 100,
            ease: Phaser.Math.Easing.Expo.InOut,
        });
    };
    PlayerView.prototype.getCards = function (cards) {
        return __awaiter(this, void 0, void 0, function () {
            var _i, cards_1, card;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        for (_i = 0, cards_1 = cards; _i < cards_1.length; _i++) {
                            card = cards_1[_i];
                            card.setInteractive();
                            card.input.enabled = false;
                            this.cards.push(card);
                        }
                        return [4 /*yield*/, this.repositionCards()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PlayerView.prototype.setTrump = function (suit) {
        if (!!this.trumpSuit) {
            return;
        }
        this.trumpSuit = suit;
    };
    PlayerView.prototype.darkerCardsBasedOnInputState = function () {
        for (var _i = 0, _a = this.cards; _i < _a.length; _i++) {
            var card = _a[_i];
            card.setDarkerState();
        }
    };
    PlayerView.prototype.lightenCards = function () {
        for (var _i = 0, _a = this.cards; _i < _a.length; _i++) {
            var card = _a[_i];
            card.setDarkerState(false);
        }
    };
    PlayerView.prototype.createCards = function () {
        var cardsData = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            cardsData[_i] = arguments[_i];
        }
        for (var _a = 0, cardsData_1 = cardsData; _a < cardsData_1.length; _a++) {
            var cardData = cardsData_1[_a];
            var card = new CardView(this.scene, gameConfig.canvasWidth / 2, gameConfig.canvasHeight + 100, cardData.suit, cardData.value);
            this.scene.add.existing(card);
            this.setCardEvents(card);
            this.cards.push(card);
        }
        this.repositionCards();
    };
    PlayerView.prototype.getDealedCard = function (card) {
        var _this = this;
        new Promise(function (resolve) {
            if (!card) {
                return resolve();
            }
            _this.cards.push(card);
            _this.setCardEvents(card);
            _this.scene.tweens.add({
                targets: card,
                scaleX: 1,
                scaleY: 1,
                x: gameConfig.canvasWidth / 2,
                y: gameConfig.canvasHeight + card.height,
                duration: 750,
                angle: 180,
                onComplete: function () {
                    _this.openDealedCard(card);
                    resolve();
                },
            });
        });
    };
    PlayerView.prototype.getLastPlayedCard = function (data) {
        var cards = this.cards.slice();
        !!this.selectedCard && cards.push(this.selectedCard);
        this.selectedCard = null;
        var lastPlayedCard = cards.filter(function (card) {
            return card.suit === data.suit && card.value === data.value;
        })[0];
        this.cards.remove(lastPlayedCard);
        // this.repositionCards();
        return lastPlayedCard;
    };
    PlayerView.prototype.setCardInputs = function (playableCard, targets, swap) {
        playableCard.targets = targets;
        playableCard.swapEnabled = swap;
        playableCard.input.enabled = true;
    };
    PlayerView.prototype.selectTheCard = function (card) {
        if (!card || !this.cards.contains(card)) {
            this.selectedCard = null;
            return;
        }
        this.selectedCard = card;
        this.repositionCardsToHighlight();
        this.emit(PlayerView.CARD_SELECTED_EVENT, this.selectedCard);
    };
    PlayerView.prototype.unselectTheCard = function (reValidate) {
        if (reValidate === void 0) { reValidate = false; }
        if (!this.selectedCard) {
            return;
        }
        this.selectedCard = null;
        this.emitCardUnselectedEvent(reValidate);
        !reValidate && this.repositionCards();
    };
    PlayerView.prototype.disableCards = function () {
        for (var _i = 0, _a = this.cards; _i < _a.length; _i++) {
            var card = _a[_i];
            card.input.enabled = false;
            card.targets = [];
            card.swapEnabled = false;
        }
    };
    PlayerView.prototype.setCardEvents = function (card) {
        card.setInteractive();
        this.scene.input.setDraggable(card);
        card.input.enabled = false;
    };
    PlayerView.prototype.createActionButton = function () {
        this.actionButton = new GameActionButton(this.scene);
        this.scene.add.existing(this.actionButton);
        this.actionButton.setDepth(-100);
        this.actionButton.on(BaseTurnButton.CLICK_EVENT, this.onActionButtonClick, this);
    };
    PlayerView.prototype.setSceneListeners = function () {
        this.removeAllSceneListeners();
        this.scene.input.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
    };
    PlayerView.prototype.onPointerDown = function (pointer) {
        this.selectedCard = null;
        this.scene.input.on(Phaser.Input.Events.POINTER_MOVE, this.onPointerMove, this);
        this.scene.input.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        var targetCard = this.getPointerTargetCard(pointer);
        if (!targetCard || !this.cards.contains(targetCard)) {
            this.selectedCard = null;
            return;
        }
        this.selectedCard = targetCard;
        var possibleActionsCount = (this.selectedCard.swapEnabled ? 1 : 0) +
            (this.selectedCard.targets ? this.selectedCard.targets.length : 0);
        if (possibleActionsCount > 1) {
            this.selectTheCard(targetCard);
            this.emit(PlayerView.CARD_SELECTED_EVENT, this.selectedCard);
        }
    };
    PlayerView.prototype.onPointerMove = function (pointer) {
        var targetCard = this.getPointerTargetCard(pointer);
        if (!targetCard) {
            return this.unselectTheCard(true);
        }
        if (this.selectedCard !== targetCard) {
            this.selectTheCard(targetCard);
        }
    };
    PlayerView.prototype.onPointerUp = function (pointer) {
        // this.scene.tweens.killTweensOf(this.cards);
        this.removeAllSceneListeners();
        this.scene.input.on(Phaser.Input.Events.POINTER_UP, this.checkForSelectionCancellation, this);
        if (!this.selectedCard) {
            this.unselectTheCard(true);
            this.setSceneListeners();
            return;
        }
        if (Phaser.Geom.Rectangle.Contains(this.selectedCard.getBounds(), pointer.x, pointer.y)) {
            var selectedCardTargetsCount = (this.selectedCard.targets ? this.selectedCard.targets.length : 0) +
                (this.selectedCard.swapEnabled ? 1 : 0);
            selectedCardTargetsCount <= 1 &&
                this.emit(PlayerView.CARD_PLAY_EVENT, this.selectedCard);
        }
    };
    PlayerView.prototype.checkForSelectionCancellation = function (pointer) {
        var targetCard = this.getPointerTargetCard(pointer);
        if (!targetCard || this.selectedCard != targetCard) {
            this.unselectTheCard(true);
        }
    };
    PlayerView.prototype.removeAllSceneListeners = function () {
        this.scene.input.off(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
        this.scene.input.off(Phaser.Input.Events.POINTER_MOVE, this.onPointerMove, this);
        this.scene.input.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
    };
    PlayerView.prototype.getCard = function (card) {
        return this.cards.find(function (cardView) {
            return cardView.suit === card.suit && cardView.value === card.value;
        });
    };
    PlayerView.prototype.onActionButtonClick = function () {
        this.emit(PlayerView.BUTTON_ACTION_EVENT, this.actionButton.action, this);
        this.repositionCards();
    };
    PlayerView.prototype.openDealedCard = function (card) {
        this.scene.tweens.add({
            targets: card,
            scaleX: 0,
            duration: 100,
            yoyo: true,
            onYoyo: this.onDealedCardYoyo,
            onYoyoScope: this,
        });
    };
    PlayerView.prototype.onDealedCardYoyo = function (tween, card) {
        card.updateData();
        card.setAlpha(1);
        card.setAngle(0);
        card.isOpen = true;
    };
    PlayerView.prototype.emitCardUnselectedEvent = function (reValidate) {
        this.emit(PlayerView.CARD_UNSELECTED_EVENT, reValidate);
    };
    PlayerView.prototype.getPointerTargetCard = function (pointer) {
        var point = new Phaser.Geom.Point(pointer.x, pointer.y);
        var availableCards = this.cards.filter(function (card) { return !!card.input && card.input.enabled; });
        var targetCards = availableCards.filter(function (card) {
            return Phaser.Geom.Rectangle.ContainsPoint(card.getBounds(), point);
        });
        targetCards.sort(function (a, b) {
            return b.depth - a.depth;
        });
        for (var i = 0; i < targetCards.length; i++) {
            if (Phaser.Geom.Rectangle.Contains(targetCards[i].getBounds(), pointer.x, pointer.y)) {
                return targetCards[i];
            }
        }
        // const distancesFromCenter: number[] = targetCards.map((card: CardView) =>
        //   Phaser.Math.Distance.Between(card.x, card.y, pointer.x, pointer.y),
        // );
        // const nearest: number = Math.min(...distancesFromCenter);
        // const cardIndex: number = distancesFromCenter.indexOf(nearest);
        // return targetCards[cardIndex];
    };
    PlayerView.NAME = 'PlayerView';
    PlayerView.BUTTON_ACTION_DONE_NOTIFICATION = PlayerView.NAME + "ButtonActionDoneNotification";
    PlayerView.CARD_PLAY_NOTIFICATION = PlayerView.NAME + "CardPlayNotification";
    PlayerView.GOT_CARDS_NOTIFICATION = PlayerView.NAME + "GotCardsNotification";
    PlayerView.CARD_SELECTED_NOTIFICATION = PlayerView.NAME + "CardSelectedNotification";
    PlayerView.CARD_UNSELECTED_NOTIFICATION = PlayerView.NAME + "CardUnselectedNotification";
    PlayerView.CARD_PLAY_EVENT = 'playingCard';
    PlayerView.CARD_SELECTED_EVENT = 'cardSelectedEvent';
    PlayerView.CARD_UNSELECTED_EVENT = 'cardUnselectedEvent';
    PlayerView.BUTTON_ACTION_EVENT = 'buttonAction';
    return PlayerView;
}(DurakPlayerView));
export default PlayerView;
