var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../assets';
import CardVO from '../../../model/VO/CardVO';
var CardView = /** @class */ (function (_super) {
    __extends(CardView, _super);
    function CardView(scene, x, y, suit, value, isOpen) {
        if (isOpen === void 0) { isOpen = true; }
        var _this = _super.call(this, scene, x, y) || this;
        _this.scene = scene;
        _this.suit = suit;
        _this.value = value;
        _this.isOpen = isOpen;
        _this.swapEnabled = false;
        _this.targets = [];
        _this.createCard();
        _this.createDarker();
        _this.setSize(_this.card.displayWidth, _this.card.displayHeight);
        return _this;
    }
    CardView.prototype.updateData = function (card) {
        if (card === void 0) { card = new CardVO(this.suit, this.value); }
        this.suit = card.suit;
        this.value = card.value;
        this.card.setTexture(MultiAtlases.Cards.Atlas.Name, this.getFrame());
    };
    CardView.prototype.setDarkerState = function (enabled) {
        if (enabled === void 0) { enabled = this.input ? !this.input.enabled : true; }
        this.darker.setVisible(enabled);
    };
    CardView.prototype.setPlayedHighLight = function (animate) {
        if (animate === void 0) { animate = true; }
        if (this.isHilightEnabled || !this.scene) {
            return;
        }
        var random = Phaser.Math.Between(0, 1);
        if (animate) {
            this.scene.tweens.killTweensOf(this);
            // this.setAngle(random === 0 ? -4 : 4);
            this.scene.tweens.add({
                targets: this,
                scaleX: 0.75,
                scaleY: 0.75,
                ease: Phaser.Math.Easing.Back.InOut,
                duration: 200,
            });
        }
        this.isHilightEnabled = true;
        !this.input && this.setInteractive();
        this.input.enabled = true;
        // this.setDarkerState(false);
        this.setTargetClickState(true);
    };
    CardView.prototype.removePlayedHighLight = function () {
        if (!this.scene) {
            return;
        }
        this.scene.tweens.killTweensOf(this);
        this.setAngle(0);
        this.setScale(0.6);
        this.isHilightEnabled = false;
        !this.input && this.setInteractive();
        this.input.enabled = false;
        // this.setDarkerState(false);
        this.setTargetClickState(false);
    };
    CardView.prototype.setTexture = function (key, frame) {
        this.card.setTexture(key, frame);
    };
    CardView.prototype.setFrame = function (frame, updateSize, updateOrigin) {
        this.card.setFrame(frame, updateSize, updateOrigin);
    };
    CardView.prototype.setTargetClickState = function (enabled) {
        enabled
            ? this.once(Phaser.Input.Events.POINTER_UP, this.onHilightClick, this)
            : this.off(Phaser.Input.Events.POINTER_UP, this.onHilightClick, this);
    };
    CardView.prototype.createCard = function () {
        this.card = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Cards.Atlas.Name,
            frame: this.getFrame(),
        });
        this.add(this.card);
    };
    CardView.prototype.getFrame = function () {
        var frame;
        frame =
            this.suit === -1 && this.value === -1
                ? MultiAtlases.Cards.Atlas.Frames.SwapArea
                : this.suit === null && this.value === null
                    ? MultiAtlases.Cards.Atlas.Frames.Cover
                    : MultiAtlases.Cards.Atlas.Frames["Card" + this.suit + this.value];
        return frame;
    };
    CardView.prototype.createDarker = function () {
        this.darker = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Cards.Atlas.Name,
            frame: MultiAtlases.Cards.Atlas.Frames.CardDark,
        });
        this.add(this.darker);
        this.darker.setVisible(false);
        this.bringToTop(this.darker);
    };
    CardView.prototype.onHilightClick = function () {
        this.emit(CardView.HIGH_LIGHTED_CLICK_EVENT);
    };
    CardView.prototype.isSwapArea = function () {
        return this.suit === -1 && this.value === -1;
    };
    CardView.HIGH_LIGHTED_CLICK_EVENT = 'hilightedClick';
    return CardView;
}(Phaser.GameObjects.Container));
export default CardView;
