var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { I18nScene, } from '@candywings/phaser3-i18n-plugin';
import { gameConfig } from '../../constants/GameConfig';
import { isMobile } from '../../utils/Utils';
var BaseScene = /** @class */ (function (_super) {
    __extends(BaseScene, _super);
    function BaseScene(name) {
        var _this = _super.call(this, name) || this;
        _this.constructor['START'] = name + "StartNotification";
        _this.constructor['READY'] = name + "ReadyNotification";
        _this.constructor['PRE_UPDATE'] = name + "PreUpdateNotification";
        _this.constructor['UPDATE'] = name + "UpdateNotification";
        _this.constructor['POST_UPDATE'] = name + "PostUpdateNotification";
        _this.constructor['RESIZE'] = name + "ResizeNotification";
        _this.constructor['PAUSE'] = name + "PauseNotification";
        _this.constructor['RESUME'] = name + "ResumeNotification";
        _this.constructor['SLEEP'] = name + "SleepNotification";
        _this.constructor['WAKE'] = name + "WakeNotification";
        _this.constructor['TRANSITION_INIT'] = name + "TransitionInitNotification";
        _this.constructor['TRANSITION_COMPLETE'] = name + "TransitionCompleteNotification";
        _this.constructor['TRANSITION_OUT'] = name + "TransitionOutNotification";
        _this.constructor['SHUTDOWN'] = name + "ShutdownNotification";
        _this.constructor['DESTROY'] = name + "DestroyNotification";
        _this.constructor['LANGUAGE_CHANGED_EVENT'] = "languageChangedEvent";
        return _this;
    }
    BaseScene.prototype.slideTo = function (from, to) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.tweens.killTweensOf(_this.cameras.main);
                        if (from) {
                            _this.cameras.main.x = Math.sign(from.x) * gameConfig.canvasWidth;
                            _this.cameras.main.y = Math.sign(from.y) * gameConfig.canvasHeight;
                        }
                        _this.tweens.add({
                            targets: _this.cameras.main,
                            x: to ? gameConfig.canvasWidth * Math.sign(to.x) : 0,
                            y: to ? gameConfig.canvasHeight * Math.sign(to.y) : 0,
                            duration: 700,
                            ease: Phaser.Math.Easing.Expo.InOut,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    BaseScene.prototype.playSFX = function (name, loop) {
        if (loop === void 0) { loop = false; }
        //this.events.emit(BaseScene.PLAY_SFX_EVENT, name, loop);
    };
    BaseScene.prototype.stopSFX = function () {
        //this.events.emit(BaseScene.STOP_SFX_EVENT);
    };
    BaseScene.prototype.init = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.input.setTopOnly(false);
        this.i18n = this.game.i18n;
        isMobile() && this.input.addPointer(4);
    };
    Object.defineProperty(BaseScene.prototype, "width", {
        get: function () {
            return this.cameras.main.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseScene.prototype, "height", {
        get: function () {
            return this.cameras.main.height;
        },
        enumerable: true,
        configurable: true
    });
    BaseScene.PLAY_SFX_NOTIFICATION = "BaseScenePlaySfxNotification";
    BaseScene.STOP_SFX_NOTIFICATION = "BaseSceneStopSfxNotification";
    BaseScene.PLAY_SFX_EVENT = "playSfx";
    BaseScene.STOP_SFX_EVENT = "stopSfx";
    return BaseScene;
}(I18nScene));
export default BaseScene;
