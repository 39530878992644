var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Atlases } from '../../../assets';
import { calculateLevelAndProgress, } from '../../../utils/Utils';
import AbstractTopBarValueElement from './AbstractTopBarValueElement';
var XpElement = /** @class */ (function (_super) {
    __extends(XpElement, _super);
    function XpElement(scene) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.xp = 0;
        _this.level = 0;
        _this.createBody();
        return _this;
    }
    XpElement.prototype.updateValue = function (xp, force) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!force) return [3 /*break*/, 1];
                        _a = this.setXp(xp);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.applyXp(xp)];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/, _a];
                }
            });
        });
    };
    XpElement.prototype.setXp = function (xp) {
        return __awaiter(this, void 0, void 0, function () {
            var fillFrame, levelData;
            return __generator(this, function (_a) {
                this.xp = xp;
                fillFrame = this.scene.textures.getFrame(Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.LevelProgressFrame);
                levelData = calculateLevelAndProgress(xp);
                this.fill.width = fillFrame.width * levelData.progress;
                this.fill.setOrigin(0, 0.5);
                if (levelData.level !== this.level) {
                    this.level = levelData.level;
                    this.emit(XpElement.LEVEL_CHANGED_EVENT, this.level);
                }
                return [2 /*return*/];
            });
        });
    };
    XpElement.prototype.applyXp = function (xp) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.scene.tweens.add({
                            targets: _this,
                            xp: xp,
                            onUpdate: function () {
                                _this.setXp(_this.xp);
                            },
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    XpElement.prototype.createBody = function () {
        this.createUpperFrame();
        this.setSize(this.upperFrame.displayWidth, this.upperFrame.displayHeight);
        this.createBackground();
        this.createFill();
        this.bringToTop(this.upperFrame);
    };
    XpElement.prototype.createUpperFrame = function () {
        this.upperFrame = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Topbar.Atlas.Name,
            frame: Atlases.Topbar.Atlas.Frames.LevelProgressUpperFrame,
        });
        this.add(this.upperFrame);
        this.upperFrame.setScale(0.6);
    };
    XpElement.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Topbar.Atlas.Name,
            frame: Atlases.Topbar.Atlas.Frames.LevelProgressBackFrame,
        });
        this.background.setScale((this.upperFrame.displayWidth / this.background.width) * 0.99, (this.upperFrame.displayHeight / this.background.height) * 0.5);
        this.background.x = -this.background.displayWidth * 0.5;
        this.background.setOrigin(0, 0.5);
        this.add(this.background);
    };
    XpElement.prototype.createFill = function () {
        var fillFrame = this.scene.textures.getFrame(Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.LevelProgressFrame);
        this.fill = this.scene.make.tileSprite({
            x: -this.upperFrame.displayWidth * 0.485,
            y: 0,
            key: fillFrame.texture.key,
            frame: fillFrame.name,
            width: 0,
            height: fillFrame.height,
        });
        this.fill.setScale(this.upperFrame.scaleX);
        this.fill.setOrigin(0, 0.5);
        this.add(this.fill);
    };
    XpElement.LEVEL_CHANGED_EVENT = 'levelChanged';
    return XpElement;
}(AbstractTopBarValueElement));
export default XpElement;
