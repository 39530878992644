import SpineClass from './SpineClass';
var SpineMaker = /** @class */ (function () {
    function SpineMaker() {
    }
    SpineMaker.add = function (scene, x, y, key, animationName, loop) {
        var spineFixedObject = new SpineClass(scene, scene.spine, x, y, key, animationName, loop);
        var originalRenderWebGL = spineFixedObject.renderWebGL;
        spineFixedObject.renderWebGL = function (renderer) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            if (this.parentContainer) {
                renderer.nextTypeMatch = false;
                renderer.newType = true;
            }
            return originalRenderWebGL.call.apply(originalRenderWebGL, [this, renderer].concat(args));
        };
        var spine = spineFixedObject;
        scene.add.existing(spine);
        scene.sys.displayList.add(spine);
        return spine;
    };
    SpineMaker.make = function (config, addToScene) {
        var spineFixedObject = new SpineClass(config.scene, config.scene.spine, config.x, config.y, config.key, config.animationName, config.loop);
        var originalRenderWebGL = spineFixedObject.renderWebGL;
        spineFixedObject.renderWebGL = function (renderer) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            if (this.parentContainer) {
                renderer.nextTypeMatch = false;
                renderer.newType = true;
            }
            return originalRenderWebGL.call.apply(originalRenderWebGL, [this, renderer].concat(args));
        };
        var spine = spineFixedObject;
        addToScene && config.scene.add.existing(spine);
        config.scene.sys.displayList.add(spine);
        // config.scene.sys.updateList.add(spine);
        return spine;
    };
    return SpineMaker;
}());
export default SpineMaker;
