var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import WheelScene from '../../../view/scenes/WheelScene';
import UiGuard from './UiGuard';
var IsNotInWheelSceneGuard = /** @class */ (function (_super) {
    __extends(IsNotInWheelSceneGuard, _super);
    function IsNotInWheelSceneGuard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IsNotInWheelSceneGuard.prototype.approve = function () {
        return this.proxy.vo.currentScene !== WheelScene.NAME;
    };
    return IsNotInWheelSceneGuard;
}(UiGuard));
export default IsNotInWheelSceneGuard;
