var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { I18nGame, I18nPlugin } from '@candywings/phaser3-i18n-plugin';
import { Facade } from '@candywings/pure-mvc';
import { BitmapFonts, Fonts, Locales } from './assets';
import { gameConfig } from './constants/GameConfig';
import GameFacade from './GameFacade';
import { getPlatform } from './utils/CordovaUtils';
import { isCordova, isOk, isVk } from './utils/Utils';
import { generateGameConfiguration } from './view/utils/phaser/PhaserUtils';
var Game = /** @class */ (function (_super) {
    __extends(Game, _super);
    function Game() {
        var _this = _super.call(this, generateGameConfiguration()) || this;
        _this.createI18n();
        _this.initializeArchitecture();
        getPlatform() === 'browser' && (window.onresize = _this.resize.bind(_this));
        _this.resize();
        _this.canvas.focus();
        window.addEventListener('beforeunload', function () {
            for (var _i = 0, _a = _this.scene.getScenes(true); _i < _a.length; _i++) {
                var scene = _a[_i];
                scene.sys.pause();
            }
        });
        return _this;
    }
    Game.prototype.initializeArchitecture = function () {
        GameFacade.game = this;
        Facade.getInstance = GameFacade.getInstance;
        Facade.getInstance(Game.NAME);
    };
    Game.prototype.createI18n = function () {
        this.i18n = new I18nPlugin(this, {
            languages: [
                { key: Locales.En.Name, keyJSON: Locales.En.Name },
                { key: Locales.Ru.Name, keyJSON: Locales.Ru.Name },
            ],
            language: isOk() || isVk() ? Locales.Ru.Name : Locales.En.Name,
            valueInjectorOpener: '{{',
            valueInjectorCloser: '}}',
            fontMappings: [
                { en: Fonts.ArialBlack.Name, ru: Fonts.ArialBlack.Name },
                { en: Fonts.AvenirNextBold.Name, ru: Fonts.AvenirNextBold.Name },
                { en: Fonts.AvenirNextMedium.Name, ru: Fonts.AvenirNextMedium.Name },
                { en: Fonts.RobotoSlab.Name, ru: Fonts.RobotoSlab.Name },
            ],
            bitmapFontMappings: [
                { en: BitmapFonts.Roboto.Name, ru: BitmapFonts.Roboto.Name },
                { en: BitmapFonts.RobotoSlab.Name, ru: BitmapFonts.RobotoSlab.Name },
            ],
        });
    };
    Game.prototype.resize = function () {
        var _a = this.config, width = _a.width, height = _a.height;
        var scale = {
            x: (window.innerWidth || width) / width,
            y: (window.innerHeight || height) / height,
        };
        var browserScale = isCordova()
            ? Math.min(window.innerHeight / height, window.innerWidth / width)
            : 0.75;
        scale.x = scale.y = browserScale;
        this.canvas.style.position = 'absolute';
        this.canvas.style.width = width * scale.x + 'px';
        this.canvas.style.height = height * scale.y + 'px';
        this.canvas.style.left = (window.innerWidth - width * scale.x) / 2 + 'px';
        this.canvas.style.top = 0 + 'px';
        this.input.scaleManager.setGameSize(gameConfig.canvasWidth, gameConfig.canvasHeight);
    };
    Game.NAME = 'Kings of Durak';
    return Game;
}(I18nGame));
export default Game;
