var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../../../../assets';
import { gameConfig } from '../../../../../../constants/GameConfig';
import { HorizontalScrollerContainer } from '../../../../../utils/scroll/HorizontalScrollerContainer';
import SmileScrollerElement from './SmileScrollerElement';
var SmilesScroller = /** @class */ (function (_super) {
    __extends(SmilesScroller, _super);
    function SmilesScroller(scene) {
        var _this = this;
        var backgroundFrame = scene.textures.getFrame(MultiAtlases.Emojis.Atlas.Name, MultiAtlases.Emojis.Atlas.Frames.Background);
        var elementFrame = scene.textures.getFrame(MultiAtlases.Emojis.Atlas.Name, MultiAtlases.Emojis.Atlas.Frames.Smile0);
        var inputListenerConfig = {
            x: 0,
            y: 0,
            width: backgroundFrame.width,
            height: backgroundFrame.height,
            distanceTrashHold: 30,
        };
        var relativeMaskConfig = {
            x: elementFrame.width * 0.55,
            y: 0,
            width: inputListenerConfig.width -
                elementFrame.width * 0.55 -
                Math.ceil(10 * gameConfig.resolutionMultiplier),
            height: backgroundFrame.height,
        };
        var kineticScrollConfig = {
            enabled: true,
            resistance: 0.1
        };
        _this = _super.call(this, scene, inputListenerConfig, relativeMaskConfig, kineticScrollConfig) || this;
        _this.createContent();
        return _this;
    }
    SmilesScroller.prototype.createContent = function () {
        this.createSmiles();
        this.sendToBack(this.background);
    };
    SmilesScroller.prototype.createSmiles = function () {
        for (var i = 0; i < 24; i++) {
            var smile = new SmileScrollerElement(this.scene, i);
            this.addScrollAble(smile, true);
        }
    };
    return SmilesScroller;
}(HorizontalScrollerContainer));
export default SmilesScroller;
