var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Atlases, BitmapFonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var DoorPrice = /** @class */ (function (_super) {
    __extends(DoorPrice, _super);
    function DoorPrice(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        return _this;
    }
    DoorPrice.prototype.createBody = function () {
        this.createBackground();
        this.createCurrencyIcon();
        this.createText();
    };
    DoorPrice.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames.PriceBackground,
        });
        this.add(this.background);
    };
    DoorPrice.prototype.createCurrencyIcon = function () {
        this.currencyIcon = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames[Phaser.Utils.String.UppercaseFirst(this.config.currency) + "Icon"],
        });
        this.currencyIcon.x = -this.background.width * 0.32;
        this.add(this.currencyIcon);
    };
    DoorPrice.prototype.createText = function () {
        this.text = this.scene.make.bitmapText({
            x: this.background.width * 0.1,
            y: 0,
            text: "" + this.config.value,
            font: BitmapFonts.RobotoSlab.Name,
            size: 45 * gameConfig.resolutionMultiplier,
        });
        this.text.setOrigin(0.5);
        this.text.setTint(0xd5b571);
        this.add(this.text);
    };
    return DoorPrice;
}(Phaser.GameObjects.Container));
export default DoorPrice;
