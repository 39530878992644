var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BitmapFonts, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { Translation } from '../../translations';
import LevelUpPopupButton from '../components/popup/level-up/LevelUpPopupButton';
import PopupNinePatchBitmapTitle from '../components/popup/PopupNinePatchBitmapTitle';
import BackgroundShine from '../components/popup/reward/BackgroundShine';
import RewardField from '../components/popup/reward/RewardField';
import SimpleButtonWithBitmapText from '../utils/simpleButton/SimpleButtonWithBitmapText';
import StandardPopup from './StandardPopup';
var RewardPopup = /** @class */ (function (_super) {
    __extends(RewardPopup, _super);
    function RewardPopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RewardPopup.prototype.createBody = function () {
        this.createColoredBlocker(0.7, 0x000000);
        this.createBgImage(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.RewardPopupBackground);
        this.createLightEffects();
        this.createTitle();
        this.createClaimButton();
    };
    RewardPopup.prototype.getRewardItems = function () {
        return this.rewards;
    };
    RewardPopup.prototype.prepareToShow = function (x, y, title, rewards, rewardName) {
        var args = [];
        for (var _i = 5; _i < arguments.length; _i++) {
            args[_i - 5] = arguments[_i];
        }
        this.title.updateTitle(title);
        this.createRewardFields(rewards);
        this.createRewardIcon(rewards);
        this.rewards = this.createRewardItems(rewards);
        this.claimButton.once(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction.bind(this, rewardName));
        this.title.prepareToShow();
        _super.prototype.prepareToShow.apply(this, [x, y].concat(args));
    };
    RewardPopup.prototype.startClaimAnimation = function () {
        for (var _i = 0, _a = this.rewardFields; _i < _a.length; _i++) {
            var rewardField = _a[_i];
            rewardField.hide(1000);
        }
    };
    RewardPopup.prototype.prepareToHide = function (actionId) {
        var _this = this;
        this.scene.tweens.add({
            targets: this.topShine,
            alpha: 0,
            duration: 100,
            onComplete: function () {
                _this.topShine.destroy();
            },
        });
        _super.prototype.prepareToHide.call(this, actionId);
    };
    RewardPopup.prototype.onShowComplete = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var i;
            var _this = this;
            return __generator(this, function (_a) {
                _super.prototype.onShowComplete.apply(this, args);
                this.scene.add.existing(this.topShine);
                this.scene.children.moveDown(this.topShine);
                this.scene.children.sendToBack(this.blocker);
                this.scene.tweens.add({
                    targets: this.topShine,
                    alpha: 1,
                    onComplete: function () {
                        _this.scene.tweens.add({
                            targets: _this.topShine,
                            alpha: 0.8,
                            duration: 1500,
                            yoyo: true,
                            repeat: -1,
                        });
                    },
                });
                this.title.show();
                this.backgroundShine.show();
                for (i = 0; i < this.rewardFields.length; i++) {
                    this.rewardFields[i].show(i * 100);
                }
                return [2 /*return*/];
            });
        });
    };
    RewardPopup.prototype.onAction = function (type) {
        this.scene.tweens.add({
            targets: [this.claimButton, this.title],
            scaleX: 0,
            scaleY: 0,
            duration: 300,
            ease: Phaser.Math.Easing.Back.In,
        });
        _super.prototype.onAction.call(this, type);
    };
    RewardPopup.prototype.createTitle = function () {
        var config = {
            frame: MultiAtlases.Popups.Atlas.Frames.RewardPopupTitle,
            borderDistance: 70,
            text: Translation.LEVEL_UP_POPUP_TITLE,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            color: 0xc3a469,
            origin: { x: 0.5, y: 0.6 },
        };
        this.title = new PopupNinePatchBitmapTitle(this.scene, config);
        this.title.y -= this.height * 0.535;
        this.add(this.title);
    };
    RewardPopup.prototype.createClaimButton = function () {
        this.claimButton = new LevelUpPopupButton(this.scene);
        this.add(this.claimButton);
        this.claimButton.y = this.height * 0.535;
    };
    RewardPopup.prototype.createRewardItems = function (rewards) {
        var rewardItems;
        rewardItems = {};
        for (var _i = 0, rewards_1 = rewards; _i < rewards_1.length; _i++) {
            var reward = rewards_1[_i];
            if (!reward.amount) {
                continue;
            }
            if (!rewardItems[reward.type]) {
                rewardItems[reward.type] = [];
            }
            for (var i = 0; i < Math.min(10, 1 + 5 * Math.ceil(reward.amount / 100)); i++) {
                rewardItems[reward.type].push(this.createRewardItem(reward.type));
            }
        }
        return rewardItems;
    };
    RewardPopup.prototype.createRewardItem = function (type) {
        var frame = MultiAtlases.Popups.Atlas.Frames["CurrencyIcon" + Phaser.Utils.String.UppercaseFirst(type)];
        var field = this.rewardFields.find(function (field) { return field.config.type === type; });
        var sprite = this.scene.make.sprite({
            x: field.x,
            y: field.y,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: frame,
        });
        this.add(sprite);
        sprite.setAlpha(0);
        return sprite;
    };
    RewardPopup.prototype.createLightEffects = function () {
        this.createTopShine();
        this.createBackgroundShines();
    };
    RewardPopup.prototype.createTopShine = function () {
        this.topShine = this.scene.make.image({
            x: gameConfig.canvasWidth / 2,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.TopLightPositive,
        });
        this.topShine.setOrigin(0.5, 0);
        this.topShine.setAlpha(0);
    };
    RewardPopup.prototype.createBackgroundShines = function () {
        this.backgroundShine = new BackgroundShine(this.scene, true);
        this.add(this.backgroundShine);
        this.sendToBack(this.backgroundShine);
    };
    RewardPopup.prototype.createRewardIcon = function (rewards) {
        var frame;
        if (rewards.length > 1) {
            frame = MultiAtlases.Popups.Atlas.Frames.RewardPopupDefaultIcon;
        }
        else {
            var reward = rewards[0];
            var type = reward.type;
            frame =
                MultiAtlases.Popups.Atlas.Frames["RewardPopup" + Phaser.Utils.String.UppercaseFirst(type) + "Icon"] || MultiAtlases.Popups.Atlas.Frames.RewardPopupDefaultIcon;
        }
        this.rewardIcon = this.scene.make.image({
            x: -this.width * 0.26,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: frame,
        });
        this.add(this.rewardIcon);
    };
    RewardPopup.prototype.createRewardFields = function (rewards) {
        this.rewardFields = [];
        for (var i = 0; i < rewards.length; i++) {
            var rewardField = this.createRewardField(rewards[i], i);
            this.rewardFields.push(rewardField);
        }
        this.positionRewardFields();
    };
    RewardPopup.prototype.createRewardField = function (reward, index) {
        var rewardField = new RewardField(this.scene, reward, index);
        this.add(rewardField);
        return rewardField;
    };
    RewardPopup.prototype.positionRewardFields = function () {
        var first = this.rewardFields.getFirst();
        var gap = 10;
        var totalHeight = 4 * (first.height + gap) - gap;
        var startY = -totalHeight / 2;
        var endY = totalHeight / 2;
        var x = 0;
        var line = new Phaser.Geom.Line(x, startY, x, endY);
        var points = line.getPoints(4);
        points.shift();
        for (var i = 0; i < this.rewardFields.length; i++) {
            this.rewardFields[i].x = points[i].x;
            this.rewardFields[i].y = points[i].y;
        }
    };
    RewardPopup.NAME = "RewardPopup";
    RewardPopup.CLAIM_REWARD_NOTIFICATION = RewardPopup.NAME + "ClaimRewardNotification";
    RewardPopup.SHOWN_NOTIFICATION = RewardPopup.NAME + "ShownNotification";
    RewardPopup.HIDDEN_NOTIFICATION = RewardPopup.NAME + "HiddenNotification";
    return RewardPopup;
}(StandardPopup));
export default RewardPopup;
