var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AllEvents, UserChannelHandlingEvent, } from '../../constants/SocketEvents';
import { decrypt } from '../../utils/Crypto';
import { blackLog } from '../../utils/Utils';
import { wrapRoomToStatePlayers } from '../helpers/utils';
import SocketVOProxy, { debugSocket } from '../SocketVOProxy';
import ProxyHandler from './ProxyHandler';
var UserChannelHandler = /** @class */ (function (_super) {
    __extends(UserChannelHandler, _super);
    function UserChannelHandler(proxy) {
        return _super.call(this, proxy) || this;
    }
    UserChannelHandler.prototype.WELCOME_USER_CB = function (data) {
        this.proxy.vo.roomType = !!data ? data.type : null;
        !!data &&
            this.sendNotification(SocketVOProxy.READY_TO_JOIN_ROOM_NOTIFICATION, data.joinRoom, true);
        this.sendNotification(SocketVOProxy.USER_CHANNEL_WELCOME_USER_COME_BACK_NOTIFICATION, !!data);
    };
    UserChannelHandler.prototype.LEAVE_USER = function () {
        this.proxy.disconnectFromRoom();
        this.sendNotification(SocketVOProxy.USER_LEFT_ROOM_NOTIFICATION);
    };
    UserChannelHandler.prototype.RECONNECT_GAME_STATE = function (data) {
        this.proxy.joinRoomChannel(data.roomId);
        this.proxy.vo.players = wrapRoomToStatePlayers(data.usersInfo, data.userCards, this.proxy.vo.userId);
        this.proxy.vo.validatedCards = data.validCards.map(function (value) {
            return {
                card: decrypt(value.card),
                targets: value.targets
                    ? value.targets.map(function (value) { return decrypt(value); })
                    : null,
                canSwap: value.canSwap,
            };
        });
        this.sendNotification(SocketVOProxy.RECONNECT_GAME_DATA_RECEIVED_NOTIFICATION, data);
    };
    UserChannelHandler.prototype.JOIN_ROOM = function (data) {
        this.sendNotification(SocketVOProxy.READY_TO_JOIN_ROOM_NOTIFICATION, data.roomId);
    };
    UserChannelHandler.prototype.GET_CARDS = function (data) {
        var decryptedNewCards = data.newCards.map(function (card) {
            return decrypt(card);
        });
        this.proxy.vo.archivedData.GET_CARDS = decryptedNewCards;
        this.proxy.sendNotification(SocketVOProxy.SET_PLAYER_CARDS_TO_DEAL_NOTIFICATION, decryptedNewCards);
    };
    UserChannelHandler.prototype.VALID_CARDS = function (data) {
        this.proxy.vo.validatedCards = data.cards.map(function (value) {
            return {
                card: decrypt(value.card),
                targets: value.targets
                    ? value.targets.map(function (value) { return decrypt(value); })
                    : null,
                canSwap: value.canSwap,
            };
        });
        this.proxy.vo.validAction = data.action;
        this.sendNotification(SocketVOProxy.CARDS_VALIDATED_NOTIFICATION, this.proxy.vo.validatedCards, this.proxy.vo.validAction);
    };
    UserChannelHandler.prototype.GAME_END_DATA = function (data) {
        this.sendNotification(SocketVOProxy.PLAYER_ENDED_THE_GAME_NOTIFICATION, data);
    };
    UserChannelHandler.prototype.ALLOW_PLAY = function (data) {
        if (data.allowPlay) {
            this.proxy.clearData();
            this.sendNotification(SocketVOProxy.FIND_ROOM_ALLOWED_NOTIFICATION);
        }
        else {
            this.sendNotification(SocketVOProxy.FIND_ROOM_DENIED_NOTIFICATION, data.message);
        }
    };
    UserChannelHandler.prototype.CHARGE_USER = function (data) {
        this.sendNotification(SocketVOProxy.CHARGE_USER_NOTIFICATION, data);
    };
    UserChannelHandler.prototype.GAME_STATE_RESPONSE = function (data) {
        if (data.data.error) {
            this.LEAVE_USER();
            return;
        }
        this.sendNotification(SocketVOProxy.GAME_STATE_RESPONSE_RECEIVED_NOTIFICATION, data.data);
    };
    UserChannelHandler.prototype.REQUEST_DISCONNECT = function () {
        this.sendNotification(SocketVOProxy.LOGIN_FROM_OTHER_DEVICE_NOTIFICATION);
    };
    UserChannelHandler.prototype.INVALID_ACTION = function (data) {
        if (data.userId !== this.proxy.vo.userId ||
            data.roomId !== this.proxy.vo.roomId) {
            return;
        }
        this.sendNotification(SocketVOProxy.INVALID_ACTION_NOTIFICATION);
    };
    UserChannelHandler.prototype.handleEvents = function (_a) {
        var event = _a.event, args = __rest(_a, ["event"]);
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                if (debugSocket) {
                    blackLog("UserChannelHandlingEvent." + AllEvents[event] + " = " + event, args);
                }
                switch (event) {
                    case UserChannelHandlingEvent.RECONNECT_GAME_STATE:
                        this.RECONNECT_GAME_STATE(args.data);
                        break;
                    case UserChannelHandlingEvent.WELCOME_USER_CB:
                        this.WELCOME_USER_CB(args.data);
                        break;
                    case UserChannelHandlingEvent.ALLOW_PLAY:
                        this.ALLOW_PLAY(args);
                        break;
                    case UserChannelHandlingEvent.JOIN_ROOM:
                        this.JOIN_ROOM(args);
                        break;
                    case UserChannelHandlingEvent.GET_CARDS:
                        this.GET_CARDS(args);
                        break;
                    case UserChannelHandlingEvent.VALID_CARDS:
                        this.VALID_CARDS(args);
                        break;
                    case UserChannelHandlingEvent.GAME_END_DATA:
                        this.GAME_END_DATA(args);
                        break;
                    case UserChannelHandlingEvent.CHARGE_USER:
                        this.CHARGE_USER(args);
                        break;
                    case UserChannelHandlingEvent.GAME_STATE_RESPONSE:
                        this.GAME_STATE_RESPONSE(args);
                        break;
                    case UserChannelHandlingEvent.LEAVE_USER:
                        this.LEAVE_USER();
                        break;
                    case UserChannelHandlingEvent.INVALID_ACTION:
                        this.INVALID_ACTION(args.requestedAction);
                        break;
                    case UserChannelHandlingEvent.REQUEST_DISCONNECT:
                        this.REQUEST_DISCONNECT();
                        break;
                    default:
                        console.warn("UserChannelHandlingEvent." + AllEvents[event] + " = " + event + " is not handled", { args: args });
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    return UserChannelHandler;
}(ProxyHandler));
export default UserChannelHandler;
