var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Proxy } from '@candywings/pure-mvc';
var ProxyWithHandler = /** @class */ (function (_super) {
    __extends(ProxyWithHandler, _super);
    function ProxyWithHandler() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProxyWithHandler.prototype.sendNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        _super.prototype.sendNotification.apply(this, [notificationName].concat(args));
    };
    return ProxyWithHandler;
}(Proxy));
export default ProxyWithHandler;
