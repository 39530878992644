var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, Fonts, Images, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { Translation } from '../../../translations';
import ServiceScene from '../../scenes/ServiceScene';
import SimpleButtonWithBitmapText from '../../utils/simpleButton/SimpleButtonWithBitmapText';
var LoginFromOtherDeviceView = /** @class */ (function (_super) {
    __extends(LoginFromOtherDeviceView, _super);
    function LoginFromOtherDeviceView(scene) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.createComponents();
        _this.setListeners();
        _this.prepare();
        _this.show();
        return _this;
    }
    LoginFromOtherDeviceView.prototype.createComponents = function () {
        this.createBackground();
        this.createMessageText();
        this.creatTitleText();
        this.createContinueButton();
        this.createCloseButton();
    };
    LoginFromOtherDeviceView.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: gameConfig.canvasWidth / 2,
            y: gameConfig.canvasHeight / 2,
            key: Images.WhitePixel.Name,
        });
        this.add(this.background);
        this.background.setScale(gameConfig.canvasWidth / this.background.width, gameConfig.canvasHeight / this.background.height);
        this.background.setTint(0x000000);
        this.background.setAlpha(0.95);
    };
    LoginFromOtherDeviceView.prototype.creatTitleText = function () {
        var style = {
            fontFamily: Fonts.RobotoSlab.Name,
            fontSize: 60 * gameConfig.resolutionMultiplier,
            fill: '#a87716',
        };
        this.titleText = this.scene.make.extText({
            x: gameConfig.canvasWidth / 2,
            y: gameConfig.canvasHeight / 2,
            text: Translation.SERVICE_LOGIN_FROM_OTHER_DEVICE_TITLE,
            style: style,
        });
        this.titleText.setOrigin(0.5);
        this.titleText.y =
            this.messageText.y -
                this.messageText.height * 0.6 -
                this.titleText.height * 0.6;
        this.add(this.titleText);
    };
    LoginFromOtherDeviceView.prototype.createMessageText = function () {
        var style = {
            fontFamily: Fonts.RobotoSlab.Name,
            fontSize: 46 * gameConfig.resolutionMultiplier,
            fill: '#a87716',
        };
        this.messageText = this.scene.make.extText({
            x: gameConfig.canvasWidth / 2,
            y: gameConfig.canvasHeight / 2,
            text: Translation.SERVICE_LOGIN_FROM_OTHER_DEVICE_MESSAGE,
            style: style,
        });
        this.messageText.setOrigin(0.5);
        this.messageText.y -= this.messageText.height * 0.6;
        this.add(this.messageText);
    };
    LoginFromOtherDeviceView.prototype.createContinueButton = function () {
        var normalTextConfig = {
            text: Translation.SERVICE_LOGIN_FROM_OTHER_DEVICE_CONTINUE,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0xfff3d3,
            origin: {
                x: 0.5,
                y: 0.8,
            },
        };
        var hoverTextConfig = {
            text: Translation.SERVICE_LOGIN_FROM_OTHER_DEVICE_CONTINUE,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0xfff3d3,
            origin: {
                x: 0.5,
                y: 0.8,
            },
        };
        var downTextConfig = {
            text: Translation.SERVICE_LOGIN_FROM_OTHER_DEVICE_CONTINUE,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0x0cc8c8,
            origin: {
                x: 0.5,
                y: 0.8,
            },
        };
        var normalStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.NextButtonNormal,
            textConfig: normalTextConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.NextButtonHover,
            textConfig: hoverTextConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.NextButtonDown,
            textConfig: downTextConfig,
        };
        this.continueButton = new SimpleButtonWithBitmapText(this.scene, {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        });
        this.add(this.continueButton);
        this.continueButton.x = gameConfig.canvasWidth / 2;
        this.continueButton.y =
            gameConfig.canvasHeight / 2 + this.continueButton.height * 1.1;
    };
    LoginFromOtherDeviceView.prototype.createCloseButton = function () {
        var normalTextConfig = {
            text: Translation.SERVICE_LOGIN_FROM_OTHER_DEVICE_CLOSE_APP,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0xfff3d3,
            origin: {
                x: 0.5,
                y: 0.8,
            },
        };
        var hoverTextConfig = {
            text: Translation.SERVICE_LOGIN_FROM_OTHER_DEVICE_CLOSE_APP,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0xfff3d3,
            origin: {
                x: 0.5,
                y: 0.8,
            },
        };
        var downTextConfig = {
            text: Translation.SERVICE_LOGIN_FROM_OTHER_DEVICE_CLOSE_APP,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0x0cc8c8,
            origin: {
                x: 0.5,
                y: 0.8,
            },
        };
        var normalStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.NextButtonNormal,
            textConfig: normalTextConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.NextButtonHover,
            textConfig: hoverTextConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.NextButtonDown,
            textConfig: downTextConfig,
        };
        this.closeButton = new SimpleButtonWithBitmapText(this.scene, {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        });
        this.add(this.closeButton);
        this.closeButton.x = gameConfig.canvasWidth / 2;
        this.closeButton.y = this.continueButton.y + this.closeButton.height * 1.1;
    };
    LoginFromOtherDeviceView.prototype.setListeners = function () {
        this.continueButton.once(SimpleButtonWithBitmapText.CLICK_EVENT, this.onContinue, this);
        this.closeButton.once(SimpleButtonWithBitmapText.CLICK_EVENT, this.onClose, this);
    };
    LoginFromOtherDeviceView.prototype.removeListeners = function () {
        this.continueButton.off(SimpleButtonWithBitmapText.CLICK_EVENT, this.onContinue, this);
        this.closeButton.off(SimpleButtonWithBitmapText.CLICK_EVENT, this.onClose, this);
    };
    LoginFromOtherDeviceView.prototype.onContinue = function () {
        this.removeListeners();
        this.scene.events.emit(ServiceScene.CONTINUE_EVENT);
    };
    LoginFromOtherDeviceView.prototype.onClose = function () {
        this.removeListeners();
        this.scene.events.emit(ServiceScene.CLOSE_EVENT);
    };
    LoginFromOtherDeviceView.prototype.prepare = function () {
        this.titleText.setAlpha(0);
        this.messageText.setAlpha(0);
        this.continueButton.setScale(0);
        this.closeButton.setScale(0);
    };
    LoginFromOtherDeviceView.prototype.show = function () {
        var _this = this;
        this.scene.tweens.add({
            targets: this.background,
            alpha: 0.95,
            duration: 200,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: [_this.titleText, _this.messageText],
                    duration: 200,
                    alpha: 1,
                    onComplete: function () {
                        _this.scene.tweens.add({
                            targets: [_this.continueButton, _this.closeButton],
                            scaleX: 1,
                            scaleY: 1,
                            duration: 200,
                            ease: Phaser.Math.Easing.Back.Out,
                        });
                    },
                });
            },
        });
    };
    return LoginFromOtherDeviceView;
}(Phaser.GameObjects
    .Container));
export default LoginFromOtherDeviceView;
