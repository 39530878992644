var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import DeckVOProxy from '../../model/DeckVOProxy';
import SocketVOProxy from '../../model/SocketVOProxy';
import StateVOProxy from '../../model/StateVOProxy';
import ControlViewMediator from '../components/game/ControlViewMediator';
import DeckViewMediator from '../components/game/DeckViewMediator';
import OpponentViewMediator from '../components/game/OpponentViewMediator';
import PlayerAvatarViewMediator from '../components/game/PlayerAvatarViewMediator';
import PlayerViewMediator from '../components/game/PlayerViewMediator';
import TurnViewMediator from '../components/game/TurnViewMediator';
import WaitingViewMediator from '../components/game/WaitingViewMediator';
import ReplayPopup from '../popups/ReplayPopup';
import BaseSceneMediator from './BaseSceneMediator';
import GameScene from './GameScene';
import LobbyScene from './LobbyScene';
import PreloadScene from './PreloadScene';
import RoomScene from './RoomScene';
import ServiceScene from './ServiceScene';
import TopBarScene from './TopBarScene';
import PopupScene from './PopupScene';
var GameSceneMediator = /** @class */ (function (_super) {
    __extends(GameSceneMediator, _super);
    function GameSceneMediator() {
        return _super.call(this, GameSceneMediator.NAME, null) || this;
    }
    GameSceneMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(PreloadScene.WAITING_FOR_API_NOTIFICATION, LobbyScene.SWITCHING_TO_GAME_SCENE, RoomScene.SWITCHING_TO_GAME_SCENE, SocketVOProxy.RETRIEVING_GAME_STATE_NOTIFICATION, SocketVOProxy.USER_LEFT_ROOM_NOTIFICATION, StateVOProxy.PLAYERS_READY_NOTIFICATION, StateVOProxy.FORCE_DATA_INITIALIZED_NOTIFICATION, ReplayPopup.USER_REPLAYS_NOTIFICATION, ReplayPopup.REPLAY_NOTIFICATION, ReplayPopup.LEAVE_NOTIFICATION, ServiceScene.GAME_RESUMED_NOTIFICATION, PreloadScene.LOAD_COMPLETE_NOTIFICATION, SocketVOProxy.FIND_ROOM_DENIED_NOTIFICATION, ServiceScene.GAME_PAUSED_NOTIFICATION, SocketVOProxy.DISCONNECT_NOTIFICATION, SocketVOProxy.RECONNECTED_NOTIFICATION);
    };
    GameSceneMediator.prototype.handleNotification = function (notificationName) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, stateVOProxy, socketVOProxy, socketVOProxy_1, i, socketVOProxy_2, socketVOProxy_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = notificationName;
                        switch (_a) {
                            case PreloadScene.WAITING_FOR_API_NOTIFICATION: return [3 /*break*/, 1];
                            case PreloadScene.LOAD_COMPLETE_NOTIFICATION: return [3 /*break*/, 2];
                            case LobbyScene.SWITCHING_TO_GAME_SCENE: return [3 /*break*/, 4];
                            case RoomScene.SWITCHING_TO_GAME_SCENE: return [3 /*break*/, 4];
                            case SocketVOProxy.FIND_ROOM_ALLOWED_NOTIFICATION: return [3 /*break*/, 6];
                            case ReplayPopup.REPLAY_NOTIFICATION: return [3 /*break*/, 7];
                            case SocketVOProxy.FIND_ROOM_DENIED_NOTIFICATION: return [3 /*break*/, 8];
                            case SocketVOProxy.USER_LEFT_ROOM_NOTIFICATION: return [3 /*break*/, 8];
                            case StateVOProxy.FORCE_DATA_INITIALIZED_NOTIFICATION: return [3 /*break*/, 9];
                            case DeckVOProxy.INITIALIZE_SUCCESS_NOTIFICATION: return [3 /*break*/, 10];
                            case StateVOProxy.PLAYERS_READY_NOTIFICATION: return [3 /*break*/, 11];
                            case ReplayPopup.USER_REPLAYS_NOTIFICATION: return [3 /*break*/, 12];
                            case SocketVOProxy.RETRIEVING_GAME_STATE_NOTIFICATION: return [3 /*break*/, 13];
                            case SocketVOProxy.DISCONNECT_NOTIFICATION: return [3 /*break*/, 14];
                            case ServiceScene.GAME_PAUSED_NOTIFICATION: return [3 /*break*/, 14];
                            case SocketVOProxy.RECONNECTED_NOTIFICATION: return [3 /*break*/, 15];
                            case ServiceScene.GAME_RESUMED_NOTIFICATION: return [3 /*break*/, 15];
                        }
                        return [3 /*break*/, 16];
                    case 1:
                        this.startScene();
                        return [3 /*break*/, 17];
                    case 2:
                        {
                            stateVOProxy = this.facade.retrieveProxy(StateVOProxy.NAME);
                            if (!stateVOProxy.vo.reconnected) {
                                return [3 /*break*/, 17];
                            }
                            this.unsubscribeToNotification(PreloadScene.LOAD_COMPLETE_NOTIFICATION);
                        }
                        return [4 /*yield*/, this.wakeScene()];
                    case 3:
                        _b.sent();
                        socketVOProxy = this.facade.retrieveProxy(SocketVOProxy.NAME);
                        this.viewComponent.setRoomName(socketVOProxy.vo.roomType);
                        this.unsubscribeToNotification(SocketVOProxy.FIND_ROOM_ALLOWED_NOTIFICATION);
                        this.sendNotification(GameScene.CREATE_NOTIFICATION);
                        return [3 /*break*/, 17];
                    case 4:
                        this.wakeScene();
                        this.sendNotification(GameScene.CREATE_NOTIFICATION);
                        this.sceneManager.bringToTop(GameScene.NAME);
                        this.sceneManager.bringToTop(TopBarScene.NAME);
                        this.sceneManager.bringToTop(PopupScene.NAME);
                        socketVOProxy_1 = this.facade.retrieveProxy(SocketVOProxy.NAME);
                        this.viewComponent.setRoomName(socketVOProxy_1.vo.roomType);
                        return [4 /*yield*/, this.viewComponent.slideTo({ x: 1, y: 0 })];
                    case 5:
                        _b.sent();
                        return [3 /*break*/, 17];
                    case 6:
                        this.unsubscribeToNotification(SocketVOProxy.FIND_ROOM_ALLOWED_NOTIFICATION);
                        this.sendNotification(GameScene.CREATE_NOTIFICATION);
                        return [3 /*break*/, 17];
                    case 7:
                        this.viewComponent.stopTweensAndTimers();
                        this.removeComponents();
                        this.viewComponent.removeOpponentAvatars();
                        this.viewComponent.reCreate();
                        this.prepareViews();
                        this.subscribeToNotifications(SocketVOProxy.FIND_ROOM_ALLOWED_NOTIFICATION);
                        this.sendNotification(GameScene.REPLAY_NOTIFICATION);
                        return [3 /*break*/, 17];
                    case 8:
                        // case ReplayPopup.LEAVE_NOTIFICATION:
                        if (!this.sceneManager.isActive(GameScene.NAME)) {
                            return [2 /*return*/];
                        }
                        this.onLeaveRoom();
                        return [3 /*break*/, 17];
                    case 9:
                        this.subscribeToNotifications(DeckVOProxy.INITIALIZE_SUCCESS_NOTIFICATION);
                        return [3 /*break*/, 17];
                    case 10:
                        this.unsubscribeToNotification(DeckVOProxy.INITIALIZE_SUCCESS_NOTIFICATION);
                        this.sendNotification(GameScene.FORCE_START_NOTIFICATION);
                        this.sceneManager.bringToTop(GameScene.NAME);
                        this.sceneManager.bringToTop(TopBarScene.NAME);
                        this.sceneManager.bringToTop(PopupScene.NAME);
                        return [3 /*break*/, 17];
                    case 11:
                        {
                            this.sendNotification(GameScene.LET_THE_GAME_START_NOTIFICATION);
                        }
                        return [3 /*break*/, 17];
                    case 12:
                        this.facade.removeMediator(DeckViewMediator.NAME);
                        this.facade.removeMediator(TurnViewMediator.NAME);
                        this.facade.removeMediator(PlayerViewMediator.NAME);
                        for (i = 0; i < 4; i++) {
                            this.facade.removeMediator(OpponentViewMediator.NAME);
                        }
                        return [3 /*break*/, 17];
                    case 13: 
                    // this.viewComponent.reCreate();
                    // this.prepareViews();
                    return [3 /*break*/, 17];
                    case 14:
                        {
                            if (!this.sceneManager.isSleeping(GameScene.NAME)) {
                                socketVOProxy_2 = this.facade.retrieveProxy(SocketVOProxy.NAME);
                                if (!socketVOProxy_2.vo.roomId) {
                                    this.sendNotification(GameScene.LEAVE_ROOM_AUTO_NOTIFICATION);
                                    this.onLeaveRoom();
                                }
                            }
                            this.viewComponent.stopTweensAndTimers();
                        }
                        return [3 /*break*/, 17];
                    case 15:
                        {
                            socketVOProxy_3 = this.facade.retrieveProxy(SocketVOProxy.NAME);
                            if (!this.sceneManager.isActive(GameScene.NAME) || this.sceneManager.isSleeping(GameScene.NAME) || !socketVOProxy_3.vo.roomId) {
                                return [3 /*break*/, 17];
                            }
                            this.viewComponent.prepareForRestart();
                            this.removeViews();
                            this.startScene();
                            this.wakeScene();
                            this.viewComponent.reCreate();
                            this.sceneManager.bringToTop(GameScene.NAME);
                            this.sendNotification(GameScene.RECREATE_NOTIFICATION);
                            return [3 /*break*/, 17];
                        }
                        _b.label = 16;
                    case 16:
                        if (this.defaultNotifications.contains(notificationName)) {
                            return [3 /*break*/, 17];
                        }
                        console.warn(notificationName + " is unhandled!");
                        return [3 /*break*/, 17];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    GameSceneMediator.prototype.removeViews = function () {
        this.facade.removeMediator(ControlViewMediator.NAME);
        this.facade.removeMediator(PlayerViewMediator.NAME);
        this.facade.removeMediator(OpponentViewMediator.NAME);
        this.facade.removeMediator(OpponentViewMediator.NAME);
        this.facade.removeMediator(OpponentViewMediator.NAME);
        this.facade.removeMediator(TurnViewMediator.NAME);
        this.facade.removeMediator(DeckViewMediator.NAME);
        this.facade.removeMediator(PlayerAvatarViewMediator.NAME);
        this.facade.removeMediator(WaitingViewMediator.NAME);
    };
    GameSceneMediator.prototype.prepareViews = function () {
        this.facade.registerMediator(new ControlViewMediator());
        this.facade.registerMediator(new PlayerViewMediator());
        this.prepareOpponents();
        this.facade.registerMediator(new TurnViewMediator());
        this.facade.registerMediator(new DeckViewMediator());
        this.facade.registerMediator(new PlayerAvatarViewMediator());
        this.facade.registerMediator(new WaitingViewMediator());
    };
    GameSceneMediator.prototype.prepareOpponents = function () {
        var socketVOProxy = this.facade.retrieveProxy(SocketVOProxy.NAME);
        for (var i = 0; i < socketVOProxy.getRoomOpponentsCount(); i++) {
            this.facade.registerMediator(new OpponentViewMediator());
        }
    };
    GameSceneMediator.prototype.startScene = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.sceneManager.start(GameScene.NAME);
                this.sceneManager.sendToBack(GameScene.NAME);
                this.sceneManager.sleep(GameScene.NAME);
                return [2 /*return*/];
            });
        });
    };
    GameSceneMediator.prototype.setView = function () {
        var gameScene = new GameScene();
        this.sceneManager.add(GameScene.NAME, gameScene);
        this.setViewComponent(gameScene);
    };
    GameSceneMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.events.on(GameScene.LEAVE_ROOM_EVENT, this.onLeaveRoom, this);
    };
    GameSceneMediator.prototype.onAvatarLoadComplete = function () {
        this.sendNotification(GameScene.AVATARS_LOAD_COMPLETE_NOTIFICATION);
    };
    GameSceneMediator.prototype.onLeaveRoom = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sendNotification(GameScene.PREPARE_TO_LEAVE_ROOM_NOTIFICATION);
                        this.viewComponent.tweens.killAll();
                        this.viewComponent.time.removeAllEvents();
                        this.viewComponent.time.clearPendingEvents();
                        this.viewComponent.removeOpponentAvatars();
                        this.sendNotification(GameScene.LEAVE_ROOM_NOTIFICATION);
                        return [4 /*yield*/, this.viewComponent.slideTo(null, { x: 1, y: 0 })];
                    case 1:
                        _a.sent();
                        this.removeComponents();
                        this.viewComponent.reCreate();
                        this.sceneManager.sleep(GameScene.NAME);
                        return [2 /*return*/];
                }
            });
        });
    };
    GameSceneMediator.prototype.sleepComponents = function () {
        this.facade.hasMediatorWithName(OpponentViewMediator.NAME) &&
            this.facade
                .retrieveMediators(OpponentViewMediator.NAME)
                .map(function (value) { return value.sleep(); });
        this.facade.retrieveMediator(PlayerViewMediator.NAME).sleep();
        this.facade.retrieveMediator(PlayerAvatarViewMediator.NAME).sleep();
        this.facade.retrieveMediator(ControlViewMediator.NAME).sleep();
        this.facade.retrieveMediator(DeckViewMediator.NAME).sleep();
        this.facade.retrieveMediator(TurnViewMediator.NAME).sleep();
        this.facade.retrieveMediator(WaitingViewMediator.NAME).sleep();
    };
    GameSceneMediator.prototype.removeComponents = function () {
        var opponentMediators = this.facade.hasMediatorWithName(OpponentViewMediator.NAME)
            ? this.facade.retrieveMediators(OpponentViewMediator.NAME)
            : [];
        this.facade.removeMediators([
            { mediatorName: PlayerViewMediator.NAME },
            { mediatorName: PlayerAvatarViewMediator.NAME },
            { mediatorName: ControlViewMediator.NAME },
            { mediatorName: DeckViewMediator.NAME },
            { mediatorName: TurnViewMediator.NAME },
            { mediatorName: WaitingViewMediator.NAME }
        ].concat(opponentMediators.map(function () {
            return { mediatorName: OpponentViewMediator.NAME };
        })));
    };
    GameSceneMediator.prototype.wakeScene = function () {
        return __awaiter(this, void 0, void 0, function () {
            var socketVOProxy;
            return __generator(this, function (_a) {
                this.sceneManager.wake(GameScene.NAME);
                socketVOProxy = this.facade.retrieveProxy(SocketVOProxy.NAME);
                this.viewComponent.setRoomName(socketVOProxy.vo.roomType);
                this.prepareViews();
                return [2 /*return*/];
            });
        });
    };
    GameSceneMediator.NAME = 'GameSceneMediator';
    return GameSceneMediator;
}(BaseSceneMediator));
export default GameSceneMediator;
