var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Images, MultiAtlases } from '../../../assets';
import { hasKey } from '../../utils/phaser/PhaserUtils';
import { getNativeAvatarKey, isNativeAvatar } from '../../../utils/Utils';
var UserInfoPopupAvatar = /** @class */ (function (_super) {
    __extends(UserInfoPopupAvatar, _super);
    function UserInfoPopupAvatar(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.config = config;
        _this.createBody();
        return _this;
    }
    UserInfoPopupAvatar.prototype.updateMask = function () {
        var transformMatrix = this.getWorldTransformMatrix();
        this.maskGraphics.clear();
        this.maskGraphics.fillStyle(0xffffff);
        this.maskGraphics.fillCircle(transformMatrix.tx, transformMatrix.ty, this.displayWidth * 0.475 * this.parentContainer.scaleX);
    };
    UserInfoPopupAvatar.prototype.createBody = function () {
        this.createBackground();
        this.createPicture();
        this.createFrame();
        this.setSize(this.frame.width, this.frame.width);
        this.createFrameShadow();
        this.background.setScale((this.frame.width / this.background.width) * 0.9);
        this.picture.setScale((this.frame.width / this.picture.width) * 0.95);
        this.createMask();
    };
    UserInfoPopupAvatar.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: this.config.isNative
                ? MultiAtlases.Login.Atlas.Name
                : MultiAtlases.GamePlay.Atlas.Name,
            frame: this.config.isNative
                ? MultiAtlases.Login.Atlas.Frames.AvatarPicBg
                : MultiAtlases.GamePlay.Atlas.Frames.AvatarPictureBackground,
        });
        this.add(this.background);
    };
    UserInfoPopupAvatar.prototype.createPicture = function () {
        var key = hasKey('playerDefaultAvatar') ? 'playerDefaultAvatar' : Images.Avatar.Name;
        if (this.config.id != 3 || isNativeAvatar(this.config.avatarUrl) || !this.config.avatarUrl) {
            key = this.getAvatarKey(this.config.avatarUrl, this.config.userId);
        }
        this.picture = this.scene.make.image({
            x: 0,
            y: 0,
            key: key,
        });
        this.add(this.picture);
    };
    UserInfoPopupAvatar.prototype.getAvatarKey = function (url, userId) {
        if (!url) {
            return Images.Avatar.Name;
        }
        else if (isNativeAvatar(url)) {
            return getNativeAvatarKey(url);
        }
        else if (!hasKey("User" + userId + "Avatar")) {
            return Images.Avatar.Name;
        }
        else {
            return "User" + userId + "Avatar";
        }
    };
    UserInfoPopupAvatar.prototype.createFrame = function () {
        this.frame = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupAvatarFrame,
        });
        this.add(this.frame);
    };
    UserInfoPopupAvatar.prototype.createFrameShadow = function () {
        this.frameShadow = this.scene.make.image({
            x: 0,
            y: this.frame.height * 0.01,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupAvatarShadow,
        });
        this.add(this.frameShadow);
    };
    UserInfoPopupAvatar.prototype.createMask = function () {
        this.maskGraphics = this.scene.make.graphics({});
        this.picture.setMask(new Phaser.Display.Masks.GeometryMask(this.scene, this.maskGraphics));
    };
    return UserInfoPopupAvatar;
}(Phaser.GameObjects.Container));
export default UserInfoPopupAvatar;
