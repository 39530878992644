var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import TopBarScene from '../../view/scenes/TopBarScene';
import IsPlayerVOProxyReadyGuard from '../Guards/model/IsPlayerVOProxyReadyGuard';
import IsNotInGameSceneGuard from '../Guards/ui/IsNotInGameSceneGuard';
import IsNotInRegistrationScene from '../Guards/ui/IsNotInRegistrationScene';
import IsNotInWheelSceneGuard from '../Guards/ui/IsNotInWheelSceneGuard';
import PlayerCommand from './PlayerCommand';
var CheckPlayerBonusesCommand = /** @class */ (function (_super) {
    __extends(CheckPlayerBonusesCommand, _super);
    function CheckPlayerBonusesCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CheckPlayerBonusesCommand.prototype.prepare = function () {
        this.addGuards(IsPlayerVOProxyReadyGuard, IsNotInRegistrationScene, IsNotInWheelSceneGuard, IsNotInGameSceneGuard);
    };
    CheckPlayerBonusesCommand.prototype.execute = function () {
        this.facade.removeCommand(TopBarScene.SHOWN_NOTIFICATION, CheckPlayerBonusesCommand);
        this.proxy.checkBonuses();
    };
    return CheckPlayerBonusesCommand;
}(PlayerCommand));
export default CheckPlayerBonusesCommand;
