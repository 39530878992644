export var AllEvents;
(function (AllEvents) {
    AllEvents[AllEvents["WELCOME"] = 0] = "WELCOME";
    AllEvents[AllEvents["FIND_ROOM"] = 1] = "FIND_ROOM";
    AllEvents[AllEvents["JOIN_ROOM"] = 2] = "JOIN_ROOM";
    AllEvents[AllEvents["REQUEST_GAME_STATE"] = 3] = "REQUEST_GAME_STATE";
    AllEvents[AllEvents["GET_CARDS"] = 4] = "GET_CARDS";
    AllEvents[AllEvents["VALID_CARDS"] = 5] = "VALID_CARDS";
    AllEvents[AllEvents["FORCE_GAME_END"] = 6] = "FORCE_GAME_END";
    AllEvents[AllEvents["PLAYED_CARD"] = 7] = "PLAYED_CARD";
    AllEvents[AllEvents["PLAY"] = 8] = "PLAY";
    AllEvents[AllEvents["GAME_START_DATA"] = 9] = "GAME_START_DATA";
    AllEvents[AllEvents["GIVE_VALID_CARDS"] = 10] = "GIVE_VALID_CARDS";
    AllEvents[AllEvents["WELCOME_USER_CB"] = 14] = "WELCOME_USER_CB";
    AllEvents[AllEvents["ALLOW_PLAY"] = 21] = "ALLOW_PLAY";
    AllEvents[AllEvents["LEAVE_USER"] = 27] = "LEAVE_USER";
    AllEvents[AllEvents["REPLAY"] = 31] = "REPLAY";
    AllEvents[AllEvents["GAME_END_DATA"] = 32] = "GAME_END_DATA";
    AllEvents[AllEvents["SAY_HELLO"] = 33] = "SAY_HELLO";
    AllEvents[AllEvents["SAY_HELLO_RECONNECT"] = 34] = "SAY_HELLO_RECONNECT";
    AllEvents[AllEvents["SEND_EMOJI"] = 35] = "SEND_EMOJI";
    AllEvents[AllEvents["CHARGE_USER"] = 36] = "CHARGE_USER";
    AllEvents[AllEvents["RECONNECT_GAME_STATE"] = 37] = "RECONNECT_GAME_STATE";
    AllEvents[AllEvents["INVALID_ACTION"] = 38] = "INVALID_ACTION";
    AllEvents[AllEvents["USER_LEFT"] = 39] = "USER_LEFT";
    AllEvents[AllEvents["GAME_STATE_RESPONSE"] = 40] = "GAME_STATE_RESPONSE";
    AllEvents[AllEvents["REQUEST_DISCONNECT"] = 41] = "REQUEST_DISCONNECT";
})(AllEvents || (AllEvents = {}));
export var SocketHandlingEvent;
(function (SocketHandlingEvent) {
    SocketHandlingEvent["CONNECT"] = "connect";
    SocketHandlingEvent["DISCONNECT"] = "disconnect";
    SocketHandlingEvent["SUBSCRIBE"] = "subscribe";
    SocketHandlingEvent["ERROR"] = "error";
    SocketHandlingEvent["MESSAGE"] = "message";
})(SocketHandlingEvent || (SocketHandlingEvent = {}));
export var UserChannelEmittingEvent;
(function (UserChannelEmittingEvent) {
    UserChannelEmittingEvent[UserChannelEmittingEvent["WELCOME"] = 0] = "WELCOME";
    UserChannelEmittingEvent[UserChannelEmittingEvent["FIND_ROOM"] = 1] = "FIND_ROOM";
    UserChannelEmittingEvent[UserChannelEmittingEvent["REPLAY"] = 31] = "REPLAY";
    UserChannelEmittingEvent[UserChannelEmittingEvent["GIVE_VALID_CARDS"] = 10] = "GIVE_VALID_CARDS";
    UserChannelEmittingEvent[UserChannelEmittingEvent["GET_STATE"] = 11] = "GET_STATE";
    UserChannelEmittingEvent[UserChannelEmittingEvent["LEAVE_USER"] = 27] = "LEAVE_USER";
    UserChannelEmittingEvent[UserChannelEmittingEvent["REQUEST_GAME_STATE"] = 3] = "REQUEST_GAME_STATE";
})(UserChannelEmittingEvent || (UserChannelEmittingEvent = {}));
export var UserChannelEmittingEventName;
(function (UserChannelEmittingEventName) {
    UserChannelEmittingEventName["WELCOME"] = "userChannel.welcome";
    UserChannelEmittingEventName["FIND_ROOM"] = "userChannel.findRoom";
    UserChannelEmittingEventName["REPLAY"] = "userChannel.replay";
    UserChannelEmittingEventName["GIVE_VALID_CARDS"] = "userChannel.validCards";
    UserChannelEmittingEventName["GET_STATE"] = "userChannel.getState";
    UserChannelEmittingEventName["LEAVE_USER"] = "userChannel.leaveUser";
    UserChannelEmittingEventName["REQUEST_GAME_STATE"] = "userChannel.requestGameState";
})(UserChannelEmittingEventName || (UserChannelEmittingEventName = {}));
export var UserChannelHandlingEvent;
(function (UserChannelHandlingEvent) {
    UserChannelHandlingEvent[UserChannelHandlingEvent["JOIN_ROOM"] = 2] = "JOIN_ROOM";
    UserChannelHandlingEvent[UserChannelHandlingEvent["GET_CARDS"] = 4] = "GET_CARDS";
    UserChannelHandlingEvent[UserChannelHandlingEvent["VALID_CARDS"] = 5] = "VALID_CARDS";
    UserChannelHandlingEvent[UserChannelHandlingEvent["WELCOME_USER_CB"] = 14] = "WELCOME_USER_CB";
    UserChannelHandlingEvent[UserChannelHandlingEvent["ALLOW_PLAY"] = 21] = "ALLOW_PLAY";
    UserChannelHandlingEvent[UserChannelHandlingEvent["LEAVE_USER"] = 27] = "LEAVE_USER";
    UserChannelHandlingEvent[UserChannelHandlingEvent["GAME_END_DATA"] = 32] = "GAME_END_DATA";
    UserChannelHandlingEvent[UserChannelHandlingEvent["CHARGE_USER"] = 36] = "CHARGE_USER";
    UserChannelHandlingEvent[UserChannelHandlingEvent["RECONNECT_GAME_STATE"] = 37] = "RECONNECT_GAME_STATE";
    UserChannelHandlingEvent[UserChannelHandlingEvent["INVALID_ACTION"] = 38] = "INVALID_ACTION";
    UserChannelHandlingEvent[UserChannelHandlingEvent["GAME_STATE_RESPONSE"] = 40] = "GAME_STATE_RESPONSE";
    UserChannelHandlingEvent[UserChannelHandlingEvent["REQUEST_DISCONNECT"] = 41] = "REQUEST_DISCONNECT";
})(UserChannelHandlingEvent || (UserChannelHandlingEvent = {}));
export var RoomChannelHandlingEvent;
(function (RoomChannelHandlingEvent) {
    RoomChannelHandlingEvent[RoomChannelHandlingEvent["FORCE_GAME_END"] = 6] = "FORCE_GAME_END";
    RoomChannelHandlingEvent[RoomChannelHandlingEvent["PLAYED_CARD"] = 7] = "PLAYED_CARD";
    RoomChannelHandlingEvent[RoomChannelHandlingEvent["GAME_START_DATA"] = 9] = "GAME_START_DATA";
    RoomChannelHandlingEvent[RoomChannelHandlingEvent["USER_LEFT"] = 39] = "USER_LEFT";
    RoomChannelHandlingEvent[RoomChannelHandlingEvent["SEND_EMOJI"] = 35] = "SEND_EMOJI";
})(RoomChannelHandlingEvent || (RoomChannelHandlingEvent = {}));
export var RoomChannelEmittingEvent;
(function (RoomChannelEmittingEvent) {
    RoomChannelEmittingEvent[RoomChannelEmittingEvent["PLAY"] = 8] = "PLAY";
    RoomChannelEmittingEvent[RoomChannelEmittingEvent["SAY_HELLO"] = 33] = "SAY_HELLO";
    RoomChannelEmittingEvent[RoomChannelEmittingEvent["SAY_HELLO_RECONNECT"] = 34] = "SAY_HELLO_RECONNECT";
    RoomChannelEmittingEvent[RoomChannelEmittingEvent["SEND_EMOJI"] = 35] = "SEND_EMOJI";
})(RoomChannelEmittingEvent || (RoomChannelEmittingEvent = {}));
export var RoomChannelEmittingEventName;
(function (RoomChannelEmittingEventName) {
    RoomChannelEmittingEventName["PLAY"] = "roomChannel.play";
    RoomChannelEmittingEventName["SAY_HELLO"] = "roomChannel.sayHello";
    RoomChannelEmittingEventName["SAY_HELLO_RECONNECT"] = "roomChannel.sayHelloReconnect";
    RoomChannelEmittingEventName["SEND_EMOJI"] = "roomChannel.sendEmoji";
})(RoomChannelEmittingEventName || (RoomChannelEmittingEventName = {}));
