var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MultiAtlases } from '../../../assets';
var PlayerGameAvatar = /** @class */ (function (_super) {
    __extends(PlayerGameAvatar, _super);
    function PlayerGameAvatar(scene, key, isNative) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.key = key;
        _this.isNative = isNative;
        _this.createBody();
        _this.prepare();
        return _this;
    }
    PlayerGameAvatar.prototype.showSmile = function (frame) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.currentSmile = _this.scene.make.image({
                            x: _this.frame.x,
                            y: _this.frame.y,
                            key: MultiAtlases.Emojis.Atlas.Name,
                            frame: frame,
                        });
                        _this.currentSmile.setMask(_this.maskObject);
                        _this.currentSmile.setScale(0);
                        _this.add(_this.currentSmile);
                        _this.scene.tweens.add({
                            targets: _this.currentSmile,
                            scaleX: _this.frame.displayWidth / _this.currentSmile.width,
                            scaleY: _this.frame.displayWidth / _this.currentSmile.width,
                            duration: 300,
                            ease: Phaser.Math.Easing.Back.Out,
                            onComplete: function () {
                                _this.hideSmile().then(resolve);
                            },
                        });
                    })];
            });
        });
    };
    PlayerGameAvatar.prototype.hideSmile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        if (!_this.currentSmile) {
                            return resolve();
                        }
                        _this.scene.tweens.killTweensOf(_this.currentSmile);
                        _this.scene.tweens.add({
                            targets: _this.currentSmile,
                            scaleX: 0,
                            scaleY: 0,
                            ease: Phaser.Math.Easing.Back.In,
                            duration: 200,
                            delay: 2000,
                            onComplete: function () {
                                _this.currentSmile.destroy();
                                _this.currentSmile = null;
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    PlayerGameAvatar.prototype.preDestroy = function () {
        !!this.currentSmile && this.scene.tweens.killTweensOf(this.currentSmile);
        this.picture.clearMask();
        this.masker.destroy();
    };
    PlayerGameAvatar.prototype.show = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.scene.tweens.killTweensOf(_this.list);
                        _this.scene.tweens.add({
                            targets: [_this.picture, _this.frame],
                            alpha: 1,
                            duration: 300,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    PlayerGameAvatar.prototype.updateMask = function () {
        this.masker.clear();
        this.masker.fillStyle(0xffffff);
        var transformMatrix = this.frame.getWorldTransformMatrix();
        this.masker.fillCircle(transformMatrix.tx, transformMatrix.ty, this.frame.displayWidth * 0.5);
    };
    PlayerGameAvatar.prototype.updateAvatar = function (key) {
        this.picture.setTexture(key);
    };
    PlayerGameAvatar.prototype.createBody = function () {
        this.createFrame();
        this.setSize(this.frame.displayWidth, this.frame.displayHeight);
        this.createPictureBackground();
        this.createPicture();
        this.bringToTop(this.frame);
        this.preparePicture();
    };
    PlayerGameAvatar.prototype.createFrame = function () {
        this.frame = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.OpponentAvatarFrame,
        });
        this.frame.setScale(1.35);
        this.add(this.frame);
    };
    PlayerGameAvatar.prototype.createPictureBackground = function () {
        this.pictureBackground = this.scene.make.image({
            x: 0,
            y: 0,
            key: this.isNative
                ? MultiAtlases.Login.Atlas.Name
                : MultiAtlases.GamePlay.Atlas.Name,
            frame: this.isNative
                ? MultiAtlases.Login.Atlas.Frames.AvatarPicBg
                : MultiAtlases.GamePlay.Atlas.Frames.AvatarPictureBackground,
        });
        this.pictureBackground.setScale(this.frame.displayWidth / this.pictureBackground.width);
        this.add(this.pictureBackground);
    };
    PlayerGameAvatar.prototype.createPicture = function () {
        this.picture = this.scene.make.image({
            x: 0,
            y: -this.height * 0.038,
            key: this.key,
        });
        this.add(this.picture);
    };
    PlayerGameAvatar.prototype.preparePicture = function () {
        this.picture.setScale(this.pictureBackground.displayWidth / this.picture.width);
        this.createMaskObject();
    };
    PlayerGameAvatar.prototype.createMaskObject = function () {
        this.masker = this.scene.make.graphics({});
        this.maskObject = new Phaser.Display.Masks.GeometryMask(this.scene, this.masker);
        this.picture.setMask(this.maskObject);
    };
    PlayerGameAvatar.prototype.prepare = function () {
        this.picture.setAlpha(0);
        this.frame.setAlpha(0);
    };
    return PlayerGameAvatar;
}(Phaser.GameObjects.Container));
export default PlayerGameAvatar;
