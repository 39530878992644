var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../../assets';
import WaitingView from '../WaitingView';
var WaitingViewExitButton = /** @class */ (function (_super) {
    __extends(WaitingViewExitButton, _super);
    function WaitingViewExitButton(scene) {
        var _this = _super.call(this, scene) || this;
        _this.createComponents();
        _this.prepare();
        return _this;
    }
    WaitingViewExitButton.prototype.show = function () {
        var _this = this;
        this.setVisible(true);
        this.scene.tweens.add({
            targets: this.background,
            duration: 200,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Back.Out,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: _this.button,
                    duration: 200,
                    scaleX: 1,
                    scaleY: 1,
                    ease: Phaser.Math.Easing.Back.Out,
                });
            },
        });
    };
    WaitingViewExitButton.prototype.createComponents = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createButton();
        this.setListeners();
    };
    WaitingViewExitButton.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SecondBackground,
        });
        this.add(this.background);
    };
    WaitingViewExitButton.prototype.createButton = function () {
        this.button = this.scene.make.image({
            x: 0,
            y: this.height * 0.05,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SecX,
        });
        this.add(this.button);
    };
    WaitingViewExitButton.prototype.setListeners = function () {
        this.setInteractive();
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
    };
    WaitingViewExitButton.prototype.onPointerDown = function () {
        this.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.once(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    WaitingViewExitButton.prototype.onPointerOut = function () {
        this.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.off(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    WaitingViewExitButton.prototype.onPointerUp = function () {
        this.removeAllListeners();
        this.parentContainer.emit(WaitingView.EXIT_CLICKED_EVENT);
    };
    WaitingViewExitButton.prototype.prepare = function () {
        this.background.setScale(0);
        this.button.setScale(0);
        this.setScale(0.6);
        this.setVisible(false);
    };
    return WaitingViewExitButton;
}(Phaser.GameObjects
    .Container));
export default WaitingViewExitButton;
