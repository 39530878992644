var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Audios } from '../../../assets';
import BaseTurnButtonState from './BaseTurnButtonState';
var BaseTurnButton = /** @class */ (function (_super) {
    __extends(BaseTurnButton, _super);
    function BaseTurnButton(scene, configs) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.configs = configs;
        _this.inputState = BaseTurnButtonInputState.UP;
        _this.createBody();
        _this.setListeners();
        return _this;
    }
    BaseTurnButton.prototype.setAction = function (action) {
        this.configs.action = action;
    };
    BaseTurnButton.prototype.playSfxOnUp = function () {
        this.scene.playSFX(Audios.ButtonTapSfx.Name);
    };
    BaseTurnButton.prototype.setEnabled = function (enabled, state) {
        if (state === void 0) { state = this.inputState ||
            BaseTurnButtonInputState.UP; }
        enabled ? this.makeEnabled(state) : this.makeDisabled();
    };
    BaseTurnButton.prototype.makeEnabled = function (inputState) {
        if (inputState === void 0) { inputState = BaseTurnButtonInputState.UP; }
        this.input.enabled = true;
        this.inputState = inputState;
        this.normalState.setVisible(inputState === BaseTurnButtonInputState.UP);
        this.configs.downState &&
            this.downState.setVisible(inputState !== BaseTurnButtonInputState.UP);
        this.configs.disabledState && this.disabledState.setVisible(false);
    };
    BaseTurnButton.prototype.makeDisabled = function () {
        this.input.enabled = false;
        this.inputState = BaseTurnButtonInputState.DISABLED;
        if (this.configs.disabledState) {
            this.configs.hoverDownState && this.hoverDownState.setVisible(false);
            this.configs.hoverNormalState && this.hoverNormalState.setVisible(false);
            this.normalState.setVisible(false);
            this.disabledState.setVisible(true);
        }
        else {
            this.normalState.setVisible(true);
        }
    };
    BaseTurnButton.prototype.createBody = function () {
        this.createNormalState();
        this.configs.hoverNormalState && this.createNormalHoverState();
        this.configs.downState && this.createDownState();
        this.configs.hoverDownState && this.createDownHoverState();
        this.configs.disabledState && this.createDisabledState();
        this.setSize(this.normalState.width, this.normalState.height);
    };
    BaseTurnButton.prototype.createNormalState = function () {
        this.normalState = new BaseTurnButtonState(this.scene, this.configs.normalState);
        this.add(this.normalState);
    };
    BaseTurnButton.prototype.createNormalHoverState = function () {
        this.hoverNormalState = new BaseTurnButtonState(this.scene, this.configs.hoverNormalState);
        this.add(this.hoverNormalState);
        this.hoverNormalState.setVisible(false);
    };
    BaseTurnButton.prototype.createDownHoverState = function () {
        this.hoverDownState = new BaseTurnButtonState(this.scene, this.configs.hoverDownState);
        this.add(this.hoverDownState);
        this.hoverDownState.setVisible(false);
    };
    BaseTurnButton.prototype.createDownState = function () {
        this.downState = new BaseTurnButtonState(this.scene, this.configs.downState);
        this.add(this.downState);
        this.downState.setVisible(false);
    };
    BaseTurnButton.prototype.createDisabledState = function () {
        this.disabledState = new BaseTurnButtonState(this.scene, this.configs.disabledState);
        this.add(this.disabledState);
        this.disabledState.setVisible(false);
    };
    BaseTurnButton.prototype.setListeners = function () {
        this.setInteractive();
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
        (!!this.configs.hoverNormalState || !!this.configs.hoverDownState) &&
            this.on(Phaser.Input.Events.POINTER_OVER, this.onPointerOver, this);
        this.on(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    BaseTurnButton.prototype.onPointerDown = function () {
        if (this.configs.downState) {
            this.normalState.setVisible(false);
            this.configs.hoverNormalState && this.hoverNormalState.setVisible(false);
            this.configs.hoverDownState && this.downState.setVisible(true);
        }
        this.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
    };
    BaseTurnButton.prototype.onPointerOver = function () {
        if (this.inputState === BaseTurnButtonInputState.UP) {
            this.hoverNormalState.setVisible(true);
            this.configs.hoverDownState && this.hoverDownState.setVisible(false);
        }
        else {
            this.configs.hoverNormalState && this.hoverNormalState.setVisible(false);
            this.configs.hoverDownState && this.hoverDownState.setVisible(true);
        }
        this.normalState.setVisible(false);
        this.configs.downState && this.downState.setVisible(false);
    };
    BaseTurnButton.prototype.onPointerOut = function () {
        if (this.inputState === BaseTurnButtonInputState.UP) {
            this.normalState.setVisible(true);
            this.configs.downState && this.downState.setVisible(false);
        }
        else {
            this.normalState.setVisible(false);
            this.configs.downState && this.downState.setVisible(true);
        }
        this.configs.hoverNormalState && this.hoverNormalState.setVisible(false);
        this.configs.hoverDownState && this.hoverDownState.setVisible(false);
        this.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
    };
    BaseTurnButton.prototype.onPointerUp = function () {
        if (this.inputState === BaseTurnButtonInputState.UP) {
            this.configs.downState && this.downState.setVisible(true);
            this.normalState.setVisible(false);
            this.inputState = BaseTurnButtonInputState.DOWN;
        }
        else if (this.inputState === BaseTurnButtonInputState.DOWN) {
            this.normalState.setVisible(true);
            this.configs.downState && this.downState.setVisible(false);
            this.inputState = BaseTurnButtonInputState.UP;
        }
        this.configs.hoverNormalState && this.hoverNormalState.setVisible(false);
        this.configs.hoverDownState && this.hoverDownState.setVisible(false);
        this.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.onClick();
    };
    BaseTurnButton.prototype.onClick = function () {
        this.emit(BaseTurnButton.CLICK_EVENT, this.configs.action);
    };
    BaseTurnButton.CLICK_EVENT = 'click';
    return BaseTurnButton;
}(Phaser.GameObjects.Container));
export default BaseTurnButton;
export var BaseTurnButtonInputState;
(function (BaseTurnButtonInputState) {
    BaseTurnButtonInputState[BaseTurnButtonInputState["DISABLED"] = 0] = "DISABLED";
    BaseTurnButtonInputState[BaseTurnButtonInputState["UP"] = 1] = "UP";
    BaseTurnButtonInputState[BaseTurnButtonInputState["DOWN"] = 2] = "DOWN";
})(BaseTurnButtonInputState || (BaseTurnButtonInputState = {}));
