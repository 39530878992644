var cardsMap = {
    // hearts
    a: {
        suit: 0,
        value: 0,
    },
    b: {
        suit: 0,
        value: 1,
    },
    c: {
        suit: 0,
        value: 2,
    },
    d: {
        suit: 0,
        value: 3,
    },
    e: {
        suit: 0,
        value: 4,
    },
    f: {
        suit: 0,
        value: 5,
    },
    g: {
        suit: 0,
        value: 6,
    },
    h: {
        suit: 0,
        value: 7,
    },
    i: {
        suit: 0,
        value: 8,
    },
    //ghar
    j: {
        suit: 1,
        value: 0,
    },
    k: {
        suit: 1,
        value: 1,
    },
    l: {
        suit: 1,
        value: 2,
    },
    m: {
        suit: 1,
        value: 3,
    },
    n: {
        suit: 1,
        value: 4,
    },
    o: {
        suit: 1,
        value: 5,
    },
    p: {
        suit: 1,
        value: 6,
    },
    q: {
        suit: 1,
        value: 7,
    },
    r: {
        suit: 1,
        value: 8,
    },
    //qyap
    s: {
        suit: 2,
        value: 0,
    },
    t: {
        suit: 2,
        value: 1,
    },
    u: {
        suit: 2,
        value: 2,
    },
    v: {
        suit: 2,
        value: 3,
    },
    w: {
        suit: 2,
        value: 4,
    },
    x: {
        suit: 2,
        value: 5,
    },
    y: {
        suit: 2,
        value: 6,
    },
    z: {
        suit: 2,
        value: 7,
    },
    A: {
        suit: 2,
        value: 8,
    },
    //xach
    B: {
        suit: 3,
        value: 0,
    },
    C: {
        suit: 3,
        value: 1,
    },
    D: {
        suit: 3,
        value: 2,
    },
    E: {
        suit: 3,
        value: 3,
    },
    F: {
        suit: 3,
        value: 4,
    },
    G: {
        suit: 3,
        value: 5,
    },
    H: {
        suit: 3,
        value: 6,
    },
    I: {
        suit: 3,
        value: 7,
    },
    J: {
        suit: 3,
        value: 8,
    },
};
export function decrypt(card) {
    return cardsMap[card] || null;
}
export function encrypt(target) {
    var keys = Object.keys(cardsMap);
    var result = keys.filter(function (key) {
        return cardsMap[key].suit === target.suit &&
            cardsMap[key].value === target.value;
    })[0];
    return result;
}
window.decrypt = decrypt;
