var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Mediator } from '@candywings/pure-mvc';
import GameScene from '../../scenes/GameScene';
import { getScene } from '../../utils/phaser/PhaserUtils';
import EmojiContainer from './control/scroller/EmojiContainer';
import { EmojiType } from './control/scroller/MessageTypeButton';
import ControlView, { ControlAction } from './ControlView';
import WaitingView from './WaitingView';
import StateVOProxy from '../../../model/StateVOProxy';
var ControlViewMediator = /** @class */ (function (_super) {
    __extends(ControlViewMediator, _super);
    function ControlViewMediator() {
        return _super.call(this, ControlViewMediator.NAME, null) || this;
    }
    ControlViewMediator.prototype.onRemove = function () {
        this.viewComponent && this.viewComponent.destroy();
    };
    ControlViewMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(GameScene.FORCE_START_NOTIFICATION, GameScene.CREATE_NOTIFICATION, GameScene.RECREATE_NOTIFICATION, GameScene.REPLAY_NOTIFICATION, WaitingView.SHOWN_NOTIFICATION, WaitingView.HIDDEN_NOTIFICATION, StateVOProxy.GAME_OVER_NOTIFICATION);
    };
    ControlViewMediator.prototype.handleNotification = function (notificationName) {
        switch (notificationName) {
            case GameScene.FORCE_START_NOTIFICATION:
            case GameScene.CREATE_NOTIFICATION:
            case GameScene.RECREATE_NOTIFICATION:
                this.setView();
                break;
            case WaitingView.SHOWN_NOTIFICATION:
            case StateVOProxy.GAME_OVER_NOTIFICATION:
                this.viewComponent.onWaitingViewActive();
                break;
            case WaitingView.HIDDEN_NOTIFICATION:
                this.viewComponent.onWaitingViewInactive();
                break;
            default:
                console.warn(notificationName + " is unhandled!");
                break;
        }
    };
    ControlViewMediator.prototype.onActionDone = function (action) {
        switch (action) {
            case ControlAction.BACK:
                this.sendNotification(ControlView.BACK_NOTIFICATION);
                this.viewComponent.hideMessageScroller();
                break;
            case ControlAction.SETTINGS:
                this.sendNotification(ControlView.SETTINGS_NOTIFICATION);
                this.viewComponent.hideMessageScroller();
                break;
            case ControlAction.MESSAGES:
                this.viewComponent.toggleMessageScroller(EmojiType.TEXT);
                break;
            case ControlAction.SMILES:
                this.viewComponent.toggleMessageScroller(EmojiType.EMOJI);
                break;
            default:
                break;
        }
    };
    ControlViewMediator.prototype.setView = function () {
        if (this.viewComponent) {
            return;
        }
        var view = new ControlView(this.scene);
        this.setViewComponent(view);
        this.viewComponent.on(ControlView.ACTION_DONE_EVENT, this.onActionDone, this);
        this.viewComponent.on(EmojiContainer.EMOJI_ACTION_DONE_EVENT, this.onEmojiClick, this);
    };
    ControlViewMediator.prototype.onEmojiClick = function (type, key) {
        this.viewComponent.hideMessageScroller();
        this.sendNotification(ControlView.EMOJI_SEND_NOTIFICATION, type, key);
    };
    Object.defineProperty(ControlViewMediator.prototype, "scene", {
        get: function () {
            return getScene(GameScene.NAME);
        },
        enumerable: true,
        configurable: true
    });
    ControlViewMediator.NAME = 'ControlViewMediator';
    return ControlViewMediator;
}(Mediator));
export default ControlViewMediator;
