var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { Translation } from '../../translations';
import SimpleButtonWithBitmapText from '../utils/simpleButton/SimpleButtonWithBitmapText';
import StandardPopup from './StandardPopup';
export var ExitConfirmationPopupAction;
(function (ExitConfirmationPopupAction) {
    ExitConfirmationPopupAction[ExitConfirmationPopupAction["POSITIVE"] = 0] = "POSITIVE";
    ExitConfirmationPopupAction[ExitConfirmationPopupAction["NEGATIVE"] = 1] = "NEGATIVE";
})(ExitConfirmationPopupAction || (ExitConfirmationPopupAction = {}));
var ExitConfirmationPopup = /** @class */ (function (_super) {
    __extends(ExitConfirmationPopup, _super);
    function ExitConfirmationPopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExitConfirmationPopup.prototype.createBody = function () {
        this.createColoredBlocker(0.7);
        this.createBgImage(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.ExitConfirmationPopupBackground);
        this.createTitleIcon();
        this.createText();
        this.createButtons();
    };
    ExitConfirmationPopup.prototype.createText = function () {
        this.text = this.scene.make.extBitmapText({
            x: 0,
            y: -this.height * 0.175,
            text: Translation.EXIT_CONFIRMATION_POPUP_TEXT,
            font: BitmapFonts.RobotoSlab.Name,
            size: 90 * gameConfig.resolutionMultiplier,
        });
        this.text.setTint(0xf1dcb2);
        this.text.setOrigin(0.5);
        this.text.setCenterAlign();
        this.add(this.text);
    };
    ExitConfirmationPopup.prototype.createButtons = function () {
        this.createPositiveButton();
        this.createNegativeButton();
    };
    ExitConfirmationPopup.prototype.createTitleIcon = function () {
        this.titleIcon = this.scene.make.image({
            x: 0,
            y: -this.height * 0.425,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.ExitConfirmationPopupTitleIcon,
        });
        this.add(this.titleIcon);
    };
    ExitConfirmationPopup.prototype.createPositiveButton = function () {
        var textConfig = {
            text: Translation.EXIT_CONFIRMATION_POPUP_POSITIVE_BUTTON,
            size: 45 * gameConfig.resolutionMultiplier,
            fill: 0xffffd8,
            font: BitmapFonts.RobotoSlab.Name,
            align: Phaser.GameObjects.BitmapText.ALIGN_CENTER,
            origin: { x: 0.5, y: 0.6 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PositiveButtonUp,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PositiveButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PositiveButtonDown,
            textConfig: textConfig,
        };
        var config = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        this.positiveButton = new SimpleButtonWithBitmapText(this.scene, config);
        this.add(this.positiveButton);
        this.positiveButton.x = -this.width * 0.17;
        this.positiveButton.y = this.height * 0.24;
        this.positiveButton.on(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction.bind(this, ExitConfirmationPopupAction.POSITIVE));
    };
    ExitConfirmationPopup.prototype.createNegativeButton = function () {
        var textConfig = {
            text: Translation.EXIT_CONFIRMATION_POPUP_NEGATIVE_BUTTON,
            size: 45 * gameConfig.resolutionMultiplier,
            fill: 0xffffd8,
            font: BitmapFonts.RobotoSlab.Name,
            align: Phaser.GameObjects.BitmapText.ALIGN_CENTER,
            origin: { x: 0.5, y: 0.6 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.NegativeButtonUp,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.NegativeButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.NegativeButtonDown,
            textConfig: textConfig,
        };
        var config = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        this.negativeButton = new SimpleButtonWithBitmapText(this.scene, config);
        this.add(this.negativeButton);
        this.negativeButton.x = this.width * 0.17;
        this.negativeButton.y = this.height * 0.24;
        this.negativeButton.on(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction.bind(this, ExitConfirmationPopupAction.NEGATIVE));
    };
    ExitConfirmationPopup.NAME = "ExitConfirmationPopup";
    ExitConfirmationPopup.EXIT_ROOM_NOTIFICATION = ExitConfirmationPopup.NAME + "ExitRoomNotification";
    return ExitConfirmationPopup;
}(StandardPopup));
export default ExitConfirmationPopup;
