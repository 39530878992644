var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Audios, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import CardView from './CardView';
var TurnView = /** @class */ (function (_super) {
    __extends(TurnView, _super);
    function TurnView(scene) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.clearCards();
        _this.createSwapArea();
        _this.repositionPromise = [];
        _this.bitaPromise = [];
        _this.darkenState = false;
        return _this;
    }
    TurnView.prototype.preDestroy = function () {
        this.scene.tweens.killTweensOf(this.cards);
        this.swapArea.destroy();
        this.clearCards();
        _super.prototype.preDestroy.call(this);
    };
    TurnView.prototype.forceUpdateCards = function (pairs) {
        return __awaiter(this, void 0, void 0, function () {
            var _i, pairs_1, pair, attack, defence;
            return __generator(this, function (_a) {
                for (_i = 0, pairs_1 = pairs; _i < pairs_1.length; _i++) {
                    pair = pairs_1[_i];
                    attack = new CardView(this.scene, gameConfig.canvasWidth / 2, gameConfig.canvasHeight / 2, pair.attack.suit, pair.attack.value);
                    attack.setDepth(-55);
                    this.scene.add.existing(attack);
                    defence = null;
                    if (pair.defence) {
                        defence = new CardView(this.scene, gameConfig.canvasWidth / 2, gameConfig.canvasHeight / 2, pair.defence.suit, pair.defence.value);
                        defence.setDepth(-50);
                        this.scene.add.existing(defence);
                    }
                    this.pairs.push({
                        attack: attack,
                        defence: defence,
                    });
                }
                this.repositionCards();
                return [2 /*return*/];
            });
        });
    };
    TurnView.prototype.addCard = function (playedCard, target) {
        if (!target) {
            this.pairs.push({
                attack: playedCard,
                defence: undefined,
            });
            playedCard.setDepth(-55);
            playedCard.angle = 0;
        }
        else {
            var targetCard = this.attackCards.filter(function (card) {
                return card.suit === target.suit && card.value === target.value;
            })[0];
            var targetCardIndex = this.attackCards.indexOf(targetCard);
            this.pairs[targetCardIndex].defence = playedCard;
            playedCard.setDepth(-50);
        }
        this.scene.playSFX(Audios.CardPlaySfx.Name);
    };
    TurnView.prototype.giveTargetCard = function (cardData) {
        var targetCard = this.attackCards.filter(function (card) {
            return card.suit === cardData.suit && card.value === cardData.value;
        })[0];
        return targetCard;
    };
    TurnView.prototype.bitaCards = function (cards) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var pairs;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                pairs = this.pairs.filter(function (pair) {
                    return cards.contains(pair.attack) || cards.contains(pair.defence);
                });
                (_a = this.pairs).remove.apply(_a, pairs);
                this.scene.tweens.killTweensOf(cards);
                this.scene.tweens.add({
                    targets: cards,
                    scaleX: 0,
                    duration: 150,
                    delay: 100,
                    ease: Phaser.Math.Easing.Back.In,
                    yoyo: true,
                    onYoyo: function () {
                        for (var _i = 0, cards_1 = cards; _i < cards_1.length; _i++) {
                            var card = cards_1[_i];
                            card.setTexture(MultiAtlases.Cards.Atlas.Name, MultiAtlases.Cards.Atlas.Frames.Cover);
                        }
                    },
                    onComplete: function () {
                        for (var i = 0; i < cards.length; i++) {
                            _this.scene.tweens.add({
                                targets: cards[i],
                                x: gameConfig.canvasWidth / 2 + i,
                                y: gameConfig.canvasHeight / 2 - i / 2,
                                duration: 100,
                            });
                        }
                        _this.scene.tweens.add({
                            targets: cards,
                            ease: Phaser.Math.Easing.Expo.InOut,
                            rotation: function () { return Math.PI * Math.random(); },
                            delay: 200,
                            duration: 300,
                            x: function () { return gameConfig.canvasWidth + cards[0].height + 100; },
                            y: function () { return 50; },
                            scaleX: 0.6,
                            scaleY: 0.6,
                            onComplete: function () {
                                for (var _i = 0, cards_2 = cards; _i < cards_2.length; _i++) {
                                    var card = cards_2[_i];
                                    card.destroy();
                                }
                                resolve();
                            },
                        });
                    },
                });
                return [2 /*return*/];
            });
        }); });
    };
    TurnView.prototype.getCards = function () {
        this.hideHighLights();
        this.removeSwapAreaFromCardsList();
        var cards = this.cards.slice();
        this.clearCards();
        return cards;
    };
    TurnView.prototype.clearCards = function () {
        this.pairs = [];
    };
    TurnView.prototype.enableSwapArea = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.swapArea.visible = true;
                this.swapArea.input.dropZone = true;
                if (this.cards.indexOf(this.swapArea) !== -1) {
                    return [2 /*return*/];
                }
                this.pairs.push({
                    attack: this.swapArea,
                    defence: undefined,
                });
                this.repositionCards();
                return [2 /*return*/];
            });
        });
    };
    TurnView.prototype.disableSwapArea = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.scene.tweens.killTweensOf(this.swapArea);
                this.removeSwapAreaFromCardsList();
                this.repositionCards();
                return [2 /*return*/];
            });
        });
    };
    TurnView.prototype.disableCardZones = function () {
        for (var _i = 0, _a = this.cards; _i < _a.length; _i++) {
            var card = _a[_i];
            !card.input && card.setInteractive();
            card.input.dropZone = false;
            card.input.enabled = false;
            card.removeAllListeners();
        }
    };
    TurnView.prototype.activateCardZones = function (card) {
        var targetCards = [];
        if (card.targets) {
            var _loop_1 = function (target) {
                var targetCard = this_1.attackCards.find(function (card) {
                    return card.suit === target.suit && card.value === target.value;
                });
                targetCard && targetCards.push(targetCard);
            };
            var this_1 = this;
            for (var _i = 0, _a = card.targets; _i < _a.length; _i++) {
                var target = _a[_i];
                _loop_1(target);
            }
            card.swapEnabled && targetCards.push(this.swapArea);
        }
        this.showHighLights(card, targetCards);
    };
    TurnView.prototype.disableZones = function () {
        this.disableCardZones();
        this.hideHighLights();
        this.disableSwapArea();
    };
    TurnView.prototype.showHighLights = function (playedCard, targets) {
        var noneTargetCards = this.cards.filter(function (card) { return !targets.contains(card); });
        for (var _i = 0, targets_1 = targets; _i < targets_1.length; _i++) {
            var target = targets_1[_i];
            target.setPlayedHighLight(target !== this.swapArea);
            target.once(CardView.HIGH_LIGHTED_CLICK_EVENT, this.onHighLightedCardClick.bind(this, playedCard, target), this);
        }
        for (var _a = 0, noneTargetCards_1 = noneTargetCards; _a < noneTargetCards_1.length; _a++) {
            var card = noneTargetCards_1[_a];
            card.removePlayedHighLight();
            // card.setDarkerState(true);
        }
    };
    TurnView.prototype.hideHighLights = function () {
        for (var _i = 0, _a = this.cards; _i < _a.length; _i++) {
            var card = _a[_i];
            card.removePlayedHighLight();
        }
    };
    TurnView.prototype.repositionCards = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var promises, x, y, count, cardFrame, sumWidth, line, points, i, attackCard, defenceCard;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promises = [];
                        count = this.attackCards.length - 1;
                        if (this.pairs.length === 0) {
                            resolve();
                            return [2 /*return*/];
                        }
                        cardFrame = this.scene.textures.getFrame(MultiAtlases.Cards.Atlas.Name, MultiAtlases.Cards.Atlas.Frames.Card00);
                        sumWidth = cardFrame.width * this.pairs.length;
                        line = new Phaser.Geom.Line(gameConfig.canvasWidth / 2 - sumWidth / 2, gameConfig.canvasHeight / 2, gameConfig.canvasWidth / 2 + sumWidth / 2, gameConfig.canvasHeight / 2);
                        points = line.getPoints(this.pairs.length + 1);
                        points.shift();
                        for (i = 0; i <= count; i++) {
                            attackCard = this.pairs[i].attack;
                            defenceCard = this.pairs[i].defence;
                            x = points[i].x;
                            y = points[i].y;
                            if (attackCard === this.swapArea) {
                                this.swapArea.x = gameConfig.canvasWidth * 0.8;
                            }
                            promises.push(this.repositionCard(attackCard, x, y));
                            if (defenceCard) {
                                x -= defenceCard.width * 0.6 * 0.2;
                                y -= defenceCard.height * 0.6 * 0.15;
                                promises.push(this.repositionCard(defenceCard, x, y));
                            }
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        resolve();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    TurnView.prototype.repositionCard = function (card, x, y) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.scene.tweens.killTweensOf(card);
            _this.scene.tweens.add({
                targets: card,
                x: x,
                y: y,
                scaleX: 0.6,
                scaleY: 0.6,
                duration: 100,
                onComplete: function () {
                    card.setDarkerState(_this.darkenState);
                    resolve();
                },
            });
        });
    };
    TurnView.prototype.createSwapArea = function () {
        this.swapArea = new CardView(this.scene, gameConfig.canvasWidth * 0.8, gameConfig.canvasHeight * 0.5, -1, -1);
        this.scene.add.existing(this.swapArea);
        this.swapArea.setInteractive();
        this.swapArea.input.dropZone = true;
        this.swapArea.visible = false;
        this.swapArea.depth = -55;
    };
    TurnView.prototype.onHighLightedCardClick = function (playingCard, target) {
        for (var _i = 0, _a = this.cards; _i < _a.length; _i++) {
            var card = _a[_i];
            card.removeAllListeners();
        }
        this.emit(TurnView.HIGH_LIGHTED_CARD_CLICKED_EVENT, playingCard, target);
    };
    TurnView.prototype.removeSwapAreaFromCardsList = function () {
        var swapAreaIndex = this.attackCards.indexOf(this.swapArea);
        if (swapAreaIndex !== -1) {
            this.pairs.splice(swapAreaIndex, 1);
        }
        this.swapArea.visible = false;
        !this.swapArea.input && this.swapArea.setInteractive();
        this.swapArea.input.dropZone = false;
    };
    Object.defineProperty(TurnView.prototype, "cards", {
        get: function () {
            return this.attackCards.concat(this.defenceCards);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TurnView.prototype, "attackCards", {
        get: function () {
            return this.pairs.map(function (pair) { return pair.attack; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TurnView.prototype, "defenceCards", {
        get: function () {
            return this.pairs
                .filter(function (pair) { return !!pair.defence; })
                .map(function (pair) { return pair.defence; });
        },
        enumerable: true,
        configurable: true
    });
    TurnView.NAME = 'TurnView';
    TurnView.GET_CARDS_NOTIFICATION = TurnView.NAME + "GetCardsNotification";
    TurnView.COVERED_CARDS_NOTIFICATION = TurnView.NAME + "CoveredCardsNotification";
    TurnView.HIGH_LIGHTED_CARD_CLICKED_NOTIFICATION = TurnView.NAME + "HighLightedCardClickedNotification";
    TurnView.CARD_PLAY_DONE_NOTIFICATION = TurnView.NAME + "CardPlayDoneNotification";
    TurnView.ROUND_END_NOTIFICATION = TurnView.NAME + "RoundEndNotification";
    TurnView.HIGH_LIGHTED_CARD_CLICKED_EVENT = 'highLightedCardClicked';
    return TurnView;
}(Phaser.GameObjects.Container));
export default TurnView;
