var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Atlases, Audios, Spines } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
import { isCordova, isMobile } from '../../../../utils/Utils';
import LobbyScene from '../../../scenes/LobbyScene';
import { delayRunnable } from '../../../utils/phaser/PhaserUtils';
import SpineMaker from '../../../utils/plugins/spine/SpineMaker';
import NavigationSectionActiveState from './NavigationSectionActiveState';
import NavigationSectionPassiveState from './NavigationSectionPassiveState';
var scale = 0.82;
var NavigationSection = /** @class */ (function (_super) {
    __extends(NavigationSection, _super);
    function NavigationSection(scene, mode) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.mode = mode;
        _this.createBody();
        _this.scene.add.existing(_this);
        // this.mode === NavigationMode.BONUS &&
        //   delayRunnable(this.scene, 1000, this.onPointerUp, this);
        _this.selected = true;
        _this.makeUnSelected();
        return _this;
    }
    NavigationSection.prototype.setListeners = function () {
        if (this.mode === NavigationMode.LEAGUE) {
            return;
        }
        !this.input && this.setInteractive();
        this.removeAllListeners();
        isCordova() && isMobile()
            ? this.setMobileListeners()
            : this.setBrowserListeners();
    };
    NavigationSection.prototype.makeSelected = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (this.selected) {
                                        return [2 /*return*/, resolve()];
                                    }
                                    else {
                                        delayRunnable(this.scene, 300, resolve);
                                    }
                                    this.selected = true;
                                    this.scene.playSFX(Audios[this.mode + "SpineSfx"].Name);
                                    this.scene.tweens.killTweensOf([
                                        this.passiveState,
                                        this.activeState,
                                        this,
                                    ]);
                                    this.scene.tweens.add({
                                        targets: this.passiveState,
                                        alpha: 0,
                                        duration: 100,
                                    });
                                    this.scene.tweens.add({
                                        targets: this.activeState,
                                        alpha: 1,
                                        duration: 50,
                                    });
                                    this.scene.tweens.add({
                                        targets: this,
                                        scaleX: 1,
                                        scaleY: 1,
                                        y: (gameConfig.canvasHeight + this.topBarHeight) * 0.46 +
                                            this.height * 0.08,
                                        duration: 100,
                                        onComplete: function () {
                                            _this.activeState.setAlpha(1);
                                        },
                                    });
                                    return [4 /*yield*/, this.playOpeningAnimation()];
                                case 1:
                                    _a.sent();
                                    this.playSelectedIdleAnimation();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    NavigationSection.prototype.makeUnSelected = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                            if (this.mode === NavigationMode.LEAGUE) {
                                this.scene.tweens.add({
                                    targets: this,
                                    scaleX: scale,
                                    scaleY: scale,
                                    y: (gameConfig.canvasHeight + this.topBarHeight) * 0.463,
                                    duration: 100,
                                    onComplete: function () {
                                        _this.passiveState.setAlpha(1);
                                    },
                                });
                                return [2 /*return*/, resolve()];
                            }
                            if (!this.selected) {
                                return [2 /*return*/, resolve()];
                            }
                            this.selected = false;
                            this.scene.stopSFX();
                            this.scene.tweens.killTweensOf([
                                this.passiveState,
                                this.activeState,
                                this,
                            ]);
                            this.scene.tweens.add({
                                targets: this,
                                scaleX: scale,
                                scaleY: scale,
                                y: (gameConfig.canvasHeight + this.topBarHeight) * 0.463,
                                duration: 100,
                                onComplete: function () {
                                    _this.passiveState.setAlpha(1);
                                },
                            });
                            this.scene.tweens.add({
                                targets: this.activeState,
                                alpha: 0,
                                duration: 100,
                            });
                            this.scene.tweens.add({
                                targets: this.passiveState,
                                alpha: 1,
                                duration: 50,
                            });
                            this.playDeselectedIdleAnimation();
                            resolve();
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    NavigationSection.prototype.playSelectedIdleAnimation = function () {
        this.spine.removeAllListeners();
        this.spine.play(Spines[this.mode + "Spine"].Spine.Animations.SelectedIdle, true);
    };
    NavigationSection.prototype.playDeselectedIdleAnimation = function () {
        this.spine.removeAllListeners();
        this.spine.play(Spines[this.mode + "Spine"].Spine.Animations.DeselectedIdle, true);
    };
    NavigationSection.prototype.playOpeningAnimation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.spine.removeAllListeners();
                        _this.spine.play(Spines[_this.mode + "Spine"].Spine.Animations.Transition, false);
                        _this.spine.once(Phaser.Animations.Events.ANIMATION_COMPLETE, resolve);
                    })];
            });
        });
    };
    NavigationSection.prototype.createBody = function () {
        this.createBackgrounds();
        this.mode !== NavigationMode.LEAGUE
            ? this.createSpine()
            : this.createClosedDoor();
    };
    NavigationSection.prototype.createBackgrounds = function () {
        this.createPassiveState();
        this.mode !== NavigationMode.LEAGUE && this.createActiveState();
        this.setSize(this.passiveState.width, this.passiveState.height);
    };
    NavigationSection.prototype.createClosedDoor = function () {
        var door = this.scene.make
            .image({
            x: 0,
            y: 0,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames.IconLocked,
        })
            .setScale(1 / scale);
        this.add(door);
        var lock = this.scene.make.image({
            x: 0,
            y: -door.height * 0.05,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames.Lock,
        });
        lock.setOrigin(0.5, 0);
        this.add(lock);
        var connector = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames.DoorAndLockConnector,
        });
        this.add(connector);
        this.bringToTop(connector);
    };
    NavigationSection.prototype.createActiveState = function () {
        this.activeState = new NavigationSectionActiveState(this.scene, this.mode);
        this.add(this.activeState);
        this.activeState.setAlpha(0);
    };
    NavigationSection.prototype.createPassiveState = function () {
        this.passiveState = new NavigationSectionPassiveState(this.scene, this.mode);
        this.add(this.passiveState);
    };
    NavigationSection.prototype.createSpine = function () {
        this.spine = SpineMaker.add(this.scene, 0, 0, Spines[this.mode + "Spine"].Spine.Name, Spines[this.mode + "Spine"].Spine.Animations.DeselectedIdle, false);
        this.add(this.spine);
        this.spine.setScale(gameConfig.resolutionMultiplier);
        this.playSelectedIdleAnimation();
        this.spine.setMix(Spines[this.mode + "Spine"].Spine.Animations.Transition, Spines[this.mode + "Spine"].Spine.Animations.SelectedIdle, 0.5);
        this.spine.setMix(Spines[this.mode + "Spine"].Spine.Animations.SelectedIdle, Spines[this.mode + "Spine"].Spine.Animations.Transition, 0.5);
        this.spine.setMix(Spines[this.mode + "Spine"].Spine.Animations.Transition, Spines[this.mode + "Spine"].Spine.Animations.DeselectedIdle, 0.5);
        this.spine.setMix(Spines[this.mode + "Spine"].Spine.Animations.DeselectedIdle, Spines[this.mode + "Spine"].Spine.Animations.Transition, 0.5);
        this.spine.setMix(Spines[this.mode + "Spine"].Spine.Animations.SelectedIdle, Spines[this.mode + "Spine"].Spine.Animations.DeselectedIdle, 0.25);
    };
    NavigationSection.prototype.onPointerDown = function () {
        this.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
    };
    NavigationSection.prototype.onPointerUp = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.scene.events.emit(LobbyScene.ACTION_DONE_EVENT, this.mode);
                return [2 /*return*/];
            });
        });
    };
    NavigationSection.prototype.onPointerOver = function () {
        this.makeSelected();
        this.once(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    NavigationSection.prototype.onPointerOut = function () {
        this.makeUnSelected();
        this.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
    };
    NavigationSection.prototype.setBrowserListeners = function () {
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
        this.on(Phaser.Input.Events.POINTER_OVER, this.onPointerOver, this);
    };
    NavigationSection.prototype.setMobileListeners = function () {
        this.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp);
    };
    Object.defineProperty(NavigationSection.prototype, "topBarHeight", {
        get: function () {
            var topBarBgFrame = this.scene.textures.getFrame(Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.Background);
            return topBarBgFrame.height;
        },
        enumerable: true,
        configurable: true
    });
    return NavigationSection;
}(Phaser.GameObjects.Container));
export default NavigationSection;
export var NavigationMode;
(function (NavigationMode) {
    NavigationMode["BONUS"] = "Bonus";
    NavigationMode["GAME"] = "Game";
    NavigationMode["LEAGUE"] = "League";
})(NavigationMode || (NavigationMode = {}));
