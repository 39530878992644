var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import StateVOProxy from '../../model/StateVOProxy';
import IsDeckVOProxyExistGuard from '../Guards/deck/IsDeckVOProxyExistGuard';
import DeckCommand from './DeckCommand';
var UpdateDeckReferenceCommand = /** @class */ (function (_super) {
    __extends(UpdateDeckReferenceCommand, _super);
    function UpdateDeckReferenceCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UpdateDeckReferenceCommand.prototype.prepare = function () {
        this.addGuards(IsDeckVOProxyExistGuard);
    };
    UpdateDeckReferenceCommand.prototype.execute = function () {
        var stateVOProxy = this.facade.retrieveProxy(StateVOProxy.NAME);
        this.proxy.setVO(stateVOProxy.vo.deckData);
    };
    return UpdateDeckReferenceCommand;
}(DeckCommand));
export default UpdateDeckReferenceCommand;
