var SocketVO = /** @class */ (function () {
    function SocketVO() {
        this.actionIndex = 0;
        this.archivedData = {};
    }
    return SocketVO;
}());
export default SocketVO;
export var CardPlayType;
(function (CardPlayType) {
    CardPlayType[CardPlayType["ATTACK"] = 1] = "ATTACK";
    CardPlayType[CardPlayType["DEFENCE"] = 2] = "DEFENCE";
    CardPlayType[CardPlayType["SWAP"] = 3] = "SWAP";
    CardPlayType[CardPlayType["TAKE"] = 4] = "TAKE";
    CardPlayType[CardPlayType["PASS"] = 5] = "PASS";
})(CardPlayType || (CardPlayType = {}));
export var RoundEndCase;
(function (RoundEndCase) {
    RoundEndCase["COLLECT"] = "collect";
    RoundEndCase["BITA"] = "bita";
})(RoundEndCase || (RoundEndCase = {}));
export var AllowPlayMessage;
(function (AllowPlayMessage) {
    AllowPlayMessage[AllowPlayMessage["SERVER_BUSY"] = -2] = "SERVER_BUSY";
    AllowPlayMessage[AllowPlayMessage["ROOM_NOT_ACTIVE"] = -1] = "ROOM_NOT_ACTIVE";
    AllowPlayMessage[AllowPlayMessage["NOT_ENOUGH_SILVER"] = 0] = "NOT_ENOUGH_SILVER";
    AllowPlayMessage[AllowPlayMessage["NOT_ENOUGH_GOLD"] = 1] = "NOT_ENOUGH_GOLD";
    AllowPlayMessage[AllowPlayMessage["NOT_ENOUGH_CRYSTAL"] = 2] = "NOT_ENOUGH_CRYSTAL";
})(AllowPlayMessage || (AllowPlayMessage = {}));
export var ValidationActionType;
(function (ValidationActionType) {
    ValidationActionType[ValidationActionType["DISABLED"] = 0] = "DISABLED";
    ValidationActionType[ValidationActionType["TAKE"] = 4] = "TAKE";
    ValidationActionType[ValidationActionType["PASS"] = 5] = "PASS";
})(ValidationActionType || (ValidationActionType = {}));
