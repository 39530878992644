import LobbyScene from '../../view/scenes/LobbyScene';
var UiStateVO = /** @class */ (function () {
    function UiStateVO() {
        this.previousScene = null;
        this.currentScene = null;
        this.navigationScene = LobbyScene.NAME;
    }
    return UiStateVO;
}());
export default UiStateVO;
