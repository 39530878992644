var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Atlases, Fonts } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var TopBarLevel = /** @class */ (function (_super) {
    __extends(TopBarLevel, _super);
    function TopBarLevel(scene) {
        var _this = _super.call(this, scene) || this;
        _this.createBody();
        return _this;
    }
    TopBarLevel.prototype.updateValue = function (level) {
        this.text.setText("" + level);
        this.text.setScale(Math.min(this.width / this.text.width, 1));
    };
    TopBarLevel.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createText();
    };
    TopBarLevel.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Topbar.Atlas.Name,
            frame: Atlases.Topbar.Atlas.Frames.LevelNumber,
        });
        this.add(this.background);
    };
    TopBarLevel.prototype.createText = function () {
        var style = {
            fontFamily: Fonts.ArialBlack.Name,
            fontSize: 28 * gameConfig.resolutionMultiplier,
            fill: '#341b0b',
        };
        this.text = this.scene.make.text({
            x: 0.5,
            y: 1,
            text: '0',
            style: style,
        });
        this.text.setOrigin(0.5);
        this.text.setStroke('#845624', 1);
        this.add(this.text);
    };
    return TopBarLevel;
}(Phaser.GameObjects.Container));
export default TopBarLevel;
