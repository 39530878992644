var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { postRunnable } from '../phaser/PhaserUtils';
var VerticalScrollerContainer = /** @class */ (function (_super) {
    __extends(VerticalScrollerContainer, _super);
    function VerticalScrollerContainer(scene, width, height, startX, startY, endY) {
        if (startX === void 0) { startX = 0; }
        if (startY === void 0) { startY = 0; }
        if (endY === void 0) { endY = 0; }
        var _this = _super.call(this, scene) || this;
        _this.startX = startX;
        _this.startY = startY;
        _this.endY = endY;
        _this.setSize(width, height);
        _this.scrollableList = [];
        _this.lastScrolledDistance = 0;
        _this.createMask();
        _this.createZone();
        _this.setListeners();
        return _this;
    }
    VerticalScrollerContainer.prototype.setVisible = function (visible) {
        this.limitMovement(true);
        return _super.prototype.setVisible.call(this, visible);
    };
    VerticalScrollerContainer.prototype.setPosition = function (x, y, z, w) {
        _super.prototype.setPosition.call(this, x, y, z, w);
        if (this.maskGraphics) {
            this.updateMask();
        }
        return this;
    };
    VerticalScrollerContainer.prototype.addScrollAble = function (object, toEnd) {
        if (toEnd === void 0) { toEnd = false; }
        toEnd ? this.add(object) : this.addAt(object, 0);
        toEnd
            ? this.scrollableList.push(object)
            : this.scrollableList.unshift(object);
        this.repositionChildren();
        return this;
    };
    VerticalScrollerContainer.prototype.removeScrollable = function (object) {
        this.remove(object);
        var index = this.scrollableList.indexOf(object);
        if (index !== -1) {
            this.scrollableList.splice(index, 1);
        }
        this.repositionChildren();
        return this;
    };
    VerticalScrollerContainer.prototype.scrollToIndex = function (index) {
        var _this = this;
        var target = this.scrollableList[index];
        this.scene.tweens.add({
            targets: this,
            lastAppliedY: target.y,
            onUpdate: function () {
                _this.applyScroll(_this.lastAppliedY);
            },
        });
    };
    VerticalScrollerContainer.prototype.scrollToElement = function (element) {
        var _this = this;
        if (!this.scrollableList.contains(element)) {
            return;
        }
        this.scene.tweens.add({
            targets: this,
            lastAppliedY: element.y,
            onUpdate: function () {
                _this.applyScroll(_this.lastAppliedY);
            },
        });
    };
    VerticalScrollerContainer.prototype.setMaskObject = function (object) {
        this.maskGraphics = object;
    };
    VerticalScrollerContainer.prototype.onScrollUpdate = function () {
        //
    };
    VerticalScrollerContainer.prototype.dragStart = function (pointer) {
        this.scene.input.setTopOnly(true);
        this.lastAppliedY = pointer.y;
        this.dragData = {
            start: pointer.y,
            end: null,
            startMoment: Date.now(),
            endMoment: null,
            direction: null,
        };
        this.positionFixTween &&
            this.positionFixTween.isPlaying &&
            this.positionFixTween.stop();
    };
    VerticalScrollerContainer.prototype.drag = function (pointer) {
        var diffY = pointer.y - this.lastAppliedY;
        var currentDirection = this.calculateDirection(pointer);
        if (this.dragData.direction !== currentDirection) {
            this.dragData.start = pointer.y;
            this.dragData.direction = currentDirection;
            this.dragData.startMoment = Date.now();
        }
        this.lastAppliedY = pointer.y;
        this.applyScroll(diffY * this.scrollStrength);
        this.onScrollUpdate();
    };
    VerticalScrollerContainer.prototype.dragEnd = function (pointer) {
        postRunnable(function () {
            // this.scene.input.setTopOnly(false);
        });
        this.dragData.endMoment = Date.now();
        this.dragData.end = pointer.y;
        if (this.limitMovement()) {
            return;
        }
        this.startKineticScroll();
    };
    VerticalScrollerContainer.prototype.repositionChildren = function () {
        this.lastScrolledDistance = this.first ? this.first.y : 0;
        for (var i = 0; i < this.scrollableList.length; i++) {
            var child = this.scrollableList[i];
            var prevChild = this.scrollableList[i - 1];
            child.x = this.startX;
            child.y = prevChild
                ? prevChild.y + prevChild.height
                : this.startY + child.height + this.lastScrolledDistance;
        }
        this.limitMovement(true);
        this.onScrollUpdate();
    };
    VerticalScrollerContainer.prototype.setListeners = function () {
        this.zone.setInteractive();
        this.scene.input.setDraggable(this.zone);
        this.removeListeners();
        this.zone.on(Phaser.Input.Events.GAMEOBJECT_DRAG_START, this.dragStart, this);
        this.zone.on(Phaser.Input.Events.GAMEOBJECT_DRAG, this.drag, this);
        this.zone.on(Phaser.Input.Events.GAMEOBJECT_DRAG_END, this.dragEnd, this);
    };
    VerticalScrollerContainer.prototype.updateMask = function () {
        this.maskGraphics.clear();
        this.maskGraphics.fillRectShape(this.maskShape);
        this.setMask(new Phaser.Display.Masks.GeometryMask(this.scene, this.maskGraphics));
    };
    VerticalScrollerContainer.prototype.createMask = function () {
        this.maskGraphics = this.scene.make.graphics({});
        this.maskGraphics.fillRectShape(this.maskShape);
        this.setMask(new Phaser.Display.Masks.GeometryMask(this.scene, this.maskGraphics));
    };
    VerticalScrollerContainer.prototype.createZone = function () {
        this.zone = this.scene.make.zone({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
        });
        this.zone.setOrigin(0);
        this.add(this.zone);
    };
    VerticalScrollerContainer.prototype.removeListeners = function () {
        this.zone.off('dragstart', this.dragStart, this, false);
        this.zone.off('drag', this.drag, this, false);
        this.zone.off('dragend', this.dragEnd, this, false);
    };
    VerticalScrollerContainer.prototype.applyScroll = function (y) {
        for (var _i = 0, _a = this.scrollableList; _i < _a.length; _i++) {
            var object = _a[_i];
            object.y += y;
        }
    };
    VerticalScrollerContainer.prototype.limitMovement = function (force) {
        if (force === void 0) { force = false; }
        if (!this.scrollableList.length) {
            return false;
        }
        if (this.first.y > 0 || this.sumHeight < this.height) {
            this.autoScrollToStart(force);
            return true;
        }
        if (this.last.y + this.last.height < this.height) {
            this.autoScrollToEnd(force);
            return true;
        }
        return false;
    };
    VerticalScrollerContainer.prototype.autoScrollToStart = function (force) {
        var _this = this;
        var targets = {
            y: this.first.y || 0,
            lastY: this.first.y || 0,
        };
        this.positionFixTween && this.positionFixTween.stop();
        this.positionFixTween = this.scene.tweens.add({
            targets: targets,
            y: this.startY,
            onUpdate: function () {
                var diffY = targets.y - targets.lastY;
                targets.lastY = targets.y;
                _this.applyScroll(diffY);
                _this.onScrollUpdate();
            },
            duration: force ? 1 : 200,
        });
    };
    VerticalScrollerContainer.prototype.autoScrollToEnd = function (force) {
        var _this = this;
        if (!this.autoScrollToEndEnabled) {
            return;
        }
        var targets = {
            y: this.last.y,
            lastY: this.last.y,
        };
        this.positionFixTween && this.positionFixTween.stop();
        this.positionFixTween = this.scene.tweens.add({
            targets: targets,
            y: this.height - this.endY - this.last.height,
            onUpdate: function () {
                var diffY = targets.y - targets.lastY;
                targets.lastY = targets.y;
                _this.applyScroll(diffY);
                _this.onScrollUpdate();
            },
            duration: force ? 1 : 200,
        });
    };
    VerticalScrollerContainer.prototype.autoScrollToEndEnabled = function () {
        return this.scrollableList.length * this.first.height >= this.height;
    };
    VerticalScrollerContainer.prototype.calculateDirection = function (pointer) {
        return pointer.y - this.dragData.start >= 0 ? 1 : -1;
    };
    VerticalScrollerContainer.prototype.startKineticScroll = function () {
        var _this = this;
        this.scene.tweens.killTweensOf(this);
        var dy = this.dragData.end - this.dragData.start || 0;
        var dt = this.dragData.endMoment - this.dragData.startMoment || 1;
        this.speed = (2 * dy) / Math.sqrt(dt);
        this.scene.tweens.add({
            targets: this,
            speed: 0,
            duration: Math.abs(dy),
            onUpdate: function () {
                _this.speed && _this.applyScroll(_this.speed);
                if (_this.limitMovement()) {
                    _this.scene.tweens.killTweensOf(_this);
                }
                _this.onScrollUpdate();
            },
        });
    };
    Object.defineProperty(VerticalScrollerContainer.prototype, "sumHeight", {
        get: function () {
            var height = 0;
            this.scrollableList.forEach(function (element) {
                height += element.height;
            });
            return height;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VerticalScrollerContainer.prototype, "first", {
        get: function () {
            return this.scrollableList[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VerticalScrollerContainer.prototype, "last", {
        get: function () {
            return this.scrollableList[this.scrollableList.length - 1];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VerticalScrollerContainer.prototype, "lastY", {
        get: function () {
            return this.last ? this.last.y + this.last.height : this.startY;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VerticalScrollerContainer.prototype, "maskShape", {
        get: function () {
            return new Phaser.Geom.Rectangle(this.x, this.y, this.width, this.height);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VerticalScrollerContainer.prototype, "scrollStrength", {
        get: function () {
            if (this.first && this.first.y > 0) {
                return 0.5 - this.first.y / this.height;
            }
            else if (this.last && this.last.y + this.last.height < this.height) {
                return 1 - (this.height - this.last.y + this.last.height) / this.height;
            }
            return 1;
        },
        enumerable: true,
        configurable: true
    });
    return VerticalScrollerContainer;
}(Phaser.GameObjects.Container));
export { VerticalScrollerContainer };
