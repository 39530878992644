var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gameConfig } from '../../../../constants/GameConfig';
var NavigationSectionState = /** @class */ (function (_super) {
    __extends(NavigationSectionState, _super);
    function NavigationSectionState(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        _this.prepare();
        return _this;
    }
    NavigationSectionState.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.config.button && this.createButton();
        this.config.text && this.createText();
    };
    NavigationSectionState.prototype.createBackground = function () {
        this.background = this.scene.make.image(__assign({ x: 0, y: 0 }, this.config.background));
        this.add(this.background);
    };
    NavigationSectionState.prototype.createButton = function () {
        this.buttonSprite = this.scene.make.image(__assign({ x: 0, y: 0 }, this.config.button));
        this.add(this.buttonSprite);
    };
    NavigationSectionState.prototype.createText = function () {
        var style = {
            fontFamily: this.config.text.fontFamily,
            fontSize: this.config.text.fontSize * gameConfig.resolutionMultiplier,
            fill: this.config.text.fill,
        };
        this.text = this.scene.make.extText({
            x: 0,
            y: 0,
            text: this.config.text.text || '',
            style: style,
        });
        this.text.setOrigin(0.5);
        this.add(this.text);
        this.config.text.stroke && this.text.setStroke(this.config.text.stroke, 4);
    };
    NavigationSectionState.prototype.prepare = function () { };
    return NavigationSectionState;
}(Phaser.GameObjects
    .Container));
export default NavigationSectionState;
