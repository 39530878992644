var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ApiVOProxy from '../../model/ApiVOProxy';
import SocketVOProxy from '../../model/SocketVOProxy';
import BaseStoreVOProxy from '../../model/store/BaseStoreVOProxy';
import StoreScene from '../../view/scenes/StoreScene';
import AddProductToRecallListCommand from './AddProductToRecallListCommand';
import BuyProductCommand from './BuyProductCommand';
import ConsumeProductCommand from './ConsumeProductCommand';
import RegisterStoreVOProxyCommand from './RegisterStoreVOProxyCommand';
import StoreCommand from './StoreCommand';
import UpdateStoreProductsCommand from './UpdateStoreProductsCommand';
import VerifyUnConsumedProductsCommand from './VerifyUnConsumedProductsCommand';
var RemoveStoreCommand = /** @class */ (function (_super) {
    __extends(RemoveStoreCommand, _super);
    function RemoveStoreCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RemoveStoreCommand.prototype.execute = function () {
        this.facade.removeProxy(BaseStoreVOProxy.NAME);
        this.facade.removeCommand(ApiVOProxy.STORE_DATA_READY_NOTIFICATION, UpdateStoreProductsCommand);
        this.facade.removeCommand(StoreScene.STORE_ITEM_CLICK_NOTIFICATION, BuyProductCommand);
        this.facade.removeCommand(ApiVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, RegisterStoreVOProxyCommand);
        this.facade.removeCommand(ApiVOProxy.PRODUCT_BUY_SUCCEED_NOTIFICATION, ConsumeProductCommand);
        this.facade.removeCommand(ApiVOProxy.PRODUCT_BUY_FAILED_NOTIFICATION, AddProductToRecallListCommand);
        this.facade.removeCommand(SocketVOProxy.RECONNECTED_NOTIFICATION, VerifyUnConsumedProductsCommand);
        this.facade.removeCommand(BaseStoreVOProxy.CHECK_NOT_COMPLETE_PRODUCTS_NOTIFICATION, VerifyUnConsumedProductsCommand);
        this.facade.removeCommand(BaseStoreVOProxy.INITIALIZE_FAILED_NOTIFICATION, RemoveStoreCommand);
    };
    return RemoveStoreCommand;
}(StoreCommand));
export default RemoveStoreCommand;
