import 'phaser';
import { defaultSettings } from './constants/Constants';
import { gameConfig } from './constants/GameConfig';
import Game from './Game';
import { disableConsoleFunctions, disableInspectElement, getMode, getResolution, getZone, isCordova, isOk, isVk, setZone, } from './utils/Utils';
import { loadCordova } from './utils/wrappers/CordovaWrapper';
import { loadOk } from './utils/wrappers/OkInstantWrapper';
import { getDataFromStorage, setDataToStorage, StorageKey, } from './utils/wrappers/StorageWrapper';
import { loadVk } from './utils/wrappers/VkInstantWrapper';
function detectZone() {
    if (getZone() == 'cordova') {
        return;
    }
    console.warn(window.location.href);
    switch (true) {
        case window.location.href.indexOf('zone=ok') !== -1:
            {
                setZone('ok');
                var settings = getDataFromStorage(StorageKey.SETTINGS, defaultSettings);
                settings.language = 'ru';
                setDataToStorage(StorageKey.SETTINGS, settings);
            }
            break;
        case window.location.href.indexOf('zone=vk') !== -1:
            {
                setZone('vk');
                var settings = getDataFromStorage(StorageKey.SETTINGS, defaultSettings);
                settings.language = 'ru';
                setDataToStorage(StorageKey.SETTINGS, settings);
            }
            break;
        default:
            setZone('web');
            break;
    }
}
function handleResolution() {
    var resolution = getResolution();
    switch (resolution) {
        case 'high':
            gameConfig.resolutionMultiplier = 1;
            break;
        case 'mid':
            gameConfig.resolutionMultiplier = 0.75;
            break;
        case 'low':
            gameConfig.resolutionMultiplier = 0.35;
            break;
        default:
            break;
    }
    gameConfig.designWidth = gameConfig.canvasWidth *=
        gameConfig.resolutionMultiplier;
    gameConfig.designHeight = gameConfig.canvasHeight *=
        gameConfig.resolutionMultiplier;
}
function startApp() {
    handleResolution();
    detectZone();
    setTimeout(function () {
        switch (true) {
            case isCordova():
                loadCordova();
                break;
            case isOk():
                loadOk();
                break;
            case isVk():
                loadVk();
                break;
            case getZone() === 'web':
                new Game();
                break;
            default:
                break;
        }
    }, 100);
}
if (getMode() === 'production') {
    disableConsoleFunctions();
    disableInspectElement();
}
document.addEventListener('DOMContentLoaded', startApp, false);
