var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
var textColors = ['#fbd307', '#aeaeae', '#eda649', '#eda649'];
var strokeColors = ['#dca12c', '#838383', '#c4893c', '#c4893c'];
var shadowColors = ['#ba7c00', '#363636', '#b47c36', '#b47c36'];
var GameEndPopupMedal = /** @class */ (function (_super) {
    __extends(GameEndPopupMedal, _super);
    function GameEndPopupMedal(scene, place, isWinner) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.place = place;
        _this.isWinner = isWinner;
        _this.createBody();
        _this.prepare();
        return _this;
    }
    GameEndPopupMedal.prototype.show = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.scene.tweens.add({
                            targets: [_this.medal, _this.frame],
                            scaleX: 1,
                            scaleY: 1,
                            ease: Phaser.Math.Easing.Back.Out,
                            duration: 300,
                        });
                    })];
            });
        });
    };
    GameEndPopupMedal.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        //
                    })];
            });
        });
    };
    GameEndPopupMedal.prototype.createBody = function () {
        this.createMedal();
        this.setSize(this.medal.width, this.medal.height);
        this.createFrame();
        this.createPositionText();
        this.isWinner && this.place === 1 && this.createCrown();
    };
    GameEndPopupMedal.prototype.createMedal = function () {
        this.medal = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames["GameEndPopupMedal" + (this.place - 1)],
        });
        this.add(this.medal);
    };
    GameEndPopupMedal.prototype.createFrame = function () {
        this.frame = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: this.isWinner && this.place === 1
                ? MultiAtlases.Popups.Atlas.Frames.GameEndPopupFrameWin
                : MultiAtlases.Popups.Atlas.Frames.GameEndPopupFrameLose,
        });
        this.add(this.frame);
        this.frame.setVisible((this.isWinner && this.place === 1) || !this.isWinner);
    };
    GameEndPopupMedal.prototype.createPositionText = function () {
        var style = {
            fontFamily: Fonts.ArialBlack.Name,
            fontSize: 170 * gameConfig.resolutionMultiplier,
            fill: textColors[this.place - 1],
        };
        this.positionText = this.scene.make.text({
            x: 0,
            y: 0,
            text: "" + this.place,
            style: style,
        });
        this.positionText.setOrigin(0.5);
        this.positionText.setShadow(-10, 0, shadowColors[this.place - 1], 5);
        this.positionText.setStroke(strokeColors[this.place - 1], 10);
        this.add(this.positionText);
    };
    GameEndPopupMedal.prototype.createCrown = function () {
        this.crown = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.GameEndPopupCrown,
        });
        this.add(this.crown);
    };
    GameEndPopupMedal.prototype.prepare = function () {
        this.medal.setScale(0);
        this.frame.setScale(0);
        !!this.crown && this.crown.setAlpha(0);
    };
    return GameEndPopupMedal;
}(Phaser.GameObjects.Container));
export default GameEndPopupMedal;
