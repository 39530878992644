var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mediator } from '@candywings/pure-mvc';
import { gameConfig } from '../../../constants/GameConfig';
import CardPlayedCommand from '../../../controller/socket/card-play/CardPlayedCommand';
import CardPlayFailedCommand from '../../../controller/socket/card-play/CardPlayFailedCommand';
import PlayerVOProxy from '../../../model/PlayerVOProxy';
import SocketVOProxy from '../../../model/SocketVOProxy';
import StateVOProxy from '../../../model/StateVOProxy';
import { CardPlayType, } from '../../../model/VO/SocketVO';
import { Translation } from '../../../translations';
import GameScene from '../../scenes/GameScene';
import { getScene, hasKey } from '../../utils/phaser/PhaserUtils';
import DeckView from './DeckView';
import { GameTimerType } from './GameTimer';
import PlayerAvatarView from './PlayerAvatarView';
import PlayerView from './PlayerView';
import WaitingView from './WaitingView';
import { Images } from '../../../assets';
import { isNativeAvatar, getNativeAvatarKey, fetchAvatarByConfig } from '../../../utils/Utils';
var PlayerAvatarViewMediator = /** @class */ (function (_super) {
    __extends(PlayerAvatarViewMediator, _super);
    function PlayerAvatarViewMediator() {
        return _super.call(this, PlayerAvatarViewMediator.NAME, null) || this;
    }
    PlayerAvatarViewMediator.prototype.onRemove = function () {
        this.viewComponent && this.viewComponent.destroy();
        _super.prototype.onRemove.call(this);
    };
    PlayerAvatarViewMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(GameScene.CREATE_NOTIFICATION, GameScene.RECREATE_NOTIFICATION, WaitingView.HIDDEN_NOTIFICATION, DeckView.DEAL_STARTED_NOTIFICATION, DeckView.DECK_PLACED_NOTIFICATION, SocketVOProxy.TURN_PASSED_NOTIFICATION, SocketVOProxy.PLAYER_TAKES_NOTIFICATION, StateVOProxy.CARD_PLAYED_NOTIFICATION, StateVOProxy.VALIDATE_CARDS_NOTIFICATION, CardPlayedCommand.FAILED_NOTIFICATION, PlayerView.CARD_PLAY_NOTIFICATION, PlayerView.BUTTON_ACTION_DONE_NOTIFICATION, SocketVOProxy.EMOJI_PUBLISHED_NOTIFICATION, StateVOProxy.PLAYER_COMPLETE_GAME_NOTIFICATION, StateVOProxy.NEXT_PLAYER_NOTIFICATION, GameScene.FORCE_START_NOTIFICATION);
    };
    PlayerAvatarViewMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var playedCardData, userIndex, userIndex, data;
            return __generator(this, function (_a) {
                switch (notificationName) {
                    case GameScene.FORCE_START_NOTIFICATION: {
                        this.onNextUser();
                        this.onValidate();
                        break;
                    }
                    case PlayerView.BUTTON_ACTION_DONE_NOTIFICATION:
                    case PlayerView.CARD_PLAY_NOTIFICATION:
                        this.viewComponent.pauseTimer();
                        break;
                    case CardPlayedCommand.FAILED_NOTIFICATION:
                    case CardPlayFailedCommand.FAILED_NOTIFICATION:
                        this.viewComponent.resumeTimer();
                        break;
                    case GameScene.CREATE_NOTIFICATION:
                    case GameScene.RECREATE_NOTIFICATION:
                        this.setView();
                        this.viewComponent.showTooltip(1000, Translation.TOOLTIP_DRAG_ME);
                        break;
                    case WaitingView.HIDDEN_NOTIFICATION:
                        if (!this.viewComponent) {
                            break;
                        }
                        this.viewComponent.hideTooltip();
                        break;
                    case StateVOProxy.CARD_PLAYED_NOTIFICATION:
                        playedCardData = args[0];
                        this.viewComponent.stopTimer();
                        playedCardData.type === CardPlayType.SWAP &&
                            this.viewComponent.showTooltip(1000, Translation.TOOLTIP_SWAP);
                        break;
                    case SocketVOProxy.PLAYER_TAKES_NOTIFICATION:
                        {
                            userIndex = args[0];
                            if (userIndex === 3) {
                                this.viewComponent.showTooltip(1000, Translation.TOOLTIP_TAKE);
                            }
                        }
                        this.viewComponent.stopTimer();
                        break;
                    case SocketVOProxy.TURN_PASSED_NOTIFICATION:
                        {
                            userIndex = args[0];
                            if (userIndex === 3) {
                                this.viewComponent.showTooltip(1000, Translation.TOOLTIP_PASS);
                            }
                        }
                        this.viewComponent.stopTimer();
                        break;
                    case StateVOProxy.VALIDATE_CARDS_NOTIFICATION:
                        this.onValidate();
                        if (this.stateVOProxy.vo.turnData.cardPairs.length === 0) {
                            this.viewComponent.showTooltip(1500, Translation.TOOLTIP_TURN);
                        }
                        break;
                    case SocketVOProxy.ROUND_ENDED_NOTIFICATION:
                    case DeckView.DEAL_STARTED_NOTIFICATION:
                    case StateVOProxy.PLAYER_COMPLETE_GAME_NOTIFICATION:
                        this.viewComponent.stopTimer();
                        break;
                    case SocketVOProxy.EMOJI_PUBLISHED_NOTIFICATION:
                        data = args[0];
                        if (data.userId !== this.stateVOProxy.vo.players.length - 1) {
                            break;
                        }
                        this.viewComponent.showEmoji(data.type, data.key);
                        break;
                    case DeckView.DECK_PLACED_NOTIFICATION:
                        this.unsubscribeToNotification(DeckView.DECK_PLACED_NOTIFICATION);
                        this.onNextUser();
                        this.onValidate();
                        break;
                    case StateVOProxy.NEXT_PLAYER_NOTIFICATION:
                        if (this.stateVOProxy.vo.turnData.cardPairs.length === 0) {
                            break;
                        }
                        this.onNextUser();
                        break;
                    default:
                        console.warn(notificationName + " is unhandled!");
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    PlayerAvatarViewMediator.prototype.setView = function () {
        var _this = this;
        var name = this.playerVOProxy.vo.name;
        var key = Images.Avatar.Name;
        var shouldUpdateAvatar = false;
        if (isNativeAvatar(this.playerVOProxy.vo.avatarUrl)) {
            key = getNativeAvatarKey(this.playerVOProxy.vo.avatarUrl);
        }
        else if (hasKey("playerDefaultAvatar")) {
            key = 'playerDefaultAvatar';
        }
        else {
            shouldUpdateAvatar = true;
        }
        var view = new PlayerAvatarView(this.scene, {
            key: key,
            level: this.playerVOProxy.vo.playerLevel.level,
            name: name.indexOf(' ') !== -1 ? name.substr(0, name.indexOf(' ')) : name,
            isNative: isNativeAvatar(this.playerVOProxy.vo.avatarUrl) || !this.playerVOProxy.vo.avatarUrl,
        });
        this.scene.add.existing(view);
        this.setViewComponent(view);
        this.setViewComponentListeners();
        this.viewComponent.show();
        if (shouldUpdateAvatar) {
            fetchAvatarByConfig(this.scene, {
                userId: this.playerVOProxy.vo.userId,
                avatarURL: this.playerVOProxy.vo.avatarUrl,
            }, "playerDefaultAvatar").then(function () {
                _this.viewComponent.updateAvatar("playerDefaultAvatar");
            });
        }
    };
    PlayerAvatarViewMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.on(PlayerAvatarView.TIMER_COMPLETE_EVENT, this.onTimerComplete, this);
        this.viewComponent.on(PlayerAvatarView.AVATAR_CLICKED_EVENT, this.onAvatarClick, this);
        this.viewComponent.on(PlayerAvatarView.POSITION_CHANGED_EVENT, this.onPositionChange, this);
    };
    PlayerAvatarViewMediator.prototype.onNextUser = function () {
        console.warn(this.stateVOProxy.vo.players[3].isDefender);
        if (this.stateVOProxy.vo.turnData.currentPlayer.userId == this.stateVOProxy.vo.players.length - 1) {
            this.viewComponent.stopTimer();
        }
        if (!this.stateVOProxy.vo.players[3].isDefender ||
            this.stateVOProxy.vo.turnData.currentPlayer.userId ===
                this.stateVOProxy.vo.players.length - 1) {
            this.viewComponent.hideTimerHighLight();
        }
        else if (this.stateVOProxy.vo.players[3].isDefender) {
            this.viewComponent.setTimerMode(GameTimerType.DEFENCE);
            this.viewComponent.showTimerHighLight();
        }
        this.viewComponent.setPlayTurnSfx(this.stateVOProxy.vo.turnData.currentPlayer.userId == this.stateVOProxy.vo.players.length - 1);
    };
    PlayerAvatarViewMediator.prototype.onValidate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var nextUser, playerData;
            return __generator(this, function (_a) {
                nextUser = this.stateVOProxy.vo.turnData.currentPlayer;
                playerData = this.stateVOProxy.vo.players[3];
                // this.viewComponent.stopTimer();
                if (nextUser.userId === this.stateVOProxy.vo.players.length - 1) {
                    this.viewComponent.setTimerMode(playerData.isAttacker ? GameTimerType.ATTACK : GameTimerType.DEFENCE);
                    this.viewComponent.setTimerDuration(nextUser.timeout);
                    this.viewComponent.startTimer();
                }
                return [2 /*return*/];
            });
        });
    };
    PlayerAvatarViewMediator.prototype.onTimerComplete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    PlayerAvatarViewMediator.prototype.onAvatarClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.sendNotification(PlayerAvatarView.AVATAR_CLICKED_NOTIFICATION);
                return [2 /*return*/];
            });
        });
    };
    PlayerAvatarViewMediator.prototype.onPositionChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.sendNotification(PlayerAvatarView.POSITION_CHANGED_NOTIFICATION, this.viewComponent.x / gameConfig.canvasWidth, this.viewComponent.y / gameConfig.canvasHeight);
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(PlayerAvatarViewMediator.prototype, "playerVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(PlayerVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerAvatarViewMediator.prototype, "stateVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(StateVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerAvatarViewMediator.prototype, "scene", {
        get: function () {
            return getScene(GameScene.NAME);
        },
        enumerable: true,
        configurable: true
    });
    PlayerAvatarViewMediator.NAME = 'PlayerAvatarViewMediator';
    return PlayerAvatarViewMediator;
}(Mediator));
export default PlayerAvatarViewMediator;
