var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../../assets';
import BaseUserInfoStatisticElement from './BaseUserInfoStatisticElement';
var UserInfoStatisticDiagramElement = /** @class */ (function (_super) {
    __extends(UserInfoStatisticDiagramElement, _super);
    function UserInfoStatisticDiagramElement(scene, config) {
        var _this = _super.call(this, scene, config) || this;
        _this.scene = scene;
        _this.config = config;
        return _this;
    }
    UserInfoStatisticDiagramElement.prototype.updateMask = function () {
        var rate = this.config.value / this.config.total;
        this.diagramMaskGraphics.clear();
        this.diagramMaskGraphics.fillStyle(0xffffff);
        var transform = this.diagramArc.getWorldTransformMatrix();
        var startAngle = -90;
        this.diagramMaskGraphics.slice(transform.tx, transform.ty, this.diagramArc.width / 2, Phaser.Math.DegToRad(startAngle), Phaser.Math.DegToRad(startAngle + rate * 360), false);
        this.diagramMaskGraphics.fillPath();
    };
    UserInfoStatisticDiagramElement.prototype.createContent = function () {
        this.createDiagram();
        _super.prototype.createContent.call(this);
        this.setSize(this.nameBackground.width, this.valueBackground.height);
    };
    UserInfoStatisticDiagramElement.prototype.createDiagram = function () {
        this.createDiagramBackground();
        this.createDiagramArc();
        this.createDiagramMaskGraphics();
    };
    UserInfoStatisticDiagramElement.prototype.positionNameBackground = function () {
        this.nameBackground.y =
            -this.diagramBackground.height * 0.5 - this.nameBackground.height * 0.8;
    };
    UserInfoStatisticDiagramElement.prototype.positionValueBackground = function () {
        this.valueBackground.x = this.diagramBackground.width * 0.35;
        this.valueBackground.y = this.diagramBackground.height * 0.35;
    };
    UserInfoStatisticDiagramElement.prototype.createDiagramBackground = function () {
        this.diagramBackground = this.scene.make.image({
            x: -this.width * 0.2,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupWinBackground,
        });
        this.add(this.diagramBackground);
    };
    UserInfoStatisticDiagramElement.prototype.createDiagramArc = function () {
        this.diagramArc = this.scene.make.image({
            x: this.diagramBackground.x,
            y: this.diagramBackground.y,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupWinsArc,
        });
        this.add(this.diagramArc);
    };
    UserInfoStatisticDiagramElement.prototype.createDiagramMaskGraphics = function () {
        this.diagramMaskGraphics = this.scene.make.graphics({});
        this.diagramArc.setMask(new Phaser.Display.Masks.GeometryMask(this.scene, this.diagramMaskGraphics));
    };
    return UserInfoStatisticDiagramElement;
}(BaseUserInfoStatisticElement));
export default UserInfoStatisticDiagramElement;
