var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
var StoreItemDiscount = /** @class */ (function (_super) {
    __extends(StoreItemDiscount, _super);
    function StoreItemDiscount(scene, itemsCount, x, y) {
        var _this = _super.call(this, scene, x, y) || this;
        _this.scene = scene;
        _this.itemsCount = itemsCount;
        _this.createBody();
        return _this;
    }
    StoreItemDiscount.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createText();
        this.createBrushes();
    };
    StoreItemDiscount.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames.Discount,
        });
        this.add(this.background);
    };
    StoreItemDiscount.prototype.createText = function () {
        this.text = this.scene.make.bitmapText({
            x: 0,
            y: 0,
            text: "" + this.itemsCount,
            font: BitmapFonts.RobotoSlab.Name,
            size: 70 * gameConfig.resolutionMultiplier,
        });
        this.text.setTint(0xa80303);
        this.text.setCenterAlign();
        this.text.setOrigin(0.5, 0.5);
        this.text.setAngle(-6);
        this.add(this.text);
    };
    StoreItemDiscount.prototype.createBrushes = function () {
        this.brushes = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames.Brush,
        });
        this.add(this.brushes);
    };
    return StoreItemDiscount;
}(Phaser.GameObjects.Container));
export default StoreItemDiscount;
