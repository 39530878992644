var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import GameIsNotEndedGuard from '../../Guards/socket/GameIsNotEndedGuard';
import IsCurrentUserTurnGuard from '../../Guards/socket/IsCurrentUserTurnGuard';
import SocketCommand from '../SocketCommand';
import DeckVOProxy from '../../../model/DeckVOProxy';
var AskForValidCardsCommand = /** @class */ (function (_super) {
    __extends(AskForValidCardsCommand, _super);
    function AskForValidCardsCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AskForValidCardsCommand.prototype.prepare = function () {
        this.addGuards(IsCurrentUserTurnGuard, GameIsNotEndedGuard);
    };
    AskForValidCardsCommand.prototype.execute = function (notificationName) {
        var deckVoProxy = this.facade.retrieveProxy(DeckVOProxy.NAME);
        var isActiveDeal = deckVoProxy.vo.cardsToDealToPlayers.find(function (cardsToDealToPlayers) {
            return cardsToDealToPlayers.length;
        });
        if (!isActiveDeal) {
            this.proxy.publishAskForValidCards();
        }
    };
    AskForValidCardsCommand.prototype.onAllGuardsDenied = function () {
        this.proxy.publishNextNotification();
    };
    return AskForValidCardsCommand;
}(SocketCommand));
export default AskForValidCardsCommand;
