var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, Fonts, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { Translation } from '../../translations';
import PopupTitle from '../components/popup/PopupTitle';
import SimpleButtonWithBitmapText from '../utils/simpleButton/SimpleButtonWithBitmapText';
import { SpriteButton } from '../utils/simpleButton/SpriteButton';
import StandardPopup from './StandardPopup';
export var InsufficientFundsPopupAction;
(function (InsufficientFundsPopupAction) {
    InsufficientFundsPopupAction[InsufficientFundsPopupAction["EARN"] = 0] = "EARN";
    InsufficientFundsPopupAction[InsufficientFundsPopupAction["BUY"] = 1] = "BUY";
})(InsufficientFundsPopupAction || (InsufficientFundsPopupAction = {}));
var InsufficientFundsPopup = /** @class */ (function (_super) {
    __extends(InsufficientFundsPopup, _super);
    function InsufficientFundsPopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InsufficientFundsPopup.prototype.prepareToShow = function (x, y, currency) {
        var args = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            args[_i - 3] = arguments[_i];
        }
        this.updateWallet(currency);
        _super.prototype.prepareToShow.apply(this, [x, y].concat(args));
        this.setListeners(currency);
    };
    InsufficientFundsPopup.prototype.createBody = function () {
        this.createColoredBlocker(0.7);
        this.createBackgroundEffect();
        this.createBgImage(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.InsufficientFundsPopupBackground);
        this.createTitle();
        this.createWallet();
        this.createButtons();
    };
    InsufficientFundsPopup.prototype.createBackgroundEffect = function () {
        this.backgroundEffect = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.InsufficientFundsPopupBlocker,
        });
        this.add(this.backgroundEffect);
    };
    InsufficientFundsPopup.prototype.createTitle = function () {
        var config = {
            frame: MultiAtlases.Popups.Atlas.Frames.InsufficientFundsPopupTitle,
            fontFamily: Fonts.RobotoSlab.Name,
            text: Translation.INSUFFICIENT_FUNDS_POPUP_TITLE,
            fontSize: 50,
            fill: '#c3a469',
            origin: { x: 0.5, y: 0.6 },
        };
        this.title = new PopupTitle(this.scene, config);
        this.title.y = -this.displayHeight * 0.45;
        this.add(this.title);
    };
    InsufficientFundsPopup.prototype.createButtons = function () {
        this.createEarnButton();
        this.createBuyButton();
        this.createCloseButton();
    };
    InsufficientFundsPopup.prototype.createWallet = function () {
        this.wallet = this.scene.make.image({
            x: 0,
            y: -this.height * 0.08,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.InsufficientFundsPopupWalletGold,
        });
        this.add(this.wallet);
    };
    InsufficientFundsPopup.prototype.createEarnButton = function () {
        var textConfig = {
            text: Translation.INSUFFICIENT_FUNDS_POPUP_EARN_BUTTON,
            size: 45 * gameConfig.resolutionMultiplier,
            fill: 0xffffd8,
            font: BitmapFonts.RobotoSlab.Name,
            align: Phaser.GameObjects.BitmapText.ALIGN_CENTER,
            origin: { x: 0.5, y: 0.6 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SmallNeutralButtonUp,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SmallNeutralButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SmallNeutralButtonDown,
            textConfig: textConfig,
        };
        var config = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        this.earnButton = new SimpleButtonWithBitmapText(this.scene, config);
        this.add(this.earnButton);
        this.earnButton.x = this.width * 0.17;
        this.earnButton.y = this.height * 0.3;
    };
    InsufficientFundsPopup.prototype.createBuyButton = function () {
        var textConfig = {
            text: Translation.INSUFFICIENT_FUNDS_POPUP_BUY_BUTTON,
            size: 45 * gameConfig.resolutionMultiplier,
            fill: 0xffffd8,
            font: BitmapFonts.RobotoSlab.Name,
            align: Phaser.GameObjects.BitmapText.ALIGN_CENTER,
            origin: { x: 0.5, y: 0.6 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SmallPositiveButtonUp,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SmallPositiveButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SmallPositiveButtonDown,
            textConfig: textConfig,
        };
        var config = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        this.buyButton = new SimpleButtonWithBitmapText(this.scene, config);
        this.add(this.buyButton);
        this.buyButton.x = -this.width * 0.17;
        this.buyButton.y = this.height * 0.3;
    };
    InsufficientFundsPopup.prototype.createCloseButton = function () {
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.CloseButtonUp,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.CloseButtonHover,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.CloseButtonDown,
        };
        this.closeButton = new SpriteButton(this.scene, {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        });
        this.closeButton.x = this.width * 0.45;
        this.closeButton.y = -this.height * 0.45;
        this.add(this.closeButton);
        this.closeButton.on(SpriteButton.CLICK_EVENT, this.onAction, this);
    };
    InsufficientFundsPopup.prototype.setListeners = function (currency) {
        this.earnButton.once(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction.bind(this, InsufficientFundsPopupAction.EARN, currency));
        this.buyButton.once(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction.bind(this, InsufficientFundsPopupAction.BUY, currency));
    };
    InsufficientFundsPopup.prototype.updateWallet = function (currency) {
        var frame = MultiAtlases.Popups.Atlas.Frames["InsufficientFundsPopupWallet" + Phaser.Utils.String.UppercaseFirst(currency)];
        frame && this.wallet.setTexture(MultiAtlases.Popups.Atlas.Name, frame);
    };
    InsufficientFundsPopup.NAME = "InsufficientFundsPopup";
    InsufficientFundsPopup.SHOW_START_EVENT = InsufficientFundsPopup.NAME + "ShowStartEvent";
    InsufficientFundsPopup.SHOW_START_NOTIFICATION = InsufficientFundsPopup.NAME + "ShowStartNotification";
    InsufficientFundsPopup.EARN_NOTIFICATION = InsufficientFundsPopup.NAME + "EarnNotification";
    InsufficientFundsPopup.BUY_NOTIFICATION = InsufficientFundsPopup.NAME + "BuyNotification";
    return InsufficientFundsPopup;
}(StandardPopup));
export default InsufficientFundsPopup;
