var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gameConfig } from '../../constants/GameConfig';
import PlayerVOProxy from '../../model/PlayerVOProxy';
import StateVOProxy from '../../model/StateVOProxy';
import TopBarScene from '../scenes/TopBarScene';
import GameEndPopup from './GameEndPopup';
import StandardPopupMediator from './StandardPopupMediator';
var GameEndPopupMediator = /** @class */ (function (_super) {
    __extends(GameEndPopupMediator, _super);
    function GameEndPopupMediator() {
        return _super.call(this, GameEndPopupMediator.NAME) || this;
    }
    GameEndPopupMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(StateVOProxy.PLAYER_COMPLETE_GAME_NOTIFICATION);
    };
    GameEndPopupMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        switch (notificationName) {
            case StateVOProxy.PLAYER_COMPLETE_GAME_NOTIFICATION:
                {
                    var rewards = this.playerVOProxy.vo.gameEndRewards.prize;
                    var place = args[0];
                    var isWinner = args[1];
                    this.showView(gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.52, rewards, place, isWinner);
                }
                break;
            case TopBarScene.CLAIM_ANIMATION_STARTED_NOTIFICATION:
                this.viewComponent && this.viewComponent.startClaimAnimation();
                break;
            case TopBarScene.CLAIM_ANIMATION_ENDED_NOTIFICATION:
                this.unsubscribeFromTopBarNotifications();
                if (this.viewComponent && this.viewComponent.visible) {
                    this.hideView();
                }
                break;
            default:
                break;
        }
    };
    GameEndPopupMediator.prototype.showView = function (x, y) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        this.subscribeToTopBarNotifications();
        _super.prototype.showView.apply(this, [x, y].concat(args));
    };
    GameEndPopupMediator.prototype.createView = function () {
        _super.prototype.createView.call(this, new GameEndPopup());
    };
    GameEndPopupMediator.prototype.onViewShowComplete = function () {
        _super.prototype.onViewShowComplete.call(this);
        this.sendNotification(GameEndPopup.SHOWN_NOTIFICATION);
    };
    GameEndPopupMediator.prototype.onViewHideComplete = function () {
        _super.prototype.onViewHideComplete.call(this);
        this.sendNotification(GameEndPopup.HIDDEN_NOTIFICATION);
    };
    GameEndPopupMediator.prototype.onAction = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var rewardItems;
            return __generator(this, function (_a) {
                rewardItems = this.viewComponent.getRewardItems();
                this.sendNotification(GameEndPopup.CLAIM_REWARD_NOTIFICATION, type, rewardItems);
                return [2 /*return*/];
            });
        });
    };
    GameEndPopupMediator.prototype.subscribeToTopBarNotifications = function () {
        this.subscribeToNotifications(TopBarScene.CLAIM_ANIMATION_STARTED_NOTIFICATION, TopBarScene.CLAIM_ANIMATION_ENDED_NOTIFICATION);
    };
    GameEndPopupMediator.prototype.unsubscribeFromTopBarNotifications = function () {
        this.unsubscribeToNotification(TopBarScene.CLAIM_ANIMATION_STARTED_NOTIFICATION, TopBarScene.CLAIM_ANIMATION_ENDED_NOTIFICATION);
    };
    Object.defineProperty(GameEndPopupMediator.prototype, "playerVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(PlayerVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    GameEndPopupMediator.NAME = 'GameEndPopupMediator';
    return GameEndPopupMediator;
}(StandardPopupMediator));
export default GameEndPopupMediator;
