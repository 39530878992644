var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import PlayerVOProxy from '../../model/PlayerVOProxy';
import PlayerCommand from './PlayerCommand';
var UpdatePlayerGanderCommand = /** @class */ (function (_super) {
    __extends(UpdatePlayerGanderCommand, _super);
    function UpdatePlayerGanderCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UpdatePlayerGanderCommand.prototype.execute = function (notificationName, gander, force) {
        if (force === void 0) { force = false; }
        var playerVoProxy = this.facade.retrieveProxy(PlayerVOProxy.NAME);
        playerVoProxy.updateGander(gander, force);
    };
    return UpdatePlayerGanderCommand;
}(PlayerCommand));
export default UpdatePlayerGanderCommand;
