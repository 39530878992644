var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../../assets';
var BackgroundShine = /** @class */ (function (_super) {
    __extends(BackgroundShine, _super);
    function BackgroundShine(scene, isPositive) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.isPositive = isPositive;
        _this.createBody();
        _this.prepare();
        _this.setScale(1.2, 1);
        return _this;
    }
    BackgroundShine.prototype.preDestroy = function () {
        this.stopAnimation();
        _super.prototype.preDestroy.call(this);
    };
    BackgroundShine.prototype.show = function () {
        var _this = this;
        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            duration: 500,
            onComplete: function () {
                _this.startAnimation();
                _this.scene.tweens.add({
                    targets: _this,
                    alpha: 0.3,
                    yoyo: true,
                    duration: 2500,
                    repeat: -1,
                });
            },
        });
    };
    BackgroundShine.prototype.startAnimation = function (duration) {
        if (duration === void 0) { duration = 10000; }
        this.scene.tweens.add({
            targets: this.shine,
            angle: 360,
            duration: duration,
            repeat: -1,
        });
    };
    BackgroundShine.prototype.stopAnimation = function () {
        this.scene.tweens.killTweensOf(this);
        this.scene.tweens.killTweensOf(this.shine);
    };
    BackgroundShine.prototype.createBody = function () {
        this.createShine();
    };
    BackgroundShine.prototype.createShine = function () {
        this.shine = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: this.isPositive
                ? MultiAtlases.Popups.Atlas.Frames.BackgroundShinePositive
                : MultiAtlases.Popups.Atlas.Frames.BackgroundShineNegative,
        });
        this.add(this.shine);
        this.shine.setScale(0.7);
    };
    BackgroundShine.prototype.prepare = function () {
        this.setAlpha(0);
    };
    return BackgroundShine;
}(Phaser.GameObjects.Container));
export default BackgroundShine;
