function loadAssets(scene, loaderName, checker, node, keys, autoStartLoader, scalingVariant) {
    var _a;
    if (typeof node === 'function') {
        if (!checker(node.Name)) {
            var args = [];
            for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                var key = keys_1[_i];
                args.push(node[key]);
            }
            (_a = scene.load)[loaderName].apply(_a, [node.Name].concat(args));
        }
    }
    else {
        for (var _b = 0, _c = Object.keys(node); _b < _c.length; _b++) {
            var child = _c[_b];
            loadAssets(scene, loaderName, checker, node[child], keys, autoStartLoader, scalingVariant);
        }
    }
    if (autoStartLoader) {
        scene.load.start();
    }
}
var SpriteFontAlign;
(function (SpriteFontAlign) {
    SpriteFontAlign[SpriteFontAlign["AlignTop"] = 0] = "AlignTop";
    SpriteFontAlign[SpriteFontAlign["AlignCenter"] = 1] = "AlignCenter";
    SpriteFontAlign[SpriteFontAlign["AlignBottom"] = 2] = "AlignBottom";
})(SpriteFontAlign || (SpriteFontAlign = {}));
function addSpriteIntoFont(game, fontName, frame, newCharCode, referenceChar, align, originY, cutX, cutY) {
    // if reference char is string, convert it to number
    var referenceCharF = typeof referenceChar === 'string' ? referenceChar.charCodeAt(0) : null;
    // get font characters and reference character
    var font = game.cache.bitmapFont.get(fontName);
    var fontChars = font.data.chars;
    var refChar = fontChars[referenceCharF];
    if (refChar == null) {
        throw new Error("Reference character " + String.fromCharCode(referenceCharF) + " with code " + referenceCharF + " is missing in font. Try another.");
    }
    // get frame of new sprite character
    var f = game.textures.getFrame(font['texture'], frame);
    var fWidth = f.customData['sourceSize']['w'];
    var fHeight = f.customData['sourceSize']['h'];
    // calculate y offset of sprite chracter
    var refY = refChar.yOffset +
        (align === SpriteFontAlign.AlignCenter
            ? refChar.height / 2
            : align === SpriteFontAlign.AlignBottom
                ? refChar.height
                : 0);
    var yOffset = Math.round(refY - fHeight * originY);
    // add new sprite character
    fontChars[newCharCode] = {
        yOffset: yOffset,
        xOffset: 0,
        x: f.cutX - cutX,
        y: f.cutY - cutY,
        width: f.cutWidth,
        height: f.cutHeight,
        centerX: Math.floor(fWidth / 2),
        centerY: Math.floor(fHeight / 2),
        xAdvance: fWidth + 2,
        data: {},
        kerning: {},
    };
}
export function loadImages(scene, node) {
    loadAssets(scene, 'image', scene.textures.exists.bind(scene.textures), node, [
        'FileURL',
    ]);
}
export function loadBitmapFonts(scene, node) {
    loadAssets(scene, 'bitmapFont', scene.textures.exists.bind(scene.textures), node, ['PngURL', 'XmlURL']);
}
export function loadSpriteFonts(scene, node) {
    loadAssets(scene, 'xml', scene.cache.xml.exists.bind(scene.cache.xml), node, [
        'XmlURL',
    ]);
}
export function loadAtlases(scene, node) {
    loadAssets(scene, 'atlas', scene.textures.exists.bind(scene.textures), node, [
        'TextureURL',
        'AtlasURL',
    ]);
}
export function loadJSONs(scene, node) {
    loadAssets(scene, 'json', scene.cache.json.exists.bind(scene.cache.json), node, ['FileURL']);
}
export function loadAudios(scene, node) {
    loadAssets(scene, 'audio', scene.cache.audio.exists.bind(scene.cache.audio), node, ['Mp3URL', 'OggURL']);
}
export function loadSpines(scene, node) {
    loadAssets(scene, 'spine', 
    // @ts-ignore
    function () { return false; }, // scene.spine.cache.exists.bind(scene.spine.cache),
    node, ['SkeletonURL', 'AtlasURL']);
}
export function loadXMLs(scene, node) {
    loadAssets(scene, 'xml', scene.cache.xml.exists.bind(scene.cache.xml), node, [
        'FileURL',
    ]);
}
export function createSpritefont(scene, node) {
    var fontName = node['Name'];
    var textureKey = node['Atlas'];
    var frameKey = node['Name'];
    var chars = node['Chars'];
    // @ts-ignore
    Phaser.GameObjects.BitmapText.ParseFromAtlas(scene, fontName, textureKey, frameKey, node['Name']);
    var fontFrame = scene.sys.game.textures.getFrame(textureKey, frameKey);
    chars.forEach(function (config) {
        var frame = config.frame, charCode = config.charCode, _a = config.align, align = _a === void 0 ? SpriteFontAlign.AlignCenter : _a, _b = config.originY, originY = _b === void 0 ? 0.5 : _b;
        addSpriteIntoFont(scene.sys.game, fontName, frame, parseInt(charCode, 16), '0', align, originY, fontFrame.cutX, fontFrame.cutY);
    });
}
export function loadText(scene, node, autoStartLoader, scalingVariant) {
    if (autoStartLoader === void 0) { autoStartLoader = false; }
    loadAssets(scene, 'text', scene.cache.text.exists.bind(scene.cache.text), node, ['FileURL'], autoStartLoader, scalingVariant);
}
export function loadMultiAtlases(scene, node, autoStartLoader) {
    if (autoStartLoader === void 0) { autoStartLoader = false; }
    loadAssets(scene, 'multiatlas', scene.textures.exists.bind(scene.textures), node, ['AtlasURL', 'TextureURL'], autoStartLoader);
}
export function loadFonts(node) {
    if (typeof node === 'function') {
        loadFont(node);
    }
    else {
        for (var _i = 0, _a = Object.keys(node); _i < _a.length; _i++) {
            var child = _a[_i];
            loadFonts(node[child]);
        }
    }
}
function loadFont(node) {
    var fontStyle = document.createElement('style');
    var fontFaceNode = document.createTextNode("  @font-face {      font-family: \"" + node.Name + "\";      src: url('/" + node.TtfURL + "') format('truetype');  }  ");
    fontStyle.appendChild(fontFaceNode);
    document.head.appendChild(fontStyle);
    var paragraph = document.createElement('p');
    paragraph.innerHTML = '1';
    paragraph.style.fontFamily = node.Name;
    paragraph.style.position = 'absolute';
    paragraph.style.top = '-100px';
    paragraph.style.fontSize = '1px';
    document.body.appendChild(paragraph);
}
export var ScalingVariant;
(function (ScalingVariant) {
    ScalingVariant["HD"] = "hd";
    ScalingVariant["SD"] = "sd";
})(ScalingVariant || (ScalingVariant = {}));
