var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BitmapFonts, Fonts, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { Translation } from '../../translations';
import PopupTitle from '../components/popup/PopupTitle';
import { delayRunnable, removeRunnable } from '../utils/phaser/PhaserUtils';
import SimpleButtonWithBitmapText from '../utils/simpleButton/SimpleButtonWithBitmapText';
import StandardPopup from './StandardPopup';
export var ReplayPopupAction;
(function (ReplayPopupAction) {
    ReplayPopupAction[ReplayPopupAction["REPLAY"] = 0] = "REPLAY";
    ReplayPopupAction[ReplayPopupAction["LEAVE"] = 1] = "LEAVE";
})(ReplayPopupAction || (ReplayPopupAction = {}));
var ReplayPopup = /** @class */ (function (_super) {
    __extends(ReplayPopup, _super);
    function ReplayPopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReplayPopup.prototype.createBody = function () {
        this.createColoredBlocker(0.7);
        this.createBgImage(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.ReplayPopupBackground);
        this.createTitle();
        this.createText();
        this.createReplayButton();
        this.createLeaveButton();
        this.createTimerSpriteBackground();
        this.createTimerSprite();
    };
    ReplayPopup.prototype.prepareToShow = function (x, y) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        this.textContent = Translation.REPLAY_POPUP_SUBTITLE;
        this.remaining = 9;
        this.updateTimer();
        this.text.setText(this.textContent);
        _super.prototype.prepareToShow.call(this, x, y, args);
    };
    ReplayPopup.prototype.onShowComplete = function () {
        this.startTimer();
    };
    ReplayPopup.prototype.createTitle = function () {
        var config = {
            frame: MultiAtlases.Popups.Atlas.Frames.ReplayPopupTitle,
            fontFamily: Fonts.RobotoSlab.Name,
            text: Translation.REPLAY_POPUP_TITLE,
            fontSize: 70,
            fill: '#c3a469',
            origin: { x: 0.5, y: 0.7 },
        };
        this.title = new PopupTitle(this.scene, config);
        this.title.y = -this.displayHeight * 0.43;
        this.add(this.title);
    };
    ReplayPopup.prototype.createText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextMedium.Name,
            fontSize: 34 * gameConfig.resolutionMultiplier,
            fill: '#c3a469',
        };
        this.text = this.scene.make.extText({
            x: 0,
            y: -this.displayHeight * 0.3,
            text: this.textContent,
            style: style,
        });
        this.text.setOrigin(0.5);
        this.add(this.text);
    };
    ReplayPopup.prototype.createReplayButton = function () {
        var textConfig = {
            text: Translation.REPLAY_POPUP_REPLAY_BUTTON,
            size: 56 * gameConfig.resolutionMultiplier,
            fill: 0xffffe5,
            font: BitmapFonts.RobotoSlab.Name,
            align: Phaser.GameObjects.BitmapText.ALIGN_CENTER,
            origin: { x: 0.5, y: 0.6 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PositiveButtonUp,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PositiveButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PositiveButtonDown,
            textConfig: textConfig,
        };
        var config = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        this.replayButton = new SimpleButtonWithBitmapText(this.scene, config);
        this.add(this.replayButton);
        this.replayButton.x = -this.replayButton.width * 0.51;
        this.replayButton.y = this.displayHeight * 0.275;
        this.replayButton.once(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction.bind(this, ReplayPopupAction.REPLAY));
    };
    ReplayPopup.prototype.createLeaveButton = function () {
        var textConfig = {
            text: Translation.REPLAY_POPUP_LEAVE_BUTTON,
            size: 56 * gameConfig.resolutionMultiplier,
            fill: 0xffffe5,
            font: BitmapFonts.RobotoSlab.Name,
            align: Phaser.GameObjects.BitmapText.ALIGN_CENTER,
            origin: { x: 0.5, y: 0.6 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.NegativeButtonUp,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.NegativeButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.NegativeButtonDown,
            textConfig: textConfig,
        };
        var config = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        this.leaveButton = new SimpleButtonWithBitmapText(this.scene, config);
        this.add(this.leaveButton);
        this.leaveButton.x = this.leaveButton.width * 0.51;
        this.leaveButton.y = this.displayHeight * 0.275;
        this.leaveButton.once(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction.bind(this, ReplayPopupAction.LEAVE));
    };
    ReplayPopup.prototype.createTimerSpriteBackground = function () {
        this.timerSpriteBackground = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SecondBackground,
        });
        this.timerSpriteBackground.y = -this.timerSpriteBackground.height * 0.1;
        this.add(this.timerSpriteBackground);
    };
    ReplayPopup.prototype.createTimerSprite = function () {
        this.timerSprite = this.scene.make.sprite({
            x: 0,
            y: -this.timerSpriteBackground.height * 0.05,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.Sec9,
        });
        this.add(this.timerSprite);
    };
    ReplayPopup.prototype.startTimer = function () {
        this.timer = delayRunnable(this.scene, 1000, this.changeTimerValue, this);
    };
    ReplayPopup.prototype.changeTimerValue = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.remaining--;
                        if (!(this.remaining >= 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateTimer()];
                    case 1:
                        _a.sent();
                        this.startTimer();
                        return [2 /*return*/];
                    case 2: return [4 /*yield*/, this.hideTimer()];
                    case 3:
                        _a.sent();
                        removeRunnable(this.timer);
                        this.onAction(ReplayPopupAction.LEAVE);
                        return [2 /*return*/];
                }
            });
        });
    };
    ReplayPopup.prototype.hideTimer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.text && _this.text.setText(Translation.REPLAY_POPUP_SUBTITLE_BACK);
                        _this.replayButton && _this.replayButton.setEnabled(false);
                        _this.scene && _this.scene.tweens.add({
                            targets: [_this.timerSpriteBackground, _this.timerSprite],
                            scaleX: 0,
                            scaleY: 0,
                            ease: Phaser.Math.Easing.Back.In,
                            duration: 200,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    ReplayPopup.prototype.updateTimer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.scene && _this.scene.tweens.add({
                            targets: _this.timerSprite,
                            scaleX: 0,
                            yoyo: true,
                            onYoyo: function () {
                                _this.scene && _this.timerSprite.setTexture(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames["Sec" + _this.remaining]);
                            },
                            duration: 100,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    ReplayPopup.prototype.onAction = function (action) {
        removeRunnable(this.timer);
        _super.prototype.onAction.call(this, action);
    };
    ReplayPopup.NAME = "ReplayPopup";
    ReplayPopup.USER_REPLAYS_NOTIFICATION = ReplayPopup.NAME + "UserReplaysNotification";
    ReplayPopup.REPLAY_NOTIFICATION = ReplayPopup.NAME + "ReplayNotification";
    ReplayPopup.LEAVE_NOTIFICATION = ReplayPopup.NAME + "LeaveNotification";
    return ReplayPopup;
}(StandardPopup));
export default ReplayPopup;
