var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fonts, MultiAtlases } from '../../assets';
import { Translation } from '../../translations';
import PopupTitle from '../components/popup/PopupTitle';
import StandardPopup from './StandardPopup';
var ConnectionLostPopup = /** @class */ (function (_super) {
    __extends(ConnectionLostPopup, _super);
    function ConnectionLostPopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConnectionLostPopup.prototype.createBody = function () {
        this.createColoredBlocker(0.7);
        this.createBgImage(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.ConnectionLostPopupBackground);
        this.createTitle();
        this.createIcon();
    };
    ConnectionLostPopup.prototype.createTitle = function () {
        var config = {
            frame: MultiAtlases.Popups.Atlas.Frames.ConnectionLostPopupTitle,
            fontFamily: Fonts.RobotoSlab.Name,
            text: Translation.CONNECTION_LOST_POPUP_TITLE,
            fontSize: 48,
            fill: '#c3a469',
            origin: { x: 0.5, y: 0.6 },
        };
        this.title = new PopupTitle(this.scene, config);
        this.title.y = -this.height * 0.45;
        this.add(this.title);
    };
    ConnectionLostPopup.prototype.createIcon = function () {
        this.icon = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.ConnectionLostPopupIcon,
        });
        this.add(this.icon);
    };
    ConnectionLostPopup.NAME = 'ConnectionLostPopup';
    return ConnectionLostPopup;
}(StandardPopup));
export default ConnectionLostPopup;
