var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import ServiceScene from '../view/scenes/ServiceScene';
import CloseAppCommand from './service/CloseAppCommand';
import RelaunchTheGameCommand from './service/RelaunchTheGameCommand';
var RegisterServiceCommands = /** @class */ (function (_super) {
    __extends(RegisterServiceCommands, _super);
    function RegisterServiceCommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterServiceCommands.prototype.execute = function () {
        this.facade.registerCommandOnce(ServiceScene.CONTINUE_GAME_ON_THIS_DEVICE_NOTIFICATION, RelaunchTheGameCommand);
        this.facade.registerCommandOnce(ServiceScene.CLOSE_APP_NOTIFICATION, CloseAppCommand);
    };
    return RegisterServiceCommands;
}(SyncMacroCommand));
export default RegisterServiceCommands;
