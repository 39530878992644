var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getPlatform } from '../CordovaUtils';
var FbMobileWrapper = /** @class */ (function () {
    function FbMobileWrapper() {
    }
    FbMobileWrapper.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        getPlatform() === 'browser' &&
                            window.FB.init({
                                appId: 437521146758478,
                                autoLogAppEvents: true,
                                xfbml: true,
                                version: 'v6.0',
                            });
                        resolve();
                    })];
            });
        });
    };
    FbMobileWrapper.getLoginStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        window.facebookConnectPlugin.getLoginStatus(resolve, reject);
                    })];
            });
        });
    };
    FbMobileWrapper.showDialog = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        window.facebookConnectPlugin.showDialog({}, resolve, reject);
                    })];
            });
        });
    };
    FbMobileWrapper.getAccessToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        window.facebookConnectPlugin.getAccessToken(resolve, reject);
                    })];
            });
        });
    };
    FbMobileWrapper.login = function (permissions) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        window.facebookConnectPlugin.login(permissions, resolve, reject);
                    })];
            });
        });
    };
    FbMobileWrapper.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        window.facebookConnectPlugin.logout(resolve, reject);
                    })];
            });
        });
    };
    FbMobileWrapper.callAPI = function (graphPath, permissions) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        window.facebookConnectPlugin.api(graphPath, permissions, resolve, reject);
                    })];
            });
        });
    };
    FbMobileWrapper.getPlayerData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        window.facebookConnectPlugin.api("/me", [], resolve, reject);
                    })];
            });
        });
    };
    FbMobileWrapper.getPlayerPhoto = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        window.facebookConnectPlugin.api("/me?fields=picture.type(normal)", [], resolve, reject);
                    })];
            });
        });
    };
    return FbMobileWrapper;
}());
export { FbMobileWrapper };
export var FacebookLoginStatus;
(function (FacebookLoginStatus) {
    FacebookLoginStatus["CONNECTED"] = "connected";
    FacebookLoginStatus["NOT_AUTHORIZED"] = "not_authorized";
    FacebookLoginStatus["UNKNOWN"] = "unknown ";
})(FacebookLoginStatus || (FacebookLoginStatus = {}));
export var FacebookLoginPermission;
(function (FacebookLoginPermission) {
    FacebookLoginPermission["PUBLIC_PROFILE"] = "public_profile";
    FacebookLoginPermission["EMAIL"] = "email";
})(FacebookLoginPermission || (FacebookLoginPermission = {}));
