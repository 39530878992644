var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { delayRunnable, removeRunnable } from '../../utils/phaser/PhaserUtils';
var ToolTip = /** @class */ (function (_super) {
    __extends(ToolTip, _super);
    function ToolTip(scene, position, parentAvatar) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.position = position;
        _this.parentAvatar = parentAvatar;
        _this.createBody();
        _this.prepare();
        return _this;
    }
    ToolTip.prototype.show = function (duration, text) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(this.scene.tweens.isTweening(this) ||
                                        this.scene.tweens.isTweening(this.text))) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.hide()];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2:
                                    removeRunnable(this.tooltipHideRunnable);
                                    this.text.setText(text);
                                    this.updateBackgroundSize();
                                    this.scene.tweens.add({
                                        targets: this,
                                        scaleX: 1,
                                        scaleY: 1,
                                        alpha: 1,
                                        duration: 200,
                                        ease: Phaser.Math.Easing.Back.Out,
                                        onComplete: function () {
                                            _this.scene.tweens.add({
                                                targets: _this.text,
                                                alpha: 1,
                                                duration: 200,
                                                onComplete: function () {
                                                    _this.tooltipHideRunnable = delayRunnable(_this.scene, duration, _this.hide, _this);
                                                    resolve();
                                                },
                                            });
                                        },
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    ToolTip.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.scene.tweens.killTweensOf([_this, _this.text]);
                        _this.scene.tweens.add({
                            targets: _this.text,
                            alpha: 0,
                            duration: 100,
                            onComplete: function () {
                                if (!_this.scene) {
                                    removeRunnable(_this.tooltipHideRunnable);
                                    resolve();
                                    return;
                                }
                                _this.scene.tweens.add({
                                    targets: _this,
                                    scaleX: 0,
                                    scaleY: 0,
                                    alpha: 0,
                                    duration: 100,
                                    onComplete: function () {
                                        removeRunnable(_this.tooltipHideRunnable);
                                        resolve();
                                    },
                                });
                            },
                        });
                    })];
            });
        });
    };
    ToolTip.prototype.updatePosition = function (position) {
        this.position = position;
        this.prepare();
    };
    ToolTip.prototype.createBody = function () {
        this.createBackground();
        this.createText();
    };
    ToolTip.prototype.createBackground = function () {
        var frame = this.scene.textures.getFrame(MultiAtlases.GamePlay.Atlas.Name, MultiAtlases.GamePlay.Atlas.Frames.Tooltip);
        this.background = this.scene.make.ninePatch({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.Tooltip,
            width: frame.width * gameConfig.resolutionMultiplier,
            height: frame.height * gameConfig.resolutionMultiplier,
        });
        this.background.setOrigin(1, 0.5);
        this.add(this.background);
        this.setSize(this.background.width, this.background.height);
    };
    ToolTip.prototype.createText = function () {
        var fontSize = 48;
        if (this.parentAvatar == ToolTipParent.PLAYER) {
            fontSize = 64;
        }
        var style = {
            fontFamily: Fonts.AvenirNextBold.Name,
            fontSize: fontSize * gameConfig.resolutionMultiplier,
            fill: '#69340D',
        };
        this.text = this.scene.make.extText({
            x: this.background.x,
            y: this.background.y,
            text: '',
            style: style,
        });
        this.text.setOrigin(0.5, 0.8);
        this.add(this.text);
        this.updateBackgroundSize();
    };
    ToolTip.prototype.updateBackgroundSize = function () {
        this.background.resize(this.text.width + 52 * gameConfig.resolutionMultiplier, this.text.height + 40 * gameConfig.resolutionMultiplier);
        this.text.x = (this.background.scaleX * this.background.width) / 2;
    };
    ToolTip.prototype.prepare = function () {
        switch (this.position) {
            case ToolTipPosition.TOP:
                this.background.setScale(-1, 1);
                this.background.setOrigin(0, 0.5);
                this.text.x = (this.background.scaleX * this.background.width) / 2;
                this.text.setOrigin(this.text.originX, 0.7);
                break;
            case ToolTipPosition.BOTTOM:
                this.background.setScale(-1);
                this.background.setOrigin(0, 0.5);
                this.text.setOrigin(this.text.originX, 0.35);
                break;
            default:
                break;
        }
        this.setScale(0);
        this.setAlpha(0);
        this.text.setAlpha(0);
    };
    return ToolTip;
}(Phaser.GameObjects.Container));
export default ToolTip;
export var ToolTipPosition;
(function (ToolTipPosition) {
    ToolTipPosition[ToolTipPosition["BOTTOM"] = 0] = "BOTTOM";
    ToolTipPosition[ToolTipPosition["TOP"] = 1] = "TOP";
})(ToolTipPosition || (ToolTipPosition = {}));
export var ToolTipParent;
(function (ToolTipParent) {
    ToolTipParent[ToolTipParent["OPPONENT"] = 0] = "OPPONENT";
    ToolTipParent[ToolTipParent["PLAYER"] = 1] = "PLAYER";
})(ToolTipParent || (ToolTipParent = {}));
