var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import AbstractSocketGuard from './AbstractSocketGuard';
var IsValidCardGuard = /** @class */ (function (_super) {
    __extends(IsValidCardGuard, _super);
    function IsValidCardGuard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IsValidCardGuard.prototype.approve = function (notificationName, card) {
        var validCardData = this.socketVOProxy.vo.validatedCards.filter(function (data) {
            return data.card.suit === card.suit && data.card.value === card.value;
        })[0];
        return !!validCardData;
    };
    return IsValidCardGuard;
}(AbstractSocketGuard));
export default IsValidCardGuard;
