var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BitmapFonts, Images, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { Gender } from '../../../model/VO/PlayerVO';
import { Translation } from '../../../translations';
import RegistrationScene from '../../scenes/RegistrationScene';
import SimpleButtonWithBitmapText from '../../utils/simpleButton/SimpleButtonWithBitmapText';
import { SpriteButton } from '../../utils/simpleButton/SpriteButton';
import AvatarScrollableContainer from './avatarList/AvatarScrollableContainer';
import RegistrationNameTextField from './RegistrationNameTextField';
import RegistrationProfileAvatar from './RegistrationProfileAvatar';
import RegistrationProfileGenderRadio from './RegistrationProfileGenderRadio';
import RegistrationProfileMessage from './RegistrationProfileMessage';
export var FinalizeProfileSceneAction;
(function (FinalizeProfileSceneAction) {
    FinalizeProfileSceneAction[FinalizeProfileSceneAction["EDIT"] = 0] = "EDIT";
    FinalizeProfileSceneAction[FinalizeProfileSceneAction["AVATAR"] = 1] = "AVATAR";
    FinalizeProfileSceneAction[FinalizeProfileSceneAction["GENDER"] = 2] = "GENDER";
    FinalizeProfileSceneAction[FinalizeProfileSceneAction["NEXT"] = 3] = "NEXT";
    FinalizeProfileSceneAction[FinalizeProfileSceneAction["OK"] = 4] = "OK";
})(FinalizeProfileSceneAction || (FinalizeProfileSceneAction = {}));
var RegistrationProfileInfoSection = /** @class */ (function (_super) {
    __extends(RegistrationProfileInfoSection, _super);
    function RegistrationProfileInfoSection(scene, config) {
        var _this = _super.call(this, scene, gameConfig.canvasWidth / 2, gameConfig.canvasHeight / 2) || this;
        _this.scene = scene;
        _this.config = config;
        _this.scene.add.existing(_this);
        _this.init();
        _this.createBody();
        _this.prepare();
        return _this;
    }
    RegistrationProfileInfoSection.prototype.getKeys = function () {
        return this.keys;
    };
    RegistrationProfileInfoSection.prototype.prepareNameFieldForTyping = function () {
        this.scene.tweens.killTweensOf(this.nameField);
        var scale = 1.5;
        this.scene.tweens.add({
            targets: this.nameField,
            y: -gameConfig.canvasHeight / 2 + this.nameField.height * scale * 1.5,
            scaleX: scale,
            scaleY: scale,
            delay: 100,
            duration: 100,
        });
    };
    RegistrationProfileInfoSection.prototype.returnNameFieldToItsPosition = function () {
        this.scene.tweens.killTweensOf(this.nameField);
        this.scene.tweens.add({
            targets: this.nameField,
            y: -this.nameField.height * 0.9,
            scaleX: 1,
            scaleY: 1,
            duration: 200,
        });
    };
    RegistrationProfileInfoSection.prototype.hideAvatar = function () {
        this.scene.tweens.killTweensOf([this.avatar, this.editButton]);
        this.scene.tweens.add({
            targets: [this.avatar, this.editButton],
            scaleX: 0,
            scaleY: 0,
            duration: 100,
        });
    };
    RegistrationProfileInfoSection.prototype.showAvatar = function (gender) {
        gender && this.avatar.updateAvatar(gender);
        this.scene.tweens.add({
            targets: [this.avatar, this.editButton],
            scaleX: 1,
            scaleY: 1,
            duration: 150,
        });
    };
    RegistrationProfileInfoSection.prototype.setAvatar = function (gender, index) {
        this.avatar.updateAvatar(gender, index);
        this.scene.tweens.add({
            targets: [this.avatar],
            scaleX: 1,
            scaleY: 1,
            duration: 150,
        });
    };
    RegistrationProfileInfoSection.prototype.showGenderRadios = function () {
        this.scene.tweens.add({
            targets: this.genderRadios,
            scaleX: 1,
            scaleY: 1,
            duration: 200,
            ease: Phaser.Math.Easing.Back.Out,
        });
    };
    RegistrationProfileInfoSection.prototype.hideGenderRadios = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.scene.tweens.add({
                            targets: _this.genderRadios,
                            scaleX: 0,
                            scaleY: 0,
                            duration: 200,
                            ease: Phaser.Math.Easing.Back.In,
                            onComplete: resolve,
                        });
                    })];
            });
        });
    };
    RegistrationProfileInfoSection.prototype.showMessageAndNextElement = function () {
        var _this = this;
        this.message.updateBackgroundSize();
        this.scene.tweens.add({
            targets: [
                this.genderRadios,
                this.message,
                this.nextButton,
                this.nameField,
            ],
            scaleX: 1,
            scaleY: 1,
            duration: 200,
            ease: Phaser.Math.Easing.Back.Out,
            onComplete: function () {
                _this.nameField.enable();
            },
        });
    };
    RegistrationProfileInfoSection.prototype.hideMessageAndNextElement = function () {
        this.scene.tweens.add({
            targets: [this.genderRadios, this.message, this.nextButton],
            scaleX: 0,
            scaleY: 0,
            duration: 200,
            ease: Phaser.Math.Easing.Back.Out,
        });
    };
    RegistrationProfileInfoSection.prototype.showEditButton = function () {
        this.scene.tweens.killTweensOf(this.editButton);
        this.scene.tweens.add({
            targets: this.editButton,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Back.Out,
            duration: 300,
        });
    };
    RegistrationProfileInfoSection.prototype.hideEditButton = function () {
        this.scene.tweens.killTweensOf(this.editButton);
        this.scene.tweens.add({
            targets: this.editButton,
            scaleX: 0,
            scaleY: 0,
            ease: Phaser.Math.Easing.Back.In,
            duration: 300,
        });
    };
    RegistrationProfileInfoSection.prototype.toggleAvatarsList = function (gender) {
        if (this.avatarsList.isShown) {
            this.hideAvatarList();
            this.showEditButton();
            this.showMessageAndNextElement();
        }
        else {
            this.showAvatarsList(gender);
            this.hideEditButton();
            this.hideMessageAndNextElement();
        }
    };
    RegistrationProfileInfoSection.prototype.showAvatarsList = function (gender) {
        this.scene.add.existing(this.avatarsList);
        this.hideGenderRadios();
        this.avatarsList.show(gender);
    };
    RegistrationProfileInfoSection.prototype.hideAvatarList = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.avatarsList.hide()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.showGenderRadios()];
                    case 2:
                        _a.sent();
                        this.scene.children.remove(this.avatarsList);
                        return [2 /*return*/];
                }
            });
        });
    };
    RegistrationProfileInfoSection.prototype.updateRadioStates = function (gender) {
        for (var _i = 0, _a = this.genderRadios; _i < _a.length; _i++) {
            var radio = _a[_i];
            radio.setSelected(radio.config.gender === gender);
        }
    };
    RegistrationProfileInfoSection.prototype.createBody = function () {
        this.createAvatar();
        this.createEditButton();
        this.createNameField();
        this.createGenderRadios();
        this.createMessage();
        this.createNextButton();
        this.createAvatarsList();
        this.setListeners();
    };
    RegistrationProfileInfoSection.prototype.createAvatar = function () {
        this.avatar = new RegistrationProfileAvatar(this.scene, this.keys);
        this.avatar.setPosition(0, -gameConfig.canvasHeight * 0.3);
        this.add(this.avatar);
    };
    RegistrationProfileInfoSection.prototype.createEditButton = function () {
        var normalStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.EditButtonNormal,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.EditButtonHover,
        };
        var downStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.EditButtonDown,
        };
        this.editButton = new SpriteButton(this.scene, {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        });
        this.add(this.editButton);
        this.editButton.x = this.avatar.x + this.avatar.width * 0.65;
        this.editButton.y = this.avatar.y;
    };
    RegistrationProfileInfoSection.prototype.createNameField = function () {
        this.nameField = new RegistrationNameTextField(this.scene, this.config.name);
        this.nameField.y = -this.nameField.height * 0.9;
        this.add(this.nameField);
    };
    RegistrationProfileInfoSection.prototype.createGenderRadios = function () {
        this.createMaleRadio();
        this.createFemaleRadio();
        this.setRadioPositions();
    };
    RegistrationProfileInfoSection.prototype.createMaleRadio = function () {
        var radio = new RegistrationProfileGenderRadio(this.scene, {
            actionId: FinalizeProfileSceneAction.GENDER,
            text: Translation.FINALIZE_PROFILE_SCENE_RADIO_MALE_TEXT,
            gender: Gender.MALE,
        });
        this.genderRadios.push(radio);
        this.add(radio);
    };
    RegistrationProfileInfoSection.prototype.createFemaleRadio = function () {
        var radio = new RegistrationProfileGenderRadio(this.scene, {
            actionId: FinalizeProfileSceneAction.GENDER,
            text: Translation.FINALIZE_PROFILE_SCENE_RADIO_FEMALE_TEXT,
            gender: Gender.FEMALE,
        });
        this.genderRadios.push(radio);
        this.add(radio);
    };
    RegistrationProfileInfoSection.prototype.setRadioPositions = function () {
        var maleRadio = this.genderRadios[0];
        maleRadio.setPosition(-maleRadio.width * 0.52, maleRadio.height * 0.7);
        var femaleRadio = this.genderRadios[1];
        femaleRadio.setPosition(femaleRadio.width * 0.52, femaleRadio.height * 0.7);
    };
    RegistrationProfileInfoSection.prototype.createMessage = function () {
        this.message = new RegistrationProfileMessage(this.scene, Translation.FINALIZE_PROFILE_SCENE_MESSAGE_TEXT);
        this.message.setPosition(0, gameConfig.designHeight * 0.18);
        this.add(this.message);
    };
    RegistrationProfileInfoSection.prototype.createNextButton = function () {
        var normalTextConfig = {
            text: Translation.FINALIZE_PROFILE_SCENE_NEXT_BUTTON_TEXT,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0xfff3d3,
            origin: {
                x: 0.5,
                y: 0.8,
            },
        };
        var hoverTextConfig = {
            text: Translation.FINALIZE_PROFILE_SCENE_NEXT_BUTTON_TEXT,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0xfff3d3,
            origin: {
                x: 0.5,
                y: 0.8,
            },
        };
        var downTextConfig = {
            text: Translation.FINALIZE_PROFILE_SCENE_NEXT_BUTTON_TEXT,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0x0cc8c8,
            origin: {
                x: 0.5,
                y: 0.8,
            },
        };
        var normalStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.NextButtonNormal,
            textConfig: normalTextConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.NextButtonHover,
            textConfig: hoverTextConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.NextButtonDown,
            textConfig: downTextConfig,
        };
        this.nextButton = new SimpleButtonWithBitmapText(this.scene, {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        });
        this.nextButton.setPosition(0, gameConfig.designHeight * 0.34);
        this.add(this.nextButton);
    };
    RegistrationProfileInfoSection.prototype.createAvatarsList = function () {
        var avatarTransformMatrix = this.avatar.getWorldTransformMatrix();
        this.avatarsList = new AvatarScrollableContainer(this.scene, avatarTransformMatrix.tx, avatarTransformMatrix.ty + this.avatar.height * 0.55, this.keys);
    };
    RegistrationProfileInfoSection.prototype.generateKeys = function (count, prefix) {
        if (count === void 0) { count = 0; }
        if (prefix === void 0) { prefix = null; }
        var keys = [];
        for (var i = 0; i < count; i++) {
            keys.push(prefix + "-" + i);
        }
        keys.push(Images.Avatar.Name);
        return keys;
    };
    RegistrationProfileInfoSection.prototype.setListeners = function () {
        this.editButton.on(SpriteButton.CLICK_EVENT, this.onEditClick, this);
        this.nextButton.on(SimpleButtonWithBitmapText.CLICK_EVENT, this.onNextClick, this);
        this.avatar.on(RegistrationProfileAvatar.CLICK_EVENT, this.onAvatarClick, this);
    };
    RegistrationProfileInfoSection.prototype.init = function () {
        this.genderRadios = [];
        var male = this.generateKeys(this.config.avatarsCount.male, 'male');
        var female = this.generateKeys(this.config.avatarsCount.female, 'female');
        var uni = this.generateKeys();
        this.keys = {
            male: male,
            female: female,
            uni: uni,
        };
    };
    RegistrationProfileInfoSection.prototype.prepare = function () {
        this.editButton.setScale(0);
        this.message.setScale(0);
        this.nextButton.setScale(0);
        for (var _i = 0, _a = this.genderRadios; _i < _a.length; _i++) {
            var radio = _a[_i];
            radio.setScale(0);
        }
        this.nameField.setScale(0);
    };
    RegistrationProfileInfoSection.prototype.onEditClick = function () {
        this.scene.events.emit(RegistrationScene.PROFILE_ACTION_EVENT, FinalizeProfileSceneAction.EDIT);
    };
    RegistrationProfileInfoSection.prototype.onAvatarClick = function () {
        this.scene.events.emit(RegistrationScene.PROFILE_ACTION_EVENT, FinalizeProfileSceneAction.AVATAR);
    };
    RegistrationProfileInfoSection.prototype.onNextClick = function () {
        var name = this.nameField.getValue();
        if (!name || name === '' || name.replace(' ', '') === '') {
            return this.nameField.focus();
        }
        this.scene.events.emit(RegistrationScene.PROFILE_ACTION_EVENT, FinalizeProfileSceneAction.NEXT, this.nameField.getValue());
    };
    return RegistrationProfileInfoSection;
}(Phaser.GameObjects
    .Container));
export default RegistrationProfileInfoSection;
