var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mediator } from '@candywings/pure-mvc';
import { Images } from '../../../assets';
import SocketVOProxy from '../../../model/SocketVOProxy';
import StateVOProxy from '../../../model/StateVOProxy';
import { CardPlayType } from '../../../model/VO/SocketVO';
import { Translation } from '../../../translations';
import { fetchAvatarByConfig, getNativeAvatarKey, isNativeAvatar } from '../../../utils/Utils';
import GameScene from '../../scenes/GameScene';
import { getScene, hasKey } from '../../utils/phaser/PhaserUtils';
import DeckView from './DeckView';
import DurakPlayerView from './DurakPlayerView';
import { GameTimerType } from './GameTimer';
import OpponentView from './OpponentView';
import TurnView from './TurnView';
var OpponentViewMediator = /** @class */ (function (_super) {
    __extends(OpponentViewMediator, _super);
    function OpponentViewMediator() {
        return _super.call(this, "" + OpponentViewMediator.NAME, null) || this;
    }
    OpponentViewMediator.prototype.onRemove = function () {
        this.viewComponent && this.viewComponent.destroy();
        _super.prototype.onRemove.call(this);
    };
    OpponentViewMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(DeckView.DEAL_CARD_NOTIFICATION, GameScene.CREATE_NOTIFICATION, GameScene.RECREATE_NOTIFICATION, StateVOProxy.PLAYERS_READY_NOTIFICATION, GameScene.FORCE_START_NOTIFICATION, SocketVOProxy.TURN_PASSED_NOTIFICATION, SocketVOProxy.PLAYER_TAKES_NOTIFICATION, SocketVOProxy.ROUND_ENDED_NOTIFICATION, DeckView.DECK_PLACED_NOTIFICATION, StateVOProxy.CARD_PLAYED_NOTIFICATION, StateVOProxy.GAME_OVER_NOTIFICATION, TurnView.GET_CARDS_NOTIFICATION, SocketVOProxy.EMOJI_PUBLISHED_NOTIFICATION, StateVOProxy.NEXT_PLAYER_NOTIFICATION);
    };
    OpponentViewMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, playerIndex, card, playerIndex, cards, userIndex, userIndex, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = notificationName;
                        switch (_a) {
                            case GameScene.FORCE_START_NOTIFICATION: return [3 /*break*/, 1];
                            case GameScene.CREATE_NOTIFICATION: return [3 /*break*/, 2];
                            case GameScene.RECREATE_NOTIFICATION: return [3 /*break*/, 2];
                            case StateVOProxy.PLAYERS_READY_NOTIFICATION: return [3 /*break*/, 3];
                            case DeckView.DEAL_CARD_NOTIFICATION: return [3 /*break*/, 4];
                            case TurnView.GET_CARDS_NOTIFICATION: return [3 /*break*/, 6];
                            case StateVOProxy.NEXT_PLAYER_NOTIFICATION: return [3 /*break*/, 7];
                            case StateVOProxy.CARD_PLAYED_NOTIFICATION: return [3 /*break*/, 8];
                            case SocketVOProxy.TURN_PASSED_NOTIFICATION: return [3 /*break*/, 9];
                            case SocketVOProxy.PLAYER_TAKES_NOTIFICATION: return [3 /*break*/, 10];
                            case SocketVOProxy.ROUND_ENDED_NOTIFICATION: return [3 /*break*/, 11];
                            case SocketVOProxy.EMOJI_PUBLISHED_NOTIFICATION: return [3 /*break*/, 12];
                            case StateVOProxy.GAME_OVER_NOTIFICATION: return [3 /*break*/, 13];
                        }
                        return [3 /*break*/, 14];
                    case 1:
                        {
                            this.viewComponent.forceUpdateCards(this.stateVOProxy.vo.players[this.index].cards.length);
                            this.updateViewComponentData();
                            this.onNextUser();
                        }
                        return [3 /*break*/, 15];
                    case 2:
                        this.setView();
                        return [3 /*break*/, 15];
                    case 3:
                        {
                            {
                                this.updateViewComponentData();
                            }
                            return [3 /*break*/, 15];
                        }
                        _b.label = 4;
                    case 4:
                        console.warn('********************');
                        console.warn('DeckView.DEAL_CARD_NOTIFICATION');
                        console.warn(args[1]);
                        playerIndex = args[0];
                        if (playerIndex !== this.index) {
                            return [3 /*break*/, 15];
                        }
                        card = args[1];
                        return [4 /*yield*/, this.viewComponent.getDealedCard(card)];
                    case 5:
                        _b.sent();
                        return [3 /*break*/, 15];
                    case 6:
                        {
                            playerIndex = args[0];
                            if (playerIndex !== this.index) {
                                return [2 /*return*/];
                            }
                            cards = args[1];
                            this.takeCards(cards);
                        }
                        return [3 /*break*/, 15];
                    case 7:
                        this.onNextUser();
                        return [3 /*break*/, 15];
                    case 8:
                        this.onCardPlayed(args[0]);
                        return [3 /*break*/, 15];
                    case 9:
                        this.viewComponent.stopTimer();
                        {
                            userIndex = args[0];
                            if (userIndex === this.index) {
                                this.viewComponent.showTooltip(1000, Translation.TOOLTIP_PASS);
                            }
                        }
                        return [3 /*break*/, 15];
                    case 10:
                        this.viewComponent.stopTimer();
                        {
                            userIndex = args[0];
                            if (userIndex === this.index) {
                                this.viewComponent.showTooltip(1000, Translation.TOOLTIP_TAKE);
                            }
                        }
                        return [3 /*break*/, 15];
                    case 11:
                        this.viewComponent.stopTimer();
                        return [3 /*break*/, 15];
                    case 12:
                        data = args[0];
                        if (data.userId !== this.index) {
                            return [3 /*break*/, 15];
                        }
                        this.viewComponent.showEmoji(data.type, data.key);
                        return [3 /*break*/, 15];
                    case 13:
                        this.viewComponent.clearCards();
                        return [3 /*break*/, 15];
                    case 14:
                        console.warn(notificationName + " is unhandled!");
                        return [3 /*break*/, 15];
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    OpponentViewMediator.prototype.setView = function () {
        var view = new OpponentView(this.scene, this.index);
        this.setViewComponent(view);
        this.setViewComponentListeners();
    };
    OpponentViewMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.on(OpponentView.AVATAR_CLICK_EVENT, this.onAvatarClick, this);
    };
    OpponentViewMediator.prototype.takeCards = function (cards) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.viewComponent.getCards(cards)];
                    case 1:
                        _a.sent();
                        this.sendNotification(OpponentView.GOT_CARDS_NOTIFICATION);
                        return [2 /*return*/];
                }
            });
        });
    };
    OpponentViewMediator.prototype.onNextUser = function () {
        this.viewComponent.stopTimer();
        var nextUser = this.stateVOProxy.vo.turnData.currentPlayer;
        var playerData = this.stateVOProxy.vo.players[this.index];
        if (nextUser.userId != this.index) {
            this.viewComponent.hideTimerHighLight();
            this.viewComponent.activeMode(false);
            if (playerData.isDefender) {
                this.viewComponent.setTimerMode(GameTimerType.DEFENCE);
                this.viewComponent.showTimerHighLight();
            }
            return;
        }
        if (nextUser.userId == this.index) {
            this.viewComponent.activeMode(true, !playerData.isDefender);
            this.viewComponent.setTimerMode(playerData.isDefender ? GameTimerType.DEFENCE : GameTimerType.ATTACK);
            this.viewComponent.setTimerDuration(nextUser.timeout);
            this.viewComponent.startTimer();
            if (this.stateVOProxy.vo.turnData.cardPairs.length === 0) {
                this.viewComponent.showTooltip(1500, Translation.TOOLTIP_TURN);
            }
        }
    };
    OpponentViewMediator.prototype.onCardPlayed = function (playedCardData) {
        return __awaiter(this, void 0, void 0, function () {
            var card;
            return __generator(this, function (_a) {
                this.viewComponent.stopTimer();
                this.viewComponent.hideTooltip();
                if (playedCardData.userIndex !== this.index) {
                    return [2 /*return*/];
                }
                card = this.viewComponent.createCard(playedCardData.card);
                this.sendNotification(DurakPlayerView.PLAYED_CARD_NOTIFICATION, card, playedCardData.target);
                this.viewComponent.shiftCard();
                playedCardData.type === CardPlayType.SWAP &&
                    this.viewComponent.showTooltip(1000, Translation.TOOLTIP_SWAP);
                return [2 /*return*/];
            });
        });
    };
    OpponentViewMediator.prototype.updateViewComponentData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var players, opponentData, name, isNative, key;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        players = this.stateVOProxy.vo.players;
                        opponentData = players[this.index];
                        name = opponentData.name;
                        isNative = isNativeAvatar(opponentData.avatar);
                        key = "User" + opponentData.userId + "Avatar";
                        if (!!hasKey(key)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadAvatar({
                                userId: opponentData.userId,
                                avatarURL: opponentData.avatar
                            })];
                    case 1:
                        key = _a.sent();
                        _a.label = 2;
                    case 2:
                        this.viewComponent.setAvatar({
                            name: name.indexOf(' ') !== -1 ? name.substr(0, name.indexOf(' ')) : name,
                            level: opponentData.level,
                            key: key,
                            isNative: isNative || !opponentData.avatar,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    OpponentViewMediator.prototype.loadAvatar = function (avatarData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!avatarData.avatarURL) return [3 /*break*/, 1];
                        return [2 /*return*/, Images.Avatar.Name];
                    case 1:
                        if (!isNativeAvatar(avatarData.avatarURL)) return [3 /*break*/, 2];
                        return [2 /*return*/, getNativeAvatarKey(avatarData.avatarURL)];
                    case 2: return [4 /*yield*/, fetchAvatarByConfig(this.scene, avatarData)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, "User" + avatarData.userId + "Avatar"];
                }
            });
        });
    };
    OpponentViewMediator.prototype.onAvatarClick = function () {
        this.sendNotification(OpponentView.AVATAR_CLICKED_NOTIFICATION, this.index);
    };
    Object.defineProperty(OpponentViewMediator.prototype, "scene", {
        get: function () {
            return getScene(GameScene.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpponentViewMediator.prototype, "stateVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(StateVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    OpponentViewMediator.NAME = 'OpponentViewMediator';
    return OpponentViewMediator;
}(Mediator));
export default OpponentViewMediator;
