var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Facade } from '@candywings/pure-mvc';
import StartupCommand from './controller/StartupCommand';
import Game from './Game';
import BootSceneMediator from './view/scenes/BootSceneMediator';
import GameSceneMediator from './view/scenes/GameSceneMediator';
import LobbySceneMediator from './view/scenes/LobbySceneMediator';
import PopupSceneMediator from './view/scenes/PopupSceneMediator';
import PreloadSceneMediator from './view/scenes/PreloadSceneMediator';
import RegistrationSceneMediator from './view/scenes/RegistrationSceneMediator';
import RoomSceneMediator from './view/scenes/RoomSceneMediator';
import ServiceSceneMediator from './view/scenes/ServiceSceneMediator';
import StoreSceneMediator from './view/scenes/StoreSceneMediator';
import TopBarSceneMediator from './view/scenes/TopBarSceneMediator';
import WheelSceneMediator from './view/scenes/WheelSceneMediator';
var consoleArgs = [
    "",
    "background: " + '#c8c8ff',
    "background: " + '#9696ff',
    "color: " + '#ffffff' + "; background: " + '#0000ff' + ";",
    "background: " + '#9696ff',
    "background: " + '#c8c8ff',
];
var GameFacade = /** @class */ (function (_super) {
    __extends(GameFacade, _super);
    function GameFacade() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GameFacade.getInstance = function (key) {
        if (!Facade.instanceMap[key]) {
            var instance = new GameFacade(key);
            Facade.instanceMap[key] = instance;
        }
        return Facade.instanceMap[key];
    };
    GameFacade.onMobileBackButtonClick = function () {
        this.getInstance(Game.NAME).sendNotification(GameFacade.BACK_BUTTON_CLICKED_NOTIFICATION);
    };
    GameFacade.prototype.initializeFacade = function () {
        GameFacade.game.events.once(Phaser.Core.Events.READY, this.ready, this);
    };
    GameFacade.prototype.sendNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        consoleArgs[0] = "%c %c %c " + notificationName + (args.length > 0 ? ' | ' + args : '') + " %c %c ";
        console.log.apply(console, consoleArgs);
        _super.prototype.sendNotification.apply(this, [notificationName].concat(args));
    };
    GameFacade.prototype.initializeModel = function () {
        _super.prototype.initializeModel.call(this);
    };
    GameFacade.prototype.initializeController = function () {
        _super.prototype.initializeController.call(this);
        this.registerCommand(GameFacade.STARTUP_NOTIFICATION, StartupCommand);
    };
    GameFacade.prototype.initializeView = function () {
        _super.prototype.initializeView.call(this);
        this.registerMediator(new ServiceSceneMediator());
        this.registerMediator(new PopupSceneMediator());
        this.registerMediator(new BootSceneMediator());
        this.registerMediator(new PreloadSceneMediator());
        this.registerMediator(new RegistrationSceneMediator());
        this.registerMediator(new LobbySceneMediator());
        this.registerMediator(new GameSceneMediator());
        this.registerMediator(new TopBarSceneMediator());
        this.registerMediator(new RoomSceneMediator());
        this.registerMediator(new StoreSceneMediator());
        this.registerMediator(new WheelSceneMediator());
    };
    GameFacade.prototype.startup = function () {
        this.sendNotification(GameFacade.STARTUP_NOTIFICATION);
    };
    GameFacade.prototype.ready = function () {
        _super.prototype.initializeFacade.call(this);
        this.startup();
    };
    GameFacade.NAME = 'GameFacade';
    GameFacade.STARTUP_NOTIFICATION = GameFacade.NAME + "StartUpNotification";
    GameFacade.RESTART = GameFacade.NAME + "RestartNotification";
    GameFacade.NO_LOGIN_INFORMATION_NOTIFICATION = GameFacade.NAME + "NoLoginInformationNotification";
    GameFacade.LOGIN_SERVICE_SIGH_IN_SUCCESS_NOTIFICATION = GameFacade.NAME + "LoginServiceSignInSuccessNotification";
    GameFacade.LOGIN_SERVICE_SIGH_IN_FAIL_NOTIFICATION = GameFacade.NAME + "LoginServiceSignInFailNotification";
    GameFacade.BACK_BUTTON_CLICKED_NOTIFICATION = GameFacade.NAME + "BackButtonClickedNotification";
    return GameFacade;
}(Facade));
export default GameFacade;
