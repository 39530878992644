var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fonts, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { Setting } from '../../model/VO/PlayerVO';
import { Translation } from '../../translations';
import { getZone, isOk, isVk } from '../../utils/Utils';
import PopupTitle from '../components/popup/PopupTitle';
import { SpriteButton } from '../utils/simpleButton/SpriteButton';
import SettingsPopupLanguageSection from './settings/SettingsPopupLanguageSection';
import SettingsPopupSection from './settings/SettingsPopupSection';
import StandardPopup from './StandardPopup';
var SettingsPopup = /** @class */ (function (_super) {
    __extends(SettingsPopup, _super);
    function SettingsPopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SettingsPopup.prototype.createBody = function () {
        this.sections = [];
        this.createColoredBlocker(0.7);
        this.createBgImage(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.SettingsPopupBackground);
        this.createTitle();
        this.createCloseButton();
    };
    SettingsPopup.prototype.createSettings = function (configs) {
        var configurations = configs.slice();
        isOk() || (isVk() && configurations.pop());
        for (var _i = 0, configurations_1 = configurations; _i < configurations_1.length; _i++) {
            var config = configurations_1[_i];
            var section = void 0;
            if (configs.indexOf(config) === configs.length - 1) {
                section = new SettingsPopupLanguageSection(this.scene, config);
                section.setVisible(getZone() !== 'ok');
            }
            else {
                section = new SettingsPopupSection(this.scene, config);
            }
            this.add(section);
            this.sections.push(section);
        }
        this.positionSections();
    };
    SettingsPopup.prototype.updateSettings = function (settings) {
        var sound = this.sections.find(function (section) {
            return section.config.name === Setting.SOUND;
        });
        sound.updateStatus(settings.sound);
        var music = this.sections.find(function (section) {
            return section.config.name === Setting.MUSIC;
        });
        music.updateStatus(settings.music);
        var messages = this.sections.find(function (section) {
            return section.config.name === Setting.MESSAGES;
        });
        messages.updateStatus(settings.messages);
        if (!isOk() && !isVk()) {
            var language = this.sections.find(function (section) {
                return section.config.name === Setting.LANGUAGE;
            });
            language.updateStatus(settings.language);
        }
    };
    SettingsPopup.prototype.createTitle = function () {
        var config = {
            frame: MultiAtlases.Popups.Atlas.Frames.SettingsPopupTitle,
            fontFamily: Fonts.RobotoSlab.Name,
            text: Translation.SETTINGS_POPUP_TITLE,
            fontSize: 52,
            fill: '#c3a469',
            origin: { x: 0.5, y: 0.6 },
        };
        this.title = new PopupTitle(this.scene, config);
        this.title.y = -this.height * 0.45;
        this.add(this.title);
    };
    SettingsPopup.prototype.createCloseButton = function () {
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.CloseButtonUp,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.CloseButtonHover,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.CloseButtonDown,
        };
        this.closeButton = new SpriteButton(this.scene, {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        });
        this.closeButton.x = this.width * 0.45;
        this.closeButton.y = -this.height * 0.45;
        this.add(this.closeButton);
        this.closeButton.on(SpriteButton.CLICK_EVENT, this.onClick, this);
    };
    SettingsPopup.prototype.positionSections = function () {
        var titleFrame = this.scene.textures.getFrame(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.SettingsPopupTitle);
        var startY = titleFrame.height / 2;
        var gup = 17 * gameConfig.resolutionMultiplier;
        var totalHeight = this.sections.length * this.sections[0].height +
            (this.sections.length - 1) * gup;
        var line = new Phaser.Geom.Line(0, startY - totalHeight / 2, 0, startY + totalHeight / 2);
        var points = line.getPoints(this.sections.length);
        for (var i = 0; i < this.sections.length; i++) {
            this.sections[i].setPosition(points[i].x, points[i].y);
        }
    };
    SettingsPopup.prototype.onClick = function () {
        this.emit(SettingsPopup.ACTION_EVENT, SettingsPopup.ACTION_CLOSE);
    };
    SettingsPopup.NAME = "SettingsPopup";
    SettingsPopup.ACTION_CLOSE_WITH_BUTTON = 1;
    SettingsPopup.SETTING_CHANGED_NOTIFICATION = SettingsPopup.NAME + "SettingChangedNotification";
    return SettingsPopup;
}(StandardPopup));
export default SettingsPopup;
