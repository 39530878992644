var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Atlases, Audios } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import HomeButton from '../components/lobby/topbar/HomeButton';
import StoreButton from '../components/lobby/topbar/StoreButton';
import WheelButton from '../components/lobby/topbar/WheelButton';
import CrystalElement from '../components/topbar/CrystalElement';
import GoldElement from '../components/topbar/GoldElement';
import SilverElement from '../components/topbar/SilverElement';
import TopBarPLayerInfo from '../components/topbar/TopBarPlayerInfo';
import { postRunnable } from '../utils/phaser/PhaserUtils';
import BaseScene from './BaseScene';
export var TopBarAction;
(function (TopBarAction) {
    TopBarAction[TopBarAction["STORE"] = 0] = "STORE";
    TopBarAction[TopBarAction["WHEEL"] = 1] = "WHEEL";
    TopBarAction[TopBarAction["HOME"] = 2] = "HOME";
    TopBarAction[TopBarAction["GOLD"] = 3] = "GOLD";
    TopBarAction[TopBarAction["SILVER"] = 4] = "SILVER";
    TopBarAction[TopBarAction["CRYSTAL"] = 5] = "CRYSTAL";
    TopBarAction[TopBarAction["USER_INFO"] = 6] = "USER_INFO";
    TopBarAction[TopBarAction["STAR"] = 7] = "STAR";
    TopBarAction[TopBarAction["XP"] = 8] = "XP";
})(TopBarAction || (TopBarAction = {}));
var TopBarScene = /** @class */ (function (_super) {
    __extends(TopBarScene, _super);
    function TopBarScene() {
        return _super.call(this, TopBarScene.NAME) || this;
    }
    TopBarScene.prototype.updateAvatarTexture = function (key) {
        this.playerInfo.updateAvatar(key);
    };
    TopBarScene.prototype.applyRewards = function (rewardItems, updateConfig) {
        return __awaiter(this, void 0, void 0, function () {
            var rewardTypes, _i, rewardTypes_1, rewardType, items, target, valueUpdateData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        rewardTypes = Object.keys(rewardItems);
                        _i = 0, rewardTypes_1 = rewardTypes;
                        _a.label = 1;
                    case 1:
                        if (!(_i < rewardTypes_1.length)) return [3 /*break*/, 4];
                        rewardType = rewardTypes_1[_i];
                        items = rewardItems[rewardType];
                        target = this.getTarget(TopBarElement[rewardType.toUpperCase()]);
                        if (!!!target) return [3 /*break*/, 3];
                        valueUpdateData = updateConfig[rewardType];
                        return [4 /*yield*/, this.applyReward(target, items, valueUpdateData)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TopBarScene.prototype.applyReward = function (target, items, valueUpdateData) {
        return __awaiter(this, void 0, void 0, function () {
            var totalDuration, firstItem, itemMatrix, targetMatrix, _loop_1, this_1, _i, items_1, item;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        totalDuration = 0;
                        firstItem = items[0];
                        itemMatrix = firstItem.getWorldTransformMatrix();
                        targetMatrix = target.getWorldTransformMatrix();
                        _loop_1 = function (item) {
                            item.parentContainer.remove(item);
                            this_1.add.existing(item);
                            item.x = itemMatrix.tx;
                            item.y = itemMatrix.ty;
                            this_1.tweens.add({
                                targets: item,
                                x: targetMatrix.tx,
                                y: targetMatrix.ty,
                                alpha: 3,
                                ease: Phaser.Math.Easing.Expo.In,
                                delay: items.indexOf(item) * 100,
                                duration: 300,
                                onComplete: function () {
                                    _this.playSFX(Audios.CurrencyCreditSfx.Name);
                                    _this.tweens.killTweensOf(item);
                                    _this.tweens.add({
                                        targets: item,
                                        scaleX: 1.2,
                                        scaleY: 1.2,
                                        duration: 100,
                                        onComplete: function () {
                                            _this.tweens.add({
                                                targets: item,
                                                scaleX: 0,
                                                scaleY: 0,
                                                duration: 300,
                                                ease: Phaser.Math.Easing.Back.In,
                                                onComplete: function () {
                                                    item.destroy();
                                                },
                                            });
                                        },
                                    });
                                },
                            });
                        };
                        this_1 = this;
                        for (_i = 0, items_1 = items; _i < items_1.length; _i++) {
                            item = items_1[_i];
                            _loop_1(item);
                        }
                        totalDuration += items.length * 100 + 300;
                        return [4 /*yield*/, this.updateElement(target, valueUpdateData, totalDuration)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TopBarScene.prototype.updateCurrencies = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var updates, keys, _i, keys_1, key, currency;
            return __generator(this, function (_a) {
                updates = [];
                keys = Object.keys(data);
                for (_i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                    key = keys_1[_i];
                    currency = this.getCurrencyElementByType(key);
                    !!currency &&
                        updates.push(this.updateElement(currency, data[key]));
                }
                !!data.xp && updates.push(this.updateElement(this.playerInfo.xp, data.xp));
                !!data.level && this.playerInfo.updateLevel(data.level);
                return [2 /*return*/, Promise.all(updates)];
            });
        });
    };
    TopBarScene.prototype.enableInput = function () {
        this.input.enabled = true;
    };
    TopBarScene.prototype.disableInput = function () {
        this.input.enabled = false;
    };
    TopBarScene.prototype.create = function () {
        this.cameras.main.scrollY = 200;
        this.createBackground();
        this.createBackgroundShadow();
        this.createScopes();
        this.calculateButtonsPositions();
        this.createPlayerInfo();
        this.createCurrencies();
        this.createButtons();
    };
    TopBarScene.prototype.changeValue = function (target, startValue, amount) {
        if (startValue === void 0) { startValue = 0; }
        var tweenTarget = { value: startValue };
        this.tweens.add({
            targets: tweenTarget,
            value: tweenTarget.value + amount,
            duration: 1000,
            delay: 200,
            onUpdate: function () {
                target.updateValue(tweenTarget.value);
            },
        });
    };
    TopBarScene.prototype.setAvatarInputState = function (enabled) {
        this.playerInfo.flag.input.enabled = enabled;
    };
    TopBarScene.prototype.destroyWheelButton = function () {
        if (!this.wheelButton) {
            return;
        }
        this.tweens.killTweensOf(this.wheelButton);
        this.wheelButton.destroy();
        this.wheelButton = null;
    };
    TopBarScene.prototype.destroyStoreButton = function () {
        if (!this.storeButton) {
            return;
        }
        this.tweens.killTweensOf(this.storeButton);
        this.storeButton.destroy();
        this.storeButton = null;
    };
    TopBarScene.prototype.show = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        if (_this.cameras.main.scrollY === 0) {
                            resolve();
                            return;
                        }
                        _this.tweens.killTweensOf(_this.cameras.main);
                        _this.tweens.add({
                            targets: _this.cameras.main,
                            scrollY: 0,
                            duration: 500,
                            onComplete: function () {
                                _this.playerInfo.showFlag();
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    TopBarScene.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                            this.tweens.killTweensOf(this.cameras.main);
                            this.tweens.add({
                                targets: this.cameras.main,
                                scrollY: 200,
                                duration: 500,
                                onComplete: function () {
                                    _this.playerInfo.hideFlag();
                                    resolve();
                                },
                            });
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    // Button Animations :: START
    // STORE
    TopBarScene.prototype.showStoreButton = function () {
        if (!this.storeButton) {
            return;
        }
        this.tweens.killTweensOf(this.storeButton);
        this.storeButton.input.enabled = true;
        this.storeButton.setVisible(true);
        this.tweens.add({
            targets: this.storeButton,
            duration: 100,
            scaleX: 1,
            scaleY: 1,
        });
    };
    TopBarScene.prototype.hideStoreButton = function () {
        var _this = this;
        if (!this.storeButton) {
            return;
        }
        this.tweens.killTweensOf(this.storeButton);
        this.tweens.add({
            targets: this.storeButton,
            duration: 100,
            scaleX: 0,
            scaleY: 0,
            onComplete: function () {
                !!_this.storeButton && _this.storeButton.setVisible(false);
            },
        });
    };
    // WHEEL
    TopBarScene.prototype.showWheelButton = function () {
        if (!this.wheelButton) {
            return;
        }
        this.wheelButton.setVisible(true);
        this.tweens.killTweensOf(this.wheelButton);
        this.tweens.add({
            targets: this.wheelButton,
            duration: 100,
            scaleX: 1,
            scaleY: 1,
        });
    };
    TopBarScene.prototype.hideWheelButton = function () {
        var _this = this;
        if (!this.wheelButton) {
            return;
        }
        this.tweens.killTweensOf(this.wheelButton);
        this.tweens.add({
            targets: this.wheelButton,
            duration: 100,
            scaleX: 0,
            scaleY: 0,
            onComplete: function () {
                _this.wheelButton.setVisible(false);
            },
        });
    };
    // HOME
    TopBarScene.prototype.showHomeButton = function () {
        this.homeButton.setVisible(true);
        this.tweens.killTweensOf(this.homeButton);
        this.tweens.add({
            targets: this.homeButton,
            scaleX: 1,
            scaleY: 1,
            duration: 100,
        });
    };
    TopBarScene.prototype.hideHomeButton = function () {
        var _this = this;
        var duration = 100;
        this.tweens.killTweensOf(this.homeButton);
        this.tweens.add({
            targets: this.homeButton,
            scaleX: 0,
            scaleY: 0,
            duration: duration,
            onComplete: function () {
                _this.homeButton.setVisible(false);
            },
        });
    };
    // Button Animations :: END
    TopBarScene.prototype.setProgress = function (now, force) {
        this.playerInfo.xp.updateValue(now, force);
    };
    TopBarScene.prototype.updatePlayerInfo = function (level, name, id) {
        this.playerInfo.updateLevel(level);
        this.playerInfo.updateName(name);
        this.playerInfo.updateId(id);
    };
    // Creation :: START
    TopBarScene.prototype.createBackground = function () {
        var bgFrame = this.textures.getFrame(Atlases.Topbar.Atlas.Name + "-" + Atlases.Topbar.Atlas.Frames.Background);
        this.background = this.add.image(gameConfig.canvasWidth * 0.5, bgFrame.height * 0.5, Atlases.Topbar.Atlas.Name + "-" + Atlases.Topbar.Atlas.Frames.Background);
        this.background.depth = -80;
    };
    TopBarScene.prototype.createBackgroundShadow = function () {
        var frame = this.textures.getFrame(Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.BackgroundShadow);
        this.backgroundShadow = this.add.tileSprite(0, this.background.height - frame.height * 0.1, gameConfig.canvasWidth, frame.height, frame.texture.key, frame.name);
        this.backgroundShadow.setOrigin(0);
        this.children.sendToBack(this.backgroundShadow);
    };
    TopBarScene.prototype.createScopes = function () {
        this.leftScope = this.add.sprite(0, 0, Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.BackgroundCorner);
        this.leftScope.setScale(-1, 1);
        this.leftScope.y = this.leftScope.displayHeight / 2;
        this.leftScope.x = this.leftScope.displayWidth / 2;
        this.rightScope = this.add.sprite(0, 0, Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.BackgroundCorner);
        this.rightScope.setScale(1);
        this.rightScope.y = this.rightScope.displayHeight / 2;
        this.rightScope.x =
            gameConfig.canvasWidth - this.rightScope.displayWidth / 2;
    };
    TopBarScene.prototype.createPlayerInfo = function () {
        this.playerInfo = new TopBarPLayerInfo(this, Math.abs(this.leftScope.displayWidth) * 1.6, this.leftScope.displayHeight * 0.45);
        this.add.existing(this.playerInfo);
    };
    TopBarScene.prototype.createCurrencies = function () {
        this.createCurrencySection();
        this.createCurrencyElements();
        this.setCurrencyElementsPositions();
    };
    TopBarScene.prototype.createCurrencySection = function () {
        this.currencySection = this.add.sprite(gameConfig.canvasWidth / 2, this.background.displayHeight * 0.5, Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.CurrencySectionBackground);
        this.currencySection.x += this.currencySection.displayWidth * 0.15;
        this.currencySection.depth = -75;
    };
    TopBarScene.prototype.createCurrencyElements = function () {
        this.currencies = [];
        var gold = new GoldElement(this);
        var silver = new SilverElement(this);
        var crystal = new CrystalElement(this);
        this.currencies.push(gold, silver, crystal);
    };
    TopBarScene.prototype.setCurrencyElementsPositions = function () {
        var line = new Phaser.Geom.Line(this.currencySection.x - this.currencySection.displayWidth * 0.335, this.currencySection.y + this.currencySection.displayHeight * 0.05, this.currencySection.x + this.currencySection.displayWidth * 0.455, this.currencySection.y + this.currencySection.displayHeight * 0.05);
        var points = line.getPoints(this.currencies.length);
        for (var i = 0; i < this.currencies.length; i++) {
            this.currencies[i].x = points[i].x;
            this.currencies[i].y = points[i].y;
        }
    };
    TopBarScene.prototype.createButtons = function () {
        this.createStoreButton();
        this.createWheelButton();
        this.createHomeButton();
    };
    TopBarScene.prototype.createStoreButton = function () {
        this.storeButton = new StoreButton(this);
        this.storeButton.x = this.secondButtonPosition.x;
        this.storeButton.y = this.secondButtonPosition.y;
    };
    TopBarScene.prototype.createWheelButton = function () {
        this.wheelButton = new WheelButton(this);
        this.wheelButton.x = this.firstButtonPosition.x;
        this.wheelButton.y = this.firstButtonPosition.y;
    };
    TopBarScene.prototype.createHomeButton = function () {
        this.homeButton = new HomeButton(this);
        this.homeButton.setScale(0);
        this.homeButton.x = this.secondButtonPosition.x;
        this.homeButton.y = this.secondButtonPosition.y;
        this.homeButton.setVisible(false);
    };
    TopBarScene.prototype.calculateButtonsPositions = function () {
        var buttonFrame = this.textures.getFrame(Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.WheelButtonNormal);
        this.firstButtonPosition = new Phaser.Geom.Point(gameConfig.canvasWidth - buttonFrame.width * 1.1, this.background.displayHeight * 0.5);
        this.secondButtonPosition = new Phaser.Geom.Point(this.firstButtonPosition.x - buttonFrame.width * 1.2, this.background.displayHeight * 0.5);
    };
    // Creation :: END
    TopBarScene.prototype.getTarget = function (type) {
        var currencyElement = this.getCurrencyElementByType(type);
        return !!currencyElement ? currencyElement : this.playerInfo.xp;
    };
    TopBarScene.prototype.updateElement = function (element, data, duration) {
        if (duration === void 0) { duration = 200; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        if (data.force) {
                            element.updateValue(data.after);
                            postRunnable(function () {
                                element.fixStyle();
                            });
                            resolve();
                        }
                        else {
                            var tweenObject_1 = {
                                value: data.before || 0,
                            };
                            _this.tweens.add({
                                targets: tweenObject_1,
                                duration: duration,
                                value: data.after,
                                onUpdate: function () {
                                    element.updateValue(Math.floor(tweenObject_1.value));
                                },
                                onComplete: function () {
                                    postRunnable(resolve);
                                },
                            });
                        }
                    })];
            });
        });
    };
    TopBarScene.prototype.getCurrencyElementByType = function (type) {
        return this.currencies.find(function (currency) { return currency.config.type === type; });
    };
    TopBarScene.NAME = 'TopBarScene';
    TopBarScene.START = TopBarScene.NAME + "StartNotification";
    TopBarScene.STORE_BUTTON_CLICKED_NOTIFICATION = TopBarScene.NAME + "StoreButtonClickedNotification";
    TopBarScene.WHEEL_BUTTON_CLICKED_NOTIFICATION = TopBarScene.NAME + "WheelButtonClickedNotification";
    TopBarScene.NAVIGATION_BUTTON_CLICKED_NOTIFICATION = TopBarScene.NAME + "NavigationButtonClickedNotification";
    TopBarScene.CLAIM_ANIMATION_STARTED_NOTIFICATION = TopBarScene.NAME + "ClaimAnimationStartedNotification";
    TopBarScene.GAME_END_REWARD_CLAIMED_NOTIFICATION = TopBarScene.NAME + "GameEndClaimedNotification";
    TopBarScene.REWARDS_CLAIMED_NOTIFICATION = TopBarScene.NAME + "RewardsClaimedNotification";
    TopBarScene.CLAIM_ANIMATION_ENDED_NOTIFICATION = TopBarScene.NAME + "ClaimAnimationEndedNotification";
    TopBarScene.AVATAR_CLICKED_NOTIFICATION = TopBarScene.NAME + "AvatarClickedNotification";
    TopBarScene.SHOWN_NOTIFICATION = TopBarScene.NAME + "ShownNotification";
    TopBarScene.HIDDEN_NOTIFICATION = TopBarScene.NAME + "HiddenNotification";
    TopBarScene.ACTION_DONE_EVENT = 'actionDone';
    return TopBarScene;
}(BaseScene));
export default TopBarScene;
export var TopBarElement;
(function (TopBarElement) {
    TopBarElement["GOLD"] = "gold";
    TopBarElement["SILVER"] = "silver";
    TopBarElement["CRYSTAL"] = "crystal";
    TopBarElement["STAR"] = "star";
    TopBarElement["XP"] = "xp";
})(TopBarElement || (TopBarElement = {}));
