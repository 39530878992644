var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Mediator } from '@candywings/pure-mvc';
import SocketVOProxy from '../../../model/SocketVOProxy';
import StateVOProxy from '../../../model/StateVOProxy';
import InsufficientFundsPopup from '../../popups/InsufficientFundsPopup';
import GameScene from '../../scenes/GameScene';
import WaitingTimer from './WaitingTimer';
import WaitingView from './WaitingView';
var WaitingViewMediator = /** @class */ (function (_super) {
    __extends(WaitingViewMediator, _super);
    function WaitingViewMediator() {
        return _super.call(this, WaitingViewMediator.NAME, null) || this;
    }
    WaitingViewMediator.prototype.onRemove = function () {
        this.viewComponent && this.viewComponent.destroy();
        _super.prototype.onRemove.call(this);
    };
    WaitingViewMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(GameScene.REPLAY_NOTIFICATION, GameScene.CREATE_NOTIFICATION, GameScene.RECREATE_NOTIFICATION, GameScene.FORCE_START_NOTIFICATION, GameScene.LET_THE_GAME_START_NOTIFICATION, GameScene.PREPARE_TO_LEAVE_ROOM_NOTIFICATION, SocketVOProxy.ROOM_CHANNEL_JOIN_SUCCESS_NOTIFICATION, InsufficientFundsPopup.SHOW_START_NOTIFICATION);
    };
    WaitingViewMediator.prototype.handleNotification = function (notificationName) {
        switch (notificationName) {
            // case GameScene.REPLAY_NOTIFICATION:
            case GameScene.CREATE_NOTIFICATION:
            case GameScene.RECREATE_NOTIFICATION:
                var stateVOProxy = this.facade.retrieveProxy(StateVOProxy.NAME);
                if (stateVOProxy.vo.players.length || stateVOProxy.vo.reconnected) {
                    this.sendNotification(WaitingView.HIDDEN_NOTIFICATION);
                }
                else {
                    this.setView();
                    this.viewComponent.show();
                    this.sendNotification(WaitingView.SHOWN_NOTIFICATION);
                }
                break;
            case SocketVOProxy.ROOM_CHANNEL_JOIN_SUCCESS_NOTIFICATION:
            case InsufficientFundsPopup.SHOW_START_NOTIFICATION:
            case GameScene.FORCE_START_NOTIFICATION:
            case GameScene.LET_THE_GAME_START_NOTIFICATION:
            case GameScene.PREPARE_TO_LEAVE_ROOM_NOTIFICATION:
                if (!this.viewComponent) {
                    return;
                }
                this.viewComponent.hide();
                this.sendNotification(WaitingView.HIDDEN_NOTIFICATION);
                break;
            default:
                break;
        }
    };
    WaitingViewMediator.prototype.setView = function () {
        this.setViewComponent(new WaitingView());
        this.setViewComponentListeners();
    };
    WaitingViewMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.on(WaitingTimer.TIMER_COMPLETE_EVENT, this.onTimerComplete, this);
        this.viewComponent.on(WaitingView.EXIT_CLICKED_EVENT, this.onExitClick, this);
    };
    WaitingViewMediator.prototype.onTimerComplete = function () {
        this.viewComponent.showExitButton();
        this.sendNotification(WaitingView.TIMER_COMPLETE_NOTIFICATION);
    };
    WaitingViewMediator.prototype.onExitClick = function () {
        this.sendNotification(WaitingView.EXIT_CLICKED_NOTIFICATION);
    };
    WaitingViewMediator.NAME = 'WaitingViewMediator';
    return WaitingViewMediator;
}(Mediator));
export default WaitingViewMediator;
