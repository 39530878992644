var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Proxy } from '@candywings/pure-mvc';
import StoreVO from '../VO/StoreVO';
var BaseStoreVOProxy = /** @class */ (function (_super) {
    __extends(BaseStoreVOProxy, _super);
    function BaseStoreVOProxy() {
        return _super.call(this, BaseStoreVOProxy.NAME, new StoreVO()) || this;
    }
    BaseStoreVOProxy.prototype.onRegister = function () {
        this.sendNotification(BaseStoreVOProxy.REGISTERED_NOTIFICATION);
    };
    BaseStoreVOProxy.prototype.verifyUnConsumedProducts = function () {
        for (var _i = 0, _a = this.vo.buyFailedProducts; _i < _a.length; _i++) {
            var product = _a[_i];
            this.sendNotification(BaseStoreVOProxy.UNFINISHED_PURCHASE_NOTIFICATION, product);
        }
    };
    BaseStoreVOProxy.NAME = 'BaseStoreVOProxy';
    BaseStoreVOProxy.REGISTERED_NOTIFICATION = BaseStoreVOProxy.NAME + "RegisteredNotification";
    BaseStoreVOProxy.INITIALIZE_FAILED_NOTIFICATION = BaseStoreVOProxy.NAME + "InitializeFailedNotification";
    BaseStoreVOProxy.STORE_DATA_UPDATED_NOTIFICATION = BaseStoreVOProxy.NAME + "StoreDataUpdatedNotification";
    BaseStoreVOProxy.STORE_DATA_REMOVED_NOTIFICATION = BaseStoreVOProxy.NAME + "StoreDataRemovedNotification";
    BaseStoreVOProxy.PRODUCT_DOES_NOT_EXIST_NOTIFICATION = BaseStoreVOProxy.NAME + "ProductDoesn'tExistNotification";
    BaseStoreVOProxy.PRODUCT_BUY_PENDING_NOTIFICATION = BaseStoreVOProxy.NAME + "ProductBuyPendingNotification";
    BaseStoreVOProxy.PRODUCT_BUY_SUCCEED_NOTIFICATION = BaseStoreVOProxy.NAME + "ProductBuySucceedNotification";
    BaseStoreVOProxy.PRODUCT_CONSUME_SUCCEED_NOTIFICATION = BaseStoreVOProxy.NAME + "ProductConsumeSucceedNotification";
    BaseStoreVOProxy.PRODUCT_CONSUME_FAILED_NOTIFICATION = BaseStoreVOProxy.NAME + "ProductConsumeFailedNotification";
    BaseStoreVOProxy.PRODUCT_BUY_FAILED_NOTIFICATION = BaseStoreVOProxy.NAME + "ProductBuyFailedNotification";
    BaseStoreVOProxy.UNFINISHED_PURCHASE_NOTIFICATION = BaseStoreVOProxy.NAME + "UnFinishedPurchaseNotification";
    BaseStoreVOProxy.CHECK_NOT_COMPLETE_PRODUCTS_NOTIFICATION = BaseStoreVOProxy.NAME + "CheckNotCompleteProductsNotification";
    BaseStoreVOProxy.NOT_SUPPORTED_NOTIFICATION = BaseStoreVOProxy.NAME + "NotSupportedNotification";
    return BaseStoreVOProxy;
}(Proxy));
export default BaseStoreVOProxy;
