var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BitmapFonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
import { Gender } from '../../../../model/VO/PlayerVO';
import { Translation } from '../../../../translations';
import RegistrationScene from '../../../scenes/RegistrationScene';
import { VerticalScrollerContainer } from '../../../utils/scroll/VerticalScrollerContainer';
import SimpleButton from '../../../utils/simpleButton/SimpleButton';
import SimpleButtonWithBitmapText from '../../../utils/simpleButton/SimpleButtonWithBitmapText';
import { FinalizeProfileSceneAction } from '../RegistrationProfileInfoSection';
import AvatarScrollableLine from './AvatarScrollableLine';
var AvatarScrollableContainer = /** @class */ (function (_super) {
    __extends(AvatarScrollableContainer, _super);
    function AvatarScrollableContainer(scene, x, y, keys) {
        var _this = _super.call(this, scene, gameConfig.designWidth * 0.68, gameConfig.designHeight * 0.435, gameConfig.designHeight * 0.045, gameConfig.designHeight * 0.009) || this;
        _this.scene = scene;
        _this.keys = keys;
        _this.setPosition(x, y);
        _this.createBody();
        _this.setOkButtonListener();
        _this.prepare();
        return _this;
    }
    AvatarScrollableContainer.prototype.show = function (gender) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        for (var i = 0, length_1 = _this.scrollableList.length; i < length_1; i++) {
                            _this.removeScrollable(_this.scrollableList[length_1 - i - 1]);
                        }
                        var targets = {
                            width: _this.background.width,
                            height: _this.background.height,
                        };
                        _this.arrow.setVisible(true);
                        _this.scene.tweens.add({
                            targets: _this.arrow,
                            scaleX: 1,
                            scaleY: 1,
                            duration: 100,
                        });
                        _this.scene.tweens.add({
                            targets: _this.background,
                            scaleX: 1,
                            scaleY: 1,
                            duration: 100,
                            onComplete: function () {
                                _this.scene.tweens.add({
                                    targets: targets,
                                    width: gameConfig.canvasWidth * 0.68,
                                    height: gameConfig.designHeight * 0.435,
                                    duration: 200,
                                    onUpdate: function () {
                                        _this.setPosition(_this.background.x - _this.background.displayWidth / 2, _this.y);
                                    },
                                    onComplete: function () { return __awaiter(_this, void 0, void 0, function () {
                                        var targetLines, _i, targetLines_1, line, _a, targetLines_2, line;
                                        return __generator(this, function (_b) {
                                            switch (gender) {
                                                case Gender.MALE:
                                                    targetLines = this.maleLines;
                                                    break;
                                                case Gender.FEMALE:
                                                    targetLines = this.femaleLines;
                                                    break;
                                                default:
                                                    targetLines = this.uniLines;
                                                    break;
                                            }
                                            this.scene.tweens.killTweensOf(targetLines);
                                            for (_i = 0, targetLines_1 = targetLines; _i < targetLines_1.length; _i++) {
                                                line = targetLines_1[_i];
                                                this.addScrollAble(line, true);
                                            }
                                            for (_a = 0, targetLines_2 = targetLines; _a < targetLines_2.length; _a++) {
                                                line = targetLines_2[_a];
                                                line.show();
                                            }
                                            this.okButton.setPosition(this.background.x, this.background.y +
                                                this.background.displayHeight +
                                                this.okButton.height * 0.6);
                                            this.scene.tweens.add({
                                                targets: this.okButton,
                                                scaleX: 1,
                                                scaleY: 1,
                                                duration: 50,
                                            });
                                            resolve();
                                            return [2 /*return*/];
                                        });
                                    }); },
                                });
                            },
                        });
                    })];
            });
        });
    };
    AvatarScrollableContainer.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                        var _i, _a, line, bgFrame, targets;
                        var _this = this;
                        return __generator(this, function (_b) {
                            this.scene.tweens.add({
                                targets: this.okButton,
                                scaleX: 0,
                                scaleY: 0,
                                duration: 200,
                            });
                            this.scene.tweens.killTweensOf(this.scrollableList);
                            for (_i = 0, _a = this.scrollableList; _i < _a.length; _i++) {
                                line = _a[_i];
                                line.hide();
                            }
                            bgFrame = this.scene.textures.getFrame(MultiAtlases.Login.Atlas.Name, MultiAtlases.Login.Atlas.Frames.AvatarsContainerBackground);
                            targets = {
                                width: this.background.width,
                                height: this.background.height,
                            };
                            this.scene.tweens.add({
                                targets: targets,
                                width: bgFrame.width,
                                height: bgFrame.height,
                                duration: 100,
                                onUpdate: function () {
                                    _this.setPosition(_this.background.x - _this.background.width / 2, _this.y);
                                },
                                onComplete: function () {
                                    _this.scene.tweens.add({
                                        targets: _this.arrow,
                                        scaleX: 0,
                                        scaleY: 0,
                                        duration: 50,
                                        onComplete: function () {
                                            _this.arrow.setVisible(false);
                                        },
                                    });
                                    _this.scene.tweens.add({
                                        targets: _this.background,
                                        scaleX: 0,
                                        scaleY: 0,
                                        duration: 100,
                                        onComplete: function () {
                                            resolve();
                                        },
                                    });
                                },
                            });
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    AvatarScrollableContainer.prototype.createBody = function () {
        this.createBackground();
        this.createScrollableElements();
        this.createButton();
    };
    AvatarScrollableContainer.prototype.createBackground = function () {
        this.createArrow();
        this.createNinePatchBackground();
        this.scene.children.bringToTop(this.arrow);
    };
    AvatarScrollableContainer.prototype.createArrow = function () {
        this.arrow = this.scene.make.sprite({
            x: this.x,
            y: this.y,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.AvatarsBgArrow,
        }, true);
        this.arrow.setOrigin(0.5, 1);
    };
    AvatarScrollableContainer.prototype.createNinePatchBackground = function () {
        this.background = this.scene.make.ninePatch({
            x: this.x,
            y: this.y - 4,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.AvatarsContainerBackground,
            width: gameConfig.designWidth * 0.655,
            height: gameConfig.designHeight * 0.435,
        });
        this.background.setOrigin(0.5, 0);
        this.scene.add.existing(this.background);
    };
    AvatarScrollableContainer.prototype.createScrollableElements = function () {
        this.maleLines = [];
        this.femaleLines = [];
        this.uniLines = [];
        for (var i = 0; i < Math.ceil(this.keys.male.length / 4); i++) {
            var line = new AvatarScrollableLine(this.scene, {
                gender: Gender.MALE,
                keys: this.keys.male.slice().splice(i * 4, (i + 1) * 4),
            });
            this.maleLines.push(line);
        }
        for (var i = 0; i < Math.ceil(this.keys.female.length / 4); i++) {
            var line = new AvatarScrollableLine(this.scene, {
                gender: Gender.FEMALE,
                keys: this.keys.female.slice().splice(i * 4, (i + 1) * 4),
            });
            this.femaleLines.push(line);
        }
        for (var i = 0; i < Math.ceil(this.keys.uni.length / 4); i++) {
            var line = new AvatarScrollableLine(this.scene, {
                gender: Gender.NONE,
                keys: this.keys.uni.slice().splice(i * 4, (i + 1) * 4),
            });
            this.uniLines.push(line);
        }
    };
    AvatarScrollableContainer.prototype.createButton = function () {
        var textConfig = {
            text: Translation.LOGIN_AVATAR_CHOSE_OK,
            size: 60 * gameConfig.resolutionMultiplier,
            fill: 0x137f80,
            font: BitmapFonts.RobotoSlab.Name,
            origin: { x: 0.5, y: 0.7 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.OkButtonNormal,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.OkButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.OkButtonDown,
            textConfig: textConfig,
        };
        var config = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        this.okButton = new SimpleButtonWithBitmapText(this.scene, config);
        this.okButton.setScale(0);
        this.scene.add.existing(this.okButton);
    };
    AvatarScrollableContainer.prototype.prepare = function () {
        this.scene.input.dragDistanceThreshold = 20;
        this.arrow.setVisible(false);
        this.arrow.setScale(0);
        this.background.setScale(0);
    };
    AvatarScrollableContainer.prototype.setOkButtonListener = function () {
        this.okButton.on(SimpleButton.CLICK_EVENT, this.onOkClick, this);
    };
    AvatarScrollableContainer.prototype.onOkClick = function () {
        this.scene.events.emit(RegistrationScene.PROFILE_ACTION_EVENT, FinalizeProfileSceneAction.OK);
    };
    AvatarScrollableContainer.prototype.onScrollUpdate = function () {
        for (var _i = 0, _a = this.maleLines.concat(this.femaleLines, this.uniLines); _i < _a.length; _i++) {
            var line = _a[_i];
            line.update();
        }
        this.scene.children.bringToTop(this.okButton);
    };
    Object.defineProperty(AvatarScrollableContainer.prototype, "maskShape", {
        get: function () {
            return new Phaser.Geom.Rectangle(this.x, this.y, this.background ? this.background.width : 0, this.background ? this.background.height * 0.975 : 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarScrollableContainer.prototype, "isShown", {
        get: function () {
            return this.arrow.visible;
        },
        enumerable: true,
        configurable: true
    });
    return AvatarScrollableContainer;
}(VerticalScrollerContainer));
export default AvatarScrollableContainer;
