var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Images, Audios } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import PopupScene from '../scenes/PopupScene';
import { getScene } from '../utils/phaser/PhaserUtils';
var StandardPopup = /** @class */ (function (_super) {
    __extends(StandardPopup, _super);
    function StandardPopup(scene) {
        if (scene === void 0) { scene = getScene(PopupScene.NAME); }
        var _this = _super.call(this, scene, gameConfig.canvasWidth / 2, gameConfig.canvasHeight / 2) || this;
        _this.scene = scene;
        _this.isAlivePromise = new Promise(function (resolve) {
            _this.on(Phaser.GameObjects.Events.DESTROY, resolve);
        });
        _this.createBody();
        _this.scene.add.existing(_this);
        return _this;
    }
    StandardPopup.prototype.prepareToShow = function (x, y) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        this.x = x;
        this.y = y;
    };
    StandardPopup.prototype.prepareToHide = function (actionId) {
        this.hide(actionId);
    };
    StandardPopup.prototype.preDestroy = function () {
        this.blocker.destroy();
        _super.prototype.preDestroy.call(this);
    };
    StandardPopup.prototype.show = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.emit(StandardPopup.SHOW_START_EVENT);
                                    this.setAlpha(0);
                                    this.playShowSfx();
                                    return [4 /*yield*/, this.showBlocker()];
                                case 1:
                                    _a.sent();
                                    this.scene.tweens.add({
                                        targets: this,
                                        alpha: 1,
                                        duration: 200,
                                        ease: Phaser.Math.Easing.Expo.In,
                                        onComplete: function () {
                                            _this.onShowComplete.apply(_this, args);
                                            resolve();
                                        },
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    StandardPopup.prototype.hide = function (actionId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.emit(StandardPopup.HIDE_START_EVENT);
                        _this.playHideSfx();
                        _this.scene.tweens.add({
                            targets: _this,
                            alpha: 0,
                            duration: 200,
                            ease: Phaser.Math.Easing.Expo.In,
                            onComplete: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.hideBlocker()];
                                        case 1:
                                            _a.sent();
                                            this.visible = false;
                                            this.emit(StandardPopup.HIDE_COMPLETE_EVENT, actionId);
                                            resolve();
                                            return [2 /*return*/];
                                    }
                                });
                            }); },
                        });
                    })];
            });
        });
    };
    StandardPopup.prototype.playShowSfx = function () {
        this.scene.playSFX(Audios.PopupShowSfx.Name);
    };
    StandardPopup.prototype.playHideSfx = function () {
        this.scene.playSFX(Audios.PopupHideSfx.Name);
    };
    StandardPopup.prototype.showBlocker = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        if (!_this.blocker || !_this.blocker.active) {
                            return resolve();
                        }
                        _this.blocker.input.enabled = true;
                        var blockerAlpha = _this.blocker.alpha;
                        _this.blocker.setAlpha(0);
                        _this.scene.tweens.add({
                            targets: _this.blocker,
                            alpha: blockerAlpha,
                            duration: 200,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    StandardPopup.prototype.hideBlocker = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        if (!_this.blocker || !_this.blocker.active) {
                            return resolve();
                        }
                        _this.scene.tweens.add({
                            targets: _this.blocker,
                            alpha: 0,
                            duration: 200,
                            onComplete: function () {
                                if (_this.blocker) {
                                    _this.blocker.input.enabled = false;
                                    _this.blocker.visible = false;
                                    _this.blocker.destroy();
                                }
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    StandardPopup.prototype.createBody = function () {
        throw new Error("Method 'createBody' is not implemented in " + this.constructor.name);
    };
    StandardPopup.prototype.onShowComplete = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.scene.children.bringToTop(this);
        this.emit(StandardPopup.SHOW_COMPLETE_EVENT);
    };
    StandardPopup.prototype.createBg = function (key, frame, width, height) {
        var config = {
            key: key,
            frame: frame,
            width: width,
            height: height,
        };
        var bg = this.scene.make.ninePatch(config, false);
        bg.setInteractive();
        this.add(bg);
        this.setSize(bg.width, bg.height);
        return bg;
    };
    StandardPopup.prototype.createBgImage = function (key, frame) {
        var config = {
            key: key,
            frame: frame,
        };
        var bg = this.scene.make.image(config, false);
        bg.setInteractive();
        this.add(bg);
        this.setSize(bg.width, bg.height);
        return bg;
    };
    StandardPopup.prototype.createBlockerImage = function (key, frame, alpha) {
        if (alpha === void 0) { alpha = 1; }
        var config = {
            x: gameConfig.canvasWidth / 2,
            y: gameConfig.canvasHeight / 2,
            key: key,
            frame: frame,
        };
        this.blocker = this.scene.make.image(config);
        this.blocker.alpha = alpha;
        this.blocker.visible = false;
        this.blocker.setInteractive();
        this.setBlockerListeners();
        this.blocker.input.enabled = false;
    };
    StandardPopup.prototype.createColoredBlocker = function (alpha, color) {
        if (alpha === void 0) { alpha = 1; }
        if (color === void 0) { color = 0x000000; }
        this.blocker && this.blocker.destroy();
        this.blocker = this.scene.make.image({
            x: 0,
            y: 0,
            key: Images.WhitePixel.Name,
        });
        this.blocker.setScale(gameConfig.canvasWidth, gameConfig.canvasHeight);
        this.blocker.setOrigin(0);
        this.blocker.setTintFill(color);
        this.blocker.setAlpha(alpha);
        this.blocker.setInteractive();
        this.setBlockerListeners();
        this.blocker.input.enabled = false;
    };
    StandardPopup.prototype.setBlockerListeners = function () {
        this.blocker.on(Phaser.Input.Events.POINTER_UP, this.blockerPointerUp, this);
        this.blocker.on(Phaser.Input.Events.POINTER_DOWN, this.blockerPointerDown, this);
        this.blocker.on(Phaser.Input.Events.POINTER_OVER, this.blockerPointerOver, this);
        this.blocker.on(Phaser.Input.Events.POINTER_OUT, this.blockerPointerOut, this);
    };
    StandardPopup.prototype.createCancelButton = function (key, frame, x, y) {
        if (x === void 0) { x = this.width * 0.5; }
        if (y === void 0) { y = -this.height * 0.5; }
        var config = {
            x: x,
            y: y,
            key: key,
            frame: frame,
        };
        var closeBtn = this.scene.make.image(config);
        closeBtn.setInteractive();
        closeBtn.on(Phaser.Input.Events.POINTER_DOWN, this.emitClose, this);
        this.add(closeBtn);
    };
    StandardPopup.prototype.blockerPointerDown = function () {
        //
    };
    StandardPopup.prototype.blockerPointerUp = function () {
        //
    };
    StandardPopup.prototype.blockerPointerOver = function () {
        //
    };
    StandardPopup.prototype.blockerPointerOut = function () {
        //
    };
    StandardPopup.prototype.emitClose = function () {
        this.emit(StandardPopup.ACTION_EVENT, StandardPopup.ACTION_CLOSE);
    };
    StandardPopup.prototype.onAction = function (action) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.emit.apply(this, [StandardPopup.ACTION_EVENT, action].concat(args));
    };
    StandardPopup.NAME = 'StandardPopup';
    StandardPopup.ACTION_EVENT = StandardPopup.NAME + "ActionEvent";
    StandardPopup.ACTION_CLOSE = 0;
    StandardPopup.SHOW_START_NOTIFICATION = StandardPopup.NAME + "ShowStartNotification";
    StandardPopup.SHOW_COMPLETE_NOTIFICATION = StandardPopup.NAME + "ShowCompleteNotification";
    StandardPopup.HIDE_START_NOTIFICATION = StandardPopup.NAME + "HideStartNotification";
    StandardPopup.HIDE_COMPLETE_NOTIFICATION = StandardPopup.NAME + "HideCompleteNotification";
    StandardPopup.SHOW_START_EVENT = StandardPopup.NAME + "ShowStartEvent";
    StandardPopup.SHOW_COMPLETE_EVENT = StandardPopup.NAME + "ShowCompleteEvent";
    StandardPopup.HIDE_START_EVENT = StandardPopup.NAME + "HideStartEvent";
    StandardPopup.HIDE_COMPLETE_EVENT = StandardPopup.NAME + "HideCompleteEvent";
    return StandardPopup;
}(Phaser.GameObjects.Container));
export default StandardPopup;
