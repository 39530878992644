var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { msToTime } from '../../../model/helpers/utils';
import { StoreItemState } from '../../../model/VO/StoreVO';
import { upperCaseFirstLetter } from '../../../utils/Utils';
import StoreScene from '../../scenes/StoreScene';
import { hasFrame, loopRunnable, removeRunnable, } from '../../utils/phaser/PhaserUtils';
import StoreItemCount from './item/StoreItemCount';
import StoreItemDiscount from './item/StoreItemDiscount';
import StoreItemPrice from './item/StoreItemPrice';
import StoreItemTitle from './item/StoreItemTitle';
var StoreItem = /** @class */ (function (_super) {
    __extends(StoreItem, _super);
    function StoreItem(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.config = config;
        _this.createBody();
        return _this;
    }
    StoreItem.prototype.updateData = function (config) {
        this.config = config;
        // title
        if (this.config.name) {
            !!this.title
                ? this.title.setText("store-item-" + this.config.packSize)
                : this.createTitle();
        }
        else {
            !!this.title && this.title.destroy();
            this.title = null;
        }
        // frame
        if (!!this.frame) {
            if (this.config.state === StoreItemState.NORMAL) {
                this.frame.destroy();
                this.frame = null;
            }
            else {
                this.frame.setTexture(MultiAtlases.Store.Atlas.Name, this.config.state === StoreItemState.BEST_SELLER
                    ? MultiAtlases.Store.Atlas.Frames.BestSellerFrame
                    : MultiAtlases.Store.Atlas.Frames.BestValueFrame);
            }
        }
        else if (this.config.state !== StoreItemState.NORMAL) {
            this.createFrame();
        }
        // icon
        var iconFrame = MultiAtlases.Store.Atlas.Frames[upperCaseFirstLetter(this.config.section) + "Item" + this.config.iconIndex];
        var defaultIconFrame = MultiAtlases.Store.Atlas.Frames[upperCaseFirstLetter(this.config.section) + "Item0"];
        this.itemIcon.setTexture(MultiAtlases.Store.Atlas.Name, hasFrame(MultiAtlases.Store.Atlas.Name, iconFrame)
            ? iconFrame
            : defaultIconFrame);
        // discount
        this.discount && this.discount.destroy();
        this.config.multiplier !== 1 && this.createStoreItemDiscount();
        // price
        if (this.config.remaining && this.config.remaining > 0) {
            this.remainingMoment = Date.now() + (this.config.remaining || 0);
            this.updateRemaining();
            this.startRemainingTimer();
        }
        else {
            this.remainingMoment = null;
            this.price.updateText(this.config.price, this.config.action);
        }
    };
    StoreItem.prototype.show = function (delay) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.scene.tweens.killTweensOf(_this);
                        _this.scene.tweens.add({
                            targets: _this,
                            y: 0,
                            delay: delay,
                            duration: 400,
                            ease: Phaser.Math.Easing.Back.Out,
                            easeParams: [0.5],
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    StoreItem.prototype.hide = function (delay) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.scene.tweens.killTweensOf(_this);
                        _this.scene.tweens.add({
                            targets: _this,
                            y: gameConfig.canvasHeight,
                            delay: delay,
                            ease: Phaser.Math.Easing.Back.In,
                            easeParams: [0.5],
                            duration: 400,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    StoreItem.prototype.createBody = function () {
        this.createBackground();
        !!this.config.name && this.createTitle();
        this.createItemIcon();
        this.createStoreItemCount();
        this.config.multiplier !== 1 && this.createStoreItemDiscount();
        this.setSize(this.itemsCount.width, this.background.height);
        this.config.state !== StoreItemState.NORMAL && this.createFrame();
        this.createPrice();
        this.bringToTop(this.itemsCount);
        this.setListeners();
    };
    StoreItem.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames.ItemBackground,
        });
        this.add(this.background);
    };
    StoreItem.prototype.createTitle = function () {
        this.title = new StoreItemTitle(this.scene, "store-item-" + this.config.packSize);
        this.add(this.title);
        this.title.y = -this.background.height / 2 - this.title.height / 2 - 20;
    };
    StoreItem.prototype.createItemIcon = function () {
        var frame = MultiAtlases.Store.Atlas.Frames[upperCaseFirstLetter(this.config.section) + "Item" + this.config.iconIndex];
        var defaultFrame = MultiAtlases.Store.Atlas.Frames[upperCaseFirstLetter(this.config.section) + "Item0"];
        this.itemIcon = this.scene.make.image({
            x: 0,
            y: -this.background.height * 0.05,
            key: MultiAtlases.Store.Atlas.Name,
            frame: hasFrame(MultiAtlases.Store.Atlas.Name, frame)
                ? frame
                : defaultFrame,
        });
        this.add(this.itemIcon);
    };
    StoreItem.prototype.createFrame = function () {
        this.frame = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: this.config.state === StoreItemState.BEST_SELLER
                ? MultiAtlases.Store.Atlas.Frames.BestSellerFrame
                : MultiAtlases.Store.Atlas.Frames.BestValueFrame,
        });
        this.add(this.frame);
    };
    StoreItem.prototype.createStoreItemDiscount = function () {
        this.discount = new StoreItemDiscount(this.scene, this.config.value * this.config.multiplier);
        this.discount.y = this.itemsCount.y - this.discount.height * 0.64;
        this.add(this.discount);
    };
    StoreItem.prototype.createStoreItemCount = function () {
        var config = {
            type: this.config.type,
            count: this.config.value,
            multiplier: this.config.multiplier,
            section: this.config.section,
        };
        this.itemsCount = new StoreItemCount(this.scene, config);
        this.itemsCount.y = this.background.height * 0.4;
        this.add(this.itemsCount);
    };
    StoreItem.prototype.createPrice = function () {
        this.price = new StoreItemPrice(this.scene, this.config.price, this.config.action);
        if (this.config.remaining && this.config.remaining > 0) {
            this.remainingMoment = Date.now() + (this.config.remaining || 0);
            this.updateRemaining();
            this.startRemainingTimer();
        }
        this.add(this.price);
        this.price.y =
            this.itemsCount.y + this.itemsCount.height * 0.33 + this.price.height / 2;
    };
    StoreItem.prototype.setListeners = function () {
        this.setInteractive();
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
    };
    StoreItem.prototype.onPointerDown = function () {
        this.once(Phaser.Input.Events.POINTER_UP, this.onClick, this);
        this.once(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    StoreItem.prototype.onPointerOut = function () {
        this.off(Phaser.Input.Events.POINTER_UP, this.onClick, this);
        this.off(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    StoreItem.prototype.onClick = function () {
        this.scene.events.emit(StoreScene.STORE_ITEM_CLICK_EVENT, this.parentContainer.section, this.config);
    };
    StoreItem.prototype.startRemainingTimer = function () {
        this.timerRunnable && removeRunnable(this.timerRunnable);
        this.timerRunnable = loopRunnable(this.scene, 1000, this.updateRemaining, this);
    };
    StoreItem.prototype.updateRemaining = function () {
        var remaining = this.remainingMoment - Date.now();
        if (remaining > 0) {
            this.price.updateText(msToTime(remaining), this.config.action, true);
        }
        else {
            this.price.updateText(this.config.price, this.config.action);
            removeRunnable(this.timerRunnable);
        }
    };
    return StoreItem;
}(Phaser.GameObjects.Container));
export default StoreItem;
