var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { decrypt } from '../../utils/Crypto';
export function fixPlayersData(players) {
    var fixedPlayers = [];
    for (var _i = 0, players_1 = players; _i < players_1.length; _i++) {
        var player = players_1[_i];
        var fixedPlayer = {
            userId: player.userId,
            userIndex: 0,
            username: player.username,
            name: player.name,
            avatar: player.avatarUrl,
            wins: player.wins,
            draws: player.draws || 0,
            loses: player.loses,
            leaves: player.total - player.wins - player.loses - player.draws,
            total: player.total,
            level: player.level,
            gifts: player.gifts,
            gender: player.gender,
            rate: player.rate,
            isBot: player.isBot,
            cardsCount: player.cardsCount,
        };
        fixedPlayers.push(fixedPlayer);
    }
    return fixedPlayers;
}
export function sortPlayers(players, currentUserId) {
    while (players[3].userId !== currentUserId) {
        players.unshift(players.pop());
    }
    return players.map(function (player, index) {
        player.userIndex = index;
        return player;
    });
}
export function wrapRoomToStatePlayers(players, userCards, currentUserId) {
    var fixedPlayers = fixPlayersData(players);
    var statePlayers = [];
    for (var i = 0; i < fixedPlayers.length; i++) {
        var player = __assign({}, fixedPlayers[i], { cards: fixedPlayers[i].userId === currentUserId
                ? createCardsBasedOnData(userCards)
                : createCardsBasedOnCount(players[i].cardsCount), isAttacker: false, isDefender: false });
        statePlayers.push(player);
    }
    var sortedPlayers = sortPlayers(statePlayers, currentUserId);
    return sortedPlayers;
}
function createCardsBasedOnData(cardKeys) {
    return cardKeys.map(function (key) { return decrypt(key); });
}
function createCardsBasedOnCount(count) {
    var cards = [];
    for (var i = 0; i < count; i++) {
        cards.push(null);
    }
    return cards;
}
export function msToTime(ms) {
    var balance = Math.round(ms / 1000);
    var hours = Math.floor(balance / 3600);
    balance -= hours * 3600;
    var minutes = Math.floor(balance / 60);
    balance -= minutes * 60;
    return (hours >= 10 ? hours : '0' + hours) + " : " + (minutes >= 10 ? minutes : '0' + minutes) + " : " + (balance >= 10 ? balance : '0' + balance);
}
