var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MultiAtlases } from '../../../assets';
import { defaultAvatarPosition } from '../../../constants/Constants';
import { gameConfig } from '../../../constants/GameConfig';
import { getDataFromStorage, StorageKey, } from '../../../utils/wrappers/StorageWrapper';
import { EmojiType } from './control/scroller/MessageTypeButton';
import GameTimer from './GameTimer';
import PlayerGameAvatar from './PlayerGameAvatar';
import PlayerGameLevel from './PlayerGameLevel';
import PlayerGameName from './PlayerGameName';
import ToolTip, { ToolTipPosition, ToolTipParent } from './ToolTip';
var PlayerAvatarView = /** @class */ (function (_super) {
    __extends(PlayerAvatarView, _super);
    function PlayerAvatarView(scene, config) {
        var _this = _super.call(this, scene, 0, gameConfig.canvasHeight * 0.5) || this;
        _this.scene = scene;
        _this.config = config;
        _this.dragOffset = {
            x: 0,
            y: 0,
        };
        _this.createBody();
        _this.setDepth(11);
        _this.scene.add.existing(_this);
        var position = getDataFromStorage(StorageKey.AVATAR, defaultAvatarPosition);
        _this.setPosition(position.x * gameConfig.canvasWidth, position.y * gameConfig.canvasHeight);
        _this.setListeners();
        return _this;
    }
    PlayerAvatarView.prototype.showTimerHighLight = function () {
        this.timer.show();
    };
    PlayerAvatarView.prototype.hideTimerHighLight = function () {
        this.timer.hide();
    };
    PlayerAvatarView.prototype.setPlayTurnSfx = function (playTurnSfx) {
        this.timer.playTurnSfx = playTurnSfx;
    };
    PlayerAvatarView.prototype.showEmoji = function (type, key) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = type;
                        switch (_a) {
                            case EmojiType.EMOJI: return [3 /*break*/, 1];
                            case EmojiType.TEXT: return [3 /*break*/, 4];
                        }
                        return [3 /*break*/, 5];
                    case 1: return [4 /*yield*/, this.avatar.hideSmile()];
                    case 2:
                        _b.sent();
                        this.level.hide();
                        this.playerName.hide();
                        return [4 /*yield*/, this.avatar.showSmile("smile-" + key)];
                    case 3:
                        _b.sent();
                        this.level.show();
                        this.playerName.show();
                        return [3 /*break*/, 6];
                    case 4:
                        this.avatar.hideSmile();
                        this.tooltip.show(2000, "emoji-message-" + key);
                        return [3 /*break*/, 6];
                    case 5: return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PlayerAvatarView.prototype.show = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.avatar.show()];
                    case 1:
                        _a.sent();
                        this.playerName.show();
                        this.level.show();
                        this.scene.tweens.killTweensOf(this.flag);
                        this.scene.tweens.add({
                            targets: this.flag,
                            scaleY: 1,
                            duration: 500,
                            ease: Phaser.Math.Easing.Back.Out,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PlayerAvatarView.prototype.setPosition = function (x, y, z, w) {
        _super.prototype.setPosition.call(this, x, y, z, w);
        this.avatar && this.avatar.updateMask();
        this.timer && this.timer.updateMask();
        return this;
    };
    PlayerAvatarView.prototype.showTooltip = function (duration, text) {
        this.updateTooltipPosition();
        this.tooltip.show(duration, text);
    };
    PlayerAvatarView.prototype.hideTooltip = function () {
        this.tooltip.hide();
    };
    PlayerAvatarView.prototype.startTimer = function () {
        this.timer.start();
    };
    PlayerAvatarView.prototype.resumeTimer = function () {
        this.timer.resume();
    };
    PlayerAvatarView.prototype.stopTimer = function () {
        this.timer.stop();
    };
    PlayerAvatarView.prototype.pauseTimer = function () {
        this.timer.pause();
    };
    PlayerAvatarView.prototype.setTimerDuration = function (duration) {
        this.timer.setDuration(duration);
    };
    PlayerAvatarView.prototype.setTimerMode = function (type) {
        this.timer.prepare(type);
    };
    PlayerAvatarView.prototype.updateAvatar = function (key) {
        this.avatar.updateAvatar(key);
        this.avatar.updateMask();
    };
    PlayerAvatarView.prototype.updateTooltipPosition = function () {
        if (this.y <= this.height * 1.5) {
            this.tooltip.y = this.height * 0.77;
            this.tooltip.updatePosition(ToolTipPosition.BOTTOM);
        }
        else {
            this.tooltip.y = -this.height * 0.77;
            this.tooltip.updatePosition(ToolTipPosition.TOP);
        }
    };
    PlayerAvatarView.prototype.createBody = function () {
        this.createShadow();
        this.createBackgrounds();
        this.createFlag();
        this.createTimer();
        this.createAvatar();
        this.setSize(this.avatar.displayWidth, this.avatar.displayHeight);
        this.createLevel();
        this.createName();
        this.createTooltip();
    };
    PlayerAvatarView.prototype.createAvatar = function () {
        this.avatar = new PlayerGameAvatar(this.scene, this.config.key, this.config.isNative);
        this.add(this.avatar);
        this.avatar.updateMask();
    };
    PlayerAvatarView.prototype.createLevel = function () {
        this.level = new PlayerGameLevel(this.scene, this.config.level);
        this.add(this.level);
        this.level.x = this.width * 0.35;
        this.level.y = this.height * 0.35;
    };
    PlayerAvatarView.prototype.createName = function () {
        this.playerName = new PlayerGameName(this.scene, this.config.name);
        this.add(this.playerName);
        this.playerName.y = this.height * 0.7;
    };
    PlayerAvatarView.prototype.createTooltip = function () {
        this.tooltip = new ToolTip(this.scene, ToolTipPosition.TOP, ToolTipParent.PLAYER);
        this.tooltip.x = -this.width * 0.3;
        this.tooltip.y = -this.height * 0.77;
        this.add(this.tooltip);
    };
    PlayerAvatarView.prototype.createBackgrounds = function () {
        this.backgroundBig = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.PlayerBackgroundDecorationBig,
        });
        this.add(this.backgroundBig);
        this.backgroundSmall = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.PlayerBackgroundDecorationSmall,
        });
        this.add(this.backgroundSmall);
    };
    PlayerAvatarView.prototype.createShadow = function () {
        this.shadow = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.PlayerBackgroundDecorationShadow,
        });
        this.shadow.y = this.shadow.height * 0.045;
        this.add(this.shadow);
    };
    PlayerAvatarView.prototype.createFlag = function () {
        this.flag = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Flags.Atlas.Name,
            frame: MultiAtlases.Flags.Atlas.Frames.PlayerFlagLeagueNone,
        });
        this.flag.setOrigin(0.5, 0);
        this.add(this.flag);
        this.flag.setScale(1, 0);
    };
    PlayerAvatarView.prototype.createTimer = function () {
        this.timer = new GameTimer(this.scene);
        this.add(this.timer);
        this.timer.setPosition();
        this.timer.setScale(1.4);
        this.timer.on(GameTimer.COMPLETE_EVENT, this.onTimerComplete, this);
    };
    PlayerAvatarView.prototype.setListeners = function () {
        this.setSize(this.backgroundBig.width, this.backgroundBig.height);
        this.setInteractive();
        this.scene.input.setDraggable(this);
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
        this.on(Phaser.Input.Events.DRAG_START, this.onDragStart, this);
        this.on(Phaser.Input.Events.DRAG, this.onDrag, this);
        this.on(Phaser.Input.Events.DRAG_END, this.onDragEnd, this);
    };
    PlayerAvatarView.prototype.onDragStart = function (pointer) {
        this.dragOffset.x = this.x - pointer.x;
        this.dragOffset.y = this.y - pointer.y;
        this.hideBackground();
    };
    PlayerAvatarView.prototype.onDrag = function (pointer) {
        if (pointer.getDistance() <= 30) {
            return;
        }
        var trueX = pointer.x + this.dragOffset.x;
        var trueY = pointer.y + this.dragOffset.y;
        if (trueX > gameConfig.canvasWidth - this.width / 2) {
            trueX = gameConfig.canvasWidth - this.width / 2;
        }
        if (trueX < this.width / 2) {
            trueX = this.width / 2;
        }
        if (trueY > gameConfig.canvasHeight - this.height / 2) {
            trueY = gameConfig.canvasHeight - this.height / 2;
        }
        if (trueY < this.height / 2) {
            trueY = this.height / 2;
        }
        this.setPosition(trueX, trueY);
    };
    PlayerAvatarView.prototype.onDragEnd = function () {
        this.showBackground();
        this.emit(PlayerAvatarView.POSITION_CHANGED_EVENT);
    };
    PlayerAvatarView.prototype.onPointerDown = function () {
        this.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.once(Phaser.Input.Events.DRAG, this.clearClickEvents, this);
    };
    PlayerAvatarView.prototype.onPointerUp = function () {
        this.emit(PlayerAvatarView.AVATAR_CLICKED_EVENT);
        this.clearClickEvents();
    };
    PlayerAvatarView.prototype.clearClickEvents = function () {
        this.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.off(Phaser.Input.Events.DRAG, this.clearClickEvents, this);
    };
    PlayerAvatarView.prototype.onTimerComplete = function () {
        this.emit(PlayerAvatarView.TIMER_COMPLETE_EVENT);
    };
    PlayerAvatarView.prototype.showBackground = function () {
        var _this = this;
        this.scene.tweens.killTweensOf([
            this.backgroundBig,
            this.backgroundSmall,
            this.shadow,
        ]);
        this.scene.tweens.add({
            targets: this.backgroundSmall,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 100,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: [_this.backgroundBig, _this.shadow],
                    scaleX: 1,
                    scaleY: 1,
                    ease: Phaser.Math.Easing.Expo.InOut,
                    duration: 100,
                });
            },
        });
    };
    PlayerAvatarView.prototype.hideBackground = function () {
        var _this = this;
        this.scene.tweens.killTweensOf([
            this.backgroundBig,
            this.backgroundSmall,
            this.shadow,
        ]);
        this.scene.tweens.add({
            targets: [this.backgroundBig, this.shadow],
            scaleX: 0,
            scaleY: 0,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 100,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: [_this.backgroundSmall],
                    scaleX: 0,
                    scaleY: 0,
                    ease: Phaser.Math.Easing.Expo.InOut,
                    duration: 100,
                });
            },
        });
    };
    PlayerAvatarView.NAME = 'PlayerAvatarView';
    PlayerAvatarView.TIMER_COMPLETE_EVENT = "timerComplete";
    PlayerAvatarView.AVATAR_CLICKED_EVENT = "avatarClicked";
    PlayerAvatarView.POSITION_CHANGED_EVENT = "positionChangedEvent";
    PlayerAvatarView.TIMER_COMPLETE_NOTIFICATION = PlayerAvatarView.NAME + "TimerCompleteNotification";
    PlayerAvatarView.AVATAR_CLICKED_NOTIFICATION = PlayerAvatarView.NAME + "AvatarClickedNotification";
    PlayerAvatarView.POSITION_CHANGED_NOTIFICATION = PlayerAvatarView.NAME + "PositionChangedEvent";
    return PlayerAvatarView;
}(Phaser.GameObjects.Container));
export default PlayerAvatarView;
