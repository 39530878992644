var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Atlases, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import Door from '../components/doors/Door';
import SettingsButton from '../components/lobby/SettingsButton';
import BaseScene from './BaseScene';
var RoomScene = /** @class */ (function (_super) {
    __extends(RoomScene, _super);
    function RoomScene() {
        var _this = _super.call(this, RoomScene.NAME) || this;
        _this.doors = [];
        return _this;
    }
    RoomScene.prototype.create = function () {
        this.createBackground();
        this.createSettingsButton();
        this.input.setTopOnly(true);
    };
    RoomScene.prototype.show = function () {
        for (var _i = 0, _a = this.doors; _i < _a.length; _i++) {
            var section = _a[_i];
            section.show();
        }
    };
    RoomScene.prototype.hide = function () {
        for (var _i = 0, _a = this.doors; _i < _a.length; _i++) {
            var section = _a[_i];
            section.hide();
        }
    };
    RoomScene.prototype.createSections = function (roomsVO, level) {
        var names = Object.keys(roomsVO);
        names.remove('bonus', 'bot');
        for (var _i = 0, names_1 = names; _i < names_1.length; _i++) {
            var name_1 = names_1[_i];
            var roomData = roomsVO[name_1];
            var config = __assign({ name: name_1, isActive: false, isAvailable: level >= roomData.minLevel }, roomData);
            var mode = new Door(this, config);
            this.add.existing(mode);
            this.doors.push(mode);
        }
        this.positionSections();
    };
    RoomScene.prototype.createBackground = function () {
        this.background = this.make.image({
            x: gameConfig.canvasWidth / 2,
            y: gameConfig.canvasHeight,
            key: MultiAtlases.Lobby.Atlas.Name,
            frame: MultiAtlases.Lobby.Atlas.Frames.Background,
        });
        this.background.setOrigin(0.5, 1);
        this.background.setScale(Math.max(gameConfig.canvasWidth / this.background.width, gameConfig.canvasHeight / this.background.height));
    };
    RoomScene.prototype.createSettingsButton = function () {
        this.settingsButton = new SettingsButton(this);
        this.add.existing(this.settingsButton);
        this.settingsButton.x = this.settingsButton.width * 0.76;
        this.settingsButton.y =
            gameConfig.canvasHeight - this.settingsButton.width * 0.7;
    };
    RoomScene.prototype.positionSections = function () {
        var topBarBgFrame = this.textures.getFrame(Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.Background);
        var y = (gameConfig.canvasHeight * 0.9 + topBarBgFrame.height) / 2;
        var line = new Phaser.Geom.Line(0, y, gameConfig.canvasWidth, y);
        var points = line.getPoints(this.doors.length + 1);
        points.shift();
        for (var i = 0; i < this.doors.length; i++) {
            this.doors[i].setPosition(points[i].x, points[i].y);
        }
    };
    RoomScene.NAME = 'RoomScene';
    RoomScene.DOOR_SELECTED_NOTIFICATION = RoomScene.NAME + "DoorSelectedNotification";
    RoomScene.SHOW_COMPLETE_NOTIFICATION = RoomScene.NAME + "ShowCompleteNotification";
    RoomScene.HIDE_COMPLETE_NOTIFICATION = RoomScene.NAME + "HideCompleteNotification";
    RoomScene.SWITCHING_TO_GAME_SCENE = RoomScene.NAME + "SwitchingToGameScene";
    RoomScene.SETTINGS_CLICKED_NOTIFICATION = RoomScene.NAME + "SettingsClickedNotification";
    RoomScene.DOOR_SELECTED_EVENT = 'gameSelected';
    return RoomScene;
}(BaseScene));
export default RoomScene;
