var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../assets';
var RegistrationProfileLanguageSectionDecoration = /** @class */ (function (_super) {
    __extends(RegistrationProfileLanguageSectionDecoration, _super);
    function RegistrationProfileLanguageSectionDecoration(scene) {
        var _this = _super.call(this, scene) || this;
        _this.createBody();
        return _this;
    }
    RegistrationProfileLanguageSectionDecoration.prototype.hide = function () {
        var _this = this;
        this.scene.tweens.add({
            targets: [this.leftLine, this.rightLine],
            scaleX: 0,
            duration: 100,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: _this,
                    scaleX: 0,
                    scaleY: 0,
                    ease: Phaser.Math.Easing.Back.In,
                    duration: 150,
                    onComplete: function () {
                        _this.destroy();
                    },
                });
            },
        });
    };
    RegistrationProfileLanguageSectionDecoration.prototype.show = function () {
        var _this = this;
        this.scene.tweens.add({
            targets: this.diamond,
            scaleX: 1,
            scaleY: 1,
            duration: 300,
            ease: Phaser.Math.Easing.Back.Out,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: _this,
                    angle: 450,
                    duration: 1500,
                    ease: Phaser.Math.Easing.Expo.InOut,
                });
                _this.scene.tweens.add({
                    targets: [_this.leftLine, _this.rightLine],
                    scaleX: 1,
                    delay: 250,
                    duration: 1000,
                    onComplete: function () {
                        var leftCenter = _this.leftLine.getCenter();
                        var rightCenter = _this.rightLine.getCenter();
                        _this.leftLine.x = leftCenter.x;
                        _this.leftLine.setOrigin(0.5);
                        _this.rightLine.x = rightCenter.x;
                        _this.rightLine.setOrigin(0.5);
                        _this.scene.tweens.add({
                            targets: [_this.leftLine, _this.rightLine],
                            delay: 200,
                            scaleX: 0,
                            duration: 250,
                            onComplete: function () {
                                _this.emit(RegistrationProfileLanguageSectionDecoration.SHOW_FIRST_PART_COMPLETE_EVENT, _this.leftLine.x / 2, _this.rightLine.x / 2);
                                _this.angle = 0;
                                _this.leftLine.x = -_this.leftLine.width * 0.1;
                                _this.rightLine.x = _this.rightLine.width * 0.1;
                                _this.leftLine.setOrigin(1, 0.5);
                                _this.rightLine.setOrigin(0, 0.5);
                                _this.scene.tweens.add({
                                    targets: [_this.leftLine, _this.rightLine],
                                    duration: 200,
                                    scaleX: 1,
                                });
                            },
                        });
                    },
                });
            },
        });
    };
    RegistrationProfileLanguageSectionDecoration.prototype.createBody = function () {
        this.createDiamond();
        this.createLines();
        this.prepare();
    };
    RegistrationProfileLanguageSectionDecoration.prototype.createDiamond = function () {
        this.diamond = this.scene.make.sprite({
            x: 0,
            y: 0,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.Diamond,
        });
        this.add(this.diamond);
    };
    RegistrationProfileLanguageSectionDecoration.prototype.createLines = function () {
        this.createLeftLine();
        this.createRightLine();
    };
    RegistrationProfileLanguageSectionDecoration.prototype.createLeftLine = function () {
        this.leftLine = this.scene.make.sprite({
            x: 0,
            y: 0,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.Line,
        });
        this.leftLine.x = -this.leftLine.width * 0.1;
        this.leftLine.setOrigin(1, 0.5);
        this.add(this.leftLine);
    };
    RegistrationProfileLanguageSectionDecoration.prototype.createRightLine = function () {
        this.rightLine = this.scene.make.sprite({
            x: 0,
            y: 0,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.Line,
        });
        this.rightLine.x = this.rightLine.width * 0.1;
        this.rightLine.setOrigin(0, 0.5);
        this.add(this.rightLine);
    };
    RegistrationProfileLanguageSectionDecoration.prototype.prepare = function () {
        this.diamond.setScale(0);
        this.leftLine.setScale(0, 1);
        this.rightLine.setScale(0, 1);
    };
    RegistrationProfileLanguageSectionDecoration.SHOW_FIRST_PART_COMPLETE_EVENT = 'showFirstPartComplete';
    return RegistrationProfileLanguageSectionDecoration;
}(Phaser
    .GameObjects.Container));
export default RegistrationProfileLanguageSectionDecoration;
