var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
import { Translation } from '../../../../translations';
import SimpleButtonWithBitmapText from '../../../utils/simpleButton/SimpleButtonWithBitmapText';
var LevelUpPopupButton = /** @class */ (function (_super) {
    __extends(LevelUpPopupButton, _super);
    function LevelUpPopupButton(scene) {
        var _this = this;
        var textConfig = {
            font: BitmapFonts.RobotoSlab.Name,
            size: 60 * gameConfig.resolutionMultiplier,
            fill: 0xfffedc,
            origin: { x: 0.5, y: 0.6 },
            text: Translation.LEVEL_UP_POPUP_BUTTON,
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.LevelUpPopupButtonUp,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.LevelUpPopupButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.LevelUpPopupButtonDown,
            textConfig: textConfig,
        };
        _this = _super.call(this, scene, {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        }) || this;
        _this.createGlow();
        return _this;
    }
    LevelUpPopupButton.prototype.createGlow = function () {
        this.glow = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.LevelUpPopupButtonGlow,
        });
        this.add(this.glow);
        this.sendToBack(this.glow);
    };
    return LevelUpPopupButton;
}(SimpleButtonWithBitmapText));
export default LevelUpPopupButton;
