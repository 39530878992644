var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Atlases, Fonts } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { formatValue } from '../../../utils/Utils';
import AbstractTopBarValueElement from './AbstractTopBarValueElement';
var CurrencyElement = /** @class */ (function (_super) {
    __extends(CurrencyElement, _super);
    function CurrencyElement(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        _this.scene.add.existing(_this);
        _this.setListeners();
        return _this;
    }
    CurrencyElement.prototype.updateValue = function (value) {
        this.config.value = value;
        this.text.setText("" + formatValue(this.config.value, 1, true));
        this.setSize(this.icon.width * 1.5 + this.text.width, this.icon.height);
    };
    CurrencyElement.prototype.fixStyle = function () {
        var style = {
            fontFamily: Fonts.AvenirNextMedium.Name,
            fontSize: 34 * gameConfig.resolutionMultiplier,
            fill: '#ffedcb',
        };
        this.text.setStyle(style);
        this.text.setText(this.text.text);
    };
    CurrencyElement.prototype.createBody = function () {
        this.createBackground();
        this.createIcon();
        this.createText();
        this.setSize(this.icon.width * 1.5 + this.text.width, this.icon.height);
    };
    CurrencyElement.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Topbar.Atlas.Name,
            frame: Atlases.Topbar.Atlas.Frames.CurrencyBackground,
        });
        this.background.y = -this.background.height * 0.07;
        this.add(this.background);
    };
    CurrencyElement.prototype.createIcon = function () {
        this.icon = this.scene.make.image({
            x: 0,
            y: -this.background.height * 0.065,
            key: Atlases.Topbar.Atlas.Name,
            frame: this.config.iconFrame,
        });
        this.add(this.icon);
    };
    CurrencyElement.prototype.createText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextMedium.Name,
            fontSize: 34 * gameConfig.resolutionMultiplier,
            fill: '#ffedcb',
        };
        this.text = this.scene.make.text({
            x: this.background.width * 0.35,
            y: -this.background.height * 0.03,
            text: "" + this.config.value,
            style: style,
        });
        this.text.setOrigin(0, 0.6);
        this.add(this.text);
    };
    CurrencyElement.prototype.setListeners = function () {
        this.background.setInteractive();
        this.background.on(Phaser.Input.Events.POINTER_UP, this.onClick, this);
    };
    CurrencyElement.prototype.onClick = function () {
        //
    };
    return CurrencyElement;
}(AbstractTopBarValueElement));
export default CurrencyElement;
