var RoomsVO = /** @class */ (function () {
    function RoomsVO() {
    }
    return RoomsVO;
}());
export default RoomsVO;
export var Room;
(function (Room) {
    Room["SMALL"] = "small";
    Room["MEDIUM"] = "medium";
    Room["BIG"] = "big";
    Room["BONUS"] = "bonus";
    Room["BOT"] = "bot";
})(Room || (Room = {}));
