var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ApiVOProxy from '../../model/ApiVOProxy';
import PlayerVOProxy from '../../model/PlayerVOProxy';
import { clearDocumentBackgroundImage } from '../../utils/DomUtils';
import RewardPopup from '../popups/RewardPopup';
import BaseSceneMediator from './BaseSceneMediator';
import TopBarScene from './TopBarScene';
import WheelScene from './WheelScene';
var WheelSceneMediator = /** @class */ (function (_super) {
    __extends(WheelSceneMediator, _super);
    function WheelSceneMediator() {
        return _super.call(this, WheelSceneMediator.NAME, null) || this;
    }
    WheelSceneMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(TopBarScene.WHEEL_BUTTON_CLICKED_NOTIFICATION, RewardPopup.HIDDEN_NOTIFICATION);
    };
    WheelSceneMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var wheelDataArray;
            return __generator(this, function (_a) {
                this.handleDefaultNotifications.apply(this, [notificationName].concat(args));
                switch (notificationName) {
                    case TopBarScene.WHEEL_BUTTON_CLICKED_NOTIFICATION:
                        this.fadeScreenIn(null, null, true);
                        this.sceneManager.start(WheelScene.NAME);
                        wheelDataArray = this.playerVOProxy.vo
                            .wheelData.data;
                        this.viewComponent.createWheelSections(wheelDataArray);
                        this.viewComponent.show();
                        this.sendNotification(WheelScene.SHOWN_NOTIFICATION);
                        this.getPrize();
                        break;
                    case RewardPopup.HIDDEN_NOTIFICATION:
                        this.sceneManager.isActive(WheelScene.NAME) &&
                            this.viewComponent.hide();
                        break;
                    default:
                        if (this.defaultNotifications.contains(notificationName)) {
                            break;
                        }
                        console.warn(notificationName + " is unhandled!");
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    WheelSceneMediator.prototype.setView = function () {
        var wheelScene = new WheelScene();
        this.sceneManager.add(WheelScene.NAME, wheelScene);
        this.setViewComponent(wheelScene);
    };
    WheelSceneMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.events.on(WheelScene.SHOW_WHEEL_ELEMENTS_EVENT, this.onShowWheelElements, this);
        this.viewComponent.events.on(WheelScene.READY_TO_DESTROY_EVENT, this.onReadyToDestroy, this);
        this.viewComponent.events.on(WheelScene.ALLOW_KNIFE_EVENT, this.allowKnife, this);
        this.viewComponent.events.on(WheelScene.KNIFE_THROW_EVENT, this.onKnifeThrown, this);
        this.viewComponent.events.on(WheelScene.WHEEL_ROTATION_COMPLETED_EVENT, this.onWheelRotationComplete, this);
    };
    WheelSceneMediator.prototype.onSceneWake = function () {
        clearDocumentBackgroundImage();
        _super.prototype.onSceneWake.call(this);
    };
    WheelSceneMediator.prototype.onShowWheelElements = function () {
        this.viewComponent.showWheelElements();
    };
    WheelSceneMediator.prototype.allowKnife = function () {
        this.viewComponent.showKnife();
    };
    WheelSceneMediator.prototype.onKnifeThrown = function () {
        var apiVOProxy = this.facade.retrieveProxy(ApiVOProxy.NAME);
        this.viewComponent.onKnifeThrow(apiVOProxy.vo.wheelData.segment);
    };
    WheelSceneMediator.prototype.getPrize = function () {
        this.sendNotification(WheelScene.KNIFE_READY_TO_THROW_NOTIFICATION);
    };
    WheelSceneMediator.prototype.onWheelRotationComplete = function () {
        this.sendNotification(WheelScene.WHEEL_ROTATED_NOTIFICATION);
    };
    WheelSceneMediator.prototype.onReadyToDestroy = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fadeScreenOut()];
                    case 1:
                        _a.sent();
                        this.sendNotification(WheelScene.READY_TO_DESTROY_NOTIFICATION);
                        this.sceneManager.stop(WheelScene.NAME);
                        this.sceneManager.remove(WheelScene.NAME);
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(WheelSceneMediator.prototype, "playerVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(PlayerVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    WheelSceneMediator.NAME = 'WheelSceneMediator';
    return WheelSceneMediator;
}(BaseSceneMediator));
export default WheelSceneMediator;
