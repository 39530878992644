var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, Fonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
import { Translation } from '../../../../translations';
import { getZone } from '../../../../utils/Utils';
import SimpleButtonWithBitmapText from '../../../utils/simpleButton/SimpleButtonWithBitmapText';
import UserInfoPopup, { UserInfoPopupAction, } from '../../UserInfoPopup';
import UserInfoStatisticDiagramElement from './UserInfoStatisticDiagramElement';
import UserInfoStatisticElement from './UserInfoStatisticElement';
var UserInfoProfileContent = /** @class */ (function (_super) {
    __extends(UserInfoProfileContent, _super);
    function UserInfoProfileContent(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        return _this;
    }
    UserInfoProfileContent.prototype.updateMask = function () {
        this.winStatistic.updateMask();
    };
    UserInfoProfileContent.prototype.createBody = function () {
        this.createStatisticsSection();
        this.createNameSection();
        // this.config.logoutButtonEnabled && this.createLogoutButton();
        this.createGamePlayedTextBackground();
        this.createGamePlayedText();
        this.createGamePlayedCountTextBackground();
        this.createGamePlayedCountText();
        this.setListeners();
    };
    UserInfoProfileContent.prototype.createNameSection = function () {
        this.createNameSectionBackground();
        this.createNameText();
        this.createIdText();
    };
    UserInfoProfileContent.prototype.createNameText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextBold.Name,
            fontSize: 58 * gameConfig.resolutionMultiplier,
            fill: '#ffedcb',
        };
        this.nameText = this.scene.make.extText({
            x: this.nameBackground.x - this.nameBackground.width * 0.425,
            y: this.nameBackground.y + this.nameBackground.height * 0.01,
            text: this.config.name.split(' ', 1)[0],
            style: style,
        });
        this.nameText.setOrigin(0, 0.6);
        this.nameText.setTint(0xffedcb);
        this.add(this.nameText);
    };
    UserInfoProfileContent.prototype.createIdText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextMedium.Name,
            fontSize: 30 * gameConfig.resolutionMultiplier,
            fill: '#ffedcb',
        };
        this.idText = this.scene.make.extText({
            x: this.nameText.x +
                this.nameText.width +
                30 * gameConfig.resolutionMultiplier,
            y: this.nameBackground.y + this.nameBackground.height * 0.045,
            text: Translation.USER_INFO_POPUP_PROFILE_ID,
            style: style,
            i18nOptions: {
                id: this.config.userId,
            },
        });
        this.idText.setOrigin(0, 0.5);
        this.add(this.idText);
    };
    UserInfoProfileContent.prototype.createGamePlayedTextBackground = function () {
        this.gamePlayedTextBackground = this.scene.make.image({
            x: this.nameBackground.x + this.nameBackground.width * 0.285,
            y: this.nameBackground.y - this.nameBackground.height * 0.03,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupGamesPlayedTextBackground,
        });
        this.add(this.gamePlayedTextBackground);
    };
    UserInfoProfileContent.prototype.createGamePlayedText = function () {
        var style = {
            fontFamily: Fonts.RobotoSlab.Name,
            fontSize: 30 * gameConfig.resolutionMultiplier,
            fill: '#ffedcb',
        };
        this.gamePlayedText = this.scene.make.extText({
            x: this.gamePlayedTextBackground.x -
                this.gamePlayedTextBackground.width * 0.44,
            y: this.gamePlayedTextBackground.y,
            text: Translation.USER_INFO_POPUP_PROFILE_PLAYED_GAMES,
            style: style,
        });
        this.gamePlayedText.setOrigin(0, 0.45);
        this.gamePlayedText.setTint(0xe8ac42);
        this.add(this.gamePlayedText);
    };
    UserInfoProfileContent.prototype.createGamePlayedCountTextBackground = function () {
        this.gamePlayedCountTextBackground = this.scene.make.image({
            x: this.gamePlayedTextBackground.x +
                this.gamePlayedTextBackground.width * 0.58,
            y: this.gamePlayedTextBackground.y +
                this.gamePlayedTextBackground.height * 0.05,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames
                .UserInfoPopupGamesPlayedCountBackground,
        });
        this.add(this.gamePlayedCountTextBackground);
    };
    UserInfoProfileContent.prototype.createGamePlayedCountText = function () {
        var style = {
            fontFamily: Fonts.RobotoSlab.Name,
            fontSize: 56 * gameConfig.resolutionMultiplier,
            fill: '#e6a941',
        };
        this.gamePlayedCountText = this.scene.make.text({
            x: this.gamePlayedCountTextBackground.x,
            y: this.gamePlayedCountTextBackground.y -
                this.gamePlayedCountTextBackground.height * 0.015,
            text: "" + this.config.total,
            style: style,
        });
        this.gamePlayedCountText.setShadow(0, 3, '#000000', 3);
        this.gamePlayedCountText.setOrigin(0.5, 0.55);
        this.gamePlayedCountText.width >
            this.gamePlayedCountTextBackground.width * 0.8 &&
            this.gamePlayedCountText.setScale((this.gamePlayedCountTextBackground.width * 0.8) /
                this.gamePlayedCountText.width);
        this.add(this.gamePlayedCountText);
    };
    UserInfoProfileContent.prototype.createNameSectionBackground = function () {
        this.nameBackground = this.scene.make.image({
            x: -this.statisticsBackground.width * 0.08,
            y: -this.statisticsBackground.height * 0.494,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupNameBackground,
        });
        this.add(this.nameBackground);
    };
    UserInfoProfileContent.prototype.createStatisticsSection = function () {
        this.createStatisticsSectionBackground();
        this.setSize(this.statisticsBackground.width, this.statisticsBackground.height);
        this.createWinDiagram();
        this.createLosesInfo();
        this.createDrawsInfo();
        this.createLeavesInfo();
    };
    UserInfoProfileContent.prototype.createStatisticsSectionBackground = function () {
        this.statisticsBackground = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupContentBackground,
        });
        this.add(this.statisticsBackground);
    };
    UserInfoProfileContent.prototype.createWinDiagram = function () {
        this.winStatistic = new UserInfoStatisticDiagramElement(this.scene, {
            name: 'user-info-popup-profile-statistics-name-wins',
            value: this.config.wins,
            total: this.config.total,
            color: '#fff9d6',
        });
        this.add(this.winStatistic);
        this.winStatistic.x = -this.width * 0.27;
        this.winStatistic.y = this.height * 0.18;
    };
    UserInfoProfileContent.prototype.createLosesInfo = function () {
        this.losesStatistic = new UserInfoStatisticElement(this.scene, {
            name: 'user-info-popup-profile-statistics-name-loses',
            value: this.config.loses,
            color: '#d01515',
        });
        this.add(this.losesStatistic);
        this.losesStatistic.x = this.width * 0.2;
        this.losesStatistic.y = -this.height * 0.22;
    };
    UserInfoProfileContent.prototype.createDrawsInfo = function () {
        this.drawsStatistic = new UserInfoStatisticElement(this.scene, {
            name: 'user-info-popup-profile-statistics-name-draws',
            value: this.config.draws,
            color: '#fff9d6',
        });
        this.add(this.drawsStatistic);
        this.drawsStatistic.x = this.losesStatistic.x;
        this.drawsStatistic.y = this.losesStatistic.y + this.height * 0.22;
    };
    UserInfoProfileContent.prototype.createLeavesInfo = function () {
        this.leavesStatistic = new UserInfoStatisticElement(this.scene, {
            name: 'user-info-popup-profile-statistics-name-leaves',
            value: this.config.leaves,
            color: '#d01515',
        });
        this.add(this.leavesStatistic);
        this.leavesStatistic.x = this.drawsStatistic.x;
        this.leavesStatistic.y = this.drawsStatistic.y + this.height * 0.22;
    };
    UserInfoProfileContent.prototype.createLogoutButton = function () {
        var textConfig = {
            text: Translation.USER_INFO_POPUP_BUTTON_LOGOUT,
            size: 46 * gameConfig.resolutionMultiplier,
            fill: 0x9b0505,
            font: BitmapFonts.RobotoSlab.Name,
            align: Phaser.GameObjects.BitmapText.ALIGN_CENTER,
            origin: { x: 0.5, y: 0.6 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupLogoutButtonNormal,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupLogoutButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupLogoutButtonDown,
            textConfig: textConfig,
        };
        var config = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        this.logoutButton = new SimpleButtonWithBitmapText(this.scene, config);
        this.add(this.logoutButton);
        this.logoutButton.x =
            this.statisticsBackground.width * 0.481 - this.logoutButton.width / 2;
        this.logoutButton.y =
            this.statisticsBackground.height * 0.497 - this.logoutButton.height / 2;
        this.logoutButton.setVisible(getZone() === 'cordova');
    };
    UserInfoProfileContent.prototype.setListeners = function () {
        this.config.logoutButtonEnabled &&
            this.logoutButton && this.logoutButton.on(SimpleButtonWithBitmapText.CLICK_EVENT, this.onLogout, this);
    };
    UserInfoProfileContent.prototype.onLogout = function () {
        this.parentContainer.emit(UserInfoPopup.ACTION_EVENT, UserInfoPopupAction.LOGOUT);
    };
    return UserInfoProfileContent;
}(Phaser.GameObjects
    .Container));
export default UserInfoProfileContent;
