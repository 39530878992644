var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fonts, MultiAtlases } from '../../../../assets';
import NavigationSectionState from './NavigationSectionState';
var NavigationSectionPassiveState = /** @class */ (function (_super) {
    __extends(NavigationSectionPassiveState, _super);
    function NavigationSectionPassiveState(scene, mode) {
        var _this = this;
        var config = {
            background: {
                key: MultiAtlases.Lobby.Atlas.Name,
                frame: MultiAtlases.Lobby.Atlas.Frames.SectionBackgroundPassive,
            },
            text: {
                text: "lobby-navigation-" + mode.toLowerCase(),
                fontFamily: Fonts.RobotoSlab.Name,
                fontSize: 50,
                fill: '#f3deba',
            },
        };
        _this = _super.call(this, scene, config) || this;
        return _this;
    }
    NavigationSectionPassiveState.prototype.prepare = function () {
        this.text.y = this.height * 0.43;
    };
    return NavigationSectionPassiveState;
}(NavigationSectionState));
export default NavigationSectionPassiveState;
