var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import ApiVOProxy from '../model/ApiVOProxy';
import PlayerVOProxy from '../model/PlayerVOProxy';
import SocketVOProxy from '../model/SocketVOProxy';
import PlayerAvatarView from '../view/components/game/PlayerAvatarView';
import SettingsPopup from '../view/popups/SettingsPopup';
import RegistrationScene from '../view/scenes/RegistrationScene';
import ServiceScene from '../view/scenes/ServiceScene';
import TopBarScene from '../view/scenes/TopBarScene';
import ApplyClaimedRewardCommand from './player/ApplyClaimedRewardCommand';
import CheckPlayerBonusesCommand from './player/CheckPlayerBonusesCommand';
import ClaimGamePlayPriceCommand from './player/ClaimGamePlayPriceCommand';
import InitializePlayerCommand from './player/InitializePlayerCommand';
import SetLanguageCommand from './player/SetLanguageCommand';
import UpdateAvatarCommand from './player/UpdateAvatarCommand';
import UpdateNickNameCommand from './player/UpdateNickNameCommand';
import UpdatePlayerAvatarPositionCommand from './player/UpdatePlayerAvatarPositionCommand';
import UpdatePlayerBonusesCommand from './player/UpdatePlayerBonusesCommand';
import UpdatePlayerDataCommand from './player/UpdatePlayerDataCommand';
import UpdatePlayerGanderCommand from './player/UpdatePlayerGanderCommand';
import UpdatePlayerProfileDataCommand from './player/UpdatePlayerProfileDataCommand';
import UpdateSettingCommand from './player/UpdateSettingCommand';
var RegisterPlayerCommands = /** @class */ (function (_super) {
    __extends(RegisterPlayerCommands, _super);
    function RegisterPlayerCommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterPlayerCommands.prototype.execute = function () {
        this.facade.registerCommand(ApiVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, InitializePlayerCommand);
        this.facade.registerCommand(ApiVOProxy.USER_PROFILE_DATA_UPDATED_NOTIFICATION, UpdatePlayerProfileDataCommand);
        this.facade.registerCommand(ServiceScene.GAME_RESUMED_NOTIFICATION, CheckPlayerBonusesCommand);
        this.facade.registerCommand(TopBarScene.SHOWN_NOTIFICATION, CheckPlayerBonusesCommand);
        this.facade.registerCommand(PlayerVOProxy.USER_BONUSES_UPDATED_NOTIFICATION, CheckPlayerBonusesCommand);
        this.facade.registerCommand(SocketVOProxy.CLAIM_GAME_PLAY_PRICE_NOTIFICATION, ClaimGamePlayPriceCommand);
        this.facade.registerCommand(RegistrationScene.GENDER_CHANGED_NOTIFICATION, UpdatePlayerGanderCommand);
        this.facade.registerCommand(RegistrationScene.AVATAR_CHANGED_NOTIFICATION, UpdateAvatarCommand);
        this.facade.registerCommand(RegistrationScene.NAME_CHANGED_NOTIFICATION, UpdateNickNameCommand);
        // this.facade.registerCommand(
        //   SocketVOProxy.CHARGE_USER_NOTIFICATION,
        //   SetChargeUserDataCommand,
        // );
        this.facade.registerCommand(TopBarScene.CLAIM_ANIMATION_STARTED_NOTIFICATION, ApplyClaimedRewardCommand);
        this.facade.registerCommand(ApiVOProxy.UPDATE_PLAYER_DATA_NOTIFICATION, UpdatePlayerDataCommand);
        this.facade.registerCommand(RegistrationScene.LANGUAGE_CLICKED_NOTIFICATION, SetLanguageCommand);
        this.facade.registerCommand(SettingsPopup.SETTING_CHANGED_NOTIFICATION, UpdateSettingCommand);
        this.facade.registerCommand(PlayerAvatarView.POSITION_CHANGED_NOTIFICATION, UpdatePlayerAvatarPositionCommand);
        this.facade.registerCommand(ApiVOProxy.NOTIFICATIONS_CHECK_COMPLETE_NOTIFICATION, UpdatePlayerBonusesCommand);
    };
    return RegisterPlayerCommands;
}(SyncMacroCommand));
export default RegisterPlayerCommands;
