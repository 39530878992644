var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Guard } from "@candywings/pure-mvc";
import { StoreItemActionType } from "../../../model/VO/StoreVO";
var IsActionWatchGuard = /** @class */ (function (_super) {
    __extends(IsActionWatchGuard, _super);
    function IsActionWatchGuard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IsActionWatchGuard.prototype.approve = function (notificationName, section, config) {
        return section === 'free' && config.action === StoreItemActionType.WATCH;
    };
    return IsActionWatchGuard;
}(Guard));
export default IsActionWatchGuard;
