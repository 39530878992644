var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, Fonts, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { Translation } from '../../translations';
import SimpleButtonWithBitmapText from '../utils/simpleButton/SimpleButtonWithBitmapText';
import StandardPopup from './StandardPopup';
export var WaitOrLeavePopupAction;
(function (WaitOrLeavePopupAction) {
    WaitOrLeavePopupAction[WaitOrLeavePopupAction["WAIT"] = 0] = "WAIT";
    WaitOrLeavePopupAction[WaitOrLeavePopupAction["LEAVE"] = 1] = "LEAVE";
})(WaitOrLeavePopupAction || (WaitOrLeavePopupAction = {}));
var WaitOrLeavePopup = /** @class */ (function (_super) {
    __extends(WaitOrLeavePopup, _super);
    function WaitOrLeavePopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WaitOrLeavePopup.prototype.createBody = function () {
        this.createColoredBlocker(0.7);
        this.createBgImage(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.ExitConfirmationPopupBackground);
        this.createText();
        this.createTitleIcon();
        this.createButtons();
        this.setListeners();
    };
    WaitOrLeavePopup.prototype.createText = function () {
        var style = {
            fontFamily: Fonts.RobotoSlab.Name,
            fontSize: 60,
            fill: '#f1dcb2',
        };
        this.subTitle = this.scene.make.extText({
            x: 0,
            y: -this.height * 0.175,
            text: Translation.WAIT_OR_LEAVE_POPUP_SUBTITLE,
            style: style,
        });
        this.subTitle.setAlign('center');
        this.subTitle.setScale(Math.min((this.width * 0.85) / this.subTitle.width, 1));
        this.subTitle.setOrigin(0.5);
        this.add(this.subTitle);
    };
    WaitOrLeavePopup.prototype.createTitleIcon = function () {
        this.titleIcon = this.scene.make.image({
            x: 0,
            y: -this.height * 0.435,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.WaitOrLeavePopupTitleIcon,
        });
        this.add(this.titleIcon);
    };
    WaitOrLeavePopup.prototype.createButtons = function () {
        this.createWaitButton();
        this.createLeaveButton();
    };
    WaitOrLeavePopup.prototype.createWaitButton = function () {
        var textConfig = {
            text: Translation.WAIT_OR_LEAVE_POPUP_POSITIVE_BUTTON,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0xfff3d3,
            origin: { x: 0.5, y: 0.6 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PositiveButtonUp,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PositiveButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PositiveButtonDown,
            textConfig: textConfig,
        };
        this.waitButton = new SimpleButtonWithBitmapText(this.scene, {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        });
        this.add(this.waitButton);
        this.waitButton.x = -this.waitButton.width * 0.6;
        this.waitButton.y = this.height * 0.225;
    };
    WaitOrLeavePopup.prototype.createLeaveButton = function () {
        var textConfig = {
            text: Translation.WAIT_OR_LEAVE_POPUP_NEGATIVE_BUTTON,
            font: BitmapFonts.RobotoSlab.Name,
            size: 50 * gameConfig.resolutionMultiplier,
            fill: 0xfff3d3,
            origin: { x: 0.5, y: 0.6 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PrimaryButtonUp,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PrimaryButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.PrimaryButtonDown,
            textConfig: textConfig,
        };
        this.leaveButton = new SimpleButtonWithBitmapText(this.scene, {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        });
        this.add(this.leaveButton);
        this.leaveButton.x = this.leaveButton.width * 0.6;
        this.leaveButton.y = this.height * 0.225;
    };
    WaitOrLeavePopup.prototype.setListeners = function () {
        this.leaveButton.setEnabled(true);
        this.waitButton.setEnabled(true);
        this.leaveButton.on(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction.bind(this, WaitOrLeavePopupAction.LEAVE));
        this.waitButton.on(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction.bind(this, WaitOrLeavePopupAction.WAIT));
    };
    WaitOrLeavePopup.NAME = 'WaitOrLeavePopup';
    WaitOrLeavePopup.BUTTON_CLICKED_EVENT = 'ButtonClickedEvent';
    WaitOrLeavePopup.WAIT_CLICKED_NOTIFICATION = WaitOrLeavePopup.NAME + "WaitClickedNotification";
    WaitOrLeavePopup.LEAVE_CLICKED_NOTIFICATION = WaitOrLeavePopup.NAME + "LeaveClickedNotification";
    return WaitOrLeavePopup;
}(StandardPopup));
export default WaitOrLeavePopup;
