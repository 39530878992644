var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import AdsVOProxy from '../model/AdsVOProxy';
import LoadRewardedVideoCommand from './ad/LoadRewardedVideoCommand';
import RegisterAdsVOProxyCommand from './ad/RegisterAdsVOProxyCommand';
import LobbyScene from '../view/scenes/LobbyScene';
import RegisterAdEventsCommand from './ad/RegisterAdEventsCommand';
import StoreScene from '../view/scenes/StoreScene';
import ShowRewardedVideoCommand from './ad/ShowRewardedVideoCommand';
var RegisterAdsCommands = /** @class */ (function (_super) {
    __extends(RegisterAdsCommands, _super);
    function RegisterAdsCommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterAdsCommands.prototype.execute = function () {
        this.facade.registerCommandOnce(AdsVOProxy.REGISTERED_NOTIFICATION, RegisterAdEventsCommand);
        this.facade.registerCommandOnce(AdsVOProxy.REGISTERED_NOTIFICATION, LoadRewardedVideoCommand);
        this.facade.registerCommand(AdsVOProxy.REWARDED_VIDEO_SHOW_COMPLETE_NOTIFICATION, LoadRewardedVideoCommand);
        this.facade.registerCommand(AdsVOProxy.REWARDED_VIDEO_CLOSE_NOTIFICATION, LoadRewardedVideoCommand);
        this.facade.registerCommand(LobbyScene.SHOWN_NOTIFICATION, LoadRewardedVideoCommand);
        this.facade.registerCommand(StoreScene.STORE_ITEM_CLICK_NOTIFICATION, ShowRewardedVideoCommand);
        _super.prototype.execute.call(this);
    };
    RegisterAdsCommands.prototype.initializeMacroCommand = function () {
        this.addSubCommand(RegisterAdsVOProxyCommand);
    };
    return RegisterAdsCommands;
}(SyncMacroCommand));
export default RegisterAdsCommands;
