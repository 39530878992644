var ProxyHandler = /** @class */ (function () {
    function ProxyHandler(proxy) {
        this.proxy = proxy;
        this.queue = [];
    }
    ProxyHandler.prototype.sendNotification = function (name) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        (_a = this.proxy).sendNotification.apply(_a, [name].concat(args));
    };
    ProxyHandler.prototype.addToQueue = function (name) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var notification = {
            name: name,
            args: args,
        };
        this.queue.push(notification);
    };
    ProxyHandler.prototype.sendFromQueue = function () {
        var _a;
        var notification = this.queue.shift();
        if (!notification) {
            return;
        }
        this.proxy.vo.hasOwnProperty('actionIndex') &&
            (this.proxy.vo.actionIndex = notification.actionId);
        (_a = this.proxy).sendNotification.apply(_a, [notification.name].concat(notification.args));
    };
    return ProxyHandler;
}());
export default ProxyHandler;
