var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import GameScene from '../../../view/scenes/GameScene';
import UiGuard from './UiGuard';
var IsNotInGameSceneGuard = /** @class */ (function (_super) {
    __extends(IsNotInGameSceneGuard, _super);
    function IsNotInGameSceneGuard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IsNotInGameSceneGuard.prototype.approve = function () {
        return this.proxy.vo.currentScene !== GameScene.NAME;
    };
    return IsNotInGameSceneGuard;
}(UiGuard));
export default IsNotInGameSceneGuard;
