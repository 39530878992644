var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import ApiVOProxy from '../model/ApiVOProxy';
import PlayerVOProxy from '../model/PlayerVOProxy';
import LobbyScene from '../view/scenes/LobbyScene';
import RoomScene from '../view/scenes/RoomScene';
import StoreScene from '../view/scenes/StoreScene';
import TopBarScene from '../view/scenes/TopBarScene';
import RegisterFirebaseVOProxyCommand from './firebase/RegisterFirebaseVOProxyCommand';
import SendLevelUpCommand from './firebase/SendLevelUpCommand';
import SendRoomCommand from './firebase/SendRoomCommand';
import SendStoreCommand from './firebase/SendStoreCommand';
import SendStoreItemCommand from './firebase/SendStoreItemCommand';
import SendStoreTabCommand from './firebase/SendStoreTabCommand';
import SetUserIdCommand from './firebase/SetUserIdCommand';
import IsCordovaGuard from './Guards/device/IsCordovaGuard';
var RegisterFirebaseCommands = /** @class */ (function (_super) {
    __extends(RegisterFirebaseCommands, _super);
    function RegisterFirebaseCommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterFirebaseCommands.prototype.prepare = function () {
        this.addGuards(IsCordovaGuard);
    };
    RegisterFirebaseCommands.prototype.execute = function () {
        this.facade.registerCommand(PlayerVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, SetUserIdCommand);
        this.facade.registerCommand(ApiVOProxy.USER_LEVEL_UP_NOTIFICATION, SendLevelUpCommand);
        this.facade.registerCommand(TopBarScene.STORE_BUTTON_CLICKED_NOTIFICATION, SendStoreCommand);
        this.facade.registerCommand(RoomScene.DOOR_SELECTED_NOTIFICATION, SendRoomCommand);
        this.facade.registerCommand(LobbyScene.BONUS_CHOSE_NOTIFICATION, SendRoomCommand);
        this.facade.registerCommand(StoreScene.STORE_TAB_CLICK_NOTIFICATION, SendStoreTabCommand);
        this.facade.registerCommand(StoreScene.STORE_ITEM_CLICK_NOTIFICATION, SendStoreItemCommand);
        _super.prototype.execute.call(this);
    };
    RegisterFirebaseCommands.prototype.initializeMacroCommand = function () {
        this.addSubCommand(RegisterFirebaseVOProxyCommand);
    };
    return RegisterFirebaseCommands;
}(SyncMacroCommand));
export default RegisterFirebaseCommands;
