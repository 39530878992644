var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../../../../assets';
import BaseEmojiScrollableElement from '../BaseEmojiScrollableElement';
var SmileScrollerElement = /** @class */ (function (_super) {
    __extends(SmileScrollerElement, _super);
    function SmileScrollerElement(scene, index) {
        var _this = _super.call(this, scene, index) || this;
        _this.createContent();
        _this.setScale(0.7);
        return _this;
    }
    SmileScrollerElement.prototype.createContent = function () {
        this.createImage();
        this.setSize(this.image.width, this.image.height);
    };
    SmileScrollerElement.prototype.createImage = function () {
        this.image = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Emojis.Atlas.Name,
            frame: MultiAtlases.Emojis.Atlas.Frames["Smile" + this.index],
        });
        this.add(this.image);
        this.image.setOrigin(0);
    };
    return SmileScrollerElement;
}(BaseEmojiScrollableElement));
export default SmileScrollerElement;
