var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Atlases, BitmapFonts } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var DoorTitle = /** @class */ (function (_super) {
    __extends(DoorTitle, _super);
    function DoorTitle(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        return _this;
    }
    DoorTitle.prototype.createBody = function () {
        this.createBackground();
        this.createText();
    };
    DoorTitle.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames.TitleBackground,
        });
        this.add(this.background);
    };
    DoorTitle.prototype.createText = function () {
        this.text = this.scene.make.extBitmapText({
            x: 0,
            y: 0,
            text: "modes-scene-section-title-" + this.config.text,
            font: BitmapFonts.RobotoSlab.Name,
            size: 46 * gameConfig.resolutionMultiplier,
        });
        this.text.setOrigin(0.5);
        this.text.setTint(0xd8b975);
        this.add(this.text);
    };
    return DoorTitle;
}(Phaser.GameObjects.Container));
export default DoorTitle;
