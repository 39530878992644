var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import ApiVOProxy from '../../model/ApiVOProxy';
import BaseStoreVOProxy from '../../model/store/BaseStoreVOProxy';
import IsMobileGuard from '../Guards/device/IsMobileGuard';
var StoreCommand = /** @class */ (function (_super) {
    __extends(StoreCommand, _super);
    function StoreCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StoreCommand.prototype.prepare = function () {
        this.addGuards(IsMobileGuard);
    };
    Object.defineProperty(StoreCommand.prototype, "api", {
        get: function () {
            return this.facade.retrieveProxy(ApiVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StoreCommand.prototype, "proxy", {
        get: function () {
            return this.facade.retrieveProxy(BaseStoreVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    return StoreCommand;
}(SyncMacroCommand));
export default StoreCommand;
