var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ApiVOProxy from '../../model/ApiVOProxy';
import PlayerVOProxy from '../../model/PlayerVOProxy';
import BaseStoreVOProxy from '../../model/store/BaseStoreVOProxy';
import { RewardName, RewardType } from '../../model/VO/ApiVO';
import GameEndPopup from '../popups/GameEndPopup';
import InsufficientFundsPopup from '../popups/InsufficientFundsPopup';
import RewardPopup from '../popups/RewardPopup';
import StandardPopup from '../popups/StandardPopup';
import BaseSceneMediator from './BaseSceneMediator';
import GameScene from './GameScene';
import LobbyScene from './LobbyScene';
import PreloadScene from './PreloadScene';
import RoomScene from './RoomScene';
import ServiceScene from './ServiceScene';
import StoreScene from './StoreScene';
import TopBarScene, { TopBarAction } from './TopBarScene';
import WheelScene from './WheelScene';
import { Images } from '../../assets';
import { isNativeAvatar, getNativeAvatarKey } from '../../utils/Utils';
import { hasKey } from '../utils/phaser/PhaserUtils';
var TopBarSceneMediator = /** @class */ (function (_super) {
    __extends(TopBarSceneMediator, _super);
    function TopBarSceneMediator() {
        return _super.call(this, TopBarSceneMediator.NAME, null) || this;
    }
    TopBarSceneMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(PreloadScene.LOAD_COMPLETE_NOTIFICATION, LobbyScene.SHOWN_NOTIFICATION, LobbyScene.GAME_CHOSE_NOTIFICATION, GameScene.CREATE_NOTIFICATION, GameScene.LEAVE_ROOM_NOTIFICATION, InsufficientFundsPopup.EARN_NOTIFICATION, InsufficientFundsPopup.BUY_NOTIFICATION, RoomScene.SHOW_COMPLETE_NOTIFICATION, PlayerVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, PlayerVOProxy.UPDATE_CURRENCIES_NOTIFICATION, PlayerVOProxy.AVATAR_UPDATED_NOTIFICATION, StoreScene.SHOWN_NOTIFICATION, StoreScene.HIDDEN_NOTIFICATION, StandardPopup.HIDE_COMPLETE_NOTIFICATION, WheelScene.READY_TO_DESTROY_NOTIFICATION, RewardPopup.SHOWN_NOTIFICATION, RewardPopup.CLAIM_REWARD_NOTIFICATION, GameEndPopup.SHOWN_NOTIFICATION, GameEndPopup.CLAIM_REWARD_NOTIFICATION, GameEndPopup.HIDDEN_NOTIFICATION, BaseStoreVOProxy.INITIALIZE_FAILED_NOTIFICATION, ServiceScene.PLAYER_AVATAR_UPDATED_NOTIFICATION, GameScene.SLEEP_NOTIFICATION, GameScene.WAKE_NOTIFICATION);
    };
    TopBarSceneMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, name_1, rewardItems, rewards, rewardTypes, updateConfig, _loop_1, this_1, _b, rewardTypes_1, rewardType;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.handleDefaultNotifications.apply(this, [notificationName].concat(args));
                        _a = notificationName;
                        switch (_a) {
                            case PlayerVOProxy.INITIALIZE_SUCCESS_NOTIFICATION: return [3 /*break*/, 1];
                            case PlayerVOProxy.AVATAR_UPDATED_NOTIFICATION: return [3 /*break*/, 2];
                            case PlayerVOProxy.UPDATE_CURRENCIES_NOTIFICATION: return [3 /*break*/, 3];
                            case InsufficientFundsPopup.BUY_NOTIFICATION: return [3 /*break*/, 4];
                            case InsufficientFundsPopup.EARN_NOTIFICATION: return [3 /*break*/, 4];
                            case LobbyScene.GAME_CHOSE_NOTIFICATION: return [3 /*break*/, 5];
                            case GameEndPopup.CLAIM_REWARD_NOTIFICATION: return [3 /*break*/, 6];
                            case RewardPopup.CLAIM_REWARD_NOTIFICATION: return [3 /*break*/, 6];
                            case GameScene.SLEEP_NOTIFICATION: return [3 /*break*/, 9];
                            case GameScene.LEAVE_ROOM_NOTIFICATION: return [3 /*break*/, 9];
                            case LobbyScene.SHOWN_NOTIFICATION: return [3 /*break*/, 10];
                            case RoomScene.SHOW_COMPLETE_NOTIFICATION: return [3 /*break*/, 12];
                            case StoreScene.SHOWN_NOTIFICATION: return [3 /*break*/, 13];
                            case ApiVOProxy.USER_LEVEL_UP_NOTIFICATION: return [3 /*break*/, 15];
                            case GameScene.WAKE_NOTIFICATION: return [3 /*break*/, 15];
                            case GameScene.CREATE_NOTIFICATION: return [3 /*break*/, 15];
                            case StoreScene.HIDDEN_NOTIFICATION: return [3 /*break*/, 16];
                            case GameEndPopup.SHOWN_NOTIFICATION: return [3 /*break*/, 17];
                            case RewardPopup.SHOWN_NOTIFICATION: return [3 /*break*/, 18];
                            case GameEndPopup.HIDDEN_NOTIFICATION: return [3 /*break*/, 19];
                            case WheelScene.READY_TO_DESTROY_NOTIFICATION: return [3 /*break*/, 20];
                            case PreloadScene.LOAD_COMPLETE_NOTIFICATION: return [3 /*break*/, 21];
                            case BaseStoreVOProxy.INITIALIZE_FAILED_NOTIFICATION: return [3 /*break*/, 22];
                            case ServiceScene.PLAYER_AVATAR_UPDATED_NOTIFICATION: return [3 /*break*/, 23];
                        }
                        return [3 /*break*/, 24];
                    case 1:
                        this.sceneManager.start(TopBarScene.NAME);
                        this.forceUpdateComponents();
                        this.sceneManager.sleep(TopBarScene.NAME);
                        return [3 /*break*/, 25];
                    case 2:
                        this.forceUpdateComponents();
                        return [3 /*break*/, 25];
                    case 3:
                        this.forceUpdateComponents();
                        return [3 /*break*/, 25];
                    case 4:
                        if (!this.facade.hasProxy(BaseStoreVOProxy.NAME)) {
                            this.viewComponent.enableInput();
                            return [2 /*return*/];
                        }
                        this.viewComponent.hideStoreButton();
                        this.viewComponent.hideWheelButton();
                        return [3 /*break*/, 25];
                    case 5:
                        this.viewComponent.hideWheelButton();
                        this.viewComponent.hideStoreButton();
                        this.viewComponent.showHomeButton();
                        this.sceneManager.bringToTop(TopBarScene.NAME);
                        return [3 /*break*/, 25];
                    case 6:
                        this.viewComponent.setAvatarInputState(false);
                        this.viewComponent.hideHomeButton();
                        this.viewComponent.hideStoreButton();
                        this.viewComponent.hideWheelButton();
                        return [4 /*yield*/, this.show()];
                    case 7:
                        _c.sent();
                        name_1 = args[0];
                        rewardItems = args[1];
                        rewards = this.getRewardsByName(name_1);
                        rewardTypes = rewards.map(function (reward) { return reward.type; });
                        updateConfig = {};
                        _loop_1 = function (rewardType) {
                            if ([RewardType.GOLD, RewardType.CRYSTAL, RewardType.SILVER].includes(rewardType)) {
                                var before = this_1.playerVOProxy.vo
                                    .playerCurrency[rewardType];
                                var after = before +
                                    rewards.find(function (reward) { return reward.type === rewardType; })
                                        .amount;
                                updateConfig[rewardType] = {
                                    before: before,
                                    after: after,
                                    force: false,
                                };
                            }
                            else {
                                var before = this_1.playerVOProxy.vo.playerLevel.xp;
                                var after = before +
                                    rewards.find(function (reward) { return reward.type === rewardType; })
                                        .amount;
                                updateConfig[rewardType] = {
                                    before: before,
                                    after: after,
                                    force: false,
                                };
                            }
                        };
                        this_1 = this;
                        for (_b = 0, rewardTypes_1 = rewardTypes; _b < rewardTypes_1.length; _b++) {
                            rewardType = rewardTypes_1[_b];
                            _loop_1(rewardType);
                        }
                        this.sendNotification(TopBarScene.CLAIM_ANIMATION_STARTED_NOTIFICATION, updateConfig);
                        return [4 /*yield*/, this.viewComponent.applyRewards(rewardItems, updateConfig)];
                    case 8:
                        _c.sent();
                        this.sendNotification(TopBarScene.CLAIM_ANIMATION_ENDED_NOTIFICATION);
                        this.checkForRewardsOver(name_1);
                        this.sceneManager.isActive(LobbyScene.NAME) &&
                            this.showLobbyButtons();
                        this.sceneManager.isActive(StoreScene.NAME) &&
                            this.showStoreButtons();
                        this.sceneManager.isActive(RoomScene.NAME) && this.showRoomButtons();
                        this.viewComponent.setAvatarInputState(true);
                        return [3 /*break*/, 25];
                    case 9:
                        if (this.apiVOProxy.vo.levelUpData && this.apiVOProxy.vo.levelUpData.length) {
                            return [3 /*break*/, 25];
                        }
                        this.show();
                        return [3 /*break*/, 25];
                    case 10: return [4 /*yield*/, this.show()];
                    case 11:
                        _c.sent();
                        if (this.apiVOProxy.vo.levelUpData && this.apiVOProxy.vo.levelUpData.length) {
                            return [3 /*break*/, 25];
                        }
                        (!this.sceneManager.getScene(StoreScene.NAME) ||
                            this.sceneManager.isSleeping(StoreScene.NAME)) &&
                            this.showLobbyButtons();
                        return [3 /*break*/, 25];
                    case 12:
                        this.viewComponent.enableInput();
                        this.show();
                        if (this.apiVOProxy.vo.levelUpData && this.apiVOProxy.vo.levelUpData.length) {
                            return [3 /*break*/, 25];
                        }
                        this.showRoomButtons();
                        return [3 /*break*/, 25];
                    case 13:
                        this.sceneManager.wake(TopBarScene.NAME);
                        this.showStoreButtons();
                        return [4 /*yield*/, this.show()];
                    case 14:
                        _c.sent();
                        return [3 /*break*/, 25];
                    case 15:
                        this.hide();
                        return [3 /*break*/, 25];
                    case 16:
                        this.showLobbyButtons();
                        return [3 /*break*/, 25];
                    case 17:
                        this.viewComponent.hideHomeButton();
                        this.viewComponent.hideWheelButton();
                        this.viewComponent.hideStoreButton();
                        return [3 /*break*/, 25];
                    case 18:
                        this.viewComponent.hideHomeButton();
                        this.viewComponent.hideWheelButton();
                        this.viewComponent.hideStoreButton();
                        _c.label = 19;
                    case 19:
                        this.hide();
                        return [3 /*break*/, 25];
                    case 20:
                        this.viewComponent.destroyWheelButton();
                        return [3 /*break*/, 25];
                    case 21:
                        this.updateAvatar();
                        return [3 /*break*/, 25];
                    case 22:
                        this.viewComponent.destroyStoreButton();
                        return [3 /*break*/, 25];
                    case 23:
                        this.viewComponent.updatePlayerInfo(this.playerVOProxy.vo.playerLevel.level, this.playerVOProxy.vo.name, this.playerVOProxy.vo.userId);
                        this.updateAvatar();
                        return [3 /*break*/, 25];
                    case 24:
                        if (this.defaultNotifications.contains(notificationName)) {
                            return [3 /*break*/, 25];
                        }
                        console.warn(notificationName + " is unhandled!");
                        return [3 /*break*/, 25];
                    case 25: return [2 /*return*/];
                }
            });
        });
    };
    TopBarSceneMediator.prototype.setView = function () {
        var topBarScene = new TopBarScene();
        this.sceneManager.add(TopBarScene.NAME, topBarScene);
        this.setViewComponent(topBarScene);
    };
    TopBarSceneMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.events.on(TopBarScene.ACTION_DONE_EVENT, this.onAction, this);
    };
    TopBarSceneMediator.prototype.updateAvatar = function () {
        var key = this.getAvatarKey(this.playerVOProxy.vo.avatarUrl, this.playerVOProxy.vo.userId);
        this.viewComponent.updateAvatarTexture(key);
    };
    TopBarSceneMediator.prototype.getAvatarKey = function (url, userId) {
        if (!url) {
            return Images.Avatar.Name;
        }
        else if (isNativeAvatar(url)) {
            return getNativeAvatarKey(url);
        }
        else if (!hasKey("playerDefaultAvatar")) {
            return Images.Avatar.Name;
        }
        else {
            return "playerDefaultAvatar";
        }
    };
    TopBarSceneMediator.prototype.show = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.sceneManager.isSleeping(TopBarScene.NAME) ||
                            !this.sceneManager.isActive(TopBarScene.NAME)) {
                            this.sceneManager.wake(TopBarScene.NAME);
                        }
                        this.viewComponent.scene.bringToTop(TopBarScene.NAME);
                        if (!this.facade.hasProxy(BaseStoreVOProxy.NAME)) {
                            this.viewComponent.destroyStoreButton();
                        }
                        return [4 /*yield*/, this.viewComponent.show()];
                    case 1:
                        _a.sent();
                        this.sendNotification(TopBarScene.SHOWN_NOTIFICATION);
                        return [2 /*return*/];
                }
            });
        });
    };
    TopBarSceneMediator.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.viewComponent.hide()];
                    case 1:
                        _a.sent();
                        this.sendNotification(TopBarScene.HIDDEN_NOTIFICATION);
                        return [2 /*return*/];
                }
            });
        });
    };
    TopBarSceneMediator.prototype.onAction = function (actionId) {
        this.viewComponent.disableInput();
        switch (actionId) {
            case TopBarAction.GOLD:
            case TopBarAction.SILVER:
            case TopBarAction.CRYSTAL:
                var storeVOProxy = this.facade.retrieveProxy(BaseStoreVOProxy.NAME);
                var section = TopBarAction[actionId].toLowerCase();
                if (!storeVOProxy ||
                    !storeVOProxy.vo.mergedData[section] ||
                    storeVOProxy.vo.mergedData[section].length === 0) {
                    this.viewComponent.enableInput();
                    return;
                }
            case TopBarAction.STORE:
                if (!this.facade.hasProxy(BaseStoreVOProxy.NAME)) {
                    this.viewComponent.enableInput();
                    return;
                }
                this.viewComponent.hideStoreButton();
                this.viewComponent.hideWheelButton();
                this.sendNotification(TopBarScene.STORE_BUTTON_CLICKED_NOTIFICATION, actionId);
                this.sceneManager.bringToTop(TopBarScene.NAME);
                break;
            case TopBarAction.WHEEL:
                this.showWheelButtons();
                this.sendNotification(TopBarScene.WHEEL_BUTTON_CLICKED_NOTIFICATION);
                this.hide();
                break;
            case TopBarAction.HOME:
                this.viewComponent.hideHomeButton();
                this.sendNotification(TopBarScene.NAVIGATION_BUTTON_CLICKED_NOTIFICATION);
                break;
            case TopBarAction.USER_INFO:
                this.sendNotification(TopBarScene.AVATAR_CLICKED_NOTIFICATION);
                break;
            default:
                break;
        }
    };
    TopBarSceneMediator.prototype.getRewardsByName = function (name) {
        switch (name) {
            case RewardName.Level_Up:
                return this.apiVOProxy.consumeLevelUpRewards();
            case RewardName.Wheel:
                return this.playerVOProxy.getWheelReward();
            case RewardName.Bonus:
                return this.playerVOProxy.consumeBonusReward();
            case RewardName.GameEnd:
                return this.playerVOProxy.consumeGameEndReward();
            default:
                break;
        }
    };
    TopBarSceneMediator.prototype.showLobbyButtons = function () {
        this.viewComponent.showStoreButton();
        this.playerVOProxy.vo.wheelData.allowed && this.viewComponent.showWheelButton();
        this.viewComponent.hideHomeButton();
        this.viewComponent.enableInput();
    };
    TopBarSceneMediator.prototype.showStoreButtons = function () {
        this.viewComponent.hideStoreButton();
        this.viewComponent.showHomeButton();
        this.viewComponent.enableInput();
    };
    TopBarSceneMediator.prototype.showRoomButtons = function () {
        this.viewComponent.hideStoreButton();
        this.viewComponent.hideWheelButton();
        this.viewComponent.showHomeButton();
        this.sceneManager.bringToTop(TopBarScene.NAME);
        this.sceneManager.bringToTop(TopBarScene.NAME);
        this.viewComponent.enableInput();
    };
    TopBarSceneMediator.prototype.showWheelButtons = function () {
        this.viewComponent.hideWheelButton();
        this.viewComponent.hideStoreButton();
    };
    TopBarSceneMediator.prototype.checkForRewardsOver = function (name) {
        switch (name) {
            case RewardName.Wheel:
            case RewardName.GameEnd:
                this.sendNotification(TopBarScene.REWARDS_CLAIMED_NOTIFICATION);
                break;
            case RewardName.Level_Up:
                !this.apiVOProxy.vo.levelUpData ||
                    (this.apiVOProxy.vo.levelUpData.length === 0 &&
                        this.sendNotification(TopBarScene.REWARDS_CLAIMED_NOTIFICATION));
                break;
            case RewardName.Bonus:
                !this.playerVOProxy.vo.bonuses ||
                    (this.playerVOProxy.vo.bonuses.length === 0 &&
                        this.sendNotification(TopBarScene.REWARDS_CLAIMED_NOTIFICATION));
                break;
            default:
                break;
        }
    };
    TopBarSceneMediator.prototype.updateCurrencies = function () {
        this.viewComponent.updateCurrencies({
            gold: {
                after: this.playerVOProxy.vo.playerCurrency.gold,
                force: true,
            },
            silver: {
                after: this.playerVOProxy.vo.playerCurrency.silver,
                force: true,
            },
            crystal: {
                after: this.playerVOProxy.vo.playerCurrency.crystal,
                force: true,
            },
            level: this.playerVOProxy.vo.playerLevel.level,
        });
    };
    TopBarSceneMediator.prototype.forceUpdateComponents = function () {
        var level = this.playerVOProxy.vo.playerLevel.level;
        var name = this.playerVOProxy.vo.name;
        var id = this.playerVOProxy.vo.userId;
        var xp = this.playerVOProxy.vo.playerLevel.xp;
        this.viewComponent.updatePlayerInfo(level, name, id);
        this.updateCurrencies();
        this.viewComponent.setProgress(xp, true);
        if (this.playerVOProxy.vo.wheelData.allowed) {
            this.viewComponent.showWheelButton();
        }
        else {
            this.viewComponent.hideWheelButton();
        }
    };
    Object.defineProperty(TopBarSceneMediator.prototype, "playerVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(PlayerVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TopBarSceneMediator.prototype, "apiVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(ApiVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    TopBarSceneMediator.NAME = 'TopBarSceneMediator';
    return TopBarSceneMediator;
}(BaseSceneMediator));
export default TopBarSceneMediator;
