var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gameConfig } from '../../constants/GameConfig';
import { BaseMediator } from '../base/BaseMediator';
import PopupManager from '../utils/PopupManager';
import StandardPopup from './StandardPopup';
var StandardPopupMediator = /** @class */ (function (_super) {
    __extends(StandardPopupMediator, _super);
    function StandardPopupMediator(name) {
        return _super.call(this, name, null) || this;
    }
    StandardPopupMediator.prototype.onRegister = function () {
        this.popupManager = PopupManager.getInstance();
        _super.prototype.onRegister.call(this);
    };
    StandardPopupMediator.prototype.handleNotification = function (notificationName) {
        console.warn(notificationName + " is unhandled!");
    };
    StandardPopupMediator.prototype.createView = function (viewComponent) {
        this.setViewComponent(viewComponent);
        this.setViewComponentListeners();
        this.registerEvents();
    };
    StandardPopupMediator.prototype.showView = function (x, y) {
        var _a;
        if (x === void 0) { x = gameConfig.canvasWidth * 0.5; }
        if (y === void 0) { y = gameConfig.canvasHeight * 0.5; }
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        (_a = this.popupManager).show.apply(_a, [this, x, y].concat(args));
    };
    StandardPopupMediator.prototype.prepareToShow = function (x, y) {
        if (x === void 0) { x = gameConfig.canvasWidth * 0.5; }
        if (y === void 0) { y = gameConfig.canvasHeight * 0.5; }
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.viewComponent && this.viewComponent.isAlivePromise)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.viewComponent.isAlivePromise];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        this.createView();
                        (_a = this.viewComponent).prepareToShow.apply(_a, [x, y].concat(args));
                        return [2 /*return*/];
                }
            });
        });
    };
    StandardPopupMediator.prototype.hideView = function (actionId) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        !!this.viewComponent && this.popupManager.hide(actionId);
    };
    StandardPopupMediator.prototype.onAction = function (actionId) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.hideView(actionId);
    };
    StandardPopupMediator.prototype.onViewShow = function () {
        this.sendNotification(StandardPopup.SHOW_START_NOTIFICATION, this.viewComponent.constructor.name);
    };
    StandardPopupMediator.prototype.onViewShowComplete = function () {
        this.sendNotification(StandardPopup.SHOW_COMPLETE_NOTIFICATION, this.viewComponent.constructor.name);
    };
    StandardPopupMediator.prototype.onViewHide = function () {
        this.sendNotification(StandardPopup.HIDE_START_NOTIFICATION, this.viewComponent.constructor.name);
    };
    StandardPopupMediator.prototype.onViewHideComplete = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.sendNotification(StandardPopup.HIDE_COMPLETE_NOTIFICATION, this.viewComponent.constructor.name);
    };
    StandardPopupMediator.prototype.setViewComponentListeners = function () {
        //
    };
    StandardPopupMediator.prototype.removeViewComponentListeners = function () {
        //
    };
    StandardPopupMediator.prototype.registerEvents = function () {
        this.viewComponent.on(StandardPopup.SHOW_START_EVENT, this.onViewShow, this);
        this.viewComponent.on(StandardPopup.SHOW_COMPLETE_EVENT, this.onViewShowComplete, this);
        this.viewComponent.on(StandardPopup.HIDE_START_EVENT, this.onViewHide, this);
        this.viewComponent.on(StandardPopup.HIDE_COMPLETE_EVENT, this.onViewHideComplete, this);
        this.viewComponent.on(StandardPopup.ACTION_EVENT, this.onAction, this);
        this.viewComponent.on(Phaser.GameObjects.Events.DESTROY, this.onViewComponentDestroy, this);
    };
    StandardPopupMediator.prototype.removeEvents = function () {
        this.viewComponent.off(StandardPopup.SHOW_START_EVENT, this.onViewShow, this);
        this.viewComponent.off(StandardPopup.SHOW_COMPLETE_EVENT, this.onViewShowComplete, this);
        this.viewComponent.off(StandardPopup.HIDE_START_EVENT, this.onViewHide, this);
        this.viewComponent.off(StandardPopup.HIDE_COMPLETE_EVENT, this.onViewHideComplete, this);
        this.viewComponent.off(StandardPopup.ACTION_EVENT, this.onAction, this);
        this.viewComponent.off(Phaser.GameObjects.Events.DESTROY, this.onViewComponentDestroy, this);
    };
    StandardPopupMediator.prototype.onViewComponentDestroy = function () {
        this.removeViewComponentListeners();
        this.removeEvents();
        this.viewComponent = null;
    };
    return StandardPopupMediator;
}(BaseMediator));
export default StandardPopupMediator;
