import DeckVO from './DeckVO';
import { ValidationActionType } from './SocketVO';
var StateVO = /** @class */ (function () {
    function StateVO() {
        this.players = [];
        this.turnData = {
            cardPairs: [],
            currentPlayer: {
                userId: null,
                timeout: null,
                attackers: [],
                defender: null,
            },
            validatedCards: [],
            action: ValidationActionType.DISABLED,
        };
        this.reconnected = false;
        this.isGameStarted = false;
        this.isGameEnded = false;
        this.isForceGameEnded = false;
        this.deckData = new DeckVO();
        this.deckData.cardsToDealToPlayers = [];
        this.deckData.isTrumpShown = false;
        this.deckData.trump = null;
        this.deckData.cardsCount = 12;
        this.bitaCardsCount = 0;
        this.gameEndMoment = null;
        this.positions = [];
    }
    Object.defineProperty(StateVO.prototype, "swapEnabled", {
        get: function () {
            for (var _i = 0, _a = this.turnData.validatedCards; _i < _a.length; _i++) {
                var card = _a[_i];
                if (card.canSwap) {
                    return true;
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    return StateVO;
}());
export default StateVO;
