var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Game from '../../Game';
import GameFacade from '../../GameFacade';
import AdsVOProxy from '../../model/AdsVOProxy';
import PlayerVOProxy from '../../model/PlayerVOProxy';
import SocketVOProxy from '../../model/SocketVOProxy';
import { isOk, isVk } from '../../utils/Utils';
import WaitingView from '../components/game/WaitingView';
import LogOutConfirmationPopup from '../popups/LogOutConfirmationPopup';
import { postRunnable } from '../utils/phaser/PhaserUtils';
import BaseScene from './BaseScene';
import BaseSceneMediator from './BaseSceneMediator';
import GameScene from './GameScene';
import LobbyScene from './LobbyScene';
import PreloadScene from './PreloadScene';
import RoomScene from './RoomScene';
import ServiceScene from './ServiceScene';
import StoreScene from './StoreScene';
var ServiceSceneMediator = /** @class */ (function (_super) {
    __extends(ServiceSceneMediator, _super);
    function ServiceSceneMediator() {
        return _super.call(this, ServiceSceneMediator.NAME, null) || this;
    }
    ServiceSceneMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(BaseScene.PLAY_SFX_NOTIFICATION, BaseScene.STOP_SFX_NOTIFICATION, PlayerVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, PlayerVOProxy.UPDATE_SETTINGS_NOTIFICATION, PlayerVOProxy.USER_PROFILE_UPDATED_NOTIFICATION, PreloadScene.LOAD_COMPLETE_NOTIFICATION, LobbyScene.SHOWN_NOTIFICATION, StoreScene.SHOWN_NOTIFICATION, RoomScene.SHOW_COMPLETE_NOTIFICATION, GameScene.FORCE_START_NOTIFICATION, WaitingView.SHOWN_NOTIFICATION, LogOutConfirmationPopup.LOGOUT_NOTIFICATION, SocketVOProxy.LOGIN_FROM_OTHER_DEVICE_NOTIFICATION, AdsVOProxy.SHOW_REWARDED_VIDEO_NOTIFICATION, AdsVOProxy.REWARDED_VIDEO_CLOSED_NOTIFICATION, AdsVOProxy.SHOW_REWARDED_VIDEO_FAIL_NOTIFICATION);
    };
    ServiceSceneMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.handleDefaultNotifications.apply(this, [notificationName].concat(args));
        switch (notificationName) {
            case PlayerVOProxy.INITIALIZE_SUCCESS_NOTIFICATION:
            case PlayerVOProxy.UPDATE_SETTINGS_NOTIFICATION:
                {
                    var playerVOProxy = this.facade.retrieveProxy(PlayerVOProxy.NAME);
                    this.viewComponent.updateSoundState(playerVOProxy.vo.settings.sound);
                    this.viewComponent.updateMusicState(playerVOProxy.vo.settings.music);
                    !isOk() &&
                        !isVk() &&
                        this.viewComponent.updateLanguage(playerVOProxy.vo.settings.language);
                }
                break;
            case PreloadScene.LOAD_COMPLETE_NOTIFICATION:
                this.viewComponent.createBackgroundMusics();
                break;
            case LobbyScene.SHOWN_NOTIFICATION:
            case StoreScene.SHOWN_NOTIFICATION:
            case RoomScene.SHOW_COMPLETE_NOTIFICATION:
                //this.viewComponent.playMusic(Audios.LobbyBackgroundMusic.Name);
                break;
            case WaitingView.SHOWN_NOTIFICATION:
            case GameScene.FORCE_START_NOTIFICATION:
                //this.viewComponent.playMusic(Audios.GameBackgroundMusic.Name);
                break;
            case BaseScene.PLAY_SFX_NOTIFICATION:
                {
                    var sfxName = args[0];
                    this.viewComponent.playSFX(sfxName);
                }
                break;
            case BaseScene.STOP_SFX_NOTIFICATION:
                this.viewComponent.stopSFX();
                break;
            case LogOutConfirmationPopup.LOGOUT_NOTIFICATION:
                this.onLogout();
                break;
            case SocketVOProxy.LOGIN_FROM_OTHER_DEVICE_NOTIFICATION:
                this.sceneManager.bringToTop(ServiceScene.NAME);
                var scenes = this.sceneManager.getScenes(true);
                for (var _a = 0, scenes_1 = scenes; _a < scenes_1.length; _a++) {
                    var scene = scenes_1[_a];
                    if (scene === this.viewComponent) {
                        continue;
                    }
                    this.sceneManager.pause(scene.constructor.name);
                }
                this.viewComponent.showLoginFromOtherDeviceView();
                break;
            case PlayerVOProxy.USER_PROFILE_UPDATED_NOTIFICATION:
                {
                    var playerVOProxy = this.facade.retrieveProxy(PlayerVOProxy.NAME);
                    this.viewComponent.updatePlayerAvatarTexture(playerVOProxy.vo.avatarUrl);
                }
                break;
            case AdsVOProxy.REWARDED_VIDEO_OPEN_NOTIFICATION:
                this.viewComponent.turnOffSounds();
                break;
            case AdsVOProxy.SHOW_REWARDED_VIDEO_FAIL_NOTIFICATION:
            case AdsVOProxy.REWARDED_VIDEO_CLOSED_NOTIFICATION:
                this.viewComponent.turnOnSounds();
                break;
            default:
                break;
        }
    };
    ServiceSceneMediator.prototype.onSceneDestroy = function () {
        _super.prototype.onSceneDestroy.call(this);
        this.facade.removeMediator(ServiceSceneMediator.NAME, this.id);
    };
    ServiceSceneMediator.prototype.setView = function () {
        var serviceScene = new ServiceScene();
        this.sceneManager.add(ServiceScene.NAME, serviceScene);
        this.setViewComponent(serviceScene);
        _super.prototype.setView.call(this);
        this.sceneManager.start(ServiceScene.NAME);
    };
    ServiceSceneMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.game.events.on(Phaser.Core.Events.PAUSE, this.onPause, this);
        this.viewComponent.game.events.on(Phaser.Core.Events.RESUME, this.onResume, this);
        this.viewComponent.events.on(ServiceScene.CONTINUE_EVENT, this.onContinue, this);
        this.viewComponent.events.on(ServiceScene.PLAYER_AVATAR_UPDATED_EVENT, this.onPlayerAvatarUpdated, this);
        this.viewComponent.events.on(ServiceScene.CLOSE_EVENT, this.onClose, this);
    };
    ServiceSceneMediator.prototype.onLogout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fadeScreenOut()];
                    case 1:
                        _a.sent();
                        GameFacade.game.destroy(false, true);
                        GameFacade.removeCore(Game.NAME);
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        });
    };
    ServiceSceneMediator.prototype.onPause = function () {
        // disableRendering();
        this.viewComponent.turnOffSounds();
        this.viewComponent.updateSoundState(false);
        this.sendNotification(ServiceScene.GAME_PAUSED_NOTIFICATION);
    };
    ServiceSceneMediator.prototype.onResume = function () {
        // enableRendering();
        this.viewComponent.turnOnSounds();
        var playerVOProxy = this.facade.retrieveProxy(PlayerVOProxy.NAME);
        this.viewComponent.updateSoundState(playerVOProxy.vo.settings.sound);
        this.sendNotification(ServiceScene.GAME_RESUMED_NOTIFICATION);
    };
    ServiceSceneMediator.prototype.onContinue = function () {
        this.sendNotification(ServiceScene.CONTINUE_GAME_ON_THIS_DEVICE_NOTIFICATION);
    };
    ServiceSceneMediator.prototype.onClose = function () {
        postRunnable(this.sendNotification, this, ServiceScene.CLOSE_APP_NOTIFICATION);
    };
    ServiceSceneMediator.prototype.onPlayerAvatarUpdated = function () {
        this.sendNotification(ServiceScene.PLAYER_AVATAR_UPDATED_NOTIFICATION);
    };
    ServiceSceneMediator.NAME = 'ServiceSceneMediator';
    return ServiceSceneMediator;
}(BaseSceneMediator));
export default ServiceSceneMediator;
