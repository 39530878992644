var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gameConfig } from '../../constants/GameConfig';
import SocketVOProxy from '../../model/SocketVOProxy';
import { Currency } from '../../model/VO/PlayerVO';
import { AllowPlayMessage } from '../../model/VO/SocketVO';
import InsufficientFundsPopup, { InsufficientFundsPopupAction } from './InsufficientFundsPopup';
import StandardPopupMediator from './StandardPopupMediator';
var InsufficientFundsPopupMediator = /** @class */ (function (_super) {
    __extends(InsufficientFundsPopupMediator, _super);
    function InsufficientFundsPopupMediator() {
        return _super.call(this, InsufficientFundsPopupMediator.NAME) || this;
    }
    InsufficientFundsPopupMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(SocketVOProxy.FIND_ROOM_DENIED_NOTIFICATION);
    };
    InsufficientFundsPopupMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        switch (notificationName) {
            case SocketVOProxy.FIND_ROOM_DENIED_NOTIFICATION:
                {
                    var message = args[0];
                    if (message !== AllowPlayMessage.NOT_ENOUGH_GOLD &&
                        message !== AllowPlayMessage.NOT_ENOUGH_SILVER &&
                        message !== AllowPlayMessage.NOT_ENOUGH_CRYSTAL) {
                        break;
                    }
                    this.showView(message);
                }
                break;
            default:
                break;
        }
    };
    InsufficientFundsPopupMediator.prototype.showView = function (message) {
        switch (message) {
            case AllowPlayMessage.NOT_ENOUGH_GOLD:
                _super.prototype.showView.call(this, gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.55, Currency.GOLD);
                break;
            case AllowPlayMessage.NOT_ENOUGH_SILVER:
                _super.prototype.showView.call(this, gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.55, Currency.SILVER);
                break;
            case AllowPlayMessage.NOT_ENOUGH_CRYSTAL:
                _super.prototype.showView.call(this, gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.55, Currency.CRYSTAL);
                break;
            default:
                break;
        }
    };
    InsufficientFundsPopupMediator.prototype.onAction = function (actionId, currencyType) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (actionId) {
                    case InsufficientFundsPopupAction.EARN:
                        this.sendNotification(InsufficientFundsPopup.EARN_NOTIFICATION, currencyType);
                        break;
                    case InsufficientFundsPopupAction.BUY:
                        this.sendNotification(InsufficientFundsPopup.BUY_NOTIFICATION, currencyType);
                        break;
                    default:
                        break;
                }
                _super.prototype.onAction.call(this, actionId);
                return [2 /*return*/];
            });
        });
    };
    InsufficientFundsPopupMediator.prototype.createView = function () {
        _super.prototype.createView.call(this, new InsufficientFundsPopup());
    };
    InsufficientFundsPopupMediator.NAME = 'InsufficientFundsPopupMediator';
    return InsufficientFundsPopupMediator;
}(StandardPopupMediator));
export default InsufficientFundsPopupMediator;
