var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { callToAPI } from '../../utils/Calls';
import { handleEmptyName } from '../../utils/Utils';
export var apiRoute;
(function (apiRoute) {
    apiRoute["authenticate"] = "auth";
    apiRoute["getRooms"] = "config/getRooms";
    apiRoute["wheelRolled"] = "wheel/rolled";
    apiRoute["updateSetting"] = "updateSettings";
    apiRoute["updateUserInfo"] = "update_user_info";
    apiRoute["getStoreItems"] = "config/getStoreItems";
    apiRoute["checkLevelUp"] = "checkLevelUp";
    apiRoute["userUpdates"] = "userUpdates";
    apiRoute["buyProduct"] = "buyProduct";
    apiRoute["unfinishedPurchase"] = "unfinishedPurchase";
    apiRoute["rewardedVideoWatched"] = "video_viewed";
    apiRoute["checkNotifications"] = "checkNotifications";
})(apiRoute || (apiRoute = {}));
var API = /** @class */ (function () {
    function API() {
    }
    API.authenticate = function (zone, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.authenticate + "/dev", data.jwt, {
                            zone: zone,
                            uid: data.uid,
                            name: handleEmptyName(data.name),
                            gender: data.gender,
                            socialAvatarUrl: data.socialAvatarUrl,
                            device: window.device || null,
                            access_token: data.jwt || null,
                            deviceVersion: data.deviceVersion,
                            deviceModle: data.deviceModel,
                            deviceType: data.deviceType,
                            gameVersion: data.gameVersion,
                        })
                            .then(function (data) {
                            return resolve(data);
                        })
                            .catch(function (reason) {
                            return reject(reason);
                        });
                    })];
            });
        });
    };
    API.getRooms = function (token, userId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.getRooms, token, {
                            userId: userId,
                        })
                            .then(function (data) {
                            return resolve(data);
                        })
                            .catch(function (reason) {
                            return reject(reason);
                        });
                    })];
            });
        });
    };
    API.wheelRolled = function (token, userId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.wheelRolled, token, {
                            userId: userId,
                        })
                            .then(function () {
                            resolve();
                        })
                            .catch(function (reason) {
                            reject(reason);
                        });
                    })];
            });
        });
    };
    API.updateSettings = function (token, userId, setting, value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.updateSetting, token, {
                            userId: userId,
                            setting: setting,
                            value: value,
                        })
                            .then(function () {
                            resolve();
                        })
                            .catch(function (reason) {
                            reject(reason);
                        });
                    })];
            });
        });
    };
    API.checkNotifications = function (token, userId, platform) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.checkNotifications, token, {
                            userId: userId,
                            platform: platform,
                        })
                            .then(function (result) {
                            resolve(result);
                        })
                            .catch(function (reason) {
                            reject(reason);
                        });
                    })];
            });
        });
    };
    API.updateUserInfo = function (token, userId, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.updateUserInfo, token, __assign({ userId: userId }, data))
                            .then(function () {
                            resolve();
                        })
                            .catch(function (reason) {
                            reject(reason);
                        });
                    })];
            });
        });
    };
    API.getStoreItems = function (token, userId, platform) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.getStoreItems, token, { userId: userId, platform: platform })
                            .then(resolve)
                            .catch(reject);
                    })];
            });
        });
    };
    API.checkLevelUp = function (token, userId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.checkLevelUp, token, { userId: userId })
                            .then(resolve)
                            .catch(reject);
                    })];
            });
        });
    };
    API.getUserUpdates = function (token, userId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.userUpdates, token, { userId: userId, jwt: token })
                            .then(resolve)
                            .catch(reject);
                    })];
            });
        });
    };
    API.buyProduct = function (token, userId, platform, receipt, productInfo) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var params = {
                            userId: userId,
                            amount: productInfo ? productInfo.priceAsDecimal : 0,
                            currency: productInfo ? productInfo.currency : 'USD',
                        };
                        callToAPI(apiRoute.buyProduct, token, { params: params, platform: platform, receipt: receipt })
                            .then(resolve)
                            .catch(reject);
                    })];
            });
        });
    };
    API.unfinishedPurchase = function (token, userId, productId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.unfinishedPurchase, token, { userId: userId, productId: productId })
                            .then(resolve)
                            .catch(reject);
                    })];
            });
        });
    };
    API.sendRewardedVideoWatched = function (token, userId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        callToAPI(apiRoute.rewardedVideoWatched, token, { userId: userId })
                            .then(resolve)
                            .catch(reject);
                    })];
            });
        });
    };
    return API;
}());
export default API;
