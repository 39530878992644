var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import ApiVOProxy from '../model/ApiVOProxy';
import SocketVOProxy from '../model/SocketVOProxy';
import BaseStoreVOProxy from '../model/store/BaseStoreVOProxy';
import StoreScene from '../view/scenes/StoreScene';
import AddProductToRecallListCommand from './store/AddProductToRecallListCommand';
import BuyProductCommand from './store/BuyProductCommand';
import ConsumeProductCommand from './store/ConsumeProductCommand';
import RegisterStoreVOProxyCommand from './store/RegisterStoreVOProxyCommand';
import RemoveStoreCommand from './store/RemoveStoreCommand';
import UpdateStoreProductsCommand from './store/UpdateStoreProductsCommand';
import VerifyUnConsumedProductsCommand from './store/VerifyUnConsumedProductsCommand';
var RegisterStoreCommands = /** @class */ (function (_super) {
    __extends(RegisterStoreCommands, _super);
    function RegisterStoreCommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterStoreCommands.prototype.execute = function () {
        this.facade.registerCommand(ApiVOProxy.STORE_DATA_READY_NOTIFICATION, UpdateStoreProductsCommand);
        this.facade.registerCommand(StoreScene.STORE_ITEM_CLICK_NOTIFICATION, BuyProductCommand);
        this.facade.registerCommand(ApiVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, RegisterStoreVOProxyCommand);
        this.facade.registerCommand(ApiVOProxy.PRODUCT_BUY_SUCCEED_NOTIFICATION, ConsumeProductCommand);
        this.facade.registerCommand(ApiVOProxy.PRODUCT_BUY_FAILED_NOTIFICATION, AddProductToRecallListCommand);
        this.facade.registerCommand(SocketVOProxy.RECONNECTED_NOTIFICATION, VerifyUnConsumedProductsCommand);
        this.facade.registerCommand(BaseStoreVOProxy.CHECK_NOT_COMPLETE_PRODUCTS_NOTIFICATION, VerifyUnConsumedProductsCommand);
        this.facade.registerCommand(BaseStoreVOProxy.INITIALIZE_FAILED_NOTIFICATION, RemoveStoreCommand);
    };
    return RegisterStoreCommands;
}(SyncMacroCommand));
export default RegisterStoreCommands;
