var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import IsMethodFacebook from '../Guards/api/sign-in-methods/IsMethodFacebook';
import IsMethodNative from '../Guards/api/sign-in-methods/IsMethodNative';
import IsMobileGuard from '../Guards/device/IsMobileGuard';
import ApiMacroCommand from './ApiMacroCommand';
import LoginViaFacebookCommand from './sign-in-methods/LoginViaFacebookCommand';
import LoginViaNativeCommand from './sign-in-methods/LoginViaNativeCommand';
var OnLoginServiceChoseCommand = /** @class */ (function (_super) {
    __extends(OnLoginServiceChoseCommand, _super);
    function OnLoginServiceChoseCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OnLoginServiceChoseCommand.prototype.initializeMacroCommand = function () {
        this.addExclusiveSubCommand(LoginViaNativeCommand, IsMobileGuard, IsMethodNative);
        this.addExclusiveSubCommand(LoginViaFacebookCommand, IsMethodFacebook);
    };
    return OnLoginServiceChoseCommand;
}(ApiMacroCommand));
export default OnLoginServiceChoseCommand;
