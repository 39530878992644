var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Audios, Fonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import PopupScene from '../../scenes/PopupScene';
import AbstractSettingPopupSection from './AbstractSettingPopupSection';
var SettingsPopupSection = /** @class */ (function (_super) {
    __extends(SettingsPopupSection, _super);
    function SettingsPopupSection(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        return _this;
    }
    SettingsPopupSection.prototype.updateStatus = function (value) {
        if (this.config.status === value) {
            return;
        }
        this.config.status = value;
        this.config.status ? this.turnOn() : this.turnOff();
    };
    SettingsPopupSection.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createNameText();
        this.createSwitcher();
        this.setListeners();
    };
    SettingsPopupSection.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SettingsPopupSection,
        });
        this.add(this.background);
    };
    SettingsPopupSection.prototype.createNameText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextMedium.Name,
            fontSize: 40 * gameConfig.resolutionMultiplier,
            fill: '#c3a469',
        };
        this.nameText = this.scene.make.extText({
            x: this.background.x - this.background.width * 0.45,
            y: 0,
            text: "settings-popup-" + this.config.name,
            style: style,
        });
        this.nameText.setOrigin(0, 0.5);
        this.add(this.nameText);
    };
    SettingsPopupSection.prototype.createSwitcher = function () {
        this.switcher = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: this.config.status
                ? MultiAtlases.Popups.Atlas.Frames.OnBtn
                : MultiAtlases.Popups.Atlas.Frames.OffBtn,
        });
        this.switcher.x = this.background.width * 0.35;
        this.switcher.y = -this.background.height * 0.15;
        this.switcher.setInteractive();
        this.add(this.switcher);
    };
    SettingsPopupSection.prototype.turnOff = function () {
        this.scene.playSFX(Audios.SwitcherOffSfx.Name);
        this.switcher.setTexture(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.OffBtn);
    };
    SettingsPopupSection.prototype.turnOn = function () {
        this.scene.playSFX(Audios.SwitcherOnSfx.Name);
        this.switcher.setTexture(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.OnBtn);
    };
    SettingsPopupSection.prototype.setListeners = function () {
        this.switcher.on(Phaser.Input.Events.POINTER_UP, this.onClick, this);
    };
    SettingsPopupSection.prototype.onClick = function () {
        this.scene.events.emit(PopupScene.SETTING_CHANGE_EVENT, this.config.name);
    };
    return SettingsPopupSection;
}(AbstractSettingPopupSection));
export default SettingsPopupSection;
