var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import RoomsVOProxy from '../model/RoomsVOProxy';
import SocketVOProxy from '../model/SocketVOProxy';
import StateVOProxy from '../model/StateVOProxy';
import ControlView from '../view/components/game/ControlView';
import DeckView from '../view/components/game/DeckView';
import PlayerView from '../view/components/game/PlayerView';
import TurnView from '../view/components/game/TurnView';
import WaitingView from '../view/components/game/WaitingView';
import ExitConfirmationPopup from '../view/popups/ExitConfirmationPopup';
import ReplayPopup from '../view/popups/ReplayPopup';
import WaitOrLeavePopup from '../view/popups/WaitOrLeavePopup';
import LobbyScene from '../view/scenes/LobbyScene';
import RoomScene from '../view/scenes/RoomScene';
import AskForValidCardsCommand from './socket/card-play/AskForValidCardsCommand';
import CardPlayedCommand from './socket/card-play/CardPlayedCommand';
import SendPlayerActionCommand from './socket/card-play/SendPlayerActionCommand';
import DisconnectFromRoomCommand from './socket/connections/DisconnectFromRoomCommand';
import DisconnectUserCommand from './socket/connections/DisconnectUserCommand';
import ForceUpdateGameStateCommand from './socket/connections/ForceUpdateGameStateCommand';
import InitializeSocketCommand from './socket/connections/InitializeSocketCommand';
import JoinToRoomChannelCommand from './socket/connections/JoinToRoomChannelCommand';
import JoinUserChannelCommand from './socket/connections/JoinUserChannelCommand';
import PrepareToJoinRoomChannelCommand from './socket/connections/PrepareToJoinRoomChannelCommand';
import SayHelloToRoomChannelCommand from './socket/connections/SayHelloToRoomChannelCommand';
import PublishQueuedNotificationCommand from './socket/PublishQueuedNotificationCommand';
import RegisterSocketVOProxyCommand from './socket/RegisterSocketVOProxyCommand';
import ReplayCommand from './socket/ReplayCommand';
import ClearSocketVOCommand from './socket/room/ClearSocketVOCommand';
import FindRoomCommand from './socket/room/FindRoomCommand';
import LeaveRoomCommand from './socket/room/LeaveRoomCommand';
import PublishEmojiCommand from './socket/room/PublishEmojiCommand';
import GameScene from '../view/scenes/GameScene';
import RequestGameStateCommand from './socket/connections/RequestGameStateCommand';
var RegisterSocketCommands = /** @class */ (function (_super) {
    __extends(RegisterSocketCommands, _super);
    function RegisterSocketCommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterSocketCommands.prototype.execute = function () {
        this.facade.registerCommand(RoomsVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, RegisterSocketVOProxyCommand);
        this.facade.registerCommand(SocketVOProxy.CONNECTION_SUCCESS_NOTIFICATION, JoinUserChannelCommand);
        this.facade.registerCommand(RoomScene.DOOR_SELECTED_NOTIFICATION, FindRoomCommand);
        this.facade.registerCommand(LobbyScene.BONUS_CHOSE_NOTIFICATION, FindRoomCommand);
        this.facade.registerCommand(SocketVOProxy.READY_TO_JOIN_ROOM_NOTIFICATION, JoinToRoomChannelCommand);
        this.facade.registerCommand(SocketVOProxy.READY_TO_JOIN_ROOM_NOTIFICATION, PrepareToJoinRoomChannelCommand);
        this.facade.registerCommand(PlayerView.CARD_PLAY_NOTIFICATION, CardPlayedCommand);
        this.facade.registerCommand(ReplayPopup.REPLAY_NOTIFICATION, ReplayCommand);
        this.facade.registerCommand(ReplayPopup.LEAVE_NOTIFICATION, LeaveRoomCommand);
        this.facade.registerCommand(WaitOrLeavePopup.LEAVE_CLICKED_NOTIFICATION, LeaveRoomCommand);
        this.facade.registerCommand(ExitConfirmationPopup.EXIT_ROOM_NOTIFICATION, LeaveRoomCommand);
        this.facade.registerCommand(WaitingView.EXIT_CLICKED_NOTIFICATION, LeaveRoomCommand);
        this.facade.registerCommand(GameScene.LEAVE_ROOM_AUTO_NOTIFICATION, LeaveRoomCommand);
        this.facade.registerCommand(PlayerView.BUTTON_ACTION_DONE_NOTIFICATION, SendPlayerActionCommand);
        this.facade.registerCommand(ReplayPopup.USER_REPLAYS_NOTIFICATION, ClearSocketVOCommand);
        this.facade.registerCommand(SocketVOProxy.CONNECTION_FAILED_NOTIFICATION, InitializeSocketCommand);
        this.facade.registerCommand(SocketVOProxy.USER_CHANNEL_JOIN_FAILED_NOTIFICATION, JoinUserChannelCommand);
        this.facade.registerCommand(StateVOProxy.NEXT_PLAYER_NOTIFICATION, AskForValidCardsCommand);
        this.facade.registerCommand(DeckView.DEAL_COMPLETE_NOTIFICATION, AskForValidCardsCommand);
        this.facade.registerCommand(StateVOProxy.GAME_OVER_NOTIFICATION, DisconnectFromRoomCommand);
        // this.facade.registerCommand(
        //   TurnView.CARD_PLAY_DONE_NOTIFICATION,
        //   PublishQueuedNotificationCommand,
        // );
        this.facade.registerCommand(TurnView.ROUND_END_NOTIFICATION, PublishQueuedNotificationCommand);
        this.facade.registerCommand(DeckView.DECK_PLACED_NOTIFICATION, PublishQueuedNotificationCommand);
        this.facade.registerCommand(ControlView.EMOJI_SEND_NOTIFICATION, PublishEmojiCommand);
        this.facade.registerCommand(GameScene.REQUEST_CURRENT_GAME_STATE_NOTIFICATION, ForceUpdateGameStateCommand);
        this.facade.registerCommand(SocketVOProxy.RECONNECTED_NOTIFICATION, ForceUpdateGameStateCommand);
        this.facade.registerCommand(GameScene.RECREATE_NOTIFICATION, RequestGameStateCommand);
        this.facade.registerCommand(SocketVOProxy.ROOM_CHANNEL_JOIN_SUCCESS_NOTIFICATION, SayHelloToRoomChannelCommand);
        this.facade.registerCommand(SocketVOProxy.LOGIN_FROM_OTHER_DEVICE_NOTIFICATION, DisconnectUserCommand);
    };
    return RegisterSocketCommands;
}(SyncMacroCommand));
export default RegisterSocketCommands;
