var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mediator } from '@candywings/pure-mvc';
import CardPlayedCommand from '../../../controller/socket/card-play/CardPlayedCommand';
import CardPlayFailedCommand from '../../../controller/socket/card-play/CardPlayFailedCommand';
import DeckVOProxy from '../../../model/DeckVOProxy';
import PlayerVOProxy from '../../../model/PlayerVOProxy';
import SocketVOProxy from '../../../model/SocketVOProxy';
import StateVOProxy from '../../../model/StateVOProxy';
import { ValidationActionType, } from '../../../model/VO/SocketVO';
import GameScene from '../../scenes/GameScene';
import { getScene } from '../../utils/phaser/PhaserUtils';
import DeckView from './DeckView';
import DurakPlayerView from './DurakPlayerView';
import PlayerAvatarView from './PlayerAvatarView';
import PlayerView from './PlayerView';
import TurnView from './TurnView';
var PlayerViewMediator = /** @class */ (function (_super) {
    __extends(PlayerViewMediator, _super);
    function PlayerViewMediator() {
        return _super.call(this, PlayerViewMediator.NAME, null) || this;
    }
    PlayerViewMediator.prototype.onRemove = function () {
        this.viewComponent && this.viewComponent.destroy();
        _super.prototype.onRemove.call(this);
    };
    PlayerViewMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(GameScene.FORCE_START_NOTIFICATION, GameScene.CREATE_NOTIFICATION, GameScene.RECREATE_NOTIFICATION, GameScene.REPLAY_NOTIFICATION, DeckVOProxy.READY_TO_DEAL_NOTIFICATION, DeckView.DEAL_CARD_NOTIFICATION, DeckView.DEAL_COMPLETE_NOTIFICATION, StateVOProxy.NEXT_PLAYER_NOTIFICATION, SocketVOProxy.ROUND_ENDED_NOTIFICATION, SocketVOProxy.TURN_PASSED_NOTIFICATION, SocketVOProxy.PLAYER_TAKES_NOTIFICATION, TurnView.GET_CARDS_NOTIFICATION, TurnView.HIGH_LIGHTED_CARD_CLICKED_NOTIFICATION, 
        // TurnView.CARD_PLAY_DONE_NOTIFICATION,
        CardPlayedCommand.FAILED_NOTIFICATION, CardPlayFailedCommand.FAILED_NOTIFICATION, PlayerAvatarView.TIMER_COMPLETE_NOTIFICATION, StateVOProxy.CARD_PLAYED_NOTIFICATION, StateVOProxy.VALIDATE_CARDS_NOTIFICATION, StateVOProxy.GAME_OVER_NOTIFICATION);
    };
    PlayerViewMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, trump, playerIndex, card, playerIndex, cards, playedCardData, card, card, target, card;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = notificationName;
                        switch (_a) {
                            case GameScene.FORCE_START_NOTIFICATION: return [3 /*break*/, 1];
                            case GameScene.CREATE_NOTIFICATION: return [3 /*break*/, 2];
                            case GameScene.RECREATE_NOTIFICATION: return [3 /*break*/, 2];
                            case GameScene.REPLAY_NOTIFICATION: return [3 /*break*/, 2];
                            case DeckVOProxy.READY_TO_DEAL_NOTIFICATION: return [3 /*break*/, 3];
                            case SocketVOProxy.PLAYER_TAKES_NOTIFICATION: return [3 /*break*/, 4];
                            case StateVOProxy.NEXT_PLAYER_NOTIFICATION: return [3 /*break*/, 5];
                            case SocketVOProxy.TURN_PASSED_NOTIFICATION: return [3 /*break*/, 6];
                            case PlayerAvatarView.TIMER_COMPLETE_NOTIFICATION: return [3 /*break*/, 6];
                            case DeckView.DEAL_CARD_NOTIFICATION: return [3 /*break*/, 7];
                            case DeckView.DEAL_COMPLETE_NOTIFICATION: return [3 /*break*/, 8];
                            case TurnView.GET_CARDS_NOTIFICATION: return [3 /*break*/, 9];
                            case CardPlayedCommand.FAILED_NOTIFICATION: return [3 /*break*/, 10];
                            case CardPlayFailedCommand.FAILED_NOTIFICATION: return [3 /*break*/, 10];
                            case StateVOProxy.VALIDATE_CARDS_NOTIFICATION: return [3 /*break*/, 12];
                            case StateVOProxy.CARD_PLAYED_NOTIFICATION: return [3 /*break*/, 13];
                            case SocketVOProxy.ROUND_ENDED_NOTIFICATION: return [3 /*break*/, 14];
                            case TurnView.HIGH_LIGHTED_CARD_CLICKED_NOTIFICATION: return [3 /*break*/, 15];
                            case TurnView.CARD_PLAY_DONE_NOTIFICATION: return [3 /*break*/, 16];
                            case StateVOProxy.GAME_OVER_NOTIFICATION: return [3 /*break*/, 17];
                        }
                        return [3 /*break*/, 18];
                    case 1:
                        {
                            this.setView();
                            this.viewComponent.setTrump(this.stateVOProxy.vo.deckData.trump.suit);
                            (_b = this.viewComponent).createCards.apply(_b, this.stateVOProxy.vo.players[this.stateVOProxy.vo.players.length - 1].cards);
                            this.onValidate();
                        }
                        return [3 /*break*/, 19];
                    case 2:
                        this.setView();
                        return [3 /*break*/, 19];
                    case 3:
                        {
                            trump = this.stateVOProxy.vo.deckData.trump;
                            this.viewComponent.setTrump(trump.suit);
                            this.unsubscribeToNotification(DeckVOProxy.READY_TO_DEAL_NOTIFICATION);
                        }
                        return [3 /*break*/, 19];
                    case 4:
                        this.viewComponent.repositionCards();
                        _c.label = 5;
                    case 5:
                        if (this.stateVOProxy.vo.turnData.currentPlayer.userId !=
                            this.stateVOProxy.vo.players.length - 1) {
                            this.viewComponent.repositionCards();
                        }
                        _c.label = 6;
                    case 6:
                        this.viewComponent.disableAction();
                        return [3 /*break*/, 19];
                    case 7:
                        {
                            playerIndex = args[0];
                            if (playerIndex !== this.stateVOProxy.vo.players.length - 1) {
                                return [3 /*break*/, 19];
                            }
                            card = args[1];
                            this.viewComponent.getDealedCard(card);
                            return [3 /*break*/, 19];
                        }
                        _c.label = 8;
                    case 8:
                        this.onValidate();
                        return [3 /*break*/, 19];
                    case 9:
                        {
                            playerIndex = args[0];
                            if (playerIndex !== this.stateVOProxy.vo.players.length - 1) {
                                return [3 /*break*/, 19];
                            }
                            cards = args[1];
                            this.takeCards(cards);
                        }
                        return [3 /*break*/, 19];
                    case 10:
                        this.viewComponent.getBackSelectedCard();
                        this.viewComponent.disableAction();
                        return [4 /*yield*/, this.viewComponent.repositionCards()];
                    case 11:
                        _c.sent();
                        _c.label = 12;
                    case 12:
                        this.onValidate();
                        return [3 /*break*/, 19];
                    case 13:
                        {
                            playedCardData = args[0];
                            this.viewComponent.disableAction();
                            if (playedCardData.userIndex ===
                                this.stateVOProxy.vo.players.length - 1) {
                                card = this.viewComponent.getLastPlayedCard(playedCardData.card);
                                card && card.setDarkerState(false);
                                this.sendNotification(DurakPlayerView.PLAYED_CARD_NOTIFICATION, card, playedCardData.target);
                            }
                            this.viewComponent.repositionCards(false);
                        }
                        return [3 /*break*/, 19];
                    case 14:
                        this.viewComponent.disableAction();
                        return [3 /*break*/, 19];
                    case 15:
                        {
                            card = args[0];
                            target = args[1];
                            this.onCardPlay(card, target);
                        }
                        return [3 /*break*/, 19];
                    case 16:
                        {
                            card = args[0];
                            this.viewComponent.checkAndRemoveCard(card);
                            this.viewComponent.repositionCards();
                        }
                        return [3 /*break*/, 19];
                    case 17:
                        this.viewComponent.clearCards();
                        return [3 /*break*/, 19];
                    case 18:
                        console.warn(notificationName + " is unhandled!");
                        return [3 /*break*/, 19];
                    case 19: return [2 /*return*/];
                }
            });
        });
    };
    PlayerViewMediator.prototype.setView = function () {
        var view = new PlayerView(this.scene);
        this.scene.add.existing(view);
        this.setViewComponent(view);
        this.setViewComponentListeners();
    };
    PlayerViewMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.on(PlayerView.CARD_PLAY_EVENT, this.onCardPlay, this);
        this.viewComponent.on(PlayerView.BUTTON_ACTION_EVENT, this.onButtonAction, this);
        this.viewComponent.on(PlayerView.CARD_SELECTED_EVENT, this.onCardSelected, this);
        this.viewComponent.on(PlayerView.CARD_UNSELECTED_EVENT, this.onCardUnselected, this);
    };
    PlayerViewMediator.prototype.takeCards = function (cards) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.viewComponent.getCards(cards)];
                    case 1:
                        _a.sent();
                        this.sendNotification(PlayerView.GOT_CARDS_NOTIFICATION);
                        return [2 /*return*/];
                }
            });
        });
    };
    PlayerViewMediator.prototype.onCardPlay = function (card, target) {
        // this.subscribeToNotifications(TurnView.CARD_PLAY_DONE_NOTIFICATION);
        this.sendNotification(PlayerView.CARD_PLAY_NOTIFICATION, card, target);
        this.viewComponent.disableAction();
    };
    PlayerViewMediator.prototype.onButtonAction = function (actionName) {
        this.viewComponent.disableAction();
        this.viewComponent.repositionCards();
        this.sendNotification(PlayerView.BUTTON_ACTION_DONE_NOTIFICATION, actionName);
    };
    PlayerViewMediator.prototype.onValidate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var validCards;
            return __generator(this, function (_a) {
                this.viewComponent.repositionCards();
                if (!this.stateVOProxy.vo.turnData.currentPlayer ||
                    this.stateVOProxy.vo.turnData.currentPlayer.userId !==
                        this.stateVOProxy.vo.players.length - 1) {
                    this.viewComponent.setAction(ValidationActionType.DISABLED);
                    this.viewComponent.disableAction();
                    return [2 /*return*/];
                }
                validCards = this.stateVOProxy.vo.turnData
                    .validatedCards;
                this.viewComponent.validateCards(validCards);
                this.viewComponent.setAction(this.stateVOProxy.vo.turnData.action || ValidationActionType.DISABLED);
                return [2 /*return*/];
            });
        });
    };
    PlayerViewMediator.prototype.onCardSelected = function (card) {
        this.sendNotification(PlayerView.CARD_SELECTED_NOTIFICATION, card);
    };
    PlayerViewMediator.prototype.onCardUnselected = function (validate) {
        this.sendNotification(PlayerView.CARD_UNSELECTED_NOTIFICATION);
        validate && this.onValidate();
    };
    Object.defineProperty(PlayerViewMediator.prototype, "playerVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(PlayerVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerViewMediator.prototype, "stateVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(StateVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerViewMediator.prototype, "scene", {
        get: function () {
            return getScene(GameScene.NAME);
        },
        enumerable: true,
        configurable: true
    });
    PlayerViewMediator.NAME = 'PlayerViewMediator';
    return PlayerViewMediator;
}(Mediator));
export default PlayerViewMediator;
