var StoreVO = /** @class */ (function () {
    function StoreVO() {
        this.apiData = {
            gold: [],
            crystal: [],
            silver: [],
            free: [],
            discounts: [],
        };
        this.serviceData = {
            gold: [],
            crystal: [],
            silver: [],
            free: [],
        };
        this.mergedData = {
            gold: [],
            crystal: [],
            silver: [],
            free: [],
        };
        this.updated = false;
        this.interval = null;
        this.buyFailedProducts = [];
        this.consumeFailedProducts = [];
    }
    return StoreVO;
}());
export default StoreVO;
export var StoreItemState;
(function (StoreItemState) {
    StoreItemState[StoreItemState["NORMAL"] = 0] = "NORMAL";
    StoreItemState[StoreItemState["BEST_VALUE"] = 1] = "BEST_VALUE";
    StoreItemState[StoreItemState["BEST_SELLER"] = 2] = "BEST_SELLER";
})(StoreItemState || (StoreItemState = {}));
export var StoreItemActionType;
(function (StoreItemActionType) {
    StoreItemActionType["BUY"] = "buy";
    StoreItemActionType["WATCH"] = "watch";
    StoreItemActionType["SHARE"] = "share";
    StoreItemActionType["QUEST"] = "quest";
    StoreItemActionType["SOON"] = "soon";
})(StoreItemActionType || (StoreItemActionType = {}));
export var DiscountType;
(function (DiscountType) {
    DiscountType[DiscountType["ALL"] = 0] = "ALL";
    DiscountType[DiscountType["GOLD"] = 1] = "GOLD";
    DiscountType[DiscountType["CRYSTAL"] = 2] = "CRYSTAL";
    DiscountType[DiscountType["SILVER"] = 3] = "SILVER";
})(DiscountType || (DiscountType = {}));
