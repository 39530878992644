var PlayerVO = /** @class */ (function () {
    function PlayerVO() {
        this.playerCurrency = {
            gold: 0,
            silver: 0,
            crystal: 0,
        };
        this.playerStatistics = {
            wins: 0,
            loses: 0,
            draws: 0,
            outs: 0,
        };
        this.playerLevel = {
            level: 0,
            xp: 0,
            edge: 200,
        };
        this.playerName = '';
    }
    return PlayerVO;
}());
export default PlayerVO;
export var Gender;
(function (Gender) {
    Gender[Gender["NONE"] = null] = "NONE";
    Gender[Gender["MALE"] = 1] = "MALE";
    Gender[Gender["FEMALE"] = 2] = "FEMALE";
})(Gender || (Gender = {}));
export var BonusType;
(function (BonusType) {
    BonusType["GIFT"] = "gift";
    BonusType["REGISTRATION"] = "registration";
})(BonusType || (BonusType = {}));
export var Currency;
(function (Currency) {
    Currency["GOLD"] = "gold";
    Currency["SILVER"] = "silver";
    Currency["CRYSTAL"] = "crystal";
})(Currency || (Currency = {}));
export var Setting;
(function (Setting) {
    Setting["SOUND"] = "sound";
    Setting["MUSIC"] = "music";
    Setting["VIBRATION"] = "vibration";
    Setting["MESSAGES"] = "messages";
    Setting["LANGUAGE"] = "language";
})(Setting || (Setting = {}));
