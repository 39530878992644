var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import PlayerVOProxy from '../model/PlayerVOProxy';
import RegisterRoomsProxyCommand from './rooms/RegisterRoomsProxyCommand';
var RegisterRoomsCommands = /** @class */ (function (_super) {
    __extends(RegisterRoomsCommands, _super);
    function RegisterRoomsCommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterRoomsCommands.prototype.execute = function () {
        this.facade.registerCommand(PlayerVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, RegisterRoomsProxyCommand);
    };
    return RegisterRoomsCommands;
}(SyncMacroCommand));
export default RegisterRoomsCommands;
