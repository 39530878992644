var AdsVO = /** @class */ (function () {
    function AdsVO() {
        this.banner = {
            isAvailable: false,
            isReady: false,
            isPending: false
        };
        this.interstitial = {
            isAvailable: false,
            isReady: false,
            isPending: false
        };
        this.video = {
            isAvailable: true,
            isReady: false,
            isPending: false
        };
    }
    return AdsVO;
}());
export default AdsVO;
