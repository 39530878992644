var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Atlases, Fonts, Images, MultiAtlases } from '../../../assets';
import { defaultValues } from '../../../constants/Constants';
import { gameConfig } from '../../../constants/GameConfig';
import { Translation } from '../../../translations';
import TopBarScene, { TopBarAction } from '../../scenes/TopBarScene';
import { hasKey } from '../../utils/phaser/PhaserUtils';
import TopBarLevel from './TopBarLevel';
import XpElement from './XpElement';
var TopBarPLayerInfo = /** @class */ (function (_super) {
    __extends(TopBarPLayerInfo, _super);
    function TopBarPLayerInfo(scene, x, y) {
        var _this = _super.call(this, scene, x, y) || this;
        _this.scene = scene;
        _this.createBody();
        _this.setListeners();
        return _this;
    }
    TopBarPLayerInfo.prototype.updateLevel = function (level) {
        this.level.updateValue(level);
    };
    TopBarPLayerInfo.prototype.updateName = function (name) {
        this.nameText.setText(name);
    };
    TopBarPLayerInfo.prototype.updateId = function (id) {
        this.id = id;
        this.idText.setText(Translation.TOP_BAR_USER_ID, {
            id: this.id,
        });
    };
    TopBarPLayerInfo.prototype.updateAvatar = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var transformMatrix;
            return __generator(this, function (_a) {
                this.avatarImage.setTexture(key);
                this.avatarImage.setScale((this.avatarFrame.height * 0.75) / this.avatarImage.height);
                transformMatrix = this.avatarFrame.getWorldTransformMatrix();
                this.avatarMaskGraphics.clear();
                this.avatarMaskGraphics.fillStyle(0xffffff);
                this.avatarMaskGraphics.fillCircle(transformMatrix.tx, transformMatrix.ty - this.avatarFrame.displayHeight * 0.04, this.avatarFrame.displayWidth * 0.375);
                return [2 /*return*/];
            });
        });
    };
    TopBarPLayerInfo.prototype.createBody = function () {
        this.createFlag();
        this.createAvatarFrame();
        this.createAvatarImage();
        this.createAvatarBackground();
        this.bringToTop(this.avatarImage);
        this.bringToTop(this.avatarFrame);
        this.createNameText();
        this.createPlayerIdText();
        this.createLevel();
        this.createLevelProgress();
    };
    TopBarPLayerInfo.prototype.showFlag = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        if (_this.flag.scaleY === _this.flag.scaleX) {
                            return resolve();
                        }
                        _this.scene.tweens.killTweensOf(_this.flag);
                        _this.flag.setScale(_this.flag.scaleX, 0);
                        _this.scene.tweens.add({
                            targets: _this.flag,
                            scaleY: _this.flag.scaleX,
                            ease: Phaser.Math.Easing.Back.Out,
                            duration: 500,
                            easeParams: [0.5],
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    TopBarPLayerInfo.prototype.hideFlag = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.scene.tweens.killTweensOf(_this.flag);
                        _this.scene.tweens.add({
                            targets: _this.flag,
                            scaleY: 0,
                            ease: Phaser.Math.Easing.Back.Out,
                            easeParams: [0.5],
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    TopBarPLayerInfo.prototype.createFlag = function () {
        this.flag = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Flags.Atlas.Name,
            frame: MultiAtlases.Flags.Atlas.Frames.FlagLeagueNone,
        });
        this.flag.setScale(0.4);
        this.flag.y = -this.flag.displayHeight * 0.6;
        this.flag.setOrigin(0.5, 0);
        this.add(this.flag);
    };
    TopBarPLayerInfo.prototype.createAvatarBackground = function () {
        var center = this.avatarImage.getCenter();
        this.avatarBackground = this.scene.make.image({
            x: center.x,
            y: center.y,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.AvatarPicBg,
        });
        this.avatarBackground.setScale((this.avatarImage.displayWidth - 10) / this.avatarBackground.width);
        this.add(this.avatarBackground);
    };
    TopBarPLayerInfo.prototype.createAvatarImage = function () {
        var key = hasKey(defaultValues.playerAvatar)
            ? defaultValues.playerAvatar
            : Images.Avatar.Name;
        this.avatarImage = this.scene.make.image({
            x: this.avatarFrame.x,
            y: this.avatarFrame.y,
            key: key,
        });
        this.avatarImage.setScale((this.avatarFrame.height * 0.75) / this.avatarImage.height);
        this.avatarImage.setOrigin(0.5, 0.58);
        this.add(this.avatarImage);
        this.avatarMaskGraphics = this.scene.make.graphics({});
        this.avatarImage.setMask(new Phaser.Display.Masks.GeometryMask(this.scene, this.avatarMaskGraphics));
    };
    TopBarPLayerInfo.prototype.createAvatarFrame = function () {
        this.avatarFrame = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Topbar.Atlas.Name,
            frame: Atlases.Topbar.Atlas.Frames.AvatarFrame,
        });
        this.add(this.avatarFrame);
    };
    TopBarPLayerInfo.prototype.createNameText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextMedium.Name,
            fontSize: 38 * gameConfig.resolutionMultiplier,
            fill: '#ffedcb',
        };
        this.nameText = this.scene.make.text({
            x: this.avatarImage.x + this.avatarImage.displayWidth * 0.88,
            y: this.avatarImage.y - this.avatarImage.displayHeight * 0.33,
            text: '',
            style: style,
        });
        this.nameText.setOrigin(0, 0.5);
        this.add(this.nameText);
    };
    TopBarPLayerInfo.prototype.createPlayerIdText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextMedium.Name,
            fontSize: 26 * gameConfig.resolutionMultiplier,
            fill: '#ffedcb',
        };
        this.idText = this.scene.make.extText({
            x: this.nameText.x,
            y: this.nameText.y + this.nameText.height * 1.05,
            text: Translation.TOP_BAR_USER_ID,
            style: style,
        });
        this.idText.setOrigin(0, 0.7);
        this.add(this.idText);
    };
    TopBarPLayerInfo.prototype.createLevelProgress = function () {
        this.xp = new XpElement(this.scene);
        this.xp.setScale(0.6);
        this.xp.x = this.nameText.x + this.xp.displayWidth * 0.49;
        this.xp.y = this.idText.y + this.idText.height * 0.9;
        this.add(this.xp);
        this.xp.on(XpElement.LEVEL_CHANGED_EVENT, this.updateLevel, this);
    };
    TopBarPLayerInfo.prototype.createLevel = function () {
        this.level = new TopBarLevel(this.scene);
        this.level.x = this.avatarFrame.x + this.avatarFrame.displayWidth * 0.295;
        this.level.y = this.avatarFrame.y + this.avatarFrame.displayHeight * 0.175;
        this.add(this.level);
    };
    TopBarPLayerInfo.prototype.setListeners = function () {
        this.flag.setInteractive();
        this.flag.on(Phaser.Input.Events.POINTER_UP, this.onAvatarClick, this);
    };
    TopBarPLayerInfo.prototype.onAvatarClick = function () {
        this.scene.events.emit(TopBarScene.ACTION_DONE_EVENT, TopBarAction.USER_INFO);
    };
    return TopBarPLayerInfo;
}(Phaser.GameObjects.Container));
export default TopBarPLayerInfo;
