import { Atlases, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
export function addNinePatchDefaults(game) {
    // Login
    game.cache.custom.ninePatch.add(MultiAtlases.Login.Atlas.Frames.MessageBox, {
        top: 32 * gameConfig.resolutionMultiplier,
        left: 43 * gameConfig.resolutionMultiplier,
        right: 31 * gameConfig.resolutionMultiplier,
    });
    game.cache.custom.ninePatch.add(MultiAtlases.Login.Atlas.Frames.GenderSelectionFrame, {
        top: 16 * gameConfig.resolutionMultiplier,
    });
    game.cache.custom.ninePatch.add(MultiAtlases.Login.Atlas.Frames.AvatarsContainerBackground, {
        top: 16 * gameConfig.resolutionMultiplier,
    });
    // TopBar
    game.cache.custom.ninePatch.add(Atlases.Topbar.Atlas.Frames.Background, {
        top: 73 * gameConfig.resolutionMultiplier,
        left: 69 * gameConfig.resolutionMultiplier,
    });
    // Store
    game.cache.custom.ninePatch.add(MultiAtlases.Store.Atlas.Frames.TitleBackground, {
        top: 37 * gameConfig.resolutionMultiplier,
        left: 67 * gameConfig.resolutionMultiplier,
    });
    // GamePlay
    game.cache.custom.ninePatch.add(MultiAtlases.GamePlay.Atlas.Frames.ScreenFrameLeft, {
        top: gameConfig.designHeight * 0.11,
        left: 0,
    });
    game.cache.custom.ninePatch.add(MultiAtlases.GamePlay.Atlas.Frames.ScreenFrameRight, {
        top: gameConfig.designHeight * 0.11,
        left: 0,
    });
    game.cache.custom.ninePatch.add(MultiAtlases.GamePlay.Atlas.Frames.Tooltip, {
        top: 20 * gameConfig.resolutionMultiplier,
        bottom: 40 * gameConfig.resolutionMultiplier,
        left: 30 * gameConfig.resolutionMultiplier,
        right: 10 * gameConfig.resolutionMultiplier,
    });
    // Popups
    game.cache.custom.ninePatch.add(MultiAtlases.Popups.Atlas.Frames.SettingsPopupLanguageBg, {
        top: 12 * gameConfig.resolutionMultiplier,
        left: 9 * gameConfig.resolutionMultiplier,
    });
    game.cache.custom.ninePatch.add(MultiAtlases.Popups.Atlas.Frames.RewardPopupTitle, {
        top: 0,
        left: 60 * gameConfig.resolutionMultiplier,
    });
}
