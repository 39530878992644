var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SimpleCommand } from '@candywings/pure-mvc';
import BaseStoreVOProxy from '../../model/store/BaseStoreVOProxy';
import CordovaStoreVOProxy from '../../model/store/CordovaStoreVOProxy';
import OkStoreVOProxy from '../../model/store/OkStoreVOProxy';
import VkStoreVOProxy from '../../model/store/VkStoreVOProxy';
import { getZone, isMobile } from '../../utils/Utils';
var RegisterStoreVOProxyCommand = /** @class */ (function (_super) {
    __extends(RegisterStoreVOProxyCommand, _super);
    function RegisterStoreVOProxyCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterStoreVOProxyCommand.prototype.execute = function () {
        switch (true) {
            case getZone() === 'cordova' && isMobile():
                this.facade.registerProxy(new CordovaStoreVOProxy());
                break;
            case getZone() === 'ok':
                this.facade.registerProxy(new OkStoreVOProxy());
                break;
            case getZone() === 'vk':
                this.facade.registerProxy(new VkStoreVOProxy());
                break;
            default:
                this.sendNotification(BaseStoreVOProxy.NOT_SUPPORTED_NOTIFICATION);
                break;
        }
    };
    return RegisterStoreVOProxyCommand;
}(SimpleCommand));
export default RegisterStoreVOProxyCommand;
