var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var RoomName = /** @class */ (function (_super) {
    __extends(RoomName, _super);
    function RoomName(scene) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.createBody();
        return _this;
    }
    RoomName.prototype.show = function () {
        this.scene.tweens.killTweensOf(this);
        this.scene.tweens.add({
            targets: this,
            scaleX: 1,
            scaleY: 1,
            duration: 200,
            ease: Phaser.Math.Easing.Back.Out,
        });
    };
    RoomName.prototype.hide = function () {
        this.scene.tweens.killTweensOf(this);
        this.scene.tweens.add({
            targets: this,
            scaleX: 0,
            scaleY: 0,
            duration: 200,
            ease: Phaser.Math.Easing.Back.In,
        });
    };
    RoomName.prototype.setName = function (name) {
        _super.prototype.setName.call(this, name);
        this.text.setText(this.name);
        this.text.setScale(Math.min(1, (this.background.width * 0.9) / this.text.width));
        return this;
    };
    RoomName.prototype.createBody = function () {
        this.createBackground();
        this.createText();
    };
    RoomName.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.RoomNameBackground,
        });
        this.add(this.background);
    };
    RoomName.prototype.createText = function () {
        var style = {
            fontFamily: Fonts.RobotoSlab.Name,
            fontSize: 32 * gameConfig.resolutionMultiplier,
            fill: '#785738',
        };
        this.text = this.scene.make.extText({
            x: 0,
            y: 0,
            text: this.name,
            style: style,
        });
        this.text.setOrigin(0.5);
        this.add(this.text);
        this.text.setScale(Math.min(1, (this.background.width * 0.9) / this.text.width));
    };
    return RoomName;
}(Phaser.GameObjects.Container));
export default RoomName;
