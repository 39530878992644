var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import IsLoggedInWithFacebook from '../../Guards/api/sign-in-check/IsLoggedInWithFacebook';
import IsLoggedInWithNativeGuard from '../../Guards/api/sign-in-check/IsLoggedInWithNativeGuard';
import IsTest from '../../Guards/api/sign-in-check/IsTest';
import IsCordovaGuard from '../../Guards/device/IsCordovaGuard';
import IsMobileGuard from '../../Guards/device/IsMobileGuard';
import RetrieveUserDataFromFacebookCommand from '../retrieve-user-data/RetrieveUserDataFromFacebookCommand';
import RetrieveUserDataFromNativeCommand from '../retrieve-user-data/RetrieveUserDataFromNativeCommand';
import LogInDevelopmentModeCommand from '../sign-in-methods/LogInDevelopmentModeCommand';
import NoLoginInformationCommand from '../sign-in-methods/NoLoginInformationCommand';
var CordovaCheckForLoginCommand = /** @class */ (function (_super) {
    __extends(CordovaCheckForLoginCommand, _super);
    function CordovaCheckForLoginCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CordovaCheckForLoginCommand.prototype.prepare = function () {
        this.addGuards(IsCordovaGuard);
    };
    CordovaCheckForLoginCommand.prototype.initializeMacroCommand = function () {
        this.addExclusiveSubCommand(LogInDevelopmentModeCommand, IsTest);
        this.addExclusiveSubCommand(RetrieveUserDataFromNativeCommand, IsMobileGuard, IsLoggedInWithNativeGuard);
        this.addExclusiveSubCommand(RetrieveUserDataFromFacebookCommand, IsLoggedInWithFacebook);
        this.addExclusiveSubCommand(NoLoginInformationCommand);
    };
    return CordovaCheckForLoginCommand;
}(SyncMacroCommand));
export default CordovaCheckForLoginCommand;
