var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { oneSignalId } from '../../constants/Constants';
import IsCordovaGuard from '../Guards/device/IsCordovaGuard';
import ApiVOProxy from '../../model/ApiVOProxy';
import IsMobileGuard from '../Guards/device/IsMobileGuard';
import APICommand from './APICommand';
var SetupOneSignalCommand = /** @class */ (function (_super) {
    __extends(SetupOneSignalCommand, _super);
    function SetupOneSignalCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SetupOneSignalCommand.prototype.prepare = function () {
        this.addGuards(IsCordovaGuard, IsMobileGuard);
    };
    SetupOneSignalCommand.prototype.execute = function () {
        var _this = this;
        var iosSettings = {
            kOSSettingsKeyAutoPrompt: true,
            kOSSettingsKeyInAppLaunchURL: false,
        };
        window.cordovaPlugins.OneSignal.startInit(oneSignalId)
            .handleNotificationOpened(function (data) {
            console.warn('notification opened');
            console.warn(data);
            _this.sendNotification(ApiVOProxy.NOTIFICATION_OPENED_NOTIFICATION, data);
        })
            .iOSSettings(iosSettings)
            .endInit();
        window.cordovaPlugins.OneSignal.setInFocusDisplaying(0 /* None */);
        window.cordovaPlugins.OneSignal.setExternalUserId("" + this.proxy.vo.userId);
        window.cordovaPlugins.OneSignal.enableSound(true);
        window.cordovaPlugins.OneSignal.enableVibrate(true);
    };
    return SetupOneSignalCommand;
}(APICommand));
export default SetupOneSignalCommand;
