var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mediator } from '@candywings/pure-mvc';
import { gameConfig } from '../../../constants/GameConfig';
import CardPlayedCommand from '../../../controller/socket/card-play/CardPlayedCommand';
import CardPlayFailedCommand from '../../../controller/socket/card-play/CardPlayFailedCommand';
import SocketVOProxy from '../../../model/SocketVOProxy';
import StateVOProxy from '../../../model/StateVOProxy';
import GameScene from '../../scenes/GameScene';
import { delayRunnable, getScene } from '../../utils/phaser/PhaserUtils';
import DurakPlayerView from './DurakPlayerView';
import PlayerAvatarView from './PlayerAvatarView';
import PlayerView from './PlayerView';
import TurnView from './TurnView';
var TurnViewMediator = /** @class */ (function (_super) {
    __extends(TurnViewMediator, _super);
    function TurnViewMediator() {
        return _super.call(this, TurnViewMediator.NAME, null) || this;
    }
    TurnViewMediator.prototype.onRemove = function () {
        this.viewComponent && this.viewComponent.destroy();
        _super.prototype.onRemove.call(this);
    };
    TurnViewMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(GameScene.CREATE_NOTIFICATION, GameScene.RECREATE_NOTIFICATION, GameScene.REPLAY_NOTIFICATION, PlayerView.BUTTON_ACTION_DONE_NOTIFICATION, PlayerView.CARD_SELECTED_NOTIFICATION, PlayerView.CARD_UNSELECTED_NOTIFICATION, PlayerAvatarView.TIMER_COMPLETE_NOTIFICATION, DurakPlayerView.PLAYED_CARD_NOTIFICATION, SocketVOProxy.TURN_PASSED_NOTIFICATION, SocketVOProxy.PLAYER_TAKES_NOTIFICATION, StateVOProxy.VALIDATE_CARDS_NOTIFICATION, StateVOProxy.BITA_NOTIFICATION, StateVOProxy.PLAYER_TOOK_NOTIFICATION, CardPlayFailedCommand.FAILED_NOTIFICATION, CardPlayedCommand.FAILED_NOTIFICATION, GameScene.FORCE_START_NOTIFICATION, 
        // PlayerView.GOT_CARDS_NOTIFICATION,
        // OpponentView.GOT_CARDS_NOTIFICATION,
        StateVOProxy.NEXT_PLAYER_NOTIFICATION);
    };
    TurnViewMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, stateVOProxy, card, card, target, cards, userId, cards;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = notificationName;
                        switch (_a) {
                            case GameScene.FORCE_START_NOTIFICATION: return [3 /*break*/, 1];
                            case GameScene.CREATE_NOTIFICATION: return [3 /*break*/, 2];
                            case GameScene.RECREATE_NOTIFICATION: return [3 /*break*/, 2];
                            case GameScene.REPLAY_NOTIFICATION: return [3 /*break*/, 2];
                            case SocketVOProxy.TURN_PASSED_NOTIFICATION: return [3 /*break*/, 3];
                            case SocketVOProxy.PLAYER_TAKES_NOTIFICATION: return [3 /*break*/, 3];
                            case PlayerView.BUTTON_ACTION_DONE_NOTIFICATION: return [3 /*break*/, 3];
                            case PlayerView.CARD_SELECTED_NOTIFICATION: return [3 /*break*/, 4];
                            case DurakPlayerView.PLAYED_CARD_NOTIFICATION: return [3 /*break*/, 5];
                            case PlayerAvatarView.TIMER_COMPLETE_NOTIFICATION: return [3 /*break*/, 6];
                            case PlayerView.CARD_UNSELECTED_NOTIFICATION: return [3 /*break*/, 6];
                            case CardPlayFailedCommand.FAILED_NOTIFICATION: return [3 /*break*/, 6];
                            case CardPlayedCommand.FAILED_NOTIFICATION: return [3 /*break*/, 6];
                            case StateVOProxy.VALIDATE_CARDS_NOTIFICATION: return [3 /*break*/, 6];
                            case StateVOProxy.BITA_NOTIFICATION: return [3 /*break*/, 7];
                            case StateVOProxy.PLAYER_TOOK_NOTIFICATION: return [3 /*break*/, 9];
                        }
                        return [3 /*break*/, 10];
                    case 1:
                        {
                            this.createView();
                            stateVOProxy = this.facade.retrieveProxy(StateVOProxy.NAME);
                            this.viewComponent.forceUpdateCards(stateVOProxy.vo.turnData.cardPairs);
                        }
                        return [3 /*break*/, 11];
                    case 2:
                        this.createView();
                        return [3 /*break*/, 11];
                    case 3:
                        this.viewComponent.disableZones();
                        return [3 /*break*/, 11];
                    case 4:
                        {
                            card = args[0];
                            this.viewComponent.hideHighLights();
                            card.swapEnabled
                                ? this.viewComponent.enableSwapArea()
                                : this.viewComponent.disableSwapArea();
                            card.targets && this.viewComponent.activateCardZones(card);
                            return [3 /*break*/, 11];
                        }
                        _b.label = 5;
                    case 5:
                        {
                            card = args[0];
                            target = args[1];
                            this.viewComponent.addCard(card, target);
                            this.viewComponent.disableCardZones();
                            this.viewComponent.hideHighLights();
                            this.viewComponent.disableSwapArea();
                            // this.sendNotification(
                            //   TurnView.CARD_PLAY_DONE_NOTIFICATION,
                            //   new CardVO(card.suit, card.value),
                            // );
                        }
                        return [3 /*break*/, 11];
                    case 6:
                        this.viewComponent.disableCardZones();
                        this.viewComponent.hideHighLights();
                        this.stateVOProxy.vo.swapEnabled
                            ? this.viewComponent.enableSwapArea()
                            : this.viewComponent.disableSwapArea();
                        return [3 /*break*/, 11];
                    case 7:
                        this.viewComponent.disableZones();
                        cards = this.viewComponent.getCards();
                        return [4 /*yield*/, this.viewComponent.repositionCards()];
                    case 8:
                        _b.sent();
                        this.viewComponent.bitaCards(cards);
                        return [3 /*break*/, 11];
                    case 9:
                        {
                            this.viewComponent.disableZones();
                            userId = args[0];
                            cards = this.viewComponent.getCards();
                            delayRunnable(this.scene, 400, this.sendNotification, this, TurnView.GET_CARDS_NOTIFICATION, userId, cards);
                            return [3 /*break*/, 11];
                        }
                        _b.label = 10;
                    case 10: return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    TurnViewMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.on(TurnView.HIGH_LIGHTED_CARD_CLICKED_EVENT, this.onHighLightedCardClick, this);
    };
    TurnViewMediator.prototype.darkenCardBasedOnMode = function () {
        var nextUser = this.stateVOProxy.vo.turnData.currentPlayer;
        var socketVOProxy = this.facade.retrieveProxy(SocketVOProxy.NAME);
        var playerData = this.stateVOProxy.vo.players[nextUser.userId];
        var darkerState = true;
        if (playerData.userId == socketVOProxy.vo.userId && playerData.isDefender) {
            darkerState = false;
        }
        this.viewComponent.darkenState = darkerState;
        // if (nextUser.userId === this.stateVOProxy.vo.players.length - 1) {
        //   this.viewComponent.setTimerMode(
        //     playerData.isAttacker ? GameTimerType.ATTACK : GameTimerType.DEFENCE,
        //   );
        //   this.viewComponent.setTimerDuration(nextUser.timeout);
        //   this.viewComponent.startTimer();
        // }
    };
    TurnViewMediator.prototype.createView = function () {
        var view = new TurnView(this.scene);
        view.setPosition(gameConfig.canvasWidth / 2, gameConfig.canvasHeight / 2);
        this.setViewComponent(view);
        this.setViewComponentListeners();
        this.scene.add.existing(view);
    };
    TurnViewMediator.prototype.onHighLightedCardClick = function (card, target) {
        this.sendNotification(TurnView.HIGH_LIGHTED_CARD_CLICKED_NOTIFICATION, card, target);
    };
    Object.defineProperty(TurnViewMediator.prototype, "stateVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(StateVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TurnViewMediator.prototype, "scene", {
        get: function () {
            return getScene(GameScene.NAME);
        },
        enumerable: true,
        configurable: true
    });
    TurnViewMediator.NAME = 'TurnViewMediator';
    return TurnViewMediator;
}(Mediator));
export default TurnViewMediator;
