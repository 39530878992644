var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MultiAtlases } from '../../../../../assets';
import ScrollerContainer from '../../../../utils/scroll/ScrollerContainer';
import MessageScroller from './message/MessageScroller';
import { EmojiType, MessageTypeButton } from './MessageTypeButton';
import SmilesScroller from './smile/SmilesScroller';
var EmojiContainer = /** @class */ (function (_super) {
    __extends(EmojiContainer, _super);
    function EmojiContainer(scene) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.createComponents();
        _this.prepare();
        return _this;
    }
    EmojiContainer.prototype.show = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.switchScrollerType(type);
                this.scene.tweens.add({
                    targets: this,
                    x: 0,
                    ease: Phaser.Math.Easing.Expo.InOut,
                    onUpdate: function () {
                        _this.smileScroller.setPosition(0, _this.smileScroller.y);
                        _this.messageScroller.setPosition(0, _this.messageScroller.y);
                        _this.smileScroller.updateMask();
                        _this.messageScroller.updateMask();
                    },
                    duration: 200,
                });
                return [2 /*return*/];
            });
        });
    };
    EmojiContainer.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.scene.tweens.killTweensOf(_this);
                        _this.scene.tweens.add({
                            targets: _this,
                            x: -_this.width,
                            duration: 200,
                            ease: Phaser.Math.Easing.Expo.InOut,
                            onUpdate: function () {
                                _this.smileScroller.updateMask();
                                _this.messageScroller.updateMask();
                                _this.smileScroller.setVisible(false);
                                _this.messageScroller.setVisible(false);
                            },
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    EmojiContainer.prototype.switchScrollerType = function (type) {
        this.smileScroller.setVisible(type === EmojiType.EMOJI);
        this.messageScroller.setVisible(!this.smileScroller.visible);
        this.smileButton.setSelectState(this.smileScroller.visible);
        this.messageButton.setSelectState(this.messageScroller.visible);
    };
    EmojiContainer.prototype.createComponents = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createSmileButton();
        this.createMessageButton();
        this.createSmileScroller();
        this.createMessageScroller();
        this.setListeners();
    };
    EmojiContainer.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Emojis.Atlas.Name,
            frame: MultiAtlases.Emojis.Atlas.Frames.Background,
        });
        this.background.setOrigin(0, 0.5);
        this.add(this.background);
    };
    EmojiContainer.prototype.createSmileButton = function () {
        this.smileButton = new MessageTypeButton(this.scene, EmojiType.EMOJI);
        this.smileButton.x = this.background.width + this.smileButton.width * 0.3;
        this.smileButton.y = this.smileButton.height * 0.425;
        this.smileButton.makeSelected();
        this.add(this.smileButton);
    };
    EmojiContainer.prototype.createMessageButton = function () {
        this.messageButton = new MessageTypeButton(this.scene, EmojiType.TEXT);
        this.messageButton.x =
            this.background.width + this.messageButton.width * 0.3;
        this.messageButton.y = -this.messageButton.height * 0.425;
        this.messageButton.makeSelected();
        this.add(this.messageButton);
    };
    EmojiContainer.prototype.createSmileScroller = function () {
        this.smileScroller = new SmilesScroller(this.scene);
        this.smileScroller.setPosition(0, -this.smileScroller.elementSize.height / 2);
        this.add(this.smileScroller);
    };
    EmojiContainer.prototype.createMessageScroller = function () {
        this.messageScroller = new MessageScroller(this.scene);
        this.messageScroller.setPosition(0, -this.messageScroller.elementSize.height / 2);
        this.add(this.messageScroller);
    };
    EmojiContainer.prototype.prepare = function () {
        this.x = -this.width;
    };
    EmojiContainer.prototype.onEmojiClick = function (pointer, target) {
        this.parentContainer &&
            this.parentContainer.emit(EmojiContainer.EMOJI_ACTION_DONE_EVENT, this.smileScroller.visible ? EmojiType.EMOJI : EmojiType.TEXT, target.index);
    };
    EmojiContainer.prototype.setListeners = function () {
        this.on(MessageTypeButton.CLICK_EVENT, this.switchScrollerType, this);
        this.smileScroller.on(ScrollerContainer.ELEMENT_CLICKED_EVENT, this.onEmojiClick, this);
        this.messageScroller.on(ScrollerContainer.ELEMENT_CLICKED_EVENT, this.onEmojiClick, this);
    };
    Object.defineProperty(EmojiContainer.prototype, "isShown", {
        get: function () {
            return this.smileScroller.visible || this.messageScroller.visible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmojiContainer.prototype, "sectionType", {
        get: function () {
            return this.smileScroller.visible ? EmojiType.EMOJI : EmojiType.TEXT;
        },
        enumerable: true,
        configurable: true
    });
    EmojiContainer.EMOJI_ACTION_DONE_EVENT = 'emojiActionDone';
    return EmojiContainer;
}(Phaser.GameObjects.Container));
export default EmojiContainer;
