var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BitmapFonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
var LevelUpPopupAvatar = /** @class */ (function (_super) {
    __extends(LevelUpPopupAvatar, _super);
    function LevelUpPopupAvatar(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        _this.prepare();
        return _this;
    }
    LevelUpPopupAvatar.prototype.show = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.applyAvatarMask();
                        _this.scene.tweens.add({
                            targets: _this.avatarFrame,
                            scaleX: 1,
                            scaleY: 1,
                            ease: Phaser.Math.Easing.Back.Out,
                            easeParams: [0.5],
                            duration: 200,
                            onComplete: function () {
                                _this.scene.tweens.add({
                                    targets: _this.avatar,
                                    alpha: 1,
                                    duration: 150,
                                });
                                _this.scene.tweens.add({
                                    targets: _this.decoration,
                                    alpha: 1,
                                    duration: 150,
                                });
                                _this.scene.tweens.add({
                                    targets: _this.levelBackground,
                                    scaleX: 1,
                                    scaleY: 1,
                                    duration: 250,
                                    ease: Phaser.Math.Easing.Back.Out,
                                    onComplete: function () {
                                        _this.scene.tweens.add({
                                            targets: _this.levelText,
                                            alpha: 1,
                                            duration: 150,
                                            onComplete: function () {
                                                resolve();
                                            },
                                        });
                                    },
                                });
                            },
                        });
                    })];
            });
        });
    };
    LevelUpPopupAvatar.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.scene.tweens.add({
                            targets: [_this.decoration, _this.levelBackground, _this.levelText],
                            alpha: 0,
                            duration: 200,
                        });
                        _this.scene.tweens.add({
                            targets: [_this.avatar, _this.avatarFrame],
                            scaleX: 0,
                            scaleY: 0,
                            duration: 200,
                            ease: Phaser.Math.Easing.Back.In,
                            easeParams: [0.5],
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    LevelUpPopupAvatar.prototype.createBody = function () {
        this.createAvatarFrame();
        this.setSize(this.avatarFrame.width, this.avatarFrame.height);
        this.createAvatar();
        this.createDecoration();
        this.createLevel();
    };
    LevelUpPopupAvatar.prototype.createAvatar = function () {
        this.avatar = this.scene.make.sprite({
            x: 0,
            y: 0,
            key: this.config.avatarKey,
        });
        this.avatar.setScale(Math.min(this.avatarFrame.width / this.avatar.width, this.avatarFrame.height / this.avatar.height));
        this.add(this.avatar);
    };
    LevelUpPopupAvatar.prototype.createAvatarFrame = function () {
        this.avatarFrame = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.LevelUpPopupAvatarFrame,
        });
        this.add(this.avatarFrame);
    };
    LevelUpPopupAvatar.prototype.createDecoration = function () {
        this.decoration = this.scene.make.image({
            x: -7,
            y: this.avatar.y + this.avatarFrame.height * 0.45,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.LevelUpPopupDecoration,
        });
        this.add(this.decoration);
    };
    LevelUpPopupAvatar.prototype.createLevel = function () {
        this.levelBackground = this.scene.make.image({
            x: 0,
            y: this.decoration.y,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.LevelUpPopupLevelBackground,
        });
        this.levelBackground.y += this.levelBackground.height * 0.4;
        this.add(this.levelBackground);
        var style = {
            fontFamily: BitmapFonts.RobotoSlab.Name,
            fontSize: 70 * gameConfig.resolutionMultiplier,
            fill: '#f5d348',
        };
        this.levelText = this.scene.make.text({
            x: this.levelBackground.x,
            y: this.levelBackground.y,
            text: this.config.level,
            style: style,
        });
        this.levelText.setOrigin(0.5, 0.625);
        this.levelText.setStroke('#d4a445', 1);
        this.levelText.setShadow(-2, 1, '#a67025');
        this.add(this.levelText);
    };
    LevelUpPopupAvatar.prototype.applyAvatarMask = function () {
        var graphics = this.scene.make.graphics({});
        graphics.fillStyle(0xffffff);
        var transformMatrix = this.avatar.getWorldTransformMatrix();
        graphics.fillCircle(transformMatrix.tx, transformMatrix.ty, this.avatarFrame.width * 0.45);
        this.avatar.setMask(new Phaser.Display.Masks.GeometryMask(this.scene, graphics));
    };
    LevelUpPopupAvatar.prototype.prepare = function () {
        this.avatarFrame.setScale(0);
        this.avatar.setAlpha(0);
        this.decoration.setAlpha(0);
        this.levelBackground.setScale(0);
        this.levelText.setAlpha(0);
    };
    return LevelUpPopupAvatar;
}(Phaser.GameObjects.Container));
export default LevelUpPopupAvatar;
