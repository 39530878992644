var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import StateVOProxy from '../../../model/StateVOProxy';
import CardVO from '../../../model/VO/CardVO';
import { CardPlayType } from '../../../model/VO/SocketVO';
import SocketCommand from '../SocketCommand';
var DefendCardPlayedCommand = /** @class */ (function (_super) {
    __extends(DefendCardPlayedCommand, _super);
    function DefendCardPlayedCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DefendCardPlayedCommand.prototype.execute = function (notificationName, card, target) {
        var stateVOProxy = this.facade.retrieveProxy(StateVOProxy.NAME);
        var cardData = stateVOProxy.getValidCardData(card);
        var targetData = !!target && !!cardData.targets && cardData.targets.length > 0
            ? new CardVO(target.suit, target.value)
            : new CardVO(cardData.targets[0].suit, cardData.targets[0].value);
        this.proxy.publishCardPlay(new CardVO(card.suit, card.value), CardPlayType.DEFENCE, targetData);
    };
    return DefendCardPlayedCommand;
}(SocketCommand));
export default DefendCardPlayedCommand;
