var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Audios } from '../../../assets';
var SimpleButton = /** @class */ (function (_super) {
    __extends(SimpleButton, _super);
    function SimpleButton(scene, configs) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.configs = configs;
        _this.createBody();
        _this.createText();
        return _this;
    }
    SimpleButton.prototype.setEnabled = function (isEnabled) {
        isEnabled ? this.makeEnabled() : this.makeDisabled();
    };
    SimpleButton.prototype.setListeners = function () {
        this.setSize(this.normalState.width, this.normalState.height);
        this.setInteractive();
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
        this.on(Phaser.Input.Events.POINTER_OVER, this.onHover, this);
        this.hoverState &&
            this.on(Phaser.Input.Events.POINTER_OVER, this.onHover, this);
    };
    SimpleButton.prototype.playSfxOnDown = function () {
        //
    };
    SimpleButton.prototype.playSfxOnUp = function () {
        this.scene.playSFX(Audios.ButtonTapSfx.Name);
    };
    SimpleButton.prototype.onPointerDown = function () {
        this.playSfxOnDown();
        if (this.downState) {
            this.normalState.visible = false;
            this.downState.visible = true;
        }
        this.hoverState && this.hoverState.setVisible(false);
        this.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
    };
    SimpleButton.prototype.onPointerUp = function () {
        this.playSfxOnUp();
        this.normalState.visible = true;
        this.downState && this.downState.setVisible(false);
        this.onClick();
    };
    SimpleButton.prototype.onOut = function () {
        this.emit(SimpleButton.OUT_EVENT);
        this.hoverState && this.hoverState.setVisible(false);
        this.downState && this.downState.setVisible(false);
        this.normalState.setVisible(true);
        this.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
    };
    SimpleButton.prototype.onHover = function () {
        this.emit(SimpleButton.OVER_EVENT);
        if (this.hoverState) {
            this.hoverState.visible = true;
            this.normalState.visible = false;
        }
        this.once(Phaser.Input.Events.POINTER_OUT, this.onOut, this);
    };
    SimpleButton.prototype.onClick = function () {
        this.emit(SimpleButton.CLICK_EVENT);
    };
    SimpleButton.prototype.createBody = function () {
        this.createStates();
        this.setListeners();
    };
    SimpleButton.prototype.createStates = function () {
        // Down state if available
        if (this.configs.downStateConfig) {
            this.downState = this.scene.make.sprite(this.configs.downStateConfig);
            this.add(this.downState);
            this.downState.depth = 5;
        }
        // Hover state if available
        if (this.configs.hoverStateConfig) {
            this.hoverState = this.scene.make.sprite(this.configs.hoverStateConfig);
            this.add(this.hoverState);
            this.hoverState.depth = 10;
            this.hoverState.visible = false;
        }
        // Disabled state if available
        if (this.configs.disabledStateConfig) {
            this.disabledState = this.scene.make.sprite(this.configs.disabledStateConfig);
            this.add(this.disabledState);
            this.disabledState.depth = 0;
        }
        // Normal state if available
        this.normalState = this.scene.make.sprite(this.configs.normalStateConfig);
        this.add(this.normalState);
        this.normalState.depth = 15;
    };
    SimpleButton.prototype.createText = function () {
        if (!this.configs.textConfig) {
            return;
        }
        this.text = this.scene.make.extText({
            x: 0,
            y: 0,
            text: this.configs.textConfig.text,
            style: {
                fontSize: this.configs.textConfig.fontSize,
                fill: this.configs.textConfig.fill,
                fontFamily: this.configs.textConfig.fontFamily,
            },
        });
        var textConfig = this.configs.textConfig;
        this.configs.textConfig.origin &&
            this.text.setOrigin(this.configs.textConfig.origin.x, this.configs.textConfig.origin.y);
        if (textConfig.stroke) {
            this.text.setStroke(textConfig.stroke.color, textConfig.stroke.thickness);
        }
        if (textConfig.shadow) {
            this.text.setShadow(textConfig.shadow.x, textConfig.shadow.y, textConfig.shadow.color, textConfig.shadow.blur, textConfig.shadow.shadowStroke, textConfig.shadow.shadowFill);
        }
        this.add(this.text);
        this.text.depth = 20;
    };
    SimpleButton.prototype.makeEnabled = function () {
        this.input.enabled = true;
        if (this.disabledState) {
            this.disabledState.setVisible(false);
            this.normalState.setVisible(true);
            this.hoverState && this.hoverState.setVisible(false);
            this.downState && this.downState.setVisible(false);
        }
        if (this.text && this.configs.textConfig.fill) {
            this.text.setFill(this.configs.textConfig.fill);
        }
    };
    SimpleButton.prototype.makeDisabled = function () {
        this.input.enabled = false;
        if (this.disabledState) {
            this.disabledState.setVisible(true);
            this.normalState.setVisible(false);
            this.hoverState && this.hoverState.setVisible(false);
            this.downState && this.downState.setVisible(false);
        }
        if (this.text && this.configs.textConfig.disabledFill) {
            this.text.setFill(this.configs.textConfig.disabledFill);
        }
    };
    SimpleButton.CLICK_EVENT = 'click';
    SimpleButton.OUT_EVENT = 'out';
    SimpleButton.OVER_EVENT = 'over';
    return SimpleButton;
}(Phaser.GameObjects.Container));
export default SimpleButton;
