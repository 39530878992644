var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Proxy } from '@candywings/pure-mvc';
import { Locales } from '../assets';
import { defaultSettings } from '../constants/Constants';
import { getEdge } from '../utils/Utils';
import { getDataFromStorage, setDataToStorage, StorageKey, } from '../utils/wrappers/StorageWrapper';
import { TopBarElement } from '../view/scenes/TopBarScene';
import { RewardName, RewardType, } from './VO/ApiVO';
import { Currency, Gender, Setting, } from './VO/PlayerVO';
var PlayerVOProxy = /** @class */ (function (_super) {
    __extends(PlayerVOProxy, _super);
    function PlayerVOProxy(vo) {
        return _super.call(this, PlayerVOProxy.NAME, vo) || this;
    }
    PlayerVOProxy.prototype.updateBonuses = function (bonuses) {
        var _a;
        (_a = this.vo.bonuses).push.apply(_a, bonuses);
        this.sendNotification(PlayerVOProxy.USER_BONUSES_UPDATED_NOTIFICATION);
    };
    PlayerVOProxy.prototype.updateProfile = function (data) {
        this.vo.name = data.name;
        this.vo.avatarUrl = data.avatarUrl;
        this.vo.gender = data.gender;
        this.fixName();
        this.sendNotification(PlayerVOProxy.USER_PROFILE_UPDATED_NOTIFICATION);
    };
    PlayerVOProxy.prototype.forceUpdateCurrency = function (currency, newValue) {
        this.vo.playerCurrency[currency] = newValue;
    };
    PlayerVOProxy.prototype.forceUpdateExperience = function (newValue) {
        this.vo.playerLevel.xp = newValue;
    };
    PlayerVOProxy.prototype.addChargeBonus = function (data) {
        var bonus = {
            name: RewardName.Bonus,
            prize: [
                {
                    type: data.chipType,
                    amount: data.amount,
                },
            ],
        };
        this.vo.bonuses.push(bonus);
        this.sendNotification(PlayerVOProxy.USER_BONUSES_UPDATED_NOTIFICATION);
    };
    PlayerVOProxy.prototype.setGameEndReward = function (data) {
        var reward = {
            name: RewardName.GameEnd,
            prize: [],
        };
        var prizeKeys = Object.keys(data.prize);
        for (var _i = 0, prizeKeys_1 = prizeKeys; _i < prizeKeys_1.length; _i++) {
            var prizeKey = prizeKeys_1[_i];
            !!data.prize[prizeKey] &&
                reward.prize.push({
                    type: RewardType[prizeKey.toUpperCase()],
                    amount: data.prize[prizeKey],
                });
        }
        this.vo.gameEndRewards = reward;
    };
    PlayerVOProxy.prototype.updateGander = function (gender, force) {
        if (force === void 0) { force = false; }
        if (force) {
            this.vo.gender = gender;
        }
        else {
            this.vo.gender = this.vo.gender === gender ? Gender.NONE : gender;
        }
        this.sendNotification(PlayerVOProxy.UPDATE_GANDER_NOTIFICATION);
    };
    PlayerVOProxy.prototype.updateAvatar = function (url) {
        this.vo.avatarUrl = url;
        this.sendNotification(PlayerVOProxy.AVATAR_UPDATED_NOTIFICATION);
    };
    PlayerVOProxy.prototype.updateNickName = function (name) {
        this.vo.name = name;
    };
    PlayerVOProxy.prototype.onRegister = function () {
        _super.prototype.onRegister.call(this);
        this.initValues();
        this.sendNotification(PlayerVOProxy.INITIALIZE_SUCCESS_NOTIFICATION);
    };
    PlayerVOProxy.prototype.updateData = function (data) {
        this.vo.playerCurrency.gold = data.gold;
        this.vo.playerCurrency.silver = data.silver;
        this.vo.playerCurrency.crystal = data.crystal;
        this.vo.playerCurrency.star = data.star;
        this.vo.playerLevel.level = data.level;
        this.vo.playerLevel.xp = data.xp;
        this.vo.playerLevel.edge = data.edge;
        this.vo.playerStatistics.wins = data.wins;
        this.vo.playerStatistics.loses = data.loses;
        this.vo.playerStatistics.outs = data.outs;
        this.vo.playerStatistics.draws = data.draws;
        this.vo.wheelData = data.wheelData;
        this.fixXp();
        this.fixName();
        this.sendNotification(PlayerVOProxy.UPDATE_CURRENCIES_NOTIFICATION, data);
    };
    PlayerVOProxy.prototype.checkBonuses = function () {
        if (this.vo.bonuses.length > 0) {
            this.sendNotification(PlayerVOProxy.PLAYER_HAS_BONUS_NOTIFICATION);
        }
    };
    PlayerVOProxy.prototype.claimGamePlayPrice = function (amount, type) {
        var updatedValue = this.vo.playerCurrency[type] - amount;
        this.sendNotification(PlayerVOProxy.UPDATE_CURRENCIES_NOTIFICATION, type, updatedValue);
        this.vo.playerCurrency[type] = updatedValue;
    };
    PlayerVOProxy.prototype.getPlayerData = function (property) {
        switch (property) {
            case Currency.GOLD:
                return this.vo.playerCurrency.gold;
            case Currency.SILVER:
                return this.vo.playerCurrency.silver;
            case Currency.CRYSTAL:
                return this.vo.playerCurrency.crystal;
            case TopBarElement.XP:
                return this.vo.playerLevel.xp / this.vo.playerLevel.edge;
            default:
                return null;
        }
    };
    PlayerVOProxy.prototype.getBonusReward = function () {
        return this.vo.bonuses[0].prize;
    };
    PlayerVOProxy.prototype.consumeBonusReward = function () {
        return this.vo.bonuses.shift().prize;
    };
    PlayerVOProxy.prototype.consumeGameEndReward = function () {
        var rewards = this.vo.gameEndRewards.prize.slice();
        this.vo.gameEndRewards = null;
        return rewards;
    };
    PlayerVOProxy.prototype.getWheelReward = function () {
        var rewardValue = this.vo.wheelData.data[this.vo.wheelData.segment];
        return [
            {
                type: rewardValue.type,
                amount: !!Currency[rewardValue.type.toUpperCase()]
                    ? +rewardValue.text
                    : 1,
            },
        ];
    };
    PlayerVOProxy.prototype.setLanguage = function (language) {
        this.vo.settings.language = language;
        setDataToStorage(StorageKey.SETTINGS, this.vo.settings);
        this.sendNotification(PlayerVOProxy.UPDATE_SETTINGS_NOTIFICATION, Setting.LANGUAGE, language);
    };
    PlayerVOProxy.prototype.updateLocalSetting = function (setting) {
        var settings = getDataFromStorage(StorageKey.SETTINGS, defaultSettings);
        if (setting === Setting.LANGUAGE) {
            settings.language =
                this.vo.settings.language === Locales.En.Name
                    ? Locales.Ru.Name
                    : Locales.En.Name;
        }
        else {
            var newValue = !settings[setting];
            settings[setting] = newValue;
        }
        setDataToStorage(StorageKey.SETTINGS, settings);
        this.vo.settings = settings;
        this.sendNotification(PlayerVOProxy.UPDATE_SETTINGS_NOTIFICATION, setting, settings[setting]);
    };
    PlayerVOProxy.prototype.updateAvatarPosition = function (x, y) {
        setDataToStorage(StorageKey.AVATAR, new Phaser.Math.Vector2(x, y));
    };
    PlayerVOProxy.prototype.initValues = function () {
        this.fixXp();
        this.fixName();
        setDataToStorage(StorageKey.SETTINGS, this.vo.settings);
    };
    PlayerVOProxy.prototype.fixXp = function () {
        for (var i = 0; i < this.vo.playerLevel.level; i++) {
            this.vo.playerLevel.xp += getEdge(i);
        }
    };
    PlayerVOProxy.prototype.fixName = function () {
        var lastSpaceIndex = this.vo.name.lastIndexOf(' ');
        if (lastSpaceIndex !== -1) {
            this.vo.name = this.vo.name.substring(0, lastSpaceIndex);
        }
    };
    PlayerVOProxy.NAME = 'PlayerVOProxy';
    PlayerVOProxy.INITIALIZE_SUCCESS_NOTIFICATION = PlayerVOProxy.NAME + "InitializeSuccessNotification";
    PlayerVOProxy.PLAYER_HAS_BONUS_NOTIFICATION = PlayerVOProxy.NAME + "PlayerHasBonusNotification";
    PlayerVOProxy.PLAYERS_TURN_NOTIFICATION = PlayerVOProxy.NAME + "PlayersTurnNotification";
    PlayerVOProxy.UPDATE_CURRENCIES_NOTIFICATION = PlayerVOProxy.NAME + "UpdateCurrenciesNotification";
    PlayerVOProxy.UPDATE_SETTINGS_NOTIFICATION = PlayerVOProxy.NAME + "UpdateSettingsNotification";
    PlayerVOProxy.UPDATE_GANDER_NOTIFICATION = PlayerVOProxy.NAME + "UpdateGanderNotification";
    PlayerVOProxy.AVATAR_UPDATED_NOTIFICATION = PlayerVOProxy.NAME + "AvatarUpdatedNotification";
    PlayerVOProxy.USER_PROFILE_UPDATED_NOTIFICATION = PlayerVOProxy.NAME + "UserProfileUpdatedNotification";
    PlayerVOProxy.USER_BONUSES_UPDATED_NOTIFICATION = PlayerVOProxy.NAME + "UserBonusesUpdatedNotification";
    return PlayerVOProxy;
}(Proxy));
export default PlayerVOProxy;
