var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var PopupTitle = /** @class */ (function (_super) {
    __extends(PopupTitle, _super);
    function PopupTitle(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        return _this;
    }
    PopupTitle.prototype.updateTitle = function (text, fontFamily, fontSize, color, origin) {
        if (text === void 0) { text = this.config.text; }
        if (fontFamily === void 0) { fontFamily = this.config.fontFamily; }
        if (fontSize === void 0) { fontSize = this.config.fontSize; }
        if (color === void 0) { color = this.config.fill; }
        if (origin === void 0) { origin = this.config.origin || { x: 0.5, y: 0.5 }; }
        this.config.text = text;
        this.config.fontFamily = fontFamily;
        this.config.fontSize = fontSize * gameConfig.resolutionMultiplier;
        this.config.fill = color;
        this.config.origin = origin;
        this.text.setText(this.config.text);
        this.text.setStyle({
            fontFamily: this.config.fontFamily,
            fontSize: this.config.fontSize,
            fill: this.config.fill,
        });
        this.config.origin &&
            this.text.setOrigin(this.config.origin.x, this.config.origin.y);
        this.correctTextSize();
    };
    PopupTitle.prototype.createBody = function () {
        this.createBackground();
        this.createText();
        this.correctTextSize();
    };
    PopupTitle.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: this.config.frame,
        });
        this.add(this.background);
    };
    PopupTitle.prototype.createText = function () {
        var style = {
            fontFamily: this.config.fontFamily,
            fontSize: this.config.fontSize,
            fill: this.config.fill,
        };
        this.text = this.scene.make.extText({
            x: 0,
            y: 0,
            text: this.config.text,
            style: style,
        });
        this.updateTitle(this.config.text);
        this.add(this.text);
    };
    PopupTitle.prototype.correctTextSize = function () {
        this.text.setScale(Math.min(1, (this.background.width - 20) / this.text.width));
    };
    return PopupTitle;
}(Phaser.GameObjects.Container));
export default PopupTitle;
