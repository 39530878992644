import CustomHtmlImage from '../view/utils/dom/CustomHtmlImage';
var backgroundImage;
export function createDocumentBackgroundImage(key, frame) {
    backgroundImage = new CustomHtmlImage(DomElementId.GAME_DIV, DomElementId.BACKGROUND_IMAGE, key, frame, window.innerWidth, window.innerHeight);
}
export function setDocumentBackgroundImage(key, frame) {
    // if (!backgroundImage) {
    //   createDocumentBackgroundImage(key, frame);
    // } else {
    //   backgroundImage.setTexture(key, frame);
    // }
}
export function clearDocumentBackgroundImage() {
    if (!backgroundImage) {
        console.warn("Background image isn't created.");
        return;
    }
    else {
        backgroundImage.clear();
    }
}
export var DomElementId;
(function (DomElementId) {
    DomElementId["GAME_DIV"] = "gameBg";
    DomElementId["BACKGROUND_IMAGE"] = "backgroundImage";
})(DomElementId || (DomElementId = {}));
export function disableRendering() {
    document.body.style.webkitBackfaceVisibility = 'hidden';
    document.body.style.webkitPerspective = '1000px';
}
export function enableRendering() {
    document.body.style.removeProperty('-webkit-perspective');
    document.body.style.removeProperty('-webkit-backface-visibility');
}
export function removeBackgroundImage() {
    document.body.removeAttribute('class');
    document.body.setAttribute('class', 'black');
    // document.getElementById('logo').remove();
}
