var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isCordova, isMobile } from '../../../../utils/Utils';
import { postRunnable } from '../../phaser/PhaserUtils';
var BaseTextField = /** @class */ (function (_super) {
    __extends(BaseTextField, _super);
    function BaseTextField(scene) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.isEnabled = false;
        _this.textContent = '';
        _this.createBody();
        return _this;
    }
    BaseTextField.prototype.enable = function () {
        this.isEnabled = true;
        this.input.enabled = true;
    };
    BaseTextField.prototype.disable = function () {
        this.isEnabled = false;
        this.input.enabled = false;
    };
    BaseTextField.prototype.focus = function () {
        //delayRunnable(this.scene, 100, this.applyFocus, this);
        this.domInputElement.focus();
    };
    BaseTextField.prototype.applyFocus = function () {
        console.warn('applyFocus');
        this.domInputElement.focus();
        isCordova() && isMobile() && window.Keyboard.show();
    };
    BaseTextField.prototype.blur = function () {
        postRunnable(this.applyBlur, this);
    };
    BaseTextField.prototype.applyBlur = function () {
        this.domInputElement.blur();
        isCordova() && isMobile() && window.Keyboard.hide();
    };
    BaseTextField.prototype.clear = function () {
        this.domInputElement.value = '';
    };
    BaseTextField.prototype.copyToClipBoard = function () {
        this.focus();
        this.domInputElement.select();
        document.execCommand('copy', false);
        this.blur();
    };
    BaseTextField.prototype.createBody = function () {
        this.createDomElement();
        this.setListeners();
    };
    BaseTextField.prototype.createDomElement = function () {
        this.domInputElement = document.createElement('input');
        this.domInputElement.style.position = 'absolute';
        this.domInputElement.style.opacity = '0';
        this.domInputElement.style.left = '-1000px';
        this.domInputElement.style.top = '-1000px';
        document.body.insertBefore(this.domInputElement, document.body.firstChild);
    };
    BaseTextField.prototype.setListeners = function () {
        this.setInteractive();
        this.input.enabled = false;
        this.domInputElement.oninput = this.onInput.bind(this);
        this.domInputElement.onchange = this.onInput.bind(this);
    };
    BaseTextField.prototype.onInput = function () {
        if (this.isEnabled) {
            this.textContent = this.domInputElement.value;
            this.onInputTextChange();
        }
    };
    BaseTextField.FOCUSED_EVENT = 'focused';
    BaseTextField.BLURRED_EVENT = 'blurred';
    return BaseTextField;
}(Phaser.GameObjects
    .Container));
export default BaseTextField;
