var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ScrollerContainer from './ScrollerContainer';
var HorizontalScrollerContainer = /** @class */ (function (_super) {
    __extends(HorizontalScrollerContainer, _super);
    function HorizontalScrollerContainer(scene, inputListenerConfig, relativeMaskConfig, kineticScrollConfig, gap) {
        return _super.call(this, scene, inputListenerConfig, relativeMaskConfig, kineticScrollConfig, gap) || this;
    }
    HorizontalScrollerContainer.prototype.startScrollToTargetTween = function (target) {
        var _this = this;
        this.scene.tweens.add({
            targets: this.lastApplied,
            x: target.x,
            onUpdate: function () {
                _this.applyScroll({
                    propertyName: 'x',
                    value: _this.lastApplied.x,
                });
            },
        });
    };
    HorizontalScrollerContainer.prototype.applyDragStart = function (pointer) {
        this.lastApplied.x = pointer.x;
        this.dragData = {
            start: pointer.x,
            end: null,
            startMoment: Date.now(),
            endMoment: null,
            direction: null,
        };
    };
    HorizontalScrollerContainer.prototype.applyDrag = function (pointer) {
        var diffX = pointer.x - this.lastApplied.x;
        var currentDirection = this.calculateDirection(pointer);
        this.lastApplied.x = pointer.x;
        if (this.dragData.direction !== currentDirection) {
            this.dragData.start = pointer.x;
            this.dragData.direction = currentDirection;
            this.dragData.startMoment = Date.now();
        }
        this.applyScroll({
            propertyName: 'x',
            value: diffX * this.scrollStrengthHorizontal,
        });
    };
    HorizontalScrollerContainer.prototype.applyDragEnd = function (pointer) {
        this.dragData.end = pointer.x;
    };
    HorizontalScrollerContainer.prototype.applyChildrenPositions = function () {
        this.lastApplied.scrolledDistance = this.relativeMaskConfig.x;
        for (var i = 0; i < this.scrollableList.length; i++) {
            var child = this.scrollableList[i];
            var prevChild = this.scrollableList[i - 1];
            child.x = prevChild
                ? prevChild.x + prevChild.width + this.gap
                : this.relativeMaskConfig.x +
                    child.width +
                    this.lastApplied.scrolledDistance;
            child.y = this.relativeMaskConfig.y;
        }
    };
    HorizontalScrollerContainer.prototype.limitMovement = function (force) {
        if (!this.scrollableList.length) {
            return false;
        }
        if (this.first.x > this.relativeMaskConfig.x ||
            this.sumWidth < this.relativeMaskConfig.width) {
            this.autoScrollToStart(force);
            return true;
        }
        if (this.last.x + this.last.width <
            this.relativeMaskConfig.x + this.relativeMaskConfig.width + this.gap) {
            this.autoScrollToEnd(force);
            return true;
        }
        return false;
    };
    HorizontalScrollerContainer.prototype.autoScrollToStart = function (force) {
        var _this = this;
        var tweenTarget = {
            x: this.first.x || 0,
            lastX: this.first.x || 0,
        };
        this.positionFixTween && this.positionFixTween.stop();
        this.positionFixTween = this.scene.tweens.add({
            targets: tweenTarget,
            x: this.relativeMaskConfig.x,
            onUpdate: function () {
                var diffX = tweenTarget.x - tweenTarget.lastX;
                tweenTarget.lastX = tweenTarget.x;
                _this.applyScroll({
                    propertyName: 'x',
                    value: diffX,
                });
            },
            duration: force ? 1 : 200,
        });
    };
    HorizontalScrollerContainer.prototype.autoScrollToEnd = function (force) {
        var _this = this;
        if (!this.autoScrollToEndEnabled) {
            return;
        }
        var tweenTarget = {
            x: this.last.x,
            lastX: this.last.x,
        };
        this.positionFixTween && this.positionFixTween.stop();
        this.positionFixTween = this.scene.tweens.add({
            targets: tweenTarget,
            x: this.relativeMaskConfig.x +
                this.relativeMaskConfig.width -
                this.last.width -
                this.gap,
            onUpdate: function () {
                var diffX = tweenTarget.x - tweenTarget.lastX;
                tweenTarget.lastX = tweenTarget.x;
                _this.applyScroll({
                    propertyName: 'x',
                    value: diffX,
                });
            },
            duration: force ? 1 : 200,
        });
    };
    HorizontalScrollerContainer.prototype.autoScrollToEndEnabled = function () {
        return this.scrollableList.length * this.first.width >= this.width;
    };
    HorizontalScrollerContainer.prototype.startKineticScroll = function () {
        var _this = this;
        this.scene.tweens.killTweensOf(this.lastApplied);
        var dx = this.dragData.end - this.dragData.start || 0;
        var dt = this.dragData.endMoment - this.dragData.startMoment || 1;
        this.lastApplied.speedX = dx / dt;
        var duration = Math.abs((this.lastApplied.speedX / this.kineticScrollConfig.resistance) *
            this.scene.game.loop.delta);
        this.scene.tweens.add({
            targets: this.lastApplied,
            speedX: 0,
            duration: duration,
            ease: Phaser.Math.Easing.Expo.Out,
            onUpdate: function () {
                _this.lastApplied.speedX &&
                    _this.applyScroll({
                        propertyName: 'x',
                        value: _this.lastApplied.speedX,
                    });
                if (_this.limitMovement()) {
                    _this.scene.tweens.killTweensOf(_this.lastApplied);
                }
            },
        });
    };
    HorizontalScrollerContainer.prototype.calculateDirection = function (pointer) {
        return Math.sign(pointer.x - this.dragData.start);
    };
    return HorizontalScrollerContainer;
}(ScrollerContainer));
export { HorizontalScrollerContainer };
