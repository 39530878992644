var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../../../../assets';
import { gameConfig } from '../../../../../../constants/GameConfig';
import { Translation } from '../../../../../../translations';
import { getFramesCountByString } from '../../../../../../utils/Utils';
import { HorizontalScrollerContainer } from '../../../../../utils/scroll/HorizontalScrollerContainer';
import MessageScrollerElement from './MessageScrollerElement';
var MessageScroller = /** @class */ (function (_super) {
    __extends(MessageScroller, _super);
    function MessageScroller(scene) {
        var _this = this;
        var backgroundFrame = scene.textures.getFrame(MultiAtlases.Emojis.Atlas.Name, MultiAtlases.Emojis.Atlas.Frames.Background);
        var elementFrame = scene.textures.getFrame(MultiAtlases.Emojis.Atlas.Name, MultiAtlases.Emojis.Atlas.Frames.Smile0);
        var inputListenerConfig = {
            x: 0,
            y: 0,
            width: backgroundFrame.width,
            height: backgroundFrame.height,
            distanceTrashHold: 30,
        };
        var relativeMaskConfig = {
            x: elementFrame.width * 0.55,
            y: 0,
            width: inputListenerConfig.width -
                elementFrame.width * 0.55 -
                Math.ceil(10 * gameConfig.resolutionMultiplier),
            height: backgroundFrame.height,
        };
        var kineticScrollConfig = {
            enabled: true,
            resistance: 0.1,
        };
        _this = _super.call(this, scene, inputListenerConfig, relativeMaskConfig, kineticScrollConfig, 10 * gameConfig.resolutionMultiplier) || this;
        _this.createContent();
        return _this;
    }
    MessageScroller.prototype.createContent = function () {
        this.createMessages();
        this.sendToBack(this.background);
    };
    MessageScroller.prototype.createMessages = function () {
        var count = getFramesCountByString(Translation, 'EMOJI_MESSAGE_');
        for (var i = 0; i < count; i++) {
            var message = new MessageScrollerElement(this.scene, i);
            this.addScrollAble(message, true);
        }
    };
    return MessageScroller;
}(HorizontalScrollerContainer));
export default MessageScroller;
