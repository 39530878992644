var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import RegistrationProfileInfoSection from '../components/registration/RegistrationProfileInfoSection';
import RegistrationProfileLanguageSection from '../components/registration/RegistrationProfileLanguageSection';
import BaseScene from './BaseScene';
var RegistrationScene = /** @class */ (function (_super) {
    __extends(RegistrationScene, _super);
    function RegistrationScene() {
        return _super.call(this, RegistrationScene.NAME) || this;
    }
    RegistrationScene.prototype.getKeys = function () {
        return this.profileInfoSection.getKeys();
    };
    RegistrationScene.prototype.removeAssets = function () {
        this.textures.remove(MultiAtlases.Login.Atlas.Name);
    };
    RegistrationScene.prototype.create = function (data) {
        this.createBackground();
        this.createSections(data.avatarsCounts, data.name);
    };
    RegistrationScene.prototype.showLanguageSection = function () {
        this.languageSection.show();
    };
    RegistrationScene.prototype.hideLanguageSection = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.languageSection.hide()];
            });
        });
    };
    RegistrationScene.prototype.showProfileSection = function () {
        this.profileInfoSection.showGenderRadios();
        this.profileInfoSection.showEditButton();
        this.profileInfoSection.showAvatar();
        this.profileInfoSection.showMessageAndNextElement();
    };
    RegistrationScene.prototype.updateGenderRadios = function (gender) {
        this.profileInfoSection.updateRadioStates(gender);
        this.profileInfoSection.showAvatar(gender);
    };
    RegistrationScene.prototype.updateProfileGender = function (gender) {
        this.profileInfoSection.setAvatar(gender, 0);
        this.profileInfoSection.showEditButton();
        this.profileInfoSection.showGenderRadios();
    };
    RegistrationScene.prototype.updateProfileAvatar = function (gender, index) {
        this.profileInfoSection.setAvatar(gender, index);
    };
    RegistrationScene.prototype.showAvatarsList = function (gender) {
        this.profileInfoSection.hideEditButton();
        this.profileInfoSection.showAvatarsList(gender);
        this.profileInfoSection.hideMessageAndNextElement();
    };
    RegistrationScene.prototype.toggleAvatarList = function (gender) {
        this.profileInfoSection.toggleAvatarsList(gender);
    };
    RegistrationScene.prototype.hideAvatarList = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.profileInfoSection.hideAvatarList()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RegistrationScene.prototype.showEditButton = function () {
        this.profileInfoSection.showEditButton();
    };
    RegistrationScene.prototype.showMessageAndNextButton = function () {
        this.profileInfoSection.showMessageAndNextElement();
    };
    RegistrationScene.prototype.createBackground = function () {
        this.background = this.add.image(gameConfig.canvasWidth / 2, gameConfig.canvasHeight / 2, MultiAtlases.Login.Atlas.Name, MultiAtlases.Login.Atlas.Frames.Background);
        this.background.setScale(Math.max(gameConfig.canvasHeight / this.background.height, gameConfig.canvasWidth / this.background.width));
    };
    RegistrationScene.prototype.createSections = function (avatarsCount, name) {
        this.languageSection = new RegistrationProfileLanguageSection(this);
        this.profileInfoSection = new RegistrationProfileInfoSection(this, {
            avatarsCount: avatarsCount,
            name: name,
        });
    };
    RegistrationScene.NAME = 'RegistrationScene';
    RegistrationScene.LANGUAGE_ACTION_EVENT = 'languageActionEvent';
    RegistrationScene.PROFILE_ACTION_EVENT = 'profileActionEvent';
    RegistrationScene.AVATAR_CHANGED_EVENT = 'avatarChangedEvent';
    RegistrationScene.AVATAR_CLICKED_EVENT = "avatarClickedEvent";
    RegistrationScene.NAME_CHANGED_EVENT = "nameChangedEvent";
    RegistrationScene.SHOWN_NOTIFICATION = RegistrationScene.NAME + "ShownNotification";
    RegistrationScene.HIDDEN_NOTIFICATION = RegistrationScene.NAME + "HiddenNotification";
    RegistrationScene.OK_BUTTON_CLICKED_NOTIFICATION = RegistrationScene.NAME + "OkButtonClickedNotification";
    RegistrationScene.EDIT_CLICKED_NOTIFICATION = RegistrationScene.NAME + "EditClickedNotification";
    RegistrationScene.GENDER_CHANGED_NOTIFICATION = RegistrationScene.NAME + "GenderChangedNotification";
    RegistrationScene.NEXT_CLICKED_NOTIFICATION = RegistrationScene.NAME + "NextClickedNotification";
    RegistrationScene.AVATAR_CHANGED_NOTIFICATION = RegistrationScene.NAME + "AvatarChangedNotification";
    RegistrationScene.NAME_CHANGED_NOTIFICATION = RegistrationScene.NAME + "NameChangedNotification";
    RegistrationScene.LANGUAGE_CLICKED_NOTIFICATION = RegistrationScene.NAME + "LanguageClickedNotification";
    return RegistrationScene;
}(BaseScene));
export default RegistrationScene;
