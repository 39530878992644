var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MultiAtlases } from '../../../assets';
import GameTimer from './GameTimer';
import OpponentGameAvatar from './OpponentGameAvatar';
import OpponentView from './OpponentView';
import PlayerGameLevel from './PlayerGameLevel';
import PlayerGameName from './PlayerGameName';
var OpponentAvatar = /** @class */ (function (_super) {
    __extends(OpponentAvatar, _super);
    function OpponentAvatar(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        _this.setListeners();
        return _this;
    }
    OpponentAvatar.prototype.showTimerHighLight = function () {
        this.timer.show();
    };
    OpponentAvatar.prototype.hideTimerHighLight = function () {
        this.timer.hide();
    };
    OpponentAvatar.prototype.startTimer = function () {
        this.timer.start();
    };
    OpponentAvatar.prototype.stopTimer = function () {
        this.timer.stop();
    };
    OpponentAvatar.prototype.updateData = function (config) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.config = config;
                        this.avatar.updateData(this.config.key, this.config.isNative);
                        this.level.updateData(this.config.level);
                        this.playerName.updateData(this.config.name);
                        return [4 /*yield*/, this.show()];
                    case 1:
                        _a.sent();
                        this.scene.tweens.add({
                            targets: this.flag,
                            scaleY: this.flag.scaleX,
                            duration: 500,
                            ease: Phaser.Math.Easing.Back.Out,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    OpponentAvatar.prototype.setTimerDuration = function (duration) {
        this.timer.setDuration(duration);
    };
    OpponentAvatar.prototype.setTimerType = function (type) {
        this.timer.prepare(type);
    };
    OpponentAvatar.prototype.show = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.avatar.show()];
                                case 1:
                                    _a.sent();
                                    !this.avatar.key && this.avatar.startLoadingAnimation();
                                    !!this.level.level && this.level.show();
                                    !!this.playerName.name && this.playerName.show();
                                    resolve();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    OpponentAvatar.prototype.setPosition = function (x, y, z, w) {
        _super.prototype.setPosition.call(this, x, y, z, w);
        this.avatar && this.avatar.updateMask();
        return this;
    };
    OpponentAvatar.prototype.createBody = function () {
        this.createAvatar();
        this.setSize(this.avatar.width, this.avatar.height);
        this.createTimer();
        this.createFlag();
        this.createLevel();
        this.createName();
    };
    OpponentAvatar.prototype.createFlag = function () {
        this.flag = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Flags.Atlas.Name,
            frame: MultiAtlases.Flags.Atlas.Frames.FlagLeagueNone,
        });
        this.flag.setOrigin(0.5, 0);
        this.add(this.flag);
        this.flag.y = -this.flag.displayHeight * 0.035;
        this.flag.setScale(0.316, 0);
        this.sendToBack(this.flag);
    };
    OpponentAvatar.prototype.createAvatar = function () {
        this.avatar = new OpponentGameAvatar(this.scene, this.config ? this.config.key : null, this.config ? this.config.isNative : false);
        this.add(this.avatar);
    };
    OpponentAvatar.prototype.createLevel = function () {
        this.level = new PlayerGameLevel(this.scene, this.config ? this.config.level : null);
        this.level.x = this.avatar.width * 0.3;
        this.level.y = this.avatar.height * 1.3;
        this.add(this.level);
    };
    OpponentAvatar.prototype.createName = function () {
        this.playerName = new PlayerGameName(this.scene, this.config ? this.config.name : '');
        this.playerName.y = this.avatar.height * 0.25;
        this.add(this.playerName);
    };
    OpponentAvatar.prototype.createTimer = function () {
        this.timer = new GameTimer(this.scene);
        this.timer.setPosition(this.avatar.x - this.avatar.width * 0.005, this.avatar.y);
        this.add(this.timer);
        this.sendToBack(this.timer);
    };
    OpponentAvatar.prototype.showSmile = function (frame) {
        this.avatar.showSmile(frame);
    };
    OpponentAvatar.prototype.setListeners = function () {
        this.avatar.setInteractive();
        this.avatar.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
    };
    OpponentAvatar.prototype.onPointerDown = function () {
        this.avatar.once(Phaser.Input.Events.POINTER_UP, this.onClick, this);
        this.avatar.once(Phaser.Input.Events.POINTER_OUT, this.cancelClickEvents, this);
    };
    OpponentAvatar.prototype.cancelClickEvents = function () {
        this.avatar.off(Phaser.Input.Events.POINTER_UP, this.onClick, this);
        this.avatar.off(Phaser.Input.Events.POINTER_OUT, this.cancelClickEvents, this);
    };
    OpponentAvatar.prototype.onClick = function () {
        this.parentContainer.emit(OpponentView.AVATAR_CLICK_EVENT);
    };
    return OpponentAvatar;
}(Phaser.GameObjects.Container));
export default OpponentAvatar;
