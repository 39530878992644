var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fonts, MultiAtlases } from '../../../../assets';
import NavigationSectionState from './NavigationSectionState';
var NavigationSectionActiveState = /** @class */ (function (_super) {
    __extends(NavigationSectionActiveState, _super);
    function NavigationSectionActiveState(scene, mode) {
        var _this = this;
        var config = {
            background: {
                key: MultiAtlases.Lobby.Atlas.Name,
                frame: MultiAtlases.Lobby.Atlas.Frames.SectionBackgroundActive,
            },
            button: {
                key: MultiAtlases.Lobby.Atlas.Name,
                frame: MultiAtlases.Lobby.Atlas.Frames.SectionButtonActive,
            },
            text: {
                text: "lobby-navigation-" + mode.toLowerCase(),
                fontFamily: Fonts.RobotoSlab.Name,
                fontSize: 60,
                fill: '#feecca',
                stroke: '#7b5e34',
            },
        };
        _this = _super.call(this, scene, config) || this;
        return _this;
    }
    NavigationSectionActiveState.prototype.prepare = function () {
        var passiveStateFrame = this.scene.textures.getFrame(MultiAtlases.Lobby.Atlas.Name, MultiAtlases.Lobby.Atlas.Frames.SectionBackgroundPassive);
        this.buttonSprite.y = passiveStateFrame.height * 0.38;
        this.text.y = passiveStateFrame.height * 0.38;
    };
    return NavigationSectionActiveState;
}(NavigationSectionState));
export default NavigationSectionActiveState;
