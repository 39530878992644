var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { Currency } from '../../../model/VO/PlayerVO';
var WheelSection = /** @class */ (function (_super) {
    __extends(WheelSection, _super);
    function WheelSection(scene, index, config, angle) {
        var _this = _super.call(this, scene, 0, 0) || this;
        _this.index = index;
        _this.config = config;
        _this.angle = angle;
        _this.createBody();
        _this.setScale(0);
        return _this;
    }
    WheelSection.prototype.mark = function () {
        this.knife.visible = true;
    };
    WheelSection.prototype.makeSelected = function () {
        this.scene.tweens.add({
            targets: this.knife,
            alpha: 0,
            duration: 200,
        });
        this.scene.tweens.add({
            targets: this.selectedFrame,
            alpha: 1,
            duration: 200,
        });
        this.scene.tweens.add({
            targets: this.text,
            alpha: 1.1,
            duration: 200,
        });
    };
    WheelSection.prototype.show = function (delay) {
        var _this = this;
        var duration = 200;
        this.scene.tweens.add({
            targets: this,
            scaleX: 1,
            scaleY: 1,
            delay: delay,
            duration: duration,
            ease: Phaser.Math.Easing.Back.InOut,
            onComplete: function () {
                _this.emit(WheelSection.SHOW_COMPLETE_EVENT);
                _this.text.visible = true;
                _this.scene.tweens.add({
                    targets: _this.text,
                    scaleX: 0.5,
                    scaleY: 0.5,
                    alpha: 1,
                    duration: duration,
                });
            },
        });
    };
    WheelSection.prototype.hide = function (delay) {
        var _this = this;
        var duration = 200;
        this.scene.tweens.add({
            targets: this.text,
            scaleX: 1,
            scaleY: 1,
            alpha: 0,
            delay: delay,
            duration: duration,
        });
        this.scene.tweens.add({
            targets: this,
            scaleX: 0,
            scaleY: 0,
            delay: delay,
            duration: duration,
            ease: Phaser.Math.Easing.Back.InOut,
            onComplete: function () {
                _this.emit(WheelSection.HIDE_COMPLETE_EVENT);
            },
        });
    };
    WheelSection.prototype.createBody = function () {
        this.createBackground();
        this.createSelectedFrame();
        this.setSize(this.selectedFrame.width, this.selectedFrame.height);
        this.createText();
        this.createKnife();
    };
    WheelSection.prototype.createBackground = function () {
        var centerFrame = this.scene.textures.getFrame(MultiAtlases.Wheel.Atlas.Name, MultiAtlases.Wheel.Atlas.Frames.Center);
        var typeKey = this.config.type;
        this.background = this.scene.make.image({
            x: centerFrame.width * 0.095,
            y: 0,
            key: MultiAtlases.Wheel.Atlas.Name,
            frame: !!Currency[typeKey.toUpperCase()]
                ? MultiAtlases.Wheel.Atlas.Frames["Sector" + this.index % 3]
                : MultiAtlases.Wheel.Atlas.Frames[Phaser.Utils.String.UppercaseFirst(this.config.text) + "Bg"],
        });
        this.background.setOrigin(0, 0.5);
        this.add(this.background);
    };
    WheelSection.prototype.createSelectedFrame = function () {
        this.selectedFrame = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Wheel.Atlas.Name,
            frame: MultiAtlases.Wheel.Atlas.Frames.Selected,
        });
        this.add(this.selectedFrame);
        this.selectedFrame.setOrigin(0, 0.5);
        this.selectedFrame.setAlpha(0);
    };
    WheelSection.prototype.createText = function () {
        var isCurrency = !!Currency[this.config.type.toUpperCase()];
        this.text = this.scene.make.bitmapText({
            x: this.width * 0.4,
            y: isCurrency ? -this.height * 0.05 : -this.height * 0.1,
            text: this.config.text,
            font: BitmapFonts.RobotoSlab.Name,
            size: isCurrency
                ? 110 * gameConfig.resolutionMultiplier
                : 150 * gameConfig.resolutionMultiplier,
        });
        this.text.setTintFill(0x561f0b);
        this.add(this.text);
        this.text.setAlpha(0);
        this.text.setOrigin(0, 0.5);
    };
    WheelSection.prototype.createKnife = function () {
        this.knife = this.scene.make.image({
            x: this.width * 0.65,
            y: 0,
            key: MultiAtlases.Wheel.Atlas.Name,
            frame: MultiAtlases.Wheel.Atlas.Frames.Knife,
        });
        this.knife.setAngle(-90);
        this.knife.setScale(0.5);
        this.knife.setVisible(false);
        this.add(this.knife);
    };
    WheelSection.SHOW_COMPLETE_EVENT = 'showComplete';
    WheelSection.HIDE_COMPLETE_EVENT = 'hideComplete';
    return WheelSection;
}(Phaser.GameObjects.Container));
export default WheelSection;
