var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import GameFacade from '../GameFacade';
import AdsVOProxy from '../model/AdsVOProxy';
import ApiVOProxy from '../model/ApiVOProxy';
import PlayerVOProxy from '../model/PlayerVOProxy';
import SocketVOProxy from '../model/SocketVOProxy';
import BaseStoreVOProxy from '../model/store/BaseStoreVOProxy';
import LogOutConfirmationPopup from '../view/popups/LogOutConfirmationPopup';
import RewardPopup from '../view/popups/RewardPopup';
import LobbyScene from '../view/scenes/LobbyScene';
import PreloadScene from '../view/scenes/PreloadScene';
import RegistrationScene from '../view/scenes/RegistrationScene';
import RoomScene from '../view/scenes/RoomScene';
import TopBarScene from '../view/scenes/TopBarScene';
import WheelScene from '../view/scenes/WheelScene';
import ChangeSettingCommand from './api/ChangeSettingCommand';
import CheckForLoginCommand from './api/CheckForLoginCommand';
import ClaimWheelBonusCommand from './api/ClaimWheelBonusCommand';
import GetStoreDataCommand from './api/GetStoreDataCommand';
import InitializeAPICommand from './api/InitializeAPICommand';
import OnLoginServiceChoseCommand from './api/OnLoginServiceChoseCommand';
import RegisterApiVOProxyCommand from './api/RegisterApiVOProxyCommand';
import CheckLevelUpDataCommand from './api/retrieve-user-data/CheckLevelUpDataCommand';
import CheckServerNotificationsCommand from './api/retrieve-user-data/CheckServerNotificationsCommand';
import RetrievePlayerDataCommand from './api/retrieve-user-data/RetrievePlayerDataCommand';
import UpdateLevelUpDataCommand from './api/retrieve-user-data/UpdateLevelUpDataCommand';
import SetupOneSignalPluginCommand from './api/SetupOneSignalPluginCommand';
import LogoutCommand from './api/sign-out-methods/LogoutCommand';
import SendBoughtProductDataCommand from './api/store/SendBoughtProductDataCommand';
import SendRewardVideoWatchedCommand from './api/store/SendRewardVideoWatchedCommand';
import SendUnfinishedPurchaseDataCommand from './api/store/SendUnfinishedPurchaseDataCommand';
import UpdateUserInfoCommand from './player/UpdateUserInfoCommand';
var RegisterAPICommands = /** @class */ (function (_super) {
    __extends(RegisterAPICommands, _super);
    function RegisterAPICommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterAPICommands.prototype.execute = function () {
        _super.prototype.execute.call(this);
        this.facade.registerCommand(PreloadScene.WAITING_FOR_API_NOTIFICATION, CheckForLoginCommand);
        this.facade.registerCommand(PreloadScene.LOGIN_SERVICE_CHOSE_NOTIFICATION, OnLoginServiceChoseCommand);
        this.facade.registerCommand(GameFacade.LOGIN_SERVICE_SIGH_IN_SUCCESS_NOTIFICATION, InitializeAPICommand);
        this.facade.registerCommand(WheelScene.KNIFE_READY_TO_THROW_NOTIFICATION, ClaimWheelBonusCommand);
        this.facade.registerCommand(PlayerVOProxy.UPDATE_SETTINGS_NOTIFICATION, ChangeSettingCommand);
        this.facade.registerCommand(RegistrationScene.NEXT_CLICKED_NOTIFICATION, UpdateUserInfoCommand);
        this.facade.registerCommand(ApiVOProxy.WHEEL_ROTATION_COMPLETED_NOTIFICATION, GetStoreDataCommand);
        this.facade.registerCommand(SocketVOProxy.USER_CHANNEL_WELCOME_COMPLETED_NOTIFICATION, GetStoreDataCommand);
        this.facade.registerCommand(LobbyScene.SHOWN_NOTIFICATION, UpdateLevelUpDataCommand);
        this.facade.registerCommand(LobbyScene.SHOWN_NOTIFICATION, CheckServerNotificationsCommand);
        this.facade.registerCommand(RewardPopup.HIDDEN_NOTIFICATION, CheckLevelUpDataCommand);
        this.facade.registerCommand(TopBarScene.REWARDS_CLAIMED_NOTIFICATION, RetrievePlayerDataCommand);
        this.facade.registerCommand(ApiVOProxy.PRODUCT_BUY_SUCCEED_NOTIFICATION, RetrievePlayerDataCommand);
        this.facade.registerCommand(SocketVOProxy.ROOM_USERS_INFO_READY_NOTIFICATION, RetrievePlayerDataCommand);
        this.facade.registerCommand(PlayerVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, SetupOneSignalPluginCommand);
        this.facade.registerCommand(BaseStoreVOProxy.PRODUCT_BUY_SUCCEED_NOTIFICATION, SendBoughtProductDataCommand);
        this.facade.registerCommand(BaseStoreVOProxy.UNFINISHED_PURCHASE_NOTIFICATION, SendUnfinishedPurchaseDataCommand);
        this.facade.registerCommand(LogOutConfirmationPopup.LOGOUT_NOTIFICATION, LogoutCommand);
        this.facade.registerCommand(RoomScene.SHOW_COMPLETE_NOTIFICATION, CheckServerNotificationsCommand);
        this.facade.registerCommand(SocketVOProxy.CHARGE_USER_NOTIFICATION, CheckServerNotificationsCommand);
        this.facade.registerCommand(AdsVOProxy.REWARDED_VIDEO_SHOW_COMPLETE_NOTIFICATION, SendRewardVideoWatchedCommand);
    };
    RegisterAPICommands.prototype.initializeMacroCommand = function () {
        this.addSubCommand(RegisterApiVOProxyCommand);
    };
    return RegisterAPICommands;
}(SyncMacroCommand));
export default RegisterAPICommands;
