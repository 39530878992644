var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import RegistrationScene from '../../scenes/RegistrationScene';
import SimpleButtonWithBitmapText from '../../utils/simpleButton/SimpleButtonWithBitmapText';
var LanguageButton = /** @class */ (function (_super) {
    __extends(LanguageButton, _super);
    function LanguageButton(scene, text, language) {
        var _this = this;
        var normalStateTextConfig = {
            text: text,
            font: BitmapFonts.RobotoSlab.Name,
            size: 65 * gameConfig.resolutionMultiplier,
            fill: 0x0ce2e2,
        };
        var hoverStateTextConfig = {
            text: text,
            font: BitmapFonts.RobotoSlab.Name,
            size: 65 * gameConfig.resolutionMultiplier,
            fill: 0x32555b,
        };
        var normalStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.LanguageButtonNormal,
            textConfig: normalStateTextConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.LanguageButtonDown,
            textConfig: hoverStateTextConfig,
        };
        var configs = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: hoverStateConfig,
        };
        _this = _super.call(this, scene, configs) || this;
        _this.language = language;
        _this.text.setAlpha(0);
        return _this;
    }
    LanguageButton.prototype.showText = function () {
        this.scene.tweens.add({
            targets: this.text,
            alpha: 1,
            duration: 150,
        });
    };
    LanguageButton.prototype.onClick = function () {
        this.scene.events.emit(RegistrationScene.LANGUAGE_ACTION_EVENT, this.language);
    };
    return LanguageButton;
}(SimpleButtonWithBitmapText));
export default LanguageButton;
