var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Guard } from '@candywings/pure-mvc';
import StateVOProxy from '../../../model/StateVOProxy';
var IsAttackGuard = /** @class */ (function (_super) {
    __extends(IsAttackGuard, _super);
    function IsAttackGuard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IsAttackGuard.prototype.approve = function (notificationName, card) {
        var stateVOProxy = this.facade.retrieveProxy(StateVOProxy.NAME);
        var cardData = stateVOProxy.getValidCardData(card);
        return (!!cardData &&
            !cardData.canSwap &&
            (!cardData.targets || cardData.targets.length === 0));
    };
    return IsAttackGuard;
}(Guard));
export default IsAttackGuard;
