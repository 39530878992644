var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gameConfig } from '../../constants/GameConfig';
import ApiVOProxy from '../../model/ApiVOProxy';
import PlayerVOProxy from '../../model/PlayerVOProxy';
import { RewardName } from '../../model/VO/ApiVO';
import { Translation } from '../../translations';
import GameScene from '../scenes/GameScene';
import TopBarScene from '../scenes/TopBarScene';
import WheelScene from '../scenes/WheelScene';
import LevelUpPopup from './LevelUpPopup';
import RewardPopup from './RewardPopup';
import StandardPopupMediator from './StandardPopupMediator';
var RewardPopupMediator = /** @class */ (function (_super) {
    __extends(RewardPopupMediator, _super);
    function RewardPopupMediator() {
        return _super.call(this, RewardPopupMediator.NAME) || this;
    }
    RewardPopupMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(WheelScene.WHEEL_ROTATED_NOTIFICATION, PlayerVOProxy.PLAYER_HAS_BONUS_NOTIFICATION, LevelUpPopup.GET_REWARD_CLICKED_NOTIFICATION, GameScene.FORCE_START_NOTIFICATION);
    };
    RewardPopupMediator.prototype.onRegister = function () {
        _super.prototype.onRegister.call(this);
    };
    RewardPopupMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        switch (notificationName) {
            case LevelUpPopup.GET_REWARD_CLICKED_NOTIFICATION:
                {
                    var title = Translation.REWARD_POPUP_TITLE_LEVEL_UP;
                    var rewardName = RewardName.Level_Up;
                    var rewards = this.getRewardsData(rewardName);
                    this.showView(gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.5, title, rewards, rewardName);
                }
                break;
            case PlayerVOProxy.PLAYER_HAS_BONUS_NOTIFICATION:
                {
                    var title = Translation.REWARD_POPUP_TITLE_STARTUP;
                    var rewardName = RewardName.Bonus;
                    var rewards = this.getRewardsData(rewardName);
                    this.showView(gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.5, title, rewards, rewardName);
                }
                break;
            case WheelScene.WHEEL_ROTATED_NOTIFICATION:
                {
                    var title = Translation.REWARD_POPUP_TITLE_WHEEL;
                    var rewardName = RewardName.Wheel;
                    var rewards = this.getRewardsData(rewardName);
                    this.showView(gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.5, title, rewards, rewardName);
                }
                break;
            case TopBarScene.CLAIM_ANIMATION_STARTED_NOTIFICATION:
                this.viewComponent && this.viewComponent.startClaimAnimation();
                break;
            case TopBarScene.CLAIM_ANIMATION_ENDED_NOTIFICATION:
                this.unsubscribeFromTopBarNotifications();
                if (this.viewComponent && this.viewComponent.visible) {
                    this.hideView();
                }
                break;
            default:
                break;
        }
    };
    RewardPopupMediator.prototype.showView = function (x, y, title, rewards, rewardName) {
        var rewardsCheck = [];
        for (var _i = 0, rewards_1 = rewards; _i < rewards_1.length; _i++) {
            var reward = rewards_1[_i];
            if (reward.amount && reward.amount > 0) {
                rewardsCheck.push(reward);
            }
        }
        if (rewardsCheck.length == 0) {
            return;
        }
        this.subscribeToTopBarNotifications();
        _super.prototype.showView.call(this, x, y, title, rewardsCheck, rewardName);
    };
    RewardPopupMediator.prototype.createView = function () {
        _super.prototype.createView.call(this, new RewardPopup());
    };
    RewardPopupMediator.prototype.onViewShowComplete = function () {
        _super.prototype.onViewShowComplete.call(this);
        this.sendNotification(RewardPopup.SHOWN_NOTIFICATION);
    };
    RewardPopupMediator.prototype.onViewHideComplete = function () {
        _super.prototype.onViewHideComplete.call(this);
        this.sendNotification(RewardPopup.HIDDEN_NOTIFICATION);
    };
    RewardPopupMediator.prototype.onAction = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var rewardItems;
            return __generator(this, function (_a) {
                rewardItems = this.viewComponent.getRewardItems();
                this.sendNotification(RewardPopup.CLAIM_REWARD_NOTIFICATION, type, rewardItems);
                return [2 /*return*/];
            });
        });
    };
    RewardPopupMediator.prototype.getRewardsData = function (name) {
        var playerVOProxy = this.facade.retrieveProxy(PlayerVOProxy.NAME);
        var apiVOProxy = this.facade.retrieveProxy(ApiVOProxy.NAME);
        switch (name) {
            case RewardName.Bonus:
                return playerVOProxy.getBonusReward();
            case RewardName.Level_Up:
                return apiVOProxy.getLevelUpRewards();
            case RewardName.Wheel:
                return playerVOProxy.getWheelReward();
            default:
                break;
        }
    };
    RewardPopupMediator.prototype.subscribeToTopBarNotifications = function () {
        this.subscribeToNotifications(TopBarScene.CLAIM_ANIMATION_STARTED_NOTIFICATION, TopBarScene.CLAIM_ANIMATION_ENDED_NOTIFICATION);
    };
    RewardPopupMediator.prototype.unsubscribeFromTopBarNotifications = function () {
        this.unsubscribeToNotification(TopBarScene.CLAIM_ANIMATION_STARTED_NOTIFICATION, TopBarScene.CLAIM_ANIMATION_ENDED_NOTIFICATION);
    };
    RewardPopupMediator.NAME = 'RewardPopupMediator';
    return RewardPopupMediator;
}(StandardPopupMediator));
export default RewardPopupMediator;
