var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import DeckVOProxy from '../model/DeckVOProxy';
import RoomsVOProxy from '../model/RoomsVOProxy';
import SocketVOProxy from '../model/SocketVOProxy';
import PlayerAvatarView from '../view/components/game/PlayerAvatarView';
import WaitingView from '../view/components/game/WaitingView';
import ExitConfirmationPopup from '../view/popups/ExitConfirmationPopup';
import ReplayPopup from '../view/popups/ReplayPopup';
import WaitOrLeavePopup from '../view/popups/WaitOrLeavePopup';
import LobbyScene from '../view/scenes/LobbyScene';
import RoomScene from '../view/scenes/RoomScene';
import ApplyCardPlayCommand from './state/ApplyCardPlayCommand';
import ApplyCardValidationCommand from './state/ApplyCardValidationCommand';
import ApplyDealCommand from './state/ApplyDealCommand';
import ApplyForceGameEndCommand from './state/ApplyForceGameEndCommand';
import ApplyGameResumeDataCommand from './state/ApplyGameResumeDataCommand';
import ApplyNextUserCommand from './state/ApplyNextUserCommand';
import ApplyRoundEndCommand from './state/ApplyRoundEndCommand';
import ClearStateVOCommand from './state/ClearStateVOCommand';
import ClearValidCardsCommand from './state/ClearValidCardsCommand';
import InitDeckStateCommand from './state/InitDeckStateCommand';
import RegisterStateVOProxyCommand from './state/RegisterStateVOProxyCommand';
import SetPlayerGameEndDataCommand from './state/SetPlayerGameEndDataCommand';
import SetPlayersCommand from './state/SetPlayersCommand';
import SetReconnectStateCommand from './state/SetReconnectStateCommand';
import SetStateDataCommand from './state/SetStateDataCommand';
import TurnUserIntoBotCommand from './state/TurnUserIntoBotCommand';
var RegisterStateCommands = /** @class */ (function (_super) {
    __extends(RegisterStateCommands, _super);
    function RegisterStateCommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterStateCommands.prototype.execute = function () {
        this.facade.registerCommand(SocketVOProxy.USER_CHANNEL_WELCOME_USER_COME_BACK_NOTIFICATION, SetReconnectStateCommand);
        this.facade.registerCommand(SocketVOProxy.RECONNECT_GAME_DATA_RECEIVED_NOTIFICATION, SetStateDataCommand);
        this.facade.registerCommand(SocketVOProxy.GAME_STATE_RESPONSE_RECEIVED_NOTIFICATION, ApplyGameResumeDataCommand);
        this.facade.registerCommand(RoomsVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, RegisterStateVOProxyCommand);
        this.facade.registerCommand(DeckVOProxy.INITIALIZE_SUCCESS_NOTIFICATION, InitDeckStateCommand);
        this.facade.registerCommand(SocketVOProxy.ROOM_USERS_INFO_READY_NOTIFICATION, SetPlayersCommand);
        this.facade.registerCommand(SocketVOProxy.CARD_PLAYED_NOTIFICATION, ApplyCardPlayCommand);
        this.facade.registerCommand(SocketVOProxy.ACTIVATE_NEXT_USER_NOTIFICATION, ApplyNextUserCommand);
        this.facade.registerCommand(DeckVOProxy.READY_TO_DEAL_NOTIFICATION, ApplyDealCommand);
        this.facade.registerCommand(SocketVOProxy.CARDS_VALIDATED_NOTIFICATION, ApplyCardValidationCommand);
        this.facade.registerCommand(SocketVOProxy.ROUND_ENDED_NOTIFICATION, ApplyRoundEndCommand);
        this.facade.registerCommand(SocketVOProxy.PLAYER_ENDED_THE_GAME_NOTIFICATION, SetPlayerGameEndDataCommand);
        this.facade.registerCommand(SocketVOProxy.PLAYER_LEFT_ROOM_NOTIFICATION, TurnUserIntoBotCommand);
        this.facade.registerCommand(WaitingView.EXIT_CLICKED_NOTIFICATION, ClearStateVOCommand);
        this.facade.registerCommand(ExitConfirmationPopup.EXIT_ROOM_NOTIFICATION, ClearStateVOCommand);
        this.facade.registerCommand(WaitOrLeavePopup.LEAVE_CLICKED_NOTIFICATION, ClearStateVOCommand);
        this.facade.registerCommand(ReplayPopup.LEAVE_NOTIFICATION, ClearStateVOCommand);
        this.facade.registerCommand(ReplayPopup.USER_REPLAYS_NOTIFICATION, ClearStateVOCommand);
        this.facade.registerCommand(SocketVOProxy.GAME_ENDED_NOTIFICATION, ApplyForceGameEndCommand);
        this.facade.registerCommand(RoomScene.DOOR_SELECTED_NOTIFICATION, ClearStateVOCommand);
        this.facade.registerCommand(LobbyScene.BONUS_CHOSE_NOTIFICATION, ClearStateVOCommand);
        this.facade.registerCommand(PlayerAvatarView.TIMER_COMPLETE_NOTIFICATION, ClearValidCardsCommand);
    };
    return RegisterStateCommands;
}(SyncMacroCommand));
export default RegisterStateCommands;
