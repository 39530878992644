var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import RegisterAdsCommands from './RegisterAdsCommands';
import RegisterAPICommands from './RegisterAPICommands';
import RegisterDeckCommands from './RegisterDeckCommands';
import RegisterPlayerCommands from './RegisterPlayerCommands';
import RegisterRoomsCommands from './RegisterRoomsCommands';
import RegisterServiceCommands from './RegisterServiceCommands';
import RegisterSocketCommands from './RegisterSocketCommands';
import RegisterStateCommands from './RegisterStateCommands';
import RegisterStoreCommands from './RegisterStoreCommands';
import RegisterUiStateCommands from './RegisterUiStateCommands';
import RegisterFirebaseCommands from './RegisterFirebaseCommands';
var StartupCommand = /** @class */ (function (_super) {
    __extends(StartupCommand, _super);
    function StartupCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StartupCommand.prototype.initializeMacroCommand = function () {
        this.addSubCommand(RegisterUiStateCommands);
        this.addSubCommand(RegisterAPICommands);
        this.addSubCommand(RegisterSocketCommands);
        this.addSubCommand(RegisterRoomsCommands);
        this.addSubCommand(RegisterPlayerCommands);
        this.addSubCommand(RegisterDeckCommands);
        this.addSubCommand(RegisterStateCommands);
        this.addSubCommand(RegisterAdsCommands);
        this.addSubCommand(RegisterStoreCommands);
        this.addSubCommand(RegisterFirebaseCommands);
        this.addSubCommand(RegisterServiceCommands);
    };
    return StartupCommand;
}(SyncMacroCommand));
export default StartupCommand;
