var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MultiAtlases } from '../../../assets';
import { getOpponentPositionX } from '../../utils/phaser/PhaserUtils';
import CardView from './CardView';
import { EmojiType } from './control/scroller/MessageTypeButton';
import DurakPlayerView from './DurakPlayerView';
import OpponentAvatar from './OpponentAvatar';
import ToolTip, { ToolTipPosition, ToolTipParent } from './ToolTip';
var OpponentView = /** @class */ (function (_super) {
    __extends(OpponentView, _super);
    function OpponentView(scene, index) {
        var _this = _super.call(this, scene, getOpponentPositionX(index), 0) || this;
        _this.scene = scene;
        _this.index = index;
        _this.scene.add.existing(_this);
        _this.cards = [];
        _this.setDepth(11);
        _this.createBody();
        return _this;
    }
    OpponentView.prototype.clearCards = function () {
        var _this = this;
        if (this.cards && this.cards.length > 0) {
            this.scene.tweens.killTweensOf(this.cards);
            this.scene.tweens.add({
                targets: this.cards,
                scaleX: 0,
                scaleY: 0,
                duration: 200,
                ease: Phaser.Math.Easing.Expo.InOut,
                onComplete: function () {
                    for (var _i = 0, _a = _this.cards; _i < _a.length; _i++) {
                        var card = _a[_i];
                        card.destroy();
                    }
                    _this.cards = [];
                },
            });
        }
    };
    OpponentView.prototype.showTimerHighLight = function () {
        this.mainView.showTimerHighLight();
    };
    OpponentView.prototype.hideTimerHighLight = function () {
        this.mainView.hideTimerHighLight();
    };
    OpponentView.prototype.showEmoji = function (type, key) {
        switch (type) {
            case EmojiType.EMOJI:
                this.mainView.showSmile("smile-" + key);
                break;
            case EmojiType.TEXT:
                this.tooltip.show(2000, "emoji-message-" + key);
                break;
            default:
                break;
        }
    };
    OpponentView.prototype.showTooltip = function (duration, text) {
        this.bringToTop(this.tooltip);
        this.tooltip.show(duration, text);
    };
    OpponentView.prototype.hideTooltip = function () {
        this.tooltip.hide();
    };
    OpponentView.prototype.startTimer = function () {
        this.mainView.startTimer();
    };
    OpponentView.prototype.stopTimer = function () {
        this.mainView.stopTimer();
    };
    OpponentView.prototype.preDestroy = function () {
        this.scene.tweens.killTweensOf(this.cards);
        for (var _i = 0, _a = this.cards; _i < _a.length; _i++) {
            var card = _a[_i];
            card.destroy();
        }
    };
    OpponentView.prototype.forceUpdateCards = function (count) {
        var countDiff = count - this.cards.length;
        for (var i = 0; i < Math.abs(countDiff); i++) {
            if (Math.sign(countDiff) > 0) {
                var card = this.scene.make.sprite({
                    key: MultiAtlases.Cards.Atlas.Name,
                    frame: MultiAtlases.Cards.Atlas.Frames.CoverSmall,
                    x: 0,
                    y: 0,
                    add: true,
                });
                card.y = card.height * 6.1;
                this.cards.push(card);
                this.add(card);
            }
        }
        this.repositionCards();
    };
    OpponentView.prototype.setTimerDuration = function (duration) {
        this.mainView.setTimerDuration(duration);
    };
    OpponentView.prototype.setTimerMode = function (type) {
        this.mainView.setTimerType(type);
    };
    OpponentView.prototype.setAvatar = function (config) {
        this.mainView.updateData(config);
    };
    OpponentView.prototype.getDealedCard = function (card) {
        var _this = this;
        this.scene.tweens.add({
            targets: card,
            x: this.x,
            y: this.y + this.mainView.height * 1.1,
            duration: 750,
            angle: 180,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: card,
                    delay: 200,
                    scaleX: 0,
                    scaleY: 0,
                    duration: 200,
                    ease: Phaser.Math.Easing.Back.In,
                    onComplete: function () {
                        card.destroy();
                        _this.addCards(1);
                    },
                });
            },
        });
    };
    OpponentView.prototype.addCards = function (count) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                        var i, card;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    for (i = 0; i < count; i++) {
                                        card = this.scene.make.sprite({
                                            key: MultiAtlases.Cards.Atlas.Name,
                                            frame: MultiAtlases.Cards.Atlas.Frames.CoverSmall,
                                            x: 0,
                                            y: this.mainView.height * 0.5,
                                            add: true,
                                        });
                                        this.cards.push(card);
                                        this.add(card);
                                    }
                                    return [4 /*yield*/, this.repositionCards()];
                                case 1:
                                    _a.sent();
                                    resolve();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    OpponentView.prototype.createCard = function (cardData) {
        var selectedCard = new CardView(this.scene, this.x, this.y + this.mainView.height * 0.5, cardData.suit, cardData.value);
        selectedCard.setDepth(-1);
        selectedCard.setScale(0);
        this.scene.add.existing(selectedCard);
        return selectedCard;
    };
    OpponentView.prototype.shiftCard = function () {
        var smallCard = this.cards.shift();
        smallCard && smallCard.destroy();
        this.repositionCards();
    };
    OpponentView.prototype.getCards = function (targets) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.scene.tweens.add({
                            targets: targets,
                            duration: 300,
                            x: _this.x,
                            y: _this.mainView.avatar.y,
                            onComplete: function () {
                                _this.scene.tweens.add({
                                    targets: targets,
                                    scaleX: 0,
                                    scaleY: 0,
                                    duration: 200,
                                    ease: Phaser.Math.Easing.Back.In,
                                    onComplete: function () { return __awaiter(_this, void 0, void 0, function () {
                                        var cardsCount, _i, targets_1, card;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    cardsCount = targets.length;
                                                    for (_i = 0, targets_1 = targets; _i < targets_1.length; _i++) {
                                                        card = targets_1[_i];
                                                        card.destroy();
                                                    }
                                                    return [4 /*yield*/, this.addCards(cardsCount)];
                                                case 1:
                                                    _a.sent();
                                                    this.emit(OpponentView.TAKING_CARDS_EVENT);
                                                    resolve();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); },
                                });
                            },
                        });
                    })];
            });
        });
    };
    OpponentView.prototype.activeMode = function (on, attacker) {
        var _this = this;
        if (attacker === void 0) { attacker = true; }
        var scale = on ? 1.3 : 1;
        this.scene.tweens.add({
            targets: this,
            ease: Phaser.Math.Easing.Back.InOut,
            scaleX: scale,
            scaleY: scale,
            duration: 500,
            onUpdate: function () {
                _this.mainView.setPosition();
            }
        });
        this.emblem.setTexture(MultiAtlases.GamePlay.Atlas.Name, attacker ? MultiAtlases.GamePlay.Atlas.Frames.EmblemAttack : MultiAtlases.GamePlay.Atlas.Frames.EmblemShield);
        this.bringToTop(this.emblem);
        this.emblem.setVisible(on);
    };
    OpponentView.prototype.repositionCards = function () {
        return __awaiter(this, void 0, void 0, function () {
            var smallCardFrame, flagFrame, y, sumWidth, line, points, i, card;
            return __generator(this, function (_a) {
                smallCardFrame = this.scene.textures.getFrame(MultiAtlases.Cards.Atlas.Name, MultiAtlases.Cards.Atlas.Frames.CoverSmall);
                flagFrame = this.scene.textures.getFrame(MultiAtlases.Flags.Atlas.Name, MultiAtlases.Flags.Atlas.Frames.FlagLeagueNone);
                y = flagFrame.height * 0.3;
                sumWidth = Math.min(this.width * 1.2, this.cards.length * smallCardFrame.width);
                line = new Phaser.Geom.Line(-sumWidth / 2, y, sumWidth / 2, y);
                points = line.getPoints(this.cards.length + 1);
                points.shift();
                for (i = 0; i < this.cards.length; i++) {
                    card = this.cards[i];
                    card.depth = i + 1;
                    this.scene.tweens.killTweensOf(this.cards);
                    this.scene.tweens.add({
                        targets: card,
                        x: points[i].x,
                        y: points[i].y,
                        duration: 100,
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    OpponentView.prototype.createBody = function () {
        this.createAvatar();
        this.createTooltip();
        this.createEmbleme();
    };
    OpponentView.prototype.createAvatar = function () {
        this.mainView = new OpponentAvatar(this.scene);
        this.add(this.mainView);
        this.setSize(this.mainView.width, this.mainView.height);
        this.mainView.setPosition();
        this.mainView.show();
    };
    OpponentView.prototype.createTooltip = function () {
        this.tooltip = new ToolTip(this.scene, ToolTipPosition.BOTTOM, ToolTipParent.OPPONENT);
        this.tooltip.y = this.y + this.mainView.height * 1.635;
        this.tooltip.x = -this.width * 0.4;
        this.add(this.tooltip);
    };
    OpponentView.prototype.createEmbleme = function () {
        this.emblem = this.scene.make.image({
            x: this.mainView.x + this.mainView.width * 0.3,
            y: this.mainView.y + this.mainView.height * 0.6,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.EmblemAttack,
        });
        this.emblem.setVisible(false);
        this.add(this.emblem);
    };
    OpponentView.NAME = 'OpponentView';
    OpponentView.GOT_CARDS_NOTIFICATION = OpponentView.NAME + "GotCardsNotification";
    OpponentView.AVATAR_CLICKED_NOTIFICATION = OpponentView.NAME + "AvatarClickedNotification";
    OpponentView.AVATAR_CLICK_EVENT = 'avatarClicked';
    OpponentView.TAKING_CARDS_EVENT = 'takingCards';
    return OpponentView;
}(DurakPlayerView));
export default OpponentView;
