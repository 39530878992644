var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { setDataToStorage, StorageKey, } from '../../../utils/wrappers/StorageWrapper';
import IsGameSceneActiveGuard from '../../Guards/scenes/IsGameSceneActiveGuard';
import SocketCommand from '../SocketCommand';
import GameScene from '../../../view/scenes/GameScene';
var JoinToPreparedRoomChannelCommand = /** @class */ (function (_super) {
    __extends(JoinToPreparedRoomChannelCommand, _super);
    function JoinToPreparedRoomChannelCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    JoinToPreparedRoomChannelCommand.prototype.prepare = function () {
        this.addGuards(IsGameSceneActiveGuard);
    };
    JoinToPreparedRoomChannelCommand.prototype.execute = function () {
        setDataToStorage(StorageKey.LAST_GAME_JOIN, Date.now());
        this.proxy.joinRoomChannel(this.proxy.vo.roomId, this.proxy.vo.isReconnect);
        this.facade.removeCommand(GameScene.CREATE_NOTIFICATION, JoinToPreparedRoomChannelCommand);
    };
    return JoinToPreparedRoomChannelCommand;
}(SocketCommand));
export default JoinToPreparedRoomChannelCommand;
