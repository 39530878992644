var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SimpleCommand } from '@candywings/pure-mvc';
import Game from '../../Game';
import GameFacade from '../../GameFacade';
var CloseAppCommand = /** @class */ (function (_super) {
    __extends(CloseAppCommand, _super);
    function CloseAppCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CloseAppCommand.prototype.execute = function () {
        if (navigator.app) {
            navigator.app.exitApp();
        }
        else {
            GameFacade.game.loop.stop();
            GameFacade.removeCore(Game.NAME);
            GameFacade.game = null;
            document.head.remove();
            document.body.remove();
        }
    };
    return CloseAppCommand;
}(SimpleCommand));
export default CloseAppCommand;
