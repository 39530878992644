var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import SocketVOProxy from '../model/SocketVOProxy';
import StateVOProxy from '../model/StateVOProxy';
import DeckView from '../view/components/game/DeckView';
import CardDealedCommand from './deck/CardDealedCommand';
import DealCompleteCommand from './deck/DealCompleteCommand';
import RegisterDeckCommand from './deck/RegisterDeckCommand';
import SetDeckForceDataCommand from './deck/SetDeckForceDataCommand';
import SetDeckTrumpCommand from './deck/SetDeckTrumpCommand';
import SetOpponentsCardsToDealCommand from './deck/SetOpponentsCardsToDealCommand';
import SetPlayerCardsToDealCommand from './deck/SetPlayerCardsToDealCommand';
import UpdateDeckReferenceCommand from './deck/UpdateDeckReferenceCommand';
var RegisterDeckCommands = /** @class */ (function (_super) {
    __extends(RegisterDeckCommands, _super);
    function RegisterDeckCommands() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterDeckCommands.prototype.execute = function () {
        this.facade.registerCommand(SocketVOProxy.FIND_ROOM_ALLOWED_NOTIFICATION, RegisterDeckCommand);
        this.facade.registerCommand(SocketVOProxy.PREPARE_DECK_NOTIFICATION, SetDeckTrumpCommand);
        this.facade.registerCommand(SocketVOProxy.SET_PLAYER_CARDS_TO_DEAL_NOTIFICATION, SetPlayerCardsToDealCommand);
        this.facade.registerCommand(SocketVOProxy.SET_OPPONENTS_CARDS_TO_DEAL_NOTIFICATION, SetOpponentsCardsToDealCommand);
        this.facade.registerCommand(DeckView.DEAL_COMPLETE_NOTIFICATION, DealCompleteCommand);
        this.facade.registerCommand(DeckView.DEAL_CARD_NOTIFICATION, CardDealedCommand);
        this.facade.registerCommand(StateVOProxy.FORCE_DATA_INITIALIZED_NOTIFICATION, SetDeckForceDataCommand);
        this.facade.registerCommand(StateVOProxy.CLEARED_NOTIFICATION, UpdateDeckReferenceCommand);
    };
    return RegisterDeckCommands;
}(SyncMacroCommand));
export default RegisterDeckCommands;
