var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import TextureGenerationContainer from '../../utils/plugins/textureGenerator/TextureGenerationContainer';
var LobbySceneBackgroundTexture = /** @class */ (function (_super) {
    __extends(LobbySceneBackgroundTexture, _super);
    function LobbySceneBackgroundTexture() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LobbySceneBackgroundTexture.prototype.createContent = function () {
        this.setKey(MultiAtlases.Lobby.Atlas.Name + "-" + MultiAtlases.Lobby.Atlas.Frames.Background);
        this.createSceneBackground();
        this.setSize(gameConfig.canvasWidth, gameConfig.canvasHeight);
        this.x = 0;
        this.y = 0;
    };
    LobbySceneBackgroundTexture.prototype.createSceneBackground = function () {
        this.background = this.scene.make.image({
            x: gameConfig.canvasWidth / 2,
            y: gameConfig.canvasHeight,
            key: MultiAtlases.Lobby.Atlas.Name,
            frame: MultiAtlases.Lobby.Atlas.Frames.Background,
        });
        this.background.setOrigin(0.5, 1);
        this.background.setScale(Math.max(gameConfig.canvasHeight / this.background.height, gameConfig.canvasWidth / this.background.width));
        this.add(this.background);
    };
    return LobbySceneBackgroundTexture;
}(TextureGenerationContainer));
export default LobbySceneBackgroundTexture;
