var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Atlases, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import NavigationSection, { NavigationMode, } from '../components/lobby/navigation/NavigationSection';
import SettingsButton from '../components/lobby/SettingsButton';
import BaseScene from './BaseScene';
var LobbyScene = /** @class */ (function (_super) {
    __extends(LobbyScene, _super);
    function LobbyScene() {
        return _super.call(this, LobbyScene.NAME) || this;
    }
    LobbyScene.prototype.create = function () {
        this.createBackground();
        this.createSettingsButton();
        this.createModes();
    };
    LobbyScene.prototype.makeSelected = function (mode) {
        return __awaiter(this, void 0, void 0, function () {
            var _i, _a, section, targetSection, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        for (_i = 0, _a = this.sections; _i < _a.length; _i++) {
                            section = _a[_i];
                            section.makeUnSelected();
                        }
                        targetSection = this.sections.find(function (section) { return section.mode === mode; });
                        _b = !!targetSection;
                        if (!_b) return [3 /*break*/, 2];
                        return [4 /*yield*/, targetSection.makeSelected()];
                    case 1:
                        _b = (_c.sent());
                        _c.label = 2;
                    case 2:
                        _b;
                        return [2 /*return*/];
                }
            });
        });
    };
    LobbyScene.prototype.enableNavigationInput = function () {
        for (var _i = 0, _a = this.sections; _i < _a.length; _i++) {
            var section = _a[_i];
            section.setListeners();
            section.makeUnSelected();
        }
    };
    LobbyScene.prototype.disableNavigationInput = function () {
        for (var _i = 0, _a = this.sections; _i < _a.length; _i++) {
            var section = _a[_i];
            section.removeAllListeners();
        }
    };
    LobbyScene.prototype.createBackground = function () {
        this.background = this.make.image({
            x: gameConfig.canvasWidth / 2,
            y: gameConfig.canvasHeight,
            key: MultiAtlases.Lobby.Atlas.Name,
            frame: MultiAtlases.Lobby.Atlas.Frames.Background,
        });
        this.background.setOrigin(0.5, 1);
        this.background.setScale(Math.max(gameConfig.canvasWidth / this.background.width, gameConfig.canvasHeight / this.background.height));
    };
    LobbyScene.prototype.createSettingsButton = function () {
        this.settingsButton = new SettingsButton(this);
        this.add.existing(this.settingsButton);
        this.settingsButton.x = this.settingsButton.width * 0.76;
        this.settingsButton.y =
            gameConfig.canvasHeight - this.settingsButton.width * 0.7;
    };
    LobbyScene.prototype.createModes = function () {
        var modes = [
            NavigationMode.GAME,
            NavigationMode.BONUS,
            NavigationMode.LEAGUE,
        ];
        this.sections = [];
        for (var _i = 0, modes_1 = modes; _i < modes_1.length; _i++) {
            var mode = modes_1[_i];
            var section = new NavigationSection(this, mode);
            this.sections.push(section);
        }
        for (var i = 0; i < this.sections.length; i++) {
            var section = this.sections[i];
            var position = this.navigationSectionsPositions[i];
            section.setPosition(position.x, position.y);
        }
    };
    Object.defineProperty(LobbyScene.prototype, "navigationSectionsPositions", {
        get: function () {
            var gup = gameConfig.designWidth * 0.08;
            var y = 0;
            var line = new Phaser.Geom.Line((-(this.sections.length - 1) * gup) / 2, y, gameConfig.canvasWidth + ((this.sections.length - 1) * gup) / 2, y);
            var points = line.getPoints(this.sections.length + 1);
            points.shift();
            return points;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LobbyScene.prototype, "topBarBgHeight", {
        get: function () {
            return this.textures.getFrame(Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.Background).height;
        },
        enumerable: true,
        configurable: true
    });
    LobbyScene.NAME = 'LobbyScene';
    LobbyScene.SHOWN_NOTIFICATION = LobbyScene.NAME + "ShownNotification";
    LobbyScene.HIDDEN_NOTIFICATION = LobbyScene.NAME + "HiddenNotification";
    LobbyScene.BONUS_CHOSE_NOTIFICATION = LobbyScene.NAME + "BonusChoseNotification";
    LobbyScene.GAME_CHOSE_NOTIFICATION = LobbyScene.NAME + "GameChoseNotification";
    LobbyScene.LEAGUE_CHOSE = LobbyScene.NAME + "LeagueChose";
    LobbyScene.SWITCHING_TO_GAME_SCENE = LobbyScene.NAME + "SwitchingToGameScene";
    LobbyScene.SETTINGS_CLICKED_NOTIFICATION = LobbyScene.NAME + "SettingsClickedNotification";
    LobbyScene.ACTION_DONE_EVENT = 'actionDone';
    return LobbyScene;
}(BaseScene));
export default LobbyScene;
