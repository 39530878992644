var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../../assets';
import RegistrationScene from '../../../scenes/RegistrationScene';
var AvatarScrollableLineItem = /** @class */ (function (_super) {
    __extends(AvatarScrollableLineItem, _super);
    function AvatarScrollableLineItem(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        _this.updateMask();
        return _this;
    }
    AvatarScrollableLineItem.prototype.updateMask = function () {
        if (!this.maskGraphics) {
            return;
        }
        this.maskGraphics.clear();
        this.maskGraphics.fillStyle(0xffffff);
        var transformMatrix = this.getWorldTransformMatrix();
        this.maskGraphics.fillCircle(transformMatrix.tx + this.displayWidth / 2, transformMatrix.ty + this.displayHeight / 2, this.avatarBackground.width * 0.465 * this.scaleX);
    };
    AvatarScrollableLineItem.prototype.createBody = function () {
        this.createCircleBackground();
        this.setSize(this.circleBackground.width, this.circleBackground.height);
        this.createAvatarBackground();
        this.createAvatarIcon();
        this.setListeners();
    };
    AvatarScrollableLineItem.prototype.createCircleBackground = function () {
        this.circleBackground = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.CircleAvatar,
        });
        this.circleBackground.setOrigin(0);
        this.add(this.circleBackground);
    };
    AvatarScrollableLineItem.prototype.createAvatarBackground = function () {
        var center = this.circleBackground.getCenter();
        this.avatarBackground = this.scene.make.image({
            x: center.x,
            y: center.y,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.AvatarPicBg,
        });
        this.add(this.avatarBackground);
    };
    AvatarScrollableLineItem.prototype.createAvatarIcon = function () {
        var center = this.avatarBackground.getCenter();
        this.avatarIcon = this.scene.make.image({
            x: center.x,
            y: center.y,
            key: this.config.key,
        });
        this.add(this.avatarIcon);
        if (this.config.key == "playerDefaultAvatar") {
            this.avatarIcon.setScale((this.avatarBackground.width * 0.92) / this.avatarIcon.width);
        }
    };
    AvatarScrollableLineItem.prototype.createMask = function () {
        this.maskGraphics = this.scene.make.graphics({});
        this.avatarIcon.setMask(new Phaser.Display.Masks.GeometryMask(this.scene, this.maskGraphics));
    };
    AvatarScrollableLineItem.prototype.setListeners = function () {
        this.circleBackground.setInteractive();
        this.circleBackground.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
    };
    AvatarScrollableLineItem.prototype.onPointerDown = function (pointer) {
        if (!this.isInVisibilityZone(pointer.x, pointer.y)) {
            return;
        }
        this.scene.tweens.add({
            targets: this.avatarBackground,
            scaleX: 1.1,
            scaleY: 1.1,
            duration: 100,
        });
        this.circleBackground.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.circleBackground.once(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    AvatarScrollableLineItem.prototype.onPointerUp = function (pointer) {
        this.onPointerOut();
        if (!this.isInVisibilityZone(pointer.x, pointer.y) ||
            pointer.getDistance() >= this.scene.input.dragDistanceThreshold) {
            return;
        }
        this.scene.events.emit(RegistrationScene.AVATAR_CHANGED_EVENT, this.avatarIcon.texture.key);
    };
    AvatarScrollableLineItem.prototype.onPointerOut = function () {
        this.scene.tweens.add({
            targets: this.avatarBackground,
            scaleX: 1,
            scaleY: 1,
            duration: 100,
        });
        this.circleBackground.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.circleBackground.off(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    AvatarScrollableLineItem.prototype.isInVisibilityZone = function (x, y) {
        var line = this
            .parentContainer;
        var scrollableContainer = line.parentContainer;
        return scrollableContainer.background.getBounds().contains(x, y);
    };
    return AvatarScrollableLineItem;
}(Phaser.GameObjects
    .Container));
export default AvatarScrollableLineItem;
