var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import WheelSection from './WheelSection';
var WheelSections = /** @class */ (function (_super) {
    __extends(WheelSections, _super);
    function WheelSections(scene, x, y, configs) {
        var _this = _super.call(this, scene, x, y) || this;
        _this.scene = scene;
        _this.configs = configs;
        _this.createContent();
        _this.angularSpeed = 0;
        return _this;
    }
    WheelSections.prototype.update = function () {
        this.angle += this.angularSpeed;
    };
    WheelSections.prototype.createContent = function () {
        var angleDif = 360 / this.configs.length;
        for (var i = 0; i < this.configs.length; i++) {
            var config = this.configs[i];
            var angle = angleDif * i;
            this.createSection(config, angle, i);
        }
    };
    WheelSections.prototype.createSection = function (config, angle, index) {
        var section = new WheelSection(this.scene, index, config, angle);
        this.add(section);
    };
    return WheelSections;
}(Phaser.GameObjects.Container));
export default WheelSections;
