var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Game from '../../Game';
import GameFacade from '../../GameFacade';
import { loadScript } from '../Utils';
export function loadCordova() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    window.defaultOpen = window.open;
                    return [4 /*yield*/, loadScript('cordova.js')];
                case 1:
                    _a.sent();
                    init();
                    return [2 /*return*/];
            }
        });
    });
}
function init() {
    document.addEventListener('deviceready', function () {
        // CrashlyticsWrapper.initialize();
        switch (window.cordova.platformId) {
            case 'android':
                preparePlugins();
                initAndroid();
                break;
            case 'ios':
                preparePlugins();
                initIOS();
                break;
            case 'browser':
                initBrowser();
                break;
            default:
                console.warn("platform " + window.cordova.platformId + " is not expected for handling in CordovaWrapper");
                break;
        }
    });
}
function initAndroid() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            initAndroidFullScreen()
                .then(function () {
                overrideBackButtonAction();
                new Game();
            })
                .catch(function (error) {
                console.warn(error);
            });
            return [2 /*return*/];
        });
    });
}
function preparePlugins() {
    window.cordovaPlugins = {
        OneSignal: window.plugins.OneSignal,
    };
}
function initIOS() {
    new Game();
}
function initBrowser() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            window.open = window.defaultOpen;
            new Game();
            return [2 /*return*/];
        });
    });
}
function initAndroidFullScreen() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    AndroidFullScreen.isSupported(function () {
                        AndroidFullScreen.immersiveMode(resolve, reject);
                    }, reject);
                })];
        });
    });
}
function overrideBackButtonAction() {
    document.addEventListener('backbutton', function (event) {
        event.preventDefault();
        GameFacade.onMobileBackButtonClick();
        return true;
    }, false);
}
