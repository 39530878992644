var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import StoreAdapter from '../../service/StoreAdapter';
import { InappProductTypes as InAppProductTypes, } from '../../utils/wrappers/InAppPurchaseWrapper';
import { Currency } from '../VO/PlayerVO';
import { StoreItemActionType, StoreItemState, } from '../VO/StoreVO';
import BaseStoreVOProxy from './BaseStoreVOProxy';
var CordovaStoreVOProxy = /** @class */ (function (_super) {
    __extends(CordovaStoreVOProxy, _super);
    function CordovaStoreVOProxy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CordovaStoreVOProxy.prototype.onRegister = function () {
        var _this = this;
        StoreAdapter.initialize();
        this.executeUnConsumedProducts().then(function () {
            _this.sendNotification(BaseStoreVOProxy.REGISTERED_NOTIFICATION);
        });
    };
    CordovaStoreVOProxy.prototype.verifyUnConsumedProducts = function () {
        for (var _i = 0, _a = this.vo.buyFailedProducts; _i < _a.length; _i++) {
            var product = _a[_i];
            this.sendNotification(BaseStoreVOProxy.UNFINISHED_PURCHASE_NOTIFICATION, product);
        }
    };
    CordovaStoreVOProxy.prototype.buyProduct = function (section, productId) {
        return __awaiter(this, void 0, void 0, function () {
            var targetProduct;
            var _this = this;
            return __generator(this, function (_a) {
                targetProduct = this.vo.mergedData[section].find(function (value) { return value.productId === productId; });
                if (!targetProduct) {
                    this.sendNotification(BaseStoreVOProxy.PRODUCT_DOES_NOT_EXIST_NOTIFICATION, section, productId);
                }
                else {
                    this.sendNotification(BaseStoreVOProxy.PRODUCT_BUY_PENDING_NOTIFICATION, section, productId);
                    StoreAdapter.buy(productId)
                        .then(function (boughtProduct) {
                        var productInfo = _this.vo.serviceData[section].find(function (product) {
                            product.productId === productId;
                        });
                        _this.sendNotification(BaseStoreVOProxy.PRODUCT_BUY_SUCCEED_NOTIFICATION, boughtProduct, productInfo);
                    })
                        .catch(function (reason) {
                        _this.sendNotification(BaseStoreVOProxy.PRODUCT_BUY_FAILED_NOTIFICATION, section, productId, reason);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    CordovaStoreVOProxy.prototype.addToBuyFailedList = function (product) {
        this.vo.buyFailedProducts.push(product);
    };
    CordovaStoreVOProxy.prototype.addToConsumeFailedList = function (product) {
        this.vo.consumeFailedProducts.push(product);
    };
    CordovaStoreVOProxy.prototype.removeFromBuyFailedList = function (product) {
        var failedProduct = this.vo.buyFailedProducts.find(function (failedProduct) {
            return failedProduct.productId === product.productId;
        });
        failedProduct && this.vo.buyFailedProducts.remove(failedProduct);
        this.vo.buyFailedProducts.length === 0 &&
            !!this.vo.interval &&
            clearInterval(this.vo.interval);
    };
    CordovaStoreVOProxy.prototype.removeFromConsumeFailedList = function (product) {
        var failedProduct = this.vo.consumeFailedProducts.find(function (failedProduct) {
            return failedProduct.productId === product.productId;
        });
        failedProduct && this.vo.buyFailedProducts.remove(failedProduct);
    };
    CordovaStoreVOProxy.prototype.consumeProduct = function (receipt) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.removeFromBuyFailedList(receipt);
                window.inAppPurchase
                    .consume(receipt.productType, receipt.receipt, receipt.signature)
                    .then(function (value) {
                    _this.removeFromConsumeFailedList(receipt);
                    _this.sendNotification(BaseStoreVOProxy.PRODUCT_CONSUME_SUCCEED_NOTIFICATION, value);
                })
                    .catch(function (reason) {
                    _this.addToConsumeFailedList(receipt);
                    _this.sendNotification(BaseStoreVOProxy.PRODUCT_CONSUME_FAILED_NOTIFICATION, receipt, reason);
                });
                return [2 /*return*/];
            });
        });
    };
    CordovaStoreVOProxy.prototype.removeAllProducts = function () {
        this.vo.apiData = {
            gold: [],
            silver: [],
            crystal: [],
            free: [],
            discounts: [],
        };
        this.vo.serviceData = {
            gold: [],
            silver: [],
            crystal: [],
            free: [],
        };
        this.vo.mergedData = {
            gold: [],
            silver: [],
            crystal: [],
            free: [],
        };
        this.sendNotification(BaseStoreVOProxy.STORE_DATA_REMOVED_NOTIFICATION);
    };
    CordovaStoreVOProxy.prototype.updateData = function (data) {
        this.removeAllProducts();
        this.vo.updated = false;
        this.vo.apiData.gold = data.gold || [];
        this.vo.apiData.silver = data.silver || [];
        this.vo.apiData.crystal = data.crystal || [];
        this.vo.apiData.free = data.free || [];
        this.vo.apiData.discounts = data.discounts || [];
        this.vo.initializedMoment = Date.now();
        this.registerProducts();
    };
    CordovaStoreVOProxy.prototype.registerProducts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.registerProductsToStore()
                    .then(function () {
                    _this.getProductsDataFromStore().then(function () {
                        _this.margeProductObjects();
                        _this.vo.updated = true;
                        _this.sendNotification(BaseStoreVOProxy.STORE_DATA_UPDATED_NOTIFICATION);
                    });
                })
                    .catch(function (error) {
                    _this.sendNotification(BaseStoreVOProxy.INITIALIZE_FAILED_NOTIFICATION, error);
                });
                return [2 /*return*/];
            });
        });
    };
    CordovaStoreVOProxy.prototype.executeUnConsumedProducts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var unConsumedProducts, consumableProducts, _i, consumableProducts_1, product;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, StoreAdapter.restorePurchases()];
                    case 1:
                        unConsumedProducts = (_a.sent()) || [];
                        consumableProducts = unConsumedProducts.filter(function (product) {
                            return product.productType === InAppProductTypes.CONSUMABLE;
                        });
                        _i = 0, consumableProducts_1 = consumableProducts;
                        _a.label = 2;
                    case 2:
                        if (!(_i < consumableProducts_1.length)) return [3 /*break*/, 5];
                        product = consumableProducts_1[_i];
                        this.sendNotification(BaseStoreVOProxy.CHECK_NOT_COMPLETE_PRODUCTS_NOTIFICATION, product);
                        return [4 /*yield*/, this.consumeProduct(product)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CordovaStoreVOProxy.prototype.registerProductsToStore = function () {
        return __awaiter(this, void 0, void 0, function () {
            var goldProductIds, crystalProductIds, silverProductIds;
            return __generator(this, function (_a) {
                goldProductIds = this.vo.apiData.gold.map(function (item) { return item.productId; });
                crystalProductIds = this.vo.apiData.crystal.map(function (item) { return item.productId; });
                silverProductIds = this.vo.apiData.silver.map(function (item) { return item.productId; });
                StoreAdapter.registerProducts.apply(StoreAdapter, goldProductIds.concat(crystalProductIds, silverProductIds));
                return [2 /*return*/];
            });
        });
    };
    CordovaStoreVOProxy.prototype.getProductsDataFromStore = function () {
        return __awaiter(this, void 0, void 0, function () {
            var goldProductIds, crystalProductIds, silverProductIds, _a, _b, error_1, _c, _d, error_2, _e, _f, error_3;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        goldProductIds = this.vo.apiData.gold.map(function (item) { return item.productId; });
                        crystalProductIds = this.vo.apiData.crystal.map(function (item) { return item.productId; });
                        silverProductIds = this.vo.apiData.silver.map(function (item) { return item.productId; });
                        _g.label = 1;
                    case 1:
                        _g.trys.push([1, 4, , 5]);
                        _a = this.vo.serviceData;
                        _b = goldProductIds;
                        if (!_b) return [3 /*break*/, 3];
                        return [4 /*yield*/, StoreAdapter.getProducts(goldProductIds)];
                    case 2:
                        _b = (_g.sent());
                        _g.label = 3;
                    case 3:
                        _a.gold = _b;
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _g.sent();
                        this.vo.serviceData.gold = [];
                        return [3 /*break*/, 5];
                    case 5:
                        _g.trys.push([5, 8, , 9]);
                        _c = this.vo.serviceData;
                        _d = goldProductIds;
                        if (!_d) return [3 /*break*/, 7];
                        return [4 /*yield*/, StoreAdapter.getProducts(crystalProductIds)];
                    case 6:
                        _d = (_g.sent());
                        _g.label = 7;
                    case 7:
                        _c.crystal = _d;
                        return [3 /*break*/, 9];
                    case 8:
                        error_2 = _g.sent();
                        this.vo.serviceData.crystal = [];
                        return [3 /*break*/, 9];
                    case 9:
                        _g.trys.push([9, 12, , 13]);
                        _e = this.vo.serviceData;
                        _f = goldProductIds;
                        if (!_f) return [3 /*break*/, 11];
                        return [4 /*yield*/, StoreAdapter.getProducts(silverProductIds)];
                    case 10:
                        _f = (_g.sent());
                        _g.label = 11;
                    case 11:
                        _e.silver = _f;
                        return [3 /*break*/, 13];
                    case 12:
                        error_3 = _g.sent();
                        this.vo.serviceData.silver = [];
                        return [3 /*break*/, 13];
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    CordovaStoreVOProxy.prototype.margeProductObjects = function () {
        var keys = Object.keys(this.vo.apiData);
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key = keys_1[_i];
            if (key === 'discounts') {
                continue;
            }
            this.margeProductObject(key);
        }
    };
    CordovaStoreVOProxy.prototype.margeProductObject = function (type) {
        var apiDataTargetArray = this.vo.apiData[type];
        var serviceDataTargetArray = this.vo.serviceData[type];
        var mergedDataTargetArray = this.vo.mergedData[type];
        mergedDataTargetArray.remove.apply(mergedDataTargetArray, mergedDataTargetArray);
        if (!apiDataTargetArray || !apiDataTargetArray.length) {
            return;
        }
        if (type === 'free') {
            var mergedDataTargetArray_1 = this.vo.mergedData[type];
            mergedDataTargetArray_1.remove.apply(mergedDataTargetArray_1, mergedDataTargetArray_1);
            var _loop_1 = function (i) {
                var apiItem = apiDataTargetArray[i];
                mergedDataTargetArray_1.push({
                    iconIndex: i,
                    productId: apiItem.type +
                        mergedDataTargetArray_1.filter(function (element) { return element.price === apiItem.type; }).length,
                    state: StoreItemState.NORMAL,
                    value: apiItem.value,
                    price: apiItem.type,
                    section: apiItem.section,
                    name: null,
                    description: null,
                    packSize: null,
                    type: Currency.GOLD,
                    action: apiItem.type,
                    multiplier: 1,
                    remaining: apiItem.remaining,
                });
            };
            for (var i = 0; i < apiDataTargetArray.length; i++) {
                _loop_1(i);
            }
            return;
        }
        var _loop_2 = function (i) {
            var apiItem = apiDataTargetArray[i];
            var serviceItem = serviceDataTargetArray.find(function (product) { return product.productId === apiItem.productId; });
            if (!apiItem || !serviceItem) {
                return "continue";
            }
            var mergedProduct = {
                iconIndex: apiItem.id - 1,
                productId: apiItem.productId,
                state: apiItem.bestValue
                    ? StoreItemState.BEST_VALUE
                    : apiItem.bestSeller
                        ? StoreItemState.BEST_SELLER
                        : StoreItemState.NORMAL,
                value: apiItem.value,
                price: serviceItem.price,
                section: apiItem.section,
                name: serviceItem.title,
                description: serviceItem.description,
                packSize: apiItem.packSize,
                type: apiItem.type,
                action: apiItem.action || StoreItemActionType.BUY,
                multiplier: apiItem.multiplier,
            };
            mergedDataTargetArray.push(mergedProduct);
        };
        for (var i = 0; i < apiDataTargetArray.length; i++) {
            _loop_2(i);
        }
    };
    CordovaStoreVOProxy.NAME = 'StoreVOProxy';
    return CordovaStoreVOProxy;
}(BaseStoreVOProxy));
export default CordovaStoreVOProxy;
