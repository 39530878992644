var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import AdCommand from "./AdCommand";
import { AdMobEvents } from "../../utils/wrappers/AdMobWrapper";
var RegisterAdEventsCommand = /** @class */ (function (_super) {
    __extends(RegisterAdEventsCommand, _super);
    function RegisterAdEventsCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterAdEventsCommand.prototype.execute = function () {
        document.addEventListener(AdMobEvents.ON_REWARDED_OPEN, this.proxy.sendRewardOpenNotififcation.bind(this.proxy));
        document.addEventListener(AdMobEvents.ON_REWARDED_CLOSE, this.proxy.sendRewardClosedNotififcation.bind(this.proxy));
        document.addEventListener(AdMobEvents.ON_REWARDED_REWARD, this.proxy.sendRewardNotififcation.bind(this.proxy));
        // document.addEventListener(AdMobEvents.ON_REWARDED_SHOW_FAIL, this.proxy.sendRewardRewardNotififcation.bind(this.proxy));
    };
    return RegisterAdEventsCommand;
}(AdCommand));
export default RegisterAdEventsCommand;
