var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import ControlLeaveButton from './buttons/ControlLeaveButton';
import ControlMessageButton from './buttons/ControlMessageButton';
import ControlSettingsButton from './buttons/ControlSettingsButton';
import ControlSmileButton from './buttons/ControlSmileButton';
import EmojiContainer from './control/scroller/EmojiContainer';
export var ControlAction;
(function (ControlAction) {
    ControlAction[ControlAction["BACK"] = -1] = "BACK";
    ControlAction[ControlAction["SETTINGS"] = 0] = "SETTINGS";
    ControlAction[ControlAction["MESSAGES"] = 1] = "MESSAGES";
    ControlAction[ControlAction["SMILES"] = 2] = "SMILES";
})(ControlAction || (ControlAction = {}));
var ControlView = /** @class */ (function (_super) {
    __extends(ControlView, _super);
    function ControlView(scene) {
        var _this = _super.call(this, scene, -5, gameConfig.canvasHeight * 0.52) || this;
        _this.scene = scene;
        _this.createBody();
        _this.scene.add.existing(_this);
        _this.setDepth(100);
        return _this;
    }
    ControlView.prototype.toggleMessageScroller = function (section) {
        !this.messageScroller.isShown ||
            this.messageScroller.sectionType !== section
            ? this.messageScroller.show(section)
            : this.messageScroller.hide();
    };
    ControlView.prototype.onWaitingViewActive = function () {
        this.leaveButton.makeDisabled();
        this.chatButton.makeDisabled();
        this.smileButton.makeDisabled();
        this.background.setVisible(false);
        this.messageScroller.setVisible(false);
    };
    ControlView.prototype.onWaitingViewInactive = function () {
        this.leaveButton.makeEnabled();
        this.chatButton.makeEnabled();
        this.smileButton.makeEnabled();
        this.background.setVisible(true);
        this.messageScroller.setVisible(true);
    };
    ControlView.prototype.showMessageScroller = function (section) {
        this.messageScroller.show(section);
    };
    ControlView.prototype.hideMessageScroller = function () {
        this.messageScroller.hide();
    };
    ControlView.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createLeaveButton();
        this.createSettingsButton();
        this.createChatButton();
        this.createSmileButton();
        this.createMessageScroller();
    };
    ControlView.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.ControlViewBackground,
        });
        this.background.setOrigin(0, 0.5);
        this.add(this.background);
    };
    ControlView.prototype.createLeaveButton = function () {
        this.leaveButton = new ControlLeaveButton(this.scene);
        this.add(this.leaveButton);
        this.leaveButton.setPosition(this.background.width * 0.444, -this.background.height * 0.328);
    };
    ControlView.prototype.createSettingsButton = function () {
        this.settingsButton = new ControlSettingsButton(this.scene);
        this.add(this.settingsButton);
        this.settingsButton.setPosition(this.background.width * 0.444, -this.background.height * 0.112);
    };
    ControlView.prototype.createChatButton = function () {
        this.chatButton = new ControlMessageButton(this.scene);
        this.add(this.chatButton);
        this.chatButton.setPosition(this.background.width * 0.444, this.background.height * 0.11);
    };
    ControlView.prototype.createSmileButton = function () {
        this.smileButton = new ControlSmileButton(this.scene);
        this.add(this.smileButton);
        this.smileButton.setPosition(this.background.width * 0.444, this.background.height * 0.333);
    };
    ControlView.prototype.createMessageScroller = function () {
        this.messageScroller = new EmojiContainer(this.scene);
        this.messageScroller.setPosition(this.messageScroller.x, 0);
        this.add(this.messageScroller);
        this.sendToBack(this.messageScroller);
    };
    ControlView.NAME = 'ControlView';
    ControlView.ACTION_DONE_EVENT = 'actionDone';
    ControlView.BACK_NOTIFICATION = ControlView.NAME + "ActionBackNotification";
    ControlView.SETTINGS_NOTIFICATION = ControlView.NAME + "ActionSettingsNotification";
    ControlView.EMOJI_SEND_NOTIFICATION = ControlView.NAME + "EmojiSendNotification";
    return ControlView;
}(Phaser.GameObjects.Container));
export default ControlView;
