var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fonts, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { Translation } from '../../translations';
import SimpleButton from '../utils/simpleButton/SimpleButton';
import StandardPopup from './StandardPopup';
export var ExitConfirmationPopupAction;
(function (ExitConfirmationPopupAction) {
    ExitConfirmationPopupAction[ExitConfirmationPopupAction["POSITIVE"] = 0] = "POSITIVE";
    ExitConfirmationPopupAction[ExitConfirmationPopupAction["NEGATIVE"] = 1] = "NEGATIVE";
})(ExitConfirmationPopupAction || (ExitConfirmationPopupAction = {}));
var BackToGamePopup = /** @class */ (function (_super) {
    __extends(BackToGamePopup, _super);
    function BackToGamePopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BackToGamePopup.prototype.onShowComplete = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        _super.prototype.onShowComplete.apply(this, args);
        this.startShineAnimation();
    };
    BackToGamePopup.prototype.createBody = function () {
        this.createColoredBlocker(0.7);
        this.createButtonShadow();
        this.createButton();
        this.createButtonShine();
        this.createButtonShineMask();
        this.setListeners();
    };
    BackToGamePopup.prototype.startShineAnimation = function () {
        this.shine.x = -this.backToGameButton.width / 2 - this.shine.width;
        this.maskGraphics.clear();
        this.maskGraphics.fillStyle(0xffffff, 1);
        this.maskGraphics.fillRectShape(new Phaser.Geom.Rectangle(this.x - this.backToGameButton.width * 0.445, this.y - this.backToGameButton.height / 2, this.backToGameButton.width * 0.9, this.backToGameButton.height));
        this.scene.tweens.add({
            targets: this.shine,
            x: this.backToGameButton.width / 2,
            duration: 1500,
            repeat: -1,
            repeatDelay: 2500,
            ease: Phaser.Math.Easing.Expo.InOut,
        });
    };
    BackToGamePopup.prototype.createButtonShadow = function () {
        this.shadow = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.BackToGameButtonShadow,
        });
        this.add(this.shadow);
    };
    BackToGamePopup.prototype.createButtonShine = function () {
        this.shine = this.scene.make.image({
            x: -this.backToGameButton.width / 2,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.BackToGameButtonShine,
        });
        this.add(this.shine);
        this.shine.setOrigin(0, 0.5);
    };
    BackToGamePopup.prototype.createButtonShineMask = function () {
        this.maskGraphics = this.scene.make.graphics({});
        this.shine.setMask(new Phaser.Display.Masks.GeometryMask(this.scene, this.maskGraphics));
    };
    BackToGamePopup.prototype.createButton = function () {
        var textConfig = {
            text: Translation.BACK_TO_GAME,
            fontSize: 70 * gameConfig.resolutionMultiplier,
            fill: '#e6c331',
            fontFamily: Fonts.AvenirNextBold.Name,
            origin: { x: 0.5, y: 0.5 },
            shadow: {
                x: 2,
                y: 2,
                blur: 15 * gameConfig.resolutionMultiplier,
                color: '#000000',
                shadowStroke: false,
                shadowFill: true,
            },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.BackToGameButton,
        };
        this.backToGameButton = new SimpleButton(this.scene, {
            normalStateConfig: normalStateConfig,
            textConfig: textConfig,
        });
        this.add(this.backToGameButton);
    };
    BackToGamePopup.prototype.setListeners = function () {
        this.backToGameButton.once(SimpleButton.CLICK_EVENT, this.onAction, this);
    };
    BackToGamePopup.NAME = "BackToGamePopup";
    BackToGamePopup.RESUME_CLICKED_NOTIFICATION = BackToGamePopup.NAME + "ResumeClickedNotification";
    return BackToGamePopup;
}(StandardPopup));
export default BackToGamePopup;
