var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { ValidationActionType } from '../../../model/VO/SocketVO';
import { Translation } from '../../../translations';
import BaseTurnButton, { BaseTurnButtonInputState, } from '../../utils/turnButton/BaseTurnButton';
var GameActionButton = /** @class */ (function (_super) {
    __extends(GameActionButton, _super);
    function GameActionButton(scene) {
        var _this = this;
        var normalTextConfig = {
            font: BitmapFonts.RobotoSlab.Name,
            text: Translation.GAMEPLAY_ACTION_BUTTON_PASS,
            size: 48 * gameConfig.resolutionMultiplier,
            fill: 0xffffff,
        };
        var downTextConfig = {
            font: BitmapFonts.RobotoSlab.Name,
            text: Translation.GAMEPLAY_ACTION_BUTTON_PASS,
            size: 48 * gameConfig.resolutionMultiplier,
            fill: 0x462b18,
        };
        var normalState = {
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.ActionButtonUp,
            textConfig: normalTextConfig,
        };
        var downState = {
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.ActionButtonDown,
            textConfig: downTextConfig,
        };
        var configs = {
            normalState: normalState,
            downState: downState,
            disabledState: downState,
        };
        _this = _super.call(this, scene, configs) || this;
        var backgroundLinesFrame = _this.scene.textures.getFrame(MultiAtlases.GamePlay.Atlas.Name, MultiAtlases.GamePlay.Atlas.Frames.ActionButtonBackground);
        _this.x = gameConfig.canvasWidth / 2;
        _this.y = gameConfig.canvasHeight - backgroundLinesFrame.height * 0.84 * 1.2;
        _this.setScale(1.2);
        _this.setAction(ValidationActionType.DISABLED);
        return _this;
    }
    GameActionButton.prototype.makeDisabled = function () {
        _super.prototype.makeDisabled.call(this);
        this.setAlpha(0.7);
    };
    GameActionButton.prototype.makeEnabled = function () {
        _super.prototype.makeEnabled.call(this, BaseTurnButtonInputState.UP);
        this.setAlpha(1);
    };
    GameActionButton.prototype.setAction = function (action) {
        this.action = action;
        this.action !== ValidationActionType.DISABLED &&
            this.setText("gameplay-action-button-" + ValidationActionType[action].toLowerCase());
        this.disabledState.setVisible(action === ValidationActionType.DISABLED);
        this.normalState.setVisible(!this.disabledState.visible);
        this.setEnabled(action !== ValidationActionType.DISABLED, action === ValidationActionType.DISABLED
            ? BaseTurnButtonInputState.DISABLED
            : BaseTurnButtonInputState.UP);
    };
    GameActionButton.prototype.setText = function (text) {
        this.normalState.setText(text);
        this.downState.setText(text);
        this.disabledState.setText(text);
    };
    GameActionButton.prototype.onPointerUp = function () {
        this.playSfxOnUp();
        this.onClick();
        this.makeDisabled();
    };
    return GameActionButton;
}(BaseTurnButton));
export default GameActionButton;
