var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Audios, MultiAtlases } from '../../../assets';
import StoreScene from '../../scenes/StoreScene';
var StoreTabButton = /** @class */ (function (_super) {
    __extends(StoreTabButton, _super);
    function StoreTabButton(scene, config, x, y) {
        var _this = _super.call(this, scene, x, y) || this;
        _this.scene = scene;
        _this.config = config;
        _this.activated = false;
        _this.scene.add.existing(_this);
        _this.createBody();
        _this.setListeners();
        return _this;
    }
    StoreTabButton.prototype.setContent = function (content) {
        this.content = content;
        content.hide(true);
    };
    StoreTabButton.prototype.makeActive = function () {
        return __awaiter(this, void 0, void 0, function () {
            var frame;
            return __generator(this, function (_a) {
                this.activated = true;
                this.background.setTexture(MultiAtlases.Store.Atlas.Name, MultiAtlases.Store.Atlas.Frames.TabActive);
                frame = this.scene.textures.getFrame(MultiAtlases.Store.Atlas.Name, this.config.iconActiveFrame);
                if (this.icon && frame.name === this.config.iconActiveFrame) {
                    this.icon.setTexture(MultiAtlases.Store.Atlas.Name, this.config.iconActiveFrame);
                }
                return [2 /*return*/, this.content.show()];
            });
        });
    };
    StoreTabButton.prototype.makeInActive = function (force) {
        if (force === void 0) { force = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.activated = false;
                this.background.setTexture(MultiAtlases.Store.Atlas.Name, MultiAtlases.Store.Atlas.Frames.TabInactive);
                if (this.icon) {
                    this.icon.setTexture(MultiAtlases.Store.Atlas.Name, this.config.iconPassiveFrame);
                }
                this.removeAllListeners();
                this.once(Phaser.Input.Events.POINTER_UP, this.makeActive, this);
                if (this.content) {
                    this.content.hide(force);
                }
                return [2 /*return*/];
            });
        });
    };
    StoreTabButton.prototype.createBody = function () {
        this.createBackground();
        this.createIcon();
    };
    StoreTabButton.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames.TabInactive,
        });
        this.add(this.background);
        this.background.setOrigin(1, 0.5);
    };
    StoreTabButton.prototype.createIcon = function () {
        var backgroundCenter = this.background.getCenter();
        this.icon = this.scene.make.image({
            x: backgroundCenter.x,
            y: backgroundCenter.y,
            key: MultiAtlases.Store.Atlas.Name,
            frame: this.config.iconPassiveFrame,
        });
        this.add(this.icon);
    };
    StoreTabButton.prototype.setListeners = function () {
        this.background.setInteractive();
        this.background.on(Phaser.Input.Events.POINTER_UP, this.onClick, this);
    };
    StoreTabButton.prototype.onClick = function () {
        this.scene.playSFX(Audios.ButtonTapSfx.Name);
        this.scene.events.emit(StoreScene.STORE_TAB_CLICK_EVENT, this.config.section);
    };
    return StoreTabButton;
}(Phaser.GameObjects.Container));
export default StoreTabButton;
