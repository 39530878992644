var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var PlayerGameLevel = /** @class */ (function (_super) {
    __extends(PlayerGameLevel, _super);
    function PlayerGameLevel(scene, level) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.level = level;
        _this.createBody();
        _this.setScale(0);
        return _this;
    }
    PlayerGameLevel.prototype.updateData = function (level) {
        var style = {
            fontFamily: Fonts.AvenirNextBold.Name,
            fontSize: 44 * gameConfig.resolutionMultiplier,
            fill: '#a87716',
        };
        this.level = level;
        this.text.setText("" + this.level);
        this.text.setStyle(style);
        this.text.setShadow(1, 3, '#3b200d', 5, false, true);
        this.text.setOrigin(0.5);
        this.text.setScale(Math.min(1, (this.background.width * 0.75) / this.text.width));
    };
    PlayerGameLevel.prototype.show = function () {
        this.scene.tweens.killTweensOf(this);
        this.scene.tweens.add({
            targets: this,
            scaleX: 1,
            scaleY: 1,
            duration: 200,
            ease: Phaser.Math.Easing.Back.Out,
        });
    };
    PlayerGameLevel.prototype.hide = function () {
        this.scene.tweens.killTweensOf(this);
        this.scene.tweens.add({
            targets: this,
            scaleX: 0,
            scaleY: 0,
            duration: 200,
            ease: Phaser.Math.Easing.Back.In,
        });
    };
    PlayerGameLevel.prototype.createBody = function () {
        this.createBackground();
        this.createText();
    };
    PlayerGameLevel.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.LevelBackground,
        });
        this.add(this.background);
    };
    PlayerGameLevel.prototype.createText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextBold.Name,
            fontSize: 46 * gameConfig.resolutionMultiplier,
            fill: '#a87716',
        };
        this.text = this.scene.make.text({
            x: 0,
            y: 0,
            text: "" + this.level,
            style: style,
        });
        this.text.setShadow(1, 3, '#3b200d', 5, false, true);
        this.text.setOrigin(0.5);
        this.add(this.text);
        this.text.setScale(Math.min(1, (this.background.width * 0.75) / this.text.width));
    };
    return PlayerGameLevel;
}(Phaser.GameObjects.Container));
export default PlayerGameLevel;
