var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Images, MultiAtlases } from '../../../assets';
import { Gender } from '../../../model/VO/PlayerVO';
var RegistrationProfileAvatar = /** @class */ (function (_super) {
    __extends(RegistrationProfileAvatar, _super);
    function RegistrationProfileAvatar(scene, keys) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.keys = keys;
        _this.createBody();
        _this.setListeners();
        _this.setScale(0);
        return _this;
    }
    RegistrationProfileAvatar.prototype.updateAvatar = function (gender, index) {
        this.changeAvatar(gender, index || 0);
    };
    RegistrationProfileAvatar.prototype.hide = function () {
        this.scene.tweens.killTweensOf(this);
        this.scene.tweens.add({
            targets: this,
            scaleX: 0,
            scaleY: 0,
            ease: Phaser.Math.Easing.Back.Out,
            duration: 300,
        });
    };
    RegistrationProfileAvatar.prototype.changeAvatar = function (gender, index) {
        var _this = this;
        this.gender = gender;
        var genderKeys = this.gender === Gender.MALE
            ? this.keys.male
            : this.gender === Gender.FEMALE
                ? this.keys.female
                : this.keys.uni;
        var key = genderKeys[index] || genderKeys[0];
        if (this.avatar.texture.key === key ||
            this.scene.tweens.isTweening(this.avatar)) {
            return;
        }
        var defaultFrame = this.scene.textures.getFrame(Images.Avatar.Name);
        var avatarFrame = this.scene.textures.getFrame(key);
        var scale = (defaultFrame.width * 0.8) / avatarFrame.width;
        this.scene.tweens.killTweensOf(this.avatar);
        this.scene.tweens.add({
            targets: this.background,
            scaleX: 0,
            yoyo: true,
            duration: 100,
        });
        this.scene.tweens.add({
            targets: [this.avatar, this.avatarShadow],
            scaleX: 0,
            duration: 100,
            onComplete: function () {
                _this.avatar.setTexture(key);
                _this.avatar.setScale(0, scale);
                _this.scene.tweens.add({
                    targets: _this.avatar,
                    scaleX: scale,
                    duration: 100,
                });
                _this.scene.tweens.add({
                    targets: _this.avatarShadow,
                    scaleY: (_this.height * 0.6) / _this.avatarShadow.height,
                    scaleX: (_this.height * 0.6) / _this.avatarShadow.height,
                    duration: 100,
                });
            },
        });
    };
    RegistrationProfileAvatar.prototype.createBody = function () {
        this.createBackgroundCircle();
        this.setSize(this.backgroundCircle.width, this.backgroundCircle.height);
        this.createAvatarShadow();
        this.createAvatarBackground();
        this.createAvatar();
        this.createMask();
    };
    RegistrationProfileAvatar.prototype.createMask = function () {
        this.maskGraphics = this.scene.make.graphics({});
        this.masker = new Phaser.Display.Masks.GeometryMask(this.scene, this.maskGraphics);
    };
    RegistrationProfileAvatar.prototype.createBackgroundCircle = function () {
        this.backgroundCircle = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.AvatarCircle,
        });
        this.backgroundCircle.setDepth(1);
        this.backgroundCircle.setOrigin(0.5);
        this.add(this.backgroundCircle);
    };
    RegistrationProfileAvatar.prototype.createAvatarShadow = function () {
        this.avatarShadow = this.scene.make.image({
            x: this.backgroundCircle.x,
            y: this.backgroundCircle.y,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.AvatarBackgroundShadow,
        });
        this.avatarShadow.y += this.avatarShadow.height * 0.1;
        this.avatarShadow.setOrigin(0.5);
        this.add(this.avatarShadow);
    };
    RegistrationProfileAvatar.prototype.createAvatarBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.AvatarPicBg,
        });
        this.add(this.background);
        this.background.setScale((this.width * 0.65) / this.background.width);
    };
    RegistrationProfileAvatar.prototype.createAvatar = function () {
        this.avatar = this.scene.make.image({
            x: this.backgroundCircle.x,
            y: this.backgroundCircle.y,
            key: Images.Avatar.Name,
        });
        this.avatar.setOrigin(0.5);
        this.add(this.avatar);
        this.avatar.setScale((this.width * 0.6) / this.avatar.width);
    };
    RegistrationProfileAvatar.prototype.setListeners = function () {
        this.setInteractive();
        this.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown, this);
    };
    RegistrationProfileAvatar.prototype.onPointerDown = function () {
        this.once(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.once(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    RegistrationProfileAvatar.prototype.onPointerUp = function () {
        this.emit(RegistrationProfileAvatar.CLICK_EVENT);
    };
    RegistrationProfileAvatar.prototype.onPointerOut = function () {
        this.off(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
        this.off(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
    };
    RegistrationProfileAvatar.CLICK_EVENT = 'click';
    return RegistrationProfileAvatar;
}(Phaser.GameObjects
    .Container));
export default RegistrationProfileAvatar;
