var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import AdsVO from "./VO/AdsVO";
import AdWrapper from "../utils/wrappers/AdMobWrapper";
import { Proxy } from '@candywings/pure-mvc';
var AdsVOProxy = /** @class */ (function (_super) {
    __extends(AdsVOProxy, _super);
    function AdsVOProxy() {
        return _super.call(this, AdsVOProxy.NAME, new AdsVO()) || this;
    }
    AdsVOProxy.prototype.onRegister = function () {
        _super.prototype.onRegister.call(this);
        this.sendNotification(AdsVOProxy.REGISTERED_NOTIFICATION);
    };
    AdsVOProxy.prototype.loadInterstitial = function () {
        console.warn("interstital ads load isn't implemented");
    };
    AdsVOProxy.prototype.showInterstitial = function () {
        console.warn("interstital ads show isn't implemented");
    };
    AdsVOProxy.prototype.loadRewardedVideo = function () {
        var _this = this;
        console.warn('trying to load video');
        if (!this.vo.video.isAvailable || this.vo.video.isReady) {
            return;
        }
        console.warn('no ready video available');
        AdWrapper.loadRewardedVideo().then(function () {
            _this.vo.video.isReady = true;
            _this.sendNotification(AdsVOProxy.REWARDED_VIDEO_LOAD_SUCCESS_NOTIFICATION);
        }).catch(function (reason) {
            _this.sendNotification(AdsVOProxy.REWARDED_VIDEO_LOAD_FAILED_NOTIFICATION, reason);
        }).finally(function () {
            _this.sendNotification(AdsVOProxy.REWARDED_VIDEO_LOAD_COMPLETE_NOTIFICATION);
        });
    };
    AdsVOProxy.prototype.showRewardedVideo = function () {
        if (!this.vo.video.isAvailable || !this.vo.video.isReady) {
            this.sendNotification(AdsVOProxy.REWARDED_VIDEO_IS_NOT_READY_NOTIFICATION);
            this.loadRewardedVideo();
            return;
        }
        AdWrapper.showRewardVideoAd();
    };
    AdsVOProxy.prototype.sendRewardOpenNotififcation = function () {
        this.vo.video.isReady = false;
        this.sendNotification(AdsVOProxy.REWARDED_VIDEO_OPEN_NOTIFICATION);
    };
    AdsVOProxy.prototype.sendRewardClosedNotififcation = function () {
        this.sendNotification(AdsVOProxy.REWARDED_VIDEO_CLOSE_NOTIFICATION);
    };
    AdsVOProxy.prototype.sendRewardNotififcation = function () {
        this.sendNotification(AdsVOProxy.REWARDED_VIDEO_SHOW_COMPLETE_NOTIFICATION);
    };
    AdsVOProxy.NAME = 'AdsVOProxy';
    AdsVOProxy.REGISTERED_NOTIFICATION = AdsVOProxy.NAME + "RegisteredNotification";
    AdsVOProxy.INTERSTITIAL_LOAD_SUCCESS_NOTIFICATION = AdsVOProxy.NAME + "InterstitalLoadSuccessNotification";
    AdsVOProxy.INTERSTITIAL_LOAD_FAILED_NOTIFICATION = AdsVOProxy.NAME + "InterstitalLoadFailedNotification";
    AdsVOProxy.INTERSTITIAL_LOAD_COMPLETE_NOTIFICATION = AdsVOProxy.NAME + "InterstitalLoadCompleteNotification";
    AdsVOProxy.INTERSTITIAL_IS_NOT_SUPPORTED_NOTIFICATION = AdsVOProxy.NAME + "InterstitalIsNotSupportedNotification";
    AdsVOProxy.INTERSTITIAL_IS_NOT_READY_NOTIFICATION = AdsVOProxy.NAME + "InterstitalIsNotReadyNotification";
    AdsVOProxy.INTERSTITIAL_IS_ALREADY_READY_NOTIFICATION = AdsVOProxy.NAME + "InterstitalIsAlreadyReadyNotification";
    AdsVOProxy.INTERSTITIAL_OPEN_NOTIFICATION = AdsVOProxy.NAME + "InterstitalOpenNotification";
    AdsVOProxy.INTERSTITIAL_CLOSE_NOTIFICATION = AdsVOProxy.NAME + "InterstitalCloseNotification";
    AdsVOProxy.INTERSTITIAL_SHOW_FAILED_NOTIFICATION = AdsVOProxy.NAME + "InterstitalShowFailedNotification";
    AdsVOProxy.INTERSTITIAL_SHOW_COMPLETE_NOTIFICATION = AdsVOProxy.NAME + "InterstitalShowCompleteNotification";
    AdsVOProxy.REWARDED_VIDEO_LOAD_SUCCESS_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoLoadSuccessNotification";
    AdsVOProxy.REWARDED_VIDEO_LOAD_FAILED_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoLoadFailedNotification";
    AdsVOProxy.REWARDED_VIDEO_LOAD_COMPLETE_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoLoadCompleteNotification";
    AdsVOProxy.REWARDED_VIDEO_IS_NOT_SUPPORTED_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoIsNotSupportedNotification";
    AdsVOProxy.REWARDED_VIDEO_IS_NOT_READY_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoIsNotReadyNotification";
    AdsVOProxy.REWARDED_VIDEO_IS_ALREADY_READY_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoIsAlreadyReadyNotification";
    AdsVOProxy.REWARDED_VIDEO_OPEN_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoOpenNotification";
    AdsVOProxy.REWARDED_VIDEO_CLOSE_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoCloseNotification";
    AdsVOProxy.REWARDED_VIDEO_SHOW_FAILED_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoShowFailedNotification";
    AdsVOProxy.REWARDED_VIDEO_SHOW_COMPLETE_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoShowCompleteNotification";
    AdsVOProxy.LOAD_INTERSTITIAL_SUCCESS_NOTIFICATION = AdsVOProxy.NAME + "LoadInterstitialSuccessNotification";
    AdsVOProxy.LOAD_INTERSTITIAL_FAIL_NOTIFICATION = AdsVOProxy.NAME + "LoadInterstitialFailNotification";
    AdsVOProxy.LOAD_INTERSTITIAL_COMPLETE_NOTIFICATION = AdsVOProxy.NAME + "LoadInterstitialCompleteNotification";
    AdsVOProxy.SHOW_INTERSTITIAL_SUCCESS_NOTIFICATION = AdsVOProxy.NAME + "ShowInterstitialSuccessNotification";
    AdsVOProxy.SHOW_INTERSTITIAL_FAIL_NOTIFICATION = AdsVOProxy.NAME + "ShowInterstitialFailNotification";
    AdsVOProxy.SHOW_INTERSTITIAL_COMPLETE_NOTIFICATION = AdsVOProxy.NAME + "ShowInterstitialCompleteNotification";
    AdsVOProxy.LOAD_REWARDED_VIDEO_SUCCESS_NOTIFICATION = AdsVOProxy.NAME + "LoadRewardedVideoSuccessNotification";
    AdsVOProxy.LOAD_REWARDED_VIDEO_FAIL_NOTIFICATION = AdsVOProxy.NAME + "LoadRewardedVideoFailNotification";
    AdsVOProxy.LOAD_REWARDED_VIDEO_COMPLETE_NOTIFICATION = AdsVOProxy.NAME + "LoadRewardedVideoCompleteNotification";
    AdsVOProxy.SHOW_REWARDED_VIDEO_NOTIFICATION = AdsVOProxy.NAME + "ShowRewardedVideoNotification";
    AdsVOProxy.SHOW_REWARDED_VIDEO_SUCCESS_NOTIFICATION = AdsVOProxy.NAME + "ShowRewardedVideoSuccessNotification";
    AdsVOProxy.SHOW_REWARDED_VIDEO_FAIL_NOTIFICATION = AdsVOProxy.NAME + "ShowRewardedVideoFailNotification";
    AdsVOProxy.REWARDED_VIDEO_CLOSED_NOTIFICATION = AdsVOProxy.NAME + "RewardedVideoClosedNotification";
    AdsVOProxy.SHOW_REWARDED_VIDEO_COMPLETE_NOTIFICATION = AdsVOProxy.NAME + "ShowRewardedVideoCompleteNotification";
    return AdsVOProxy;
}(Proxy));
export default AdsVOProxy;
