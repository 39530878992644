var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import PlayerCommand from './PlayerCommand';
var UpdateAvatarCommand = /** @class */ (function (_super) {
    __extends(UpdateAvatarCommand, _super);
    function UpdateAvatarCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UpdateAvatarCommand.prototype.execute = function (notificationName, key) {
        var imagesPath = 'assets/images';
        var url = key == "playerDefaultAvatar"
            ? this.proxy.vo.socialAvatarUrl
            : "https://test.kingsofdurak.com/" + imagesPath + "/" + key + ".png";
        this.proxy.updateAvatar(url);
    };
    return UpdateAvatarCommand;
}(PlayerCommand));
export default UpdateAvatarCommand;
