var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Atlases } from '../../../assets';
import RoomScene from '../../scenes/RoomScene';
import DoorPrice from './DoorPrice';
import DoorTitle from './DoorTitle';
var Door = /** @class */ (function (_super) {
    __extends(Door, _super);
    function Door(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createBody();
        _this.prepare();
        _this.updateState();
        return _this;
    }
    Door.prototype.updateState = function (level) {
        if (!!level) {
            this.config.isAvailable = this.config.minLevel <= level;
        }
        this.price.setVisible(this.config.isAvailable);
        this.selectionLight.setVisible(this.config.isAvailable);
        this.icon.setVisible(this.config.isAvailable);
        this.lockedIcon.setVisible(!this.config.isAvailable);
        this.lock.setVisible(!this.config.isAvailable);
        this.doorAndLockConnector.setVisible(!this.config.isAvailable);
    };
    Door.prototype.show = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.scene.tweens.killTweensOf([
                            _this.background,
                            _this.icon,
                            _this.lockedIcon,
                            _this.title,
                            _this.price,
                        ]);
                        _this.scene.tweens.add({
                            targets: _this.title,
                            alpha: 1,
                            duration: 200,
                        });
                        _this.scene.tweens.add({
                            targets: _this.price,
                            alpha: 1,
                            duration: 200,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    Door.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.makeInactive(100);
                        _this.scene.tweens.killTweensOf([
                            _this.background,
                            _this.icon,
                            _this.lockedIcon,
                            _this.title,
                            _this.price,
                        ]);
                        _this.scene.tweens.add({
                            targets: _this.title,
                            alpha: 0,
                            duration: 200,
                        });
                        _this.scene.tweens.add({
                            targets: _this.price,
                            alpha: 0,
                            duration: 200,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    Door.prototype.makeActive = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        if (!_this.config.isAvailable) {
                            return resolve();
                        }
                        _this.config.isActive = true;
                        _this.scene.tweens.killTweensOf(_this.selectionLight);
                        _this.scene.tweens.add({
                            targets: _this.selectionLight,
                            alpha: 1,
                            duration: 300,
                            onComplete: function () {
                                _this.scene.tweens.add({
                                    targets: _this.selectionLight,
                                    alpha: 0.6,
                                    yoyo: true,
                                    repeat: -1,
                                    repeatDelay: 300,
                                    duration: 1500,
                                });
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    Door.prototype.makeInactive = function (duration) {
        if (duration === void 0) { duration = 1000; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        if (!_this.config.isAvailable) {
                            return resolve();
                        }
                        _this.config.isActive = false;
                        _this.scene.tweens.killTweensOf(_this.selectionLight);
                        _this.scene.tweens.add({
                            targets: _this.selectionLight,
                            alpha: 0,
                            duration: duration,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    Door.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createPrice();
        this.createSelectionLight();
        this.createRoomIcon();
        this.createLockIcon();
        this.createLock();
        this.createLockDecoration();
        this.setListeners();
        this.createTitle();
    };
    Door.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames.IconBackground,
        });
        this.add(this.background);
    };
    Door.prototype.createRoomIcon = function () {
        var framePostFix = 'Enabled';
        this.icon = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames["Icon" + Phaser.Utils.String.UppercaseFirst(this.config.name) + framePostFix],
        });
        this.add(this.icon);
    };
    Door.prototype.createLockIcon = function () {
        this.lockedIcon = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames.IconLocked,
        });
        this.add(this.lockedIcon);
    };
    Door.prototype.createTitle = function () {
        this.title = new DoorTitle(this.scene, {
            isAvailable: this.config.isAvailable,
            text: this.config.name,
        });
        this.add(this.title);
        this.title.y = -this.height * 0.65;
    };
    Door.prototype.createPrice = function () {
        this.price = new DoorPrice(this.scene, {
            value: this.config.amount,
            currency: this.config.currency,
        });
        this.add(this.price);
        this.price.y = this.height * 0.6;
    };
    Door.prototype.createLock = function () {
        this.lock = this.scene.make.image({
            x: 0,
            y: -this.height * 0.06,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames.Lock,
        });
        this.lock.setOrigin(0.5, 0);
        this.add(this.lock);
    };
    Door.prototype.createLockDecoration = function () {
        this.doorAndLockConnector = this.scene.make.image({
            x: 0,
            y: 0,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames.DoorAndLockConnector,
        });
        this.add(this.doorAndLockConnector);
    };
    Door.prototype.createSelectionLight = function () {
        this.selectionLight = this.scene.make.image({
            x: this.width * 0.025,
            y: -this.height * 0.1,
            key: Atlases.Modes.Atlas.Name,
            frame: Atlases.Modes.Atlas.Frames.SelectLight,
        });
        this.add(this.selectionLight);
    };
    Door.prototype.setListeners = function () {
        this.setInteractive();
        this.on(Phaser.Input.Events.POINTER_OVER, this.onPointerOver, this);
        this.on(Phaser.Input.Events.POINTER_OUT, this.onPointerOut, this);
        this.on(Phaser.Input.Events.POINTER_UP_OUTSIDE, this.onPointerOut, this);
        this.config.isAvailable &&
            this.on(Phaser.Input.Events.POINTER_UP, this.onPointerUp, this);
    };
    Door.prototype.onPointerOver = function () {
        this.config.isAvailable &&
            !this.scene.tweens.isTweening(this.title) &&
            this.makeActive();
    };
    Door.prototype.onPointerOut = function () {
        this.config.isAvailable &&
            !this.scene.tweens.isTweening(this.title) &&
            this.makeInactive();
    };
    Door.prototype.onPointerUp = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.scene.events.emit(RoomScene.DOOR_SELECTED_EVENT, this.config.name);
                return [2 /*return*/];
            });
        });
    };
    Door.prototype.prepare = function () {
        this.title.setAlpha(0);
        !!this.price && this.price.setAlpha(0);
        this.selectionLight && this.selectionLight.setAlpha(0);
    };
    return Door;
}(Phaser.GameObjects.Container));
export default Door;
