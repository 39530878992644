var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
var BaseUserInfoStatisticElement = /** @class */ (function (_super) {
    __extends(BaseUserInfoStatisticElement, _super);
    function BaseUserInfoStatisticElement(scene, config) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.createContent();
        return _this;
    }
    BaseUserInfoStatisticElement.prototype.createContent = function () {
        this.createName();
        this.createValue();
    };
    BaseUserInfoStatisticElement.prototype.createName = function () {
        this.createNameBackground();
        this.positionNameBackground();
        this.createNameText();
    };
    BaseUserInfoStatisticElement.prototype.createNameBackground = function () {
        this.nameBackground = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupStatisticNameBackground,
        });
        this.add(this.nameBackground);
    };
    BaseUserInfoStatisticElement.prototype.createNameText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextBold.Name,
            fontSize: 39 * gameConfig.resolutionMultiplier,
            fill: '#fff5d2',
        };
        this.nameText = this.scene.make.extText({
            x: this.nameBackground.x,
            y: this.nameBackground.y,
            text: this.config.name,
            style: style,
        });
        this.add(this.nameText);
        this.nameText.setScale(Math.min(1, (this.nameBackground.width / this.nameText.width) * 0.8));
        this.nameText.setOrigin(0.5);
    };
    BaseUserInfoStatisticElement.prototype.createValue = function () {
        this.createValueBackground();
        this.positionValueBackground();
        this.createValueText();
    };
    BaseUserInfoStatisticElement.prototype.createValueBackground = function () {
        this.valueBackground = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupStatisticValueBackground,
        });
        this.add(this.valueBackground);
        this.valueBackground.setOrigin(0.61, 0.42);
    };
    BaseUserInfoStatisticElement.prototype.createValueText = function () {
        var style = {
            fontFamily: Fonts.AvenirNextBold.Name,
            fontSize: 38 * gameConfig.resolutionMultiplier,
            fill: this.config.color,
        };
        this.valueText = this.scene.make.extText({
            x: this.valueBackground.x +
                this.valueBackground.width * (0.5 - this.valueBackground.originX),
            y: this.valueBackground.y,
            text: "" + (this.config.value || 0),
            style: style,
        });
        this.add(this.valueText);
        this.valueText.setScale(Math.min(1, (this.valueBackground.width * 0.6) / this.valueText.width));
        this.valueText.setOrigin(0.5);
    };
    return BaseUserInfoStatisticElement;
}(Phaser
    .GameObjects.Container));
export default BaseUserInfoStatisticElement;
