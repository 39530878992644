var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import CardVO from '../../../model/VO/CardVO';
import { CardPlayType } from '../../../model/VO/SocketVO';
import SocketCommand from '../SocketCommand';
var SwapCardPlayedCommand = /** @class */ (function (_super) {
    __extends(SwapCardPlayedCommand, _super);
    function SwapCardPlayedCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SwapCardPlayedCommand.prototype.execute = function (notificationName, card) {
        this.proxy.publishCardPlay(new CardVO(card.suit, card.value), CardPlayType.SWAP);
    };
    return SwapCardPlayedCommand;
}(SocketCommand));
export default SwapCardPlayedCommand;
