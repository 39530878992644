var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { wrapRoomToStatePlayers } from '../../model/helpers/utils';
import SocketVOProxy from '../../model/SocketVOProxy';
import { ValidationActionType, } from '../../model/VO/SocketVO';
import StateVO from '../../model/VO/StateVO';
import { decrypt } from '../../utils/Crypto';
import { StateMacroCommand } from './StateMacroCommand';
var SetStateDataCommand = /** @class */ (function (_super) {
    __extends(SetStateDataCommand, _super);
    function SetStateDataCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SetStateDataCommand.prototype.execute = function (notificationName, data) {
        var _a, _b;
        _super.prototype.execute.call(this, notificationName);
        var socketVOProxy = this.facade.retrieveProxy(SocketVOProxy.NAME);
        var reconnected = this.proxy.vo.reconnected;
        data.attackers = data.attackers.map(function (id) {
            return socketVOProxy.getPlayerIndexById(id);
        });
        data.defender = socketVOProxy.getPlayerIndexById(data.defender);
        data.nextUser = socketVOProxy.getPlayerIndexById(data.nextUser);
        var finalData = new StateVO();
        // ---- ACTION INDEX ----
        socketVOProxy.vo.actionIndex = data.actionIndex;
        // ---- PLAYERS ----
        finalData.players = wrapRoomToStatePlayers(data.usersInfo, data.userCards, socketVOProxy.vo.userId);
        for (var _i = 0, _c = finalData.players; _i < _c.length; _i++) {
            var player = _c[_i];
            if (player.userIndex == data.defender) {
                player.isDefender = true;
            }
            if (data.attackers.includes(player.userIndex)) {
                player.isAttacker = true;
            }
        }
        // ---- TURN DATA ----
        finalData.turnData.currentPlayer.timeout = data.timeout;
        finalData.turnData.currentPlayer.attackers = data.attackers;
        finalData.turnData.currentPlayer.defender = data.defender;
        finalData.turnData.currentPlayer.userId = data.nextUser;
        // validatedCards
        (_a = finalData.turnData.validatedCards).push.apply(_a, data.validCards.map(function (value) {
            return {
                card: decrypt(value.card),
                targets: value.targets
                    ? value.targets.map(function (value) { return decrypt(value); })
                    : null,
                canSwap: value.canSwap,
            };
        }));
        finalData.turnData.action =
            data.validAction || ValidationActionType.DISABLED;
        // cardPairs
        (_b = finalData.turnData.cardPairs).push.apply(_b, data.attackCards.map(function (pair) {
            return {
                attack: decrypt(pair.card),
                defence: decrypt(pair.defendedBy),
            };
        }));
        // --- DECK DATA ---
        finalData.deckData.trump = decrypt(data.trump);
        finalData.deckData.cardsToDealToPlayers = [[], [], [], []];
        finalData.deckData.isTrumpShown = true;
        finalData.deckData.cardsCount = data.deck;
        var allInGameCardsCount = finalData.deckData.cardsCount;
        for (var _d = 0, _e = finalData.players; _d < _e.length; _d++) {
            var player = _e[_d];
            allInGameCardsCount += player.cards.length;
        }
        for (var _f = 0, _g = finalData.turnData.cardPairs; _f < _g.length; _f++) {
            var pair = _g[_f];
            allInGameCardsCount++;
            !!pair.defence && allInGameCardsCount++;
        }
        finalData.bitaCardsCount = 36 - allInGameCardsCount;
        // --- OTHERS ---
        finalData.positions = [];
        finalData.reconnected = reconnected;
        finalData.isGameStarted = true;
        this.proxy.initForceData(finalData);
    };
    return SetStateDataCommand;
}(StateMacroCommand));
export default SetStateDataCommand;
