var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BitmapFonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
var RewardField = /** @class */ (function (_super) {
    __extends(RewardField, _super);
    function RewardField(scene, config, index) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.config = config;
        _this.index = index;
        _this.createBody();
        _this.prepare();
        return _this;
    }
    RewardField.prototype.preDestroy = function () {
        this.scene.tweens.killTweensOf(this.list);
    };
    RewardField.prototype.removeBackground = function () {
        this.background.setVisible(false);
    };
    RewardField.prototype.show = function (delay) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var frameTexture = _this.getFrame();
                        _this.scene.tweens.add({
                            targets: _this.currencyIcon,
                            scaleX: 1,
                            scaleY: 1,
                            ease: Phaser.Math.Easing.Back.Out,
                            delay: delay,
                            duration: 200,
                            onComplete: function () {
                                _this.scene.tweens.add({
                                    targets: _this.background,
                                    duration: 500,
                                    width: frameTexture.width - 0.1,
                                    height: frameTexture.height,
                                    ease: Phaser.Math.Easing.Expo.InOut,
                                    onComplete: function () {
                                        _this.scene.tweens.add({
                                            targets: _this.countText,
                                            alpha: 1,
                                            scaleX: 1,
                                            scaleY: 1,
                                            duration: 200,
                                            onComplete: function () {
                                                resolve();
                                            },
                                        });
                                    },
                                });
                            },
                        });
                    })];
            });
        });
    };
    RewardField.prototype.hide = function (countDownDuration) {
        if (countDownDuration === void 0) { countDownDuration = 1000; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.scene.tweens.add({
                            targets: _this.config,
                            amount: 0,
                            duration: countDownDuration,
                            onUpdate: _this.updateCountText,
                            onUpdateScope: _this,
                            onComplete: _this.startHideAnimation,
                            onCompleteScope: _this,
                            onCompleteParams: [resolve],
                        });
                    })];
            });
        });
    };
    RewardField.prototype.startHideAnimation = function (tween, data, resolveCallback) {
        var _this = this;
        this.scene.tweens.add({
            targets: this.countText,
            alpha: 0,
            scaleX: 2,
            scaleY: 2,
            duration: 300,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: _this.background,
                    width: 0,
                    ease: Phaser.Math.Easing.Expo.InOut,
                    duration: 200,
                    onComplete: function () {
                        _this.scene.tweens.add({
                            targets: _this.currencyIcon,
                            scaleX: 0,
                            scaleY: 0,
                            ease: Phaser.Math.Easing.Back.In,
                            duration: 200,
                            onComplete: function () {
                                resolveCallback();
                            },
                        });
                    },
                });
            },
        });
    };
    RewardField.prototype.updateCountText = function () {
        this.countText.setText("" + Math.ceil(this.config.amount));
        this.countText.x = this.currencyIcon.width + this.countText.width / 2;
    };
    RewardField.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createCurrencyIcon();
        this.createCountText();
    };
    RewardField.prototype.createBackground = function () {
        var key = MultiAtlases.Popups.Atlas.Name;
        var frame = MultiAtlases.Popups.Atlas.Frames["RewardFieldBackground" + this.index % 3];
        var frameTexture = this.getFrame();
        this.background = this.scene.make.tileSprite({
            x: 0,
            y: 0,
            key: key,
            frame: frame,
            width: frameTexture.width - 0.1,
            height: frameTexture.height - 0.1,
        });
        this.add(this.background);
        this.background.setOrigin(0, 0.5);
    };
    RewardField.prototype.createCurrencyIcon = function () {
        this.currencyIcon = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames["CurrencyIcon" + Phaser.Utils.String.UppercaseFirst(this.config.type)],
        });
        this.add(this.currencyIcon);
    };
    RewardField.prototype.createCountText = function () {
        this.countText = this.scene.make.bitmapText({
            x: 0,
            y: 0,
            font: BitmapFonts.RobotoSlab.Name,
            text: "" + this.config.amount,
            size: 50 * gameConfig.resolutionMultiplier,
        });
        this.countText.x = this.currencyIcon.width + this.countText.width / 2;
        this.countText.setOrigin(0.5);
        this.countText.setTint(0xc0a35c);
        this.add(this.countText);
    };
    RewardField.prototype.prepare = function () {
        this.background.width = 0;
        this.currencyIcon.setScale(0);
        this.countText.setAlpha(0);
        this.countText.setScale(2);
    };
    RewardField.prototype.getFrame = function () {
        var key = MultiAtlases.Popups.Atlas.Name;
        var frame = MultiAtlases.Popups.Atlas.Frames["RewardFieldBackground" + this.index % 3];
        return this.scene.textures.getFrame(key, frame);
    };
    return RewardField;
}(Phaser.GameObjects.Container));
export default RewardField;
