var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { gameConfig } from '../../constants/GameConfig';
import GameFacade from '../../GameFacade';
import StateVOProxy from '../../model/StateVOProxy';
import ControlView from '../components/game/ControlView';
import GameScene from '../scenes/GameScene';
import { getScene } from '../utils/phaser/PhaserUtils';
import ExitConfirmationPopup, { ExitConfirmationPopupAction, } from './ExitConfirmationPopup';
import StandardPopupMediator from './StandardPopupMediator';
import RewardPopup from './RewardPopup';
var ExitConfirmationPopupMediator = /** @class */ (function (_super) {
    __extends(ExitConfirmationPopupMediator, _super);
    function ExitConfirmationPopupMediator() {
        return _super.call(this, ExitConfirmationPopupMediator.NAME) || this;
    }
    ExitConfirmationPopupMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(ControlView.BACK_NOTIFICATION, GameFacade.BACK_BUTTON_CLICKED_NOTIFICATION, RewardPopup.SHOWN_NOTIFICATION);
    };
    ExitConfirmationPopupMediator.prototype.handleNotification = function (notificationName) {
        switch (notificationName) {
            case GameFacade.BACK_BUTTON_CLICKED_NOTIFICATION:
                var gameScene = getScene(GameScene.NAME);
                var stateVOProxy = this.facade.retrieveProxy(StateVOProxy.NAME);
                if (!gameScene.sys.isActive() ||
                    gameScene.sys.isSleeping() ||
                    !stateVOProxy.vo.players.length) {
                    break;
                }
            case ControlView.BACK_NOTIFICATION:
                this.showView(gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.5);
                break;
            case RewardPopup.SHOWN_NOTIFICATION:
                this.hideView();
                break;
            default:
                break;
        }
    };
    ExitConfirmationPopupMediator.prototype.createView = function () {
        _super.prototype.createView.call(this, new ExitConfirmationPopup());
    };
    ExitConfirmationPopupMediator.prototype.onViewHideComplete = function (actionId) {
        switch (actionId) {
            case ExitConfirmationPopupAction.NEGATIVE:
                this.sendNotification(ExitConfirmationPopup.EXIT_ROOM_NOTIFICATION);
                break;
            case ExitConfirmationPopupAction.POSITIVE:
            default:
                break;
        }
        _super.prototype.onViewHideComplete.call(this);
    };
    ExitConfirmationPopupMediator.NAME = 'ExitConfirmationPopupMediator';
    return ExitConfirmationPopupMediator;
}(StandardPopupMediator));
export default ExitConfirmationPopupMediator;
