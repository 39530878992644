var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../assets';
var UserInfoProgress = /** @class */ (function (_super) {
    __extends(UserInfoProgress, _super);
    function UserInfoProgress(scene, progress) {
        var _this = _super.call(this, scene) || this;
        _this.progress = progress;
        _this.createBody();
        return _this;
    }
    UserInfoProgress.prototype.createBody = function () {
        this.createUpperFrame();
        this.setSize(this.upperFrame.displayWidth, this.upperFrame.displayHeight);
        this.createBackground();
        this.createFill();
        this.createShine();
        this.bringToTop(this.upperFrame);
    };
    UserInfoProgress.prototype.createUpperFrame = function () {
        var frame = this.scene.textures.getFrame(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.UserInfoPopupProgressUpFrame);
        this.upperFrame = this.scene.make.tileSprite({
            x: 0,
            y: 0,
            key: frame.texture.key,
            frame: frame.name,
            width: frame.width * 0.89,
            height: frame.height,
        });
        this.upperFrame.setOrigin(0, 0.5);
        this.upperFrame.x = -frame.width * 0.545;
        this.add(this.upperFrame);
    };
    UserInfoProgress.prototype.createBackground = function () {
        this.backgroundFrame = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupProgressBackground,
        });
        this.backgroundFrame.setScale((this.upperFrame.displayWidth * 0.89) / this.backgroundFrame.width, (this.upperFrame.height / this.backgroundFrame.height) * 0.5);
        this.backgroundFrame.x = this.upperFrame.x + this.upperFrame.width * 0.114;
        this.backgroundFrame.y = -this.upperFrame.displayHeight * 0.17;
        this.backgroundFrame.setOrigin(0, 0.5);
        this.add(this.backgroundFrame);
    };
    UserInfoProgress.prototype.createFill = function () {
        var fillFrame = this.scene.textures.getFrame(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.UserInfoPopupProgressFill);
        this.fill = this.scene.make.tileSprite({
            x: this.backgroundFrame.x,
            y: this.backgroundFrame.y,
            key: fillFrame.texture.key,
            frame: fillFrame.name,
            width: 0,
            height: fillFrame.height,
        });
        this.fill.setOrigin(0, 0.5);
        this.add(this.fill);
        this.fill.width = this.backgroundFrame.displayWidth * this.progress;
    };
    UserInfoProgress.prototype.createShine = function () {
        this.shine = this.scene.make.image({
            x: this.fill.x + this.fill.width,
            y: this.fill.y,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupProgressShine,
        });
        this.add(this.shine);
        this.shine.setOrigin(1, 0.5);
    };
    return UserInfoProgress;
}(Phaser.GameObjects.Container));
export default UserInfoProgress;
