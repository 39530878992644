var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { gameConfig } from '../../constants/GameConfig';
import PlayerVOProxy from '../../model/PlayerVOProxy';
import { Setting } from '../../model/VO/PlayerVO';
import ControlView from '../components/game/ControlView';
import LobbyScene from '../scenes/LobbyScene';
import PopupScene from '../scenes/PopupScene';
import RoomScene from '../scenes/RoomScene';
import SettingsPopup from './SettingsPopup';
import StandardPopupMediator from './StandardPopupMediator';
var SettingsPopupMediator = /** @class */ (function (_super) {
    __extends(SettingsPopupMediator, _super);
    function SettingsPopupMediator() {
        return _super.call(this, SettingsPopupMediator.NAME) || this;
    }
    SettingsPopupMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(ControlView.SETTINGS_NOTIFICATION, LobbyScene.SETTINGS_CLICKED_NOTIFICATION, RoomScene.SETTINGS_CLICKED_NOTIFICATION, PlayerVOProxy.UPDATE_SETTINGS_NOTIFICATION);
    };
    SettingsPopupMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        switch (notificationName) {
            case ControlView.SETTINGS_NOTIFICATION:
            case LobbyScene.SETTINGS_CLICKED_NOTIFICATION:
            case RoomScene.SETTINGS_CLICKED_NOTIFICATION:
                this.showView(gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.5);
            case PlayerVOProxy.UPDATE_SETTINGS_NOTIFICATION:
                this.viewComponent &&
                    this.viewComponent.updateSettings(this.playerVOProxy.vo.settings);
                break;
            default:
                break;
        }
    };
    SettingsPopupMediator.prototype.createView = function () {
        _super.prototype.createView.call(this, new SettingsPopup());
        this.viewComponent.createSettings(this.getConfigs());
        this.viewComponent.updateSettings(this.playerVOProxy.vo.settings);
    };
    SettingsPopupMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.scene.events.on(PopupScene.SETTING_CHANGE_EVENT, this.onSettingChange, this);
    };
    SettingsPopupMediator.prototype.removeViewComponentListeners = function () {
        this.viewComponent.scene.events.off(PopupScene.SETTING_CHANGE_EVENT, this.onSettingChange, this);
    };
    SettingsPopupMediator.prototype.onSettingChange = function (setting) {
        this.sendNotification(SettingsPopup.SETTING_CHANGED_NOTIFICATION, setting);
    };
    SettingsPopupMediator.prototype.getConfigs = function () {
        var soundConfig = {
            name: Setting.SOUND,
            status: this.playerVOProxy.vo.settings.sound,
        };
        var musicConfig = {
            name: Setting.MUSIC,
            status: this.playerVOProxy.vo.settings.music,
        };
        var vibrationConfig = {
            name: Setting.VIBRATION,
            status: this.playerVOProxy.vo.settings.vibration,
        };
        var messagesConfig = {
            name: Setting.MESSAGES,
            status: this.playerVOProxy.vo.settings.messages,
        };
        var languageConfig = {
            name: Setting.LANGUAGE,
            status: this.playerVOProxy.vo.settings.language,
        };
        var configs = [];
        configs.push(soundConfig);
        configs.push(musicConfig);
        configs.push(messagesConfig);
        configs.push(languageConfig);
        return configs;
    };
    Object.defineProperty(SettingsPopupMediator.prototype, "playerVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(PlayerVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    SettingsPopupMediator.NAME = 'SettingsPopupMediator';
    return SettingsPopupMediator;
}(StandardPopupMediator));
export default SettingsPopupMediator;
