var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ScrollerContainer = /** @class */ (function (_super) {
    __extends(ScrollerContainer, _super);
    function ScrollerContainer(scene, inputListenerConfig, relativeMaskConfig, kineticScrollConfig, gap, width, height) {
        if (gap === void 0) { gap = 0; }
        var _this = _super.call(this, scene) || this;
        _this.inputListenerConfig = inputListenerConfig;
        _this.relativeMaskConfig = relativeMaskConfig;
        _this.kineticScrollConfig = kineticScrollConfig;
        _this.gap = gap;
        _this.setSize(width, height);
        _this.scrollableList = [];
        _this.lastApplied = {
            x: 0,
            y: 0,
            speedX: 0,
            speedY: 0,
        };
        _this.createMask();
        _this.createZone();
        _this.setListeners();
        return _this;
    }
    ScrollerContainer.prototype.setVisible = function (visible) {
        this.limitMovement(true);
        return _super.prototype.setVisible.call(this, visible);
    };
    ScrollerContainer.prototype.setPosition = function (x, y, z, w) {
        _super.prototype.setPosition.call(this, x, y, z, w);
        if (this.maskGraphics) {
            this.updateMask();
        }
        return this;
    };
    ScrollerContainer.prototype.addScrollAble = function (object, toEnd) {
        if (toEnd === void 0) { toEnd = false; }
        toEnd ? this.add(object) : this.addAt(object, 0);
        toEnd
            ? this.scrollableList.push(object)
            : this.scrollableList.unshift(object);
        this.repositionChildren();
        return this;
    };
    ScrollerContainer.prototype.removeScrollable = function (object) {
        this.remove(object);
        var index = this.scrollableList.indexOf(object);
        if (index !== -1) {
            this.scrollableList.splice(index, 1);
        }
        this.repositionChildren();
        return this;
    };
    ScrollerContainer.prototype.scrollToIndex = function (index) {
        var target = this.scrollableList[index];
        this.startScrollToTargetTween(target);
    };
    ScrollerContainer.prototype.scrollToElement = function (target) {
        if (!this.scrollableList.contains(target)) {
            return;
        }
        this.startScrollToTargetTween(target);
    };
    ScrollerContainer.prototype.setMaskObject = function (object) {
        this.maskGraphics = object;
    };
    ScrollerContainer.prototype.onScrollUpdate = function () { };
    ScrollerContainer.prototype.onDragStart = function (pointer) {
        this.applyDragStart(pointer);
        this.positionFixTween &&
            this.positionFixTween.isPlaying &&
            this.positionFixTween.stop();
    };
    ScrollerContainer.prototype.onDrag = function (pointer) {
        this.applyDrag(pointer);
        this.onScrollUpdate();
    };
    ScrollerContainer.prototype.onDragEnd = function (pointer) {
        this.applyDragEnd(pointer);
        this.dragData.endMoment = Date.now();
        if (this.limitMovement()) {
            return;
        }
        this.startKineticScroll();
    };
    ScrollerContainer.prototype.checkAndApplyClickOnElement = function (pointer) {
        if ((this.dragData.end &&
            this.dragData.start &&
            Math.abs(this.dragData.end - this.dragData.start) >=
                this.inputListenerConfig.distanceTrashHold) ||
            !this.maskShape.contains(pointer.x, pointer.y)) {
            return;
        }
        for (var _i = 0, _a = this.scrollableList; _i < _a.length; _i++) {
            var element = _a[_i];
            var transformMatrix = element.getWorldTransformMatrix();
            var shape = new Phaser.Geom.Rectangle(transformMatrix.tx, transformMatrix.ty, element.width, element.height);
            if (shape.contains(pointer.x, pointer.y)) {
                this.applyClick(pointer, element);
                break;
            }
        }
    };
    ScrollerContainer.prototype.applyClick = function (pointer, element) {
        this.emit(ScrollerContainer.ELEMENT_CLICKED_EVENT, pointer, element);
    };
    ScrollerContainer.prototype.repositionChildren = function () {
        this.applyChildrenPositions();
        this.limitMovement(true);
    };
    ScrollerContainer.prototype.setListeners = function () {
        !this.zone.input && this.zone.setInteractive();
        !this.zone.input.draggable && this.scene.input.setDraggable(this.zone);
        this.removeListeners();
        this.zone.on(Phaser.Input.Events.GAMEOBJECT_DRAG_START, this.onDragStart, this);
        this.zone.on(Phaser.Input.Events.GAMEOBJECT_DRAG, this.onDrag, this);
        this.zone.on(Phaser.Input.Events.GAMEOBJECT_DRAG_END, this.onDragEnd, this);
        this.zone.on(Phaser.Input.Events.POINTER_UP, this.checkAndApplyClickOnElement, this);
    };
    ScrollerContainer.prototype.updateMask = function () {
        this.maskGraphics.clear();
        this.maskGraphics.fillStyle(0xffffff);
        this.maskGraphics.fillRectShape(this.maskShape);
        this.setMask(new Phaser.Display.Masks.GeometryMask(this.scene, this.maskGraphics));
    };
    ScrollerContainer.prototype.createMask = function () {
        this.maskGraphics = this.scene.make.graphics({});
        this.maskGraphics.fillRectShape(this.maskShape);
        // this.setMask(
        //   new Phaser.Display.Masks.GeometryMask(this.scene, this.maskGraphics),
        // );
    };
    ScrollerContainer.prototype.createZone = function () {
        this.zone = this.scene.make.zone(this.inputListenerConfig);
        this.zone.setOrigin(0);
        this.add(this.zone);
    };
    ScrollerContainer.prototype.removeListeners = function () {
        this.zone.off(Phaser.Input.Events.DRAG_START, this.onDragStart, this, false);
        this.zone.off(Phaser.Input.Events.DRAG, this.onDrag, this, false);
        this.zone.off(Phaser.Input.Events.DRAG_END, this.onDragEnd, this, false);
    };
    ScrollerContainer.prototype.applyScroll = function () {
        var scrollChanges = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            scrollChanges[_i] = arguments[_i];
        }
        for (var _a = 0, _b = this.scrollableList; _a < _b.length; _a++) {
            var object = _b[_a];
            for (var _c = 0, scrollChanges_1 = scrollChanges; _c < scrollChanges_1.length; _c++) {
                var scrollData = scrollChanges_1[_c];
                object[scrollData.propertyName] += scrollData.value;
            }
        }
    };
    Object.defineProperty(ScrollerContainer.prototype, "sumHeight", {
        get: function () {
            var height = 0;
            this.scrollableList.forEach(function (element) {
                height += element.height;
            });
            return height;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollerContainer.prototype, "sumWidth", {
        get: function () {
            var width = 0;
            this.scrollableList.forEach(function (element) {
                width += element.width;
            });
            return width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollerContainer.prototype, "first", {
        get: function () {
            return this.scrollableList.getFirst();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollerContainer.prototype, "last", {
        get: function () {
            return this.scrollableList.getLast();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollerContainer.prototype, "lastX", {
        get: function () {
            return this.last
                ? this.last.x + this.last.width
                : this.relativeMaskConfig.x;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollerContainer.prototype, "lastY", {
        get: function () {
            return this.last
                ? this.last.y + this.last.height
                : this.relativeMaskConfig.y;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollerContainer.prototype, "maskShape", {
        get: function () {
            var transformMatrix = this.getWorldTransformMatrix();
            return new Phaser.Geom.Rectangle(transformMatrix.tx + this.relativeMaskConfig.x, transformMatrix.ty + this.relativeMaskConfig.y, this.relativeMaskConfig.width, this.relativeMaskConfig.height);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollerContainer.prototype, "scrollStrengthHorizontal", {
        get: function () {
            if (this.first && this.first.x > this.relativeMaskConfig.x) {
                return Math.max(0, 0.5 -
                    (this.first.x - this.relativeMaskConfig.x) /
                        this.relativeMaskConfig.width);
            }
            else if (this.last &&
                this.last.x + this.last.width < this.relativeMaskConfig.width) {
                return Math.max(0, 0.5 -
                    (this.relativeMaskConfig.width -
                        (this.last.x - this.relativeMaskConfig.x + this.last.width)) /
                        this.relativeMaskConfig.width);
            }
            return 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollerContainer.prototype, "scrollStrengthVertical", {
        get: function () {
            if (this.first && this.first.y > 0) {
                return 0.5 - this.first.y / this.relativeMaskConfig.height;
            }
            else if (this.last &&
                this.last.y + this.last.height < this.relativeMaskConfig.height) {
                return (1 -
                    (this.relativeMaskConfig.height - this.last.y + this.last.height) /
                        this.relativeMaskConfig.height);
            }
            return 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollerContainer.prototype, "elementSize", {
        get: function () {
            return {
                width: this.scrollableList[0].displayWidth,
                height: this.scrollableList[0].displayHeight,
            };
        },
        enumerable: true,
        configurable: true
    });
    ScrollerContainer.ELEMENT_CLICKED_EVENT = 'elementClicked';
    return ScrollerContainer;
}(Phaser.GameObjects.Container));
export default ScrollerContainer;
