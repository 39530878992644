var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { Translation } from '../../translations';
import SimpleButtonWithBitmapText from '../utils/simpleButton/SimpleButtonWithBitmapText';
import { SpriteButton } from '../utils/simpleButton/SpriteButton';
import StandardPopup from './StandardPopup';
import UserInfoProfileContent from './userInfo/profile/UserInfoProfileContent';
import UserInfoLevel from './userInfo/UserInfoLevel';
import UserInfoPopupAvatar from './userInfo/UserInfoPopupAvatar';
import UserInfoProgress from './userInfo/UserInfoProgress';
export var UserInfoPopupAction;
(function (UserInfoPopupAction) {
    UserInfoPopupAction[UserInfoPopupAction["CLOSE"] = 0] = "CLOSE";
    UserInfoPopupAction[UserInfoPopupAction["LOGOUT"] = 1] = "LOGOUT";
})(UserInfoPopupAction || (UserInfoPopupAction = {}));
var UserInfoPopup = /** @class */ (function (_super) {
    __extends(UserInfoPopup, _super);
    function UserInfoPopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UserInfoPopup.prototype.prepareToShow = function (x, y, data, progress) {
        var args = [];
        for (var _i = 4; _i < arguments.length; _i++) {
            args[_i - 4] = arguments[_i];
        }
        _super.prototype.prepareToShow.apply(this, [x, y].concat(args));
        this.createSections(data);
        this.createProgress(progress);
        this.bringToTop(this.flagHandle);
        this.createFlag();
        this.createAvatar({
            id: data.userIndex,
            userId: data.userId,
            avatarUrl: data.avatar,
            isNative: data.isNative || !data.avatar,
        });
        this.createLevel(data.level);
        this.bringToTop(this.closeButton);
    };
    UserInfoPopup.prototype.createBody = function () {
        this.createColoredBlocker(0.7);
        this.createBgImage(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.UserInfoPopupBackground);
        this.createFlagHandle();
        this.createCloseButton();
    };
    UserInfoPopup.prototype.createSections = function (config) {
        this.createTournamentSection();
        this.createProfileSection(config);
    };
    UserInfoPopup.prototype.createProfileSection = function (config) {
        this.createProfileSectionButton();
        this.createProfileSectionContent(config);
    };
    UserInfoPopup.prototype.createProfileSectionButton = function () {
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupProfileTabActive,
            textConfig: {
                font: BitmapFonts.RobotoSlab.Name,
                fill: 0x472f18,
                size: 36 * gameConfig.resolutionMultiplier,
                text: Translation.USER_INFO_POPUP_TAB_PROFILE,
                origin: {
                    x: 0.58,
                    y: 0.5,
                },
            },
        };
        this.profileButton = new SimpleButtonWithBitmapText(this.scene, {
            normalStateConfig: normalStateConfig,
        });
        this.profileButton.x = -this.width * 0.079;
        this.profileButton.y = -this.height * 0.591;
        this.add(this.profileButton);
    };
    UserInfoPopup.prototype.createProfileSectionContent = function (config) {
        this.profileContent = new UserInfoProfileContent(this.scene, config);
        this.add(this.profileContent);
        this.profileContent.x = this.width * 0.08;
        this.profileContent.y = this.height * 0.02;
        this.profileContent.updateMask();
    };
    UserInfoPopup.prototype.createTournamentSection = function () {
        this.createTournamentSectionButton();
        this.createTournamentSectionContent();
    };
    UserInfoPopup.prototype.createTournamentSectionButton = function () {
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupTournamentTabInactive,
            textConfig: {
                font: BitmapFonts.RobotoSlab.Name,
                fill: 0x796237,
                size: 36 * gameConfig.resolutionMultiplier,
                text: Translation.USER_INFO_POPUP_TAB_TOURNAMENT,
                origin: {
                    x: 0.55,
                    y: 0.46,
                },
            },
        };
        this.tournamentButton = new SimpleButtonWithBitmapText(this.scene, {
            normalStateConfig: normalStateConfig,
        });
        this.tournamentButton.x = this.width * 0.205;
        this.tournamentButton.y = -this.height * 0.595;
        this.add(this.tournamentButton);
    };
    UserInfoPopup.prototype.createTournamentSectionContent = function () {
        //
    };
    UserInfoPopup.prototype.createFlagHandle = function () {
        this.flagHandle = this.scene.make.image({
            x: -this.width * 0.472,
            y: -this.height * 0.55,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupFlagHandle,
        });
        this.flagHandle.setOrigin(0.5, 0.2);
        this.add(this.flagHandle);
    };
    UserInfoPopup.prototype.createFlag = function () {
        var frame = this.scene.textures.getFrame(MultiAtlases.Flags.Atlas.Name, MultiAtlases.Flags.Atlas.Frames.FlagLeagueNone);
        this.flag = this.scene.make.tileSprite({
            x: this.flagHandle.x,
            y: this.flagHandle.y - this.flagHandle.height * 0.01,
            width: frame.width,
            height: -frame.height * 0.835,
            key: MultiAtlases.Flags.Atlas.Name,
            frame: MultiAtlases.Flags.Atlas.Frames.FlagLeagueNone,
        });
        this.flag.y += Math.abs(this.flag.height);
        this.flag.setOrigin(0.5, 0);
        this.add(this.flag);
    };
    UserInfoPopup.prototype.createAvatar = function (config) {
        this.avatar = new UserInfoPopupAvatar(this.scene, config);
        this.add(this.avatar);
        this.avatar.x = this.flagHandle.x;
        this.avatar.y = this.flagHandle.y + this.avatar.height * 0.06;
        this.avatar.updateMask();
    };
    UserInfoPopup.prototype.createLevel = function (level) {
        this.level = new UserInfoLevel(this.scene, level);
        this.level.x = this.avatar.x + this.avatar.displayWidth * 0.28;
        this.level.y = this.avatar.y + this.avatar.displayHeight * 0.38;
        this.add(this.level);
    };
    UserInfoPopup.prototype.createProgress = function (progress) {
        this.progressFIll = new UserInfoProgress(this.scene, progress);
        this.progressFIll.x = this.width * 0.125;
        this.progressFIll.y = -this.height * 0.5;
        this.add(this.progressFIll);
    };
    UserInfoPopup.prototype.createCloseButton = function () {
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.CloseButtonUp,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.CloseButtonHover,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.CloseButtonDown,
        };
        this.closeButton = new SpriteButton(this.scene, {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        });
        this.closeButton.x = this.width * 0.5;
        this.closeButton.y = -this.height * 0.475;
        this.closeButton.setScale(1.085);
        this.add(this.closeButton);
        this.closeButton.on(SpriteButton.CLICK_EVENT, this.onAction, this);
    };
    UserInfoPopup.NAME = 'UserInfoPopup';
    UserInfoPopup.LOGOUT_CLICKED_NOTIFICATION = UserInfoPopup.NAME + "LogoutClickedNotification";
    return UserInfoPopup;
}(StandardPopup));
export default UserInfoPopup;
