var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mediator } from '@candywings/pure-mvc';
import DeckVOProxy from '../../../model/DeckVOProxy';
import PlayerVOProxy from '../../../model/PlayerVOProxy';
import SocketVOProxy from '../../../model/SocketVOProxy';
import StateVOProxy from '../../../model/StateVOProxy';
import GameScene from '../../scenes/GameScene';
import { delayRunnable, getScene } from '../../utils/phaser/PhaserUtils';
import DeckView from './DeckView';
var DeckViewMediator = /** @class */ (function (_super) {
    __extends(DeckViewMediator, _super);
    function DeckViewMediator() {
        return _super.call(this, DeckViewMediator.NAME, null) || this;
    }
    DeckViewMediator.prototype.onRemove = function () {
        this.viewComponent && this.viewComponent.destroy();
        _super.prototype.onRemove.call(this);
    };
    DeckViewMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(GameScene.FORCE_START_NOTIFICATION, GameScene.REPLAY_NOTIFICATION, GameScene.CREATE_NOTIFICATION, GameScene.RECREATE_NOTIFICATION, DeckVOProxy.TRUMP_CARD_CHOSE_NOTIFICATION, DeckVOProxy.PLACE_DECK_NOTIFICATION, StateVOProxy.START_DEALING_NOTIFICATION, StateVOProxy.GAME_OVER_NOTIFICATION);
    };
    DeckViewMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, promises, playerIndex, cards, promise, card, count;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = notificationName;
                        switch (_a) {
                            case GameScene.FORCE_START_NOTIFICATION: return [3 /*break*/, 1];
                            case GameScene.REPLAY_NOTIFICATION: return [3 /*break*/, 3];
                            case GameScene.CREATE_NOTIFICATION: return [3 /*break*/, 3];
                            case GameScene.RECREATE_NOTIFICATION: return [3 /*break*/, 3];
                            case StateVOProxy.START_DEALING_NOTIFICATION: return [3 /*break*/, 4];
                            case DeckVOProxy.TRUMP_CARD_CHOSE_NOTIFICATION: return [3 /*break*/, 7];
                            case DeckVOProxy.PLACE_DECK_NOTIFICATION: return [3 /*break*/, 10];
                            case DeckVOProxy.CARDS_COUNT_CHANGED_NOTIFICATION: return [3 /*break*/, 12];
                            case StateVOProxy.GAME_OVER_NOTIFICATION: return [3 /*break*/, 13];
                        }
                        return [3 /*break*/, 14];
                    case 1:
                        this.setView();
                        this.viewComponent.showTrump(this.deckVOProxy.vo.trump, true);
                        return [4 /*yield*/, this.viewComponent.placeDeck(true)];
                    case 2:
                        _b.sent();
                        this.viewComponent.showCardsCount(this.deckVOProxy.vo.cardsCount);
                        this.viewComponent.setCardsCount(this.deckVOProxy.vo.cardsCount);
                        this.subscribeToNotifications(DeckVOProxy.CARDS_COUNT_CHANGED_NOTIFICATION);
                        return [3 /*break*/, 15];
                    case 3:
                        this.setView();
                        return [3 /*break*/, 15];
                    case 4:
                        console.warn('*************');
                        console.warn('StateVOProxy.START_DEALING_NOTIFICATION');
                        console.warn(this.stateVOProxy.vo.players.getLast().isDefender);
                        console.warn(this.stateVOProxy.vo);
                        console.warn(this.socketVOProxy.vo);
                        console.warn(this.stateVOProxy.vo.turnData);
                        console.warn(this.playerVOProxy.vo);
                        if ((this.stateVOProxy.vo.deckData.cardsToDealToPlayers.length &&
                            this.socketVOProxy.vo.validAction == 4) ||
                            this.socketVOProxy.vo.validAction == 0) {
                            delayRunnable(this.scene, 1000, this.sendNotification, this, DeckView.DEAL_COMPLETE_NOTIFICATION);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.viewComponent.moveDeckIn()];
                    case 5:
                        _b.sent();
                        promises = [];
                        this.sendNotification(DeckView.DEAL_STARTED_NOTIFICATION);
                        for (playerIndex = 0; playerIndex < this.deckVOProxy.vo.cardsToDealToPlayers.length; playerIndex++) {
                            cards = this.deckVOProxy.vo.cardsToDealToPlayers[playerIndex];
                            promise = this.viewComponent.createAndDealCards(playerIndex, cards);
                            promises.push(promise);
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 6:
                        _b.sent();
                        delayRunnable(this.scene, 1000, this.sendNotification, this, DeckView.DEAL_COMPLETE_NOTIFICATION);
                        return [3 /*break*/, 15];
                    case 7:
                        card = args[0];
                        return [4 /*yield*/, this.viewComponent.showTrump(card)];
                    case 8:
                        _b.sent();
                        return [4 /*yield*/, this.viewComponent.placeDeck()];
                    case 9:
                        _b.sent();
                        this.viewComponent.showCardsCount();
                        this.sendNotification(DeckView.DECK_PLACED_NOTIFICATION);
                        this.subscribeToNotifications(DeckVOProxy.CARDS_COUNT_CHANGED_NOTIFICATION);
                        this.viewComponent.setCardsCount(this.deckVOProxy.vo.cardsCount);
                        return [3 /*break*/, 15];
                    case 10:
                        console.warn('DeckVOProxy.PLACE_DECK_NOTIFICATION');
                        return [4 /*yield*/, this.viewComponent.placeDeck()];
                    case 11:
                        _b.sent();
                        this.sendNotification(DeckView.DECK_PLACED_NOTIFICATION);
                        return [3 /*break*/, 15];
                    case 12:
                        {
                            count = args[0];
                            this.viewComponent.setCardsCount(count);
                        }
                        return [3 /*break*/, 15];
                    case 13:
                        this.viewComponent && this.viewComponent.hide();
                        return [3 /*break*/, 15];
                    case 14:
                        console.warn(notificationName + " is unhandled!");
                        return [3 /*break*/, 15];
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    DeckViewMediator.prototype.setView = function () {
        var view = new DeckView(this.scene);
        this.setViewComponent(view);
        this.setViewComponentListeners();
    };
    DeckViewMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.on(DeckView.CARD_DEALED_EVENT, this.onCardsDealed, this);
    };
    DeckViewMediator.prototype.onCardsDealed = function (playerIndex, cards) {
        this.sendNotification(DeckView.DEAL_CARD_NOTIFICATION, playerIndex, cards);
    };
    Object.defineProperty(DeckViewMediator.prototype, "scene", {
        get: function () {
            return getScene(GameScene.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeckViewMediator.prototype, "deckVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(DeckVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeckViewMediator.prototype, "playerVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(PlayerVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeckViewMediator.prototype, "stateVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(StateVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeckViewMediator.prototype, "socketVOProxy", {
        get: function () {
            return this.facade.retrieveProxy(SocketVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    DeckViewMediator.NAME = 'DeckViewMediator';
    return DeckViewMediator;
}(Mediator));
export default DeckViewMediator;
