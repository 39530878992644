var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import PlayerVOProxy from '../../model/PlayerVOProxy';
import SocketVOProxy from '../../model/SocketVOProxy';
import StateVOProxy from '../../model/StateVOProxy';
import { Room } from '../../model/VO/RoomsVO';
import { NavigationMode } from '../components/lobby/navigation/NavigationSection';
import InsufficientFundsPopup from '../popups/InsufficientFundsPopup';
import BaseSceneMediator from './BaseSceneMediator';
import GameScene from './GameScene';
import LobbyScene from './LobbyScene';
import PreloadScene from './PreloadScene';
import RegistrationScene from './RegistrationScene';
import RoomScene from './RoomScene';
import ServiceScene from './ServiceScene';
import StoreScene from './StoreScene';
import TopBarScene from './TopBarScene';
import WheelScene from './WheelScene';
import PopupScene from './PopupScene';
var LobbySceneMediator = /** @class */ (function (_super) {
    __extends(LobbySceneMediator, _super);
    function LobbySceneMediator() {
        return _super.call(this, LobbySceneMediator.NAME, null) || this;
    }
    LobbySceneMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(PreloadScene.LOAD_COMPLETE_NOTIFICATION, TopBarScene.NAVIGATION_BUTTON_CLICKED_NOTIFICATION, TopBarScene.STORE_BUTTON_CLICKED_NOTIFICATION, TopBarScene.WHEEL_BUTTON_CLICKED_NOTIFICATION, StoreScene.SHOWN_NOTIFICATION, StoreScene.HIDDEN_NOTIFICATION, GameScene.LEAVE_ROOM_NOTIFICATION, InsufficientFundsPopup.BUY_NOTIFICATION, InsufficientFundsPopup.EARN_NOTIFICATION, RegistrationScene.HIDDEN_NOTIFICATION, RoomScene.DOOR_SELECTED_NOTIFICATION, RoomScene.SHOW_COMPLETE_NOTIFICATION, WheelScene.READY_TO_DESTROY_NOTIFICATION, PreloadScene.WAITING_FOR_API_NOTIFICATION, SocketVOProxy.FIND_ROOM_ALLOWED_NOTIFICATION, SocketVOProxy.FIND_ROOM_DENIED_NOTIFICATION);
    };
    LobbySceneMediator.prototype.handleNotification = function (notificationName) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, stateVOProxy, playerVOProxy;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.handleDefaultNotifications(notificationName);
                        _a = notificationName;
                        switch (_a) {
                            case PreloadScene.WAITING_FOR_API_NOTIFICATION: return [3 /*break*/, 1];
                            case PreloadScene.LOAD_COMPLETE_NOTIFICATION: return [3 /*break*/, 2];
                            case WheelScene.READY_TO_DESTROY_NOTIFICATION: return [3 /*break*/, 3];
                            case RegistrationScene.HIDDEN_NOTIFICATION: return [3 /*break*/, 3];
                            case GameScene.LEAVE_ROOM_NOTIFICATION: return [3 /*break*/, 4];
                            case InsufficientFundsPopup.BUY_NOTIFICATION: return [3 /*break*/, 5];
                            case InsufficientFundsPopup.EARN_NOTIFICATION: return [3 /*break*/, 5];
                            case TopBarScene.WHEEL_BUTTON_CLICKED_NOTIFICATION: return [3 /*break*/, 6];
                            case TopBarScene.STORE_BUTTON_CLICKED_NOTIFICATION: return [3 /*break*/, 8];
                            case StoreScene.SHOWN_NOTIFICATION: return [3 /*break*/, 9];
                            case WheelScene.READY_TO_DESTROY_NOTIFICATION: return [3 /*break*/, 10];
                            case StoreScene.HIDDEN_NOTIFICATION: return [3 /*break*/, 10];
                            case TopBarScene.NAVIGATION_BUTTON_CLICKED_NOTIFICATION: return [3 /*break*/, 12];
                            case RoomScene.SHOW_COMPLETE_NOTIFICATION: return [3 /*break*/, 13];
                            case RoomScene.DOOR_SELECTED_NOTIFICATION: return [3 /*break*/, 14];
                            case RoomScene.HIDE_COMPLETE_NOTIFICATION: return [3 /*break*/, 15];
                            case SocketVOProxy.FIND_ROOM_ALLOWED_NOTIFICATION: return [3 /*break*/, 16];
                            case SocketVOProxy.FIND_ROOM_DENIED_NOTIFICATION: return [3 /*break*/, 18];
                        }
                        return [3 /*break*/, 19];
                    case 1:
                        this.startScene();
                        return [3 /*break*/, 20];
                    case 2:
                        {
                            stateVOProxy = this.facade.retrieveProxy(StateVOProxy.NAME);
                            playerVOProxy = this.facade.retrieveProxy(PlayerVOProxy.NAME);
                            !stateVOProxy.vo.reconnected &&
                                !playerVOProxy.vo.isNewUser &&
                                this.wakeScene();
                            return [3 /*break*/, 20];
                        }
                        _b.label = 3;
                    case 3:
                        if (this.uiStateVOProxy.vo.navigationScene !== LobbyScene.NAME) {
                            return [3 /*break*/, 20];
                        }
                        this.wakeScene();
                        this.fadeScreenIn();
                        return [3 /*break*/, 20];
                    case 4:
                        if (this.uiStateVOProxy.vo.navigationScene !== LobbyScene.NAME) {
                            return [3 /*break*/, 20];
                        }
                        this.wakeScene();
                        this.viewComponent.slideTo({ x: -1, y: 0 });
                        this.viewComponent.input.enabled = true;
                        return [3 /*break*/, 20];
                    case 5:
                        this.wakeScene();
                        return [3 /*break*/, 20];
                    case 6: return [4 /*yield*/, this.fadeScreenOut()];
                    case 7:
                        _b.sent();
                        this.sceneManager.sleep(LobbyScene.NAME);
                        this.sendNotification(LobbyScene.HIDDEN_NOTIFICATION);
                        return [3 /*break*/, 20];
                    case 8:
                        this.viewComponent.input.enabled = false;
                        return [3 /*break*/, 20];
                    case 9:
                        this.sceneManager.sleep(LobbyScene.NAME);
                        return [3 /*break*/, 20];
                    case 10:
                        if (this.sceneManager.isActive(RoomScene.NAME)) {
                            return [3 /*break*/, 20];
                        }
                        return [4 /*yield*/, this.fadeScreenIn()];
                    case 11:
                        _b.sent();
                        this.sceneManager.wake(LobbyScene.NAME);
                        this.viewComponent.input.enabled = true;
                        return [3 /*break*/, 20];
                    case 12:
                        this.sceneManager.wake(LobbyScene.NAME);
                        this.viewComponent.slideTo(null, {
                            x: 0,
                            y: 0,
                        });
                        this.viewComponent.input.enabled = true;
                        return [3 /*break*/, 20];
                    case 13:
                        this.subscribeToNotifications(RoomScene.HIDE_COMPLETE_NOTIFICATION);
                        return [3 /*break*/, 20];
                    case 14:
                        this.unsubscribeToNotification(RoomScene.HIDE_COMPLETE_NOTIFICATION);
                        return [3 /*break*/, 20];
                    case 15:
                        this.sceneManager.wake(LobbyScene.NAME);
                        this.viewComponent.input.enabled = true;
                        return [3 /*break*/, 20];
                    case 16:
                        if (this.sceneManager.isSleeping(LobbyScene.NAME)) {
                            return [3 /*break*/, 20];
                        }
                        this.sendNotification(LobbyScene.SWITCHING_TO_GAME_SCENE);
                        return [4 /*yield*/, this.viewComponent.slideTo(null, {
                                x: -1,
                                y: 0,
                            })];
                    case 17:
                        _b.sent();
                        this.sceneManager.sleep(LobbyScene.NAME);
                        return [3 /*break*/, 20];
                    case 18:
                        if (this.sceneManager.isSleeping(LobbyScene.NAME)) {
                            return [3 /*break*/, 20];
                        }
                        this.viewComponent.input.enabled = true;
                        this.viewComponent.enableNavigationInput();
                        return [3 /*break*/, 20];
                    case 19:
                        if (this.defaultNotifications.contains(notificationName)) {
                            return [3 /*break*/, 20];
                        }
                        console.warn(notificationName + " is unhandled!");
                        return [3 /*break*/, 20];
                    case 20: return [2 /*return*/];
                }
            });
        });
    };
    LobbySceneMediator.prototype.onSceneWake = function () {
        _super.prototype.onSceneWake.call(this);
        this.viewComponent.input.enabled = true;
        this.viewComponent.enableNavigationInput();
        this.sendNotification(LobbyScene.SHOWN_NOTIFICATION);
    };
    LobbySceneMediator.prototype.onSceneSleep = function () {
        this.viewComponent.disableNavigationInput();
        _super.prototype.onSceneSleep.call(this);
    };
    LobbySceneMediator.prototype.setViewComponentListeners = function () {
        this.viewComponent.events.on(LobbyScene.ACTION_DONE_EVENT, this.onActionDone, this);
        this.viewComponent.events.on(ServiceScene.SETTINGS_CLICKED_EVENT, this.onSettingsClick, this);
    };
    LobbySceneMediator.prototype.setView = function () {
        var lobbyScene = new LobbyScene();
        this.sceneManager.add(LobbyScene.NAME, lobbyScene);
        this.setViewComponent(lobbyScene);
    };
    LobbySceneMediator.prototype.startScene = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.sceneManager.sendToBack(LobbyScene.NAME);
                this.sceneManager.start(LobbyScene.NAME);
                this.sceneManager.sleep(LobbyScene.NAME);
                return [2 /*return*/];
            });
        });
    };
    LobbySceneMediator.prototype.onActionDone = function (mode) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.viewComponent.input.enabled = false;
                        this.viewComponent.stopSFX();
                        _a = mode;
                        switch (_a) {
                            case NavigationMode.BONUS: return [3 /*break*/, 1];
                            case NavigationMode.GAME: return [3 /*break*/, 2];
                        }
                        return [3 /*break*/, 4];
                    case 1:
                        this.sendNotification(LobbyScene.BONUS_CHOSE_NOTIFICATION, Room.BONUS);
                        return [3 /*break*/, 5];
                    case 2:
                        this.sendNotification(LobbyScene.GAME_CHOSE_NOTIFICATION);
                        return [4 /*yield*/, this.viewComponent.slideTo(null, { x: 0, y: -1 })];
                    case 3:
                        _b.sent();
                        this.sceneManager.sleep(LobbyScene.NAME);
                        return [3 /*break*/, 5];
                    case 4: return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    LobbySceneMediator.prototype.onSettingsClick = function () {
        this.sendNotification(LobbyScene.SETTINGS_CLICKED_NOTIFICATION);
    };
    LobbySceneMediator.prototype.wakeScene = function () {
        this.sceneManager.wake(LobbyScene.NAME);
        this.sceneManager.bringToTop(LobbyScene.NAME);
        this.sceneManager.bringToTop(TopBarScene.NAME);
        this.sceneManager.bringToTop(PopupScene.NAME);
        this.fadeScreenIn(300, 0, true);
    };
    LobbySceneMediator.NAME = 'LobbySceneMediator';
    return LobbySceneMediator;
}(BaseSceneMediator));
export default LobbySceneMediator;
