var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import LobbyScene from '../../view/scenes/LobbyScene';
import RoomScene from '../../view/scenes/RoomScene';
import UiStateCommand from './UiStateCommand';
var ChangeCurrentNavigationSceneCommand = /** @class */ (function (_super) {
    __extends(ChangeCurrentNavigationSceneCommand, _super);
    function ChangeCurrentNavigationSceneCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChangeCurrentNavigationSceneCommand.prototype.execute = function (notificationName) {
        switch (notificationName) {
            case LobbyScene.SHOWN_NOTIFICATION:
                this.proxy.setCurrentNavigationScene(LobbyScene.NAME);
                break;
            case RoomScene.SHOW_COMPLETE_NOTIFICATION:
                this.proxy.setCurrentNavigationScene(RoomScene.NAME);
                break;
            default:
                break;
        }
    };
    return ChangeCurrentNavigationSceneCommand;
}(UiStateCommand));
export default ChangeCurrentNavigationSceneCommand;
