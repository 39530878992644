var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Atlases } from '../../../../assets';
import TopBarScene, { TopBarAction } from '../../../scenes/TopBarScene';
import { SpriteButton } from '../../../utils/simpleButton/SpriteButton';
var WheelButton = /** @class */ (function (_super) {
    __extends(WheelButton, _super);
    function WheelButton(scene) {
        var _this = this;
        var normalStateConfig = {
            key: Atlases.Topbar.Atlas.Name,
            frame: Atlases.Topbar.Atlas.Frames.WheelButtonNormal,
        };
        var hoverStateConfig = {
            key: Atlases.Topbar.Atlas.Name,
            frame: Atlases.Topbar.Atlas.Frames.WheelButtonHover,
        };
        var downStateConfig = {
            key: Atlases.Topbar.Atlas.Name,
            frame: Atlases.Topbar.Atlas.Frames.WheelButtonDown,
        };
        var configs = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        _this = _super.call(this, scene, configs) || this;
        _this.scene.add.existing(_this);
        _this.setEnabled(true);
        return _this;
    }
    WheelButton.prototype.onClick = function () {
        this.playSfxOnUp();
        this.scene.events.emit(TopBarScene.ACTION_DONE_EVENT, TopBarAction.WHEEL);
    };
    return WheelButton;
}(SpriteButton));
export default WheelButton;
