var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MultiAtlases } from '../../../assets';
import { loopRunnable, removeRunnable } from '../../utils/phaser/PhaserUtils';
var WaitingTimer = /** @class */ (function (_super) {
    __extends(WaitingTimer, _super);
    function WaitingTimer(scene) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.currentValue = 9;
        _this.createBody();
        return _this;
    }
    WaitingTimer.prototype.preDestroy = function () {
        this.stop();
        _super.prototype.preDestroy.call(this);
    };
    WaitingTimer.prototype.start = function (stepDuration) {
        if (stepDuration === void 0) { stepDuration = 1000; }
        this.timerRunnable = loopRunnable(this.scene, stepDuration, this.tick, this);
    };
    WaitingTimer.prototype.stop = function () {
        removeRunnable(this.timerRunnable);
        this.scene.tweens.killTweensOf(this.numberImage);
        this.scene.tweens.killTweensOf(this);
        this.scene.tweens.add({
            targets: this,
            scaleX: 0,
            scaleY: 0,
            duration: 200,
            ease: Phaser.Math.Easing.Back.In,
        });
    };
    WaitingTimer.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createNumberImage();
    };
    WaitingTimer.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SecondBackground,
        });
        this.add(this.background);
    };
    WaitingTimer.prototype.createNumberImage = function () {
        this.numberImage = this.scene.make.image({
            x: 0,
            y: this.height * 0.05,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.Sec9,
        });
        this.add(this.numberImage);
    };
    WaitingTimer.prototype.tick = function () {
        if (this.currentValue === 0) {
            this.parentContainer.emit(WaitingTimer.TIMER_COMPLETE_EVENT);
            this.stop();
            return;
        }
        this.currentValue--;
        !!this.scene &&
            this.numberImage.setTexture(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames["Sec" + this.currentValue]);
        this.emit(WaitingTimer.TIMER_TICK_EVENT, this.currentValue);
        // this.scene.tweens.add({
        //   targets: this.numberImage,
        //   scaleX: 0,
        //   duration: 100,
        //   yoyo: true,
        //   onYoyo: () => {
        //     this.emit(WaitingTimer.TIMER_TICK_EVENT, this.currentValue);
        //     !!this.scene &&
        //       this.numberImage.setTexture(
        //         MultiAtlases.Popups.Atlas.Name,
        //         (MultiAtlases.Popups.Atlas.Frames as any)[
        //           `Sec${this.currentValue}`
        //         ],
        //       );
        //   },
        // });
    };
    WaitingTimer.TIMER_COMPLETE_EVENT = 'timerComplete';
    WaitingTimer.TIMER_STARTED_EVENT = 'timerStarted';
    WaitingTimer.TIMER_TICK_EVENT = 'timerTick';
    return WaitingTimer;
}(Phaser.GameObjects.Container));
export default WaitingTimer;
