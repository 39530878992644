var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var SpineGameObject = require('phaser/plugins/spine/src/gameobject/SpineGameObject.js');
var SpineClass = /** @class */ (function (_super) {
    __extends(SpineClass, _super);
    //Some redefined methods
    function SpineClass(scene, plugin, x, y, key, animationName, loop) {
        return _super.call(this, scene, plugin, x, y, key, animationName, loop) || this;
    }
    SpineClass.prototype.renderWebGL = function (renderer) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (this.parentContainer) {
            renderer.nextTypeMatch = false;
            renderer.newType = true;
        }
        return _super.prototype.renderWebGL.apply(this, [renderer].concat(args));
    };
    return SpineClass;
}(SpineGameObject));
export default SpineClass;
