var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Proxy } from '@candywings/pure-mvc';
var RoomsVOProxy = /** @class */ (function (_super) {
    __extends(RoomsVOProxy, _super);
    function RoomsVOProxy(data) {
        return _super.call(this, RoomsVOProxy.NAME, data) || this;
    }
    RoomsVOProxy.prototype.onRegister = function () {
        _super.prototype.onRegister.call(this);
        this.sendNotification(RoomsVOProxy.INITIALIZE_SUCCESS_NOTIFICATION);
    };
    RoomsVOProxy.NAME = 'RoomsVOProxy';
    RoomsVOProxy.INITIALIZE_SUCCESS_NOTIFICATION = RoomsVOProxy.NAME + "InitializeSuccessNotification";
    return RoomsVOProxy;
}(Proxy));
export default RoomsVOProxy;
