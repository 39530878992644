var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Images, MultiAtlases } from '../../../assets';
import { hasKey } from '../../utils/phaser/PhaserUtils';
var OpponentGameAvatar = /** @class */ (function (_super) {
    __extends(OpponentGameAvatar, _super);
    function OpponentGameAvatar(scene, key, isNative) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.key = key;
        _this.isNative = isNative;
        _this.createBody();
        _this.prepare();
        return _this;
    }
    OpponentGameAvatar.prototype.preDestroy = function () {
        this.picture.clearMask();
        this.masker.destroy();
    };
    OpponentGameAvatar.prototype.updateData = function (key, isNative) {
        var _this = this;
        key = hasKey(key) ? key : Images.Avatar.Name;
        isNative = hasKey(key) ? isNative : true;
        this.isNative = isNative;
        this.background.setTexture(this.isNative
            ? MultiAtlases.Login.Atlas.Name
            : MultiAtlases.GamePlay.Atlas.Name, this.isNative
            ? MultiAtlases.Login.Atlas.Frames.AvatarPicBg
            : MultiAtlases.GamePlay.Atlas.Frames.AvatarPictureBackground);
        this.background.setScale(this.frame.displayWidth / this.background.width);
        this.key = key;
        this.picture.setTexture(key);
        this.picture.setAlpha(0);
        this.picture.setVisible(true);
        this.picture.setScale(this.frame.displayWidth / this.picture.width);
        this.updateMask();
        if (!!this.loading) {
            this.scene.tweens.killTweensOf(this.loading);
            this.scene.tweens.add({
                targets: this.loading,
                alpha: 0,
                duration: 200,
                onComplete: function () {
                    _this.loading.destroy();
                },
            });
        }
    };
    OpponentGameAvatar.prototype.showSmile = function (frame) {
        var _this = this;
        var smile = this.scene.make.image({
            x: this.picture.x,
            y: this.picture.y,
            key: MultiAtlases.Emojis.Atlas.Name,
            frame: frame,
        });
        smile.setScale(0);
        this.add(smile);
        this.scene.tweens.add({
            targets: smile,
            scaleX: this.picture.displayWidth / smile.width,
            scaleY: this.picture.displayWidth / smile.height,
            duration: 300,
            ease: Phaser.Math.Easing.Back.Out,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: smile,
                    scaleX: 0,
                    scaleY: 0,
                    duration: 300,
                    delay: 2000,
                    onComplete: function () {
                        smile.destroy();
                    },
                });
            },
        });
    };
    OpponentGameAvatar.prototype.show = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.scene.tweens.killTweensOf(_this.list);
                        _this.scene.tweens.add({
                            targets: [_this.picture, _this.frame],
                            alpha: 1,
                            duration: 300,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    OpponentGameAvatar.prototype.updateMask = function () {
        this.masker.clear();
        this.masker.fillStyle(0xffffff);
        var transformMatrix = this.frame.getWorldTransformMatrix();
        this.masker.fillCircle(transformMatrix.tx, transformMatrix.ty, this.frame.displayWidth * 0.5);
    };
    OpponentGameAvatar.prototype.createBody = function () {
        this.createFrame();
        this.createBackground();
        this.setSize(this.background.displayWidth, this.background.displayHeight);
        this.createPicture();
        this.bringToTop(this.frame);
        !this.key && this.createLoadingIcon();
        this.preparePicture();
    };
    OpponentGameAvatar.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: this.isNative
                ? MultiAtlases.Login.Atlas.Name
                : MultiAtlases.GamePlay.Atlas.Name,
            frame: this.isNative
                ? MultiAtlases.Login.Atlas.Frames.AvatarPicBg
                : MultiAtlases.GamePlay.Atlas.Frames.AvatarPictureBackground,
        });
        this.background.setScale(this.frame.width / this.background.displayWidth);
        this.add(this.background);
    };
    OpponentGameAvatar.prototype.createPicture = function () {
        this.picture = this.scene.make.image({
            x: this.background.x,
            y: this.background.y,
            key: !!this.key ? this.key : Images.Avatar.Name,
        });
        this.add(this.picture);
        this.picture.setVisible(!!this.key);
    };
    OpponentGameAvatar.prototype.createFrame = function () {
        this.frame = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.OpponentAvatarFrame,
        });
        this.add(this.frame);
    };
    OpponentGameAvatar.prototype.preparePicture = function () {
        this.picture.setScale(this.background.displayWidth / this.picture.width);
        this.createMaskObject();
    };
    OpponentGameAvatar.prototype.createMaskObject = function () {
        this.masker = this.scene.make.graphics({});
        this.picture.setMask(new Phaser.Display.Masks.GeometryMask(this.scene, this.masker));
    };
    OpponentGameAvatar.prototype.createLoadingIcon = function () {
        this.loading = this.scene.make.image({
            x: this.picture.x,
            y: this.picture.y,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.LoadingIcon,
        });
        this.add(this.loading);
        this.loading.setScale(2);
    };
    OpponentGameAvatar.prototype.startLoadingAnimation = function () {
        this.scene.tweens.add({
            targets: this.loading,
            alpha: 0.5,
            duration: 400,
            yoyo: true,
            repeat: -1,
            repeatDelay: 200,
        });
        this.scene.tweens.add({
            targets: this.loading,
            angle: 360,
            yoyo: true,
            repeat: -1,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 3000,
        });
    };
    OpponentGameAvatar.prototype.prepare = function () {
        var backgroundFrame = this.scene.textures.getFrame(MultiAtlases.GamePlay.Atlas.Name, MultiAtlases.GamePlay.Atlas.Frames.OpponentBackgroundDecoration);
        this.y = backgroundFrame.height * 0.53;
        this.picture.setAlpha(0);
        this.frame.setAlpha(0);
    };
    return OpponentGameAvatar;
}(Phaser.GameObjects.Container));
export default OpponentGameAvatar;
