var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var KnifeSprite = /** @class */ (function (_super) {
    __extends(KnifeSprite, _super);
    function KnifeSprite(scene, targetPosition) {
        var _this = _super.call(this, scene, gameConfig.canvasWidth / 2, gameConfig.canvasHeight * 1.15, MultiAtlases.Wheel.Atlas.Name, MultiAtlases.Wheel.Atlas.Frames.KnifeNormal) || this;
        _this.targetPosition = targetPosition;
        _this.targetPosition;
        _this.state = 0;
        _this.depth = 30;
        return _this;
    }
    KnifeSprite.prototype.show = function () {
        var _this = this;
        this.visible = true;
        this.scene.tweens.add({
            targets: this,
            y: gameConfig.canvasHeight * 0.8,
            duration: 300,
            ease: Phaser.Math.Easing.Back.InOut,
            onComplete: function () {
                _this.setListeners();
                _this.startIdleAnimation();
            },
        });
    };
    KnifeSprite.prototype.tweenTo = function (duration, x, y) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.scene.tweens.add({
                            targets: _this,
                            x: x,
                            y: y,
                            scaleX: 0.5 * Math.sign(_this.scaleX),
                            scaleY: 0.5,
                            duration: duration,
                            ease: Phaser.Math.Easing.Back.In,
                            onComplete: function () {
                                _this.setVisible(false);
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    KnifeSprite.prototype.setListeners = function () {
        this.setInteractive();
        this.scene.input.setDraggable(this);
        this.input.draggable = true;
        this.on(Phaser.Input.Events.GAMEOBJECT_DRAG, this.onDrag, this);
        this.on(Phaser.Input.Events.GAMEOBJECT_DRAG_END, this.onDragEnd, this);
    };
    KnifeSprite.prototype.onDrag = function (pointer) {
        if (pointer) {
            this.scene.tweens.killTweensOf(this);
            this.x = pointer.x;
        }
        this.setOrigin(0.5);
        this.scaleX = Math.abs(this.scaleX);
        this.setTexture(MultiAtlases.Wheel.Atlas.Name, MultiAtlases.Wheel.Atlas.Frames.KnifeNormal);
        this.state = KnifeSprite.NORMAL;
        this.rotation =
            Phaser.Math.Angle.Between(this.x, this.y, this.targetPosition.x, this.targetPosition.y) +
                Math.PI / 2;
    };
    KnifeSprite.prototype.onDragEnd = function () {
        this.emit(KnifeSprite.KNIFE_THROWN_EVENT, this.state);
        this.input.enabled = false;
        this.input.draggable = false;
    };
    KnifeSprite.prototype.startIdleAnimation = function () {
        var _this = this;
        this.scene.tweens.add({
            targets: this,
            x: this.x + 30,
            onUpdate: function () {
                _this.onDrag();
            },
            duration: 900,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: _this,
                    x: _this.x - 60,
                    yoyo: true,
                    repeat: -1,
                    onUpdate: function () {
                        _this.onDrag();
                    },
                    duration: 1800,
                });
            },
        });
    };
    KnifeSprite.NORMAL = 0;
    KnifeSprite.LEFT = 1;
    KnifeSprite.RIGHT = 2;
    KnifeSprite.KNIFE_THROWN_EVENT = 'knifeThrown';
    return KnifeSprite;
}(Phaser.GameObjects.Sprite));
export default KnifeSprite;
