var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Proxy } from '@candywings/pure-mvc';
import DeckVO from './VO/DeckVO';
var DeckVOProxy = /** @class */ (function (_super) {
    __extends(DeckVOProxy, _super);
    function DeckVOProxy(vo) {
        return _super.call(this, DeckVOProxy.NAME, vo || new DeckVO()) || this;
    }
    DeckVOProxy.prototype.onRegister = function () {
        _super.prototype.onRegister.call(this);
        this.sendNotification(DeckVOProxy.INITIALIZE_SUCCESS_NOTIFICATION);
    };
    DeckVOProxy.prototype.setVO = function (vo) {
        this.vo = vo;
        this.sendNotification(DeckVOProxy.REFERENCE_UPDATED_NOTIFICATION);
    };
    DeckVOProxy.prototype.setOpponentsCardsCountToDeal = function (opponents) {
        for (var _i = 0, opponents_1 = opponents; _i < opponents_1.length; _i++) {
            var opponent = opponents_1[_i];
            var count = opponent.totalCardsCount;
            this.vo.cardsToDealToPlayers[opponent.userIndex] = [];
            for (var i = 0; i < count; i++) {
                this.vo.cardsToDealToPlayers[opponent.userIndex].push(null);
            }
        }
        this.sendNotification(DeckVOProxy.READY_TO_DEAL_NOTIFICATION);
    };
    DeckVOProxy.prototype.setPlayerCardsToDeal = function (newCards) {
        this.vo.cardsToDealToPlayers[3] = newCards;
    };
    DeckVOProxy.prototype.setTrumpCard = function (trump) {
        this.vo.trump = trump;
    };
    DeckVOProxy.prototype.onDealingComplete = function () {
        this.vo.isTrumpShown
            ? this.sendNotification(DeckVOProxy.PLACE_DECK_NOTIFICATION)
            : this.sendNotification(DeckVOProxy.TRUMP_CARD_CHOSE_NOTIFICATION, this.vo.trump);
        this.vo.isTrumpShown = true;
        this.emptyCardsToDealToPlayers();
    };
    DeckVOProxy.prototype.decreaseCardsCount = function () {
        if (this.vo.cardsCount === 0) {
            return;
        }
        this.vo.cardsCount--;
        this.sendNotification(DeckVOProxy.CARDS_COUNT_CHANGED_NOTIFICATION, this.vo.cardsCount);
    };
    DeckVOProxy.prototype.emptyCardsToDealToPlayers = function () {
        for (var _i = 0, _a = this.vo.cardsToDealToPlayers; _i < _a.length; _i++) {
            var playerCardsToDeal = _a[_i];
            playerCardsToDeal.remove.apply(playerCardsToDeal, playerCardsToDeal);
        }
    };
    DeckVOProxy.NAME = 'DeckVOProxy';
    DeckVOProxy.INITIALIZE_SUCCESS_NOTIFICATION = DeckVOProxy.NAME + "InitializeSuccessNotification";
    DeckVOProxy.REFERENCE_UPDATED_NOTIFICATION = DeckVOProxy.NAME + "ReferenceUpdatedNotification";
    DeckVOProxy.DEALING_STARTED_NOTIFICATION = DeckVOProxy.NAME + "DealingStartedNotification";
    DeckVOProxy.TRUMP_CARD_CHOSE_NOTIFICATION = DeckVOProxy.NAME + "TrumpCardChoseNotification";
    DeckVOProxy.PLACE_DECK_NOTIFICATION = DeckVOProxy.NAME + "PlaceDeckNotification";
    DeckVOProxy.CARDS_COUNT_CHANGED_NOTIFICATION = DeckVOProxy.NAME + "CardsCountChangedNotification";
    DeckVOProxy.READY_TO_DEAL_NOTIFICATION = DeckVOProxy.NAME + "ReadyToDealNotification";
    return DeckVOProxy;
}(Proxy));
export default DeckVOProxy;
