import ServiceScene from '../../scenes/ServiceScene';
import { getScene, hasKey } from '../phaser/PhaserUtils';
var CustomHtmlImage = /** @class */ (function () {
    function CustomHtmlImage(parentId, id, key, frame, width, height) {
        this.parentId = parentId;
        this.id = id;
        this.frame = frame;
        this.image = new Image();
        this.id = id;
        this.image.width = width;
        this.image.height = height;
        key && this.setTexture(key, frame);
        this.addToDocument();
    }
    CustomHtmlImage.prototype.addToDocument = function () {
        var parent = this.parentId
            ? document.getElementById(this.parentId)
            : document.body;
        parent.prepend(this.image);
    };
    CustomHtmlImage.prototype.clear = function () {
        this.key = null;
        this.image.src = '';
    };
    CustomHtmlImage.prototype.setTexture = function (key, frame) {
        if (this.key === key || !hasKey(key)) {
            return;
        }
        this.key = key;
        this.image.src = this.scene.textures.getBase64(this.key);
    };
    Object.defineProperty(CustomHtmlImage.prototype, "scene", {
        get: function () {
            return getScene(ServiceScene.NAME);
        },
        enumerable: true,
        configurable: true
    });
    return CustomHtmlImage;
}());
export default CustomHtmlImage;
