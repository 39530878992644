var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NinePatchPlugin } from '@koreez/phaser3-ninepatch';
import { MultiAtlases } from '../../../assets';
import { gameConfig, Orientation } from '../../../constants/GameConfig';
import GameFacade from '../../../GameFacade';
import { getPlatform } from '../../../utils/CordovaUtils';
import { getAppVersion, getEnv, getMode, getZone } from '../../../utils/Utils';
import ServiceScene from '../../scenes/ServiceScene';
// game configs start
export function setUpDimension() {
    gameConfig.orientation === Orientation.LANDSCAPE
        ? setUpLandscapeDimensions()
        : setUpPortraitDimensions();
}
function setUpLandscapeDimensions() {
    var screenWidth = window.innerWidth / window.devicePixelRatio;
    var screenHeight = window.innerHeight / window.devicePixelRatio;
    var designMultiplier = gameConfig.canvasHeight / gameConfig.canvasWidth;
    var screenMultiplier = screenHeight / screenWidth;
    var difMultiplier = designMultiplier / screenMultiplier;
    if (difMultiplier > 1) {
        gameConfig.canvasWidth *= difMultiplier;
    }
    else {
        gameConfig.canvasHeight /= difMultiplier;
    }
}
function setUpPortraitDimensions() {
    var screenWidth = window.innerWidth / window.devicePixelRatio;
    var screenHeight = window.innerHeight / window.devicePixelRatio;
    var designMultiplier = gameConfig.canvasWidth / gameConfig.canvasHeight;
    var screenMultiplier = screenHeight / screenWidth;
    var difMultiplier = designMultiplier / screenMultiplier;
    if (difMultiplier > 1) {
        gameConfig.canvasHeight *= difMultiplier;
    }
    else {
        gameConfig.canvasWidth /= difMultiplier;
    }
}
export function generateGameConfiguration() {
    switch (getZone()) {
        case 'ok':
        case 'vk':
        case 'web':
            gameConfig.canvasWidth = 1370;
            gameConfig.canvasHeight = 837;
            break;
        default:
            setUpDimension();
            break;
    }
    return {
        type: Phaser.WEBGL,
        width: gameConfig.canvasWidth,
        height: gameConfig.canvasHeight,
        parent: 'game-container',
        title: 'Kings of Durak',
        inputMouse: getPlatform() === 'browser',
        inputMouseCapture: getPlatform() === 'browser',
        transparent: true,
        resolution: window.devicePixelRatio,
        gameURL: 'https://www.kingsofdurak.com',
        gameVersion: getAppVersion(),
        fps: 60,
        roundPixels: false,
        disableContextMenu: getMode().toLowerCase() === 'production' || getPlatform() !== 'browser',
        clearBeforeRender: getPlatform() === 'browser',
        antialias: getPlatform() === 'browser' || gameConfig.resolutionMultiplier < 1,
        antialiasGL: getPlatform() === 'browser' || gameConfig.resolutionMultiplier < 1,
        dom: {
            createContainer: false,
        },
        plugins: {
            global: [
                { key: 'NinePatchPlugin', plugin: NinePatchPlugin, start: true },
            ],
            scene: [
                {
                    key: 'SpinePlugin',
                    plugin: window.SpinePlugin,
                    mapping: 'spine',
                },
            ],
        },
        banner: {
            text: '#ffffff',
            background: ['#fff200', '#38f0e8', '#00bff3', '#ec008c'],
            hidePhaser: false,
        },
        loader: {
            maxParallelDownloads: 3,
        },
    };
}
// game configs end
// getTexture start
export function getKey(key) {
    return getScene(ServiceScene.NAME).textures.get(key);
}
export function getFrame(key, frame) {
    return getScene(ServiceScene.NAME).textures.getFrame(key, frame);
}
// getTexture end
// textures start
export function loadImageFromUrl(scene, key, url, corsEnabled, defaultBase64) {
    if (corsEnabled === void 0) { corsEnabled = true; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    if (!key || !url) {
                        scene.textures.addBase64(key, defaultBase64);
                        scene.textures.on(Phaser.Textures.Events.ADD, resolve);
                    }
                    hasKey(key) && scene.textures.removeKey(key);
                    var headers = new Headers({});
                    var request = new Request(url);
                    var options = {
                        method: 'GET',
                        headers: headers,
                        mode: corsEnabled ? 'cors' : 'no-cors',
                        cache: 'default',
                    };
                    fetch(request, options)
                        .then(function (response) {
                        response
                            .arrayBuffer()
                            .then(function (buffer) {
                            var imageStr = Phaser.Utils.Base64.ArrayBufferToBase64(buffer, 'image/jpeg');
                            return imageStr;
                        })
                            .then(function (base64) {
                            scene.textures.addBase64(key, base64);
                            scene.textures.on(Phaser.Textures.Events.ADD, resolve);
                        });
                    })
                        .catch(function (err) {
                        console.error(err);
                        if (defaultBase64) {
                            hasKey(key) && scene.textures.removeKey(key);
                            scene.textures.addBase64(key, defaultBase64);
                            resolve();
                        }
                        else {
                            resolve();
                        }
                    });
                })];
        });
    });
}
export function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(function (b) { return (binary += String.fromCharCode(b)); });
    return window.btoa(binary);
}
export function hasKey(key) {
    return GameFacade.game.textures.getTextureKeys().contains(key);
}
export function hasFrame(key, frameName) {
    var texture = GameFacade.game.textures
        .list[key];
    var frameNames = texture.getFrameNames();
    return frameNames.contains(frameName);
}
export function removeTexture(key) {
    var serviceScene = getScene(ServiceScene.NAME);
    hasKey(key) && serviceScene.textures.remove(key);
    hasKey(key) && serviceScene.textures.removeKey(key);
}
export function getOpponentPositionX(index) {
    var scene = getScene(ServiceScene.NAME);
    var backgroundDecorationFrame = scene.textures.getFrame(MultiAtlases.GamePlay.Atlas.Name, MultiAtlases.GamePlay.Atlas.Frames.OpponentBackgroundDecoration);
    return (gameConfig.canvasWidth * 0.5 +
        (index - 1) * backgroundDecorationFrame.width * 1.41);
}
// textures end
// runnables start
export function delayRunnable(scene, delay, runnable, context) {
    var args = [];
    for (var _i = 4; _i < arguments.length; _i++) {
        args[_i - 4] = arguments[_i];
    }
    var timerEvent = _addRunnable.apply(void 0, [scene,
        delay,
        runnable,
        context,
        false].concat(args));
    return timerEvent;
}
export function loopRunnable(scene, delay, runnable, context) {
    var args = [];
    for (var _i = 4; _i < arguments.length; _i++) {
        args[_i - 4] = arguments[_i];
    }
    return _addRunnable.apply(void 0, [scene, delay, runnable, context, true].concat(args));
}
export function postRunnable(runnable, context) {
    var args = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args[_i - 2] = arguments[_i];
    }
    var serviceScene = getScene(ServiceScene.NAME);
    return delayRunnable.apply(void 0, [serviceScene,
        serviceScene.game.loop.delta,
        runnable,
        context].concat(args));
}
function _addRunnable(scene, delay, runnable, context, loop) {
    if (loop === void 0) { loop = false; }
    var args = [];
    for (var _i = 5; _i < arguments.length; _i++) {
        args[_i - 5] = arguments[_i];
    }
    return scene.time.addEvent({
        delay: delay,
        callback: runnable,
        callbackScope: context,
        loop: loop,
        args: args,
    });
}
export function removeRunnable(runnable) {
    !!runnable && runnable.destroy();
}
// runnables end
// other utils start
export function getScene(name) {
    return GameFacade.game.scene.getScene(name);
}
export function pickAny(array) {
    return array[Phaser.Math.Between(0, array.length - 1)];
}
// other utils end
export function fromObjectToArgs(object, keys) {
    var currentKeys = Object.keys(object);
    for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        if (currentKeys.contains(key)) {
            currentKeys.remove(key);
            currentKeys.addAt(i, key);
        }
    }
    return keys.map(function (key) { return object[key]; });
}
if (getEnv().MODE === 'development' || getEnv().MODE === 'test') {
    window.enableUiDebug = function () {
        var scenes = GameFacade.game.scene.getScenes(false);
        var _loop_1 = function (scene) {
            scene.input.on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, function (pointer, gameObjects) {
                gameObjects &&
                    gameObjects.length &&
                    console.warn(scene.constructor.name, gameObjects.slice());
            });
        };
        for (var _i = 0, scenes_1 = scenes; _i < scenes_1.length; _i++) {
            var scene = scenes_1[_i];
            _loop_1(scene);
        }
    };
}
