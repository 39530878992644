var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import { setDataToStorage, StorageKey, } from '../../../utils/wrappers/StorageWrapper';
import RemoveSocketCommand from '../../socket/connections/RemoveSocketCommand';
import LogoutFromFacebookCommand from './LogoutFromFacebookCommand';
import LogoutFromNativeCommand from './LogoutFromNativeCommand';
var LogoutCommand = /** @class */ (function (_super) {
    __extends(LogoutCommand, _super);
    function LogoutCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LogoutCommand.prototype.execute = function () {
        setDataToStorage(StorageKey.IS_LOG_OUT, true);
        _super.prototype.execute.call(this);
    };
    LogoutCommand.prototype.initializeMacroCommand = function () {
        this.addSubCommand(RemoveSocketCommand);
        this.addSubCommand(LogoutFromFacebookCommand);
        this.addSubCommand(LogoutFromNativeCommand);
    };
    return LogoutCommand;
}(SyncMacroCommand));
export default LogoutCommand;
