var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseUserInfoStatisticElement from './BaseUserInfoStatisticElement';
var UserInfoStatisticElement = /** @class */ (function (_super) {
    __extends(UserInfoStatisticElement, _super);
    function UserInfoStatisticElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UserInfoStatisticElement.prototype.createContent = function () {
        _super.prototype.createContent.call(this);
        this.setSize(this.nameBackground.width, this.valueBackground.height);
    };
    UserInfoStatisticElement.prototype.positionNameBackground = function () { };
    UserInfoStatisticElement.prototype.positionValueBackground = function () {
        this.valueBackground.x = this.nameBackground.width / 2;
    };
    return UserInfoStatisticElement;
}(BaseUserInfoStatisticElement));
export default UserInfoStatisticElement;
