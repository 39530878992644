var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import SocketVOProxy from '../../model/SocketVOProxy';
import { decrypt } from '../../utils/Crypto';
import IsStateDataValidGuard from '../Guards/state/IsStateDataValidGuard';
import { StateCommand } from './StateCommand';
var ApplyGameResumeDataCommand = /** @class */ (function (_super) {
    __extends(ApplyGameResumeDataCommand, _super);
    function ApplyGameResumeDataCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ApplyGameResumeDataCommand.prototype.prepare = function () {
        this.addGuards(IsStateDataValidGuard);
    };
    ApplyGameResumeDataCommand.prototype.execute = function (notificationName, data) {
        var _a, _b;
        var socketVOProxy = this.facade.retrieveProxy(SocketVOProxy.NAME);
        var finalData = this.proxy.vo;
        data.attackers = data.attackers.map(function (id) {
            return socketVOProxy.getPlayerIndexById(id);
        });
        data.defender = socketVOProxy.getPlayerIndexById(data.defender);
        data.nextUser = socketVOProxy.getPlayerIndexById(data.nextUser);
        // ---- ACTION INDEX ----
        socketVOProxy.vo.actionIndex = data.actionIndex;
        // ---- PLAYERS ----
        while (data.users[3] !== socketVOProxy.vo.userId) {
            data.users.unshift(data.users.pop());
            data.usersInfo.unshift(data.usersInfo.pop());
        }
        finalData.players[3].cards = data.userCards.map(decrypt);
        for (var i = 0; i < 3; i++) {
            finalData.players[i].cards = [];
            for (var ii = 0; ii < data.usersInfo[i].cardsCount; ii++) {
                finalData.players[i].cards.push(null);
            }
        }
        // ---- TURN DATA ----
        finalData.turnData.currentPlayer.timeout = data.timeout;
        finalData.turnData.currentPlayer.attackers = data.attackers;
        finalData.turnData.currentPlayer.defender = data.defender;
        finalData.turnData.currentPlayer.userId = data.nextUser;
        // validatedCards
        (_a = finalData.turnData.validatedCards).push.apply(_a, data.validCards.map(function (value) {
            return {
                card: decrypt(value.card),
                targets: value.targets ? value.targets.map(decrypt) : null,
                canSwap: value.canSwap,
            };
        }));
        // cardPairs
        finalData.turnData.cardPairs = [];
        (_b = finalData.turnData.cardPairs).push.apply(_b, data.attackCards.map(function (pair) {
            return {
                attack: decrypt(pair.card),
                defence: decrypt(pair.defendedBy),
            };
        }));
        // --- DECK DATA ---
        finalData.deckData.trump = decrypt(data.trump);
        finalData.deckData.cardsToDealToPlayers = [[], [], [], []];
        finalData.deckData.isTrumpShown = true;
        finalData.deckData.cardsCount = data.deck;
        var allInGameCardsCount = finalData.deckData.cardsCount;
        for (var _i = 0, _c = finalData.players; _i < _c.length; _i++) {
            var player = _c[_i];
            allInGameCardsCount += player.cards.length;
        }
        for (var _d = 0, _e = finalData.turnData.cardPairs; _d < _e.length; _d++) {
            var pair = _e[_d];
            allInGameCardsCount++;
            !!pair.defence && allInGameCardsCount++;
        }
        finalData.bitaCardsCount = 36 - allInGameCardsCount;
        // --- OTHERS ---
        finalData.positions = data.usersInfo.map(function (value) { return value.position; });
        finalData.reconnected = true;
        finalData.isGameStarted = true;
        this.proxy.initForceData(finalData);
    };
    return ApplyGameResumeDataCommand;
}(StateCommand));
export default ApplyGameResumeDataCommand;
