var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { I18nPlugin } from '@candywings/phaser3-i18n-plugin';
import { Atlases, Fonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { postRunnable } from '../../utils/phaser/PhaserUtils';
import StoreItem from './StoreItem';
var StoreTabContent = /** @class */ (function (_super) {
    __extends(StoreTabContent, _super);
    function StoreTabContent(scene, section) {
        var _this = this;
        var leftBgFrame = scene.textures.getFrame(MultiAtlases.Store.Atlas.Name, MultiAtlases.Store.Atlas.Frames.BgLeft);
        _this = _super.call(this, scene, leftBgFrame.width * 0.1, gameConfig.canvasHeight * 0.65) || this;
        _this.section = section;
        _this.createTitle("store-title-" + _this.section);
        _this.setListeners();
        _this.configs = [];
        _this.items = [];
        return _this;
    }
    StoreTabContent.prototype.preDestroy = function () {
        this.removeListeners();
        _super.prototype.preDestroy.call(this);
    };
    StoreTabContent.prototype.updateData = function (configs) {
        if (!this.items) {
            this.items = [];
        }
        this.configs = configs;
        this.createOrUpdateContent(this.configs);
        this.scene.add.existing(this);
    };
    StoreTabContent.prototype.show = function () {
        return __awaiter(this, void 0, void 0, function () {
            var delay, promises, i, item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        delay = 100;
                        this.scene.tweens.killTweensOf([this.sectionTitle, this.sectionTitleBg]);
                        this.scene.tweens.add({
                            targets: [this.sectionTitle, this.sectionTitleBg],
                            scaleX: 1,
                            scaleY: 1,
                            duration: 300,
                            ease: Phaser.Math.Easing.Back.Out,
                            easeParams: [0.5],
                        });
                        promises = [];
                        for (i = 0; i < this.items.length; i++) {
                            item = this.items[i];
                            promises.push(item.show(i * delay));
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        this.activated = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    StoreTabContent.prototype.hide = function (force) {
        if (force === void 0) { force = false; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var delay, i, item, _i, _a, item, promises, i;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    delay = 50;
                                    for (i = 0; i < this.items.length; i++) {
                                        item = this.items[i];
                                        item.hide(i * delay);
                                    }
                                    this.scene.tweens.killTweensOf([this.sectionTitle, this.sectionTitleBg]);
                                    if (!force) return [3 /*break*/, 1];
                                    this.sectionTitle.setScale(0);
                                    this.sectionTitleBg.setScale(0);
                                    for (_i = 0, _a = this.items; _i < _a.length; _i++) {
                                        item = _a[_i];
                                        item.y = gameConfig.canvasHeight;
                                    }
                                    this.activated = false;
                                    resolve();
                                    return [3 /*break*/, 3];
                                case 1:
                                    this.scene.tweens.add({
                                        targets: [this.sectionTitle, this.sectionTitleBg],
                                        scaleX: 0,
                                        scaleY: 0,
                                        ease: Phaser.Math.Easing.Back.In,
                                        easeParams: [0.5],
                                        duration: 200,
                                    });
                                    promises = [];
                                    for (i = 0; i < this.items.length; i++) {
                                        promises.push(this.items[i].hide(i * 50));
                                    }
                                    return [4 /*yield*/, Promise.all(promises)];
                                case 2:
                                    _b.sent();
                                    this.activated = false;
                                    _b.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    StoreTabContent.prototype.createTitle = function (text) {
        var topBarBgFrame = this.scene.textures.getFrame(Atlases.Topbar.Atlas.Name, Atlases.Topbar.Atlas.Frames.Background);
        var titleFrame = this.scene.textures.getFrame(MultiAtlases.Store.Atlas.Name, MultiAtlases.Store.Atlas.Frames.TitleBackground);
        this.sectionTitleBg = this.scene.make.ninePatch({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames.TitleBackground,
            width: titleFrame.width,
            height: titleFrame.height,
        });
        this.sectionTitleBg.y =
            -this.y + topBarBgFrame.height + this.sectionTitleBg.height * 0.6;
        this.add(this.sectionTitleBg);
        var style = {
            fontSize: 56 * gameConfig.resolutionMultiplier,
            fontFamily: Fonts.RobotoSlab.Name,
            fill: '#ffedcb',
        };
        this.sectionTitle = this.scene.make.extText({
            x: this.sectionTitleBg.x,
            y: this.sectionTitleBg.y,
            text: text,
            style: style,
        });
        this.sectionTitle.setAlign('center');
        this.sectionTitle.setOrigin(0.5, 0.6);
        this.add(this.sectionTitle);
        this.updateSectionTitleBgSize();
    };
    StoreTabContent.prototype.updateSectionTitleBgSize = function () {
        postRunnable(this.sectionTitleBg.resize, this.sectionTitleBg, this.sectionTitle.width + 200, this.sectionTitleBg.height);
    };
    StoreTabContent.prototype.createOrUpdateContent = function (configs) {
        // removing items if needed
        var productIds = configs.map(function (config) { return config.productId; });
        var itemsToRemove = this.items.filter(function (item) { return !productIds.contains(item.config.productId); });
        for (var _i = 0, itemsToRemove_1 = itemsToRemove; _i < itemsToRemove_1.length; _i++) {
            var item = itemsToRemove_1[_i];
            this.items.remove(item);
            item.destroy();
        }
        var _loop_1 = function (config) {
            var storeItem = this_1.items.find(function (item) { return item.config.productId === config.productId; });
            if (!storeItem) {
                storeItem = new StoreItem(this_1.scene, config);
                this_1.add(storeItem);
                this_1.items.push(storeItem);
            }
            else {
                storeItem.updateData(config);
            }
        };
        var this_1 = this;
        // creating items if needed
        for (var _a = 0, configs_1 = configs; _a < configs_1.length; _a++) {
            var config = configs_1[_a];
            _loop_1(config);
        }
        this.positionItems();
        !this.activated && this.hide(true);
    };
    StoreTabContent.prototype.positionItems = function () {
        if (!this.items || !this.items.length) {
            return;
        }
        var gap = gameConfig.designWidth * 0.15;
        var itemWidth = this.items[0].width;
        var startX = -(this.items.length / 2) * (itemWidth + gap);
        var line = new Phaser.Geom.Line(startX, 0, startX + this.items.length * (itemWidth + gap), 0);
        var positions = line.getPoints(this.items.length + 1);
        positions.shift();
        for (var i = 0; i < this.items.length; i++) {
            this.items[i].x = positions[i].x;
            this.items[i].y = 0;
        }
    };
    StoreTabContent.prototype.setListeners = function () {
        this.scene.i18n.on(I18nPlugin.LANGUAGE_CHANGED_EVENT, this.updateSectionTitleBgSize, this);
    };
    StoreTabContent.prototype.removeListeners = function () {
        this.scene.i18n.on(I18nPlugin.LANGUAGE_CHANGED_EVENT, this.updateSectionTitleBgSize, this);
    };
    return StoreTabContent;
}(Phaser.GameObjects.Container));
export default StoreTabContent;
