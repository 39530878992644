var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, Fonts, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import { Translation } from '../../translations';
import MaintenanceMessage from '../components/popup/maintenance/MaintenanceMessage';
import PopupTitle from '../components/popup/PopupTitle';
import SimpleButtonWithBitmapText from '../utils/simpleButton/SimpleButtonWithBitmapText';
import StandardPopup from './StandardPopup';
var MaintenancePopup = /** @class */ (function (_super) {
    __extends(MaintenancePopup, _super);
    function MaintenancePopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MaintenancePopup.prototype.createBody = function () {
        this.createColoredBlocker(0.7);
        this.createBgImage(MultiAtlases.Popups.Atlas.Name, MultiAtlases.Popups.Atlas.Frames.MaintenancePopupBackground);
        this.createTitle();
        this.createIcon();
        this.createMessage();
        this.createOkButton();
    };
    MaintenancePopup.prototype.createTitle = function () {
        var config = {
            frame: MultiAtlases.Popups.Atlas.Frames.SettingsPopupTitle,
            fontFamily: Fonts.RobotoSlab.Name,
            text: Translation.MAINTENANCE_POPUP_TITLE,
            fontSize: 50,
            fill: '#c3a469',
            origin: { x: 0.5, y: 0.6 },
        };
        this.title = new PopupTitle(this.scene, config);
        this.title.y = -this.height * 0.45;
        this.add(this.title);
    };
    MaintenancePopup.prototype.createIcon = function () {
        this.icon = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.MaintenancePopupIcon,
        });
        this.icon.setOrigin(0.5, 0);
        this.icon.y = -this.height * 0.475;
        this.add(this.icon);
    };
    MaintenancePopup.prototype.createMessage = function () {
        this.message = new MaintenanceMessage(this.scene);
        this.message.y = this.height * 0.125;
        this.add(this.message);
    };
    MaintenancePopup.prototype.createOkButton = function () {
        var textConfig = {
            text: Translation.MAINTENANCE_POPUP_OK_BUTTON,
            size: 45 * gameConfig.resolutionMultiplier,
            fill: 0xffffd8,
            font: BitmapFonts.RobotoSlab.Name,
            align: Phaser.GameObjects.BitmapText.ALIGN_CENTER,
            origin: { x: 0.5, y: 0.6 },
        };
        var normalStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SmallPositiveButtonUp,
            textConfig: textConfig,
        };
        var hoverStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SmallPositiveButtonHover,
            textConfig: textConfig,
        };
        var downStateConfig = {
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.SmallPositiveButtonDown,
            textConfig: textConfig,
        };
        var config = {
            normalStateConfig: normalStateConfig,
            hoverStateConfig: hoverStateConfig,
            downStateConfig: downStateConfig,
        };
        this.okButton = new SimpleButtonWithBitmapText(this.scene, config);
        this.add(this.okButton);
        this.okButton.y = this.height * 0.325;
        this.okButton.on(SimpleButtonWithBitmapText.CLICK_EVENT, this.onAction, this);
    };
    MaintenancePopup.NAME = 'MaintenancePopup';
    return MaintenancePopup;
}(StandardPopup));
export default MaintenancePopup;
