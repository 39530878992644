var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Atlases, Audios } from '../../../assets';
import TopBarScene, { TopBarAction, TopBarElement, } from '../../scenes/TopBarScene';
import CurrencyElement from './CurrencyElement';
var GoldElement = /** @class */ (function (_super) {
    __extends(GoldElement, _super);
    function GoldElement(scene) {
        var _this = this;
        var config = {
            iconFrame: Atlases.Topbar.Atlas.Frames.GoldIcon,
            value: 0,
            type: TopBarElement.GOLD,
        };
        _this = _super.call(this, scene, config) || this;
        return _this;
    }
    GoldElement.prototype.onClick = function () {
        this.scene.playSFX(Audios.CurrencyCreditSfx.Name);
        this.scene.events.emit(TopBarScene.ACTION_DONE_EVENT, TopBarAction.GOLD);
    };
    return GoldElement;
}(CurrencyElement));
export default GoldElement;
