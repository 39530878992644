var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//@ts-ignore
import * as SocketCluster from 'socketcluster-es6-client';
import { AllEvents, RoomChannelEmittingEvent, RoomChannelEmittingEventName, SocketHandlingEvent, UserChannelEmittingEvent, UserChannelEmittingEventName, } from '../constants/SocketEvents';
import { encrypt } from '../utils/Crypto';
import { brownLog, getEnv } from '../utils/Utils';
import GameScene from '../view/scenes/GameScene';
import { getScene } from '../view/utils/phaser/PhaserUtils';
import ProxyWithHandler from './base/ProxyWithHandler';
import RoomChannelHandler from './handlers/RoomChannelHandler';
import UserChannelHandler from './handlers/UserChannelHandler';
import SocketVO from './VO/SocketVO';
export var debugSocket = true;
var SocketVOProxy = /** @class */ (function (_super) {
    __extends(SocketVOProxy, _super);
    function SocketVOProxy() {
        var _this = _super.call(this, SocketVOProxy.NAME, new SocketVO()) || this;
        _this.createHandlers();
        return _this;
    }
    SocketVOProxy.prototype.removeConnections = function () {
        if (!this.client) {
            return;
        }
        this.client.off(SocketHandlingEvent.CONNECT, this.onConnectionSuccess.bind(this));
        this.client.off(SocketHandlingEvent.ERROR, this.onConnectionError.bind(this));
        this.client.off(SocketHandlingEvent.SUBSCRIBE, this.onUserChannelSubscribe.bind(this));
        this.client.off(SocketHandlingEvent.SUBSCRIBE, this.onRoomChannelSubscribe.bind(this));
        if (this.userChannel) {
            this.userChannel.unwatch();
            this.userChannel.unsubscribe();
        }
        if (this.roomChannel) {
            this.roomChannel.unwatch();
            this.roomChannel.unsubscribe();
        }
        this.client.destroy();
    };
    SocketVOProxy.prototype.publishNextNotification = function () {
        // this.roomHandler.sendFromQueue();
    };
    SocketVOProxy.prototype.clearData = function () {
        this.vo.players = [];
        this.vo.validatedCards = [];
        this.vo.isReconnect = false;
    };
    SocketVOProxy.prototype.askForState = function () {
        this.sendNotification(SocketVOProxy.RETRIEVING_GAME_STATE_NOTIFICATION);
        // this.roomHandler.clearQueue();
        // this.publishRequestGameState();
    };
    SocketVOProxy.prototype.destroy = function () {
        this.roomHandler = null;
        this.userHandler = null;
        this.client.destroy();
    };
    SocketVOProxy.prototype.init = function (token, setListeners) {
        return __awaiter(this, void 0, void 0, function () {
            var options;
            return __generator(this, function (_a) {
                options = {
                    hostname: getEnv().SOCKET_HOST,
                    port: +getEnv().SOCKET_PORT,
                    secure: getEnv().MODE === 'production' || getEnv().MODE === 'test'
                        ? true
                        : false,
                    rejectUnauthorized: true,
                    query: {
                        token: token,
                    },
                };
                this.client = SocketCluster.connect(options);
                if (setListeners) {
                    this.client.once(SocketHandlingEvent.CONNECT, this.onConnectionSuccess.bind(this));
                    this.client.once(SocketHandlingEvent.ERROR, this.onConnectionError.bind(this));
                    this.client.on(SocketHandlingEvent.DISCONNECT, this.onDisconnect.bind(this));
                }
                return [2 /*return*/];
            });
        });
    };
    SocketVOProxy.prototype.joinUserChannel = function (userId) {
        this.vo.userId = userId;
        this.userChannelClientName = 'user_' + userId + '_client';
        this.userChannelServerName = 'user_' + userId + '_server';
        this.userChannel = this.client.subscribe(this.userChannelClientName);
        this.client.once(SocketHandlingEvent.SUBSCRIBE, this.onUserChannelSubscribe.bind(this));
    };
    SocketVOProxy.prototype.recoverUserConnection = function () {
        this.client.unwatch(this.userHandler.handleEvents.bind(this));
        this.client.unsubscribe(this.userChannelClientName);
        this.userChannel = this.client.subscribe(this.userChannelClientName);
        this.client.once(SocketHandlingEvent.SUBSCRIBE, this.onUserChannelSubscribe.bind(this));
    };
    SocketVOProxy.prototype.joinRoomChannel = function (roomId, isReconnect) {
        if (isReconnect === void 0) { isReconnect = false; }
        if (this.vo.roomId && this.roomChannel) {
            this.roomChannel.unwatch();
            this.roomChannel.unsubscribe();
        }
        this.vo.roomId = roomId;
        this.roomChannelClientName = 'room_' + roomId + '_client';
        this.roomChannelServerName = 'room_' + roomId + '_server';
        this.roomChannel = this.client.subscribe(this.roomChannelClientName);
        this.client.once(SocketHandlingEvent.SUBSCRIBE, this.onRoomChannelSubscribe.bind(this, isReconnect));
    };
    SocketVOProxy.prototype.recoverRoomConnection = function () {
        this.client.unwatch(this.roomHandler.handleEvents.bind(this));
        this.client.unsubscribe(this.roomChannelClientName);
        this.roomChannel = this.client.subscribe(this.roomChannelClientName);
        this.client.once(SocketHandlingEvent.SUBSCRIBE, this.onRoomChannelSubscribe.bind(this));
        this.client.once(SocketHandlingEvent.SUBSCRIBE, this.publishToUserChannel.bind(this, {
            event: UserChannelEmittingEvent.GET_STATE,
            eventName: UserChannelEmittingEventName.GET_STATE,
        }));
    };
    SocketVOProxy.prototype.findRoom = function (roomType) {
        this.clearData();
        this.vo.roomType = roomType;
        this.publishToUserChannel({
            event: UserChannelEmittingEvent.FIND_ROOM,
            eventName: UserChannelEmittingEventName.FIND_ROOM,
            userId: this.vo.userId,
            roomType: roomType,
        });
    };
    SocketVOProxy.prototype.publishRequestGameState = function () {
        var data = {
            roomId: this.vo.roomId,
            userId: this.vo.userId,
            event: UserChannelEmittingEvent.REQUEST_GAME_STATE,
            eventName: UserChannelEmittingEventName.REQUEST_GAME_STATE,
        };
        this.publishToUserChannel(data);
    };
    SocketVOProxy.prototype.publishReplay = function () {
        this.roomHandler.clearQueue();
        var data = {
            event: UserChannelEmittingEvent.REPLAY,
            eventName: UserChannelEmittingEventName.REPLAY,
            roomId: this.vo.roomId,
            userId: this.vo.userId,
        };
        this.publishToUserChannel(data);
    };
    SocketVOProxy.prototype.publishCardPlay = function (card, type, target) {
        console.warn({ card: card, target: target });
        var data = {
            event: RoomChannelEmittingEvent.PLAY,
            eventName: RoomChannelEmittingEventName.PLAY,
            roomId: this.vo.roomId,
            userId: this.vo.userId,
            type: type,
            card: !!card ? encrypt(card) : null,
            target: !!target ? encrypt(target) : null,
        };
        this.publishToRoomChannel(data);
    };
    SocketVOProxy.prototype.publishEmoji = function (type, key) {
        var data = {
            event: RoomChannelEmittingEvent.SEND_EMOJI,
            eventName: RoomChannelEmittingEventName.SEND_EMOJI,
            roomId: this.vo.roomId,
            userId: this.vo.userId,
            type: type,
            key: key,
        };
        this.publishToRoomChannel(data);
    };
    SocketVOProxy.prototype.publishAskForValidCards = function () {
        var data = {
            event: UserChannelEmittingEvent.GIVE_VALID_CARDS,
            eventName: UserChannelEmittingEventName.GIVE_VALID_CARDS,
            userId: this.vo.userId,
            roomId: this.vo.roomId,
        };
        this.publishToUserChannel(data);
    };
    SocketVOProxy.prototype.leaveRoom = function () {
        this.publishToUserChannel({
            event: UserChannelEmittingEvent.LEAVE_USER,
            eventName: UserChannelEmittingEventName.LEAVE_USER,
            userId: this.vo.userId,
            roomId: this.vo.roomId,
        });
    };
    SocketVOProxy.prototype.onConnectionSuccess = function (status) {
        // listening reconnection
        this.sendNotification(SocketVOProxy.CONNECTION_SUCCESS_NOTIFICATION);
    };
    SocketVOProxy.prototype.onUserChannelSubscribe = function () {
        this.userChannel.watch(this.userHandler.handleEvents.bind(this.userHandler));
        this.vo.isDisconnected = false;
        this.startConnectionCheck();
        this.client.on(SocketHandlingEvent.MESSAGE, this.onMessage.bind(this));
        this.publishToUserChannel({
            event: UserChannelEmittingEvent.WELCOME,
            eventName: UserChannelEmittingEventName.WELCOME,
        }, this.onWelcomeCall);
        this.sendNotification(SocketVOProxy.USER_CHANNEL_JOIN_SUCCESS_NOTIFICATION);
    };
    SocketVOProxy.prototype.onRoomChannelSubscribe = function (isReconnect) {
        this.roomChannel.watch(this.roomHandler.handleEvents.bind(this.roomHandler));
        this.client.off(SocketHandlingEvent.SUBSCRIBE, this.onRoomChannelSubscribe.bind(this, isReconnect));
        isReconnect
            ? this.sayHelloReconnect()
            : this.sendNotification(SocketVOProxy.ROOM_CHANNEL_JOIN_SUCCESS_NOTIFICATION);
    };
    SocketVOProxy.prototype.getRoomOpponentsCount = function () {
        switch (this.vo.roomType) {
            case 'big':
            case 'medium':
            case 'small':
                return 3;
            default:
                return this.vo.players ? this.vo.players.length : 0;
        }
    };
    SocketVOProxy.prototype.sayHello = function () {
        this.publishToRoomChannel({
            userId: this.vo.userId,
            roomId: this.vo.roomId,
            event: RoomChannelEmittingEvent.SAY_HELLO,
            eventName: RoomChannelEmittingEventName.SAY_HELLO,
        });
    };
    SocketVOProxy.prototype.sayHelloReconnect = function () {
        this.publishToRoomChannel({
            userId: this.vo.userId,
            roomId: this.vo.roomId,
            event: RoomChannelEmittingEvent.SAY_HELLO_RECONNECT,
            eventName: RoomChannelEmittingEventName.SAY_HELLO_RECONNECT,
        });
    };
    SocketVOProxy.prototype.publishToUserChannel = function (data, callback, context) {
        if (this.vo.isDisconnected) {
            return;
        }
        this.client.publish(this.userChannelServerName, data, callback && callback.bind(context || this));
        if (debugSocket) {
            brownLog("UserChannelEmittingEvent." + AllEvents[data.event] + " = " + data.event, data);
        }
    };
    SocketVOProxy.prototype.publishToRoomChannel = function (data, callback, context) {
        if (this.vo.isDisconnected) {
            return;
        }
        this.client.publish(this.roomChannelServerName, data, callback && callback.bind(context || this));
        if (debugSocket) {
            brownLog("RoomChannelEmittingEvent." + AllEvents[data.event] + " = " + data.event, data);
        }
    };
    SocketVOProxy.prototype.onDisconnect = function () {
        var gameScene = getScene(GameScene.NAME);
        if (gameScene && !gameScene.sys.isSleeping()) {
            this.vo.isDisconnected = true;
            this.sendNotification(SocketVOProxy.DISCONNECT_NOTIFICATION);
        }
    };
    SocketVOProxy.prototype.onConnectionError = function () {
        this.sendNotification(SocketVOProxy.CONNECTION_FAILED_NOTIFICATION, false);
    };
    // calls callbacks
    SocketVOProxy.prototype.onWelcomeCall = function (nullData, data) {
        if (data.userId === this.vo.userId) {
            this.sendNotification(SocketVOProxy.USER_CHANNEL_WELCOME_COMPLETED_NOTIFICATION);
        }
        else {
            this.sendNotification(SocketVOProxy.USER_CHANNEL_WELCOME_FAILED_NOTIFICATION);
        }
    };
    SocketVOProxy.prototype.getPlayerIndexById = function (id) {
        return this.vo.players.findIndex(function (user) { return user.userId === id; });
    };
    SocketVOProxy.prototype.disconnectFromRoom = function () {
        if (!this.roomChannel) {
            return;
        }
        this.roomChannel.unwatch(this.roomHandler.handleEvents.bind(this.roomHandler));
        this.roomChannel.unsubscribe();
        this.roomChannel = null;
    };
    SocketVOProxy.prototype.createHandlers = function () {
        this.roomHandler = new RoomChannelHandler(this);
        this.userHandler = new UserChannelHandler(this);
    };
    SocketVOProxy.prototype.onMessage = function () {
        var _this = this;
        this.vo.lastMessageMoment = Date.now();
        if (this.vo.isDisconnected) {
            this.vo.isDisconnected = false;
            setTimeout(function () {
                _this.sendNotification(SocketVOProxy.RECONNECTED_NOTIFICATION);
            }, 100);
        }
    };
    SocketVOProxy.prototype.startConnectionCheck = function () {
        this.vo.isDisconnected = false;
        this.vo.lastMessageMoment = Date.now();
        setInterval(this.checkConnectionLost.bind(this), 3000);
    };
    SocketVOProxy.prototype.checkConnectionLost = function () {
        if (Date.now() - this.vo.lastMessageMoment > 7000 &&
            !this.vo.isDisconnected) {
            this.vo.isDisconnected = true;
            this.sendNotification(SocketVOProxy.DISCONNECT_NOTIFICATION);
        }
    };
    SocketVOProxy.NAME = 'SocketVOProxy';
    SocketVOProxy.CONNECTION_SUCCESS_NOTIFICATION = SocketVOProxy.NAME + "ConnectionSuccessNotification";
    SocketVOProxy.DISCONNECT_NOTIFICATION = SocketVOProxy.NAME + "DisconnectNotification";
    SocketVOProxy.CONNECTION_FAILED_NOTIFICATION = SocketVOProxy.NAME + "ConnectionFailedNotification";
    SocketVOProxy.ERROR_NOTIFICATION = SocketVOProxy.NAME + "ErrorNotification";
    SocketVOProxy.USER_CHANNEL_JOIN_SUCCESS_NOTIFICATION = SocketVOProxy.NAME + "UserChannelSuccessNotification";
    SocketVOProxy.ROOM_CHANNEL_JOIN_SUCCESS_NOTIFICATION = SocketVOProxy.NAME + "RoomChannelSuccessNotification";
    SocketVOProxy.USER_CHANNEL_WELCOME_COMPLETED_NOTIFICATION = SocketVOProxy.NAME + "UserChannelWelcomeCompletedNotification";
    SocketVOProxy.LOGIN_FROM_OTHER_DEVICE_NOTIFICATION = SocketVOProxy.NAME + "LoginFromOtherDeviceNotification";
    SocketVOProxy.USER_CHANNEL_WELCOME_USER_COME_BACK_NOTIFICATION = SocketVOProxy.NAME + "UserChannelWelcomeUserComeBackNotification";
    SocketVOProxy.USER_CHANNEL_WELCOME_FAILED_NOTIFICATION = SocketVOProxy.NAME + "UserChannelWelcomeFailedNotification";
    SocketVOProxy.USER_CHANNEL_JOIN_FAILED_NOTIFICATION = SocketVOProxy.NAME + "UserChannelFailedNotification";
    SocketVOProxy.FIND_ROOM_ALLOWED_NOTIFICATION = SocketVOProxy.NAME + "FindRoomAllowedNotification";
    SocketVOProxy.READY_TO_JOIN_ROOM_NOTIFICATION = SocketVOProxy.NAME + "ReadyToJoinRoomNotification";
    SocketVOProxy.FIND_ROOM_DENIED_NOTIFICATION = SocketVOProxy.NAME + "FindRoomDeniedNotification";
    SocketVOProxy.RECONNECTED_NOTIFICATION = SocketVOProxy.NAME + "ReconnectedNotification";
    SocketVOProxy.RECONNECT_GAME_DATA_RECEIVED_NOTIFICATION = SocketVOProxy.NAME + "ReconnectGameDataReceivedNotification";
    SocketVOProxy.ROOM_USERS_INFO_READY_NOTIFICATION = SocketVOProxy.NAME + "RoomUsersInfoReadyNotification";
    SocketVOProxy.PREPARE_DECK_NOTIFICATION = SocketVOProxy.NAME + "PrepareDeckNotification";
    SocketVOProxy.ACTIVATE_NEXT_USER_NOTIFICATION = SocketVOProxy.NAME + "ActivateNextUserNotification";
    SocketVOProxy.SET_PLAYER_CARDS_TO_DEAL_NOTIFICATION = SocketVOProxy.NAME + "SetPlayerCardsToDealNotification";
    SocketVOProxy.SET_OPPONENTS_CARDS_TO_DEAL_NOTIFICATION = SocketVOProxy.NAME + "SetOpponentsCardsToDealNotification";
    SocketVOProxy.CARDS_VALIDATED_NOTIFICATION = SocketVOProxy.NAME + "CardsValidatedNotification";
    SocketVOProxy.CARD_PLAYED_NOTIFICATION = SocketVOProxy.NAME + "CardPlayedNotification";
    SocketVOProxy.TURN_PASSED_NOTIFICATION = SocketVOProxy.NAME + "TurnPassedNotification";
    SocketVOProxy.PLAYER_TAKES_NOTIFICATION = SocketVOProxy.NAME + "PlayerTakesNotification";
    SocketVOProxy.ROUND_ENDED_NOTIFICATION = SocketVOProxy.NAME + "RoundEndedNotification";
    SocketVOProxy.PLAYER_ENDED_THE_GAME_NOTIFICATION = SocketVOProxy.NAME + "PLayerEndedTheGameNotification";
    SocketVOProxy.CLAIM_GAME_PLAY_PRICE_NOTIFICATION = SocketVOProxy.NAME + "ClaimGamePlayPriceNotification";
    SocketVOProxy.PLAYER_LEFT_ROOM_NOTIFICATION = SocketVOProxy.NAME + "PlayerLeftRoomNotification";
    SocketVOProxy.USER_LEFT_ROOM_NOTIFICATION = SocketVOProxy.NAME + "UserLeftRoomNotification";
    SocketVOProxy.CHARGE_USER_NOTIFICATION = SocketVOProxy.NAME + "ChargeUserNotification";
    SocketVOProxy.RETRIEVING_GAME_STATE_NOTIFICATION = SocketVOProxy.NAME + "RetrievingGameStateNotification";
    SocketVOProxy.GAME_ENDED_NOTIFICATION = SocketVOProxy.NAME + "GameEndedNotification";
    SocketVOProxy.EMOJI_PUBLISHED_NOTIFICATION = SocketVOProxy.NAME + "EmojiPublishedNotification";
    SocketVOProxy.GAME_STATE_RESPONSE_RECEIVED_NOTIFICATION = SocketVOProxy.NAME + "GameStateRecievedNotification";
    SocketVOProxy.INVALID_ACTION_NOTIFICATION = SocketVOProxy.NAME + "InvalidActionNotification";
    return SocketVOProxy;
}(ProxyWithHandler));
export default SocketVOProxy;
