var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var UserInfoLevel = /** @class */ (function (_super) {
    __extends(UserInfoLevel, _super);
    function UserInfoLevel(scene, level) {
        var _this = _super.call(this, scene) || this;
        _this.level = level;
        _this.createBody();
        return _this;
    }
    UserInfoLevel.prototype.createBody = function () {
        this.createBackground();
        this.createText();
    };
    UserInfoLevel.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.UserInfoPopupLevelBackground,
        });
        this.add(this.background);
    };
    UserInfoLevel.prototype.createText = function () {
        var style = {
            fontFamily: Fonts.ArialBlack.Name,
            fontSize: 48 * gameConfig.resolutionMultiplier,
            fill: '#a87716',
        };
        this.text = this.scene.make.text({
            x: 0,
            y: 0,
            text: "" + this.level,
            style: style,
        });
        this.text.setOrigin(0.5);
        this.text.setShadow(3, 3, '#000000', 5);
        this.add(this.text);
        this.text.width > this.background.width * 0.9 &&
            this.text.setScale((this.background.width * 0.9) / this.text.width);
    };
    return UserInfoLevel;
}(Phaser.GameObjects.Container));
export default UserInfoLevel;
