var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Locales } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { Translation } from '../../../translations';
import LanguageButton from './LanguageButton';
import RegistrationProfileLanguageSectionDecoration from './RegistrationProfileLanguageSectionDecoration';
var RegistrationProfileLanguageSection = /** @class */ (function (_super) {
    __extends(RegistrationProfileLanguageSection, _super);
    function RegistrationProfileLanguageSection(scene) {
        var _this = _super.call(this, scene, gameConfig.canvasWidth / 2, gameConfig.canvasHeight * 0.475) || this;
        _this.scene = scene;
        _this.scene.add.existing(_this);
        _this.init();
        _this.createBody();
        _this.prepare();
        return _this;
    }
    RegistrationProfileLanguageSection.prototype.show = function () {
        this.decoration.show();
        this.decoration.once(RegistrationProfileLanguageSectionDecoration.SHOW_FIRST_PART_COMPLETE_EVENT, this.showButtons, this);
    };
    RegistrationProfileLanguageSection.prototype.hide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.decoration.hide();
                        _this.scene.tweens.add({
                            targets: _this.languageButtons,
                            scaleX: 0,
                            scaleY: 0,
                            duration: 300,
                            delay: function (target) {
                                _this.languageButtons.indexOf(target) * 100;
                            },
                            ease: Phaser.Math.Easing.Back.In,
                            onComplete: resolve,
                        });
                    })];
            });
        });
    };
    RegistrationProfileLanguageSection.prototype.showButtons = function (rusY, engY) {
        var _this = this;
        this.rusButton.y = rusY;
        this.engButton.y = engY;
        this.scene.tweens.add({
            targets: this.languageButtons,
            scaleX: 1,
            duration: 200,
            onComplete: function () {
                for (var _i = 0, _a = _this.languageButtons; _i < _a.length; _i++) {
                    var button = _a[_i];
                    button.showText();
                }
            },
        });
    };
    RegistrationProfileLanguageSection.prototype.createBody = function () {
        this.createDecoration();
        this.createRussianButton();
        this.createEnglishButton();
    };
    RegistrationProfileLanguageSection.prototype.init = function () {
        this.languageButtons = [];
    };
    RegistrationProfileLanguageSection.prototype.createDecoration = function () {
        this.decoration = new RegistrationProfileLanguageSectionDecoration(this.scene);
        this.add(this.decoration);
    };
    RegistrationProfileLanguageSection.prototype.createRussianButton = function () {
        this.rusButton = new LanguageButton(this.scene, Translation.FINALIZE_PROFILE_SCENE_RUSSIAN, Locales.Ru.Name);
        this.rusButton.y = -this.rusButton.width * 0.55;
        this.add(this.rusButton);
        this.languageButtons.push(this.rusButton);
    };
    RegistrationProfileLanguageSection.prototype.createEnglishButton = function () {
        this.engButton = new LanguageButton(this.scene, Translation.FINALIZE_PROFILE_SCENE_ENGLISH, Locales.En.Name);
        this.engButton.y = this.engButton.width * 0.55;
        this.add(this.engButton);
        this.languageButtons.push(this.engButton);
    };
    RegistrationProfileLanguageSection.prototype.prepare = function () {
        for (var _i = 0, _a = this.languageButtons; _i < _a.length; _i++) {
            var button = _a[_i];
            button.setScale(0, 1);
        }
    };
    return RegistrationProfileLanguageSection;
}(Phaser
    .GameObjects.Container));
export default RegistrationProfileLanguageSection;
