var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import PlayerVO from './PlayerVO';
var ApiVO = /** @class */ (function (_super) {
    __extends(ApiVO, _super);
    function ApiVO() {
        var _this = _super.call(this) || this;
        _this.levelUpData = [];
        return _this;
    }
    return ApiVO;
}(PlayerVO));
export default ApiVO;
export var RewardName;
(function (RewardName) {
    RewardName[RewardName["GameEnd"] = 0] = "GameEnd";
    RewardName[RewardName["Bonus"] = 1] = "Bonus";
    RewardName[RewardName["Level_Up"] = 2] = "Level_Up";
    RewardName[RewardName["Wheel"] = 3] = "Wheel";
})(RewardName || (RewardName = {}));
export var RewardType;
(function (RewardType) {
    RewardType["GOLD"] = "gold";
    RewardType["SILVER"] = "silver";
    RewardType["CRYSTAL"] = "crystal";
    RewardType["STAR"] = "star";
    RewardType["XP"] = "xp";
    RewardType["X2"] = "x2";
    RewardType["X3"] = "x3";
    RewardType["X4"] = "x4";
})(RewardType || (RewardType = {}));
