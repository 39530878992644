var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Proxy } from '@candywings/pure-mvc';
import UiStateVO from './VO/UiStateVO';
var UiStateVOProxy = /** @class */ (function (_super) {
    __extends(UiStateVOProxy, _super);
    function UiStateVOProxy() {
        return _super.call(this, UiStateVOProxy.NAME, new UiStateVO()) || this;
    }
    UiStateVOProxy.prototype.onRegister = function () {
        _super.prototype.onRegister.call(this);
        this.sendNotification(UiStateVOProxy.REGISTERED_NOTIFICATION);
    };
    UiStateVOProxy.prototype.setCurrentScene = function (sceneName) {
        this.vo.previousScene = this.vo.currentScene
            ? this.vo.currentScene
            : sceneName;
        this.vo.currentScene = sceneName;
    };
    UiStateVOProxy.prototype.setCurrentNavigationScene = function (sceneName) {
        this.vo.navigationScene = sceneName;
    };
    UiStateVOProxy.NAME = 'UiVOProxy';
    UiStateVOProxy.REGISTERED_NOTIFICATION = UiStateVOProxy.NAME + "RegisteredNotification";
    return UiStateVOProxy;
}(Proxy));
export default UiStateVOProxy;
