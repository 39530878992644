var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import BaseTextField from '../../utils/dom/textField/BaseTextField';
import { postRunnable } from '../../utils/phaser/PhaserUtils';
var RegistrationNameTextField = /** @class */ (function (_super) {
    __extends(RegistrationNameTextField, _super);
    function RegistrationNameTextField(scene, defaultValue) {
        var _this = _super.call(this, scene) || this;
        _this.domInputElement.maxLength = 8;
        if (defaultValue) {
            defaultValue =
                defaultValue.length > _this.domInputElement.maxLength
                    ? defaultValue.substring(0, _this.domInputElement.maxLength)
                    : defaultValue;
            _this.text.setText(defaultValue);
            _this.domInputElement.value = defaultValue;
            _this.updateCursorPosition();
        }
        return _this;
    }
    RegistrationNameTextField.prototype.onInputTextChange = function () {
        this.text.setText(this.textContent);
        this.updateCursorPosition();
    };
    RegistrationNameTextField.prototype.getValue = function () {
        return this.text.text;
    };
    RegistrationNameTextField.prototype.focus = function () {
        _super.prototype.focus.call(this);
        this.startCursorBlinking();
        postRunnable(this.setBlurListener, this);
    };
    RegistrationNameTextField.prototype.blur = function () {
        _super.prototype.blur.call(this);
        this.stopCursorBlinking();
        postRunnable(this.setFocusListener, this);
    };
    RegistrationNameTextField.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, gameConfig.designHeight * 0.1);
        this.createText();
        this.createCursor();
        _super.prototype.createBody.call(this);
    };
    RegistrationNameTextField.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: gameConfig.designHeight * 0.05,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.TextUnderscore,
        });
        this.add(this.background);
    };
    RegistrationNameTextField.prototype.createText = function () {
        this.text = this.scene.make.bitmapText({
            x: -this.background.width / 2,
            y: this.background.y / 2,
            font: BitmapFonts.RobotoSlab.Name,
            size: 48 * gameConfig.resolutionMultiplier,
        });
        this.text.setOrigin(0, 0.5);
        this.text.setTint(0x0cc8c8);
        this.add(this.text);
    };
    RegistrationNameTextField.prototype.createCursor = function () {
        this.cursor = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Login.Atlas.Name,
            frame: MultiAtlases.Login.Atlas.Frames.TextCursor,
        });
        this.add(this.cursor);
        this.cursor.setAlpha(0);
        this.updateCursorPosition();
    };
    RegistrationNameTextField.prototype.startCursorBlinking = function () {
        var _this = this;
        this.scene.tweens.killTweensOf(this.cursor);
        this.scene.tweens.add({
            targets: this.cursor,
            alpha: 1,
            duration: 300,
            onComplete: function () {
                _this.scene.tweens.add({
                    targets: _this.cursor,
                    alpha: 0.5,
                    yoyo: true,
                    repeat: -1,
                    duration: 500,
                });
            },
        });
    };
    RegistrationNameTextField.prototype.stopCursorBlinking = function () {
        this.scene.tweens.killTweensOf(this.cursor);
        this.scene.tweens.add({
            targets: this.cursor,
            alpha: 0,
            duration: 200,
        });
    };
    RegistrationNameTextField.prototype.updateCursorPosition = function () {
        this.cursor.x = this.text.x + this.text.width * (1 - this.text.originX);
        this.cursor.y = this.text.y;
    };
    RegistrationNameTextField.prototype.setListeners = function () {
        _super.prototype.setListeners.call(this);
        this.setFocusListener();
    };
    RegistrationNameTextField.prototype.onInput = function () {
        if (this.isEnabled) {
            this.domInputElement.value = this.domInputElement.value.replace(' ', '');
            this.textContent = this.domInputElement.value;
            this.onInputTextChange();
        }
    };
    RegistrationNameTextField.prototype.setFocusListener = function () {
        this.off(Phaser.Input.Events.POINTER_UP, this.focus, this);
        this.once(Phaser.Input.Events.POINTER_UP, this.focus, this);
    };
    RegistrationNameTextField.prototype.setBlurListener = function () {
        this.scene.input.off(Phaser.Input.Events.POINTER_UP, this.blur, this);
        this.scene.input.once(Phaser.Input.Events.POINTER_UP, this.blur, this);
    };
    return RegistrationNameTextField;
}(BaseTextField));
export default RegistrationNameTextField;
