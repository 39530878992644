var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
var DeckCardsCount = /** @class */ (function (_super) {
    __extends(DeckCardsCount, _super);
    function DeckCardsCount(scene) {
        var _this = _super.call(this, scene) || this;
        _this.createBody();
        _this.setScale(0);
        return _this;
    }
    DeckCardsCount.prototype.show = function (count) {
        this.scene.tweens.add({
            targets: this,
            scaleX: 1,
            scaleY: 1,
            duration: 200,
            ease: Phaser.Math.Easing.Back.Out,
            onComplete: this.setCount,
            onCompleteScope: this,
            onCompleteParams: [count],
        });
    };
    DeckCardsCount.prototype.hide = function () {
        this.scene.tweens.add({
            targets: this,
            scaleX: 0,
            scaleY: 0,
            duration: 200,
            ease: Phaser.Math.Easing.Back.In,
        });
    };
    DeckCardsCount.prototype.setCount = function (tween, targets, count) {
        var _this = this;
        this.scene.tweens.add({
            targets: this.text,
            scaleX: 1.1,
            scaleY: 1.1,
            yoyo: true,
            duration: 150,
            onYoyo: function () {
                _this.text.setText("" + count);
            },
        });
    };
    DeckCardsCount.prototype.createBody = function () {
        this.createBackground();
        this.setSize(this.background.width, this.background.height);
        this.createText();
    };
    DeckCardsCount.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames.DeckCountBackground,
        });
        this.add(this.background);
    };
    DeckCardsCount.prototype.createText = function () {
        this.text = this.scene.make.bitmapText({
            x: 0,
            y: 0,
            text: '',
            font: BitmapFonts.RobotoSlab.Name,
            size: 48 * gameConfig.resolutionMultiplier,
        });
        this.text.setOrigin(0.5);
        this.text.setTint(0xa87716);
        this.add(this.text);
    };
    return DeckCardsCount;
}(Phaser.GameObjects.Container));
export default DeckCardsCount;
