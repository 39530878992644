var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
var StoreItemTitle = /** @class */ (function (_super) {
    __extends(StoreItemTitle, _super);
    function StoreItemTitle(scene, textContent, x, y) {
        var _this = _super.call(this, scene, x, y) || this;
        _this.scene = scene;
        _this.textContent = textContent;
        _this.createBody();
        return _this;
    }
    StoreItemTitle.prototype.setText = function (text) {
        this.text.setText(text);
    };
    StoreItemTitle.prototype.createBody = function () {
        this.createBackground();
        this.createText();
        this.setSize(this.background.width, this.background.height);
    };
    StoreItemTitle.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Store.Atlas.Name,
            frame: MultiAtlases.Store.Atlas.Frames.SmallTitleBackground,
        });
        this.add(this.background);
    };
    StoreItemTitle.prototype.createText = function () {
        this.text = this.scene.make.extBitmapText({
            x: 0,
            y: 0,
            text: this.textContent,
            font: BitmapFonts.RobotoSlab.Name,
            size: 40 * gameConfig.resolutionMultiplier,
        });
        this.text.setOrigin(0.5);
        this.text.setCenterAlign();
        this.add(this.text);
    };
    return StoreItemTitle;
}(Phaser.GameObjects.Container));
export default StoreItemTitle;
