var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { gameConfig } from '../../constants/GameConfig';
import ApiVOProxy from '../../model/ApiVOProxy';
import PlayerVOProxy from '../../model/PlayerVOProxy';
import LevelUpPopup from './LevelUpPopup';
import StandardPopupMediator from './StandardPopupMediator';
import { isNativeAvatar, getNativeAvatarKey } from '../../utils/Utils';
import { Images } from '../../assets';
import PopupScene from '../scenes/PopupScene';
import GameFacade from '../../GameFacade';
import { hasKey } from '../utils/phaser/PhaserUtils';
var LevelUpPopupMediator = /** @class */ (function (_super) {
    __extends(LevelUpPopupMediator, _super);
    function LevelUpPopupMediator() {
        return _super.call(this, LevelUpPopupMediator.NAME) || this;
    }
    LevelUpPopupMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(ApiVOProxy.USER_LEVEL_UP_NOTIFICATION);
    };
    LevelUpPopupMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        switch (notificationName) {
            case ApiVOProxy.USER_LEVEL_UP_NOTIFICATION:
                {
                    var levelUpData = this.proxy.vo.levelUpData[0];
                    this.showView(gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.5, levelUpData.level, this.getAvatarKey());
                }
                break;
            default:
                break;
        }
    };
    LevelUpPopupMediator.prototype.getAvatarKey = function () {
        var playerVOProxy = this.facade.retrieveProxy(PlayerVOProxy.NAME);
        if (!playerVOProxy.vo.avatarUrl) {
            return Images.Avatar.Name;
        }
        else if (isNativeAvatar(playerVOProxy.vo.avatarUrl)) {
            return getNativeAvatarKey(playerVOProxy.vo.avatarUrl);
        }
        else if (!hasKey('playerDefaultAvatar')) {
            return Images.Avatar.Name;
        }
        else {
            return 'playerDefaultAvatar';
        }
    };
    LevelUpPopupMediator.prototype.createView = function () {
        _super.prototype.createView.call(this, new LevelUpPopup());
    };
    LevelUpPopupMediator.prototype.onViewShowComplete = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        _super.prototype.onViewShowComplete.call(this);
        this.bringToTopRequest();
    };
    LevelUpPopupMediator.prototype.onViewHideComplete = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        _super.prototype.onViewHideComplete.apply(this, args);
        this.sendNotification(LevelUpPopup.GET_REWARD_CLICKED_NOTIFICATION);
    };
    LevelUpPopupMediator.prototype.registerEvents = function () {
        _super.prototype.registerEvents.call(this);
        this.viewComponent.on('bringMeToTop', this.bringToTopRequest);
    };
    LevelUpPopupMediator.prototype.bringToTopRequest = function () {
        GameFacade.game.scene.bringToTop(PopupScene.NAME);
    };
    Object.defineProperty(LevelUpPopupMediator.prototype, "proxy", {
        get: function () {
            return this.facade.retrieveProxy(ApiVOProxy.NAME);
        },
        enumerable: true,
        configurable: true
    });
    LevelUpPopupMediator.NAME = 'LevelUpPopupMediator';
    return LevelUpPopupMediator;
}(StandardPopupMediator));
export default LevelUpPopupMediator;
