var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BitmapFonts, MultiAtlases } from '../../../../assets';
import { gameConfig } from '../../../../constants/GameConfig';
import { Translation } from '../../../../translations';
var MaintenanceMessage = /** @class */ (function (_super) {
    __extends(MaintenanceMessage, _super);
    function MaintenanceMessage(scene) {
        var _this = _super.call(this, scene) || this;
        _this.scene = scene;
        _this.createBody();
        return _this;
    }
    MaintenanceMessage.prototype.createBody = function () {
        this.createBackground();
        this.createText();
    };
    MaintenanceMessage.prototype.createText = function () {
        this.text = this.scene.make.extBitmapText({
            x: 0,
            y: 0,
            text: Translation.MAINTENANCE_POPUP_MESSAGE,
            font: BitmapFonts.RobotoSlab.Name,
            size: 36 * gameConfig.resolutionMultiplier,
        });
        this.text.setTint(0xc3a55f);
        this.text.setCenterAlign();
        this.text.setOrigin(0.5);
        this.add(this.text);
        // this.text.setScale(Math.min(1, this.background.height / this.text.height));
    };
    MaintenanceMessage.prototype.createBackground = function () {
        this.background = this.scene.make.image({
            x: 0,
            y: 0,
            key: MultiAtlases.Popups.Atlas.Name,
            frame: MultiAtlases.Popups.Atlas.Frames.MaintenancePopupTextBackground,
        });
        this.add(this.background);
        this.background.setTint(0x000000);
        this.background.setAlpha(0.9);
    };
    return MaintenanceMessage;
}(Phaser.GameObjects.Container));
export default MaintenanceMessage;
