var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SyncMacroCommand } from '@candywings/pure-mvc';
import GameFacade from '../../../GameFacade';
import { Gender } from '../../../model/VO/PlayerVO';
import { getZone } from '../../../utils/Utils';
import OkInstantWrapper from '../../../utils/wrappers/OkInstantWrapper';
import IsOkGuard from '../../Guards/device/IsOkGuard';
var OkCheckForLoginCommand = /** @class */ (function (_super) {
    __extends(OkCheckForLoginCommand, _super);
    function OkCheckForLoginCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OkCheckForLoginCommand.prototype.prepare = function () {
        this.addGuards(IsOkGuard);
    };
    OkCheckForLoginCommand.prototype.execute = function () {
        var _this = this;
        OkInstantWrapper.authenticate()
            .then(function (data) {
            console.warn({ data: data });
            var userData = {
                uid: data.uid,
                avatarUrl: data.pic128x128,
                socialAvatarUrl: data.pic128x128,
                name: data.first_name,
                gender: Gender.MALE,
            };
            _this.sendNotification(GameFacade.LOGIN_SERVICE_SIGH_IN_SUCCESS_NOTIFICATION, getZone(), userData);
        })
            .catch(function (reason) {
            _this.sendNotification(GameFacade.LOGIN_SERVICE_SIGH_IN_FAIL_NOTIFICATION, reason);
        });
    };
    return OkCheckForLoginCommand;
}(SyncMacroCommand));
export default OkCheckForLoginCommand;
