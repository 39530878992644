var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { sumArrayValues } from '../../../../utils/Utils';
var PathFollowerImage = /** @class */ (function (_super) {
    __extends(PathFollowerImage, _super);
    function PathFollowerImage(scene, path, x, y, key, frame) {
        var _this = _super.call(this, scene, x, y, key, frame) || this;
        _this.path = path;
        return _this;
    }
    PathFollowerImage.prototype.destroy = function (fromScene) {
        if (fromScene === void 0) { fromScene = false; }
        this.stopFollow();
        _super.prototype.destroy.call(this, fromScene);
    };
    PathFollowerImage.prototype.startFollow = function (config, path) {
        var _this = this;
        if (config === void 0) { config = {
            duration: 1000,
            delay: 0,
            rotateToPath: false,
            positionOnPath: true,
            from: 0,
            to: 1,
            repeat: 0,
        }; }
        if (!this.scene || !this.active) {
            return;
        }
        this.pathConfig = config;
        this.pointIndex = this.pathConfig.from
            ? Math.max(Math.round(this.pathConfig.from * this.points.length), 0)
            : 0;
        this.path = path ? path : this.path;
        if (!this.path) {
            throw 'path is missing';
        }
        var length = sumArrayValues.apply(void 0, this.path.getCurveLengths());
        this.points = this.path.getSpacedPoints(length);
        var endIndex = this.pathConfig.to
            ? Math.min(Math.round(this.pathConfig.to * this.points.length), this.points.length - 1)
            : this.points.length - 1;
        this.pathTween = this.scene.tweens.add({
            targets: this,
            pointIndex: endIndex,
            onStart: function () {
                if (_this.pathConfig.positionOnPath) {
                    var index = Math.floor(_this.pointIndex);
                    _this.x = _this.points[index] ? _this.points[index].x : _this.x;
                    _this.y = _this.points[index] ? _this.points[index].y : _this.y;
                }
            },
            onUpdate: function () {
                var _a;
                var index = Math.floor(_this.pointIndex);
                _this.x = _this.points[index] ? _this.points[index].x : _this.x;
                _this.y = _this.points[index] ? _this.points[index].y : _this.y;
                if (_this.pathConfig.rotateToPath) {
                    var prevPoint = _this.points[index - 1] || _this.points[index];
                    var nextPoint = _this.points[index - 1]
                        ? _this.points[index]
                        : _this.points[index + 1];
                    _this.rotation = Phaser.Math.Angle.BetweenPoints(new Phaser.Geom.Point(prevPoint ? prevPoint.x : 0, prevPoint ? prevPoint.y : 0), new Phaser.Geom.Point(nextPoint ? nextPoint.x : 0, nextPoint ? nextPoint.y : 0));
                }
                _this.pathConfig.onUpdate && (_a = _this.pathConfig.onUpdate).bind.apply(_a, [_this.pathConfig.onUpdateScope].concat(_this.pathConfig.onUpdateParams));
            },
            onComplete: this.onComplete,
            duration: this.pathConfig.duration,
            delay: this.pathConfig.delay,
            repeat: this.pathConfig.repeat,
            repeatDelay: this.pathConfig.repeatDelay,
            yoyo: this.pathConfig.yoyo,
            ease: this.pathConfig.ease,
            easeParams: this.pathConfig.easeParams,
            onRepeat: this.pathConfig.onRepeat,
            onRepeatScope: this.pathConfig.onRepeatScope,
            onRepeatParams: this.pathConfig.onRepeatParams,
        });
    };
    PathFollowerImage.prototype.stopFollow = function () {
        if (!this.scene || !this.active) {
            return;
        }
        this.pathTween && this.pathTween.stop();
    };
    PathFollowerImage.prototype.onComplete = function () {
        //
    };
    Object.defineProperty(PathFollowerImage.prototype, "progress", {
        get: function () {
            return this.points ? this.pointIndex / this.points.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    return PathFollowerImage;
}(Phaser.GameObjects.Image));
export default PathFollowerImage;
