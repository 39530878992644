var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BackToGamePopupMediator from '../popups/BackToGamePopupMediator';
import ConnectionLostPopupMediator from '../popups/ConnectionLostPopupMediator';
import ExitConfirmationPopupMediator from '../popups/ExitConfirmationPopupMediator';
import GameEndPopupMediator from '../popups/GameEndPopupMediator';
import InsufficientFundsPopupMediator from '../popups/InsufficientFundsPopupMediator';
import LevelUpPopupMediator from '../popups/LevelUpPopupMediator';
import LogoutConfirmationPopupMediator from '../popups/LogoutConfirmationPopupMediator';
import MaintenancePopupMediator from '../popups/MaintenancePopupMediator';
import ReplayPopupMediator from '../popups/ReplayPopupMediator';
import RewardPopupMediator from '../popups/RewardPopupMediator';
import SettingsPopupMediator from '../popups/SettingsPopupMediator';
import UserInfoPopupMediator from '../popups/UserInfoPopupMediator';
import WaitOrLeavePopupMediator from '../popups/WaitOrLeavePopupMediator';
import PopupManager from '../utils/PopupManager';
import BaseSceneMediator from './BaseSceneMediator';
import PopupScene from './PopupScene';
import PreloadScene from './PreloadScene';
var PopupSceneMediator = /** @class */ (function (_super) {
    __extends(PopupSceneMediator, _super);
    function PopupSceneMediator(viewComponent) {
        var _this = _super.call(this, PopupSceneMediator.NAME, viewComponent) || this;
        _this.popupManager = PopupManager.getInstance();
        return _this;
    }
    PopupSceneMediator.prototype.onRegister = function () {
        _super.prototype.onRegister.call(this);
        this.unsubscribeToNotification(PopupScene.WAKE_NOTIFICATION, PopupScene.SLEEP_NOTIFICATION);
    };
    PopupSceneMediator.prototype.registerNotificationInterests = function () {
        this.subscribeToNotifications(PreloadScene.LOAD_COMPLETE_NOTIFICATION);
    };
    PopupSceneMediator.prototype.handleNotification = function (notificationName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        _super.prototype.handleNotification.call(this, notificationName);
        switch (notificationName) {
            case PreloadScene.LOAD_COMPLETE_NOTIFICATION:
                this.registerGamePopups();
                break;
            default:
                console.warn(notificationName + " is unhandled!");
                break;
        }
    };
    PopupSceneMediator.prototype.onSceneReady = function () {
        _super.prototype.onSceneReady.call(this);
    };
    PopupSceneMediator.prototype.onSceneWake = function () {
        this.sceneManager.bringToTop(PopupScene.NAME);
        _super.prototype.onSceneWake.call(this);
    };
    PopupSceneMediator.prototype.onSceneSleep = function () {
        this.sceneManager.sendToBack(PopupScene.NAME);
        _super.prototype.onSceneSleep.call(this);
    };
    PopupSceneMediator.prototype.setView = function () {
        var popupScene = new PopupScene();
        this.sceneManager.add(PopupScene.NAME, popupScene);
        this.setViewComponent(popupScene);
        this.sceneManager.start(PopupScene.NAME);
        this.sceneManager.sleep(PopupScene.NAME);
        _super.prototype.setView.call(this);
    };
    PopupSceneMediator.prototype.registerGamePopups = function () {
        this.registerSettingsPopupMediator();
        this.registerExitConfirmationPopupMediator();
        this.registerNewGamePopupMediator();
        this.registerGetRewardPopupMediator();
        this.registerNotEnoughPopupMediator();
        this.registerGameEndPopupMediator();
        this.registerWaitOrLeavePopup();
        this.registerMaintenancePopup();
        this.registerConnectionLostPopup();
        this.registerLevelUpPopup();
        this.registerUserInfoPopup();
        this.registerLogoutConfirmationPopup();
        this.registerBackToGamePopup();
        this.sendNotification(PopupScene.REGISTERED_NOTIFICATION);
    };
    // SettingsPopup
    PopupSceneMediator.prototype.registerSettingsPopupMediator = function () {
        this.facade.registerMediator(new SettingsPopupMediator());
    };
    // ExitConfirmationPopup
    PopupSceneMediator.prototype.registerExitConfirmationPopupMediator = function () {
        this.facade.registerMediator(new ExitConfirmationPopupMediator());
    };
    // NewGamePopup
    PopupSceneMediator.prototype.registerNewGamePopupMediator = function () {
        this.facade.registerMediator(new ReplayPopupMediator());
    };
    // RewardPopup
    PopupSceneMediator.prototype.registerGetRewardPopupMediator = function () {
        this.facade.registerMediator(new RewardPopupMediator());
    };
    // NotEnoughPopup
    PopupSceneMediator.prototype.registerNotEnoughPopupMediator = function () {
        this.facade.registerMediator(new InsufficientFundsPopupMediator());
    };
    // GameEndPopup
    PopupSceneMediator.prototype.registerGameEndPopupMediator = function () {
        this.facade.registerMediator(new GameEndPopupMediator());
    };
    // WaitOrLeavePopup
    PopupSceneMediator.prototype.registerWaitOrLeavePopup = function () {
        this.facade.registerMediator(new WaitOrLeavePopupMediator());
    };
    // MaintenancePopup
    PopupSceneMediator.prototype.registerMaintenancePopup = function () {
        this.facade.registerMediator(new MaintenancePopupMediator());
    };
    // ConnectionLostPopup
    PopupSceneMediator.prototype.registerConnectionLostPopup = function () {
        this.facade.registerMediator(new ConnectionLostPopupMediator());
    };
    // LevelUpPopup
    PopupSceneMediator.prototype.registerLevelUpPopup = function () {
        this.facade.registerMediator(new LevelUpPopupMediator());
    };
    // UserInfoPopup
    PopupSceneMediator.prototype.registerUserInfoPopup = function () {
        this.facade.registerMediator(new UserInfoPopupMediator());
    };
    // LogoutConfirmationPopup
    PopupSceneMediator.prototype.registerLogoutConfirmationPopup = function () {
        this.facade.registerMediator(new LogoutConfirmationPopupMediator());
    };
    // BackToGamePopup
    PopupSceneMediator.prototype.registerBackToGamePopup = function () {
        this.facade.registerMediator(new BackToGamePopupMediator());
    };
    PopupSceneMediator.NAME = 'PopupSceneMediator';
    return PopupSceneMediator;
}(BaseSceneMediator));
export default PopupSceneMediator;
