var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Proxy } from "@candywings/pure-mvc";
import AdsVO from "./VO/AdsVO";
import PlayerVOProxy from "./PlayerVOProxy";
var FirebaseVOProxy = /** @class */ (function (_super) {
    __extends(FirebaseVOProxy, _super);
    function FirebaseVOProxy() {
        return _super.call(this, FirebaseVOProxy.NAME, new AdsVO) || this;
    }
    FirebaseVOProxy.prototype.setUserId = function () {
        var playerVOProxy = this.facade.retrieveProxy(PlayerVOProxy.NAME);
        if (playerVOProxy) {
            window.FirebasePlugin.setUserId(playerVOProxy.vo.userId + "");
        }
    };
    FirebaseVOProxy.prototype.sendLvlUpEvent = function () {
        window.FirebasePlugin.logEvent("level_up", {});
    };
    FirebaseVOProxy.prototype.sendRoomClickEvent = function (roomType) {
        window.FirebasePlugin.logEvent("room_" + roomType, {});
    };
    FirebaseVOProxy.prototype.sendStoreClickedEvent = function () {
        window.FirebasePlugin.logEvent("store_icon", {});
    };
    FirebaseVOProxy.prototype.sendStoreTabClickedEvent = function (tab) {
        window.FirebasePlugin.logEvent("store_tab_" + tab, {});
    };
    FirebaseVOProxy.prototype.sendStoreItemClickedEvent = function (type, tab) {
        console.warn('sendStoreItemClickedEvent', "store_item_" + tab + "_" + type);
        window.FirebasePlugin.logEvent("store_item_" + tab + "_" + type, {});
    };
    FirebaseVOProxy.NAME = 'FirebaseVOProxy';
    FirebaseVOProxy.REGISTERED_NOTIFICATION = FirebaseVOProxy.NAME + "RegisteredNotification";
    return FirebaseVOProxy;
}(Proxy));
export default FirebaseVOProxy;
