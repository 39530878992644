// Plugins
import { getZone } from './Utils';
export function getPlatform() {
    var location = getZone();
    switch (location) {
        case 'cordova':
            return window.device.platform.toLowerCase();
        default:
            return getZone();
    }
}
