var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseScene from './BaseScene';
var PopupScene = /** @class */ (function (_super) {
    __extends(PopupScene, _super);
    function PopupScene() {
        return _super.call(this, PopupScene.NAME) || this;
    }
    PopupScene.prototype.create = function () {
        this.input.setTopOnly(true);
    };
    PopupScene.NAME = 'PopupScene';
    PopupScene.REGISTERED_NOTIFICATION = PopupScene.NAME + "RegisteredNotification";
    PopupScene.WAKE_NOTIFICATION = PopupScene.NAME + "WakeNotification";
    PopupScene.SLEEP_NOTIFICATION = PopupScene.NAME + "SleepNotification";
    PopupScene.SETTING_CHANGE_EVENT = "settingChange";
    return PopupScene;
}(BaseScene));
export default PopupScene;
