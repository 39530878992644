var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { isNullOrUndefined } from 'util';
import { MultiAtlases } from '../assets';
import { gameConfig } from '../constants/GameConfig';
import GameScene from '../view/scenes/GameScene';
import { arrayBufferToBase64, getScene, } from '../view/utils/phaser/PhaserUtils';
import { getPlatform } from './CordovaUtils';
import { getDataFromStorage, StorageKey } from './wrappers/StorageWrapper';
require('phaser/plugins/spine/dist/SpinePlugin');
Array.prototype.contains = function (element) {
    return this.indexOf(element) !== -1;
};
Array.prototype.remove = function () {
    var elements = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        elements[_i] = arguments[_i];
    }
    for (var _a = 0, elements_1 = elements; _a < elements_1.length; _a++) {
        var element = elements_1[_a];
        this.contains(element) && this.splice(this.indexOf(element), 1);
    }
    return elements;
};
Array.prototype.getLast = function () {
    return this[this.length - 1];
};
Array.prototype.getFirst = function () {
    return this[0];
};
Array.prototype.addAt = function (index) {
    var elements = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        elements[_i - 1] = arguments[_i];
    }
    var arrayLastPart = this.splice(index, this.length - index - 1);
    this.remove.apply(this, arrayLastPart);
    this.push.apply(this, elements.concat(arrayLastPart));
    return this;
};
export function serialize(object) {
    return JSON.parse(JSON.stringify(object));
}
export function blackLog() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    write.apply(void 0, ['#000000', '#ffffff', args.shift()].concat(args));
}
export function brownLog() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    write.apply(void 0, ['#654321', '#ffffff', args.shift()].concat(args));
}
export function greyLog() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    write.apply(void 0, ['#8e8e8e', '#ffffff', args.shift()].concat(args));
}
export function write(backgroundColor, fontColor, text) {
    var args = [];
    for (var _i = 3; _i < arguments.length; _i++) {
        args[_i - 3] = arguments[_i];
    }
    var consoleArgs = [
        "",
        "background: " + '#c8c8ff',
        "background: " + '#9696ff',
        "color: " + fontColor + "; background: " + backgroundColor + ";",
        "background: " + '#9696ff',
        "background: " + '#c8c8ff',
    ];
    consoleArgs[0] = "%c %c %c " + text + " " + (args ? '\n' + JSON.stringify(args, null, '\t') : '') + " %c %c";
    console.log.apply(console, consoleArgs);
}
export function sortCardsArray(cards, trumpSuit) {
    var sortedCards = [];
    var trumps = [];
    var _loop_1 = function (value) {
        var cardsByValue = cards.filter(function (card) { return card.value === value; });
        cardsByValue.sort(function (card1, card2) { return card1.suit - card2.suit; });
        var trump = cardsByValue.find(function (card) { return card.suit === trumpSuit; });
        if (!!trump) {
            cardsByValue.remove(trump);
            trumps.push(trump);
        }
        sortedCards.push.apply(sortedCards, cardsByValue);
    };
    for (var value = 0; value < 9; value++) {
        _loop_1(value);
    }
    trumps.length > 2 &&
        trumps.sort(function (card1, card2) { return card1.value - card2.value; });
    sortedCards.push.apply(sortedCards, trumps);
    return sortedCards;
}
export function calculatePositions(cards) {
    var centerX = gameConfig.canvasWidth / 2;
    var cardFrame = getScene(GameScene.NAME).textures.getFrame(MultiAtlases.Cards.Atlas.Name, MultiAtlases.Cards.Atlas.Frames.Card00);
    var cardWidth = cardFrame.width;
    var sumWidth = Math.min(cards.length * cardWidth, gameConfig.canvasWidth - 1.5 * cardWidth);
    var startX = centerX - sumWidth / 2;
    var endX = centerX + sumWidth / 2;
    var y = gameConfig.canvasHeight + 10;
    var line = new Phaser.Geom.Line(startX, y, endX, y);
    var positions = line.getPoints(cards.length + 1);
    positions.shift();
    return positions;
}
export function trueIndex(index) {
    var playersCount = 4;
    return index < 0
        ? (playersCount + (index % playersCount)) % playersCount
        : index % playersCount;
}
export function isCard(target) {
    return (!!target &&
        !isNullOrUndefined(target.suit) &&
        !isNullOrUndefined(target.value));
}
export function formatTime(ms) {
    var allInSecs = Math.floor(ms / 1000);
    var minutes = Math.floor(allInSecs / 60);
    var seconds = allInSecs % 60;
    minutes = minutes < 0 ? 0 : minutes;
    seconds = seconds < 0 ? 0 : seconds;
    return (minutes < 10 ? '0' + minutes : minutes) + "\u0589" + (seconds < 10 ? '0' + seconds : seconds);
}
export function formatValue(value, fixTo, roundSmallValues) {
    if (fixTo === void 0) { fixTo = 2; }
    if (roundSmallValues === void 0) { roundSmallValues = false; }
    value = +value.toFixed(2);
    var exponent = 0;
    while (Math.pow(1000, exponent) <= value) {
        exponent++;
    }
    exponent -= 1;
    if (exponent < 0) {
        return value.toString();
    }
    var numberPart = +(value / Math.pow(1000, exponent));
    numberPart =
        fixTo === 0
            ? Math.floor(+numberPart.toFixed(3))
            : +numberPart.toFixed(fixTo);
    var postfix = generateStringPart(exponent);
    var resultNumber = fixTo >= 1 || postfix.length > 0
        ? +numberPart.toFixed(numberPart < 100 ? 1 : 0)
        : +numberPart.toFixed(roundSmallValues ? 0 : fixTo);
    return resultNumber + postfix;
}
var ALPHABET = 'abcdefghijklmnopqrstuvwxyz';
function generateStringPart(exponent) {
    var result = '';
    switch (exponent) {
        case 0:
            break;
        case 1:
            result = 'K';
            break;
        case 2:
            result = 'M';
            break;
        case 3:
            result = 'B';
            break;
        case 4:
            result = 'T';
            break;
        default:
            var firstElementIndex = Math.floor((exponent - 5) / ALPHABET.length);
            var secondElementIndex = (exponent - 5) % ALPHABET.length;
            result += ALPHABET[firstElementIndex];
            result += ALPHABET[secondElementIndex];
            break;
    }
    return result;
}
var zoneValue = zone;
export function getEnv() {
    return env;
}
export function getMode() {
    return mode;
}
export function getZone() {
    return zoneValue;
}
export function setZone(value) {
    zoneValue = value;
}
export function getResolution() {
    return resolution;
}
export function getAppVersion() {
    return __APP_VERSION__;
}
export function getGameVersion() {
    return VERSION;
}
export function upperCaseFirstLetter(value) {
    return value.substr(0, 1).toUpperCase() + value.substr(1, value.length);
}
export function toKebabCase(value) {
    value.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
    value.indexOf('-') === 0 && value.substring(1);
    return value;
}
export function sumArrayValues() {
    var arr = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arr[_i] = arguments[_i];
    }
    var total = 0;
    for (var _a = 0, arr_1 = arr; _a < arr_1.length; _a++) {
        var num = arr_1[_a];
        total += num;
    }
    return total;
}
export function getEdge(level) {
    switch (true) {
        case level >= 0 && level <= 5:
            return 20;
        case level >= 6 && level <= 10:
            return 40;
        case level >= 11 && level <= 20:
            return 80;
        case level >= 21 && level <= 50:
            return 120;
        case level >= 51 && level <= 100:
            return 160;
        case level >= 101 && level <= 200:
            return 180;
        default:
            return 200;
    }
}
export function isNativeAvatar(url) {
    return url ? url.indexOf('https://test.kingsofdurak.com') !== -1 : false;
}
export function getNativeAvatarKey(url) {
    var pathToAvatars = '/assets/images/';
    return url
        .replace("https://test.kingsofdurak.com" + pathToAvatars, '')
        .replace('.png', '');
}
export function calculateLevelAndProgress(xp) {
    var level = 0;
    var edge = getEdge(level);
    while (xp >= edge) {
        level++;
        xp -= edge;
        edge = getEdge(level);
    }
    var progress = xp / edge;
    return { level: level, progress: progress };
}
export function checkChildWindowCloseState(timeoutRef, childWindow, duration, callback, context) {
    var args = [];
    for (var _i = 5; _i < arguments.length; _i++) {
        args[_i - 5] = arguments[_i];
    }
    if (childWindow.closed) {
        callback.apply.apply(callback, [context].concat(args));
    }
    else {
        setTimeout(function () {
            checkChildWindowCloseState.apply(void 0, [timeoutRef,
                childWindow,
                duration,
                callback,
                context].concat(args));
        }, duration);
    }
}
export function populateAndConcat(array, concatWith) {
    if (concatWith === void 0) { concatWith = ''; }
    var result = '';
    for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
        var item = array_1[_i];
        result += item + concatWith;
    }
    return result;
}
export function getAdMobBannerAdId() {
    switch (getPlatform()) {
        case 'android':
            return '';
        case 'ios':
            return '';
        default:
            return null;
    }
}
export function getAdMobRewardedVideoAdId() {
    switch (getPlatform()) {
        case 'android':
            return 'ca-app-pub-6216306886113201/9470090287';
        case 'ios':
            return 'ca-app-pub-6216306886113201/9172020461';
        default:
            return null;
    }
}
export function getAdMobInterstitialAdId() {
    switch (getPlatform()) {
        case 'android':
            return '';
        case 'ios':
            return '';
        default:
            return null;
    }
}
export function isCordova() {
    return getZone() === 'cordova';
}
export function isOk() {
    return getZone() === 'ok';
}
export function isVk() {
    return getZone() === 'vk';
}
export function isWeb() {
    return getZone() === 'web';
}
export function isMobile() {
    return getPlatform() !== 'browser';
}
export function disableConsoleFunctions() {
    window.console.log = window.console.info = window.console.warn = window.console.error = function () { };
}
export function disableInspectElement() {
    document.onkeydown = function (e) {
        switch (true) {
            case e.keyCode === 123:
            case e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0):
            case e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0):
            case e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0):
            case e.ctrlKey && e.keyCode == 'U'.charCodeAt(0):
                event.preventDefault();
                return false;
            default:
                break;
        }
    };
}
export function isInGame() {
    var lastGameStartMoment = getDataFromStorage(StorageKey.LAST_GAME_JOIN, null);
    return (!!lastGameStartMoment && Date.now() - lastGameStartMoment < 20 * 60 * 1000);
}
export function calculateChildTree(target) {
    var data = {};
    var ref = !!target.children ? target.children : target;
    data.constructor = target.constructor.name;
    data.type = target.type;
    data.object = target;
    if (!!ref.list && ref.list.length > 0) {
        data.totalChildren = calculateChildrenCount(target);
        data.children = [];
        for (var _i = 0, _a = ref.list; _i < _a.length; _i++) {
            var child = _a[_i];
            data.children.push(calculateChildTree(child));
        }
    }
    return data;
}
export function calculateChildrenCount(target) {
    var total = 0;
    var ref = !!target.children ? target.children : target;
    if (!!ref.list && ref.list.length > 0) {
        total += ref.list.length;
        for (var _i = 0, _a = ref.list; _i < _a.length; _i++) {
            var child = _a[_i];
            total += calculateChildrenCount(child);
        }
    }
    return total;
}
export function getFramesCountByString(node, str) {
    var keys = Object.keys(node);
    return keys.filter(function (value) { return value.indexOf(str) !== -1; }).length;
}
export function handleEmptyName(name) {
    return !name || name === '' || name === ' ' ? "User" + Date.now() : name;
}
export function loadScript(url, params) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var script = document.createElement('script');
                    script.src = url;
                    if (!!params) {
                        var keys = Object.keys(params);
                        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                            var key = keys_1[_i];
                            script[key] = params[key];
                        }
                    }
                    document.head.append(script);
                    script.onload = function () { return resolve(); };
                    script.onerror = function (error) { return reject(error); };
                })];
        });
    });
}
export function fetchAvatarByConfig(scene, config, avatarKey) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    avatarKey = avatarKey || "User" + config.userId + "Avatar";
                    if (scene.textures.exists(avatarKey)) {
                        return resolve();
                    }
                    var request = new Request("" + config.avatarURL) || null;
                    var headers = new Headers();
                    var options = {
                        method: 'GET',
                        headers: headers,
                        mode: 'cors',
                        cache: 'default',
                    };
                    fetch(request, options).then(function (response) {
                        response
                            .arrayBuffer()
                            .then(function (buffer) {
                            var base64Flag = 'data:image/jpeg;base64,';
                            var imageStr = arrayBufferToBase64(buffer);
                            var base64 = base64Flag + imageStr;
                            return base64;
                        })
                            .then(function (base64) {
                            if (scene.textures.exists(avatarKey)) {
                                resolve();
                            }
                            else {
                                var resolveHandler_1 = function (key) {
                                    if (key === avatarKey) {
                                        scene.textures.off(Phaser.Textures.Events.ADD, resolveHandler_1);
                                        scene.textures.off(Phaser.Textures.Events.ERROR, rejectHandler_1);
                                        resolve();
                                    }
                                };
                                var rejectHandler_1 = function (key) {
                                    if (key === avatarKey) {
                                        scene.textures.off(Phaser.Textures.Events.ADD, resolveHandler_1);
                                        scene.textures.off(Phaser.Textures.Events.ERROR, rejectHandler_1);
                                        reject();
                                    }
                                };
                                scene.textures.on(Phaser.Textures.Events.ADD, resolveHandler_1);
                                scene.textures.on(Phaser.Textures.Events.ERROR, rejectHandler_1);
                                scene.textures.addBase64(avatarKey, base64);
                            }
                        });
                    });
                })];
        });
    });
}
