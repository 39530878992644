var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MultiAtlases } from '../../../assets';
import { gameConfig } from '../../../constants/GameConfig';
import { delayRunnable } from '../../utils/phaser/PhaserUtils';
import CardView from './CardView';
import DeckCardsCount from './DeckCardsCount';
var DeckView = /** @class */ (function (_super) {
    __extends(DeckView, _super);
    function DeckView(scene) {
        var _this = _super.call(this, scene, -gameConfig.canvasWidth * 0.1, -gameConfig.canvasWidth * 0.1) || this;
        _this.scene = scene;
        _this.isReady = false;
        _this.dealedCards = [];
        _this.createBody();
        _this.setDepth(10);
        _this.scene.add.existing(_this);
        return _this;
    }
    DeckView.prototype.hide = function () {
        this.scene.tweens.add({
            targets: this,
            x: -this.width - this.height,
            duration: 200,
            ease: Phaser.Math.Easing.Expo.InOut,
        });
    };
    DeckView.prototype.showCardsCount = function (count) {
        if (count === void 0) { count = 12; }
        this.cardsCount.show(count);
    };
    DeckView.prototype.preDestroy = function () {
        for (var _i = 0, _a = this.dealedCards; _i < _a.length; _i++) {
            var card = _a[_i];
            !!card && card.active && card.destroy();
        }
    };
    DeckView.prototype.moveDeckIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        if (_this.isReady) {
                            return resolve();
                        }
                        _this.scene.tweens.add({
                            targets: _this,
                            duration: 250,
                            angle: 45,
                        });
                        _this.scene.tweens.add({
                            targets: _this,
                            duration: 250,
                            x: gameConfig.canvasWidth * 0.08,
                            y: gameConfig.canvasHeight * 0.11,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    DeckView.prototype.createAndDealCards = function (playerIndex, cards) {
        if (cards === void 0) { cards = []; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                        var parts, i, index, promises, _loop_1, this_1, partIndex;
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    parts = [];
                                    for (i = 0; i < cards.length; i++) {
                                        index = Math.floor(i / 3);
                                        if (!parts[index]) {
                                            parts[index] = [];
                                        }
                                        parts[index].push(cards ? cards[i] : null);
                                    }
                                    promises = [];
                                    _loop_1 = function (partIndex) {
                                        var _loop_2 = function (cardIndex) {
                                            var cardData = parts[partIndex][cardIndex];
                                            var card = new CardView(this_1.scene, this_1.x, this_1.y, cardData ? cardData.suit : null, cardData ? cardData.value : null, false);
                                            this_1.scene.add.existing(card);
                                            card.setDepth(20);
                                            card.setTexture(MultiAtlases.Cards.Atlas.Name, MultiAtlases.Cards.Atlas.Frames.Cover);
                                            card.setScale(this_1.cover.scaleX);
                                            this_1.dealedCards.push(card);
                                            var promise = new Promise(function (resolve) {
                                                delayRunnable(_this.scene, partIndex * 500 + cardIndex * 50, _this.emitCardDeal, _this, playerIndex, card, resolve);
                                            });
                                            promises.push(promise);
                                        };
                                        for (var cardIndex = 0; cardIndex < parts[partIndex].length; cardIndex++) {
                                            _loop_2(cardIndex);
                                        }
                                    };
                                    this_1 = this;
                                    for (partIndex = 0; partIndex < parts.length; partIndex++) {
                                        _loop_1(partIndex);
                                    }
                                    return [4 /*yield*/, Promise.all(promises)];
                                case 1:
                                    _a.sent();
                                    resolve();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    DeckView.prototype.emitCardDeal = function (playerIndex, card, resolveCallback) {
        this.emit(DeckView.CARD_DEALED_EVENT, playerIndex, card);
        this.dealedCards.remove(card);
        resolveCallback();
    };
    DeckView.prototype.showTrump = function (card, force) {
        if (force === void 0) { force = false; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.trump.updateData(card);
                        _this.trumpSymbol.setTexture(MultiAtlases.GamePlay.Atlas.Name, MultiAtlases.GamePlay.Atlas.Frames["TrumpKind" + _this.trump.suit]);
                        if (force) {
                            _this.trump.angle = 90;
                            _this.trump.x = _this.cover.x + _this.trump.displayWidth * 0.4;
                            _this.trump.y = _this.cover.y;
                            _this.isReady = true;
                            return resolve();
                        }
                        _this.scene.tweens.add({
                            targets: _this.trump,
                            duration: 500,
                            angle: 90,
                            x: _this.cover.x + _this.trump.displayWidth * 0.4,
                            y: _this.cover.y,
                            onComplete: function () {
                                resolve();
                            },
                        });
                    })];
            });
        });
    };
    DeckView.prototype.placeDeck = function (force) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        if (force || _this.x === 0) {
                            _this.x = _this.width * 0.15;
                            _this.y = _this.trump.displayHeight * 0.535;
                            return resolve();
                        }
                        _this.scene.tweens.add({
                            targets: _this,
                            duration: 500,
                            angle: 0,
                            onComplete: function () {
                                _this.scene.tweens.add({
                                    targets: _this,
                                    duration: 600,
                                    delay: 100,
                                    x: _this.width * 0.15,
                                    y: _this.trump.displayHeight * 0.535,
                                    onComplete: function () {
                                        _this.isReady = true;
                                        resolve();
                                    },
                                });
                            },
                        });
                    })];
            });
        });
    };
    DeckView.prototype.setCardsCount = function (count) {
        if (!this.isReady) {
            return;
        }
        this.cover.setVisible(count > 1);
        this.trump.setVisible(count > 0);
        this.trumpSymbol.setVisible(count <= 0);
        count > 0 ? this.cardsCount.show(count) : this.cardsCount.hide();
    };
    DeckView.prototype.createBody = function () {
        this.createCover();
        this.createTrump();
        this.createTrumpSymbol();
        this.createCardsCountComponent();
    };
    DeckView.prototype.createCover = function () {
        this.cover = this.scene.make.sprite({
            key: MultiAtlases.Cards.Atlas.Name,
            frame: MultiAtlases.Cards.Atlas.Frames.Cover,
            x: 0,
            y: 0,
        });
        this.cover.setScale(0.65);
        this.add(this.cover);
        this.setSize(this.cover.width, this.cover.height);
    };
    DeckView.prototype.createTrump = function () {
        this.trump = new CardView(this.scene, 0, 0, 0, 0);
        this.trump.setScale(this.cover.scaleX);
        this.add(this.trump);
        this.sendToBack(this.trump);
    };
    DeckView.prototype.createTrumpSymbol = function () {
        this.trumpSymbol = this.scene.make.sprite({
            key: MultiAtlases.GamePlay.Atlas.Name,
            frame: MultiAtlases.GamePlay.Atlas.Frames["TrumpKind" + this.trump.suit],
            x: this.trump.x + this.trump.width * 0.3,
            y: this.trump.y,
        });
        this.add(this.trumpSymbol);
        this.sendToBack(this.trumpSymbol);
        this.trumpSymbol.setVisible(false);
    };
    DeckView.prototype.createCardsCountComponent = function () {
        this.cardsCount = new DeckCardsCount(this.scene);
        this.add(this.cardsCount);
        this.cardsCount.x = this.trump.height * 0.61;
        this.cardsCount.y = -this.trump.width * 0.5 + this.cardsCount.height * 1.64;
    };
    DeckView.NAME = 'DeckView';
    DeckView.DEAL_CARD_NOTIFICATION = DeckView.NAME + "DealCardNotification";
    DeckView.DECK_PLACED_NOTIFICATION = DeckView.NAME + "DeckPlacedNotification";
    DeckView.DEAL_STARTED_NOTIFICATION = DeckView.NAME + "DealStartedNotification";
    DeckView.DEAL_COMPLETE_NOTIFICATION = DeckView.NAME + "DealCompleteNotification";
    DeckView.CARD_DEALED_EVENT = 'cardDealed';
    return DeckView;
}(Phaser.GameObjects.Container));
export default DeckView;
