var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Audios, MultiAtlases } from '../../assets';
import { gameConfig } from '../../constants/GameConfig';
import KnifeSprite from '../components/wheel/KnifeSprite';
import WheelCenter from '../components/wheel/WheelCenter';
import WheelSection from '../components/wheel/WheelSection';
import WheelSections from '../components/wheel/WheelSections';
import { delayRunnable } from '../utils/phaser/PhaserUtils';
import BaseScene from './BaseScene';
var scale = 1.1;
var WheelScene = /** @class */ (function (_super) {
    __extends(WheelScene, _super);
    function WheelScene() {
        return _super.call(this, WheelScene.NAME) || this;
    }
    WheelScene.prototype.update = function (time, delta) {
        !!this.wheelSections && this.wheelSections.update();
        !!this.wheelCenter && this.wheelCenter.update();
        _super.prototype.update.call(this, time, delta);
    };
    WheelScene.prototype.create = function () {
        this.createBackground();
        this.createCracks();
        this.createWheel();
    };
    WheelScene.prototype.createWheelSections = function (configs) {
        this.wheelSections = new WheelSections(this, this.wheelBg.x, this.wheelBg.y, configs).setScale(scale);
        this.add.existing(this.wheelSections);
        this.createWheelOtherElements(configs);
    };
    WheelScene.prototype.showWheelElements = function () {
        var _this = this;
        this.tweens.add({
            targets: [this.icon, this.wheelCenter],
            scaleX: scale,
            scaleY: scale,
            duration: 200,
            onStart: function () {
                _this.icon.setScale(0);
                _this.wheelCenter.setScale(0);
                _this.icon.visible = true;
                _this.wheelCenter.visible = true;
            },
            onComplete: function () {
                _this.wheelSections.visible = true;
                for (var i = 0; i < _this.wheelSections.list.length; i++) {
                    var sector = _this.wheelSections.list[i];
                    sector.show(i * 75);
                    sector.once(WheelSection.SHOW_COMPLETE_EVENT, _this.wheelCenter.showIcon.bind(_this.wheelCenter, i));
                    sector.once(WheelSection.HIDE_COMPLETE_EVENT, _this.wheelCenter.hideIcon.bind(_this.wheelCenter, i));
                    if (i === _this.wheelSections.list.length - 1) {
                        _this.startWheelRotationAnimation();
                    }
                }
            },
        });
    };
    WheelScene.prototype.showKnife = function () {
        this.knifeSprite.show();
    };
    WheelScene.prototype.onKnifeThrow = function (index) {
        return __awaiter(this, void 0, void 0, function () {
            var x, y, targetSection, currentAngle, crossingAngle, angleDif, fullRotationFramesCount, framesCount;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.playSFX(Audios.SwordSfx.Name);
                        targetSection = this.wheelSections.list[index];
                        currentAngle = targetSection.angle + this.wheelSections.angle;
                        switch (this.knifeSprite.state) {
                            case KnifeSprite.LEFT:
                                x = this.wheelCenter.x - targetSection.width * 0.8;
                                y = this.wheelCenter.y;
                                crossingAngle = 180;
                                break;
                            case KnifeSprite.NORMAL:
                                x = this.wheelCenter.x;
                                y = this.wheelCenter.y + targetSection.width * 0.8;
                                crossingAngle = 90;
                                break;
                            case KnifeSprite.RIGHT:
                                x = this.wheelCenter.x + targetSection.width * 0.8;
                                y = this.wheelCenter.y;
                                crossingAngle = 0;
                                break;
                            default:
                                break;
                        }
                        angleDif = (crossingAngle - currentAngle + 360) % 360;
                        fullRotationFramesCount = 360 / this.wheelSections.angularSpeed;
                        framesCount = angleDif / this.wheelSections.angularSpeed;
                        return [4 /*yield*/, this.knifeSprite.tweenTo((framesCount < 10 ? framesCount + fullRotationFramesCount : framesCount) *
                                this.game.loop.delta, x, y)];
                    case 1:
                        _a.sent();
                        this.stopWheelRotationAnimation(index);
                        return [2 /*return*/];
                }
            });
        });
    };
    WheelScene.prototype.show = function () {
        delayRunnable(this, 1000, this.startShowAnimation, this);
    };
    WheelScene.prototype.hide = function () {
        this.startCracksHideAnimation();
        this.hideWheelElements();
    };
    WheelScene.prototype.startWheelRotationAnimation = function () {
        var _this = this;
        this.tweens.add({
            targets: [this.wheelSections, this.wheelCenter],
            angularSpeed: 15,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 3000,
            onComplete: function () {
                _this.events.emit(WheelScene.ALLOW_KNIFE_EVENT);
            },
        });
    };
    WheelScene.prototype.createWheelOtherElements = function (configs) {
        this.wheelCenter = new WheelCenter(this, configs);
        this.wheelCenter.x = this.wheelBg.x;
        this.wheelCenter.y = this.wheelBg.y;
        this.add.existing(this.wheelCenter);
        this.icon = this.add
            .sprite(this.wheelCenter.x, this.wheelCenter.y, MultiAtlases.Wheel.Atlas.Name, MultiAtlases.Wheel.Atlas.Frames.Icon)
            .setScale(scale);
        this.upperFrame = this.add
            .sprite(this.wheelBg.x, this.wheelBg.y, MultiAtlases.Wheel.Atlas.Name, MultiAtlases.Wheel.Atlas.Frames.WheelUp)
            .setScale(scale);
        this.add.existing(this.wheelCenter);
        this.wheelCenter.visible = false;
        this.icon.visible = false;
        this.upperFrame.visible = false;
        this.createKnife();
    };
    WheelScene.prototype.startShowAnimation = function () {
        var _this = this;
        this.playSFX(Audios.BgCracksSfx.Name);
        this.wheelBg.visible = true;
        this.maskGraphics = this.make.graphics({});
        this.tweens.add({
            targets: this.wheelBg,
            scaleX: scale,
            scaleY: scale,
            delay: 50,
            duration: 50,
            onComplete: function () {
                _this.upperFrame.visible = true;
                _this.startCracksShowAnimation();
                _this.cameras.main.shake(2000, 0.0015);
                _this.playSFX(Audios.WheelConstructingSfx.Name);
                _this.tweens.add({
                    targets: _this.background,
                    onStart: function () {
                        _this.background.visible = true;
                    },
                    alpha: 1,
                    duration: 400,
                    onComplete: function () {
                        _this.events.emit(WheelScene.SHOW_WHEEL_ELEMENTS_EVENT);
                    },
                });
            },
        });
    };
    WheelScene.prototype.startCracksShowAnimation = function () {
        var _this = this;
        var tweenTarget = {
            x: this.wheelBgCrack.x,
            y: this.wheelBgCrack.y,
            radius: this.wheelBgCrack.displayWidth * 0.6,
        };
        this.maskGraphics.fillCircle(tweenTarget.x, tweenTarget.y, tweenTarget.radius);
        var mask = new Phaser.Display.Masks.GeometryMask(this, this.maskGraphics);
        this.bgCrack.setMask(mask);
        this.wheelBgCrack.setMask(mask);
        this.centerCracks.setMask(mask);
        this.tweens.add({
            targets: tweenTarget,
            radius: gameConfig.canvasWidth / 2,
            duration: 2000,
            onUpdate: function () {
                _this.maskGraphics.clear();
                _this.maskGraphics.fillCircle(tweenTarget.x, tweenTarget.y, tweenTarget.radius);
            },
        });
        this.wheelBgCrack.visible = true;
        this.bgCrack.visible = true;
        this.centerCracks.visible = true;
    };
    WheelScene.prototype.createBackground = function () {
        this.background = this.add.sprite(0, gameConfig.canvasHeight, MultiAtlases.Wheel.Atlas.Name, MultiAtlases.Wheel.Atlas.Frames.Background);
        this.background.setOrigin(0, 1);
        this.background.setScale(Math.max(gameConfig.canvasHeight / this.background.height, gameConfig.canvasWidth / this.background.width));
        this.background.alpha = 0;
        this.background.depth = -90;
        this.background.visible = false;
    };
    WheelScene.prototype.createCracks = function () {
        this.wheelBgCrack = this.add
            .sprite(gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.5, MultiAtlases.Wheel.Atlas.Name, MultiAtlases.Wheel.Atlas.Frames.CircleCrack)
            .setScale(scale);
        this.bgCrack = this.add.sprite(gameConfig.canvasWidth * 0.5, gameConfig.canvasHeight * 0.5, MultiAtlases.Wheel.Atlas.Name, MultiAtlases.Wheel.Atlas.Frames.Cracks);
        this.bgCrack.setTint(0x000000);
        this.centerCracks = this.add.sprite(this.wheelBgCrack.x - this.wheelBgCrack.displayWidth * 0.01, this.wheelBgCrack.y + this.wheelBgCrack.displayHeight * 0.07, MultiAtlases.Wheel.Atlas.Name, MultiAtlases.Wheel.Atlas.Frames.CenterCracks);
        this.bgCrack.depth = -88;
        this.bgCrack.visible = false;
        this.centerCracks.visible = false;
        this.wheelBgCrack.visible = false;
    };
    WheelScene.prototype.createWheel = function () {
        this.wheelBg = this.add
            .sprite(gameConfig.canvasWidth / 2, gameConfig.canvasHeight * 0.5, MultiAtlases.Wheel.Atlas.Name, MultiAtlases.Wheel.Atlas.Frames.WheelBg)
            .setScale(2);
        this.wheelBg.visible = false;
    };
    WheelScene.prototype.createKnife = function () {
        this.knifeSprite = new KnifeSprite(this, new Phaser.Geom.Point(this.icon.x, this.icon.y));
        this.add.existing(this.knifeSprite);
        this.knifeSprite.depth = 100;
        this.knifeSprite.on(KnifeSprite.KNIFE_THROWN_EVENT, this.emitUserAction, this);
        this.knifeSprite.setVisible(false);
    };
    WheelScene.prototype.emitUserAction = function () {
        this.events.emit(WheelScene.KNIFE_THROW_EVENT);
    };
    WheelScene.prototype.stopWheelRotationAnimation = function (index) {
        var _this = this;
        this.cameras.main.shake(300, 0.003);
        var targetSection = this.wheelSections.list[index];
        targetSection.mark();
        this.wheelSections.angle %= 360;
        this.wheelCenter.angle %= 360;
        this.tweens.add({
            targets: [this.wheelSections, this.wheelCenter],
            angle: 90 - targetSection.angle + 2 * 360,
            duration: 6000,
            ease: Phaser.Math.Easing.Expo.Out,
            onComplete: function () {
                targetSection.makeSelected();
                _this.events.emit(WheelScene.WHEEL_ROTATION_COMPLETED_EVENT);
            },
        });
        this.wheelSections.angularSpeed = 0;
        this.wheelCenter.angularSpeed = 0;
    };
    WheelScene.prototype.startCracksHideAnimation = function () {
        var _this = this;
        this.cameras.main.shake(1000, 0.005);
        var tweenTarget = {
            x: this.wheelBgCrack.x,
            y: this.wheelBgCrack.y,
            radius: gameConfig.canvasWidth / 2,
        };
        this.tweens.add({
            targets: tweenTarget,
            radius: this.wheelBgCrack.displayWidth * 0.6,
            duration: 750,
            onUpdate: function () {
                _this.maskGraphics.clear();
                _this.maskGraphics.fillCircle(tweenTarget.x, tweenTarget.y, tweenTarget.radius);
            },
            onComplete: function () {
                _this.startHideAnimation();
            },
        });
    };
    WheelScene.prototype.startHideAnimation = function () {
        var _this = this;
        this.tweens.add({
            targets: this.background,
            onStart: function () {
                _this.background.visible = true;
            },
            alpha: 0,
            duration: 200,
            onComplete: function () {
                _this.wheelBgCrack.visible = false;
                _this.bgCrack.visible = false;
                _this.upperFrame.visible = false;
                _this.tweens.add({
                    targets: _this.wheelBg,
                    scaleX: 2,
                    scaleY: 2,
                    duration: 50,
                    onComplete: function () {
                        _this.wheelBg.visible = false;
                        _this.events.emit(WheelScene.READY_TO_DESTROY_EVENT);
                    },
                });
            },
        });
    };
    WheelScene.prototype.hideWheelElements = function () {
        var _this = this;
        for (var i = 0; i < this.wheelSections.list.length; i++) {
            var sector = this.wheelSections.list[i];
            sector.hide(i * 75);
            if (i !== this.wheelSections.list.length - 1) {
                continue;
            }
            sector.on(WheelSection.HIDE_COMPLETE_EVENT, function () {
                _this.tweens.add({
                    targets: [_this.icon, _this.wheelCenter],
                    scaleX: 0,
                    scaleY: 0,
                    duration: 100,
                    onComplete: function () {
                        _this.icon.visible = false;
                        _this.wheelCenter.visible = false;
                    },
                });
            });
        }
    };
    WheelScene.NAME = 'WheelScene';
    WheelScene.SHOWN_NOTIFICATION = WheelScene.NAME + "ShownNotification";
    WheelScene.WHEEL_ROTATED_NOTIFICATION = WheelScene.NAME + "WheelRotatedNotification";
    WheelScene.KNIFE_READY_TO_THROW_NOTIFICATION = WheelScene.NAME + "KnifeReadyToThrowNotification";
    WheelScene.READY_TO_DESTROY_NOTIFICATION = WheelScene.NAME + "ReadyToDestroyNotification";
    WheelScene.ALLOW_KNIFE_EVENT = 'allowKnife';
    WheelScene.KNIFE_THROW_EVENT = 'knifeThrow';
    WheelScene.READY_TO_DESTROY_EVENT = 'readyToDestroy';
    WheelScene.SHOW_WHEEL_ELEMENTS_EVENT = 'showWheelElements';
    WheelScene.WHEEL_ROTATION_COMPLETED_EVENT = 'wheelRotationComplete';
    return WheelScene;
}(BaseScene));
export default WheelScene;
